import React from "react";
import {
  Container,
  Box,
  Input,
  Button,
  InputLabel,
  Typography,
  InputAdornment,
  IconButton,
  // Customizable Area Start
  Grid,
  Paper,
  TextField,
  LinearProgress,
  CircularProgress
  // Customizable Area End
} from "@material-ui/core";

// Customizable Area Start
import { createTheme, ThemeProvider, makeStyles, withStyles } from "@material-ui/core/styles";
import { purple } from '@material-ui/core/colors';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from "@material-ui/core/FormControl";
import Select from '@material-ui/core/Select';
import TuneIcon from '@material-ui/icons/Tune';
import './style.css'
import VerticalBarChart from "./VerticalBarChart.web";
import VerticalBarChart1 from "./VerticalBarChart1.web";


const theme = createTheme({
  palette: {
    primary: {
      main: "#fff",
      contrastText: "#fff",
    },
  },
  typography: {
    h6: {
      fontWeight: 500,
    },
    subtitle1: {
      margin: "20px 0px",
    },
  },
});
// Customizable Area End

import CustomisableUserProfilesController, {
  Props,
  configJSON,
} from "./CustomisableUserProfilesController.web";

export default class InsightOverview extends CustomisableUserProfilesController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  async componentDidMount() {
    this.getListTimeData()
    this.getListWatchedData()
    this.getReportListTimeData()
    this.getReportListWatchedData()
  }

  handleTab = (value: any) => {
    this.getAllNotesData('', value == 0 ? '' : value == 1 ? 'personal' : 'course');
    this.setState({
      handleTabValue: value,
      searchValue: ''
    })
  }
  handleSearch = (e: any) => {
    this.getAllNotesData(e.target.value, this.state.handleTabValue == 0 ? '' : this.state.handleTabValue == 1 ? 'personal' : 'course');
    this.setState({
      searchValue: e.target.value
    })
  }
  // Customizable Area End
  render() {
    const { progressLoading, videoWatchLoading, listReportWatchedData, listReportTimeData } = this.state;
    console.log(listReportWatchedData, 'Course_detail_data');

    return (
      // Customizable Area Start
      <Grid container>
        <Typography variant="h3" className="profile_header">Course Reports</Typography>
        <Grid item xs={12}>
          <Paper className="paper" style={{
            overflow: 'hidden', padding: '30px',
            background: 'linear-gradient(180deg, rgba(253,253,255,1) 0%, rgba(239,244,250,1) 100%)',
            borderRadius: '24px',
            flex: 1, maxWidth: '87vw', height: '500px', margin: 'auto', marginTop: '25px'
          }}>
            <Grid item xs={12} >
              <Box style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                <Typography variant="h6" className="profileName">
                  Time Spent on Training
                </Typography>
                <Box style={{ fontWeight: 700, fontSize: '17px', float: 'right', cursor: 'pointer' }}>
                  <FormControl style={{ color: "#3F526D", borderColor: "none", borderWidth: "0px", borderStyle: "solid", borderRadius: "0px", minWidth: "120px", justifyContent: "center" }}>
                    <Select
                      style={{ width: "220px", height: '45px', fontSize: "18px", backgroundColor: "#f5f7f6", paddingLeft: '30px', borderRadius: '10px', border: 'none' }}
                      value={this.state.timeSelected}
                      onChange={this.handleChangeTime}
                      disableUnderline
                      inputProps={{
                        name: "agent",
                        id: "age-simple"
                      }}
                    >
                      {this.state.values.map((value: any, index: any) => {
                        console.log(value);

                        return <MenuItem onClick={() => this.getReportListTimeData(value)} value={value}>{value}</MenuItem>;
                      })}
                    </Select>
                  </FormControl></Box>
              </Box>
              <Grid container style={{ marginTop: "10px", maxWidth: '100%' }}>
                <Grid item xs={12} style={{ display: 'flex', width: '100%' }} >
                  {progressLoading ? <Box style={{ display: "flex", height: "35%", width: "100%", justifyContent: "center", alignItems: "center" }} > <CircularProgress /> </Box> :
                    <VerticalBarChart1 data={listReportTimeData} />}
                </Grid>
              </Grid>
            </Grid>
          </Paper>
        </Grid>
        <Grid item xs={12}>
          <Paper className="paper" style={{
            overflow: 'hidden', padding: '30px',
            background: 'linear-gradient(180deg, rgba(253,253,255,1) 0%, rgba(239,244,250,1) 100%)',
            borderRadius: '24px',
            flex: 1, maxWidth: '87vw', height: '500px', margin: 'auto', marginTop: '25px'
          }}>
            <Grid item xs={12} >
              <Box style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                <Typography variant="h6" className="profileName">
                  Total Number Of Videos Watched
                </Typography>
                <Box style={{ fontWeight: 700, fontSize: '17px', float: 'right', cursor: 'pointer' }}>
                  <FormControl style={{ color: "#3F526D", borderColor: "none", borderWidth: "0px", borderStyle: "solid", borderRadius: "0px", minWidth: "120px", justifyContent: "center" }}>
                    <Select
                      style={{ width: "220px", height: '45px', fontSize: "18px", backgroundColor: "#f5f7f6", paddingLeft: '30px', borderRadius: '10px', border: 'none' }}
                      value={this.state.videoSelected}
                      onChange={this.handleChangeVideo}
                      disableUnderline
                      inputProps={{
                        name: "agent",
                        id: "age-simple"
                      }}
                    >
                      {this.state.values.map((value: any, index: any) => {
                        return <MenuItem key={index} onClick={() => this.getReportListWatchedData(value)} value={value}>{value}</MenuItem>;
                      })}
                    </Select>
                  </FormControl></Box>
              </Box>
              <Grid container style={{ marginTop: "10px", maxWidth: '100%' }}>
                <Grid item xs={12} style={{ display: 'flex', width: '100%' }} >
                  {progressLoading ? <Box style={{ display: "flex", height: "35%", width: "100%", justifyContent: "center", alignItems: "center" }} > <CircularProgress /> </Box> :
                    <VerticalBarChart data1={listReportWatchedData} />}
                </Grid>
              </Grid>
            </Grid>
          </Paper>
        </Grid>
      </Grid>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
const webStyle = {
  overallProgress: {
    width: '80%',
    height: '35px',
    backgroundColor: '#e4f5f8',
    "& .MuiLinearProgress-colorPrimary": {
      backgroundColor: "red",
    },
  },
  overallProgress2: {
    width: '98%',
    height: '30px',
    backgroundColor: '#e4f5f8',
    paddingTop: '10px',
    borderRadius: '10px',
    "& .MuiLinearProgress-colorPrimary": {
      backgroundColor: "red",
    },
  },
  inputStyle: {
    borderBottom: "1px solid rgba(0, 0, 0, 0.6)",
    width: "100%",
    height: "100px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
  },
  buttonStyle: {
    width: "100%",
    height: "45px",
    marginTop: "40px",
    border: "none",
    backgroundColor: "rgb(98, 0, 238)",
  },
  allCourseInnerContent: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItem: 'center',
    width: '97%',
    paddingBottom: '10px'
  }
};

const TransparentButton = withStyles((theme) => ({
  root: {
    backgroundColor: 'transparent',
    color: purple[500],
    borderRadius: "10px",
    borderColor: purple[500],
    border: '1px solid',
    '&:hover': {
      borderColor: purple[500],
      color: purple[500],
      backgroundColor: 'transparent',
    },
  },
}))(Button);

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
  },
}));



// Customizable Area End
