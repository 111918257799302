// Customizable Area 
import React from "react";

import {
    Container,
    Box,
    Input,
    Button,
    InputLabel,
    Typography,
    InputAdornment,
    IconButton,
    // Customizable Area Start
    Grid,
    Paper,
    TextField,
    Checkbox,
    FormControlLabel,
    Switch,
    FormControl,
    MenuItem,
    Select,
    OutlinedInput,
    Accordion,
    AccordionSummary,
    AccordionDetails,
    TextareaAutosize


} from "@material-ui/core";

//@ts-ignore
import { createTheme, ThemeProvider, createStyles, Theme, withStyles } from "@material-ui/core/styles";
// import withStyles from '@material-ui/core/styles';
import { ExpandMore } from "@material-ui/icons";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import Visibility from "@material-ui/icons/Visibility";
import { useFormik, Formik, ErrorMessage } from 'formik';
import * as yup from 'yup';
import CourseCreationController, {
    Props,
    configJSON,
} from "./CourseCreationController.web";
import './CourseCreation.css';
import { BackgroundColor, CirclePlus, Library, Plus, Trash, upload, Vector } from "./assets";
class AddAchievement extends CourseCreationController {
    handleFileChange(e: any) {
        console.log(e.target.files[0])
        this.setState({//@ts-ignore
            imgVidFile: e.target.files[0], imgVidUrl: URL.createObjectURL(e.target.files[0]), filename: e.target.files[0].name
        })
    }
    render() {
        const onImageChange = (event: any) => {
            console.log(event.target.files, event.target.files[0])
            this.setState({
                achievmentImg: event.target.files[0]
            })

        }
        return <>
            <div className="add-reward">
                <Formik
                    initialValues={{
                        name: "",
                        description: "",
                    }}
                    onSubmit={(values) => {

                        this.addAchievment(values)

                        console.log(values, 'achievment');
                    }}
                    validationSchema={yup.object().shape({
                        name: yup.string().required('Please enter name')
                            .min(5, 'Name is too short (minimum is 5 characters)'),
                        description: yup.string().required('Please enter description')
                            .min(150, 'Description is too short (minimum is 150 characters)'),

                    })}
                >
                    {(props) => {
                        const {
                            values,
                            touched,
                            errors,
                            dirty,
                            isSubmitting,
                            setFieldValue,
                            handleChange,
                            handleBlur,
                            handleSubmit,
                            handleReset,
                        } = props;
                        return (
                            <form style={{ width: "100%" }} onSubmit={handleSubmit}>
                                <Grid container md={12} xs={12} spacing={4}>
                                    <Grid item md={6} xs={12}>
                                        <TextField
                                            type="text"
                                            variant="outlined"
                                            name="name"
                                            value={values.name}
                                            onChange={handleChange}
                                            error={touched.name && Boolean(errors.name)}
                                            helperText={touched.name && errors.name}
                                            placeholder="Achievement name"
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                            fullWidth
                                        />
                                    </Grid>
                                    <Grid item md={6} xs={12}>
                                        <TextField
                                            type="text"
                                            variant="outlined"
                                            placeholder="Search Existing Rewards/Achievements"
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                            fullWidth
                                        />
                                    </Grid>
                                </Grid>
                                <Grid container md={12} xs={12} spacing={4}>
                                    <Grid item md={12} xs={12}>
                                        <InputLabel htmlFor="reward-desc" className="input-label" style={{ marginBottom: "10px" }}>Achievement Description</InputLabel>
                                        <TextField
                                            fullWidth
                                            id="course_desc"
                                            name="description"
                                            value={values.description}
                                            onChange={handleChange}
                                            error={touched.description && Boolean(errors.description)}
                                            helperText={touched.description && errors.description}
                                            variant="outlined"
                                            placeholder="Please write your description here"
                                            multiline
                                            rows={10}
                                            maxRows={12}
                                            InputLabelProps={{
                                                shrink: true
                                            }}
                                        />
                                    </Grid>

                                </Grid>
                                {/* <Grid container md={12} xs={12} spacing={4}>
                            <Grid item  md={6} xs={12}>
                                <p className="inner-heading">Choose Video</p>
                            </Grid>
                            <Grid item  md={6} xs={12}>
                                <p className="inner-heading">Upload Thumbnail</p>
                            </Grid>
                        </Grid> */}

                                <Grid item container md={12} xs={12} spacing={4}>
                                    {/* <Grid item container md={5} xs={12}>
                        <p className="inner-heading">Choose Video</p>

                        <div className="outer-border">
                                <Grid item md={12} xs={12}>
                                    <div className="inner-container">
                                     <div className="inner-border">
                                        <p className="inner-text">Add Object</p>
                                        <img src={Vector}/>
                                     </div>
                                    </div>
                                </Grid>
                                <Grid item md={12} xs={12}>
                                    <div className="inner-container">
                                    <div className="inner-border">
                                        <p className="inner-text">Add Object</p>
                                        <img src={Vector}/>
                                    </div>
                                    </div>
                                </Grid>
                                <Grid item md={12} xs={12}>
                                    <div className="inner-container">
                                    <div className="inner-border">
                                        <p className="inner-text">Add Object</p>
                                        <img src={Vector}/>
                                    </div>
                                    </div>
                                </Grid>
                                <Grid item md={12} xs={12}>
                                <div className="inner-container">
                                <Button className="add-more-btn">+ Add more</Button>
                                </div>
                                </Grid>
                        </div>

                        </Grid> */}

                                    {/* <Grid item container md={6} xs={12} >
                                
                                <div className="dashed-border-achievement">
                                    <Grid item  md={2}></Grid>
                                    <Grid item  md={8} xs={12}>
                                        <div className="inner-border2">
                                        <img src={Library} />
                                        <p className="text">
                                        Choose video from content Library
                                        </p>
                                        </div>

                                    </Grid>
                                    <Grid item  md={2}></Grid>
                                </div>
                            </Grid> */}
                                    <Grid item container md={7} xs={12} >
                                        <p className="inner-heading">Upload Thumbnail</p>
                                        <div className="dashed-border">
                                            <Grid item md={1}></Grid>
                                            <Grid item md={5} xs={12}>
                                                <div className="inner-border2">
                                                    <img src={Plus} height="50" />
                                                    <p className="text">
                                                        Choose from icon Library
                                                    </p>
                                                </div>

                                            </Grid>
                                            <Grid item md={1} >
                                                <p className="or-text">Or</p>
                                            </Grid>


                                            <Grid item md={5} xs={12}>
                                                <div className="inner-border2" >
                                                    <label htmlFor="uploadFile" style={{ display: "block" }}>
                                                        <input type="file" name="files" id="uploadFile" style={{ visibility: "hidden" }} accept="image/*" onChange={onImageChange} />
                                                        {this.state.achievmentImg == "" ? <img src={upload} /> : <img width={200} src={URL.createObjectURL(this.state.achievmentImg)} />}

                                                    </label>
                                                    <p className="text">
                                                        Upload from device
                                                    </p>

                                                </div>
                                            </Grid>
                                            <Grid item md={1}></Grid>

                                        </div>
                                    </Grid>
                                </Grid>
                                <Grid container md={12} xs={12}>
                                    <Grid item md={12} xs={12} style={{ padding: "30px", textAlign: "center" }}>
                                        <Button className="create-reward-btn" type="submit">Create Achievement</Button>
                                    </Grid>
                                </Grid>
                            </form>
                        );
                    }}
                </Formik>


            </div>

        </>
    }
}
export default AddAchievement;
// Customizable Area End