// Customizable Area Start
import React from "react";
import {
  Grid,
  Button,
  FormControl,
  Select,
  MenuItem,
  InputAdornment,
  IconButton,
  TextField,
  FormControlLabel,
  InputLabel,
  Chip,
  Dialog,
  DialogActions,
  DialogContent,
  Switch,
  Box,
  RadioGroup,
  CircularProgress,
} from "@material-ui/core";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import Accordion from "@material-ui/core/Accordion";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import Typography from "@material-ui/core/Typography";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import Radio from "@material-ui/core/Radio";
import CloseIcon from "@material-ui/icons/Close";
//@ts-ignore
import Calendar from "react-calendar";
import {
  Theme,
  createStyles,
  withStyles,
  WithStyles,
} from "@material-ui/core/styles";
import * as yup from "yup";
import { Formik, Form } from "formik";
import "./UserInvite.css";
import "./styles.css";
import "react-calendar/dist/Calendar.css";
import { SelectArrow, AddIcon, UploadIcon } from "./assets";
import Autocompletes from "../../../../../components/src/Automcomplete/Autocomplete";
import PageTitle from "../../../../../components/src/DynamicTitle";
import ManageUsersController, { Branch, Team } from "./ManageUsersController";

const validationSchema = yup.object({
  firstName: yup.string().required("First name is required"),
  lastName: yup.string().required("Last name is required"),
  title: yup.string(),
  email: yup
    .string()
    .email("Email address is not valid")
    .required("Email address is required"),
  phone: yup.string().required("Phone number is required"),
  branchId: yup.number(),
  teamId: yup.number(),
  activeUser: yup.string().required("This field is required"),
  password: yup
    .string()
    .matches(
      /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{5,}$/,
      "Must Contain 5 Characters, One Uppercase, One Lowercase, One Number, and one special case Character"
    )
    .required("Password is required"),
  confirmPassword: yup
    .string()
    .oneOf([yup.ref("password")], "Passwords must match")
    .required("Password confirmation is required"),
  accExpDate: yup.string(),
  accessLevel: yup.string().required("Access level is required"),
  showReports: yup.string().required("This field is required"),
  canManageUsers: yup.string().required("This field is required"),
});

const initialValues = {
  firstName: "",
  lastName: "",
  title: "",
  email: "",
  phone: "",
  branchId: "",
  teamId: "",
  activeUser: "no",
  password: "",
  confirmPassword: "",
  accExpDate: "",
  accessLevel: "user",
  showReports: "show",
  canManageUsers: "yes",
};

//@ts-ignore
const dialogStyles: any = (theme: Theme) =>
  createStyles({
    root: {
      margin: 0,
      padding: theme.spacing(2),
    },
    closeButton: {
      position: "absolute",
      right: theme.spacing(1),
      top: theme.spacing(1),
      color: theme.palette.grey[500],
    },
  });

//@ts-ignore
export interface DialogTitleProps extends WithStyles<typeof dialogStyles> {
  id: string;
  children: React.ReactNode;
  onClose: () => void;
}

const DialogTitle = withStyles(dialogStyles)((props: DialogTitleProps) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const SelectArrowIcon = () => {
  return (
    <img
      src={SelectArrow}
      alt="down arrow"
      style={{ marginRight: 10, width: 13 }}
    />
  );
};

class CreateUser extends ManageUsersController {
  render() {
    return (
      <>
        <PageTitle title="Create New User" />
        <Formik
          validationSchema={validationSchema}
          initialValues={initialValues}
          onSubmit={(values, { setSubmitting, resetForm }) => {
            this.createUser(values, resetForm);
            setSubmitting(true);
          }}
        >
          {(formikProps) => {
            return (
              <Form>
                <div className="user-invite">
                  {/* User info starts */}
                  <div className={this.props.classes.root}>
                    <Accordion
                      className={this.props.classes.accordionContainer}
                      defaultExpanded
                    >
                      <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1c-content"
                        id="panel1c-header"
                      >
                        <Typography className={"user-heading"}>
                          Users Information
                        </Typography>
                      </AccordionSummary>
                      <AccordionDetails>
                        <div className={this.props.classes.fullWidth}>
                          <Grid container spacing={4}>
                            <Grid item md={6} xs={12}>
                              <InputLabel
                                className="input-label"
                                htmlFor="first_name"
                              >
                                First Name*
                              </InputLabel>
                              <TextField
                                fullWidth
                                id="first_name"
                                name="firstName"
                                variant="outlined"
                                placeholder="First Name"
                                value={formikProps.values.firstName}
                                onChange={formikProps.handleChange}
                                onBlur={formikProps.handleBlur}
                                error={
                                  formikProps.touched.firstName &&
                                  Boolean(formikProps.errors.firstName)
                                }
                                helperText={
                                  formikProps.touched.firstName &&
                                  formikProps.errors.firstName
                                }
                                InputLabelProps={{
                                  shrink: true,
                                }}
                              />
                            </Grid>
                            <Grid item md={6} xs={12}>
                              <InputLabel
                                className="input-label"
                                htmlFor="last_name"
                              >
                                Last Name*
                              </InputLabel>
                              <TextField
                                fullWidth
                                id="last_name"
                                name="lastName"
                                variant="outlined"
                                placeholder="Last Name"
                                value={formikProps.values.lastName}
                                onChange={formikProps.handleChange}
                                onBlur={formikProps.handleBlur}
                                error={
                                  formikProps.touched.lastName &&
                                  Boolean(formikProps.errors.lastName)
                                }
                                helperText={
                                  formikProps.touched.lastName &&
                                  formikProps.errors.lastName
                                }
                                InputLabelProps={{
                                  shrink: true,
                                }}
                              />
                            </Grid>
                          </Grid>
                          <Grid container spacing={4}>
                            <Grid item md={6} xs={12}>
                              <InputLabel
                                className="input-label"
                                htmlFor="title"
                              >
                                Title
                              </InputLabel>
                              <TextField
                                fullWidth
                                id="title"
                                name="title"
                                variant="outlined"
                                placeholder="Title"
                                value={formikProps.values.title}
                                onChange={formikProps.handleChange}
                                onBlur={formikProps.handleBlur}
                                error={
                                  formikProps.touched.title &&
                                  Boolean(formikProps.errors.title)
                                }
                                helperText={
                                  formikProps.touched.title &&
                                  formikProps.errors.title
                                }
                                InputLabelProps={{
                                  shrink: true,
                                }}
                              />
                            </Grid>
                            <Grid item md={6} xs={12}>
                              <InputLabel
                                className="input-label"
                                htmlFor="email_address"
                              >
                                Email Address*
                              </InputLabel>
                              <TextField
                                fullWidth
                                id="email_address"
                                name="email"
                                variant="outlined"
                                placeholder="Email Address"
                                value={formikProps.values.email}
                                onChange={formikProps.handleChange}
                                onBlur={formikProps.handleBlur}
                                error={
                                  formikProps.touched.email &&
                                  Boolean(formikProps.errors.email)
                                }
                                helperText={
                                  formikProps.touched.email &&
                                  formikProps.errors.email
                                }
                                InputLabelProps={{
                                  shrink: true,
                                }}
                              />
                            </Grid>
                          </Grid>
                          <Grid container spacing={4}>
                            <Grid item md={6} xs={12}>
                              <InputLabel
                                className="input-label"
                                htmlFor="phone_number"
                              >
                                Phone Number*
                              </InputLabel>
                              <TextField
                                fullWidth
                                id="phone_number"
                                name="phone"
                                variant="outlined"
                                placeholder="Phone Number"
                                value={formikProps.values.phone}
                                onChange={formikProps.handleChange}
                                onBlur={formikProps.handleBlur}
                                error={
                                  formikProps.touched.phone &&
                                  Boolean(formikProps.errors.phone)
                                }
                                helperText={
                                  formikProps.touched.phone &&
                                  formikProps.errors.phone
                                }
                                InputLabelProps={{
                                  shrink: true,
                                }}
                              />
                            </Grid>
                          </Grid>
                        </div>
                      </AccordionDetails>
                    </Accordion>
                  </div>
                  {/* User info ends */}
                  {/* Info starts */}
                  <div className={this.props.classes.root}>
                    <Accordion
                      className={this.props.classes.accordionContainer}
                      defaultExpanded
                    >
                      <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1c-content"
                        id="panel1c-header"
                      >
                        <Typography className={"user-heading"}>
                          Information
                        </Typography>
                      </AccordionSummary>
                      <AccordionDetails className={this.props.classes.details}>
                        <Grid container spacing={4}>
                          <Grid item md={6} xs={12}>
                            <InputLabel
                              className="input-label"
                              htmlFor="add_to_branch"
                            >
                              Add to Branch
                            </InputLabel>
                            <FormControl
                              className={this.props.classes.selectFormControl}
                            >
                              <Select
                                displayEmpty
                                labelId="select-branch"
                                id="select-branch"
                                name="branchId"
                                className={this.props.classes.selectDropdown}
                                variant="outlined"
                                IconComponent={SelectArrowIcon}
                                value={formikProps.values.branchId}
                                onChange={formikProps.handleChange}
                                onBlur={formikProps.handleBlur}
                              >
                                <MenuItem value="">Select branch</MenuItem>
                                {this.state.branches.map((branch: Branch) => {
                                  return (
                                    <MenuItem value={branch.id}>
                                      {branch.name}
                                    </MenuItem>
                                  );
                                })}
                              </Select>
                            </FormControl>
                          </Grid>
                          <Grid item md={6} xs={12}>
                            <InputLabel
                              className="input-label"
                              htmlFor="add_to_team"
                            >
                              Add to Team
                            </InputLabel>
                            <FormControl
                              className={this.props.classes.selectFormControl}
                            >
                              <Select
                                displayEmpty
                                labelId="select-teams"
                                id="select-teams"
                                name="teamId"
                                className={this.props.classes.selectDropdown}
                                variant="outlined"
                                IconComponent={SelectArrowIcon}
                                value={formikProps.values.teamId}
                                onChange={formikProps.handleChange}
                                onBlur={formikProps.handleBlur}
                              >
                                <MenuItem value="">Select team</MenuItem>
                                {this.state.teams.map((team: Team) => {
                                  return (
                                    <MenuItem value={team.id}>
                                      {team.name}
                                    </MenuItem>
                                  );
                                })}
                              </Select>
                            </FormControl>
                          </Grid>
                        </Grid>
                      </AccordionDetails>
                    </Accordion>
                  </div>
                  {/* Info ends */}
                  {/* Acc info starts */}
                  <div className={this.props.classes.root}>
                    <Accordion
                      className={this.props.classes.accordionContainer}
                      defaultExpanded
                    >
                      <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1c-content"
                        id="panel1c-header"
                      >
                        <Typography className={"user-heading"}>
                          Account Information
                        </Typography>
                      </AccordionSummary>
                      <AccordionDetails className={this.props.classes.details}>
                        <div className={this.props.classes.fullWidth}>
                          <Grid container spacing={4}>
                            <Grid item md={6} xs={12}>
                              <InputLabel className="input-label">
                                Active User*
                              </InputLabel>
                              <FormControl component="fieldset">
                                <RadioGroup
                                  row
                                  aria-label="Active User"
                                  name="activeUser"
                                  value={formikProps.values.activeUser}
                                  onChange={formikProps.handleChange}
                                  onBlur={formikProps.handleBlur}
                                >
                                  <FormControlLabel
                                    value="yes"
                                    control={<Radio />}
                                    label="Yes"
                                    className={this.props.classes.radio}
                                  />
                                  <FormControlLabel
                                    value="no"
                                    control={<Radio />}
                                    label="No"
                                    className={this.props.classes.radio}
                                  />
                                </RadioGroup>
                              </FormControl>
                            </Grid>
                            <Grid item md={6} xs={12}>
                              <InputLabel
                                className="input-label"
                                htmlFor="account_expiration_date"
                              >
                                Account Expiration Date
                              </InputLabel>
                              <TextField
                                fullWidth
                                type="date"
                                id="account_expiration_date"
                                name="accExpDate"
                                variant="outlined"
                                placeholder="Account Expiration Date"
                                value={formikProps.values.accExpDate}
                                onChange={formikProps.handleChange}
                                onBlur={formikProps.handleBlur}
                                InputLabelProps={{
                                  shrink: true,
                                }}
                              />
                            </Grid>
                          </Grid>
                          <Grid container spacing={4}>
                            <Grid item md={6} xs={12}>
                              <InputLabel
                                className="input-label"
                                htmlFor="password"
                              >
                                Password*
                              </InputLabel>
                              <TextField
                                fullWidth
                                id="password"
                                name="password"
                                variant="outlined"
                                placeholder="Password"
                                value={formikProps.values.password}
                                onChange={formikProps.handleChange}
                                onBlur={formikProps.handleBlur}
                                error={
                                  formikProps.touched.password &&
                                  Boolean(formikProps.errors.password)
                                }
                                helperText={
                                  formikProps.touched.password &&
                                  formikProps.errors.password
                                }
                                InputLabelProps={{
                                  shrink: true,
                                }}
                              />
                            </Grid>
                            <Grid item md={6} xs={12}>
                              <InputLabel
                                className="input-label"
                                htmlFor="confirm_password"
                              >
                                Confirm Password*
                              </InputLabel>
                              <TextField
                                fullWidth
                                id="confirm_password"
                                name="confirmPassword"
                                variant="outlined"
                                placeholder="Confirm Password"
                                value={formikProps.values.confirmPassword}
                                onChange={formikProps.handleChange}
                                onBlur={formikProps.handleBlur}
                                error={
                                  formikProps.touched.confirmPassword &&
                                  Boolean(formikProps.errors.confirmPassword)
                                }
                                helperText={
                                  formikProps.touched.confirmPassword &&
                                  formikProps.errors.confirmPassword
                                }
                                InputLabelProps={{
                                  shrink: true,
                                }}
                              />
                            </Grid>
                          </Grid>
                        </div>
                      </AccordionDetails>
                    </Accordion>
                  </div>
                  {/* Acc info ends */}
                  {/* Application settings starts */}
                  <div className={this.props.classes.root}>
                    <Accordion
                      className={this.props.classes.accordionContainer}
                      defaultExpanded
                    >
                      <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1c-content"
                        id="panel1c-header"
                      >
                        <Typography className={"user-heading"}>
                          Application Settings
                        </Typography>
                      </AccordionSummary>
                      <AccordionDetails className={this.props.classes.details}>
                        <div className={this.props.classes.fullWidth}>
                          <Grid container spacing={4}>
                            <Grid item md={4} xs={12}>
                              <InputLabel className="input-label" htmlFor="sms">
                                Access Level*
                              </InputLabel>
                              <FormControl
                                className={this.props.classes.selectFormControl}
                              >
                                <Select
                                  labelId="select-access-level"
                                  id="select-access-level"
                                  className={this.props.classes.selectDropdown}
                                  variant="outlined"
                                  IconComponent={SelectArrowIcon}
                                  name="accessLevel"
                                  value={formikProps.values.accessLevel}
                                  onChange={formikProps.handleChange}
                                  onBlur={formikProps.handleBlur}
                                >
                                  <MenuItem value="user">Basic User</MenuItem>
                                  <MenuItem value="super_admin">
                                    Super Admin
                                  </MenuItem>
                                </Select>
                              </FormControl>
                            </Grid>
                          </Grid>
                          <Grid container spacing={4}>
                            <Grid item md={6} xs={12}>
                              <InputLabel className="input-label">
                                Training Course Reports*
                              </InputLabel>
                              <FormControl component="fieldset">
                                <RadioGroup
                                  row
                                  aria-label="Training Course Reports"
                                  name="showReports"
                                  value={formikProps.values.showReports}
                                  onChange={formikProps.handleChange}
                                  onBlur={formikProps.handleBlur}
                                >
                                  <FormControlLabel
                                    value="show"
                                    control={<Radio />}
                                    label="Show"
                                    className={this.props.classes.radio}
                                  />
                                  <FormControlLabel
                                    value="hide"
                                    control={<Radio />}
                                    label="Hide"
                                    className={this.props.classes.radio}
                                  />
                                </RadioGroup>
                              </FormControl>
                            </Grid>
                          </Grid>
                          <Grid container spacing={4}>
                            <Grid item md={6} xs={12}>
                              <InputLabel className="input-label">
                                Ability to Create/Edit Users*
                              </InputLabel>
                              <FormControl component="fieldset">
                                <RadioGroup
                                  row
                                  aria-label="Ability to Create/Edit Users"
                                  name="canManageUsers"
                                  value={formikProps.values.canManageUsers}
                                  onChange={formikProps.handleChange}
                                  onBlur={formikProps.handleBlur}
                                >
                                  <FormControlLabel
                                    value="yes"
                                    control={<Radio />}
                                    label="Yes"
                                    className={this.props.classes.radio}
                                  />
                                  <FormControlLabel
                                    value="no"
                                    control={<Radio />}
                                    label="No"
                                    className={this.props.classes.radio}
                                  />
                                </RadioGroup>
                              </FormControl>
                            </Grid>
                          </Grid>
                          <Grid container spacing={4}>
                            <Grid item md={4} xs={12}>
                              <InputLabel
                                className="input-label"
                                htmlFor="Teams"
                              >
                                Teams*
                              </InputLabel>
                              <div
                                className={
                                  this.props.classes.teamSelectContainer
                                }
                              >
                                <FormControl
                                  className={
                                    this.props.classes.selectFormControl
                                  }
                                  disabled
                                >
                                  <Select
                                    labelId="select-teams"
                                    id="select-teams"
                                    className={
                                      this.props.classes.selectDropdown
                                    }
                                    variant="outlined"
                                    value={""}
                                    IconComponent={SelectArrowIcon}
                                    displayEmpty
                                  >
                                    <MenuItem value="">Select team</MenuItem>
                                    <MenuItem value="team">team 1</MenuItem>
                                    <MenuItem value="default-screen">
                                      team 2
                                    </MenuItem>
                                    <MenuItem value="value2">team 3</MenuItem>
                                  </Select>
                                </FormControl>
                                <IconButton disabled style={{ marginLeft: 10 }}>
                                  <img src={AddIcon} alt="add icon" />
                                </IconButton>
                              </div>
                            </Grid>
                          </Grid>
                        </div>
                      </AccordionDetails>
                    </Accordion>
                  </div>
                  {/* Applicaiton settings ends */}
                  {/* Subscription starts */}
                  <div className={this.props.classes.root}>
                    <Accordion
                      className={this.props.classes.accordionContainer}
                      defaultExpanded
                    >
                      <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1c-content"
                        id="panel1c-header"
                      >
                        <Typography className={"user-heading"}>
                          Subscription Payment
                        </Typography>
                      </AccordionSummary>
                      <AccordionDetails className={this.props.classes.details}>
                        <Grid container spacing={4}>
                          <Grid item md={6} xs={12}>
                            <Button
                              disabled
                              className="download-btn"
                              onClick={(e) => this.handleClick(e)}
                            >
                              Add Subscription
                            </Button>
                          </Grid>
                        </Grid>
                        <Grid container>
                          <Grid item md={4}>
                            <Autocompletes
                              //@ts-ignore
                              openDialog={() => this.openDialog()}
                              //@ts-ignore
                              ref={this.childRef}
                            />
                          </Grid>
                        </Grid>
                      </AccordionDetails>
                    </Accordion>
                  </div>
                  {/* Subscription ends */}
                  {/* OTP starts */}
                  <div className={this.props.classes.root}>
                    <Accordion
                      className={this.props.classes.accordionContainer}
                      defaultExpanded
                    >
                      <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1c-content"
                        id="panel1c-header"
                      >
                        <Grid container spacing={2}>
                          <Grid
                            item
                            container
                            md={4}
                            xs={12}
                            justifyContent="space-between"
                            spacing={3}
                          >
                            <Grid item>
                              <Typography className={"user-heading"}>
                                One time Payment{" "}
                              </Typography>
                            </Grid>
                            <Grid item>
                              <label>
                                Total collected{" "}
                                <Chip className="custom-chip" label="$0" />
                              </label>
                            </Grid>
                          </Grid>
                          <Grid
                            item
                            container
                            md={8}
                            xs={12}
                            spacing={5}
                            className={this.props.classes.justifyEnd}
                          >
                            <Grid item>
                              <Button disabled className="download-btn">Download</Button>
                            </Grid>
                            <Grid item>
                              <Button
                                disabled
                                className="payment-btn"
                                onClick={() => this.paymentDialog()}
                              >
                                New Payment
                              </Button>
                            </Grid>
                          </Grid>
                        </Grid>
                      </AccordionSummary>
                      <AccordionDetails
                        className={this.props.classes.details}
                      ></AccordionDetails>
                    </Accordion>
                  </div>
                  {/* OTP ends */}
                  {/* Card info starts */}
                  <div className={this.props.classes.root}>
                    <Accordion
                      className={this.props.classes.accordionContainer}
                      defaultExpanded
                    >
                      <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1c-content"
                        id="panel1c-header"
                      >
                        <Typography className={"user-heading"}>
                          Card Information
                        </Typography>
                      </AccordionSummary>
                      <AccordionDetails style={{ display: "block" }}>
                        <Typography className={"user-heading"}>
                          Contracts Document
                        </Typography>
                        <br />
                        <Button
                          disabled
                          variant="outlined"
                          startIcon={<img src={UploadIcon} />}
                          className={`${this.props.classes.btn} ${this.props.classes.inviteBtn}`}
                        >
                          Upload here
                        </Button>
                      </AccordionDetails>
                    </Accordion>
                  </div>
                  {/* Card info ends */}
                  {/* Action starts */}
                  <Grid container>
                    <Grid item md={4} xs={12}></Grid>
                    <Grid
                      item
                      container
                      md={8}
                      xs={12}
                      justifyContent="space-between"
                      spacing={2}
                    >
                      <Grid item>
                        <Button
                          disableElevation
                          variant="contained"
                          type="submit"
                          className={`${this.props.classes.btn} ${this.props.classes.createUserBtn}`}
                          endIcon={
                            this.state.isLoading ? (
                              <CircularProgress
                                size={20}
                                style={{ color: "white" }}
                              />
                            ) : null
                          }
                        >
                          Create User
                        </Button>
                      </Grid>
                      <Grid item>
                        <Button
                          disabled
                          variant="outlined"
                          className={`${this.props.classes.btn} ${this.props.classes.inviteBtn}`}
                        >
                          Create invite link
                        </Button>
                      </Grid>
                    </Grid>
                  </Grid>
                  {/* Action ends */}
                  <Dialog
                    //@ts-ignore
                    open={this.state.Open}
                    onClose={() => this.openDialog()}
                    aria-labelledby="form-dialog-title"
                    className="custom-dialog"
                    fullWidth={true}
                    maxWidth={"md"}
                  >
                    {
                      //@ts-ignore
                      <DialogTitle id="customized-dialog-title">
                        <InputLabel className="input-label-heading">
                          Add New Subscription Product
                        </InputLabel>
                      </DialogTitle>
                    }

                    <DialogContent>
                      <form style={{ width: "100%" }}>
                        <Grid container spacing={4}>
                          <Grid item md={6} xs={12}>
                            <InputLabel className="input-label" htmlFor="title">
                              Title
                            </InputLabel>
                            <TextField
                              id="title"
                              name="title"
                              variant="outlined"
                              placeholder="Title"
                              InputLabelProps={{
                                shrink: true,
                              }}
                              fullWidth
                            />
                          </Grid>
                          <Grid item md={6} xs={12}>
                            <InputLabel
                              className="input-label"
                              htmlFor="description"
                            >
                              Description
                            </InputLabel>
                            <TextField
                              id="description"
                              name="description"
                              variant="outlined"
                              placeholder="Description"
                              InputLabelProps={{
                                shrink: true,
                              }}
                              fullWidth
                            />
                          </Grid>
                        </Grid>
                        <Grid container spacing={4}>
                          <Grid item md={6} xs={12}>
                            <InputLabel
                              className="input-label"
                              htmlFor="add_course"
                            >
                              Add Course
                            </InputLabel>
                            <FormControl className="select-all">
                              <Select
                                labelId="add_course"
                                id="add_course"
                                name="add_course"
                                variant="outlined"
                                IconComponent={() => null}
                                endAdornment={
                                  <InputAdornment position="end">
                                    <img src={SelectArrow} />
                                  </InputAdornment>
                                }
                                value=""
                                fullWidth
                              >
                                <MenuItem value="" selected>
                                  <em>Add Course</em>
                                </MenuItem>
                                <MenuItem value={1}>Phase 1</MenuItem>
                                <MenuItem value={2}>Phase 2</MenuItem>
                                <MenuItem value={3}>Phase 3</MenuItem>
                                <MenuItem value={4}>Phase 4</MenuItem>
                                <MenuItem value={5}>Phase 5</MenuItem>
                              </Select>
                            </FormControl>
                          </Grid>
                          <Grid item md={6} xs={12}>
                            <InputLabel
                              className="input-label"
                              htmlFor="add_program"
                            >
                              Add Program
                            </InputLabel>
                            <FormControl className="select-all">
                              <Select
                                labelId="add_program"
                                id="add_program"
                                name="add_program"
                                variant="outlined"
                                IconComponent={() => null}
                                endAdornment={
                                  <InputAdornment position="end">
                                    <img src={SelectArrow} />
                                  </InputAdornment>
                                }
                                value=""
                                fullWidth
                              >
                                <MenuItem value="" selected>
                                  <em>Add_program</em>
                                </MenuItem>
                                <MenuItem value={1}>Phase 1</MenuItem>
                                <MenuItem value={2}>Phase 2</MenuItem>
                                <MenuItem value={3}>Phase 3</MenuItem>
                                <MenuItem value={4}>Phase 4</MenuItem>
                                <MenuItem value={5}>Phase 5</MenuItem>
                              </Select>
                            </FormControl>
                          </Grid>
                        </Grid>
                        <Grid container spacing={4}>
                          <Grid item md={6} xs={12}>
                            <InputLabel
                              className="input-label"
                              htmlFor="add_content_role"
                            >
                              Add Content Role{" "}
                              <span className="optional-field">(Optional)</span>
                            </InputLabel>
                            <FormControl className="select-all">
                              <Select
                                labelId="add_content_role"
                                id="add_content_role"
                                name="add_content_role"
                                variant="outlined"
                                IconComponent={() => null}
                                endAdornment={
                                  <InputAdornment position="end">
                                    <img src={SelectArrow} />
                                  </InputAdornment>
                                }
                                value=""
                                fullWidth
                              >
                                <MenuItem value="" selected>
                                  <em>Add Content Role</em>
                                </MenuItem>
                                <MenuItem value={1}>Phase 1</MenuItem>
                                <MenuItem value={2}>Phase 2</MenuItem>
                                <MenuItem value={3}>Phase 3</MenuItem>
                                <MenuItem value={4}>Phase 4</MenuItem>
                                <MenuItem value={5}>Phase 5</MenuItem>
                              </Select>
                            </FormControl>
                          </Grid>
                          <Grid item md={6} xs={12}>
                            <InputLabel
                              className="input-label"
                              htmlFor="add_content_bundle"
                            >
                              Add Content Bundle
                            </InputLabel>
                            <FormControl className="select-all">
                              <Select
                                labelId="add_content_bundle"
                                id="add_content_bundle"
                                name="add_content_bundle"
                                variant="outlined"
                                IconComponent={() => null}
                                endAdornment={
                                  <InputAdornment position="end">
                                    <img src={SelectArrow} />
                                  </InputAdornment>
                                }
                                value=""
                                fullWidth
                              >
                                <MenuItem value="" selected>
                                  <em>Add Content Bundle</em>
                                </MenuItem>
                                <MenuItem value={1}>Phase 1</MenuItem>
                                <MenuItem value={2}>Phase 2</MenuItem>
                                <MenuItem value={3}>Phase 3</MenuItem>
                                <MenuItem value={4}>Phase 4</MenuItem>
                                <MenuItem value={5}>Phase 5</MenuItem>
                              </Select>
                            </FormControl>
                          </Grid>
                        </Grid>
                        <Grid container spacing={4}>
                          <Typography
                            className={"user-heading"}
                            style={{ padding: "15px" }}
                          >
                            Choose one option below
                          </Typography>
                          <Grid item md={12} xs={12}>
                            <div
                              className=""
                              style={{
                                display: "flex",
                                flexDirection: "column",
                              }}
                            >
                              <Box
                                className={this.props.classes.listItem}
                                key="flat_monthly_fee"
                                style={{
                                  display: "flex",
                                  alignItems: "center",
                                }}
                                // onClick={() => {setCurContent(item)}}
                              >
                                <Typography
                                  component="p"
                                  className={"input-label"}
                                >
                                  Flat monthly fee
                                </Typography>
                                <Switch
                                  // checked={state.checkedA}
                                  name="flat_monthly_fee"
                                  inputProps={{
                                    "aria-label": "Flat monthly fee",
                                  }}
                                  className={this.props.classes.switch}
                                  classes={{
                                    switchBase: this.props.classes.switchBase,
                                    track: this.props.classes.track,
                                  }}
                                />
                              </Box>
                              <Box
                                className={this.props.classes.listItem}
                                key="free_plan"
                                style={{
                                  display: "flex",
                                  alignItems: "center",
                                }}
                                // onClick={() => {setCurContent(item)}}
                              >
                                <Typography
                                  component="p"
                                  className={"input-label"}
                                >
                                  Free Plan
                                </Typography>
                                <Switch
                                  // checked={state.checkedA}
                                  name="free_plan"
                                  inputProps={{ "aria-label": "Free Plan" }}
                                  className={this.props.classes.switch}
                                  classes={{
                                    switchBase: this.props.classes.switchBase,
                                    track: this.props.classes.track,
                                  }}
                                />
                              </Box>
                            </div>
                          </Grid>
                        </Grid>
                        <Grid container spacing={4}>
                          <Grid item md={4} xs={12}>
                            <InputLabel className="input-label" htmlFor="price">
                              Enter Price
                            </InputLabel>
                            <TextField
                              id="price"
                              name="price"
                              variant="outlined"
                              placeholder="Enter Price"
                              InputLabelProps={{
                                shrink: true,
                              }}
                              fullWidth
                            />
                          </Grid>
                          <Grid item md={4} xs={12}>
                            <InputLabel
                              className="input-label"
                              htmlFor="expire_date"
                            >
                              Access Expiration Date
                            </InputLabel>
                            <TextField
                              id="expire_date"
                              name="expire_date"
                              variant="outlined"
                              placeholder="Access Expiration Date"
                              InputLabelProps={{
                                shrink: true,
                              }}
                              fullWidth
                            />
                          </Grid>

                          <Grid item md={4} xs={12}>
                            <InputLabel
                              className="input-label"
                              htmlFor="upload_contract"
                            >
                              Upload Contract
                            </InputLabel>
                            <TextField
                              id="upload_contract"
                              name="upload_contract"
                              variant="outlined"
                              placeholder="Upload Contract"
                              InputLabelProps={{
                                shrink: true,
                              }}
                              disabled={true}
                              fullWidth
                            />
                          </Grid>
                        </Grid>
                        <Grid container spacing={5}>
                          <Grid item md={4} xs={12}>
                            <InputLabel className="input-label">
                              Start Billing on
                            </InputLabel>

                            <Calendar
                              onChange={(e: any) => this.onChange(e)}
                              value={this.state.value}
                              tileClassName={({ date, view }: any) =>
                                view === "month" && date.getDay() < 6
                                  ? ""
                                  : "saturday"
                              }
                            />
                          </Grid>
                          <Grid item md={4} xs={12}>
                            <InputLabel className="input-label">
                              End Billing on (optional)
                            </InputLabel>

                            <Calendar
                              onChange={(e: any) => this.onChange(e)}
                              value={this.state.value}
                              tileClassName={({ date, view }: any) =>
                                view === "month" && date.getDay() < 6
                                  ? ""
                                  : "saturday"
                              }
                            />
                          </Grid>
                        </Grid>
                      </form>
                    </DialogContent>
                    <DialogActions
                      style={{ justifyContent: "center", textAlign: "center" }}
                    >
                      <Button
                        onClick={() => this.openDialog()}
                        className="add-product-btn"
                      >
                        Add Product
                      </Button>
                    </DialogActions>
                  </Dialog>
                  <Dialog
                    //@ts-ignore
                    open={this.state.show}
                    onClose={() => this.paymentDialog()}
                    aria-labelledby="form-dialog-title"
                    className="custom-dialog"
                    fullWidth={true}
                    maxWidth={"sm"}
                  >
                    {
                      //@ts-ignore
                      <DialogTitle id="customized-dialog-title">
                        <InputLabel className="input-label-heading">
                          One Time Payment
                        </InputLabel>
                      </DialogTitle>
                    }

                    <DialogContent>
                      <form style={{ width: "100%" }}>
                        <Grid container spacing={4}>
                          <Grid item md={12} xs={12}>
                            <InputLabel className="input-label" htmlFor="title">
                              Title
                            </InputLabel>
                            <TextField
                              id="title"
                              name="title"
                              variant="outlined"
                              placeholder="Title"
                              InputLabelProps={{
                                shrink: true,
                              }}
                              fullWidth
                            />
                          </Grid>
                        </Grid>
                        <Grid container spacing={4}>
                          <Grid item md={12} xs={12}>
                            <InputLabel
                              className="input-label"
                              htmlFor="description"
                            >
                              Description
                            </InputLabel>
                            <TextField
                              id="description"
                              name="description"
                              variant="outlined"
                              placeholder="Description"
                              InputLabelProps={{
                                shrink: true,
                              }}
                              fullWidth
                            />
                          </Grid>
                        </Grid>
                        <Grid container spacing={4}>
                          <Grid item md={12} xs={12}>
                            <InputLabel
                              className="input-label"
                              htmlFor="amount"
                            >
                              Amount
                            </InputLabel>
                            <TextField
                              id="amount"
                              name="amount"
                              variant="outlined"
                              placeholder="Enter Amount"
                              InputLabelProps={{
                                shrink: true,
                              }}
                              fullWidth
                            />
                          </Grid>
                        </Grid>

                        <Grid container spacing={4}>
                          <Grid item md={12} xs={12}>
                            <InputLabel
                              className="input-label"
                              htmlFor="upload_contract"
                            >
                              Upload Contract
                            </InputLabel>
                            <TextField
                              id="upload_contract"
                              name="upload_contract"
                              variant="outlined"
                              placeholder="Upload Contract"
                              InputLabelProps={{
                                shrink: true,
                              }}
                              disabled={true}
                              fullWidth
                            />
                          </Grid>
                        </Grid>
                        <Grid container spacing={5}>
                          <Grid item md={6} xs={12}>
                            <InputLabel className="input-label">
                              End Billing on (optional)
                            </InputLabel>

                            <Calendar
                              onChange={(e: any) => this.onChange(e)}
                              value={this.state.value}
                              tileClassName={({ date, view }: any) =>
                                view === "month" && date.getDay() < 6
                                  ? ""
                                  : "saturday"
                              }
                            />
                          </Grid>
                        </Grid>
                      </form>
                    </DialogContent>
                    <DialogActions
                      style={{ justifyContent: "center", textAlign: "center" }}
                    >
                      <Button
                        onClick={() => this.paymentDialog()}
                        className="add-product-btn"
                      >
                        Add Payment
                      </Button>
                    </DialogActions>
                  </Dialog>
                </div>
              </Form>
            );
          }}
        </Formik>
      </>
    );
  }
}

const styles = (theme: Theme) =>
  createStyles({
    root: {
      width: "100%",
      marginBottom: 20,
    },
    accordionContainer: {
      borderRadius: "18px !important",
      background: "#FFF",
      boxShadow: "1px 2px 10px 0px rgba(0, 0, 0, 0.07)",
    },
    heading: {
      fontStyle: "normal",
      fontWeight: 600,
      fontSize: "22px",
      lineHeight: "33px",
      color: "#12142B",
    },
    secondaryHeading: {
      fontSize: theme.typography.pxToRem(15),
      color: theme.palette.text.secondary,
    },
    icon: {
      verticalAlign: "bottom",
      height: 20,
      width: 20,
    },
    details: {
      alignItems: "center",
    },
    column: {
      flexBasis: "33.33%",
    },
    helper: {
      borderLeft: `2px solid ${theme.palette.divider}`,
      padding: theme.spacing(1, 2),
    },
    link: {
      color: theme.palette.primary.main,
      textDecoration: "none",
      "&:hover": {
        textDecoration: "underline",
      },
    },
    listItem: {
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
      padding: "12px 10px",
      [theme.breakpoints.up("sm")]: {
        width: "100%",
        padding: "12px 20px",
        paddingRight: 0,
      },
      [theme.breakpoints.up("md")]: {
        width: "100%",
      },
    },
    listItemTimeline: {
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
      padding: "12px 10px",
      [theme.breakpoints.up("sm")]: {
        width: "100%",
        padding: "12px 20px",
        paddingRight: 0,
      },
      [theme.breakpoints.up("md")]: {
        width: "100%",
      },
    },
    listItemText: {
      fontWeight: 500,
      fontSize: "14px",
      color: "#11142D",
      lineHeight: "20px",
    },
    switch: {
      "& .MuiSwitch-colorSecondary.Mui-checked": {
        color: "#6C328B",
      },
      "& .MuiSwitch-colorSecondary.Mui-checked + .MuiSwitch-track": {
        backgroundColor: "#C4AED0",
      },
    },
    switchBase: {
      color: "#545650",
      "&$checked": {
        color: "#6C328B",
      },
      "&$checked + $track": {
        background: "#c4aed0",
        mixBlendMode: "normal",
        opacity: "0.37",
      },
    },
    track: {
      background: "#c8cbd2",
      mixBlendMode: "normal",
      opacity: "0.37",
    },
    btn: {
      borderRadius: "18px",
      fontWeight: 600,
      fontSize: "16px",
      lineHeight: "22px",
      textAlign: "center",
      letterSpacing: "0.3px",
      textTransform: "capitalize",
    },
    createUserBtn: {
      background: "#ff8b3f",
      width: "360px",
      height: "56px",
      color: "#ffffff",
      "&:hover": {
        background: "#ff8b3f",
      },
    },
    inviteBtn: {
      background: "#fff",
      width: "185px",
      height: "56px",
      color: "#6C328B",
      border: "1.5px solid #6C328B",
    },
    selectFormControl: {
      width: "100%",
    },
    selectDropdown: {
      "& .MuiSelect-select:focus": {
        borderRadius: 18,
        background: "#fff",
      },
    },
    selectArrowIcon: {
      marginRight: 10,
    },
    teamSelectContainer: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
    },
    justifyEnd: {
      justifyContent: "flex-start",
      [theme.breakpoints.up("md")]: {
        justifyContent: "flex-end",
      },
    },
    radio: {
      "& .Mui-checked": {
        color: "#6C328E",
      },
    },
    fullWidth: {
      width: "100%",
    },
  });

export default withStyles(styles)(CreateUser);
// Customizable Area End
