Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.apiContentType = "application/json";
exports.endPoint = "notifications/notifications";
exports.getNotificationsEndpoint = "notifications/notifications";
exports.deleteNotificationsEndpoint = "account_block/account/delete_all";
exports.silentNotificationsEndpoint =
  "bx_block_notifications/cus_notification/silence_notification";
exports.remindMeEndPoint = "bx_block_coursecreation/close_popup";
exports.getRemindersEndPoint = "bx_block_coursecreation/due_date_courses";
exports.getDataMethod = "GET";
exports.postDataMethod = "POST";
exports.markAsReadMethod = "PUT";
exports.deleteMethod = "DELETE";

exports.notifications = "Notifications";
exports.deleteText = "DELETE";
exports.okText = "OK";
exports.deleteMessage = "Notifications deleted!";
// Customizable Area End
