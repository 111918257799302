Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.validationApiContentType = "application/json";
exports.validationApiMethodType = "GET";
exports.exampleAPiEndPoint = "EXAMPLE_URL";
exports.exampleAPiMethod = "POST";
exports.exampleApiContentType = "application/json";
exports.textInputPlaceHolder = "Enter Text";
exports.labelTitleText = "QuestionBank";
exports.labelBodyText = "QuestionBank Body";

exports.btnExampleTitle = "CLICK ME";
// Content types
exports.applicationJson = "application/json";
// Http methods
exports.httpGetMethod = "GET";
exports.httpPostMethod = "POST";
exports.httpPutMethod = "PUT";
exports.httpDelMethod = "DELETE";
// Create question
exports.createQuestionUrl = "bx_block_questionbank/quize_questions";
// Create quiz
exports.quizUrl = "bx_block_questionbank/quizzes";
// Bulk delete questions
exports.bulkDeleteQuestionsUrl = "bx_block_questionbank/quize_questions/bulk_delete";
// Bulk actions
exports.bulkActionUrl = "bx_block_questionbank/quize_questions/bulk_action";
// Customizable Area End