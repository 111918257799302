Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.placeHolderEmail = "Email";

exports.labelHeader = "Please enter your personal details";
exports.labelFirstName = "Please type first name";
exports.lastName = "Please type last name";
exports.labelEmail = "Please type email address";
exports.labelPassword = "Please enter password";
exports.labelRePassword = "Confirm your password";
exports.errorCountryCodeNotSelected = "Please select country code";
exports.labelMobileNo = "Please enter mobile number";
exports.errorBlankField = "can't be blank";

exports.labelLegalText =
  "By checking this option you agreed to our";

exports.labelLegalTermCondition = "Terms and Conditions";
exports.labelLegalPrivacyPolicy = "Privacy policy";
exports.btnTextSignUp = "REGISTER";
exports.errorPasswordNotValid = "Password not valid.";
exports.errorMobileNumber = "Invalid Mobile Number";

exports.errorTitle = "Error";

exports.errorBothPasswordsNotSame = "Passwords should be same.";
exports.errorAllFieldsAreMandatory = "All fields are mandatory.";

exports.contentTypeApiAddDetail = "application/json";

exports.accountsAPiEndPoint =
  "account_block/account";

exports.apiMethodTypeAddDetail = "POST";

exports.errorEmailNotValid = "Email not valid.";
exports.urlGetValidations =
  "profile/validations";
exports.urlFaqData = "/bx_block_settings/pages/terms-of-use";
exports.validationApiContentType = "application/json";
exports.xpandLogo = "XPAND";
exports.validationApiMethodType = "GET";
exports.labelReferalText = "Do you have Referral Code"
exports.HiThere = "Hi There!";
exports.titleWelcome = "Welcome to XPAND";
exports.backgroundImage = "assets/backgroundImage.png";
exports.Oval = "assets/Oval.png";
exports.perfectImage = "assets/perfectImage.png";
exports.formDataContentType = "application/json";
// Customizable Area End
