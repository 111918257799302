import React from "react";
import {
  Button, Grid, Box,
  // Customizable Area Start
  Paper, TextField, Typography
} from "@material-ui/core";
import Modal from '@material-ui/core/Modal';
import Accordion from "@material-ui/core/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import Checkbox from "@material-ui/core/Checkbox";
import ManageThumbnailLibraryController, {
  Props
} from "./ManageThumbnailLibraryController.web";
import './style.css';
// Customizable Area Start
const Vector = require("../assets/Vector.png");
const folder = require("../assets/Vector2.png");
const lock = require("../assets/lock2.png");
const bulk_lock = require("../assets/lockFolder.png")
const bulk_delete = require("../assets/trash-can.png")
const close_circle = require("../assets/close-circle.png")
const delete_icon = require("../assets/white_delete.png")
const close = require("../assets/imagenav_close.png")

// Customizable Area End


export default class ManageThumbnailLibrary extends ManageThumbnailLibraryController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start

  // Customizable Area End


  render() {
    return (
      <div>
        <Grid container>
          <Grid item lg={12} md={12} sm={12} xs={12} style={{ marginTop: '-23px' }}>
            <Typography style={{ fontSize: '48px', fontWeight: 700, }}>Manage Thumbnail Library</Typography>
          </Grid>


          <Grid container spacing={2} style={{ marginTop: '0px' }}>
            <Grid item lg={8}>
              <Typography style={{ fontSize: '20px', fontWeight: 400, color: '#979797', marginTop: '22px' }}>Folders</Typography>
            </Grid>
            <Grid item lg={2}><Button onClick={this.dialogHandleOpen} style={{ borderRadius: '12px', width: '212px', height: '56px', backgroundColor: '#653889', textTransform: 'capitalize' }} variant="contained" color="primary" >Add Thumbnails</Button></Grid>
            <Grid item lg={2}>
              <Box>
                <Accordion style={{ boxShadow: 'none', border: '1px solid #545650', borderRadius: '10px', height: '56px' }}>
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                  >
                    <Typography style={{ margin: 'auto', fontSize: '16px', fontWeight: 500 }}>Bulk Actions</Typography>
                  </AccordionSummary>
                  <AccordionDetails style={{ backgroundColor: '#fff', borderRadius: '10px', border: '1px solid #DEDEDE', marginTop: '-9px' }}>
                    <Box style={{ width: '100%' }}>
                      <Grid container>
                        <Grid item lg={12}><Grid container style={{ cursor: "pointer" }}>
                          <Grid item lg={3}><img src={bulk_lock} /></Grid>
                          <Grid item lg={9}><Typography>Lock</Typography></Grid>
                        </Grid></Grid>
                        <Grid item lg={12} style={{ marginTop: '15px' }}><Grid onClick={() => this.setState({bulkAction:true})} container style={{ cursor: "pointer" }}>
                          <Grid item lg={3}><img src={bulk_delete} /></Grid>
                          <Grid item lg={9}><Typography>Delete</Typography></Grid>
                        </Grid></Grid>
                      </Grid>
                    </Box>
                  </AccordionDetails>
                </Accordion>
              </Box>
            </Grid>
          </Grid>
          {this.state.bulkAction ?
            <Grid container style={{ marginTop: '15px' }}>
              <Grid item lg={9}></Grid>
              <Grid item lg={3}>
                <Grid container spacing={2}>
                  <Grid item lg={6}><Button onClick={() => this.bulk_delete_Action()} style={{ backgroundColor: '#653889', color: '#fff', width: '100%', height: '45px', borderRadius: '10px', display: 'flex', justifyContent: 'space-evenly', position: 'unset' }}><img src={delete_icon} /> Delete</Button></Grid>
                  <Grid item lg={6}> <Button style={{ border: '1px solid #653889', width: '100%', height: '45px', borderRadius: '10px', display: 'flex', justifyContent: 'space-evenly', position: 'unset' }} onClick={() => this.setState({bulkAction:false})}><img src={close_circle} /> Cancel</Button></Grid>
                </Grid>
              </Grid>
            </Grid> : <></>
          }


          <Modal
            open={this.state.dialogopen}
            onClose={this.dialogHandleClose}
            aria-labelledby="simple-modal-title"
            aria-describedby="simple-modal-description"
            style={{ backgroundColor: "rgba(0, 0, 0, -0.5)" }}
          >
            <div style={{
              position: 'absolute', width: '890px', height: '225px', backgroundColor: '#fff',
              margin: '12%', marginLeft: '21%', borderRadius: '15px', boxShadow: '-6px -6px 25px rgba(230, 230, 230, 0.25), 6px 6px 25px rgba(229, 229, 229, 0.25)'

            }}>
              <img src={close} onClick={this.dialogHandleClose} style={{ float: 'right', marginTop: '20px', marginRight: '20px' }} />
              <Typography style={{ fontSize: '20px', fontWeight: 600, marginTop: '20px', marginLeft: '25px' }}>Create New Folder</Typography>
              <Grid container spacing={4} style={{ marginLeft: '30px', marginTop: '25px' }}>
                <Grid item lg={9}>
                  <TextField style={{ borderRadius: '16px', width: '665px' }} value={this.state.createFolder} onChange={(event: any) => this.setState({ createFolder: event.target.value })} placeholder="Type the name of the folder" variant="outlined" />
                </Grid>
                <Grid item lg={3}>
                  <Button onClick={() => this.createNewFolder()} style={{ borderRadius: '18px', width: '130px', height: '56px', backgroundColor: '#653889' }} variant="contained" color="primary" >Create</Button>
                </Grid>

              </Grid>
            </div>
          </Modal>


          <Grid container spacing={2} style={{ marginTop: '10px' }}>
            {this.state.folderData?.data?.map((data: any, index: any) => (
              <>
                <Grid key={index.id} item lg={3}><Paper style={{ width: '295px', height: '68px', backgroundColor: '#fff', border: '1px solid rgba(0, 0, 0, 0.25)', boxShadow: 'rgb(230 230 230 / 25%) -6px -6px 25px, rgb(230 232 236) 6px 6px 25px', borderRadius: '10px' }}>
                  <Grid container style={{ padding: '15px' }}>
                   {this.state.bulkAction ? <Grid item lg={1} style={{margin:'auto'}}><Checkbox style={{color:'#6C328B'}} 
                  //  checked={this.state.checked}
        onChange={() => this.handleChange(data)} inputProps={{ "aria-label": "secondary checkbox" }}  /></Grid> :<></>}
                    <Grid item lg={2} style={{ margin: 'auto',marginLeft:'10px' }}>
                      <>
                       <img src={folder} />
                      </>
                    </Grid>
                    <Grid item lg={8} style={{ margin: 'auto' }}>
                      <Typography onClick={() => this.handleNavigate(data)} style={{ cursor: 'Pointer', fontSize: '14px', fontWeight: 600, color: '#222836', marginLeft: '0px', marginTop: '0px', textTransform: 'capitalize' }}>{data.name}</Typography>
                    </Grid>
                    <Grid item lg={1} style={{ margin: 'auto' }}>
                      {data.is_lock ? <img style={{ marginLeft: '8px' }} src={lock} /> : <></>}
                      {/*  <img style={{ marginLeft: '8px' }} src={lock} /> */}
                    </Grid>
                  </Grid>
                </Paper></Grid>
              </>
            ))}

          </Grid>

        </Grid>
      </div>
    )

  }

}
