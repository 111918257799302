Object.defineProperty(exports, "__esModule", {
  value: true,
});

// Customizable Area Start
exports.validationApiContentType = "application/json";
exports.validationApiMethodType = "GET";
exports.exampleAPiEndPoint = "EXAMPLE_URL";
exports.exampleAPiMethod = "POST";
exports.PutMethod ="PUT"
exports.PostMethod ="POST";
exports.DeleteMethod = "DELETE";
exports.exampleApiContentType = "application/json";
exports.textInputPlaceHolder = "Enter Text";
exports.labelTitleText = "CourseCreation";
exports.labelBodyText = "CourseCreation Body";
exports.createNotesAPiMethod = "POST";
exports.editNotesAPiMethod = "PUT";
exports.allNotesAPiEndPoint = "bx_block_library2/notes";
exports.btnExampleTitle = "CLICK ME";
exports.categoryFetchAPiEndPoint = "bx_block_categories/categories";
exports.createCourseAPiEndPoint = "bx_block_coursecreation/courses";
exports.createProgramAPiEndPoint = "bx_block_coursecreation/programs";
exports.getProgramDetailApiEndPoint = "bx_block_coursecreation/programs";
exports.getCourseDetailApiEndPoint = "bx_block_coursecreation/courses";
exports.updateProgramDetailApiEndPoint = "bx_block_coursecreation/programs";
exports.updateCourseDetailApiEndPoint = "bx_block_coursecreation/courses";
exports.getProgramListApiEndPoint = "bx_block_coursecreation/program_list";
exports.getIconsApiEndPoint = "bx_block_attachment/icons";
exports.reorderPhaseContetnsApiEndPoint =
  "bx_block_coursecreation/change_sequence";
exports.addRewardsApiEndPoint = "bx_block_library2/rewards";
exports.addAchievmentApiEndPoint = "bx_block_library2/achievements";
exports.getAdminNotificationList =
  "bx_block_coursecreation/notification_admin_list";
exports.getContentRoleListApiEndPoint = "bx_block_coursecreation/content_roles";
exports.createFolderApiEndPoint = "bx_block_admin/folder";
exports.getFolderApiEndPoint = "/bx_block_admin/folder/";
exports.getDeleteVideosApiEndPoint = 'bx_block_coursecreation/course_videos';
exports.getDeleteQuizApiEndPoint = 'bx_block_coursecreation/course_quizzes';
exports.getTagListApiEndPoint = 'bx_block_library2/custom_tags';
exports.createObjectApiEndPoint = 'bx_block_library2/program_objects';
exports.getdeleteObjectApiEndPoint = 'bx_block_coursecreation/program_objects';
exports.getdeleteCourseApiEndPoint = 'bx_block_coursecreation/program_courses';
exports.getExistingCourseListApiEndPoint = 'bx_block_coursecreation/collection_lists/courses';
exports.getRewardListApiEndPoint  = 'bx_block_library2/rewards';
exports.getAchievementListApiEndPoint = 'bx_block_library2/achievements';

exports.btnExampleTitle = "CLICK ME";
// Customizable Area End
