import React, { Component } from "react";
import { Grid, TablePagination } from "@material-ui/core";
import Chip from "@material-ui/core/Chip";
import './index.css';
//@ts-ignore
import upArrow from "./upArrow.svg";
//@ts-ignore
import downArrow from "./downArrow.svg";
//@ts-ignore
import path from "./Path.svg";
export interface Props {
  tableHeading: any;
  tableRows: any;
  sort: any;
  selected: any;
  getExistingCourseList:any;
  meta:any;
}
export interface S {
  page: number;
  rowsPerPage: number;
}
class CustomTable extends Component<Props, S> {
  constructor(props: any) {
    super(props);
    this.state = {
      page: 0,
      rowsPerPage: 10
    }
  }
  handleChangePage = (event: React.MouseEvent<HTMLButtonElement> | null, newPage: number) => {
    this.setState({ page: newPage });
  };
  handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) => {
    this.setState({ rowsPerPage: parseInt(event.target.value, 10) });
    this.setState({ page: 1 });
  };
  componentDidUpdate(prevProps:any,prevState:any) {
    if (this.state.page !== prevState.page || this.state.rowsPerPage!== prevState.rowsPerPage) {
      this.props.getExistingCourseList({search:"",filter:"",page:this.state.page,per:this.state.rowsPerPage})
    }
  }
  render() {
    return (
      <>
        <Grid container>
          <Grid item md={12} xs={12}>
            <div className="mainContainer" id="content-role-table">
              <table className="tableContainer">
                <tr className="tableHeading-container">
                  {/* <th style={{ width: "70px" }} /> */}
                  {this.props?.tableHeading?.map((item: any) => (
                    <>
                      {item.label === "" ? (
                        <th style={{ width: "40px" }} />
                      ) : (
                        <th style={{ width: "100px" }}>
                          <p
                            style={{
                              display: "flex",
                              flexDirection: "row",
                              justifyContent: "center",
                              marginLeft: "10px"
                            }}
                          >
                            {item.label}{" "}
                            <span
                              className=""
                              style={{
                                display: "flex",
                                flexDirection: "column",
                                paddingLeft: "10px",
                                marginTop: "5px"
                              }}
                            >
                              {item.sort ? (
                                <>
                                  <img
                                    src={upArrow}
                                    width="15px"
                                    onClick={() =>
                                      this.props.sort(
                                        "asc",
                                        item.label.toLowerCase()
                                      )
                                    }
                                    style={{ paddingBottom: "1px" }}
                                  />

                                  <img
                                    src={downArrow}
                                    width="15px"
                                    onClick={() =>
                                      this.props.sort(
                                        "dec",
                                        item.label.toLowerCase()
                                      )
                                    }
                                  />
                                </>
                              ) : (
                                ""
                              )}
                            </span>
                          </p>
                        </th>
                      )}
                    </>
                  ))}
                </tr>
                {this.props?.tableRows?.map((val: any, index: number) => {
                  return (
                    <tr className={"tableColumn-container"} onClick={() => { this.props.selected(index) }}>
                      {Object.keys(val)?.map((key) => {
                        if (
                          key === "type" &&
                          Array.isArray(val[key]) &&
                          val[key].length > 0
                        ) {
                          return val[key].map((item: any) => {
                            if (item.value === "checkbox") {
                              return (
                                <td style={{ width: "40px" }} className={val.is_selected ? "selected-item":""}>
                                  <span style={{ position: "relative" }}>
                                    <input id={`id-${index}`} type="checkbox"
                                      checked={val.is_selected}
                                      style={{
                                        width: item["size"],
                                        height: item["size"]
                                      }}
                                    />
                                    <label htmlFor={`id-${index}`} className="block" />
                                  </span>

                                </td>
                              );
                            } else if (item.value === "icon") {
                              return (
                                <td style={{ width: "40px" }} className={val.is_selected ? "selected-item":""}>
                                  <img
                                    src={item.url}
                                    width={item["size"]}
                                    height={item["size"]}
                                  />
                                </td>
                              );
                            }

                          });
                        } else if (
                          key === "actions" &&
                          Array.isArray(val[key]) &&
                          val[key].length > 0
                        ) {
                          return val[key].map((item: any) => {
                            if (item.value === "checkbox") {
                              return (
                                <td style={{ width: "40px" }} className={val.is_selected ? "selected-item":""}>
                                  <input
                                    type="checkbox"
                                    style={{
                                      width: item["size"],
                                      height: item["size"]
                                    }}
                                  />{" "}
                                </td>
                              );
                            } else if (item.value === "icon") {
                              return (
                                <td style={{ width: "40px" }} className={val.is_selected ? "selected-item":""}>
                                  <img
                                    src={item.url}
                                    width={item["size"]}
                                    height={item["size"]}
                                  />
                                </td>
                              );
                            }
                            else if (item.value === "chip") {
                              return (
                                <td style={{ width: "40px" }} className={val.is_selected ? "selected-item":""}>
                                  <Chip
                                    variant="outlined"
                                    size="small"
                                    label={item.status}
                                  />
                                </td>
                              );

                            }
                           
                          });
                        }
                        else if (key === "id" || key === "is_selected") {
                          return ""
                        }
                        else {
                          if(val[key].value === ""){
                            return <td style={{ width: val[key]?.size}} className={val.is_selected ? "selected-item":""}>
                                    <img
                                    src={path}
                                    width="20px"
                                  />

                            </td>;

                             }
                             else{
                            return <td className={val.is_selected ? "selected-item":""} style={{ width: val[key]?.size}}><p style={{fontSize:"15px",fontWeight:400,color: "#222836",fontStyle:"normal"}}>{val[key].value}</p></td>;

                          }
                        }
                      })}
                    </tr>
                  );
                })}
              </table>
              <TablePagination
                component="div"
                count={this.props.meta !== "" ? this.props.meta.pagination?.total_count + 1: this.props.tableRows.length}
                page={this.state.page}
                onPageChange={this.handleChangePage}
                rowsPerPage={this.state.rowsPerPage}
                onRowsPerPageChange={this.handleChangeRowsPerPage}
              />
            </div>
          </Grid>
        </Grid>
      </>
    );
  }
}
export default CustomTable;
