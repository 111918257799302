Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.validationApiContentType = "application/json";
exports.validationApiMethodType = "GET";
exports.exampleAPiEndPoint = "EXAMPLE_URL";
exports.exampleAPiMethod = "POST";
exports.exampleApiContentType = "application/json";
exports.textInputPlaceHolder = "Enter Text";
exports.labelTitleText = "UserGroups";
exports.labelBodyText = "UserGroups Body";
exports.expertsSkipAPIEndPoint = "/bx_block_categories/categories/skip_expert";
exports.errorExperts = "Please select a category first"
exports.errorTitle = "Error"
exports.expertsAddAPIEndPoint = "account_block/account/create_user_expert";
exports.achievementListingAPiEndPoint='bx_block_customisableuserprofiles/profiles/achievements';
exports.achievementDetailsAPiEndPoint='bx_block_customisableuserprofiles/profiles/achievement_details?id=';
exports.allrewardsListingAPiEndPoint='bx_block_customisableuserprofiles/profiles/rewards';
exports.allrewardsDetailsAPiEndPoint='bx_block_customisableuserprofiles/profiles/reward_details?id=';
exports.btnExampleTitle = "CLICK ME";
// Customizable Area End