// Customizable Area Start
import React from 'react';
import {
    Card,
    CardContent,
    Paper,
    Grid,
    CardHeader,
    Breadcrumbs,
    FormControl,
    Select,
    InputAdornment,
    MenuItem,
    Switch,
    Button,
    TablePagination
} from '@material-ui/core';
import { createTheme, ThemeProvider,createStyles,Theme ,withStyles} from "@material-ui/core/styles";
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import {Link} from 'react-router-dom';
import CourseCreationController from './CourseCreationController.web';
import './CourseCreation.css';
import { Caret1, Caret2, CopiesIcon, Delete, Download, Group2, Group4, Group7, Group8, MenuIcon } from './assets';
class IconLibrary extends CourseCreationController{
constructor(props:any){
    super(props);
}
toggle(e:any){
    if(e.target.name === "view"){
        this.setState({toggle:e.target.checked})
    }
}
handleChangePage = (event: React.MouseEvent<HTMLButtonElement> | null, newPage: number) => {
    this.setState({page:newPage});
  };
  handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) => {
    this.setState({rowsPerPage:parseInt(event.target.value, 10)});
    this.setState({page:0});
  };
render(){
    return (<>
    <div className="icon-library">
        <Grid container md={12} xs={12} style={{padding:"15px 0 15px 0px"}}>
            <Grid item md={2} xs={12} style={{padding:"15px"}}>
            <Breadcrumbs separator={<NavigateNextIcon fontSize="small" />} aria-label="breadcrumb">
        <Link to={'/folder'} className="folder">
          Folders
        </Link>
        <Link className='folder' to={"/getting-started/installation/"}>
          Rewards
        </Link>
        
      </Breadcrumbs>
            </Grid>
            <Grid item md={2} xs={12} style={{padding:"6px"}}>
            <FormControl className="branch-select">
        <Select
          labelId="demo-controlled-open-select-label"
          id="demo-controlled-open-select"
          name='branch_name'
          variant="outlined"
          IconComponent={() => null}
          endAdornment={
            <InputAdornment position="end">
            <img src={Caret1} />
            </InputAdornment>
  
          }
          fullWidth> 
          <MenuItem value="">
            <em>None</em>
          </MenuItem>
          <MenuItem value={1}>Phase 1</MenuItem>
          <MenuItem value={2}>Phase 2</MenuItem>
          <MenuItem value={3}>Phase 3</MenuItem>
          <MenuItem value={4}>Phase 4</MenuItem>
          <MenuItem value={5}>Phase 5</MenuItem>
        </Select>
        {/* <p className='invalid-feedback'>{touched.phase && errors.phase}</p> */}
            </FormControl>
            </Grid>
            <Grid item md={2} xs={12} style={{padding:"6px"}}>
                      <FormControl className="select-filter">
        <Select
          labelId="demo-controlled-open-select-label"
          id="demo-controlled-open-select"
          value={"-----"}
            endAdornment={
              <InputAdornment position="end">
              <img src={Caret2} />
              </InputAdornment>
    
            }
         startAdornment={
           <InputAdornment position="start" className="filter-label">Filter By:</InputAdornment>
         }
         IconComponent={() => null}
         variant="outlined"
         fullWidth
> 
          <MenuItem value="">
            <em>None</em>
          </MenuItem>
          <MenuItem value={10}>Ten</MenuItem>
          <MenuItem value={20}>Twenty</MenuItem>
          <MenuItem value={30}>Thirty</MenuItem>
        </Select>
      </FormControl>
            </Grid>
            <Grid item md={2} xs={12}>

            </Grid>
            <Grid item md={2} xs={12}>
             <div className='toggle-content'>
                <img 
                src={MenuIcon}
                />
                <p className='toggle-text'>{!this.state.toggle ? 'Grid View' : 'List View'}</p>

             <Switch
          name="view"
          //@ts-ignore
          checked={this.state.toggle}
          onChange={(e) => this.toggle(e)}
          inputProps={{ 'aria-label': "view" }}
          className={this.props.classes.switch}
          classes={{
            switchBase: this.props.classes.switchBase,
            track: this.props.classes.track,
          }}
        />
             </div>
            
            </Grid>
            <Grid item md={2} xs={12} style={{padding:"6px",textAlign:"center"}}>
                <Button className='btn-add-icon'>Add Icon</Button>
            </Grid>

        </Grid>
        {!this.state.toggle ? <>
            <Grid container md={12} xs={12} spacing={4}>
            <Grid item  md={6} xs={12}>
                <Card>
                    <CardHeader component='p' title={'Uploaded Icons'}/>
                    <CardContent>
                        <Grid container md={12} xs={12} spacing={2}>
                            <Grid item md={3} xs={12}>
                            <Paper elevation={10} className="paper-container">
                            <div className='paper-content'>
                                <img className='icon-svg' src={Group2} />
                            </div>
                            <p className='paper-text'><span style={{marginRight:"15px"}} ><img className='copies-icon' src={CopiesIcon} /></span>&nbsp;Super Monthly Saving</p>
                            </Paper>
                            

                                
                            </Grid>
                            <Grid item md={3} xs={12}>
                            <Paper elevation={10} className="paper-container">
                            <div className='paper-content'>
                                <img className='icon-svg' src={Group4} />
                            </div>
                            <p className='paper-text'><span style={{marginRight:"15px"}}><img className='copies-icon' src={CopiesIcon} /></span>&nbsp;Achieved sales target</p>
                            </Paper>

                                
                            </Grid>
                            <Grid item md={3} xs={12}>
                            <Paper elevation={10} className="paper-container">
                            <div className='paper-content'>
                                <img className='icon-svg' src={Group7} />
                            </div>
                            <p className='paper-text'><span style={{marginRight:"15px"}}><img className='copies-icon' src={CopiesIcon} /></span>&nbsp;Completed marathon </p>
                            </Paper>

                                
                            </Grid>
                            <Grid item md={3} xs={12}>
                            <Paper elevation={10} className="paper-container">
                            <div className='paper-content'>
                                <img className='icon-svg' src={Group8} />
                            </div>
                            <p className='paper-text'><span style={{marginRight:"15px"}}><img className='copies-icon' src={CopiesIcon} /></span>&nbsp;Reward name 2</p>
                            </Paper>

                                
                            </Grid>
                        </Grid>
                        <Grid container md={12} xs={12} spacing={2}>
                            <Grid item md={3} xs={12}>
                            <Paper elevation={10} className="paper-container">
                            <div className='paper-content'>
                                <img className='icon-svg' src={Group2} />
                            </div>
                            <p className='paper-text'><span style={{marginRight:"15px"}} ><img className='copies-icon' src={CopiesIcon} /></span>&nbsp;Super Monthly Saving</p>
                            </Paper>
                            

                                
                            </Grid>
                            <Grid item md={3} xs={12}>
                            <Paper elevation={10} className="paper-container">
                            <div className='paper-content'>
                                <img className='icon-svg' src={Group4} />
                            </div>
                            <p className='paper-text'><span style={{marginRight:"15px"}}><img className='copies-icon' src={CopiesIcon} /></span>&nbsp;Achieved sales target</p>
                            </Paper>

                                
                            </Grid>
                            <Grid item md={3} xs={12}>
                            <Paper elevation={10} className="paper-container">
                            <div className='paper-content'>
                                <img className='icon-svg' src={Group7} />
                            </div>
                            <p className='paper-text'><span style={{marginRight:"15px"}}><img className='copies-icon' src={CopiesIcon} /></span>&nbsp;Completed marathon </p>
                            </Paper>

                                
                            </Grid>
                            <Grid item md={3} xs={12}>
                            <Paper elevation={10} className="paper-container">
                            <div className='paper-content'>
                                <img className='icon-svg' src={Group8} />
                            </div>
                            <p className='paper-text'><span style={{marginRight:"15px"}}><img className='copies-icon' src={CopiesIcon} /></span>&nbsp;Reward name 2</p>
                            </Paper>

                                
                            </Grid>
                        </Grid>
                        <Grid container md={12} xs={12} spacing={2}>
                            <Grid item md={3} xs={12}>
                            <Paper elevation={10} className="paper-container">
                            <div className='paper-content'>
                                <img className='icon-svg' src={Group2} />
                            </div>
                            <p className='paper-text'><span style={{marginRight:"15px"}} ><img className='copies-icon' src={CopiesIcon} /></span>&nbsp;Super Monthly Saving</p>
                            </Paper>
                            

                                
                            </Grid>
                            <Grid item md={3} xs={12}>
                            <Paper elevation={10} className="paper-container">
                            <div className='paper-content'>
                                <img className='icon-svg' src={Group4} />
                            </div>
                            <p className='paper-text'><span style={{marginRight:"15px"}}><img className='copies-icon' src={CopiesIcon} /></span>&nbsp;Achieved sales target</p>
                            </Paper>

                                
                            </Grid>
                            <Grid item md={3} xs={12}>
                            <Paper elevation={10} className="paper-container">
                            <div className='paper-content'>
                                <img className='icon-svg' src={Group7} />
                            </div>
                            <p className='paper-text'><span style={{marginRight:"15px"}}><img className='copies-icon' src={CopiesIcon} /></span>&nbsp;Completed marathon </p>
                            </Paper>

                                
                            </Grid>
                            <Grid item md={3} xs={12}>
                            <Paper elevation={10} className="paper-container">
                            <div className='paper-content'>
                                <img className='icon-svg' src={Group8} />
                            </div>
                            <p className='paper-text'><span style={{marginRight:"15px"}}><img className='copies-icon' src={CopiesIcon} /></span>&nbsp;Reward name 2</p>
                            </Paper>

                                
                            </Grid>
                        </Grid>
                        <Grid container md={12} xs={12} spacing={2}>
                            <Grid item md={3} xs={12}>
                            <Paper elevation={10} className="paper-container">
                            <div className='paper-content'>
                                <img className='icon-svg' src={Group2} />
                            </div>
                            <p className='paper-text'><span style={{marginRight:"15px"}} ><img className='copies-icon' src={CopiesIcon} /></span>&nbsp;Super Monthly Saving</p>
                            </Paper>
                            

                                
                            </Grid>
                            <Grid item md={3} xs={12}>
                            <Paper elevation={10} className="paper-container">
                            <div className='paper-content'>
                                <img className='icon-svg' src={Group4} />
                            </div>
                            <p className='paper-text'><span style={{marginRight:"15px"}}><img className='copies-icon' src={CopiesIcon} /></span>&nbsp;Achieved sales target</p>
                            </Paper>

                                
                            </Grid>
                            <Grid item md={3} xs={12}>
                            <Paper elevation={10} className="paper-container">
                            <div className='paper-content'>
                                <img className='icon-svg' src={Group7} />
                            </div>
                            <p className='paper-text'><span style={{marginRight:"15px"}}><img className='copies-icon' src={CopiesIcon} /></span>&nbsp;Completed marathon </p>
                            </Paper>

                                
                            </Grid>
                            <Grid item md={3} xs={12}>
                            <Paper elevation={10} className="paper-container">
                            <div className='paper-content'>
                                <img className='icon-svg' src={Group8} />
                            </div>
                            <p className='paper-text'><span style={{marginRight:"15px"}}><img className='copies-icon' src={CopiesIcon} /></span>&nbsp;Reward name 2</p>
                            </Paper>

                                
                            </Grid>
                        </Grid>
                        

                    </CardContent>
                </Card>

            </Grid>
            <Grid item md={6} xs={12}>
            <Card>
                    <CardHeader component='p' title={'Pre-Loaded Icons'}/>
                    <CardContent>
                    <Grid container md={12} xs={12} spacing={2}>
                            <Grid item md={3} xs={12}>
                            <Paper elevation={10} className="paper-container">
                            <div className='paper-content'>
                                <img className='icon-svg' src={Group2} />
                            </div>
                            <p className='paper-text'><span style={{marginRight:"15px"}} ><img className='copies-icon' src={CopiesIcon} /></span>&nbsp;Super Monthly Saving</p>
                            </Paper>
                            

                                
                            </Grid>
                            <Grid item md={3} xs={12}>
                            <Paper elevation={10} className="paper-container">
                            <div className='paper-content'>
                                <img className='icon-svg' src={Group4} />
                            </div>
                            <p className='paper-text'><span style={{marginRight:"15px"}}><img className='copies-icon' src={CopiesIcon} /></span>&nbsp;Achieved sales target</p>
                            </Paper>

                                
                            </Grid>
                            <Grid item md={3} xs={12}>
                            <Paper elevation={10} className="paper-container">
                            <div className='paper-content'>
                                <img className='icon-svg' src={Group7} />
                            </div>
                            <p className='paper-text'><span style={{marginRight:"15px"}}><img className='copies-icon' src={CopiesIcon} /></span>&nbsp;Completed marathon </p>
                            </Paper>

                                
                            </Grid>
                            <Grid item md={3} xs={12}>
                            <Paper elevation={10} className="paper-container">
                            <div className='paper-content'>
                                <img className='icon-svg' src={Group8} />
                            </div>
                            <p className='paper-text'><span style={{marginRight:"15px"}}><img className='copies-icon' src={CopiesIcon} /></span>&nbsp;Reward name 2</p>
                            </Paper>

                                
                            </Grid>
                    </Grid>
                    <Grid container md={12} xs={12} spacing={2}>
                            <Grid item md={3} xs={12}>
                            <Paper elevation={10} className="paper-container">
                            <div className='paper-content'>
                                <img className='icon-svg' src={Group2} />
                            </div>
                            <p className='paper-text'><span style={{marginRight:"15px"}} ><img className='copies-icon' src={CopiesIcon} /></span>&nbsp;Super Monthly Saving</p>
                            </Paper>
                            

                                
                            </Grid>
                            <Grid item md={3} xs={12}>
                            <Paper elevation={10} className="paper-container">
                            <div className='paper-content'>
                                <img className='icon-svg' src={Group4} />
                            </div>
                            <p className='paper-text'><span style={{marginRight:"15px"}}><img className='copies-icon' src={CopiesIcon} /></span>&nbsp;Achieved sales target</p>
                            </Paper>

                                
                            </Grid>
                            <Grid item md={3} xs={12}>
                            <Paper elevation={10} className="paper-container">
                            <div className='paper-content'>
                                <img className='icon-svg' src={Group7} />
                            </div>
                            <p className='paper-text'><span style={{marginRight:"15px"}}><img className='copies-icon' src={CopiesIcon} /></span>&nbsp;Completed marathon </p>
                            </Paper>

                                
                            </Grid>
                            <Grid item md={3} xs={12}>
                            <Paper elevation={10} className="paper-container">
                            <div className='paper-content'>
                                <img className='icon-svg' src={Group8} />
                            </div>
                            <p className='paper-text'><span style={{marginRight:"15px"}}><img className='copies-icon' src={CopiesIcon} /></span>&nbsp;Reward name 2</p>
                            </Paper>

                                
                            </Grid>
                        </Grid>
                        <Grid container md={12} xs={12} spacing={2}>
                            <Grid item md={3} xs={12}>
                            <Paper elevation={10} className="paper-container">
                            <div className='paper-content'>
                                <img className='icon-svg' src={Group2} />
                            </div>
                            <p className='paper-text'><span style={{marginRight:"15px"}} ><img className='copies-icon' src={CopiesIcon} /></span>&nbsp;Super Monthly Saving</p>
                            </Paper>
                            

                                
                            </Grid>
                            <Grid item md={3} xs={12}>
                            <Paper elevation={10} className="paper-container">
                            <div className='paper-content'>
                                <img className='icon-svg' src={Group4} />
                            </div>
                            <p className='paper-text'><span style={{marginRight:"15px"}}><img className='copies-icon' src={CopiesIcon} /></span>&nbsp;Achieved sales target</p>
                            </Paper>

                                
                            </Grid>
                            <Grid item md={3} xs={12}>
                            <Paper elevation={10} className="paper-container">
                            <div className='paper-content'>
                                <img className='icon-svg' src={Group7} />
                            </div>
                            <p className='paper-text'><span style={{marginRight:"15px"}}><img className='copies-icon' src={CopiesIcon} /></span>&nbsp;Completed marathon </p>
                            </Paper>

                                
                            </Grid>
                            <Grid item md={3} xs={12}>
                            <Paper elevation={10} className="paper-container">
                            <div className='paper-content'>
                                <img className='icon-svg' src={Group8} />
                            </div>
                            <p className='paper-text'><span style={{marginRight:"15px"}}><img className='copies-icon' src={CopiesIcon} /></span>&nbsp;Reward name 2</p>
                            </Paper>

                                
                            </Grid>
                        </Grid>
                        <Grid container md={12} xs={12} spacing={2}>
                            <Grid item md={3} xs={12}>
                            <Paper elevation={10} className="paper-container">
                            <div className='paper-content'>
                                <img className='icon-svg' src={Group2} />
                            </div>
                            <p className='paper-text'><span style={{marginRight:"15px"}} ><img className='copies-icon' src={CopiesIcon} /></span>&nbsp;Super Monthly Saving</p>
                            </Paper>
                            

                                
                            </Grid>
                            <Grid item md={3} xs={12}>
                            <Paper elevation={10} className="paper-container">
                            <div className='paper-content'>
                                <img className='icon-svg' src={Group4} />
                            </div>
                            <p className='paper-text'><span style={{marginRight:"15px"}}><img className='copies-icon' src={CopiesIcon} /></span>&nbsp;Achieved sales target</p>
                            </Paper>

                                
                            </Grid>
                            <Grid item md={3} xs={12}>
                            <Paper elevation={10} className="paper-container">
                            <div className='paper-content'>
                                <img className='icon-svg' src={Group7} />
                            </div>
                            <p className='paper-text'><span style={{marginRight:"15px"}}><img className='copies-icon' src={CopiesIcon} /></span>&nbsp;Completed marathon </p>
                            </Paper>

                                
                            </Grid>
                            <Grid item md={3} xs={12}>
                            <Paper elevation={10} className="paper-container">
                            <div className='paper-content'>
                                <img className='icon-svg' src={Group8} />
                            </div>
                            <p className='paper-text'><span style={{marginRight:"15px"}}><img className='copies-icon' src={CopiesIcon} /></span>&nbsp;Reward name 2</p>
                            </Paper>

                                
                            </Grid>
                        </Grid>

                    </CardContent>
                </Card>


            </Grid>
        </Grid>
        
        </>:
        <>
        <Grid container md={12} xs={12} spacing={4}>
        <Grid item  md={6} xs={12}>
                <Card>
                    <CardHeader component='p' title={'Uploaded Icons'}/>
                    <CardContent>
                    <div style={{overflowX:"auto"}}>
                    <table className="tableContainer">
            <tr className="tableHeading-container">
              <th style={{textAlign:"start"}}>Name</th>
              <th>Created By</th>
              <th>Download</th>
            </tr>
            <tr className="tableColumn-container">
              <td>Achievement.png</td>
              <td>John Doe</td>
              <td>
                <img src={Download} />

              </td>
              <td>
                <img src={Delete} />
              </td>
            </tr>
            <tr className="tableColumn-container">
              <td>Achievement.png</td>
              <td>John Doe</td>
              <td>
                <img src={Download} />

              </td>
              <td>
                <img src={Delete} />
              </td>
            </tr>
            <tr className="tableColumn-container">
              <td>Achievement.png</td>
              <td>John Doe</td>
              <td>
                <img src={Download} />

              </td>
              <td>
                <img src={Delete} />
              </td>
            </tr>
            <tr className="tableColumn-container">
              <td>Achievement.png</td>
              <td>John Doe</td>
              <td>
                <img src={Download} />

              </td>
              <td>
                <img src={Delete} />
              </td>
            </tr>

            <tr className="tableColumn-container">
              <td>Achievement.png</td>
              <td>John Doe</td>
              <td>
              <img src={Download} />
              </td>
              <td>
              <img src={Delete} />
              </td>
            </tr>
                    </table>
                    </div>
                    
                    <TablePagination
      component="div"
      count={100}
      page={this.state.page}
      onPageChange={this.handleChangePage}
      rowsPerPage={this.state.rowsPerPage}
      onRowsPerPageChange={this.handleChangeRowsPerPage}
    />
                    </CardContent>
                    </Card>
        </Grid>
        <Grid item  md={6} xs={12}>
                <Card>
                    <CardHeader component='p' title={'Pre-Loaded Icons'}/>
                    <CardContent>
                    <div style={{overflowX:"auto"}}>
                    <table className="tableContainer">
            <tr className="tableHeading-container">
              <th style={{textAlign:"start"}}>Name</th>
              <th>Created By</th>
              <th>Download</th>
            </tr>
            <tr className="tableColumn-container">
              <td>Achievement.png</td>
              <td>John Doe</td>
              <td>
                <img src={Download} />

              </td>
              <td>
                <img src={Delete} />
              </td>
            </tr>
            <tr className="tableColumn-container">
              <td>Achievement.png</td>
              <td>John Doe</td>
              <td>
                <img src={Download} />

              </td>
              <td>
                <img src={Delete} />
              </td>
            </tr>
            <tr className="tableColumn-container">
              <td>Achievement.png</td>
              <td>John Doe</td>
              <td>
                <img src={Download} />

              </td>
              <td>
                <img src={Delete} />
              </td>
            </tr>
            <tr className="tableColumn-container">
              <td>Achievement.png</td>
              <td>John Doe</td>
              <td>
                <img src={Download} />

              </td>
              <td>
                <img src={Delete} />
              </td>
            </tr>

            <tr className="tableColumn-container">
              <td>Achievement.png</td>
              <td>John Doe</td>
              <td>
              <img src={Download} />
              </td>
              <td>
              <img src={Delete} />
              </td>
            </tr>
                    </table>
                    </div>
                    <TablePagination
      component="div"
      count={100}
      page={this.state.page}
      onPageChange={this.handleChangePage}
      rowsPerPage={this.state.rowsPerPage}
      onRowsPerPageChange={this.handleChangeRowsPerPage}
    />
                    </CardContent>
                    </Card>
        </Grid>
    
    
        </Grid>
        
        </>

        
        }
        
    </div>
    </>)
}
}
const styles: any = (theme: Theme) =>
  createStyles({
    listItem: {
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
      padding: "12px 10px",
      [theme.breakpoints.up("sm")]: {
        width: "100%",
        padding: "12px 20px",
        paddingRight: 0
      },
      [theme.breakpoints.up("md")]: {
        width: "100%"
      }
    },
    listItemTimeline: {
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
      padding: "12px 10px",
      [theme.breakpoints.up("sm")]: {
        width: "100%",
        padding: "12px 20px",
        paddingRight: 0
      },
      [theme.breakpoints.up("md")]: {
        width: "100%"
      }
    },
    listItemText: {
      fontWeight: 500,
      fontSize: "14px",
      color: "#11142D",
      lineHeight: "20px"
    },
    switch: {
      "& .MuiSwitch-colorSecondary.Mui-checked": {
        color: "#6C328B",
      },
      "& .MuiSwitch-colorSecondary.Mui-checked + .MuiSwitch-track": {
      backgroundColor: "#C4AED0",      
      },  
    },
    switchBase: {
      color: "#545650",
      "&$checked": {
        color: "#6C328B"
      },
      "&$checked + $track": {
        background: "#c4aed0",
        mixBlendMode: "normal",
        opacity: "0.37"
      }
    },
    track: {
      background: "#c8cbd2",
      mixBlendMode: "normal",
      opacity: "0.37"
    }
  });
//@ts-ignore
export default withStyles(styles)(IconLibrary);
// Customizable Area End