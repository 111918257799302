import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

// Customizable Area Start
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  history: any;
  location: any;
  id: string;
  // Customizable Area Start
  fullScreen: boolean;
  // Customizable Area End
}

interface S {
  txtInputValue: string;
  txtSavedValue: string;
  enableField: boolean;
  // Customizable Area Start
  anchorEl: any;
  currRating: any;
  grapValues: any;
  allCourseLibraryData: any;
  open: any;
  value: number;
  priceFree: any;
  toggleState: any;
  tabIndex: any;
  dropdownState: boolean;
  folderData: any;
  checked: boolean;
  folderData1: any;
  folderData2: any;
  homeScreenData: any;
  count: number;
  userSearch: string;
  getUserData: any;
  userData: any;
  page:number;
  checked1:any;
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class AdminDashboardController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  GetXpandLibraryData: any;
  GetUserData: any;
  GettabUserData: any;
  GettabBranchData: any;
  GetSearchData: any;
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      // Customizable Area End
    ];

    this.state = {
      txtInputValue: "",
      txtSavedValue: "A",
      enableField: false,
      // Customizable Area Start
      anchorEl: "",
      grapValues: "Week",
      currRating: "",
      allCourseLibraryData: {},
      priceFree: false,
      open: true,
      value: 1,
      toggleState: 1,
      tabIndex: [],
      dropdownState: true,
      homeScreenData: {},
      userData: {},
      checked: false,
      checked1: [],
      count: 1,
      page:1,
      userSearch: "",
      getUserData: {},
      folderData: {},
      folderData1: [
        {
          id: 1,
          team: "Team Name",
          user: "User Name",
          name: "None",
        },
        {
          id: 2,
          team: "Team Name",
          user: "User Name",
          name: "Branch",
        },
        {
          id: 3,
          team: "Team Name",
          user: "User Name",
          name: "Team",
        },
      ],
      folderData2: [
        {
          id: 1,
          name: "Branch",
        },
        {
          id: 2,
          name: "Team",
        },
      ],

      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
  }

  // @ts-ignore
  componentDidMount() {
    // Customizable Area Start
    this.getXpandLibraryData();
    this.tabUserData();
    this.tabBranchData();
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    runEngine.debugLog("Message Recived", message);

    if (message.id === getName(MessageEnum.AccoutLoginSuccess)) {
      let value = message.getData(getName(MessageEnum.AuthTokenDataMessage));

      this.showAlert(
        "Change Value",
        "From: " + this.state.txtSavedValue + " To: " + value
      );

      this.setState({ txtSavedValue: value });
    }

    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      var responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      var errorReponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );
      // console.log('__api 155')

      if (apiRequestCallId && responseJson) {
        // console.log('__api 157')
        if (apiRequestCallId === this.GetXpandLibraryData) {
          this.setState({ homeScreenData: responseJson });
          if (responseJson) {
            this.getUserData();
          }
        }
        if (apiRequestCallId === this.GetUserData) {
          this.setState({ getUserData: responseJson });
        }
        if (apiRequestCallId === this.GettabUserData) {
          this.setState({ userData: responseJson });
        }
        if (apiRequestCallId === this.GettabBranchData) {
          this.setState({ folderData: responseJson });
          console.log(responseJson, "responseJsonresponseJsonresponseJson");
        }
        if (apiRequestCallId === this.GetSearchData) {
          this.setState({ userData: responseJson });
          if (this.state.userSearch === "") {
            this.tabUserData();
          }
        }
      }
    }
    // Customizable Area End
  }

  txtInputWebProps = {
    onChangeText: (text: string) => {
      this.setState({ txtInputValue: text });
    },
    secureTextEntry: false,
  };

  txtInputMobileProps = {
    ...this.txtInputWebProps,
    autoCompleteType: "email",
    keyboardType: "email-address",
  };

  txtInputProps = this.isPlatformWeb()
    ? this.txtInputWebProps
    : this.txtInputMobileProps;

  btnShowHideProps = {
    onPress: () => {
      this.setState({ enableField: !this.state.enableField });
      this.txtInputProps.secureTextEntry = !this.state.enableField;
    },
  };

  btnShowHideImageProps = {};

  btnExampleProps = {
    onPress: () => this.doButtonPressed(),
  };

  doButtonPressed() {
    let msg = new Message(getName(MessageEnum.AccoutLoginSuccess));
    msg.addData(
      getName(MessageEnum.AuthTokenDataMessage),
      this.state.txtInputValue
    );
    this.send(msg);
  }

  // web events
  setInputValue = (text: string) => {
    this.setState({ txtInputValue: text });
  };

  setEnableField = () => {
    this.setState({ enableField: !this.state.enableField });
  };

  // Customizable Area Start

  handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    this.setState({ anchorEl: event.currentTarget });
  };

  handleChange = (event: any) => {
    this.setState({ checked: event.target.checked });
  };

  handleChange1 = (event: any,id:number) => {
     console.log(id);
    this.setState({ checked1: id});
  };

  handleDropdownClick = () => {
    this.setState({ dropdownState: !this.state.dropdownState });
  };

  handleTabChange = (event: any, newTabIndex: any) => {
    this.setState({ tabIndex: newTabIndex });
    this.setState({ open: !this.state.open });
  };

  handleTabClick = () => {
    this.setState({tabIndex:[]})
  }

  pagination = (evaent:any,value:any) => {
    this.setState({page:value}, ()=> 
      this.getXpandLibraryData()
    ) 
   
}

  handleClose = () => {
    this.setState({ anchorEl: null });
  };

  tabBranchData = async () => {
    const header = {
      "Content-Type": "application/json",
      token: localStorage.getItem("token"),
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.GettabBranchData = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_admin/home/search`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      header
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "get"
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  searchData = (event: any) => {
    this.setState({ userSearch: event.target.value });
    const header = {
      "Content-Type": "application/json",
      token: localStorage.getItem("token"),
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.GetSearchData = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_admin/home/search?query=${this.state.userSearch}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      header
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "get"
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  tabUserData = async () => {
    const header = {
      "Content-Type": "application/json",
      token: localStorage.getItem("token"),
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.GettabUserData = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_admin/home/search`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      header
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "get"
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  getUserData = async () => {
    const header = {
      "Content-Type": "application/json",
      token: localStorage.getItem("token"),
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.GetUserData = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_admin/home/search`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      header
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "get"
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  getXpandLibraryData = async () => {
    const header = {
      "Content-Type": "application/json",
      token: localStorage.getItem("token"),
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.GetXpandLibraryData = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_admin/home?page=${this.state.page}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      header
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "get"
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  // Customizable Area End
}