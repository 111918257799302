export const imgPasswordVisible = require("../assets/ic_password_visible.png");
export const imgPasswordInVisible = require("../assets/ic_password_invisible.png");
export const search = require("../assets/search.png");
export const filterImg = require("../assets/filter.png");
export const courses = require("../assets/image_not_available.png");
export const uploadVideo = require("../assets/uploadVideo.png");
export const addLink = require("../assets/link.png");
export const editNote = require("../assets/edit.png");
export const dataNotFound = require("../assets/data_not_found.png");
export const setting = require("../assets/setting.png");




