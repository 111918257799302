import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { imgPasswordInVisible, imgPasswordVisible } from "./assets";
import { report } from "process";
import { Animated, Easing } from 'react-native';
import AsyncStorage from "@react-native-async-storage/async-storage";
import { Console } from "console";
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  txtInputValue: string;
  txtSavedValue: string;
  enableField: boolean;
  report: String
  back: String
  courseReport: String;
  animatedValue: any
  arrayHolder: any,
  graphData: any
  watchedVideoData: any
  token: String
  trainingOrReport: string
  type: string
  courseReportData: any
  loading: boolean,
  modalVisible: boolean
  paymentMethods: any,
  showCoursesFilter: boolean,
  showVideWatchFilter: boolean,
  courseFilterData: any,
  filter_dues: any,
  filter_dues_params: any,
  filermonth: any,
  zoomDomain:any,
  scrollEnabled:boolean,
  showFilter : boolean,
  showFilterDetilas:any,
  showFilterVideo:boolean,
  userFilterName:any,
  userFilterVideoName:any
  showFilterVideosDetilas:any
  istrue:boolean
  // Customizable Area Start
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class CertificationTrackingController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  getTraningReportApiCallId: any;
  getVideoReportApiCallId: any;
  getVideoWatchReportApiCallId: any;
  initialCoursesFilter = [
    { id: 1, name: 'Due this week', namespentTime: 'This Week', isChecked: false,isCheckedVideoTime: false, param: 'due_this_week=true', paramfilter: 'filter=week' },
    { id: 2, name: 'Due this month', namespentTime: 'This Month', isChecked: false,isCheckedVideoTime: false, param: 'due_this_month=true', paramfilter: 'filter=month' },
    { id: 3, name: 'Total Dues', namespentTime: 'This Year', isChecked: false,isCheckedVideoTime: false, param: 'all_due=true', paramfilter: 'filter=year' },
  ]
  // initialFilterVideTime = [
  //   { id: 1, name: 'this week', isChecked: false, param: 'filter=week' },
  //   { id: 2, name: 'this month', isChecked: false, paramfilter: 'filter=month' },
  //   { id: 3, name: 'this year', isChecked: false, paramfilter: 'filter=year' },
  // ]
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      // Customizable Area End
    ];

    this.state = {
      txtInputValue: "",
      txtSavedValue: "A",
      enableField: false,
      report: configJSON.report,
      back: configJSON.back,
      courseReport: configJSON.courseReport,
      animatedValue: new Animated.Value(0),
      arrayHolder: [],
      graphData: [],
      watchedVideoData: [],
      token: "",
      trainingOrReport: "",
      type: this.props.navigation.state.params?.item,
      courseReportData: {},
      loading: false,
      modalVisible: false,
      showVideWatchFilter: false,
      courseFilterData: [],
      filter_dues: [],
      filter_dues_params: [],
      filermonth: [],
      zoomDomain:100,
      showFilterVideo:false,
      paymentMethods: [
        {
          id: 0,
          name: "Due this week",
          isSelected: false,
        },

        {
          id: 1,
          name: "Due this month",
          isSelected: false,

        },
        {
          id: 2,
          name: "Total Dues",
          isSelected: false,

        }

      ],
      showFilterDetilas : [
        { id: 1, name: 'Due this week', namespentTime: 'This Week', isChecked: false,isCheckedVideoTime: false, param: 'due_this_week=true', paramfilter: 'filter=week' },
        { id: 2, name: 'Due this month', namespentTime: 'This Month', isChecked: false,isCheckedVideoTime: false, param: 'due_this_month=true', paramfilter: 'filter=month' },
        { id: 3, name: 'Total Dues', namespentTime: 'This Year', isChecked: false,isCheckedVideoTime: false, param: 'all_due=true', paramfilter: 'filter=year' },
      ],
      showFilterVideosDetilas : [
        { id: 1, name: 'Due this week', namespentTime: 'This Week', isChecked: false,isCheckedVideoTime: false, param: 'due_this_week=true', paramfilter: 'filter=week' },
        { id: 2, name: 'Due this month', namespentTime: 'This Month', isChecked: false,isCheckedVideoTime: false, param: 'due_this_month=true', paramfilter: 'filter=month' },
        { id: 3, name: 'Total Dues', namespentTime: 'This Year', isChecked: false,isCheckedVideoTime: false, param: 'all_due=true', paramfilter: 'filter=year' },
      ],
      showFilter:false,
      scrollEnabled:false,
      showCoursesFilter: false,
      userFilterName : 'This week',
      userFilterVideoName:'This week',
      istrue:false


      // Customizable Area Start
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
  }
  startAnimation = () => {
    Animated.timing(this.state.animatedValue, {
      toValue: 1,
      duration: 1000,
      easing: Easing.ease,       // <-- animation duration
      useNativeDriver: false   // <-- need to set false to prevent yellow box warning
    }).start();
  }
  updateFilter() {
    this.setState({ courseFilterData: this.initialCoursesFilter });
  }


  onApplyFilterTimePressed(id:any) {
    console.log("name@@@@",id)
    this.setState({ showCoursesFilter: !this.state.showCoursesFilter })
    let selectedCourses: any = [];
    let selectedParams: any = [];
    this.state.courseFilterData.map((item: any) => {
      console.log("icourseFilterDatawjbgwrjvaishali",item)
      if(item.id === id){
        selectedCourses.push(item?.id);
        selectedParams.push(item?.paramfilter);
        }});
         this.setState({ filter_dues_params: selectedParams },
      () => {
        console.log("filter_dues_params",this.state.filter_dues_params)
        this.getTraningReport(this.state.token);
      });
    this.setState({ filter_dues: selectedCourses });
  }
  

  onApplyFilterVideoTimeWatchPressed(id:any) {
    this.setState({ showVideWatchFilter: !this.state.showVideWatchFilter })
    let selectedCourses: any = [];
    let selectedParams: any = [];
    this.state.courseFilterData.map((item: any) => {
      if(item.id === id){
        selectedCourses.push(item?.id);
        selectedParams.push(item?.paramfilter);
      }
    });
    this.setState({ filter_dues_params: selectedParams },
      () => {
        this.getVideoReportTimeWatch(this.state.token);


      });
    this.setState({ filter_dues: selectedCourses });
  }
  onApplyFilterPressed(item:any) {
    console.log("item@@@@@@@@@@@@@@",item)
    this.setState({ showCoursesFilter: !this.state.showCoursesFilter })
    let selectedCourses: any = [];
    let selectedParams: any = [];
    this.state.courseFilterData.map((item: any) => {
      if (item?.isChecked) {
        selectedCourses.push(item?.id);
        selectedParams.push(item?.param);
      }
    });
    this.setState({ filter_dues_params: selectedParams },
      () => {         
        this.getCourseReport(this.state.token);


      });
    this.setState({ filter_dues: selectedCourses });
  }

  onCoursesFilterItemSelected(id: any) {

    let check = this.state.courseFilterData;
    console.log("@@@@@@ ============", check)
    let newIndex = this.state.courseFilterData.findIndex((item: any) => item.id === id);
    check[newIndex].isChecked = !check[newIndex].isChecked;
    this.setState({ courseFilterData: check });
    check.map((element: any) => {
      if (element.id !== id) {
        element.isChecked = false
      }
    })
    this.setState({ courseFilterData: check })

    // let filterSelectedData = this.state.courseFilterData.map((item: any) => {
    //   if (item?.id === id) {
    //     if (!item.isChecked) {
    //       item.isChecked = true;
    //       return item
    //     } else {
    //       item.isChecked = false;
    //       return item
    //     }
    //   } else {
    //     return item
    //   }

    // });
    // this.setState({ courseFilterData: filterSelectedData });
  }

  onCoursesFilterTimveVideoSelected(id: any) {

    let check = this.state.courseFilterData;
    console.log("@@@@@@ ============", check)
    let newIndex = this.state.courseFilterData.findIndex((item: any) => item.id === id);
    check[newIndex].isCheckedVideoTime = !check[newIndex].isCheckedVideoTime;
    this.setState({ courseFilterData: check });
    check.map((element: any) => {
      if (element.id !== id) {
        element.isCheckedVideoTime = false
      }
    })
    this.setState({ courseFilterData: check })
    // let filterSelectedData = this.state.courseFilterData.map((item: any) => {
    //   if (item?.id === id) {
    //     if (!item.isCheckedVideoTime) {
    //       item.isCheckedVideoTime = true;
    //       return item
    //     } else {
    //       item.isCheckedVideoTime = false;
    //       return item
    //     }
    //   } else {
    //     return item
    //   }

    // });
    // this.setState({ courseFilterData: filterSelectedData });
  }
  isFilterAppliedListAvailable() {
    this.setState({ showCoursesFilter: !this.state.showCoursesFilter });
    this.state.courseFilterData.map((item: any) => {
      if (this.state.filter_dues.includes(item.id)) {
        item.isCheckedVideoTime = true
      } else {
        item.isCheckedVideoTime = false
      }
    });
  }
  isFilterAppliedListVideoWatchAvailable() {
    this.setState({ showVideWatchFilter: !this.state.showVideWatchFilter });
    this.state.courseFilterData.map((item: any) => {
      if (this.state.filter_dues.includes(item.id)) {
        item.isChecked = true
      } else {
        item.isChecked = false
      }
    });
  }
  
  async handleCheckBox(itemId: any) {
    //this.setState({ cardId: 0 })
    console.log("handleCheckBox", itemId)
    let temp:any = []
    this.state.paymentMethods.map((paymentMethods:any) => {
      let data = paymentMethods
      if (data.id === itemId) {
        data.isSelected = true
      } else {
        data.isSelected = false
      }
      temp.push(data)

    })
    this.setState({ paymentMethods: temp })

    // this.state.cardsData.map((card) => {
    //   card.isSelected = true
    // })

  }
  getAppliedFilterParams() {
    // console.log("this.state.filter_dues_params@@",this.state.filter_dues_params)
    let params: string = '';
    this.state.filter_dues_params.map((item: any) => {
      params = params.concat('?', item);
    })
    return params;
  }

  getAppliedFilterParamsTime() {
    console.log("this.state.filter_dues_params@@", this.state.filter_dues_params)
    let params: string = '';
    this.state.filter_dues_params.map((item: any) => {
      params = params.concat('?', item);
    })
    return params;
  }


  openCloseModal = () => {
    console.log("onpressmodal", "------------")
    this.setState({ modalVisible: !this.state.modalVisible })
  }

  async componentDidMount() {
    super.componentDidMount();
    this.startAnimation()
    this.updateFilter();

    //  this.getToken();
    // const token = await getStorageData("token", true);
    const token:any = await AsyncStorage.getItem('token')
    if (this.state.type === "courseReport") {
      this.getCourseReport(token)
    } else {
      this.getTraningReport(token);

    }

    this.setState({ token: token });

    if (this.isPlatformWeb() === false) {
      this.props.navigation.addListener("willFocus", () => {
        if (this.state.type === "courseReport") {
          this.getCourseReport(token)
        } else {
          this.getTraningReport(token);

        }        //       this.getToken();
      });
    }
  }


  async receive(from: string, message: Message) {
    runEngine.debugLog("Message Recived", message);
    this.setState({ loading: false });
    if (message.id === getName(MessageEnum.AccoutLoginSuccess)) {
      let value = message.getData(getName(MessageEnum.AuthTokenDataMessage));

      this.showAlert(
        "Change Value",
        "From: " + this.state.txtSavedValue + " To: " + value
      );

      this.setState({ txtSavedValue: value });
    }
    if (
      getName(MessageEnum.RestAPIResponceMessage) === message.id &&
      this.getTraningReportApiCallId != null &&
      this.getTraningReportApiCallId ===
      message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
    ) {

      var responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      if (responseJson && !responseJson.errors && responseJson) {
        console.log("responseJson@@@@@@@",responseJson)
        this.setState({ arrayHolder: responseJson }, () => {
          var filtered = this.state.arrayHolder.map(function (el: any) {
            let date = el.track_date.substring(5, 10)
            el.track_date = date.substring(3) + "/" + date.substring(0, 2)
            el.track_time = parseFloat(el.track_time)
            return el
          });
          this.setState({ graphData: filtered }, () => {

            setTimeout(() => { this.getVideoReport(this.state.token) }, 1000);

          })

        });

        this.getTraningReportApiCallId = null;
        runEngine.debugLog("arrayHolder", this.state.arrayHolder);

      } else {
        var errorReponse = message.getData(
          getName(MessageEnum.RestAPIResponceErrorMessage)
        );
        this.parseApiCatchErrorResponse(errorReponse);
        console.log("Errors=====", responseJson.errors)
      }
    }
    if (
      getName(MessageEnum.RestAPIResponceMessage) === message.id &&
      this.getVideoWatchReportApiCallId != null &&
      this.getVideoWatchReportApiCallId ===
      message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
    ) {

      var responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      if (responseJson && !responseJson.errors && responseJson) {

        if (this.state.type === "courseReport") {
          this.setState({ courseReportData: responseJson })
          console.log("dataVideo========", this.state.courseReportData)

        } else {

          this.setState({ watchedVideoData: responseJson }, () => {

            var filtered = this.state.watchedVideoData.map(function (el: any) {
              let date = el[0].substring(5, 10)
              console.log("date--------", date)
              el[0] = date.substring(3) + "/" + date.substring(0, 2)
              el[1] = parseFloat(el[1])

              el = Object.assign({}, el);
              return el
            });
            this.setState({ watchedVideoData: filtered })

          });
        }

        console.log("responseJson==========>", this.state.watchedVideoData);

        this.getVideoReportApiCallId = null;
      } else {
        var errorReponse = message.getData(
          getName(MessageEnum.RestAPIResponceErrorMessage)
        );
        this.parseApiCatchErrorResponse(errorReponse);
        console.log("Errors=====", responseJson.errors)
      }
    }



    if (
      getName(MessageEnum.RestAPIResponceMessage) === message.id &&
      this.getVideoReportApiCallId != null &&
      this.getVideoReportApiCallId ===
      message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
    ) {

      var responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      if (responseJson && !responseJson.errors && responseJson) {

        if (this.state.type === "courseReport") {
          this.setState({ courseReportData: responseJson })
          console.log("dataVideo========", this.state.courseReportData)

        } else {

          this.setState({ watchedVideoData: responseJson }, () => {

            var filtered = this.state.watchedVideoData.map(function (el: any) {
              let date = el[0].substring(5, 10)
              console.log("date--------", date)
              el[0] = date.substring(3) + "/" + date.substring(0, 2)
              el[1] = parseFloat(el[1])

              el = Object.assign({}, el);
              return el
            });
            this.setState({ watchedVideoData: filtered })

          });
        }

        console.log("responseJson==========>", this.state.watchedVideoData);

        this.getVideoReportApiCallId = null;
      } else {
        var errorReponse = message.getData(
          getName(MessageEnum.RestAPIResponceErrorMessage)
        );
        this.parseApiCatchErrorResponse(errorReponse);
        console.log("Errors=====", responseJson.errors)
      }
    }

    // Customizable Area Start
    // Customizable Area End
  }

  getTraningReport = (token: any,) => {
    // if (this.state.MyChannel) {
    //   httpBody.is_my_channel = this.state.MyChannel
    // }

    const header = {
      "Content-Type": configJSON.validationApiContentType,
      token: token,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getTraningReportApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.spentTrainingTime+ this.getAppliedFilterParamsTime()
    );


    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    // requestMessage.addData(
    //   getName(MessageEnum.RestAPIResponceDataMessage),
    //   JSON.stringify(httpBody)
    // );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );
    this.setState({ loading: true });
    runEngine.sendMessage(requestMessage.id, requestMessage);
    console.log("requestMessage@@@@@",requestMessage)
  };

  getVideoReport = (token: any) => {

    // if (this.state.MyChannel) {
    //   httpBody.is_my_channel = this.state.MyChannel
    // }

    const header = {
      "Content-Type": configJSON.validationApiContentType,
      token: this.state.token,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getVideoReportApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.spentVideoTrainingTime
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    // requestMessage.addData(
    //   getName(MessageEnum.RestAPIResponceDataMessage),
    //   JSON.stringify(httpBody)
    // );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );
    this.setState({ loading: true });
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };
  
  getVideoReportTimeWatch = (token: any) => {

    const header = {
      "Content-Type": configJSON.validationApiContentType,
      token: this.state.token,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getVideoWatchReportApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.spentVideoTrainingTime + this.getAppliedFilterParamsTime()
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    // requestMessage.addData(
    //   getName(MessageEnum.RestAPIResponceDataMessage),
    //   JSON.stringify(httpBody)
    // );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );
    this.setState({ loading: true });
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  getCourseReport = (token: any) => {

    // if (this.state.MyChannel) {
    //   httpBody.is_my_channel = this.state.MyChannel
    // }

    const header = {
      "Content-Type": configJSON.validationApiContentType,
      token: token,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getVideoReportApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.overAllCourseReport + this.getAppliedFilterParams()
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    // requestMessage.addData(
    //   getName(MessageEnum.RestAPIResponceDataMessage),
    //   JSON.stringify(httpBody)
    // );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );
    this.setState({ loading: true });
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };



  txtInputWebProps = {
    onChangeText: (text: string) => {
      this.setState({ txtInputValue: text });
    },
    secureTextEntry: false,
  };

  txtInputMobileProps = {
    ...this.txtInputWebProps,
    autoCompleteType: "email",
    keyboardType: "email-address",
  };

  txtInputProps = this.isPlatformWeb()
    ? this.txtInputWebProps
    : this.txtInputMobileProps;

  btnShowHideProps = {
    onPress: () => {
      this.setState({ enableField: !this.state.enableField });
      this.txtInputProps.secureTextEntry = !this.state.enableField;
      this.btnShowHideImageProps.source = this.txtInputProps.secureTextEntry
        ? imgPasswordVisible
        : imgPasswordInVisible;
    },
  };

  btnShowHideImageProps = {
    source: this.txtInputProps.secureTextEntry
      ? imgPasswordVisible
      : imgPasswordInVisible,
  };

  btnExampleProps = {
    onPress: () => this.doButtonPressed(),
  };

  doButtonPressed() {
    let msg = new Message(getName(MessageEnum.AccoutLoginSuccess));
    msg.addData(
      getName(MessageEnum.AuthTokenDataMessage),
      this.state.txtInputValue
    );
    this.send(msg);
  }

  // web events
  setInputValue = (text: string) => {
    this.setState({ txtInputValue: text });
  };

  setEnableField = () => {
    this.setState({ enableField: !this.state.enableField });
  };
  // Customizable Area Start
  // Customizable Area End
}
