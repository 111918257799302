import React, { useEffect } from "react";
import {
  Box, Button,
  // Customizable Area Start
  Grid,
  // Customizable Area End
 Paper,
  TextField, Typography,
  Accordion, AccordionDetails,
  AccordionSummary, Backdrop,
  Checkbox, CircularProgress,
  FormControl,
  MenuItem, Select, Dialog, Radio, FormControlLabel, RadioGroup
} from "@material-ui/core";
// Customizable Area Start
import RangeSlider from '@material-ui/core/Slider';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { createTheme, makeStyles, withStyles } from "@material-ui/core/styles";
import SearchIcon from '@material-ui/icons/Search';
import { Bar, Line } from 'react-chartjs-2';
import Slider from "react-slick";
import PersonalSlider from "../../../components/src/LibraryComponent/PersonalSlider";
import XpandSlider from "../../../components/src/LibraryComponent/XpandSlider";
import CorporateSlider from "../../../components/src/LibraryComponent/CorporateSlider";
import "slick-carousel/slick/slick-theme.css";
import "slick-carousel/slick/slick.css";
import { Shape, Short } from "./assets";
import './videostyles.css';

const theme = createTheme({
  palette: {
    primary: {
      main: "#fff",
      contrastText: "#fff",
    },
  },
  typography: {
    h6: {
      fontWeight: 500,
    },
    subtitle1: {
      margin: "20px 0px",
    },
  },
});

const CustomSlider: any = withStyles({
  root: {
    color: 'red',
    height: 8,
  },
  thumb: {
    height: 24,
    width: 24,
    backgroundColor: 'red',
    border: '2px solid currentColor',
    marginTop: -8,
    marginLeft: -12,
    '&:focus, &:hover, &$active': {
      boxShadow: 'inherit',
    },
  },
  active: {},
  track: {
    height: '8px',
    borderRadius: 4,
  },
  rail: {
    height: '8px',
    borderRadius: 4,
    opacity: 0.5,
    backgroundColor: 'red',
  },
})(Slider);

// Customizable Area End
import Library2Controller, {
  Props
} from "./Library2Controller.web";

export default class Library2 extends Library2Controller {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End

  render() {

    return (
      <>
        <Grid container spacing={4} style={{ paddingTop: "25px" }}>
          <Grid item lg={12} md={12} sm={12} xs={12} style={{ padding: '0px', marginTop: '-24px' }}>
            <Box style={{ paddingLeft: '15px' }}>
              <Typography style={styles.mainhadding} variant="h3" className="profile_header">Library</Typography>
            </Box>
            <Backdrop style={{ zIndex: theme.zIndex.drawer + 1, color: '#fff' }} open={this.state.loaderOpen} >
              <CircularProgress color="inherit" />
            </Backdrop>
            <Grid container spacing={2} style={{ marginTop: '30px' }}>
              <Grid item lg={4} md={6} sm={6} xs={6}>
                <Search _onFocus={this._onFocus} />
              </Grid>
              <Grid item lg={8} md={6} sm={6} xs={6}>
                <Box style={{ position: 'relative' }}>
                  <Box style={styles.mainContainer}>
                    <Box style={styles.BoxContainer}>
                      <Button onClick={() => {
                        return this.searchFilter()
                      }} variant="outlined" style={styles.filterButton}>
                        Filter
                      </Button>
                    </Box>
                    <Box style={styles.IconBox}>
                      <Button onClick={() => this.searchShort()} variant="outlined" style={styles.filterIcon}><img src={Short} /></Button>
                    </Box>
                  </Box>
                  <Box style={{
                    position: "absolute",
                    top: 'auto',
                    left: 0,
                    bottom: 0,
                    zIndex: 999
                  }}>
                    <Dialog ref={this.popupRef} className="dialog_library" hideBackdrop onClose={this.closeSearchFilter} aria-labelledby="simple-dialog-title" open={this.state.filter}>
                      <Paper style={{ borderRadius: '10px', width: '204px', boxShadow: '0px 3px 10px rgba(0, 0, 0, 0.445832)', overflow: 'hidden auto', paddingBottom: '60px' }}>
                        <Box style={{ padding: '15px' }}>
                          <Box style={{ display: 'flex', justifyContent: 'flex-start' }}>
                            <Typography style={{ fontSize: '16px', lineHeight: '18px', fontWeight: 700, color: '#1A1A1A' }}>Filter Courses</Typography>
                          </Box>
                          <Box style={{ display: 'flex', justifyContent: 'flex-start', marginTop: '11px', width: '100%' }}>
                            <Typography style={{ fontSize: '13px', lineHeight: '20px', fontWeight: 400, color: '#1A1A1A' }}>Show courses according to the following selected options</Typography>
                          </Box>
                          <Box style={{ width: '100%', display: 'flex', justifyContent: 'flex-start', marginTop: '11px', marginRight: '30%' }}>
                            <hr style={{ width: '100%', height: '1px', border: '1px solid #979797', opacity: 0.43 }} />
                          </Box>
                        </Box>
                        <Accordion defaultExpanded={true} style={{ marginTop: '-23px', boxShadow: 'none' }}>
                          <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel1a-content"
                            id="panel1a-header"
                          >
                            <Typography style={{ fontSize: '13px', fontWeight: 600 }}>Categories</Typography>
                          </AccordionSummary>
                          <AccordionDetails style={{ marginTop: '-40px' }}>
                            <Box style={{ marginTop: '15px' }}>
                              {this.state.libraryCheckedData?.data?.map((data: any, index: any) => (
                                <Box key={index} style={{ display: 'flex', justifyContent: 'space-between', marginBottom: '12px' }}>
                                  <Typography style={{ fontSize: '13px', lineHeight: '18px', fontWeight: 400, color: '#1A1A1A' }}>{data.attributes.name}</Typography>
                                  <Checkbox
                                    checked={this.state.checkboxChecked[data.attributes.id] || false}
                                    onChange={(event) => this.checkedhandleChange(data.attributes.id, data.attributes.is_selected, event)}
                                    color="primary"
                                    inputProps={{ 'aria-label': 'secondary checkbox' }}
                                    style={{ marginTop: '-12px', marginRight: '-8px', color: '#6C328B' }}
                                  />
                                </Box>
                              ))}

                            </Box>
                          </AccordionDetails>
                        </Accordion>
                        <Box style={{ width: '100%', display: 'flex', justifyContent: 'flex-start', marginTop: '0px', marginRight: '30%', padding: '0 15px' }}>
                          <hr style={{ width: '100%', height: '1px', border: '1px solid #979797', opacity: 0.43 }} />
                        </Box>
                        <Accordion defaultExpanded={true} style={{ boxShadow: 'none' }}>
                          <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel1a-content"
                            id="panel1a-header"
                          >
                            <Typography style={{ fontSize: '13px', fontWeight: 600 }}>Payment Method</Typography>
                          </AccordionSummary>
                          <AccordionDetails style={{ marginTop: '-40px' }}>
                            <Box style={{ marginTop: '15px' }}>
                              {this.state.libraryCheckedData3?.map((data: any, index: any) => (
                                <Box key={index} style={{ display: 'flex', justifyContent: 'space-between' }}>
                                  <Typography style={{ fontSize: '13px', lineHeight: '18px', fontWeight: 400, color: '#1A1A1A' }}>{data.text}</Typography>
                                  <Checkbox
                                    checked={this.state.checkboxChecked1[data.name] || false}
                                    onChange={(event: any) => this.checkedhandleChange3(data.name, event)}
                                    inputProps={{ 'aria-label': 'secondary checkbox' }}
                                    style={{ marginTop: '-12px', color: '#6C328B', marginRight: '-12px' }}
                                  />
                                </Box>
                              ))}
                            </Box>
                          </AccordionDetails>
                        </Accordion>
                        <Box style={{ width: '100%', display: 'flex', justifyContent: 'flex-start', marginTop: '0px', marginRight: '30%', padding: '0 15px' }}>
                          <hr style={{ width: '100%', height: '1px', border: '1px solid #979797', opacity: 0.43 }} />
                        </Box>
                        <Box style={{ display: 'flex', justifyContent: 'space-between', padding: '10px 30px 0 10px' }}>
                          <Typography style={{ fontSize: '12px', lineHeight: '15px', fontWeight: 600, color: '#1A1A1A' }}>Courses Only</Typography>
                          <Checkbox
                            onChange={this.handleCheckboxChange}
                            inputProps={{ 'aria-label': 'secondary checkbox' }}
                            style={{ marginTop: '-12px', marginRight: '-25px', color: '#6C328B' }}
                          />
                        </Box>
                        <Box style={{ display: 'flex', justifyContent: 'space-between', padding: '10px 30px 0 10px' }}>
                          <Typography style={{ fontSize: '12px', lineHeight: '15px', fontWeight: 600, color: '#1A1A1A' }}>Programs Only</Typography>
                          <Checkbox
                            onChange={this.handleCheckboxChangeProgram}
                            inputProps={{ 'aria-label': 'secondary checkbox' }}
                            style={{ marginTop: '-12px', marginRight: '-25px', color: '#6C328B' }}
                          />
                        </Box>

                        <Box style={{ padding: '15px' }}>
                          <Button onClick={() => this.CheckedDataSort()
                            // this.CheckedSearchDataSort()
                          } variant="outlined" style={{ marginBottom: '17px', marginTop: '17px', color: '#6C328B', float: 'right', height: '31px', width: '100%', border: '1.8px solid #6C328B', fontWeight: 700, borderRadius: '10px' }}>
                            Apply
                          </Button>
                        </Box>
                      </Paper>
                    </Dialog>
                  </Box>
                </Box>
              </Grid>
            </Grid>
            <Box>
              <Paper className="paper" style={styles.mainOfferPaper}>
                <Typography style={styles.offerTypography}>Latest offers for you</Typography>
                <SliderComponent
                  data={this.state.libraryData}
                  type='plain'
                />
              </Paper>
              <Paper className="paper" style={styles.filterPaper}>
                <Grid container>
                  <Grid lg={9} md={9} sm={12} xs={12}>
                    <Box sx={{ display: { lg: 'block !important', md: 'block !important', sm: 'none', xs: 'none' } }}>
                      <Typography style={styles.learningHadding}>Learning Progress</Typography>
                      {this?.state?.show ? <LineChart2 state={this.state.chartData?.this_time_data} /> :
                        <LineChart state={this.state.chartData?.last_time_data} />}
                    </Box>
                    <Box sx={{ display: { xs: 'block !important', sm: 'none !important', md: 'none !important', lg: 'none !important' } }}>
                      <Box style={{ fontFamily: "sans-serif", textAlign: "center" }}>
                        <BarCart Week={this.state.barWeek} state={this.state.chartData} />
                      </Box>
                      <Box style={styles.BarDropBox}>
                        <Typography style={styles.BarDropTypography}>
                          <FormControl style={styles.formControl}>
                            <Select
                              style={styles.FormControlSlect}
                              value={this.state.selected}
                              onChange={this.handleChange}
                              disableUnderline
                              inputProps={{
                                name: "agent",
                                id: "age-simple"
                              }}
                            >
                              {this.state.values.map((value: any, index: any) => {
                                return <MenuItem onClick={() => this.handleClickChart(value)} value={value}>{value}</MenuItem>;
                              })}
                            </Select>
                          </FormControl></Typography>

                        <Button variant="contained" color="primary" onClick={this.BarWeektoggle}>
                          {this.state.barWeek ? "This Week" : "Last Week"}
                        </Button>
                      </Box>
                    </Box>
                    <Box sx={{ display: { xs: 'none !important', sm: 'block !important', md: 'none !important', lg: 'none !important' } }}>
                      <Box style={{ fontFamily: "sans-serif", textAlign: "center" }}>
                        <BarCart1 Week={this.state.barWeek} state={this.state.chartData} />
                      </Box>
                      <Box style={styles.barCart2MainBox}>
                        <Typography style={styles.barCart2Typography}>
                          <FormControl style={styles.barCart2formControl}>
                            <Select
                              style={styles.select}
                              value={this.state.selected}
                              onChange={this.handleChange}
                              disableUnderline
                              inputProps={{
                                name: "agent",
                                id: "age-simple"
                              }}
                            >
                              {this.state.values.map((value: any, index: any) => {
                                return <MenuItem onClick={() => this.handleClickChart(value)} value={value}>{value}</MenuItem>;
                              })}
                            </Select>
                          </FormControl></Typography>

                        <Button variant="contained" color="primary" onClick={this.BarWeektoggle}>
                          {this.state.barWeek ? "This Week" : "Last Week"}
                        </Button>
                      </Box>
                    </Box>
                  </Grid>
                  <Grid lg={3} md={3} >
                    <Box sx={{ display: { lg: 'block !important', md: 'block !important', sm: 'none', xs: 'none' } }}>
                      <Box style={{ display: 'flex', marginLeft: '-72%', marginTop: '-20px' }}>
                        <Typography style={{ fontWeight: 700, fontSize: '17px', float: 'none', marginTop: '17px', cursor: 'pointer' }}>
                          <FormControl style={{ color: "#3F526D", borderColor: "none", borderWidth: "0px", borderStyle: "solid", borderRadius: "0px", minWidth: "120px", justifyContent: "center" }}>
                            <Select
                              style={{ width: "220px", height: '45px', fontSize: "18px", backgroundColor: "#f5f7f6", paddingLeft: '30px', borderRadius: '10px', border: 'none', textTransform: 'capitalize' }}
                              value={this.state.selected}
                              onChange={this.handleChange}
                              disableUnderline
                              inputProps={{
                                name: "agent",
                                id: "age-simple"
                              }}
                            >
                              {this.state.values.map((value: any, index: any) => {
                                return <MenuItem onClick={() => this.handleClickChart(value)} value={value}>{value}</MenuItem>;
                              })}
                            </Select>
                          </FormControl></Typography>
                        <Typography style={{ paddingLeft: '15px', fontWeight: 700, fontSize: '17px', float: 'right', marginTop: '20px', cursor: 'pointer' }}>
                          <FormControl style={{ color: "#3F526D", borderColor: "none", borderWidth: "0px", borderStyle: "solid", borderRadius: "0px", minWidth: "120px", justifyContent: "center" }}>
                            <Select
                              style={{ width: "220px", textTransform: 'capitalize', height: '45px', fontSize: "18px", backgroundColor: "#f5f7f6", paddingLeft: '30px', borderRadius: '10px', border: 'none' }}
                              value={this.state.weekSelected}
                              onChange={this.weekhandleChange}
                              disableUnderline
                              inputProps={{
                                name: "agent",
                                id: "age-simple"
                              }}
                            >
                              {this.state.weekValue.map((value: any, index: any) => {
                                return <MenuItem onClick={() => this.handleClickWeek(value)} value={value}>{value}</MenuItem>;
                              })}


                            </Select>
                          </FormControl></Typography>
                      </Box>
                      <Typography style={{ fontWeight: 700, fontSize: '18px', marginTop: "35px", marginBottom: '-10px', marginLeft: '40px' }}>Videos Watched</Typography>
                      <Grid container style={{ paddingTop: '0px' }}>
                        <Grid item lg={6} md={6} sm={12} xs={12} style={{ paddingLeft: '30px' }}>
                          <Box style={{ display: 'flex', marginTop: '30px', }}>
                            <Box style={{ width: '16px', height: '16px', backgroundColor: '#1b10e6', borderRadius: '10px' }}></Box>
                            <Typography style={{ fontWeight: 300, fontSize: '16px', paddingLeft: '15px', marginTop: '-5px' }}>This Week</Typography>
                          </Box>
                          <Typography style={{ fontWeight: 700, fontSize: '18px', marginTop: "21px", marginLeft: '15px' }}>{this.state.chartData?.meta?.this_count}</Typography>
                        </Grid>
                        <Grid item lg={6} md={6} sm={12} xs={12} style={{ paddingLeft: '30px' }}>
                          <Box style={{ display: 'flex', marginTop: '30px' }}>
                            <Box style={{ width: '16px', height: '16px', backgroundColor: '#1fd1a8', borderRadius: '10px' }}></Box>
                            <Typography style={{ fontWeight: 300, fontSize: '16px', paddingLeft: '15px', marginTop: '-5px' }}>Last Week</Typography>
                          </Box>
                          <Typography style={{ fontWeight: 700, fontSize: '18px', marginTop: "21px", marginLeft: '15px' }}>{this.state.chartData?.meta?.last_count}</Typography>
                        </Grid>
                        <Grid item lg={12} md={12} sm={12} xs={12}>
                          <Box style={{ display: 'flex', marginTop: '30px', marginLeft: '40px' }}>
                            <Box style={{ width: '16px', height: '16px', backgroundColor: '#1097e6', borderRadius: '10px' }}></Box>
                            <Typography style={{ fontWeight: 300, fontSize: '16px', paddingLeft: '15px', marginTop: '-5px' }}>Total Videos</Typography>
                          </Box>
                          <Typography style={{ fontWeight: 700, fontSize: '18px', marginTop: "5px", marginLeft: '75px' }}>{this.state.chartData?.meta?.total_count[0].count}</Typography>
                        </Grid>
                      </Grid>
                    </Box>
                  </Grid>
                </Grid>

              </Paper>
            </Box>



            <Dialog className="dialogSort_library" hideBackdrop onClose={this.closeSearchSort} aria-labelledby="simple-dialog-title" open={this.state.sort}>
              <Paper style={{ borderRadius: '10px', width: '204px', padding: '15px', boxShadow: '0px 3px 10px rgba(0, 0, 0, 0.445832)' }}>
                <Box style={{ display: 'flex', justifyContent: 'flex-start', marginTop: '25px' }}>
                  <Typography style={{ fontSize: '16px', lineHeight: '18px', fontWeight: 700, color: '#1A1A1A' }}>Sort Courses</Typography>
                </Box>
                <Box style={{ display: 'flex', justifyContent: 'flex-start', marginTop: '11px', width: '92%' }}>
                  <Typography style={{ fontSize: '13px', lineHeight: '20px', fontWeight: 400, color: '#1A1A1A' }}>Show courses according to the following selected options</Typography>
                </Box>
                <Box style={{ width: '100%', display: 'flex', justifyContent: 'flex-start', marginTop: '11px', marginRight: '30%' }}>
                  <hr style={{ width: '100%', height: '1px', border: '1px solid #979797', opacity: 0.43 }} />
                </Box>
                <Box style={{ marginTop: '15px' }}>
                  <RadioGroup value={this.state.selectedIndices} onChange={this.checkedhandleChange2}>
                    {this.state.libraryCheckedData2.map((data:any) => (
                      <FormControlLabel
                        key={data.id}
                        value={data.name}
                        control={<Radio style={{ color: '#6C328B' }} />}
                        label={
                          <Typography style={{ fontSize: '12px', lineHeight: '15px', fontWeight: 600, color: '#1A1A1A' }}>
                            {data.text}
                          </Typography>
                        }
                      />
                    ))}
                  </RadioGroup>
                  <Typography style={{ paddingBottom: '10px', paddingTop: '10px', fontSize: '14px', lineHeight: '15px', fontWeight: 600, color: '#1A1A1A' }}>Prices</Typography>
                  <Typography style={{ marginLeft: '145px', marginBottom: '-16px', fontSize: '10px', lineHeight: '15px', fontWeight: 600, color: '#1A1A1A' }}>$1000</Typography>
                  <Typography style={{ fontSize: '10px', lineHeight: '15px', fontWeight: 600, color: '#1A1A1A' }}>${this.state.percentage}</Typography>
                  <RangeSlider
                    min={50}
                    max={1000}
                    className="rangeInput"
                    defaultValue={this.state.percentage}
                    onChange={this.percentage_handler}
                    aria-label="Default"
                  />
                </Box>

                <Box>
                  <Button onClick={() => this.applyShorting()} variant="outlined" style={{ marginBottom: '17px', marginTop: '17px', color: '#6C328B', float: 'right', height: '31px', width: '100%', border: '1.8px solid #6C328B', fontWeight: 700, borderRadius: '10px' }}>
                    Apply
                  </Button>
                </Box>
              </Paper>
            </Dialog>

            <Paper className="paper" style={styles.paper_personal}>
              <Typography style={styles.typography_personal}>Personal</Typography>
              <Typography style={styles.typography_view_personal}
                onClick={() => { return this.props.navigation.navigate('PersonalPageWeb') }}>View More <img src={Shape} style={styles.img_shape} /></Typography>
              <PersonalSlider style={this.state.style} last_trackVideo={this.last_trackVideo} handleNavigate={this.handleNavigate} props={this.props} personalData={this.state.allLibraryData.personal} />
            </Paper>



            <Paper className="paper" style={styles.paper_corporate}>
              <Typography style={styles.typography_personal}>Corporate Courses</Typography>
              <Typography style={styles.typography_view_personal}
                onClick={() => {
                  return this.tostSuccessfully()
                }}>View More <img src={Shape} style={styles.img_shape} /></Typography>
              <CorporateSlider last_trackVideo={this.last_trackVideo} CorporateData={this.state.allLibraryData} handleNavigate={this.handleNavigate} tostSuccessfully={this.tostSuccessfully} style={undefined} />
            </Paper>


            <Paper className="paper" style={styles.paper_xpand}>
              <Typography style={styles.typography_personal}>Xpand Collection</Typography>
              <Typography style={styles.typography_view_personal}
                onClick={() => {
                  return this.props.navigation.navigate('XpandPageWeb')
                }}>View More <img src={Shape} style={styles.img_shape} /></Typography>
              <XpandSlider style={this.state.style} postplayButton={this.postplayButton} handleNavigate={this.handleNavigate} props={this.props} XpandData={this.state.allLibraryData} postXpandLibraryData={this.postXpandLibraryData} handleSubscription={this.handleSubscription} addCartItem={this.addCartItem} />
            </Paper>
          </Grid>
        </Grid>

      </>
    )

  }
}

const styles = {
  mainhadding: {
    display: 'contents'
  },
  mainContainer: {
    display: 'flex'
  },
  BoxContainer: {
    width: '130px',
    height: '45px',
    marginTop: '3px'
  },
  filterButton: {
    color: '#6C328B',
    fontWeight: 700,
    height: '45px',
    width: '100%',
    border: '1.5px solid #6C328B',
    borderRadius: '10px',
  },
  IconBox: {
    width: '100%',
    marginTop: '3px',
    paddingLeft: '15px'
  },
  filterIcon: {
    width: '40px',
    height: '46px',
    borderRadius: '10px',
    border: '1.5px solid #6C328B',
    cursor: 'pointer'
  },
  mainOfferPaper: {
    flex: 1,
    marginTop: '25px',
    padding: '30px',
    borderRadius: '24px',
    background: 'linear-gradient(180deg, rgba(253,253,255,1) 0%, rgba(239,244,250,1) 100%)',
    overflow: 'hidden',
    maxWidth: '90vw',
    height: '384px',
    margin: '25px auto auto auto'
  },
  offerTypography: {
    fontWeight: 700,
    marginBottom: '20px',
    fontSize: '24px'
  },
  filterPaper: {
    overflow: 'hidden',
    padding: '30px',
    background: 'linear-gradient(180deg, rgba(253,253,255,1) 0%, rgba(239,244,250,1) 100%)',
    borderRadius: '24px',
    flex: 1,
    maxWidth: '90vw',
    height: '384px',
    margin: 'auto',
    marginTop: '25px'
  },
  learningHadding: {
    fontWeight: 700,
    fontSize: '24px'
  },
  BarDropBox: {
    display: 'inline-grid',
    marginTop: '8px',
    marginLeft: '25px',
    justifyContent: 'space-around'
  },
  BarDropTypography: {
    fontWeight: 700,
    fontSize: '17px',
    // float: 'right',
    marginTop: '-30px',
    cursor: 'pointer'
  },
  formControl: {
    color: "#3F526D",
    borderColor: "none",
    borderWidth: "0px",
    borderStyle: "solid",
    borderRadius: "0px",
    minWidth: "120px",
    justifyContent: "center"
  },
  FormControlSlect: {
    width: "220px",
    height: '45px',
    fontSize: "18px",
    backgroundColor: "#f5f7f6",
    paddingLeft: '30px',
    borderRadius: '10px',
    border: 'none'
  },
  barCart2MainBox: {
    display: 'flex',
    justifyContent: 'space-around'
  },
  barCart2Typography: {
    fontWeight: 700,
    fontSize: '17px',
    // float: 'right',
    marginTop: '0px',
    cursor: 'pointer'
  },
  barCart2formControl: {
    color: "#3F526D",
    borderColor: "none",
    borderWidth: "0px",
    borderStyle: "solid",
    borderRadius: "0px",
    minWidth: "120px",
    justifyContent: "center"
  },
  select: {
    width: "220px",
    height: '45px',
    fontSize: "18px",
    backgroundColor: "#f5f7f6",
    paddingLeft: '30px',
    borderRadius: '10px',
    border: 'none'
  },
  paper_personal: {
    flex: 1,
    overflow: 'hidden',
    height: '490px',
    maxWidth: '90vw',
    margin: 'auto',
    padding: '30px',
    borderRadius: '24px',
    background: '#FFFFFF',
    marginTop: '25px'
  },
  typography_personal: {
    fontWeight: 700,
    fontSize: '24px'
  },
  typography_view_personal: {
    fontWeight: 700,
    fontSize: '17px',
    float: 'right' as 'right',
    marginTop: '-36px',
    cursor: 'pointer',
    color: 'rgba(97, 97, 97, 1)'
  },
  img_shape: {
    paddingLeft: '13px'
  },
  paper_corporate: {
    overflow: 'hidden',
    flex: 1,
    height: '500px',
    maxWidth: '90vw',
    margin: 'auto',
    padding: '30px',
    borderRadius: '24px',
    background: '#FFFFFF',
    marginTop: '25px'
  },
  paper_xpand: {
    overflow: 'hidden',
    flex: 1,
    height: '500px',
    maxWidth: '90vw',
    margin: 'auto',
    padding: '30px',
    borderRadius: '24px',
    background: '#FFFFFF',
    marginTop: '25px'
  }
}

const useStyles = makeStyles((theme: any) => ({
  search: {
    position: 'relative',
    borderRadius: theme.shape.borderRadius,
    backgroundColor: '#fff',
    '&:hover': {
      backgroundColor: '#fff',
    },
    marginLeft: 0,
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      marginLeft: theme.spacing(1),
      width: 'auto',
    },
  },
  searchIcon: {
    padding: theme.spacing(0, 2),
    height: '100%',
    position: 'absolute',
    pointerEvents: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
}));


export const Search = ({ _onFocus }: any) => {
  const classes = useStyles();
  return (
    <Box className={classes.search}>
      <Box className={classes.searchIcon}>
        <SearchIcon />
      </Box>
      <TextField onFocus={_onFocus} className="Rounded" inputProps={{ maxlength: 50 }} style={{ width: '100%', borderRadius: '12px', marginTop: '3px' }} placeholder=" Search" variant="outlined" />

    </Box>
  )
}

export const SliderComponent = (data: any) => {
  const [librarydata, setLibrarydata] = React.useState<any>([]);

  useEffect(() => {
    setLibrarydata(data.data)
  }, []);

  let slider = React.createRef<any>();

  const next = () => {
    // @ts-ignore
    slider.slickNext();
  }
  const previous = () => {
    // @ts-ignore
    slider.slickPrev();
  }

  let settings = {
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: 4.5,
    slidesToScroll: 1,
    initialSlide: 0,
    responsive: [

      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          infinite: true,
          dots: false
        }
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          // initialSlide: 2,
          dots: false
        }
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          dots: false
        }
      }
    ]
  };



  return (
    <Slider {...settings} className='slider1'>
      {librarydata
        ? librarydata.map((data: any, index: any) => (
          <Box key={index} style={{ padding: "0 20px 0 20px", borderRadius: '25px 25px 0 0', marginTop: '20px' }} >
            {/* <Box className='overlay'></Box> */}
            <Box className="container1">
              <img src={data.image_url} className="image1" />
              <Box className="overlay1">
                <Typography variant='h5' style={{
                  fontSize: '26px', display: 'Inline-block', marginTop: '-51px',
                  color: '#fff', padding: '25px', fontWeight: 600, marginLeft: '-70px', position: 'fixed'
                }}>Title <br /> Caption</Typography>
              </Box>
            </Box>
          </Box>
        ))
        :
        <></>}
    </Slider>


  )
}


export const LineChart = ({ state }: any) => {
  const data = {
    labels: state?.map((data: any) => data.track_date),//['1 june', '2 june', '3 june', '4 june', '5 june', '6 june', '7 june', '8 june', '9 june', '10 june', '11 june', '12 june', '13 june'],
    responsive: true,// labels: ['1 june', '2 june', '3 june', '4 june', '5 june', '6 june', '7 june'],
    datasets: [
      // data:[50,40,65,55,45,80],
      {
        id: 1,
        label: '',
        data: state?.map((data: any) => data.count),//[50, 40, 65, 55, 45, 80],
        backgroundColor: 'transparent',
        borderColor: '#1fd1a8',
        pointBorderColor: 'transparent',
        pointBorderWidth: 4,
        tension: 0.5,
      },


    ]
  }

  const options: any = {
    plugins: {
      legend: false
    },
    scales: {
      x: {
        grid: {
          display: false
        }
      },
      y: {
        min: 0,
        max: 25,
        ticks: {
          stepSize: 5,
          callback: (value: any) => value
        },
        // grid:{
        //   borderDash:[10]
        // },
      }
    },
  };



  return (
    <>
      <Box style={{ width: '100%', height: '250px', marginTop: '20px' }}>
        <Line width="915px" height="250px" data={data} options={options}></Line>
      </Box>
    </>
  )
}



export const LineChart2 = ({ state }: any) => {
  const data = {
    responsive: true,// labels: ['1 june', '2 june', '3 june', '4 june', '5 june', '6 june', '7 june', '8 june', '9 june', '10 june', '11 june', '12 june', '13 june'],
    labels: state?.map((data: any) => data.track_date),//['1 june', '2 june', '3 june', '4 june', '5 june', '6 june', '7 june'],
    datasets: [

      {
        id: 1,
        label: '',
        data: state?.map((data: any) => data.count),//[35, 45, 50, 56, 45, 70],
        backgroundColor: 'transparent',
        borderColor: '#0786ed',
        pointBorderColor: 'transparent',
        pointBorderWidth: 4,
        tension: 0.5,
      },

    ]
  }

  const options: any = {
    plugins: {
      legend: false
    },
    scales: {
      x: {
        grid: {
          display: false
        }
      },
      y: {
        min: 0,
        max: 25,
        ticks: {
          stepSize: 5,
          callback: (value: any) => value
        },
        // grid:{
        //   borderDash:[10]
        // },
      }
    },
  };



  return (
    <>
      <Box style={{ width: '100%', height: '250px', marginTop: '20px' }}>
        <Line width="915px" height="250px" data={data} options={options}></Line>
      </Box>
    </>
  )
}



export const LineChart3 = ({ state }: any) => {
  const data = {
    labels: state?.map((data: any) => data.track_date),//['1 june', '2 june', '3 june', '4 june', '5 june', '6 june', '7 june', '8 june', '9 june', '10 june', '11 june', '12 june', '13 june'],
    responsive: true,
    // labels: ['1 june', '2 june', '3 june', '4 june', '5 june', '6 june', '7 june'],
    datasets: [
      // data:[50,40,65,55,45,80],
      {
        id: 1,
        label: '',
        data: state?.map((data: any) => data.count),//[50, 40, 65, 55, 45, 80],
        backgroundColor: 'transparent',
        borderColor: '#1fd1a8',
        pointBorderColor: 'transparent',
        pointBorderWidth: 4,
        tension: 0.5,
      },

    ]
  }

  const options: any = {
    plugins: {
      legend: false
    },
    scales: {
      x: {
        grid: {
          display: false
        }
      },
      y: {
        min: 0,
        max: 25,
        ticks: {
          stepSize: 5,
          callback: (value: any) => value
        },
        // grid:{
        //   borderDash:[10]
        // },
      }
    },
  };



  return (
    <>
      <Box style={{ width: '850px', height: '250px', marginTop: '20px' }}>
        <Line width="850px" height="250px" data={data} options={options}></Line>
      </Box>
    </>
  )
}



export const LineChart4 = ({ state }: any) => {
  const data = {

    responsive: true,// labels: ['1 june', '2 june', '3 june', '4 june', '5 june', '6 june', '7 june', '8 june', '9 june', '10 june', '11 june', '12 june', '13 june'],
    labels: state?.map((data: any) => data.track_date),//['1 june', '2 june', '3 june', '4 june', '5 june', '6 june', '7 june'],
    datasets: [

      {
        id: 1,
        label: '',
        data: state?.map((data: any) => data.count),//[35, 45, 50, 56, 45, 70],
        backgroundColor: 'transparent',
        borderColor: '#0786ed',
        pointBorderColor: 'transparent',
        pointBorderWidth: 4,
        tension: 0.5,
      },

    ]
  }

  const options: any = {
    plugins: {
      legend: false
    },
    scales: {
      x: {
        grid: {
          display: false
        }
      },
      y: {
        min: 0,
        max: 25,
        ticks: {
          stepSize: 5,
          callback: (value: any) => value
        },
        // grid:{
        //   borderDash:[10]
        // },
      }
    },
  };



  return (
    <>
      <Box style={{ width: '850px', height: '250px', marginTop: '20px' }}>
        <Line width="850px" height="250px" data={data} options={options}></Line>
      </Box>
    </>
  )
}

export const BarCart = ({ state, Week }: any) => {
  const data = {
    labels: state?.this_time_data?.map((data: any) => data.track_date),
    datasets: [
      {
        label: "My First dataset",
        events: ["click"],
        responsive: true,
        scales: {
          xAxes: [
            {
              type: "time",
              distribution: "series"
            }
          ]
        },
        backgroundColor: '#EF2B4B',
        tooltips: {
          mode: "index",
          intersect: true
        },
        plugins: {
          annotation: {
            annotations: [
              {
                type: "line",
                mode: "horizontal",
                scaleID: "y-axis-0",
                value: 62,
                borderColor: "rgb(255, 192, 192)",
                borderWidth: 4
              }
            ]
          }
        },
        data: state?.this_time_data?.map((data: any) => data.count)
      }
    ]
  }

  const data1 = {
    labels: state?.last_time_data?.map((data: any) => data.track_date),
    datasets: [
      {
        label: "My First dataset",
        events: ["click"],
        responsive: true,
        scales: {
          xAxes: [
            {
              type: "time",
              distribution: "series"
            }
          ]
        },
        backgroundColor: '#EF2B4B',
        tooltips: {
          mode: "index",
          intersect: true
        },
        plugins: {
          annotation: {
            annotations: [
              {
                type: "line",
                mode: "horizontal",
                scaleID: "y-axis-0",
                value: 62,
                borderColor: "rgb(255, 192, 192)",
                borderWidth: 4
              }
            ]
          }
        },
        data: state?.last_time_data?.map((data: any) => data.count)
      }
    ]
  }

  const options: any = {
    plugins: {
      legend: false
    },
    scales: {
      x: {
        grid: {
          display: false
        }
      },
      y: {
        min: 0,
        max: 25,
        ticks: {
          stepSize: 5,
          callback: (value: any) => value
        },
        // grid:{
        //   borderDash:[10]
        // },
      }
    },
  };


  return (
    <>
      <Box style={{ marginTop: '-15px', width: "100%", height: "100%" }} >

        {Week ?
          <Bar data={data} width="100%" height="100%" options={options}></Bar> :
          <Bar data={data1} width="100%" height="100%" options={options}></Bar>
        }
      </Box>
    </>
  )
}

export const BarCart1 = ({ state, Week }: any) => {
  const data = {
    labels: state?.this_time_data?.map((data: any) => data.track_date),

    datasets: [
      {
        label: "My First dataset",
        events: ["click"],
        responsive: true,
        scales: {
          xAxes: [
            {
              type: "time",
              distribution: "series"
            }
          ]
        },
        backgroundColor: '#EF2B4B',
        tooltips: {
          mode: "index",
          intersect: true
        },
        plugins: {
          annotation: {
            annotations: [
              {
                type: "line",
                mode: "horizontal",
                scaleID: "y-axis-0",
                value: 62,
                borderColor: "rgb(255, 192, 192)",
                borderWidth: 4
              }
            ]
          }
        },
        data: state?.this_time_data?.map((data: any) => data.count)

      }
    ]
  }


  const data1 = {
    labels: state?.last_time_data?.map((data: any) => data.track_date),

    datasets: [
      {
        label: "My First dataset",
        events: ["click"],
        responsive: true,
        scales: {
          xAxes: [
            {
              type: "time",
              distribution: "series"
            }
          ]
        },
        backgroundColor: '#EF2B4B',
        tooltips: {
          mode: "index",
          intersect: true
        },
        plugins: {
          annotation: {
            annotations: [
              {
                type: "line",
                mode: "horizontal",
                scaleID: "y-axis-0",
                value: 62,
                borderColor: "rgb(255, 192, 192)",
                borderWidth: 4
              }
            ]
          }
        },
        data: state?.last_time_data?.map((data: any) => data.count)

      }
    ]
  }



  const options: any = {
    plugins: {
      legend: false
    },
    scales: {
      x: {
        grid: {
          display: false
        }
      },
      y: {
        min: 0,
        max: 25,
        ticks: {
          stepSize: 5,
          callback: (value: any) => value
        },
        // grid:{
        //   borderDash:[10]
        // },
      }
    },
  };



  return (
    <>
      <Box style={{ marginTop: '0px' }} >
        {Week ?
          <Bar data={data} options={options}></Bar> :
          <Bar data={data1} options={options}></Bar>
        }
      </Box>
    </>
  )
}

// Customizable Area Start
const webStyle = {
  mainWrapper: {
    display: "flex",
    fontFamily: "Roboto-Medium",
    flexDirection: "column",
    alignItems: "center",
    paddingBottom: "30px",
    background: "#fff",
  },
  inputStyle: {
    borderBottom: "1px solid rgba(0, 0, 0, 0.6)",
    width: "100%",
    height: "100px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
  },
  buttonStyle: {
    width: "100%",
    height: "45px",
    marginTop: "40px",
    border: "none",
    backgroundColor: "rgb(98, 0, 238)",
  },
};
// Customizable Area End
