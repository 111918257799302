import React from "react";
// Customizable Area Start

import {
    Typography, InputAdornment,
    Paper,
    Box,
    TextField
} from "@material-ui/core";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { withStyles } from '@material-ui/core/styles';
import LinearProgress from "@material-ui/core/LinearProgress";
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Grid from '@material-ui/core/Grid';
import "./style.css";
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Checkbox from '@material-ui/core/Checkbox';
import SearchIcon from '@material-ui/icons/Search';
import { Search } from "@material-ui/icons";
import Pagination from '@material-ui/lab/Pagination';
import AdminDashboardController, { Props } from "./AdminDashboardController.web";
const img1 = require("../assets/Oval.png")
const img2 = require("../assets/Oval1.png")
const img3 = require("../assets/Oval2.png")
const img4 = require("../assets/award2.png")
const object = require("../assets/Object.png")
const SignIn = require("../assets/SignIn.png")
const Open = require("../assets/Open.png")
const Close = require("../assets/Close.png")
import RemindersWeb from "./Reminders.web";


const BorderLinearProgress = withStyles((theme) => ({
    root: {
        height: 55,
        borderRadius: 5,
    },
    colorPrimary: {
        backgroundColor: 'rgba(138, 80, 183, 0.6)',
    },
    bar: {
        borderRadius: 5,
        background: "linear-gradient(270deg, #6C328B 0%, #41328B 100%)",
    },
}))(LinearProgress);
// Customizable Area End
let id: any;
export default class AdminDashboard extends AdminDashboardController {
    constructor(props: Props) {

        super(props);
        // Customizable Area Start

        // Customizable Area End
    }


    // Customizable Area Start

    // Customizable Area End

    render() {

        return (
            // Customizable Area Start
            <>
                <Grid container>
                    <Grid item lg={12} md={12} sm={12} xs={12}>
                        <Typography style={{ fontFamily: 'Montserrat', fontSize: '48px', marginTop: '-24px', lineHeight: '72px', fontWeight: 600, color: '#11142D' }}>Xpand The Lives You Lead</Typography>

                        <Box className="mainHeder_home">
                            <Box className="header_Box">
                                <Tabs value={this.state.tabIndex}
                                    className="childTab1"
                                    TabIndicatorProps={{
                                        style: { backgroundColor: "transparent" }
                                    }}
                                    onChange={this.handleTabChange}>
                                    <Tab className={this.state.tabIndex === 0 ? "active raisedTab" : "noneActive"} style={{ backgroundColor: this.state.tabIndex === 0 ? 'white' : 'transparent', fontSize: "16px", fontWeight: "normal", marginLeft: '40px', borderRadius: '8px 8px 8px 8px' }} label="Branch" />
                                    <Tab className={this.state.tabIndex === 1 ? "active raisedTab" : "noneActive"} style={{ backgroundColor: this.state.tabIndex === 1 ? 'white' : 'transparent', fontSize: "16px", fontWeight: "normal", borderRadius: '8px 8px 8px 8px', marginLeft: '20px' }} label="Team" />
                                    <Tab className={this.state.tabIndex === 2 ? "active raisedTab" : "noneActive"} style={{ backgroundColor: this.state.tabIndex === 2 ? 'white' : 'transparent', fontSize: "16px", fontWeight: "normal", borderRadius: '8px 8px 8px 8px', marginLeft: '20px' }} label="User" />
                                </Tabs>

                                <Box sx={{ padding: 2 }}>

                                    {this.state.tabIndex === 0 && (
                                        <Paper
                                            // onClick={() => this.handleTabClick()} 
                                            style={{ position: 'absolute', zIndex: 999, width: '90%', height: 'auto', borderRadius: '12px', background: '#FFFFFF', marginLeft: '-32px' }}>
                                            <Grid container>
                                                <Grid item lg={6} md={6} sm={4} xs={4}></Grid>
                                                <Grid item lg={3} md={3} sm={4} xs={4}>
                                                    <Paper style={{ marginTop: '37px', width: '250px', float: 'right', marginRight: '60px', borderRadius: '20px', boxShadow: 'none' }}>
                                                        <Accordion style={{ boxShadow: 'none', position: 'inherit', borderRadius: '20px', border: '1px solid rgba(26, 26, 26, 0.12)' }}>
                                                            <AccordionSummary
                                                                expandIcon={<ExpandMoreIcon />}
                                                                aria-controls="panel1a-content"
                                                                id="panel1a-header"
                                                                style={{ display: "flex" }}
                                                            >
                                                                <Typography style={{ fontSize: '16px', fontWeight: 500 }}>Filter by:</Typography>
                                                            </AccordionSummary>
                                                            <AccordionDetails style={{ flexDirection: 'column', padding: 0 }}>
                                                                {this.state.folderData2.map((data: any) => (
                                                                    <div style={{ display: 'flex', marginLeft: '6%', marginBottom: '2%' }}>
                                                                        <Checkbox
                                                                            onChange={this.handleChange}
                                                                            color="primary"
                                                                            inputProps={{ 'aria-label': 'primary checkbox' }}
                                                                            style={{ paddingRight: '25px', marginLeft: '20px' }}
                                                                        />
                                                                        <Typography style={{ fontSize: '16px', fontWeight: 400, marginTop: '10px' }}>{data.name}</Typography>
                                                                    </div>
                                                                ))}
                                                            </AccordionDetails>
                                                        </Accordion>
                                                    </Paper>
                                                </Grid>
                                                <Grid item lg={3} md={3} sm={4} xs={4} className="list" style={{ marginLeft: '-35px' }}>
                                                    <Box className="search-outline">
                                                        <TextField
                                                            fullWidth
                                                            id="search"
                                                            name="search"
                                                            variant="outlined"
                                                            placeholder="Search"
                                                            style={{ width: '310px', borderRadius: '12px', marginTop: '34px' }}
                                                            InputLabelProps={{
                                                                shrink: true,
                                                            }}
                                                            className="search"
                                                            InputProps={{
                                                                startAdornment: (
                                                                    <InputAdornment position="start">
                                                                        <Search />
                                                                    </InputAdornment>
                                                                ),
                                                            }}
                                                        />
                                                    </Box>
                                                    <div style={{ marginTop: '-38px', marginLeft: '8px' }}>
                                                    </div>
                                                </Grid>
                                            </Grid>
                                            {this.state.folderData?.data?.map((data: any) => (
                                                <div style={{ display: 'flex', marginLeft: '6%', marginBottom: '2%', marginTop: '35px' }}>
                                                    <img src={Open} style={{ paddingRight: '25px' }} />
                                                    <Typography style={{ fontSize: '16px', fontWeight: 400 }}>{data.attributes.full_name}</Typography>
                                                </div>
                                            ))}
                                        </Paper>
                                    )}

                                    {this.state.tabIndex === 1 && (
                                        <Paper style={{ marginLeft: '-32px', position: 'absolute', zIndex: 999, width: '90%', height: 'auto', borderRadius: '12px', background: '#FFFFFF' }}>
                                            <Grid container>
                                                <Grid item lg={6} md={6} sm={4} xs={4}><Typography style={{ fontSize: '20px', fontWeight: 500, marginTop: '40px', marginLeft: '10%' }}>Team Name</Typography></Grid>
                                                <Grid item lg={3} md={3} sm={4} xs={4}>
                                                    <Paper style={{ marginTop: '37px', width: '250px', float: 'right', marginRight: '60px', borderRadius: '20px', boxShadow: 'none' }}>
                                                        <Accordion style={{ boxShadow: 'none', position: 'inherit', borderRadius: '20px', border: '1px solid rgba(26, 26, 26, 0.12)' }}>
                                                            <AccordionSummary
                                                                expandIcon={<ExpandMoreIcon />}
                                                                aria-controls="panel1a-content"
                                                                id="panel1a-header"
                                                                style={{ display: "flex" }}
                                                            >
                                                                <Typography style={{ fontSize: '16px', fontWeight: 500 }}>Filter by:</Typography>
                                                            </AccordionSummary>

                                                            <AccordionDetails style={{ flexDirection: 'column', padding: 0 }}>
                                                                {this.state.folderData2.map((data: any) => (
                                                                    <div key={data.id} style={{ display: 'flex', marginLeft: '6%', marginBottom: '2%' }}>
                                                                        <Checkbox
                                                                            onChange={this.handleChange}
                                                                            color="primary"
                                                                            inputProps={{ 'aria-label': 'primary checkbox' }}
                                                                            style={{ paddingRight: '25px', marginLeft: '20px' }}
                                                                        />
                                                                        <Typography style={{ fontSize: '16px', fontWeight: 400, marginTop: '10px' }}>{data.name}</Typography>
                                                                    </div>
                                                                ))}
                                                            </AccordionDetails>
                                                        </Accordion>
                                                    </Paper>
                                                </Grid>
                                                <Grid item lg={3} md={3} sm={4} xs={4} className="list" style={{ marginLeft: '-35px' }}>
                                                    <Box className="search-outline">
                                                        <TextField
                                                            fullWidth
                                                            id="search"
                                                            name="search"
                                                            variant="outlined"
                                                            placeholder="Search"
                                                            style={{ width: '310px', borderRadius: '12px', marginTop: '34px' }}
                                                            InputLabelProps={{
                                                                shrink: true,
                                                            }}
                                                            className="search"
                                                            InputProps={{
                                                                startAdornment: (
                                                                    <InputAdornment position="start">
                                                                        <Search />
                                                                    </InputAdornment>
                                                                ),
                                                            }}
                                                        />
                                                    </Box>
                                                </Grid>
                                            </Grid>
                                            {this.state.folderData1.map((data: any) => (
                                                <Paper key={data.id} style={{ display: 'flex', borderRadius: '10px', width: '95%', margin: '20px auto', height: '75px', marginBottom: '2%', boxShadow: 'rgb(214, 214, 214) 0px 0px 7px' }}>
                                                    <Checkbox
                                                        onChange={(event) => this.handleChange1(event,data.id)}
                                                        color="primary"
                                                        inputProps={{ 'aria-label': 'primary checkbox' }}
                                                        style={{ paddingRight: '25px', marginLeft: '20px' }}
                                                    />
                                                    <Typography style={{ fontSize: '16px', fontWeight: 400, fontFamily: 'Montserrat', marginTop: '25px' }}>{data.team}</Typography>
                                                </Paper>
                                            ))}
                                        </Paper>
                                    )}

                                    {this.state.tabIndex === 2 && (
                                        <Paper style={{ marginLeft: '-32px', position: 'absolute', zIndex: 999, width: '90%', height: 'auto', borderRadius: '12px', background: '#FFFFFF' }}>
                                            <Grid container>
                                                <Grid item lg={6} md={6} sm={4} xs={4}><Typography style={{ fontSize: '20px', fontWeight: 500, marginTop: '40px', marginLeft: '10%' }}>User Name</Typography></Grid>
                                                <Grid item lg={3} md={3} sm={4} xs={4}>
                                                    <Paper style={{ marginTop: '37px', width: '250px', float: 'right', marginRight: '35px', boxShadow: 'none' }}>
                                                        <Accordion style={{ boxShadow: 'none', position: 'inherit', borderRadius: '20px', border: '1px solid rgba(26, 26, 26, 0.12)' }}>
                                                            <AccordionSummary
                                                                expandIcon={<ExpandMoreIcon />}
                                                                aria-controls="panel1a-content"
                                                                id="panel1a-header"
                                                                style={{ display: "flex" }}
                                                            >
                                                                <Typography style={{ fontSize: '16px', fontWeight: 500 }}>Filter by:</Typography>
                                                            </AccordionSummary>

                                                            <AccordionDetails style={{ flexDirection: 'column', padding: 0 }}>
                                                                {this.state.folderData2.map((data: any) => (
                                                                    <div style={{ display: 'flex', marginLeft: '6%', marginBottom: '2%' }}>
                                                                        <Checkbox
                                                                            onChange={this.handleChange}
                                                                            color="primary"
                                                                            inputProps={{ 'aria-label': 'primary checkbox' }}
                                                                            style={{ paddingRight: '25px', marginLeft: '20px' }}
                                                                        />
                                                                        <Typography style={{ fontSize: '16px', fontWeight: 400, marginTop: '10px' }}>{data.name}</Typography>
                                                                    </div>
                                                                ))}
                                                            </AccordionDetails>
                                                        </Accordion>
                                                    </Paper>
                                                </Grid>
                                                <Grid item lg={3} className="list" md={3} sm={4} xs={4}>
                                                    <Box className="search-outline">
                                                        <TextField
                                                            onChange={this.searchData}
                                                            value={this.state.userSearch}
                                                            inputProps={{ maxlength: 50 }}
                                                            fullWidth
                                                            id="search"
                                                            name="search"
                                                            variant="outlined"
                                                            placeholder="Search"
                                                            style={{ width: '310px', borderRadius: '12px', marginTop: '34px' }}
                                                            InputLabelProps={{
                                                                shrink: true,
                                                            }}
                                                            className="search"
                                                            InputProps={{
                                                                startAdornment: (
                                                                    <InputAdornment position="start">
                                                                        <Search />
                                                                    </InputAdornment>
                                                                ),
                                                            }}
                                                        />
                                                    </Box>
                                                </Grid>
                                            </Grid>
                                            {this.state.userData?.data?.map((data: any) => {
                                                return (
                                                    <Paper style={{ display: 'flex', borderRadius: '10px', width: '95%', margin: '20px auto', height: '75px', marginBottom: '2%', boxShadow: 'rgb(214, 214, 214) 0px 0px 7px' }}>
                                                        <Checkbox
                                                            onChange={(event) => this.handleChange1(event,data.id)}
                                                            color="primary"
                                                            inputProps={{ 'aria-label': 'primary checkbox' }}
                                                            style={{ paddingRight: '25px', marginLeft: '20px' }}
                                                        />
                                                        <Typography onClick={() => this.props.history.push(`/AdminCustomisableUserProfiles/${data.id}`)} style={{cursor:'pointer', fontSize: '16px', fontWeight: 400, fontFamily: 'Montserrat', marginTop: '25px' }}>{data.attributes.full_name}</Typography>
                                                    </Paper>
                                                )
                                            })}
                                        </Paper>
                                    )}

                                </Box>
                            </Box>
                        </Box>

                        <Grid container spacing={3} style={{ marginTop: '50px' }}>
                            <Grid item lg={6} md={6} sm={12} xs={12}>
                                <Paper style={{ width: '100%', height: '225px', borderRadius: '16px', background: 'linear-gradient(153.43deg, #6C328B 16.67%, #6C328B 16.67%, #5C328B 44.34%, #41328B 100%)' }}>
                                    <div style={{ paddingTop: '25px', paddingLeft: '25px', display: 'flex', }}>
                                        <img src={object} style={{ cursor: 'pointer' }} />
                                        <Typography style={{ fontFamily: 'Montserrat', color: '#fff', fontSize: '16px', fontWeight: 600, marginTop: '10px', marginLeft: '15px', cursor: 'pointer' }}>Courses Completed</Typography>
                                    </div>
                                    <Typography style={{ fontFamily: 'Montserrat', textAlign: 'center', color: '#fff', fontSize: '54px', display: 'flex', fontWeight: 700, marginLeft: '40%', marginTop: '6px' }}>{this.state.homeScreenData.complete_count}<Typography style={{ color: '#fff', fontSize: '24px', fontWeight: 400, marginTop: '30px' }}>/{this.state.homeScreenData.total_count}</Typography></Typography>
                                </Paper>
                            </Grid>
                            <Grid item lg={6} md={6} sm={12} xs={12}>
                                <Paper style={{ width: '100%', height: '225px', borderRadius: '16px', background: 'linear-gradient(153.43deg, #6C328B 16.67%, #6C328B 16.67%, #5C328B 44.34%, #41328B 100%)' }}>
                                    <div style={{ paddingTop: '25px', paddingLeft: '25px', display: 'flex', }}>
                                        <img src={SignIn} style={{ cursor: 'pointer' }} />
                                        <Typography style={{ fontFamily: 'Montserrat', color: '#fff', fontSize: '16px', fontWeight: 600, marginTop: '10px', marginLeft: '15px', cursor: 'pointer' }}>Sign ins</Typography>
                                    </div>
                                    <Typography style={{ fontFamily: 'Montserrat', textAlign: 'center', color: '#fff', fontSize: '54px', display: 'flex', fontWeight: 700, marginLeft: '45%', marginTop: '6px' }}>{this.state.homeScreenData.total_signin}</Typography>
                                </Paper>
                            </Grid>
                        </Grid>

                        <Grid container spacing={3} style={{ marginTop: '50px' }}>
                            <Grid item lg={12} md={12} sm={12} xs={12}>
                                <Paper style={{ width: '100%', height: 'auto', background: '#FFFFFF', boxShadow: '0px 4px 5px rgba(0, 0, 0, 0.14)', borderRadius: '24px' }}>
                                    <Grid container spacing={1}>
                                        <Grid item lg={4} md={4} sm={4} xs={4}><Typography style={{ fontFamily: 'Montserrat', fontSize: '18px', fontWeight: 600, textAlign: 'center', marginRight: '50px' }}>Users</Typography></Grid>
                                        <Grid item lg={2} md={2} sm={2} xs={2}><Typography style={{ fontFamily: 'Montserrat', fontSize: '18px', fontWeight: 600, textAlign: 'center' }}>Learn</Typography></Grid>
                                        <Grid item lg={4} md={4} sm={4} xs={4}><Typography style={{ fontFamily: 'Montserrat', fontSize: '18px', fontWeight: 600, textAlign: 'center', marginRight: '0px' }}>Reward</Typography></Grid>
                                        <Grid item lg={2} md={2} sm={2} xs={2}><Typography style={{ fontFamily: 'Montserrat', fontSize: '18px', fontWeight: 600, textAlign: 'center', marginRight: '30%' }}>Achievement</Typography></Grid>
                                    </Grid>
                                    <div>
                                        {this.state.homeScreenData?.res?.map((data: any, index: any) => {
                                            return (
                                                <Paper style={{ width: '95%', height: '70px', background: '#FFFFFF', boxShadow: '0px 0px 7px #d6d6d6', borderRadius: '10px', margin: '35px auto' }}>
                                                    <Grid container spacing={1}>
                                                        <Grid item lg={4} md={4} sm={4} xs={4}>
                                                            <div style={{ display: 'flex' }}>
                                                                <img src={data.image === null ? 'https://picsum.photos/536/354' : data.image} style={{ padding: '7px', marginLeft: '55px', width: '50px', height: '50px', borderRadius: '25px', marginTop: '5px' }} />
                                                                <Typography onClick={() => this.props.history.push(`/AdminCustomisableUserProfiles/${data.account_id}`)} style={{ cursor: 'pointer', fontFamily: 'Montserrat', fontSize: '16px', fontWeight: 500, marginTop: '18px', paddingLeft: '20px' }}>{data.full_name}</Typography>
                                                            </div>
                                                        </Grid>
                                                        <Grid item lg={2} md={2} sm={2} xs={2}>
                                                            <div style={{ marginTop: '3px' }}>
                                                                <BorderLinearProgress
                                                                    variant="determinate"
                                                                    value={(data.completedcourse_count / data.totalcourse_count) * 100}
                                                                />
                                                                <Typography style={{ fontFamily: 'Montserrat', marginTop: '-40px', marginLeft: '5%', position: 'absolute', color: '#fff' }}>{data.completedcourse_count}/{data.totalcourse_count}</Typography>
                                                            </div>
                                                        </Grid>
                                                        <Grid item lg={4} md={4} sm={4} xs={4}>
                                                            <Grid container spacing={1}>
                                                                <Grid item lg={1} md={1}></Grid>
                                                                <Grid item lg={4} md={4} sm={5} xs={5} style={{ display: 'flex', justifyContent: 'flex-end' }}><div style={{ width: '68px', height: '55', background: '#FF8B3F', borderRadius: '8px', marginTop: '3px' }}>
                                                                    <Typography style={{ fontFamily: 'Montserrat', textAlign: 'center', fontSize: '16px', color: '#fff', marginTop: '10px' }}>{data.reward_count}</Typography>
                                                                    <Typography style={{ fontFamily: 'Montserrat', textAlign: 'center', fontSize: '10px', color: '#fff' }}>Started</Typography>
                                                                </div></Grid>
                                                                <Grid item lg={3} md={3} sm={2} xs={2}><div style={{ width: '68px', height: '55', background: '#F42074', borderRadius: '8px', margin: '3px auto' }}>
                                                                    <Typography style={{ fontFamily: 'Montserrat', textAlign: 'center', fontSize: '16px', color: '#fff', paddingTop: '10px' }}>{data.earned_reward}</Typography>
                                                                    <Typography style={{ fontFamily: 'Montserrat', textAlign: 'center', fontSize: '10px', color: '#fff' }}>Earned</Typography>
                                                                </div></Grid>
                                                                <Grid item lg={2} md={2} sm={5} xs={5}><div style={{ width: '68px', height: '55', background: '#6C328B', borderRadius: '8px', marginTop: '3px' }}>
                                                                    <Typography style={{ fontFamily: 'Montserrat', textAlign: 'center', fontSize: '16px', color: '#fff', paddingTop: '10px' }}>{data.approved_reward}</Typography>
                                                                    <Typography style={{ fontFamily: 'Montserrat', textAlign: 'center', fontSize: '10px', color: '#fff' }}>Approved</Typography>
                                                                </div></Grid>
                                                            </Grid>
                                                        </Grid>
                                                        <Grid item lg={2} md={2} sm={2} xs={2}>
                                                            <div style={{ display: 'flex', justifyContent: 'space-around', marginTop: '12px' }}>
                                                                <img src={img4} />
                                                            </div>
                                                            <div style={{ width: '13px', height: '13px', background: 'rgba(108, 50, 139, 1)', display: 'flex', margin: 'auto', borderRadius: '10px', marginTop: '-30px' }}>
                                                                <Typography style={{ fontFamily: 'Montserrat', textAlign: 'center', fontSize: '9px', color: '#fff', fontWeight: 600, margin: 'auto' }}>{data.achiv_count}</Typography>
                                                            </div>
                                                        </Grid>
                                                    </Grid>

                                                </Paper>
                                            )
                                        })}
                                    </div>
                                    <Box style={{ display: 'flex', justifyContent: 'space-around', paddingBottom: '15px' }}>
                                        <Pagination page={this.state.page} onChange={this.pagination} count={this.state.homeScreenData?.total_pages} shape="rounded" color="primary" />
                                    </Box>
                                </Paper>
                            </Grid>
                        </Grid>

                    </Grid>

                </Grid>
                <RemindersWeb
                    userRole="super_admin"
                    fullScreen={this.props.fullScreen}
                    navigation={this.props.navigation}
                />
            </>

            // Customizable Area End
        );
    }
}

// Customizable Area Start














// Customizable Area End 