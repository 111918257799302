// Customizable Area Start
import React from "react";
import { Box, Button, Grid, TextField, Typography } from "@material-ui/core";
import { purple } from '@material-ui/core/colors';
import { withStyles } from "@material-ui/core/styles";

import FormControl from '@material-ui/core/FormControl';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import CloseIcon from '@material-ui/icons/Close';
import { Formik } from "formik";
import ReactQuill from "react-quill";
import * as yup from "yup";
import AddNotesModalController, {
  Props
} from "./AddNotesModalController";
import './style.css';
const Avatar = require("../assets/avatar.jpg");
const Profile = require("../assets/profile.webp");
const Edit = require("../assets/edit.png");
const Upload_icon = require("../assets/Upload_icon.png")
const delete_image = require("../assets/Notes_delete.png")
const edit = require("../assets/Notes_edit.png");
export default class AddNotesModal extends AddNotesModalController {
  constructor(props: Props) {
    super(props);

  }


  handleImage = (e: any) => {
    console.log(e.target.files[0], e.target.files[0].name)
    this.setState({ noteImage: e.target.files })
  }

  toolbarOptions: any = [["bold", "italic", "underline", "strike"],
  [{ list: "ordered" }, { list: "bullet" }],
  [{ indent: "-1" }, { indent: "+1" }],
  [{ header: [1, 2, 3, 4, 5, 6, false] }],
  [{ align: [] }],
  ["clean"],
  ];


  modules = {
    syntax: false,
    toolbar: this.toolbarOptions,
    clipboard: {
      matchVisual: false,
    },
  };

  formats: any = ["header", "font", "size", "bold", "italic", "underline", "strike", "blockquote", "list", "bullet", "indent", "link", "image", "video",];



  render() {
    // console.log(this.state.noteImage)
    const { imageDemo, image } = this.state

    const onImageChange = (event: any) => {
      console.log(event.target.files)
      this.setState({
        imageDemo: URL.createObjectURL(event.target.files[0]),
        image: event.target.files[0]
      })
      console.log(this.state.imageDemo, this.state.image, 'ppppppppppppppppppppppp');
    }

    const onImageDelete = () => {
      this.setState({ imageDemo: "" })
    }
    return (

      <Box style={{ width: "75vw", marginTop: '15px', backgroundColor: "white", padding: '20px', borderRadius: "20px" }}>
        <Box style={{ display: 'flex', justifyContent: 'space-between' }}>
          <Typography variant="h5" style={{ fontWeight: "bold" }}>Add New Note</Typography>
          <Button style={{ borderRadius: "50px", boxShadow: 'rgb(243 239 239 / 20%) 0px 0px 10px 0px, rgb(241 239 239 / 19%) 0px 0px 5px 0px', width: "40px", height: '60px' }} onClick={this.props.handleClose} ><CloseIcon /></Button>
        </Box>

        <Formik
          initialValues={
            {
              title: '',
              type: '',
              description: '',
              link: "",
            }
          }
          onSubmit={values => {
            //  console.log(values, 'form data test');
            this.createNotes(values);

           

          }}/*  */
          validationSchema={yup.object().shape({
            type: yup.string().required('Type is required'),
            title: yup.string().required('Title is required'),
            description: yup
              .string()
              .required('Description is required')
              .min(50, 'Description is too short (minimum is 50 characters)')
            ,
          })}
        >
          {props => {
            const {
              values,
              touched,
              errors,
              dirty,
              isSubmitting,
              setFieldValue,
              handleChange,
              handleBlur,
              handleSubmit,
              resetForm
            } = props;
            return (
              <form className="regform" onSubmit={handleSubmit}>
                <Box>
                  {/* <Box style={{ marginTop: "10px" }}>
                  <Typography variant="subtitle2" style={{ marginBottom: "5px" }}>Title</Typography>
                  <TextField fullWidth placeholder="Title of the Note" variant="outlined" size="small"
                   name="title"
                   value={values.title}
                   onChange={handleChange}
                   error={touched.title && Boolean(errors.title)}
                   helperText={touched.title && errors.title}
                  />
                  <LinkIcon className='addnoteicon linkicon' />
                </Box> */}
                  <Grid container spacing={2}>
                    <Grid item lg={6} md={6} sm={12} xs={12}>
                      <Box style={{ marginTop: "10px" }}>
                        <Typography variant="subtitle2" style={{ marginBottom: "5px" }}>Title</Typography>
                        <TextField fullWidth className="title_edit" placeholder="Title of the Note" variant="outlined" size="small"
                          name="title"
                          value={values.title}
                          onChange={handleChange}
                          error={touched.title && Boolean(errors.title)}
                          helperText={touched.title && errors.title}
                        />
                        {/* <LinkIcon className='addnoteicon linkicon' /> */}
                      </Box>
                    </Grid>
                    <Grid item lg={6} md={6} sm={12} xs={12}>
                      <Box style={{ marginTop: "10px" }}>
                        <Typography variant="subtitle2" style={{ marginBottom: "5px" }}>Type</Typography>

                        <FormControl fullWidth>
                          {/* <InputLabel id="demo-simple-select-label">Age</InputLabel> */}
                          <Select
                            id="demo-simple-select"
                            style={{ borderRadius: '15px' }}
                            variant="outlined"
                            name="type"
                            value={values.type}
                            onChange={handleChange}
                            error={touched.type && Boolean(errors.type)}


                          >
                            <MenuItem value={1}>Public</MenuItem>
                            <MenuItem value={2}>Private</MenuItem>
                          </Select>
                          {errors.type &&
                            touched.type && (
                              <div style={{
                                color: '#f44336', marginTop: '4px', marginLeft: '4px', fontSize: '12px'
                              }}>{errors.type}</div>
                            )}
                        </FormControl>
                      </Box>
                    </Grid>
                  </Grid>


                  {/* <Box style={{ marginTop: "10px" }}>
                  <Typography variant="subtitle2" style={{ marginBottom: "5px" }}>Add Image</Typography>
                 
                  <Box style={{width:"80px",height:"80px",position:"relative",display:'flex',alignItems:"center"}}>
                  {
                    imageDemo ?
                    <img src={imageDemo} className="shadowStyle" height="80px" width="80px" style={{borderRadius:"10px"}}/>
                    :
                    <img src={Profile} className="shadowStyle" height="80px" width="80px" style={{borderRadius:"10px"}}/>
                  }
                    
                    <label htmlFor="file" >                   
                        <Typography variant="subtitle2" className="imageButton">Add</Typography>
                   </label>
                    <input id="file" type="file" accept="image/*" style={{display:'none'}} onChange={onImageChange}/>
                </Box>
                
                </Box> */}
                  <Box style={{ marginTop: "10px" }}>
                    <Typography variant="subtitle2" style={{ marginBottom: "5px" }}>Add Image</Typography>

                    <Box style={{ width: "100%", height: "150px", overflow: 'hidden', borderRadius: '10px', flexDirection: 'column', justifyContent: 'center', position: "relative", display: 'flex', alignItems: "center", backgroundColor: '#E6E8EC', boxShadow: '0px 0px 16px rgba(191, 190, 190, 0.350581' }}>
                      {imageDemo
                        ?
                        <>
                          <img src={imageDemo} className="shadowStyle" height="350px" width="100%" style={{ borderRadius: "10px", objectFit: 'cover' }} />
                          <label style={{ width: '50px', height: '50px', borderRadius: '30px', padding: '12px', cursor: 'pointer', backgroundColor: '#fff', position: 'absolute', marginTop: '-7%', marginLeft: '75%', textAlign: 'center' }} htmlFor="file" >
                            <Box ><img style={{ width: '70%', marginTop: '3px' }} src={edit} /></Box>
                          </label>
                          <input id="file" type="file" accept="image/*" style={{ display: 'none' }} onChange={onImageChange} />
                          <Box onClick={() => onImageDelete()} style={{ width: '50px', height: '50px', borderRadius: '30px', padding: '12px', cursor: 'pointer', backgroundColor: '#EF2B4B', position: 'absolute', marginTop: '-7%', marginLeft: '90%', textAlign: 'center' }}><img style={{ marginTop: '3px' }} src={delete_image} /></Box>
                        </>
                        :
                        <>
                          {
                            imageDemo ?
                              <>
                                <img src={imageDemo} className="shadowStyle" height="350px" width="100%" style={{ borderRadius: "10px", objectFit: 'cover' }} />
                                {/* <Box style={{ width: '36px', height: '36px', borderRadius: '22px', backgroundColor: '#fff' }}></Box> */}
                              </>

                              :
                              <>
                                <label htmlFor="file" >

                                  <img src={Upload_icon} />


                                </label>
                                <input id="file" type="file" accept="image/*" style={{ display: 'none' }} onChange={onImageChange} />
                                <br />
                                <Typography style={{ fontSize: '14px', fontWeight: 500, lineHeight: '10px', color: '#B5B5BE' }}>Drag and Drop or Browse to upload</Typography>
                                <br />
                                <Typography style={{ fontSize: '14px', fontWeight: 500, lineHeight: '0px', color: '#B5B5BE' }}>Upload upto 15 MB and file dimension should be 768*1024</Typography>
                              </>
                          }
                        </>
                      }
                    </Box>


                  </Box>

                  {/* <Box style={{ marginTop: "10px" }}>
                  <Typography variant="subtitle2" style={{ marginBottom: "5px" }}>Add Links</Typography>
                  <TextField fullWidth variant="outlined" placeholder="www.google.com" size="small" 
                   name="link"
                   value={values.link}
                   onChange={handleChange}
                   error={touched.link && Boolean(errors.link)}
                   helperText={touched.link && errors.link}
                  />
                  <AttachmentOutlinedIcon className='addnoteicon attachicon' />
                </Box> */}
                  <Box style={{ marginTop: "10px" }}>
                    <Typography variant="subtitle2" style={{ marginBottom: "5px" }}>Description</Typography>
                    {/* <TextField fullWidth multiline  rows={4} placeholder="Write Here" variant="outlined" size="small" 
                  name="description"
                  value={values.description}
                  onChange={handleChange}
                  error={touched.description && Boolean(errors.description)}
                  helperText={touched.description && errors.description}
                  /> */}
                    <ReactQuill
                      className="react-quill"
                      modules={this.modules}
                      theme="snow"
                      value={values.description}
                      onChange={(value) => setFieldValue("description", value)}
                    />
                  </Box>
                  <Box style={{ marginTop: "10px" }}>
                    <ColorButton variant="contained" fullWidth style={{ fontSize: "14px", fontWeight: "bold" }} type="submit">Create New Note</ColorButton>
                  </Box>
                </Box>
              </form>
            )
          }}
        </Formik>
      </Box>

    );
  }
}


const ColorButton = withStyles((theme) => ({
  root: {
    backgroundColor: purple[800],
    color: "white",
    borderRadius: "10px",
    height: "50px",
    borderColor: purple[700],
    '&:hover': {
      borderColor: purple[500],
      color: "white",
      backgroundColor: purple[600],
    },
  },
}))(Button);


// Customizable Area End
