import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { toast } from "react-toastify";
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  location: any;
  history: any;
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  data: any
  image: any
  EditImage: any
  filename: any
  filenameEdit: any
  Editfile: any
  imgUrl: any
  EditimgUrl: any
  img: any
  file: any
  link:string
  id: any,
  EditId: any,
  isFreeTrial: boolean,
  loading: boolean,
  setImage: string,
  ApiImage:string,
  editVideoData: any,
  themeMode: boolean,
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class AddvideoController extends BlockComponent<Props, S, SS> {
  // Customizable Area Start
  getAddVideoApiCallId: string
  EditVideo: string
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    this.subScribedMessages = [
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.NavigationPayLoadMessage),
      getName(MessageEnum.RestAPIResponceSuccessMessage)
      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start
      data: [],
      image: "",
      EditImage: "",
      filename: "",
      filenameEdit: "",
      imgUrl: "",
      EditimgUrl: "",
      link:"",
      img: "",
      file: "",
      Editfile: "",
      id: "",
      EditId: "",
      setImage: '',
      ApiImage:'',
      isFreeTrial: false,
      loading: false,
      editVideoData: {},
      themeMode: false,
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );
      let responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );


      if (this.getAddVideoApiCallId === apiRequestCallId) {
        if (responseJson && responseJson.data) {
          console.log(responseJson.data, 'datadaddadada');
          toast.success("Video Add successfuly", { delay: 1000 });
          this.props.history.push(`/ManageContentFolder/${responseJson.data.attributes.folder_id}`, { id: responseJson.data.attributes.folder_id })
        }
        else if (responseJson.errors) {
          let value = responseJson.errors.map((item: any) => Object.keys(item))
          toast.error(responseJson.errors[0][value[0]], { delay: 1000 });
        }
      }
      if (this.EditVideo === apiRequestCallId) {
        console.log(responseJson, 'datatattatat')
        this.setState({ editVideoData: responseJson })
      }
    }
    runEngine.debugLog("Message Recived", message);
    // Customizable Area End
  }

  // Customizable Area Start
  

  onImageChange = (event: any) => {
    this.setState({
      setImage: URL.createObjectURL(event.target.files[0]),
      ApiImage: event.target.files[0]
    },()=>{

    })
  }

  handleImageChange = (e: any) => {
    const files = e.target.files[0];
    console.log(files, 'files__files====');

    if (files === undefined) {
      return;
    }
    const MIN_FILE_SIZE = 30720;
    const fileSizeKiloBytes = files.size / 1024;
    if (fileSizeKiloBytes >= MIN_FILE_SIZE) {
      toast.error("Please upload a file smaller than 30 MB", { delay: 2000 });
      return;
    }
    let filenames = "";
    filenames += files.name + "\n";
    this.setState({ Editfile: filenames })
    console.log(filenames, 'filename_local');
    // this.getStockImage()
    this.setState({ filenameEdit: filenames, EditImage: URL.createObjectURL(files), EditimgUrl: "", Editfile: files, EditId: "" })
    console.log(this.state.filenameEdit, 'filename_local');
    console.log(this.state.Editfile, 'filename_local');

  }



  // doPostAddVideo(data: any) {
  //   console.log("<============================>", data.title, 'formdata,formdata,formdataformdata');
  //   let formdata = new FormData();
  //   formdata.append('title', data.title);
  //   formdata.append('description', data.description);
  //   formdata.append('link', "http:test.com");
  //   formdata.append('folder_id', this.props.history.location.state.id);
  //   formdata.append('video_link', data.video_text_url);
  //   formdata.append('video', this.state.filename);
  //   console.log("jvvbbubuiu iijijpo jpj  p9j====> formdata", formdata);

  //   const header = {
  //     ContentType: "multipart/form-data",
  //     token: localStorage.getItem("token"),
  //   };
  //   const httpBody = formdata;
  //   console.log("jvvbbubuiu iijijpo jpj  p9j====> formdata", httpBody);
  //   const requestMessage = new Message(
  //     getName(MessageEnum.RestAPIRequestMessage)

  //   );
  //   requestMessage.addData(
  //     getName(MessageEnum.RestAPIRequestHeaderMessage),
  //     header
  //   );
  //   this.getAddVideoApiCallId = requestMessage.messageId
  //   requestMessage.addData(
  //     getName(MessageEnum.RestAPIResponceEndPointMessage),
  //     `bx_block_attachment/library_video`
  //   );
  //   requestMessage.addData(
  //     getName(MessageEnum.RestAPIRequestMethodMessage),
  //     'Post'
  //   );
  //   requestMessage.addData(
  //     getName(MessageEnum.RestAPIRequestBodyMessage),
  //     httpBody
  //   );
  //   runEngine.sendMessage(requestMessage.id, requestMessage);
  //   console.log("jvvbbubuiu iijijpo jpj  p9j====> formdata", requestMessage);
  //   return requestMessage.messageId;
  // }

  addVideo = (data:any) => {
    this.doPostAddVideo({
      contentType: undefined,
      method: 'POST',
      endPoint: `bx_block_attachment/library_video`,
      body:data
  });
  }
  doPostAddVideo = (data: any) => {
    const { method, endPoint, body } = data;
    console.log("filename_local",data);
    
    const header = {
      "Content-Type": undefined,
      token: localStorage.getItem("token"),
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
  
  );
  requestMessage.addData(
  getName(MessageEnum.RestAPIRequestHeaderMessage),
  JSON.stringify(header)
  );
  this.getAddVideoApiCallId = requestMessage.messageId
  requestMessage.addData(
  getName(MessageEnum.RestAPIResponceEndPointMessage),
  endPoint
  );
  requestMessage.addData(
  getName(MessageEnum.RestAPIRequestMethodMessage),
  method
  );
  body &&
  requestMessage.addData(
    getName(MessageEnum.RestAPIRequestBodyMessage),
    body
  );
  runEngine.sendMessage(requestMessage.id, requestMessage);
  return requestMessage.messageId;
  }

  getEditVideoData = async () => {
    const header = {
      "Content-Type": "application/json",
      token: localStorage.getItem("token"),
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.EditVideo = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_attachment/library_video/${this.props.navigation.getParam('id')}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      header
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "get"
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  // Customizable Area End
}
