import React from "react";
import {
  Box,
  Button,
  Typography,
  // Customizable Area Start
  Grid,
  Paper,
  CircularProgress
  // Customizable Area End
} from "@material-ui/core";

// Customizable Area Start
import { createTheme, ThemeProvider, makeStyles, withStyles } from "@material-ui/core/styles";
import { purple } from '@material-ui/core/colors';
import './style.css'
const cardBackground = require("../assets/cardBackground.png");
// Customizable Area End

import CustomisableUserProfilesController, {
  Props,
} from "./CustomisableUserProfilesController.web";

export default class InsightOverview extends CustomisableUserProfilesController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  async componentDidMount() {
    this.getRecentInsightsData();
    this.getRecentActivityData();
    this.getUserProfileData();
  }

  handleTab = (value: any) => {
    this.getAllNotesData('', value == 0 ? '' : value == 1 ? 'personal' : 'course');
    this.setState({
      handleTabValue: value,
      searchValue: ''
    })
  }
  handleSearch = (e: any) => {
    this.getAllNotesData(e.target.value, this.state.handleTabValue == 0 ? '' : this.state.handleTabValue == 1 ? 'personal' : 'course');
    this.setState({
      searchValue: e.target.value
    })
  }

  // Customizable Area End
  render() {
    const { userProfileData, searchValue, recentActivityData, recentInsightsData, recentInsightLoading, recentLoading } = this.state;


    return (
      // Customizable Area Start

      <Grid container>
        {console.log(this.state.isNoteModalOpen)
        }
        <Typography variant="h3" className="profile_header">Insights Overview</Typography>
        <Grid item xs={12}>
          <Grid item xs={12} style={{ marginTop: "30px", background: "#f6f6f6", padding: "15px 18px", borderRadius: '15px' }}>
            <Box style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
              <Typography variant="h6" className="profileName">
                Overall Insights
              </Typography>

            </Box>
            <Grid container style={{ marginTop: "30px" }}>
              <Grid item xs={12} style={{ display: 'flex' }} >
                <Grid container spacing={2} justifyContent="center">
                  <Grid item style={{ cursor: 'pointer' }}>
                    <Box className="mask" style={{ backgroundImage: `url(${cardBackground})` }} onClick={() => this.props.navigation.navigate("Reports")}>
                      <Box style={{ padding: "20px" }}>
                        <Typography variant="body1" style={{ color: "white", fontWeight: "bold", width: "150px", height: "50px" }}>
                          Course Completed
                        </Typography>
                        <Box style={{ display: "flex", flexDirection: "row", alignItems: "flex-end", paddingTop: "50px", justifyContent: "center" }}>
                          <Typography variant="h3" style={{ color: "white", fontWeight: "bold" }}>
                            {userProfileData?.insight_overview?.completed_courses < 10 ? "0" + userProfileData?.insight_overview?.completed_courses : userProfileData?.insight_overview?.completed_courses}
                          </Typography>
                          <Typography variant="h6" style={{ color: "white", marginBottom: "5px" }}>
                            /{userProfileData?.insight_overview?.total_courses < 10 ? "0" + userProfileData?.insight_overview?.total_courses : userProfileData?.insight_overview?.total_courses}
                          </Typography>

                        </Box>
                      </Box>
                    </Box>
                  </Grid>
                  <Grid item>
                    <Box className="mask" onClick={() => this.props.history.push("/AchievementListing")} style={{ backgroundImage: `url(${cardBackground})` }}>
                      <Box style={{ padding: "20px" }}>
                        <Typography variant="body1" style={{ color: "white", fontWeight: "bold", width: "150px", height: "50px" }}>
                          Achievement Unlocked
                        </Typography>
                        <Box style={{ display: "flex", flexDirection: "row", alignItems: "flex-end", paddingTop: "50px", justifyContent: "center" }}>
                          <Typography variant="h3" style={{ color: "white", fontWeight: "bold" }}>
                            {userProfileData?.insight_overview?.completed_achievements < 10 ? "0" + userProfileData?.insight_overview?.completed_achievements : userProfileData?.insight_overview?.completed_achievements}
                          </Typography>
                          <Typography variant="h6" style={{ color: "white", marginBottom: "5px" }}>
                            /{userProfileData?.insight_overview?.total_achievements < 10 ? "0" + userProfileData?.insight_overview?.total_achievements : userProfileData?.insight_overview?.total_achievements}
                          </Typography>
                          {/* <Typography variant="h6" style={{color:"white",marginBottom:"5px"}}>
                                /{userProfileData?.insight_overview?.total_achievements < 10 ? "0"+userProfileData?.insight_overview?.total_achievements : userProfileData?.insight_overview?.total_achievements}
                              </Typography> */}
                        </Box>
                      </Box>
                    </Box>
                  </Grid>
                  <Grid item>
                    <Box className="mask" onClick={() => this.props.history.push("/AllRewardsListing")} style={{ backgroundImage: `url(${cardBackground})` }}>
                      <Box style={{ padding: "20px" }}>
                        <Typography variant="body1" style={{ color: "white", fontWeight: "bold", width: "150px", height: "50px" }}>
                          Rewards Achieved
                        </Typography>
                        <Box style={{ display: "flex", flexDirection: "row", alignItems: "flex-end", paddingTop: "50px", justifyContent: "center" }}>
                          <Typography variant="h3" style={{ color: "white", fontWeight: "bold" }}>
                            {userProfileData?.insight_overview?.completed_rewards < 10 ? "0" + userProfileData?.insight_overview?.completed_rewards : userProfileData?.insight_overview?.completed_rewards}
                          </Typography>
                          <Typography variant="h6" style={{ color: "white", marginBottom: "5px" }}>
                            /{userProfileData?.insight_overview?.total_rewards < 10 ? "0" + userProfileData?.insight_overview?.total_rewards : userProfileData?.insight_overview?.total_rewards}
                          </Typography>
                          {/* <Typography variant="h6" style={{color:"white",marginBottom:"5px"}}>
                                /{userProfileData?.insight_overview?.total_rewards < 10 ? "0"+userProfileData?.insight_overview.total_rewards : userProfileData?.insight_overview?.total_rewards} 
                              </Typography> */}
                        </Box>
                      </Box>
                    </Box>
                  </Grid>
                  <Grid item>
                    <Box className="mask" style={{ backgroundImage: `url(${cardBackground})` }}>
                      <Box style={{ padding: "20px" }}>
                        <Typography variant="body1" style={{ color: "white", fontWeight: "bold", width: "150px", height: "50px" }}>
                          Goals Achieved
                        </Typography>
                        <Box style={{ display: "flex", flexDirection: "row", alignItems: "flex-end", paddingTop: "50px", justifyContent: "center" }}>
                          <Typography variant="h6" style={{ color: "white", fontWeight: "bold" }}>
                            Coming soon
                          </Typography>
                          {/* <Typography variant="h6" style={{color:"white",marginBottom:"5px"}}>
                                /{userProfileData?.insight_overview?.total_goal < 10 ? "0"+userProfileData?.insight_overview?.total_goal : userProfileData?.insight_overview?.total_goal}
                              </Typography> */}
                        </Box>
                      </Box>
                    </Box>
                  </Grid>
                  <Grid item>
                    <Box className="mask" style={{ backgroundImage: `url(${cardBackground})` }}>
                      <Box style={{ padding: "20px" }}>
                        <Typography variant="body1" style={{ color: "white", fontWeight: "bold", width: "150px", height: "50px" }}>
                          Task Completed
                        </Typography>
                        <Box style={{ display: "flex", flexDirection: "row", alignItems: "flex-end", paddingTop: "50px", justifyContent: "center" }}>
                          <Typography variant="h6" style={{ color: "white", fontWeight: "bold" }}>
                            Coming soon
                          </Typography>
                          {/* <Typography variant="h6" style={{color:"white",marginBottom:"5px"}}>
                                /30
                              </Typography> */}
                        </Box>
                      </Box>
                    </Box>
                  </Grid>

                </Grid>
              </Grid>
            </Grid>
          </Grid>

          <Grid container spacing={3} style={{ marginTop: "25px" }}>
            <Grid item lg={6} md={6} xs={12} sm={12}>
              <Paper elevation={5} style={{ borderRadius: "5px", marginRight: "15px" }}>
                <Box style={{ padding: "20px" }}>
                  <Typography variant="h6" className="profileName" style={{ fontWeight: "bold", marginBottom: "15px" }}>
                    Recent Insights
                  </Typography>
                  <RecentInsight recentInsight={recentInsightsData} recentInsightLoading={recentInsightLoading} />
                </Box>
              </Paper>
            </Grid>
            <Grid item lg={6} md={6} xs={12} sm={12}>
              <Paper elevation={5} style={{ borderRadius: "5px", marginRight: "15px" }}>
                <Box style={{ padding: "20px" }}>
                  <Typography variant="h6" className="profileName" style={{ fontWeight: "bold", marginBottom: "15px" }}>
                    Activity History
                  </Typography>
                  <ActivityHistory recentActivity={recentActivityData} recentLoading={recentLoading} />
                </Box>
              </Paper>
            </Grid>
          </Grid>
        </Grid>

      </Grid>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
const webStyle = {
  mainWrapper: {
    display: "flex",
    fontFamily: "Roboto-Medium",
    flexDirection: "column",
    alignItems: "center",
    paddingBottom: "30px",
    background: "#fff",
  },
  inputStyle: {
    borderBottom: "1px solid rgba(0, 0, 0, 0.6)",
    width: "100%",
    height: "100px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
  },
  buttonStyle: {
    width: "100%",
    height: "45px",
    marginTop: "40px",
    border: "none",
    backgroundColor: "rgb(98, 0, 238)",
  },
};

const ColorButton = withStyles((theme) => ({
  root: {
    backgroundColor: purple[500],
    color: "white",
    borderRadius: "10px",
    borderColor: purple[500],
    '&:hover': {
      borderColor: purple[700],
      color: "white",
      backgroundColor: purple[800],
    },
  },
}))(Button);

const TransparentButton = withStyles((theme) => ({
  root: {
    backgroundColor: 'transparent',
    color: purple[500],
    borderRadius: "10px",
    borderColor: purple[500],
    border: '1px solid',
    '&:hover': {
      borderColor: purple[500],
      color: purple[500],
      backgroundColor: 'transparent',
    },
  },
}))(Button);

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
  },
  noData: {
    display: 'flex',
    height: '35%',
    width: '100%',
    justifyContent: 'center',
    alignItems: 'center',
    textAlign: 'center'
  },
  cardsPaper: {
    borderRadius: "10px", minHeight: "160px", display: 'flex', flexDirection: "column",
    margin: '8px 0'
  }
}));


function RecentInsight({ recentInsight, recentInsightLoading }: { recentInsight: any, recentInsightLoading: any }) {
  const classes = useStyles();
  console.log(recentInsight, 'recent log');


  return (
    <div className={classes.root}>
      <Grid container spacing={3} style={{ marginTop: "10px" }}>
        {
          recentInsightLoading ? <Box style={{ display: "flex", height: "35%", width: "100%", justifyContent: "center", alignItems: "center" }} > <CircularProgress /> </Box> :
            <Grid container spacing={3} style={{ marginTop: "10px" }}>
              {
                recentInsightLoading ?
                  <Box className={classes.noData} > <CircularProgress /> </Box> :
                  <>
                    <Grid item xs={12}>
                      <Paper elevation={5} className={classes.cardsPaper}>
                        <Typography variant="subtitle1" component="div" style={{ margin: "15px 15px", fontWeight: "bold", color: "#6c328b" }}>
                          Last video viewed
                        </Typography>
                        {
                          recentInsight.last_video_viewed ?
                            <Box style={{ width: '100%', paddingLeft: '15px', display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                              <Box display="flex" style={{ alignItems: 'center' }}>
                                <img src={recentInsight?.last_video_viewed?.image} height="80px" width="80px" style={{ marginRight: "10px", borderRadius: '8px' }} />
                                <Box>
                                  <Typography variant="h6" className="prof" style={{ fontWeight: "bold",wordBreak:'break-all'  }}>
                                    {recentInsight?.last_video_viewed?.name}
                                  </Typography>
                                  {/* <Box style={{display:'flex',marginTop:"5px",justifyContent:"space-between"}}>
                                  <Typography variant="subtitle1" color="textSecondary" style={{marginLeft:"15px"}}>
                                    {recentInsight.last_video_viewed.name}
                                  </Typography>
                                </Box> */}
                                </Box>
                              </Box>
                            </Box>
                            :
                            <Box className={classes.noData}>
                              <Typography >You have No any Recent Insights</Typography>
                            </Box>
                        }

                      </Paper>
                      <Paper elevation={5} className={classes.cardsPaper}>
                        <Typography variant="subtitle1" component="div" style={{ margin: "15px 15px", fontWeight: "bold", color: "#6c328b" }}>
                          Last course completed
                        </Typography>
                        {
                          recentInsight.last_course_completed ?
                            <Box style={{ width: '100%', paddingLeft: '15px', display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                              <Box display="flex" style={{ alignItems: 'center' }}>
                                <img src={recentInsight?.last_course_completed?.image} height="80px" width="80px" style={{ marginRight: "10px", borderRadius: '8px' }} />
                                <Box>
                                  <Typography variant="h6" className="prof" style={{ fontWeight: "bold",wordBreak:'break-all'  }}>
                                    {recentInsight?.last_course_completed?.title}
                                  </Typography>

                                </Box>
                              </Box>
                            </Box>
                            :
                            <Box className={classes.noData}>
                              <Typography >You have not completed any Course</Typography>
                            </Box>
                        }

                      </Paper>
                      <Paper elevation={5} className={classes.cardsPaper}>
                        <Typography variant="subtitle1" component="div" style={{ margin: "15px 15px", fontWeight: "bold", color: "#6c328b" }}>
                          Recently purchased course
                        </Typography>
                        {
                          recentInsight.recent_purchase_course ?
                            <Box style={{ width: '100%', paddingLeft: '15px', display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                              <Box display="flex" style={{ alignItems: 'center', height: '50%' }}>
                                <img src={recentInsight?.recent_purchase_course?.image} height="80px" width="80px" style={{ marginRight: "10px", borderRadius: '8px' }} />
                                <Box>
                                  <Typography variant="h6" className="prof" style={{ fontWeight: "bold",wordBreak:'break-all'  }}>
                                    {recentInsight?.recent_purchase_course?.title}
                                  </Typography>

                                </Box>
                              </Box>
                            </Box>
                            :
                            <Box className={classes.noData}>
                              <Typography >You have not purchased any course</Typography>
                            </Box>
                        }

                      </Paper>
                      <Paper elevation={5} className={classes.cardsPaper}>
                        <Typography variant="subtitle1" component="div" style={{ margin: "15px 15px", fontWeight: "bold", color: "#6c328b" }}>
                          Recent Achievement
                        </Typography>
                        {
                          recentInsight.recent_achievement ?
                            <Box style={{ width: '100%', paddingLeft: '15px', display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                              <Box display="flex" style={{ alignItems: 'center' }}>
                                <img src={recentInsight?.recent_achievement?.icon} height="80px" width="80px" style={{ marginRight: "10px" }} />
                                <Box>
                                  <Typography variant="h6" className="prof" style={{ fontWeight: "bold",wordBreak:'break-all'  }}>
                                    {recentInsight?.recent_achievement?.name}
                                  </Typography>

                                </Box>
                              </Box>
                            </Box>
                            :
                            <Box className={classes.noData}>
                              <Typography >You have No Recent Achievement</Typography>
                            </Box>
                        }

                      </Paper>

                      <Paper elevation={5} className={classes.cardsPaper}>
                        <Typography variant="subtitle1" component="div" style={{ margin: "15px 15px", fontWeight: "bold", color: "#6c328b" }}>
                          Recent Reward
                        </Typography>
                        {
                          recentInsight.recent_reward ?
                            <Box style={{ width: '100%', paddingLeft: '15px', display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                              <Box display="flex" style={{ alignItems: 'center' }}>
                                <img src={recentInsight?.recent_reward?.icon} height="80px" width="80px" style={{ marginRight: "10px" }} />
                                <Box>
                                  <Typography variant="h6" className="prof" style={{ fontWeight: "bold",wordBreak:'break-all'  }}>
                                    {recentInsight?.recent_reward?.name}
                                  </Typography>

                                </Box>
                              </Box>
                            </Box>
                            :
                            <Box className={classes.noData}>
                              <Typography >You have No Recent Reward</Typography>
                            </Box>
                        }

                      </Paper>
                      <Paper elevation={5} className={classes.cardsPaper}>
                        <Typography variant="subtitle1" component="div" style={{ margin: "15px 15px", fontWeight: "bold", color: "#6c328b" }}>
                          Recently finished goal
                        </Typography>
                        {
                          recentInsight.recent_finished_goal ?
                            <Box style={{ width: '100%', paddingLeft: '15px', display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                              <Box display="flex" style={{ alignItems: 'center' }}>
                                <img src={recentInsight?.recent_finished_goal?.image} height="80%" width="80px" style={{ marginRight: "10px" }} />
                                <Box>
                                  <Typography variant="h6" className="prof" style={{ fontWeight: "bold",wordBreak:'break-all'  }}>
                                    {recentInsight?.recent_finished_goal?.name}
                                  </Typography>

                                </Box>
                              </Box>
                            </Box>
                            :
                            <Box className={classes.noData}>
                              <Typography >You have no recently finished goal</Typography>
                            </Box>
                        }

                      </Paper>
                      <Paper elevation={5} className={classes.cardsPaper}>
                        <Typography variant="subtitle1" component="div" style={{ margin: "15px 15px", fontWeight: "bold", color: "#6c328b" }}>
                          Recently finished assignment
                        </Typography>
                        {
                          recentInsight.recent_finished_assignment ?
                            <Box style={{ width: '100%', paddingLeft: '15px', display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                              <Box display="flex" style={{ alignItems: 'center' }}>
                                <img src={recentInsight?.recent_finished_assignment?.image} height="80%" width="80px" style={{ marginRight: "10px" }} />
                                <Box>
                                  <Typography variant="h6" className="prof" style={{ fontWeight: "bold",wordBreak:'break-all'  }}>
                                    {recentInsight?.recent_finished_assignment?.name}
                                  </Typography>

                                </Box>
                              </Box>
                            </Box>
                            :
                            <Box className={classes.noData}>
                              <Typography >You have no  recently finished assignment</Typography>
                            </Box>
                        }

                      </Paper>
                    </Grid>


                  </>
              }


            </Grid>
        }


      </Grid>
    </div>
  );
}

function ActivityHistory({ recentActivity, recentLoading }: { recentActivity: any, recentLoading: any }) {
  const classes = useStyles();
  console.log(recentActivity, 'fbs fjbs fjsbfj');

  return (
    <div className={classes.root}>
      <Grid container spacing={3} style={{ marginTop: "10px" }}>
        {
          recentLoading ? <Box style={{ display: "flex", height: "35%", width: "100%", justifyContent: "center", alignItems: "center" }} > <CircularProgress /> </Box> :
            <>
              {
                recentActivity && recentActivity.length > 0 ? recentActivity?.slice(0, 8).map((item: any, key: any) => {
                  return (
                    <Grid item xs={12} key={key}>
                      <Paper key={key} elevation={5} style={{ borderRadius: "10px", minHeight: "145px", display: 'flex', flexDirection: "column", marginBottom: '10px' }}>
                        <Box style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                          <Typography variant="subtitle1" component="div" style={{ margin: "15px 15px", fontWeight: "bold", color: "#6c328b" }}>
                            {item?.attributes?.activity_comment}
                          </Typography>
                          <Typography variant="subtitle1" component="div" style={{ margin: "15px 15px", fontWeight: "bold", color: "#6c328b" }}>
                            {item?.attributes?.duration}
                          </Typography>
                        </Box>
                        <Box style={{ paddingLeft: '15px' }}>
                          <Typography variant="h6" className="prof" style={{ fontWeight: "bold",wordBreak:'break-all'  }}>
                            {item?.attributes?.activity_model_name}
                          </Typography>
                          {/* <Box style={{display:'flex',marginTop:"5px",justifyContent:"space-between"}}>
                                <Typography variant="subtitle1" color="textSecondary" style={{marginLeft:"15px"}}>
                                  {recentInsight.last_video_viewed.name}
                                </Typography>
                              </Box> */}
                        </Box>


                      </Paper>

                    </Grid>
                  )
                }) :
                  <Box style={{ display: "flex", height: "35%", width: "100%", justifyContent: "center", alignItems: "center" }} >Data Not Found</Box>
              }
            </>
        }





      </Grid>
    </div>
  );
}
// Customizable Area End
