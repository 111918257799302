import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import { AsyncStorage } from "react-native";
// Customizable Area Start
import invert from "invert-color";
// Customizable Area End
export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  backgroundColor: string;
  textColor: string;
  fontType: string;
  fontWeight: string;
  isDarkMode: boolean;
  isEnabled: boolean;
  isBold: boolean;
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class ThemeBlockController extends BlockComponent<Props, S, SS> {
  // Customizable Area Start
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      // Customizable Area Start
      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start
      isDarkMode: false,
      isEnabled: false,
      isBold: false,
      backgroundColor: "#ffffff",
      textColor: "#000000",
      fontType: "normal",
      fontWeight: "normal",
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
  }

  async componentDidMount() {
    super.componentDidMount();
    // Customizable Area Start
    let backColor = await AsyncStorage.getItem("backgroundCol");
    let textColor = await AsyncStorage.getItem("textColo");
    let bold = await AsyncStorage.getItem("fontWeig");
    let italic = await AsyncStorage.getItem("fontTyp");
    let isitalic = await AsyncStorage.getItem("isItali");
    let isBol = await AsyncStorage.getItem("isBol");
    if (backColor != undefined && backColor != null) {
      this.setState({ backgroundColor: backColor });
    }
    if (textColor != undefined && textColor != null) {
      this.setState({ textColor: textColor });
    }
    if (bold != undefined && bold != null) {
      this.setState({ fontWeight: bold });
    }
    if (italic != undefined && italic != null) {
      this.setState({ fontType: italic });
    }
    if (isitalic != undefined && isitalic != null) {
      this.setState({ isEnabled: JSON.parse(isitalic) });
    }
    if (isBol != undefined && isBol != null) {
      this.setState({ isBold: JSON.parse(isBol) });
    }
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  setIsEnabled = () => {
    this.setState({ isEnabled: !this.state.isEnabled });
    this.setState({ fontType: this.state.isEnabled ? "normal" : "italic" });
    AsyncStorage.setItem("fontTyp", this.state.isEnabled ? "normal" : "italic");
    AsyncStorage.setItem("isItali", String(!this.state.isEnabled));
  };

  setColor = (colors: string) => {
    this.setState({ backgroundColor: colors });
    AsyncStorage.setItem("backgroundCol", colors);
    this.setState({ textColor: invert(colors) });
    AsyncStorage.setItem("textColo", invert(colors));
  };

  setTextColor = (colors: string) => {
    this.setState({ textColor: colors });
    AsyncStorage.setItem("textColo", colors);
  };

  setFontType = (type: string) => {
    this.setState({ fontType: type });
  };

  setBoldType = () => {
    this.setState({ isBold: !this.state.isBold });
    this.setState({ fontWeight: this.state.isBold ? "normal" : "bold" });
    AsyncStorage.setItem("fontWeig", this.state.isBold ? "normal" : "bold");
    AsyncStorage.setItem("isBol", String(!this.state.isBold));
  };
  // Customizable Area End
}
