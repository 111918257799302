import React from "react";
import {
  Button, Grid, InputAdornment,
  // Customizable Area Start
  Paper, Switch
  // Customizable Area End
  , TextField, Typography
} from "@material-ui/core";
import Modal from '@material-ui/core/Modal';
import SearchIcon from '@material-ui/icons/Search';
import { Search } from "@material-ui/icons";
import ManageContentLibrarylistController, {
  Props
} from "./ManageContentLibrarylistController.web";
import './style.css';
import './ContentManagement.css';
// Customizable Area Start
const Vector = require("../assets/Vector.png");
const folder = require("../assets/Vector2.png");
const lock = require("../assets/lock2.png");
const close = require("../assets/imagenav_close.png")

// Customizable Area End


export default class ManageContentLibrarylist extends ManageContentLibrarylistController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start

  // Customizable Area End


  render() {

    return (
      <div>
        <Grid container>
          <Grid item lg={12} md={12} sm={12} xs={12}>
            <Typography style={{ fontSize: '48px', fontWeight: 700, }}>Manage Content Library</Typography>
          </Grid>
          <Grid container style={{ marginTop: '25px' }} className="list">
            <Grid item lg={5} className="search-outline">
              {/* <TextField className="inputRounded" inputProps={{ maxlength: 50 }} style={{ width: '310px', borderRadius: '12px', marginTop: '34px' }} placeholder=" Search" variant="outlined" />
              <div style={{ marginTop: '-38px', marginLeft: '8px' }}>
                <SearchIcon style={{ color: '#545650' }} />
              </div> */}
              <TextField
                fullWidth
                id="search"
                name="search"
                variant="outlined"
                placeholder="Search"
                onChange={this.getSearchData}
               
                value={this.state.search}
                InputLabelProps={{
                  shrink: true,
                }}
                className="search"
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <Search />
                    </InputAdornment>
                  ),
                }}
              />
            </Grid>

          </Grid>

          <Grid container style={{ marginTop: '25px' }}>
            <Grid item lg={8}>
              <Typography style={{ fontSize: '20px', fontWeight: 400, color: '#979797', marginTop: '22px' }}>Folders</Typography>
            </Grid>
            <Grid item lg={2}>
              {/* <div style={{ display: 'flex', justifyContent: 'space-evenly', marginTop: '10px' }}>
                <div style={{ display: 'flex', marginTop: '10px' }}>
                  <img style={{ width: '20px', height: '12px', marginTop: '7px' }} src={Vector} />
                  <Typography style={{ fontSize: '18px', fontWeight: 500, color: '#6C328B', paddingLeft: '15px' }}>List View</Typography>
                </div>
                <Switch className="Switch" checked={this.state.themeMode} onChange={() => this.setState({ themeMode: !this.state.themeMode })} />
              </div> */}
            </Grid>
            <Grid item lg={2}><Button onClick={this.dialogHandleOpen} style={{ borderRadius: '12px', width: '212px', height: '56px', backgroundColor: '#653889', textTransform: "capitalize" }} variant="contained" color="primary" >Add New Folder</Button></Grid>
          </Grid>



          <Modal
            open={this.state.dialogopen}
            onClose={this.dialogHandleClose}
            aria-labelledby="simple-modal-title"
            aria-describedby="simple-modal-description"
            style={{ backgroundColor: "rgba(0, 0, 0, -0.5)" }}
          >
            <div style={{
              position: 'absolute', width: '890px', height: '225px', backgroundColor: '#fff',
              margin: '12%', marginLeft: '21%', borderRadius: '15px', boxShadow: '-6px -6px 25px rgba(230, 230, 230, 0.25), 6px 6px 25px rgba(229, 229, 229, 0.25)'

            }}>
              <img src={close} onClick={this.dialogHandleClose} style={{ float: 'right', marginTop: '20px', marginRight: '20px',cursor:'pointer' }} />
              <Typography style={{ fontSize: '20px', fontWeight: 600, marginTop: '20px', marginLeft: '25px' }}>Create New Folder</Typography>
              <Grid container spacing={4} style={{ marginLeft: '30px', marginTop: '25px' }}>
                <Grid item lg={9}>
                  <TextField className="inputField" style={{ borderRadius: '16px', width: '665px' }}  error={this.state.createFolder.length > 15}
                helperText={this.state.createFolder.length > 15 ? '15 characters is required' : ''} value={this.state.createFolder} onChange={(event: any) => this.setState({ createFolder: event.target.value })} placeholder="Type the name of the folder" variant="outlined" />
                </Grid>
                <Grid item lg={3}>
                  <Button onClick={() => this.createNewFolder()} style={{ textTransform: 'capitalize', borderRadius: '18px', width: '130px', height: '56px', backgroundColor: '#653889' }} variant="contained" color="primary" >Create</Button>
                </Grid>

              </Grid>
            </div>
          </Modal>


          <Grid container spacing={2} style={{ marginTop: '40px' }}>
            {this.state.allXpandLibararyData?.data?.map((data: any, index: any) => (
              <>
                <Grid key={index.id} item lg={3} >
                  <Paper style={{ width: '267px', height: '68px', backgroundColor: '#fff', border: '1px solid rgba(0, 0, 0, 0.25)', boxShadow: 'rgb(230 230 230 / 25%) -6px -6px 25px, rgb(230 232 236) 6px 6px 25px', borderRadius: '10px' }}>
                    <Grid container style={{ padding: '15px' }}>
                      <Grid item lg={2} style={{ margin: 'auto' }}>
                        <>
                          <img src={folder} />
                        </>
                      </Grid>
                      <Grid item lg={8} style={{ margin: 'auto' }}>
                        <Typography onClick={() => this.handleNavigate(data.id)} style={{ cursor: 'Pointer', fontSize: '14px', fontWeight: 600, color: '#222836', marginLeft: '0px', marginTop: '0px', textTransform: 'capitalize' }}>{data.name}</Typography>
                      </Grid>
                      <Grid item lg={2} style={{ margin: 'auto' }}>
                        <>
                          {data.is_lock ? <img src={lock} /> : <></>}
                        </>
                      </Grid>
                    </Grid>
                  </Paper></Grid>
              </>
            ))}

          </Grid>

        </Grid>
      </div>
    )

  }

}
