import React from "react";
import { Grid } from "@material-ui/core";
import CartItems from "./CartItems.web";
import Payments from "../../../Payments/src/Payments.web";
import SubscriptionCartItems from "./SubscriptionCartItems.web";
import { SelectedSubsCartItem } from "../ShoopingController.web";
import OrderSummary from "./OrderSummary.web";
interface ICart {
  cartType: string;
  cartItems: any;
  payableAmount: number;
  subscriptionCartItems: any;
  orderConfirmed: boolean;
  onRemoveItemFromCart: (id: any) => void;
  selectedSubsCartItem: SelectedSubsCartItem;
  onPaymentSuccess: (msg: string) => void;
  onPaymentFailure: (msg: string) => void;
  handleSubsCartItemsSelection: (item: SelectedSubsCartItem) => void;
  handleOrderConfirmation: () => void;
}

export default function Cart({
  cartType,
  cartItems,
  subscriptionCartItems,
  orderConfirmed,
  onRemoveItemFromCart,
  payableAmount,
  onPaymentSuccess,
  onPaymentFailure,
  selectedSubsCartItem,
  handleSubsCartItemsSelection,
  handleOrderConfirmation,
}: ICart) {
  return (
    <>
      <Grid container spacing={2}>
        <Grid item lg={8} xs={12}>
          {cartType === "subscription" ? (
            <SubscriptionCartItems 
              onRemove={onRemoveItemFromCart}
              cartItems={subscriptionCartItems}
              selectedSubsCartItem={selectedSubsCartItem}
              handleSubsCartItemsSelection={handleSubsCartItemsSelection}
            />
          ) : (
            <CartItems
              cartItems={cartItems}
              onRemove={onRemoveItemFromCart}
              payableAmount={payableAmount}
              orderConfirmed={orderConfirmed}
            />
          )}
        </Grid>
        <Grid item lg={4} xs={12}>
          {
            !orderConfirmed ? (
              <OrderSummary 
                payableAmount={payableAmount} 
                onOrderConfirmation={handleOrderConfirmation} 
              />
            ) : (
              <Payments
                cartType={cartType}
                payableAmount={payableAmount}
                onSuccess={onPaymentSuccess}
                onFailure={onPaymentFailure}
                selectedSubsCartItem={selectedSubsCartItem}
              />
            )
          }
        </Grid>
      </Grid>
    </>
  );
}
