import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { imgPasswordInVisible, imgPasswordVisible, slider1, slider2, slider3 } from "./assets";
import React from "react";
// Customizable Area End
export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  history: any;
  location: any;
  videoTitle: any,
  lessons: any,
  videoUrl: any,
  notes: any,


  // Customizable Area Start
  // Customizable Area End
}

interface S {
  txtInputValue: string;
  txtSavedValue: string;
  enableField: boolean;
  // Customizable Area Start
  anchorEl: any;
  libraryData: any;
  grapValues: any;
  quizze: any;
  videoTitle: any;
  videoUrl: any;
  lessons: any[];
  notes: any;
  allTitleLibraryData: any,
  myAnswer: any,
  video_set: any,
  youtube_video_set: any,
  show: boolean,
  currentQuestion: number,
  chosenAnswers: any,
  option_ids: any,
  timer: any,
  selectedValue: any,
  currRating: any,
  isActiveLesson: any,
  propsId: any,
  open: boolean,
  open1: boolean,
  open2: boolean,
  openNotes: boolean,
  loaderOpen: any,
  quizAnswers: any,
  titleNotes: any,
  captionNotes: any,
  time: any,
  time2: any,
  openEditModle: any,
  editTitle: any,
  editDescription: any,
  editType: any,
  editCourseId: any,
  editIs_private: any,
  editNotesData: any,
  LastTrackData: any,
  minutes: any,
  seconds: any,
  onlyOneClick: boolean,
  image: any,
  imageDemo: any,
  editImageDemo: any,
  editImage: any,
  // notesDetail:any,
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class TitlePageController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  GetTitleLibraryData: any;
  GetTitleLessonData: any;
  PostCourseNotesData: any;
  GetQuizzesData: any;
  PostQuizzAnswer: any;
  EditPostData: any;
  myRef: any;
  interval: any;
  param_id: any;
  Last_VideoTrackData: any;
  libraryCheckedDataSort: any;
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
    this.myRef = React.createRef();
    this.interval = null;
    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage)
      // Customizable Area End
    ];

    this.state = {
      txtInputValue: "",
      txtSavedValue: "A",
      enableField: false,
      // Customizable Area Start
      anchorEl: '',
      grapValues: 'Week',
      allTitleLibraryData: [],
      video_set: '',
      youtube_video_set: '',
      show: true,
      option_ids: [],
      quizze: [],
      myAnswer: null,
      currRating: '',
      selectedValue: '',
      currentQuestion: 0,
      chosenAnswers: {},
      quizAnswers: {},
      timer: '00:00:00',
      minutes: '',
      seconds: '',
      titleNotes: '',
      captionNotes: '',
      loaderOpen: false,
      open: false,
      open1: false,
      open2: false,
      openNotes: false,
      openEditModle: false,
      propsId: '',
      editTitle: '',
      editDescription: '',
      editType: '',
      editIs_private: '',
      editCourseId: '',
      editNotesData: {},
      LastTrackData: {},
      isActiveLesson: '',
      onlyOneClick: false,
      image: '',
      imageDemo: '',
      editImageDemo: '',
      editImage: '',
      time: {},
      time2: {},
      libraryData: [
        {
          id: 1,
          image_url: slider1
        },
        {
          id: 2,
          image_url: slider2
        },
        {
          id: 3,
          image_url: slider3
        },
        {
          id: 4,
          image_url: slider2
        },
        {
          id: 5,
          image_url: slider1
        },
        {
          id: 6,
          image_url: slider2
        },
        {
          id: 7,
          image_url: slider3
        },
        {
          id: 8,
          image_url: slider1
        },
        {
          id: 9,
          image_url: slider2
        }
      ],
      videoTitle: 'Photography page with sample project.',
      videoUrl: null,
      lessons: [
        { title: 'Introduction 1 title', url: `https://www.youtube.com/watch?v=oUFJJNQGwhk` },
        { title: 'Intro 2 title', url: 'https://www.youtube.com/watch?v=d46Azg3Pm4c' },
        { title: 'Introduction 3 title', url: 'https://www.youtube.com/watch?v=jNgP6d9HraI' },
        { title: 'Quiz ', url: 'none', type: 'quiz' },
        { title: 'Introduction 5 title', url: 'https://www.youtube.com/watch?v=ysz5S6PUM-U' },
        { title: 'Lesson title', url: 'https://www.youtube.com/watch?v=ysz5S6PUM-U' },
        { title: 'Lesson title', url: 'https://www.youtube.com/watch?v=ysz5S6PUM-U' },
      ],
      notes: [
        { id: 1, title: 'test title1', caption: 'test caption1' },
        { id: 2, title: 'test title2', caption: 'test caption2' },
        { id: 3, title: 'test title3', caption: 'test caption3' },
        { id: 4, title: 'test title4', caption: 'test caption4' },
        { id: 5, title: 'test title5', caption: 'test caption5' },
        { id: 4, title: 'test title6', caption: 'test caption4' },
        { id: 4, title: 'test title7', caption: 'test caption4' },
      ]
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    this.param_id = this.props.navigation.getParam("id");
    // Customizable Area Start
    // Customizable Area End
  }

  // @ts-ignore
  componentDidMount() {

    const open = Boolean(this.state.anchorEl);
    this.getTitleLessonData();
    window.scrollTo(0, 0)
  }


  async receive(from: string, message: Message) {
    runEngine.debugLog("Message Recived", message);

    if (message.id === getName(MessageEnum.AccoutLoginSuccess)) {
      let value = message.getData(getName(MessageEnum.AuthTokenDataMessage));

      this.showAlert(
        "Change Value",
        "From: " + this.state.txtSavedValue + " To: " + value
      );

      this.setState({ txtSavedValue: value });
    }

    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      let responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );



      if (apiRequestCallId && responseJson) {

        if (apiRequestCallId === this.GetTitleLessonData) {
          this.setState({ allTitleLibraryData: responseJson })
          this.setState({ loaderOpen: false })
          this.setState({
            video_set: responseJson.data?.attributes?.video?.url
          }, () => {
          })

          this.setState({
            youtube_video_set: responseJson.data?.attributes?.video_text_url
          }, () => {
          })


        }

        if (apiRequestCallId === this.GetQuizzesData) {
          this.setState({ quizze: responseJson })
          this.quiz_timer();
          this.setState({ loaderOpen: false })
          this.setState({ show: false }, () => {
          })

        }

        if (apiRequestCallId === this.PostQuizzAnswer) {
          this.setState({ quizAnswers: responseJson })
          if (this.state.quizAnswers.final_result?.passing_score === true && this.state.quizAnswers.final_result?.is_passed === true) {
            this.ModalhandleOpen();
          } else if (this.state.quizAnswers.final_result?.passing_score === false) {
            this.Modal2handleOpen();
          } else if (this.state.quizAnswers.final_result?.passing_score === true && this.state.quizAnswers.final_result?.is_passed === false) {
            this.Modal1handleOpen();
          }


        }

        if (apiRequestCallId === this.PostCourseNotesData) {
          this.getTitleLessonData();
          this.setState({ captionNotes: "" })
          this.setState({ titleNotes: "" })
        }

        if (apiRequestCallId === this.EditPostData) {
          this.setState({ editNotesData: responseJson })
          this.getTitleLessonData();
        }
        if (apiRequestCallId === this.Last_VideoTrackData) {
          this.setState({ LastTrackData: responseJson })
          if (this.state.LastTrackData.meta.completed_course === false && this.state.LastTrackData.meta.is_course_completed === true) {
            this.props.history.push('/CourseCompletion',{data:this.state.LastTrackData.meta})
          } else if (this.state.LastTrackData.meta.completed_course === true) {
            this.getTitleLessonData();
          }
        }
      }

    }
    // Customizable Area End
  }

  txtInputWebProps = {
    onChangeText: (text: string) => {
      this.setState({ txtInputValue: text });
    },
    secureTextEntry: false,
  };

  txtInputMobileProps = {
    ...this.txtInputWebProps,
    autoCompleteType: "email",
    keyboardType: "email-address",
  };

  txtInputProps = this.isPlatformWeb()
    ? this.txtInputWebProps
    : this.txtInputMobileProps;

  btnShowHideProps = {
    onPress: () => {
      this.setState({ enableField: !this.state.enableField });
      this.txtInputProps.secureTextEntry = !this.state.enableField;
      this.btnShowHideImageProps.source = this.txtInputProps.secureTextEntry
        ? imgPasswordVisible
        : imgPasswordInVisible;
    },
  };

  btnShowHideImageProps = {
    source: this.txtInputProps.secureTextEntry
      ? imgPasswordVisible
      : imgPasswordInVisible,
  };

  btnExampleProps = {
    onPress: () => this.doButtonPressed(),
  };

  doButtonPressed() {
    let msg = new Message(getName(MessageEnum.AccoutLoginSuccess));
    msg.addData(
      getName(MessageEnum.AuthTokenDataMessage),
      this.state.txtInputValue
    );
    this.send(msg);
  }

  // web events
  setInputValue = (text: string) => {
    this.setState({ txtInputValue: text });
  };

  setEnableField = () => {
    this.setState({ enableField: !this.state.enableField });
  };

  // Customizable Area Start

  handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    this.setState({ anchorEl: event.currentTarget });
  };

  handleClose = () => {
    this.setState({ anchorEl: null });
  };

  ModalhandleOpen = () => {
    this.setState({ open: true });
  };

  ModalhandleClose = () => {
    this.setState({ open: false });
  };

  Modal1handleOpen = () => {
    this.setState({ open1: true });
  };

  tryAGAIN = () => {
    this.setState({ open1: false })
    this.setState({ show: true })
    this.getTitleLessonData()
    window.location.reload()
  }

  handleSelectChange = (event: any) => {
    const value = event.target.value;
    this.setState({ selectedValue: value }, () => {
    });
    console.log('Selected value:', this.state.selectedValue);
  };

  Modal1handleClose = () => {
    this.setState({ open1: false });
  };

  Modal2handleOpen = () => {
    this.setState({ open2: true });
  }; 

  Modal2handleClose = () => {
    this.setState({ open2: false });  
  };   

  navigate = () => {
    this.props.history.push(`/Dashboard`) 
  }     

  navigateAchievement = () => {
    if (this.state.quizAnswers.final_result.course_completed === true) {
      this.props.history.push('/CourseCompletion',{data:this.state.quizAnswers.final_result})
    } else {  
      this.setState({ open1: false })
      this.setState({ show: true }) 
      this.getTitleLessonData()  
      window.location.reload()
    }
  }

  onImageChange = (event: any) => {
    this.setState({
      imageDemo: URL.createObjectURL(event.target.files[0]),
      image: event.target.files[0]
    })
  }

  onImageDelete = () => {
    this.setState({ imageDemo: "" })
  }






  last_trackVideo = (time: any) => {
    let date = new Date();
    let current_date = date.getFullYear() + "-" + (date.getMonth() + 1) + "-" + date.getDate();

    let formdata = new FormData();
    formdata.append("course_video_id", this.state.allTitleLibraryData.data.id);
    formdata.append("track_time", time);
    formdata.append("is_completed", "true");
    formdata.append("track_date", current_date);
    formdata.append("is_update", "true");
    if (this.props.location.state.program) {
      formdata.append("program_id", this.props.location.state.program)
    }

    const header = {
      ContentType: "multipart/form-data",
      token: localStorage.getItem("token")
    };
    const httpBody = formdata;

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.Last_VideoTrackData = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `/bx_block_adhocreporting/course_video_track`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      header
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      'Post'
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      httpBody
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);

  }

  getTitleLessonData = async (id?: any) => {
    this.setState({ loaderOpen: true })
    this.setState({ isActiveLesson: id })

    const header = {
      "Content-Type": "application/json",
      token: localStorage.getItem("token")
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.GetTitleLessonData = requestMessage.messageId;
    if (this.props.history.location.state.program) {
      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        `bx_block_videos4/course_videos/${id || this.param_id || this.props.history.location.state.id}?is_program_track=true&program_id=${this.props.location.state.program}`
      );
    } else {
      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        `/bx_block_videos4/course_videos/${id || this.param_id}`
      );
    }

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      header
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      'get'
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }



  onChangeVideo = (data: any, dataUrl: any, last_track_data: any) => {
    localStorage.setItem('Last_track-data', JSON.stringify(last_track_data))
    this.setState({ videoTitle: data, videoUrl: dataUrl })
    this.setState({ show: true })
  }

  OpenNotes = () => {
    this.setState({ openNotes: true })
  }

  CloseNotes = () => {
    this.setState({ openNotes: false })
  }

  createNotes = ({ data }: any) => {
    if (this.state.titleNotes.length < 5 || this.state.captionNotes.length < 50) {
    } else {
      this.setState({ openNotes: false })
      let value: any;
      if (this.state.selectedValue === 10) {
        value = 'false';
      } else if (this.state.selectedValue === 20) {
        value = 'true';
      }

      let formdata = new FormData();
      formdata.append("note[title]", this.state.titleNotes);
      formdata.append("note[description]", this.state.captionNotes);
      formdata.append("note[is_private]", value);
      formdata.append("note[course_id]", data?.attributes?.lessons?.id);
      formdata.append("note[chapter_id]", data?.id);
      formdata.append("note[note_type]", "course");
      if (this.state.image) {
        formdata.append("note[image]", this.state.image, this.state.image.name)
      }
      const header = {
        ContentType: "multipart/form-data",
        token: localStorage.getItem("token")
      };
      const httpBody = formdata;

      const requestMessage = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
      );
      this.PostCourseNotesData = requestMessage.messageId;
      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        `bx_block_library2/notes`
      );
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        header
      );
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        'Post'
      );
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        httpBody
      );
      runEngine.sendMessage(requestMessage.id, requestMessage);
    }

  }

  editOnCchange = (e: any) => {
    this.setState({ editTitle: e.target.value })
  }
  editOnCchange1 = (value: any) => {
    this.setState({ editDescription: value })
  }
  editOnType = (event: any) => {
    const value = event.target.value;
    this.setState({ editType: value }, () => {
    });
  }

  editOnImageChange = (event: any) => {
    this.setState({
      editImageDemo: URL.createObjectURL(event.target.files[0]),
      editImage: event.target.files[0]
    })
  }

  editOnImageDelete = () => {
    this.setState({ editImageDemo: "" })
  }

  openUpdateModle = (data: any) => {
    this.setState({ openEditModle: true })
    this.setState({ editTitle: data?.attributes?.title })
    this.setState({ editDescription: data?.attributes?.description })
    this.setState({ editCourseId: data?.id })
    this.setState({ editIs_private: data?.attributes?.is_private })
    this.setState({ editType: data?.attributes?.note_type })
    this.setState({ editImageDemo: data?.attributes?.image?.url })
  }

  openCloseModle = () => {
    this.setState({ openEditModle: false })
  }

  updateNotes = async () => {
    this.setState({ openEditModle: false })
    let formdata = new FormData();
    formdata.append("note[title]", this.state.editTitle);
    formdata.append("note[description]", this.state.editDescription);
    formdata.append("note[is_private]", this.state.editIs_private);
    if (this.state.editImage) {
      formdata.append("note[image]", this.state.editImage, this.state.editImage.name)
    }

    const header = {
      ContentType: "multipart/form-data",
      token: localStorage.getItem("token")
    };
    const httpBody = formdata;

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.EditPostData = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_library2/notes/${this.state.editCourseId}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      header
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      'Put'
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      httpBody
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }


  showClick = () => {
    this.setState({ show: false })
    // this.setState({ loaderOpen: true })
  }



  showClickTrue = () => {
    this.setState({ show: true })
  }

  quiz_timer = () => {
    if (this.interval) {
      // Clear previous interval if it exists
      clearInterval(this.interval);
    }
    let value = ((this.state.quizze?.data?.attributes?.duration) * 60000);
    const countDownTime = Date.now() + value;
    this.interval = setInterval(() => {
      let now: any = new Date();
      const distance = countDownTime - now;

      let minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
      let seconds = Math.floor((distance % (1000 * 60)) / 1000);

      if (distance < 0) {
        clearInterval(this.interval);
        this.setState({
          time: {
            minutes: 0,
            seconds: 0
          }
        }, () => {
        })
      } else {
        this.setState({ time: { minutes, seconds } })
        if (this.state.time.minutes === 0 && this.state.time.seconds === 1) {
          this.setState({ open1: false })
          this.setState({ show: true })
          this.getTitleLessonData()
          window.location.reload()
        }
      }
    }, 1000)
  }

  getquizzesData = async (props: any) => {

    const header = {
      "Content-Type": "application/json",
      token: localStorage.getItem("token")
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.GetQuizzesData = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_questionbank/quizzes/${props}?program_id=${this.props.location.state.program || null}&course_id=${this.state.allTitleLibraryData?.data?.attributes?.course_id}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      header
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      'get'
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    this.setState({ show: false })
    this.setState({ onlyOneClick: true })
    this.setState({ loaderOpen: true })
  }

  handlePrevious = () => {
    const nextQuetions = this.state.currentQuestion - 1;
    if (nextQuetions < this.state.quizze?.data?.attributes?.questions.length) {
      this.setState({ currentQuestion: nextQuetions });
    }
  };

  hendleNext = () => {
    const nextQuetions = this.state.currentQuestion + 1;
    if (nextQuetions < this.state.quizze?.data?.attributes?.questions.length) {
      this.setState({ currentQuestion: nextQuetions });
      this.setState({ myAnswer: null })
    }
  }


  checkedhandleChange = (id: any) => {
    const selectedAnswer = id;
    this.setState((prevState) => {
      const { currentQuestion } = prevState;
      const updatedAnswers = [...prevState.option_ids];
      updatedAnswers[currentQuestion] = selectedAnswer;
      return { option_ids: updatedAnswers };
    }, () => {
      const params = this.state.option_ids?.map((id: any) => ({ "quize_question_id": id.quize_question_id, "option_ids": [id.id] }));
      this.setState({ chosenAnswers: params })
    });

  }

  postQuizzAnswer = () => {

    const header = {
      "Content-Type": "application/json",
      token: localStorage.getItem("token")
    };
    // let quize_id;
    // this.state.quizze?.data?.attributes?.questions?.map((value: any) => {
    //  quize_id = value?.attributes?.quiz_id
    // })
    let quize_id: string | undefined;
    this.state.quizze?.data?.attributes?.questions?.map((value: any) => {
      quize_id = value?.attributes?.quiz_id;
      return null;
    });

    let str = this.state.quizze?.data?.attributes?.course_id;
    let number = parseInt(str);
    let str1 = this.state.quizze?.data?.attributes?.program_id;
    let number1 = parseInt(str1);
    let httpBody;

    httpBody = {
      "quize_id": quize_id,
      "course_id": number, // course id 
      "program_id": number1 || null, // if give exam from program then need to pass program id
      "answers": this.state.chosenAnswers,
    }

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.PostQuizzAnswer = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_questionbank/answers`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      header
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      'Post'
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  // Customizable Area End
}
