import React from "react";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Typography,
} from "@material-ui/core";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { Faq } from "./Settings5Controller.web";

interface Props {
  faqs: Faq[];
}

export default function Faqs({ faqs }: Props) {
  const classes = useStyles(); 

  return (
    <div className={classes.accordionRoot}>
      {faqs.map((faq) => (
        <Accordion className={classes.accordion} key={faq.id}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="faq-content"
            id="faq-header"
          >
            <Typography className={classes.accordionHeading}>
              {faq.question}
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography>{faq.answer}</Typography>
          </AccordionDetails>
        </Accordion>
      ))}
    </div>
  );
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    accordionRoot: {
      maxWidth: "535px",
    },
    accordion: {
      borderRadius: "5px !important",
      marginBottom: 15,
      background: "#fbf8f2",
      "& .MuiPaper-rounded": {
        borderRadius: 0,
        [theme.breakpoints.up("sm")]: {
          borderRadius: "0 !important",
        },
      },
    },
    accordionHeading: {
      fontSize: theme.typography.pxToRem(15),
      fontWeight: theme.typography.fontWeightRegular,
      color: "#966096",
    },
  })
);
