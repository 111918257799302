Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.validationApiContentType = "application/json";
exports.allNotesAPiMethod = "GET";
exports.createNotesAPiMethod = "POST";
exports.editNotesAPiMethod = "PUT";


exports.allNotesAPiEndPoint = "bx_block_library2/notes";
exports.allNotesApiContentType = "application/json";
exports.textInputPlaceHolder = "Enter Text";


exports.labelTitleText = "Notes";
exports.labelBodyText = "Notes Body";
exports.back = "Back";
exports.myNotes = "My Notes"
exports.addContent = "+ Create a new note"
exports.newNote = "New Note"
exports.saveNote = "Save"
exports.uploadFromGallery = "Upload from Gallery"
exports.uploadAPicture = "Upload a picture"
exports.makeThisNotePrivate = "Make this note private"
exports.yourNoteIsSetToPrivate = "Your note is set to private"
exports.addLink = "Add a Link"
exports.description = "Description"
exports.searchNotes = "Search Notes"
exports.search = "Search"
exports.searchResults = "Search Results"
exports.resultMsg = "This results show you public/private notes"
exports.error = "Results not found"
exports.btnExampleTitle = "CLICK ME";

exports.validationApiMethodType = "GET";
exports.exampleAPiEndPoint = "EXAMPLE_URL";
exports.exampleAPiMethod = "POST";
exports.exampleApiContentType = "application/json";
// Customizable Area End