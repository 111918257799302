import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import * as ImagePicker from 'react-native-image-picker';
import { showMessage, hideMessage } from "react-native-flash-message";


// Customizable Area Start
import { imgPasswordInVisible, imgPasswordVisible } from "./assets";
import { stringify } from "querystring";
import { getStorageData } from "../../../framework/src/Utilities";
import AsyncStorage from "@react-native-async-storage/async-storage";
import { Alert } from "react-native";
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  txtInputValue: string;
  txtSavedValue: string;
  enableField: boolean;
  back: String,
  myNotes: string
  addContent: String;
  newNotes: String;
  saveNotes: String;
  uploadAPicture: String;
  makeThisNotePrivate: String;
  yourNoteIsSetToPrivate: String;
  description: String;
  addLink: String;
  loading: boolean;
  arrayHolder: any;
  tabIndex: string;
  token: any
  updateData: boolean;
  personalArrayHolder: any
  video: any
  noteTitleValue: string;
  noteDesValue: string;
  searchNotes: String;
  search:String;
  notesId: any
  notesDetail: any
  searchResults: String
  resultMsg: String;
  error: String
  searchData: any
  courseId: any
  chapterId: any
  title: any
  notesType: String;
  closeBottomSheet: any
  callback: String;
  searchCourseData: any
  switchValue:boolean
  notes:string
  index:number
  routes:any
  num:number
  uploadFromGallery:any

  // Customizable Area Start
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class NotesController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start

  // Customizable Area End
  getAllNotesApiCallId: any;
  createNewNoteApiCallId: any;
  RBSheet:any
  ref:any
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionSaveMessage),
      getName(MessageEnum.SessionResponseMessage),
      // Customizable Area Start
      // Customizable Area End
    ];

    this.state = {
      notes:'',
      txtInputValue: "",
      txtSavedValue: "A",
      enableField: false,
      back: configJSON.back,
      myNotes: configJSON.myNotes,
      addContent: configJSON.addContent,
      index: 0,
      routes: [
        { key: 'allNotes', title: 'All Notes' },
        { key: 'personalNotes', title: 'Personal Notes' },
        { key: 'coursesNotes', title: 'Courses Notes' },
      ],
      newNotes: configJSON.newNote,
      saveNotes: configJSON.saveNote,
      uploadFromGallery: configJSON.uploadFromGallery,
      uploadAPicture: configJSON.uploadAPicture,
      makeThisNotePrivate: configJSON.makeThisNotePrivate,
      yourNoteIsSetToPrivate: configJSON.yourNoteIsSetToPrivate,
      description: configJSON.description,
      addLink: configJSON.addLink,
      loading: false,
      arrayHolder: [],
      tabIndex: "",
      token: "",
      updateData: false,
      num: 0,
      personalArrayHolder: [],
      video: [],
      noteTitleValue: "",
      noteDesValue: "",
      searchNotes: configJSON.searchNotes,
      search: configJSON.search,
      notesId: this.props.navigation.state.params?.item,
      notesDetail: {},
      searchResults: configJSON.searchResults,
      resultMsg: configJSON.resultMsg,
      error: configJSON.error,
      searchData:[],
      courseId: this.props.navigation.state.params?.itemId,
      chapterId: this.props.navigation.state.params?.chapterId,
      title: this.props.navigation.state.params?.title,
      notesType: this.props.navigation.state.params?.notesType,
      closeBottomSheet: false,
      callback: "",
      searchCourseData: [],
      switchValue:false

      
      // Customizable Area Start
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);


    // Customizable Area Start
    // Customizable Area End
  }
  _handleIndexChange = (index:any) => {
    this.setState({ index: index });
  }


  async componentDidMount() {
    super.componentDidMount();
 //   this.getToken();
 

    // const token = await getStorageData("token", true);
    const token = await AsyncStorage.getItem('token')

    this.setState({ token: token }, () => {
      if (this.state.notesId != undefined) {
        this.getNotesDetail();
      } else if (this.state.courseId != undefined) {
        this.getCourseChapter()
      }
    });
    if (this.isPlatformWeb() === false) {
      this.props.navigation.addListener("willFocus", () => {
        this.setState({
          notesId: this.props.navigation.state.params?.item,
          courseId: this.props.navigation.state.params?.itemId,
          chapterId: this.props.navigation.state.params?.chapterId,

        }, () => {
          if (this.state.notesId != undefined) {
            this.getNotesDetail();
          } else if (this.state.courseId != undefined) {
            this.getCourseChapter()
          }
  
        })
      });
    }
    //this.setState({ loading: true })

  }


  getToken = () => {
    const msg: Message = new Message(
      getName(MessageEnum.SessionRequestMessage)
    );
    this.send(msg);
  };

  callback = () => {
    this.setState({ callback: this.state.callback === "" || this.state.callback === "asc" ? "desc":"asc" });
  }


  async receive(from: string, message: Message) {
    runEngine.debugLog("Message Recived", message);

    if (getName(MessageEnum.SessionResponseMessage) === message.id) {
      let token = message.getData(getName(MessageEnum.SessionResponseToken));
      // let token = localStorage.getItem('authToken');
      this.setState({ token: token }, () => {
        // if (this.state.notesId != undefined) {
        //   this.getNotesDetail();
        // }
      });
    }
    var responseJson = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );
    if (responseJson.errors && responseJson?.errors[0]?.token) {
      Alert.alert('Error', responseJson.errors[0].token);
      AsyncStorage.clear();
      return this.props.navigation.navigate('Authentication');
    }
    if (
      getName(MessageEnum.RestAPIResponceMessage) === message.id &&
      this.getAllNotesApiCallId != null &&
      this.getAllNotesApiCallId ===
      message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
    ) {
      this.setState({
        loading: false,
      });

      var responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      var errorResponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );
     
      if (responseJson && responseJson.data) {
        if (this.state.notesId != undefined) {
          this.setState({ notesDetail: responseJson.data })

          this.notesTitle(this.state.notesDetail?.attributes?.title)
          this.notesDescription(this.state.notesDetail?.attributes?.description)
          this.isPrivate(this.state.notesDetail?.attributes?.is_private)
          this.setState({ uploadFromGallery: this.state.notesDetail?.attributes?.image ? this.state.notesDetail?.attributes?.image?.file_name:"Upload from Gallery" })

        } else if (this.state.courseId != undefined) {
          this.setState({ arrayHolder: responseJson.data })
        } else {
          let personalNotes = [];
          let courseNotes = []
          courseNotes = await responseJson.data.filter((data:any) => data.attributes.note_type === "course");
          personalNotes = await responseJson.data.filter((data:any) => data.attributes.note_type === "personal");

          this.setState({ searchData: personalNotes })
          this.setState({ searchCourseData: courseNotes })

        }
        this.getAllNotesApiCallId = null
        console.log("NotesDetail", this.state.searchData);

      } if (responseJson && responseJson.errors) {

        this.parseApiErrorResponse(responseJson.errors);
      } else {
        //Check Error Response
        this.createEventFailureCallBack(errorResponse);
      }

    } else if (
      getName(MessageEnum.RestAPIResponceMessage) === message.id &&
      this.createNewNoteApiCallId !== null &&
      this.createNewNoteApiCallId ===
      message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
    ) {
      this.setState({
        loading: false,
      });

      var responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      var errorResponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );
      if (responseJson && responseJson.data) {
        this.uploadVideoSuccessCallBack(responseJson);
        this.createNewNoteApiCallId = null

      } if (responseJson && responseJson.errors) {
        console.log("--------error----------", responseJson.errors);

        this.parseApiErrorResponse(responseJson.errors);
      } else {
        //Check Error Response
        this.createEventFailureCallBack(errorResponse);
      }

    }
    // Customizable Area Start
    // Customizable Area End
  }
 
  selectVideo = async () => {
    ImagePicker.launchImageLibrary({ mediaType: 'photo', includeBase64: false }, (response) => {
      console.log(response);
      if (response && response.assets) this.setState({ uploadFromGallery: response.assets[0].fileName, video: response }, () => {
        console.log("videolength", this.state.video)
      });
    })
  }



  getAllNotes = (token: any, type: String) => {
    let noteType = ""
    if (type === "personalNotes") {
      noteType = "personal"
    } else if (type === "coursesNotes") {
      noteType = "course"
    }


    // if (this.state.MyChannel) {
    //   httpBody.is_my_channel = this.state.MyChannel
    // }


    const header = {
      "Content-Type": configJSON.allNotesApiContentType,
      token: token,

    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getAllNotesApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.allNotesAPiEndPoint + "?note_type=" + noteType
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    // requestMessage.addData(
    //   getName(MessageEnum.RestAPIResponceDataMessage),
    //   JSON.stringify(httpBody)
    // );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.allNotesAPiMethod
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };



  uploadVideoSuccessCallBack = async (responseJson: any) => {
    this.setState({ loading: false });
    if (this.state.notesType === "personal" || this.state.notesType === undefined || this.state.notesType != "editCourse") {

      this.props.navigation.goBack();
    } else {
      this.getNotesDetail()
      this.props.navigation.navigate("NotesDetails");
    }
    this.setState({ noteDesValue: '' })
    this.setState({noteTitleValue:''})
    this.ref.close();
    this.setState({ callback: "render" });
    
  };

  createEventFailureCallBack = (errorResponse: any) => {
    this.setState({ loading: false });
    console.log("--------error----------", errorResponse);
  };



  txtInputNoteWebProps = {
    onChangeText: (text: string) => {
      this.setState({ noteTitleValue: text });

      //@ts-ignore
      this.txtInputNoteProps.value = text;
    },
  };

  txtInputNoteMobileProps = {
    ...this.txtInputNoteWebProps,
    autoCompleteType: "email",
    keyboardType: "email-address",
  };

  txtInputNoteProps = this.isPlatformWeb()
    ? this.txtInputNoteWebProps
    : this.txtInputNoteMobileProps;

  notesDescription = (text: string) => {
    this.setState({ noteDesValue: text })
  }

  notesTitle = (text: string) => {
    this.setState({ noteTitleValue: text })
  }

  isPrivate = (isPrivate: boolean) => {
    this.setState({ switchValue: isPrivate })
  }
  createNewNoteApi = async (ref: any) => {
    this.ref = ref;
    const token = await AsyncStorage.getItem('token')

    if (!this.state.noteTitleValue) {
      this.showErrorMsg("Please enter title")
      return;
    } else if (!this.state.noteDesValue) {
      this.showErrorMsg("Please enter Description")
      return;
    } else if (this.state.noteDesValue.length < 150) {
     // this.showAlert("","Description is too short(minimum is 150 characters)")
      this.showErrorMsg("Description is too short(minimum is 150 characters)")
      return;
    } else if (this.state.noteTitleValue.length < 5) {
      this.showErrorMsg("Title is too short(minimum is 5 characters)")
      return;
    }

    const header = {
      "Content-Type": "multipart/form-data",
      token: token,
    };
    let formData = new FormData();


    if (this.state.video.length > 0 || this.state.video?.assets?.length>0 ) {
     const data = formData.append("note[image]", {
        name: this.state.video?.assets[0]?.fileName,
        uri: this.state.video?.assets[0]?.uri,
        type: this.state.video?.assets[0]?.type
      } as any );
    }

    formData.append("note[title]", this.state.noteTitleValue);
    formData.append("note[description]", this.state.noteDesValue);
    formData.append("note[is_private]", this.state.switchValue.toString());
    formData.append("note[note_type]", this.state.notesType === "personal" || this.state.notesType === "editPersonal" ? "personal" : "course");
    if (this.state.notesType != "personal" || this.state.notesType === "editPersonal") {
      formData.append("note[course_id]", "1")
      formData.append("note[chapter_id]", "1")
    }

    //   formData.append("video", this.state.video.assets[0].uri);

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.createNewNoteApiCallId = requestMessage.messageId;


    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      this.state.notesId ?
      configJSON.allNotesAPiEndPoint + "/" + this.state.notesId : configJSON.allNotesAPiEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      formData
    );


    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      this.state.notesId? configJSON.editNotesAPiMethod:
      configJSON.createNotesAPiMethod
    );
    this.setState({
      loading: true,
    });

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };

  showErrorMsg = (msg: string) => {
    showMessage({
      message: "Field error",
      description: msg,
      type: 'danger',
      textStyle: { fontFamily: "Montserrat-Medium", },
      titleStyle: { fontFamily: "Montserrat-Medium", }

    });
  }

  getNotesDetail = () => {
    console.log("apiNotesId", this.state.notesId)
    if (this.state.notesId === undefined) {
      if (!this.state.noteTitleValue) {
        this.showErrorMsg("Please enter keyword to search")
        return;
      }
    }

    const header = {
      "Content-Type": configJSON.allNotesApiContentType,
      token: this.state.token,

    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getAllNotesApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      (this.state.notesId) ? configJSON.allNotesAPiEndPoint + ("/" + this.state.notesId) :
        configJSON.allNotesAPiEndPoint + "?per=100&search=" + this.state.noteTitleValue
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    // requestMessage.addData(
    //   getName(MessageEnum.RestAPIResponceDataMessage),
    //   JSON.stringify(httpBody)
    // );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.allNotesAPiMethod
    );
    this.setState({
      loading: true,
    });

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  getCourseChapter = () => {
    console.log("getCourseChapter")

    const header = {
      "Content-Type": configJSON.allNotesApiContentType,
      token: this.state.token,

    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getAllNotesApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      this.state.chapterId ? (configJSON.allNotesAPiEndPoint + "/course_notes?id=" + this.state.courseId + "&chapter_id=" + this.state.chapterId)+"&per=200" :
      (configJSON.allNotesAPiEndPoint + "/course_details?id="+this.state.courseId)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    // requestMessage.addData(
    //   getName(MessageEnum.RestAPIResponceDataMessage),
    //   JSON.stringify(httpBody)
    // );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.allNotesAPiMethod
    );
    this.setState({
      loading: true,
    });

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };


  txtInputWebProps = {
    onChangeText: (text: string) => {
      this.setState({ txtInputValue: text });
    },
    secureTextEntry: false,
  };

  txtInputMobileProps = {
    ...this.txtInputWebProps,
    autoCompleteType: "email",
    keyboardType: "email-address",
  };

  txtInputProps = this.isPlatformWeb()
    ? this.txtInputWebProps
    : this.txtInputMobileProps;

  btnShowHideProps = {
    onPress: () => {
      this.setState({ enableField: !this.state.enableField });
      this.txtInputProps.secureTextEntry = !this.state.enableField;
      this.btnShowHideImageProps.source = this.txtInputProps.secureTextEntry
        ? imgPasswordVisible
        : imgPasswordInVisible;
    },
  };

  btnShowHideImageProps = {
    source: this.txtInputProps.secureTextEntry
      ? imgPasswordVisible
      : imgPasswordInVisible,
  };

  btnExampleProps = {
    onPress: () => this.doButtonPressed(),
  };

  doButtonPressed() {
    let msg = new Message(getName(MessageEnum.AccoutLoginSuccess));
    msg.addData(
      getName(MessageEnum.AuthTokenDataMessage),
      this.state.txtInputValue
    );
    this.send(msg);
  }

  // web events
  setInputValue = (text: string) => {
    this.setState({ txtInputValue: text });
  };

  setEnableField = () => {
    this.setState({ enableField: !this.state.enableField });
  };

  // Customizable Area Start
  // Customizable Area End
}
