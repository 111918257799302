import React from "react";
import {
  Box,
  Button,
  Typography,
  InputAdornment,
  IconButton,
  // Customizable Area Start
  Grid,
  Paper,
  TextField,
  CircularProgress
  // Customizable Area End
} from "@material-ui/core";

// Customizable Area Start
import { createTheme, makeStyles, withStyles } from "@material-ui/core/styles";
import { purple } from '@material-ui/core/colors';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import PropTypes from 'prop-types';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import Backdrop from "@material-ui/core/Backdrop";
import Fade from "@material-ui/core/Fade";
import Modal from "@material-ui/core/Modal";
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import Line from "./LineChartAdmin.web"
import Line1 from "./LineChartAdmin1.web"
import AddNotesModal from "./AddNotesModal.web"
import './style.css'
const Avatar = require("../assets/avatar.jpg");
const cardBackground = require("../assets/cardBackground.png");
const exmaplePic = require("../assets/exmaplePic.png");
const searchIcon = require("../assets/searchIcon.png");
const fill = require("../assets/Fill1.png")
const Bill = require("../assets/Bill.png");


// Customizable Area End

import CustomisableUserProfilesController, {
  Props,
} from "./CustomisableUserProfilesController.web";

export default class CustomisableUserProfiles extends CustomisableUserProfilesController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  async componentDidMount() {
    this.getAllNotesData();
    this.getRecentInsightsData();
    this.getRecentActivityData();
    this.getAdminUserProfileData();
    this.getVideo_web_track_Admin();
    this.getVideo_web_Watch_Admin();
    window.scrollTo(0, 0)
    // this.addTimeSpent()
  }

  handleTab = (value: any) => {
    this.getAllNotesData(value === 0 ? '' : value === 1 ? 'personal' : 'course');
    this.setState({
      handleTabValue: value,
      searchValue: '',
      isloading: true,
    })
  }
  handleSearch = (e: any) => {
    this.getAllNotesData(e.target.value, this.state.handleTabValue === 0 ? '' : this.state.handleTabValue === 1 ? 'personal' : 'course');
    this.setState({
      searchValue: e.target.value,
      isloading: true
    })

  }

  handleRecentTab = (value: any) => {
    this.setState({
      recentSearchValue: '',
      recentTabValue: value,
      recentInsightLoading: true,
      recentLoading: true,
    })
    if (value == 0) {
      this.getRecentInsightsData()
    } else {
      this.getRecentActivityData();
    }

  }
  handleRecentSearch = (e: any) => {
    if (this.state.recentTabValue == 0) {
      this.setState({
        recentInsightLoading: true
      })
      this.getRecentInsightsData()

    } else {
      this.setState({
        recentLoading: true
      })
      this.getRecentActivityData(e.target.value);
    }
    this.setState({
      recentSearchValue: e.target.value,
    })
  }

  // Customizable Area End
  render() {
    const { userProfileData, recentInsightLoading, recentLoading, allNotesData, recentSearchValue, isloading, searchValue, recentActivityData, recentInsightsData, listWatchedData, listTimeData, listTimeHours } = this.state;
    return (
      // Customizable Area Start

      <Grid container>
        <Box style={{ display: 'flex' }} onClick={() => this.props.history.push('/Home')}>
          <img src={fill} style={{ width: '15px', height: '25px', cursor: 'pointer' }} />
          <Typography variant="h3" className="profile_header">View User Profile</Typography>
        </Box>
        <Box className="backButtonBox">
          <Button onClick={() => this.props.history.push('/Home')} className="backButton">Go Back</Button>
        </Box>
        <Grid item xs={12}>
          <Paper elevation={4} style={{ boxShadow: 'rgb(214, 214, 214) 0px 0px 7px', width: "100%", height: "225px", borderRadius: "10px", display: "flex", alignItems: "center" }}>
            <Grid container spacing={2} style={{ padding: "30px" }}>
              <Grid item={true} lg={5} xs={12} sm={7} style={{ display: 'flex', flexDirection: "row", }}>
                <Box style={{ display: "flex", alignItems: 'center' }}>
                  <Box style={{ width: "100px", height: "100px", position: "relative" }}>
                    <img src={this.state.userData_Admin?.avatar ? this.state.userData_Admin?.avatar : Avatar} height="100px" width="100px" style={{ borderRadius: "50px" }} />
                    <Box style={{ backgroundColor: "green", borderRadius: "15px", width: "15px", height: "15px", position: "absolute", top: "0px", right: "0px" }}></Box>
                  </Box>
                </Box>
                <Box style={{ marginLeft: "15px", display: "flex", justifyContent: "center", flexDirection: "column", }}>
                  <Typography variant="h5" className="profileName">{this.state.userData_Admin?.data?.first_name} {this.state.userData_Admin?.data?.last_name}</Typography>
                  <Typography className="profileName1">{this.state.userData_Admin?.data?.status} User</Typography>
                  <Box style={{ display: 'flex', marginTop: "10px", alignItems: "center" }}>
                    <Typography variant="body1" style={{ marginRight: "15px", color: "#6C328B", fontWeight: 700 }}>
                      Last Online
                    </Typography>
                    <Typography variant="subtitle2" color="textSecondary">
                      {this.state.userData_Admin?.last_online} Hours ago
                    </Typography>
                  </Box>
                </Box>
              </Grid>
              <Grid lg={7}>
                <Grid container style={{ marginTop: '10px', marginLeft: '-10px' }}>
                  <Grid item={true} lg={4} xs={4} sm={2} style={{ display: 'flex', flexDirection: "column", alignItems: 'center', justifyContent: "center" }}>
                    <Box style={{ display: 'flex', flexDirection: "column", alignItems: "center" }}>
                      <Typography className="SubscriptionPayment">Subscription Payment</Typography>
                    </Box>
                    <Box style={{ display: 'flex', marginTop: '15px' }}>
                      <Typography style={{ fontSize: '16px', fontWeight: 400, paddingRight: '50px' }}>
                        Product Name
                      </Typography>
                      <Typography variant="subtitle2" color="textSecondary">
                        ${this.state.userData_Admin?.sub_pay}-
                      </Typography>
                    </Box>
                  </Grid>
                  <Grid item lg={1}><hr className="line_profile" /></Grid>
                  <Grid item={true} lg={4} xs={4} sm={2} style={{ display: 'flex', flexDirection: "column", alignItems: 'center', justifyContent: "center" }}>
                    <Box style={{ display: 'flex', flexDirection: "column", alignItems: "center" }}>
                      <Typography className="SubscriptionPayment">One Time Payment</Typography>
                    </Box>
                    <Box style={{ display: 'flex', marginTop: '15px' }}>
                      <Typography style={{ fontSize: '16px', fontWeight: 400, paddingRight: '50px' }}>
                        Product Name
                      </Typography>
                      <Typography variant="subtitle2" color="textSecondary">
                        ${this.state.userData_Admin?.one_time_pay}-
                      </Typography>
                    </Box>
                  </Grid>
                  <Grid item lg={1}><hr className="line_profile" /></Grid>
                  <Grid item={true} lg={2} xs={4} sm={3}>
                    <Box style={{ display: 'flex', flexDirection: "column", alignItems: "center", marginTop: '4px' }}>
                      <Typography className="SubscriptionPayment">Total Spent</Typography>
                    </Box>
                    <Box style={{ display: 'flex', marginTop: '15px', justifyContent: 'center' }}>
                      <Typography variant="subtitle2" color="textSecondary">
                        ${this.state.userData_Admin?.total_payment}-
                      </Typography>
                    </Box>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            <img className="billImage" src={Bill} alt="Bill" />
          </Paper>
          <Grid item={true} xs={12} style={{ marginTop: "30px" }}>
            <Box style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
              <Typography variant="h6" className="profileName">
                Insights Overview
              </Typography>
            </Box>
            <Grid container style={{ marginTop: "25px" }}>
              <Grid item={true} xs={12} style={{ display: 'flex' }} >
                <Grid container spacing={2} justifyContent="center">
                  <Grid item>
                    <Box className="mask" style={{ backgroundImage: `url(${cardBackground})`, cursor: 'pointer' }} >
                      <Box style={{ padding: "20px" }}>
                        <Typography variant="body1" style={{ color: "white", fontWeight: "bold", width: "150px", height: "50px" }}>
                          Achievement Unlocked
                        </Typography>
                        <Box style={{ display: "flex", flexDirection: "row", alignItems: "flex-end", paddingTop: "50px", justifyContent: "center" }}>
                          <Typography variant="h3" style={{ color: "white", fontWeight: "bold" }}>
                            {this.state.userData_Admin?.completed_achievements < 10 ? "0" + this.state.userData_Admin?.completed_achievements : this.state.userData_Admin?.completed_achievements}
                          </Typography>
                          <Typography variant="h6" style={{ color: "white", marginBottom: "5px" }}>
                            /{this.state.userData_Admin?.total_achievements < 10 ? "0" + this.state.userData_Admin?.total_achievements : this.state.userData_Admin?.total_achievements}
                          </Typography>
                          {/* <Typography variant="h6" style={{ color: "white", fontWeight: "bold" }}>
                            Coming soon
                          </Typography> */}
                        </Box>
                      </Box>
                    </Box>
                  </Grid>
                  <Grid item>
                    <Box className="mask" style={{ backgroundImage: `url(${cardBackground})` }}>
                      <Box style={{ padding: "20px" }}>
                        <Typography variant="body1" style={{ color: "white", fontWeight: "bold", width: "150px", height: "50px" }}>
                          Rewards Achieved
                        </Typography>
                        <Box style={{ display: "flex", flexDirection: "row", alignItems: "flex-end", paddingTop: "50px", justifyContent: "center" }}>
                          {/* <Typography variant="h6" style={{ color: "white", fontWeight: "bold" }}>
                            Coming soon
                          </Typography> */}
                          <Typography variant="h3" style={{ color: "white", fontWeight: "bold" }}>
                            {this.state.userData_Admin?.completed_rewards < 10 ? "0" + this.state.userData_Admin?.completed_rewards : this.state.userData_Admin?.completed_rewards}
                          </Typography>
                          <Typography variant="h6" style={{ color: "white", marginBottom: "5px" }}>
                            /{this.state.userData_Admin?.total_rewards < 10 ? "0" + this.state.userData_Admin?.total_rewards : this.state.userData_Admin?.total_rewards}
                          </Typography>
                        </Box>
                      </Box>
                    </Box>
                  </Grid>
                  <Grid item>
                    <Box className="mask" style={{ backgroundImage: `url(${cardBackground})` }}>
                      <Box style={{ padding: "20px" }}>
                        <Typography variant="body1" style={{ color: "white", fontWeight: "bold", width: "150px", height: "50px" }}>
                          Goals Achieved
                        </Typography>
                        <Box style={{ display: "flex", flexDirection: "row", alignItems: "flex-end", paddingTop: "50px", justifyContent: "center" }}>
                          <Typography variant="h6" style={{ color: "white", fontWeight: "bold" }}>
                            Coming soon
                          </Typography>

                        </Box>
                      </Box>
                    </Box>
                  </Grid>
                  <Grid item>
                    <Box className="mask" style={{ backgroundImage: `url(${cardBackground})` }}>
                      <Box style={{ padding: "20px" }}>
                        <Typography variant="body1" style={{ color: "white", fontWeight: "bold", width: "150px", height: "50px" }}>
                          Task Completed
                        </Typography>
                        <Box style={{ display: "flex", flexDirection: "row", alignItems: "flex-end", paddingTop: "50px", justifyContent: "center" }}>
                          <Typography variant="h6" style={{ color: "white", fontWeight: "bold" }}>
                            Coming soon
                          </Typography>

                        </Box>
                      </Box>
                    </Box>
                  </Grid>
                  <Grid item>
                    <Box className="mask" style={{ backgroundImage: `url(${cardBackground})` }}>
                      <Box style={{ padding: "20px" }}>
                        <Typography variant="body1" style={{ color: "white", fontWeight: "bold", width: "150px", height: "50px" }}>
                          Course Completed
                        </Typography>
                        <Box style={{ display: "flex", flexDirection: "row", alignItems: "flex-end", paddingTop: "50px", justifyContent: "center" }}>
                          {/* <Typography variant="h6" style={{ color: "white", fontWeight: "bold" }}>
                            Coming soon
                          </Typography> */}
                          <Typography variant="h3" style={{ color: "white", fontWeight: "bold" }}>
                            {this.state.userData_Admin?.completed_courses < 10 ? "0" + this.state.userData_Admin?.completed_courses : this.state.userData_Admin?.completed_courses}
                          </Typography>
                          <Typography variant="h6" style={{ color: "white", marginBottom: "5px" }}>
                            /{this.state.userData_Admin?.total_courses < 10 ? "0" + this.state.userData_Admin?.total_courses : this.state.userData_Admin?.total_courses}
                          </Typography>
                        </Box>
                      </Box>
                    </Box>
                  </Grid>

                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <Grid container spacing={3} style={{ margin: '0px', marginTop: "25px" }}>
            <Grid item={true} xs={12} sm={6}>
              <Paper elevation={5} className="paper" style={{
                overflow: 'hidden', padding: '30px',
                borderRadius: '24px',
                flex: 1, maxWidth: '43vw', height: '384px', boxShadow: 'rgb(214, 214, 214) 0px 0px 7px'
              }}>
                <Box style={{ padding: "10px" }}>
                  <Typography variant="h6" className="profileName">
                    Videos Watched
                  </Typography>
                  <Grid container>
                    <Grid item lg={9} md={9} sm={12} xs={12}>
                      <Box style={{ display: 'flex', padding: "20px" }}>
                        <Line data={this.state.chartData} />
                      </Box>
                    </Grid>
                    <Grid item lg={3} md={3} sm={12} xs={12}>
                      <Box className="graph_num" >
                        <Typography className="graph1">{this.state.chartData?.meta?.last_count}</Typography>
                        <Typography className="graph2">/{this.state.chartData?.meta?.total_count[0].count}</Typography>
                      </Box>
                    </Grid>
                  </Grid>

                  <Box>
                    <FormControl component="fieldset">
                      <RadioGroup aria-label="required" name="required" row>
                        <Box style={{ width: '10px', height: '10px', marginTop: '5px', backgroundColor: '#5542F6', borderRadius: '10px', marginLeft: '50px' }}></Box>
                        <Typography style={{ marginLeft: '15px', cursor: 'pointer' }}>This Week</Typography>
                        <Box style={{ width: '10px', height: '10px', marginTop: '5px', backgroundColor: '#B6B4BA', borderRadius: '10px', marginLeft: '50px' }}></Box>
                        <Typography style={{ marginLeft: '15px', cursor: 'pointer' }}>Last Week</Typography>
                      </RadioGroup>
                    </FormControl>
                  </Box>
                </Box>
              </Paper>
            </Grid>
            <Grid item={true} xs={12} sm={6}>
              <Paper elevation={5} className="paper" style={{
                overflow: 'hidden', padding: '30px',
                borderRadius: '24px',
                flex: 1, maxWidth: '43vw', height: '384px', boxShadow: 'rgb(214, 214, 214) 0px 0px 7px'
              }}>
                <Box style={{ padding: "10px" }}>
                  <Typography variant="h6" className="profileName">
                    Time Watched
                  </Typography>
                  <Grid container>
                    <Grid item lg={9} md={9} sm={12} xs={12}>
                      <Box style={{ display: 'flex', padding: "20px" }}>
                        <Line1 data={this.state.watchChartData} />
                      </Box>
                    </Grid>
                    <Grid item lg={3} md={3} sm={12} xs={12}>
                      <Box className="graph_num" >
                        <Typography className="graph1">{this.state.watchChartData?.data?.week_count}</Typography>
                        <Typography className="graph2">hrs</Typography>
                      </Box>
                    </Grid>
                  </Grid>
                  <Box>
                    <FormControl component="fieldset">
                      <RadioGroup aria-label="required" name="required" row>
                        <Box style={{ width: '10px', height: '10px', marginTop: '5px', backgroundColor: '#5542F6', borderRadius: '10px', marginLeft: '50px' }}></Box>
                        <Typography style={{ marginLeft: '15px', cursor: 'pointer' }}>This Week</Typography>
                        <Box style={{ width: '10px', height: '10px', marginTop: '5px', backgroundColor: '#B6B4BA', borderRadius: '10px', marginLeft: '50px' }}></Box>
                        <Typography style={{ marginLeft: '15px', cursor: 'pointer' }}>Last Week</Typography>
                      </RadioGroup>
                    </FormControl>
                  </Box>
                </Box>
              </Paper>
            </Grid>
          </Grid>
          <Grid container spacing={3} style={{ margin: '0px', marginTop: "25px" }}>
            <Grid item={true} xs={12} sm={6}>
              <Paper elevation={5} style={{ boxShadow: 'rgb(214, 214, 214) 0px 0px 7px', borderRadius: "15px", marginRight: "15px" }}>
                <Box style={{ padding: "20px" }}>
                  <Typography variant="h6" className="profileName" style={{ fontWeight: "bold", marginBottom: "15px" }}>
                    My Notes
                  </Typography>
                  <Box style={{ display: 'flex', justifyContent: 'space-between' }}>
                    <TextField
                      size="small"
                      placeholder="Search"
                      variant="outlined"
                      style={{
                        width: "60%"
                      }}
                      id="input-with-icon-adornment"
                      onChange={(e) => this.handleSearch(e)}
                      value={searchValue}
                      InputProps={{
                        startAdornment: <InputAdornment position="start"><img src={searchIcon} /></InputAdornment>,
                      }}
                    />
                  </Box>
                  <NoteTabs data={allNotesData} isloading={isloading} handleTab={this.handleTab} navigation={this.props.navigation} />
                </Box>
              </Paper>
            </Grid>
            <Grid item={true} xs={12} sm={6}>
              <Paper elevation={5} style={{ boxShadow: 'rgb(214, 214, 214) 0px 0px 7px', borderRadius: "15px", marginRight: "15px" }}>
                <Box style={{ padding: "20px" }}>
                  <Box>
                    <TextField
                      size="small"
                      placeholder="Search"
                      variant="outlined"
                      fullWidth
                      id="input-with-icon-adornment"
                      onChange={(e) => this.handleRecentSearch(e)}
                      value={recentSearchValue}
                      InputProps={{
                        startAdornment: <InputAdornment position="start"><img src={searchIcon} /></InputAdornment>,
                      }}
                    />
                  </Box>

                  <RecentTabs recentLoading={recentLoading} recentInsightLoading={recentInsightLoading} handleRecentTab={this.handleRecentTab} recentInsight={recentInsightsData} recentActivity={recentActivityData} />

                </Box>
              </Paper>
            </Grid>
          </Grid>
        </Grid>
        <Modal
          aria-labelledby="transition-modal-title"
          aria-describedby="transition-modal-description"
          className="modalStyle"
          // @ts-ignore
          open={this.state.isNoteModalOpen}
          onClose={this.handleAddNotesCloseModal}
          closeAfterTransition
          BackdropComponent={Backdrop}
          BackdropProps={{
            timeout: 500,
          }}
        >
          {/*@ts-ignore*/}
          <Fade in={this.state.isNoteModalOpen} >
            <AddNotesModal navigation={undefined} id={""} handleClose={this.handleNoteOpenModal} />
          </Fade>
        </Modal>


      </Grid>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
const webStyle = {
  mainWrapper: {
    display: "flex",
    fontFamily: "Roboto-Medium",
    flexDirection: "column",
    alignItems: "center",
    paddingBottom: "30px",
    background: "#fff",
  },
  inputStyle: {
    borderBottom: "1px solid rgba(0, 0, 0, 0.6)",
    width: "100%",
    height: "100px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
  },
  buttonStyle: {
    width: "100%",
    height: "45px",
    marginTop: "40px",
    border: "none",
    backgroundColor: "rgb(98, 0, 238)",
  },
};

const ColorButton = withStyles((theme) => ({
  root: {
    backgroundColor: purple[500],
    color: "white",
    borderRadius: "10px",
    borderColor: purple[500],
    textTransform: 'capitalize',
    '&:hover': {
      borderColor: purple[700],
      color: "white",
      backgroundColor: purple[800],
    },
  },
}))(Button);

function TabPanel(props: any) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={1}>
          <Box>{children}</Box>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index: any) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
  },
  noData: {
    display: 'flex',
    height: '35%',
    width: '100%',
    justifyContent: 'center',
    alignItems: 'center'
  },
  cardsPaper: {
    borderRadius: "10px", height: "160px", display: 'flex', flexDirection: "column",
    margin: '8px 0',
    boxShadow: 'rgb(214, 214, 214) 0px 0px 7px'
  }
}));

const AntTabs = withStyles({
  root: {
    borderBottom: '0px solid #e8e8e8',
    marginTop: "10px"
  },
  indicator: {
    backgroundColor: '#6c328b',
  },
})(Tabs);

const TabsRecent = withStyles({
  root: {
    borderBottom: '1px solid #e8e8e8',
    marginTop: "15px"
  },
  indicator: {
    backgroundColor: '#6c328b',
  },
})(Tabs);

const AntTab = withStyles((theme) => ({
  root: {
    textTransform: 'none',
    minWidth: 72,
    fontWeight: 600,
    marginRight: theme.spacing(4),
    fontFamily: [
      'Montserrat, sans-serif',
    ].join(','),
    '&:hover': {
      color: '#6c328b',
      opacity: 1,
    },
    '&$selected': {
      color: '#6c328b',
      fontWeight: theme.typography.fontWeightBold,
    },
    '&:focus': {
      color: '#6c328b',
    },
  },
  selected: {},
}))((props) => <Tab disableRipple {...props} />);

function NoteTabs({ data, isloading, handleTab, navigation }: { data: any, isloading: any, handleTab: any, navigation: any }) {
  const classes = useStyles();
  const [value, setValue] = React.useState(0);
  const handleChange = (event: any, newValue: any) => {
    setValue(newValue);
    handleTab(newValue)
  };



  return (
    <div className={classes.root}>
      <AntTabs value={value} onChange={(x: any, y: any) => handleChange(x, y)} aria-label="simple tabs example" >
        {/* @ts-ignore */}
        <AntTab label="All Notes" {...a11yProps(0)} />
        {/* @ts-ignore */}
        <AntTab label="Personal Notes" {...a11yProps(1)} />
        {/* @ts-ignore */}
        <AntTab label="Course Notes" {...a11yProps(2)} />
      </AntTabs>
      <TabPanel value={value} index={0}>
        <Grid container spacing={3} style={{ marginTop: "10px" }}>
          {
            isloading ? <Box className={classes.noData} > <CircularProgress /> </Box> : (
              <>
                {
                  data && data.length > 0 ? data.map((item: any, key: any) => {


                    return (
                      <Grid item xs={12} key={key}>
                        {item.attributes.note_type === "personal" ?
                          <Paper elevation={1} style={{ borderRadius: "10px", height: "100px", display: 'flex' }}>
                            {
                              item.attributes.is_private ?
                                <Box style={{ backgroundColor: "#6c328b", height: "100%", width: "12px", borderRadius: "10px 0px 0px 10px" }} /> :
                                <Box style={{ backgroundColor: "#ff8b3f", height: "100%", width: "12px", borderRadius: "10px 0px 0px 10px" }} />
                            }
                            <Box style={{ width: '100%', padding: '15px', display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                              <Box display="flex" style={{ alignItems: 'center' }}>

                                <Box>
                                  <Typography variant="h6" className="profileName">
                                    {item.attributes.is_private ? `${item.attributes.title} - Personal` : `${item.attributes.title} - Public`}
                                  </Typography>
                                  {
                                    !item.attributes.image &&
                                    <Box style={{ display: 'flex', marginTop: "5px", justifyContent: "space-between" }}>
                                      {item.attributes.data && <Typography variant="subtitle1" color="textSecondary">

                                      </Typography>}

                                      <Typography dangerouslySetInnerHTML={{ __html: item.attributes.description?.length > 30 ? `${item.attributes.description.substring(0, 30)}...` : item.attributes.description }}></Typography>
                                    </Box>
                                  }
                                </Box>
                              </Box>
                              <Box>
                                <IconButton onClick={() => navigation.navigate("CourseNotes")}>
                                  <ArrowForwardIosIcon />
                                </IconButton>
                              </Box>
                            </Box>
                          </Paper>
                          :
                          <Paper elevation={1} style={{ borderRadius: "10px", height: "100px", display: 'flex' }}>
                            {
                              item.attributes.is_private ?
                                <Box style={{ backgroundColor: "#6c328b", height: "100%", width: "12px", borderRadius: "10px 0px 0px 10px" }} /> :
                                <Box style={{ backgroundColor: "#ff8b3f", height: "100%", width: "12px", borderRadius: "10px 0px 0px 10px" }} />
                            }
                            <Box style={{ width: '100%', padding: '15px', display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                              <Box display="flex" style={{ alignItems: 'center' }}>
                                {item.attributes.image ? (
                                  <img src={item.attributes.image.url} style={{ marginRight: "10px", height: "80px", width: "80px" }} alt="Image" />
                                ) : (
                                  <img src={exmaplePic} style={{ marginRight: "10px", height: "80px", width: "80px" }} alt="Image" />
                                )}
                                <Box>
                                  <Typography variant="h6" className="profileName">
                                    {item.attributes.title}
                                  </Typography>
                                  {
                                    !item.attributes.image &&
                                    <Box style={{ display: 'flex', marginTop: "5px", justifyContent: "space-between" }}>
                                      {item.attributes.data && <Typography variant="subtitle1" color="textSecondary">

                                      </Typography>}
                                      <Typography variant="subtitle1" className="profileName">
                                        {item.attributes.note_type}
                                      </Typography>
                                    </Box>
                                  }
                                </Box>
                              </Box>
                              <Box>
                                <IconButton onClick={() => navigation.navigate("CourseNotes")}>
                                  <ArrowForwardIosIcon />
                                </IconButton>
                              </Box>
                            </Box>
                          </Paper>
                        }
                      </Grid>
                    )


                  }) : <Box className={classes.noData} >Data Not Found</Box>}


                {/* <Box style={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center', padding: '12px', width: '100%' }}>
                  <Typography variant="h6" className="profileName" style={{ color: '#7e24a1', cursor: 'pointer' }} onClick={() => navigation.navigate("CourseNotes")}>
                    View all
                  </Typography>
                </Box> */}
              </>
            )

          }
        </Grid>
      </TabPanel>
      <TabPanel value={value} index={1}>
        <Grid container spacing={3} style={{ marginTop: "10px" }}>
          {
            isloading ? <Box className={classes.noData} > <CircularProgress /> </Box> : (
              <>{
                data && data.length > 0 ? data.map((item: any, key: any) => {
                  return (
                    <Grid item={true} xs={12} key={key}>

                      <Paper elevation={1} style={{ borderRadius: "10px", height: "100px", display: 'flex' }}>
                        {
                          item.attributes.is_private ?
                            <Box style={{ backgroundColor: "#6c328b", height: "100%", width: "12px", borderRadius: "10px 0px 0px 10px" }} /> :
                            <Box style={{ backgroundColor: "#ff8b3f", height: "100%", width: "12px", borderRadius: "10px 0px 0px 10px" }} />
                        }
                        <Box style={{ width: '100%', padding: '15px', display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                          <Box display="flex" style={{ alignItems: 'center' }}>

                            <Box>
                              <Typography variant="h6" className="profileName">
                                {item.attributes.is_private ? `${item.attributes.title} - Personal` : `${item.attributes.title} - Public`}
                              </Typography>
                              {
                                !item.attributes.image &&
                                <Box style={{ display: 'flex', marginTop: "5px", justifyContent: "space-between" }}>
                                  {item.attributes.data && <Typography variant="subtitle1" color="textSecondary">

                                  </Typography>}
                                  {/* <Typography variant="subtitle1" color="textSecondary">
                                    {

                                    item.attributes.description?.length > 30 ? `${item.attributes.description.substring(0,30)}...`:item.attributes.description
                                    }
                                  </Typography> */}
                                  <Typography dangerouslySetInnerHTML={{ __html: item.attributes.description?.length > 30 ? `${item.attributes.description.substring(0, 30)}...` : item.attributes.description }}></Typography>
                                </Box>
                              }
                            </Box>
                          </Box>
                          <Box>
                            <IconButton onClick={() => navigation.navigate("CourseNotes")}>
                              <ArrowForwardIosIcon />
                            </IconButton>
                          </Box>
                        </Box>
                      </Paper>
                    </Grid>
                  )


                }) :
                  <Box className={classes.noData} >Data Not Found</Box>
              }
                {/* <Box style={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center', padding: '12px', width: '100%' }}>
                  <Typography variant="h6" className="profileName" style={{ color: '#7e24a1', cursor: 'pointer' }} onClick={() => navigation.navigate("CourseNotes")}>
                    View all
                  </Typography>
                </Box> */}
              </>
            )
          }
        </Grid>
      </TabPanel>
      <TabPanel value={value} index={2}>
        <Grid container spacing={3} style={{ marginTop: "10px" }}>
          {
            isloading ? <Box className={classes.noData} > <CircularProgress /> </Box> :
              (
                <>{
                  data && data.length > 0 ? data.map((item: any, key: any) => {


                    return (
                      <Grid item={true} xs={12} key={key}>
                        {/* <Paper elevation={1} style={{borderRadius:"10px",height:"100px",display:'flex'}}>
                        {
                            key % 2 === 0 ? 
                            <Box style={{backgroundColor:"#ff8b3f",height:"100%",width:"12px",borderRadius:"10px 0px 0px 10px"}} /> : 
                            <Box style={{backgroundColor:"#6c328b",height:"100%",width:"12px",borderRadius:"10px 0px 0px 10px"}} />
                        }
                        <Box style={{width:'100%',padding:'15px',display:'flex',alignItems:'center',justifyContent:'space-between'}}>
                           <Box display="flex" style={{alignItems:'center'}}>
                            {
                                  item.attributes.image === null ? '' :
                                  <img src={item.attributes.image.url}  style={{marginRight:"10px", height:"80px", width:"80px"}} />
                            }
                            <Box>
                              <Typography variant="h6" className="profileName">
                                {item.attributes.title}
                              </Typography>
                              {
                                !item.pic && 
                                <Box style={{display:'flex',marginTop:"5px",justifyContent:"space-between"}}>
                                  {item.attributes.data && <Typography variant="subtitle1" color="textSecondary">
                                  
                                  </Typography>}
                                  <Typography variant="subtitle1" color="textSecondary">
                                    {

                                    item.attributes.description?.length > 30 ? `${item.attributes.description.substring(0,30)}...`:item.attributes.description
                                    }
                                  </Typography>
                                </Box>
                              }
                            </Box>
                           </Box>
                           <Box>
                              <IconButton onClick={()=>navigation.navigate("CourseNotes")}>
                                <ArrowForwardIosIcon/>
                              </IconButton>
                           </Box>
                        </Box>
                      </Paper> */}
                        <Paper elevation={1} style={{ borderRadius: "10px", height: "100px", display: 'flex' }}>
                          {
                            item.attributes.is_private ?
                              <Box style={{ backgroundColor: "#6c328b", height: "100%", width: "12px", borderRadius: "10px 0px 0px 10px" }} /> :
                              <Box style={{ backgroundColor: "#ff8b3f", height: "100%", width: "12px", borderRadius: "10px 0px 0px 10px" }} />
                          }
                          <Box style={{ width: '100%', padding: '15px', display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                            <Box display="flex" style={{ alignItems: 'center' }}>
                              {item.attributes.image ? (
                                <img src={item.attributes.image.url} style={{ marginRight: "10px", height: "80px", width: "80px" }} alt="Image" />
                              ) : (
                                <img src={exmaplePic} style={{ marginRight: "10px", height: "80px", width: "80px" }} alt="Image" />
                              )}
                              <Box>
                                <Typography variant="h6" className="profileName">
                                  {item.attributes.title}
                                </Typography>
                                {
                                  !item.attributes.image &&
                                  <Box style={{ display: 'flex', marginTop: "5px", justifyContent: "space-between" }}>
                                    {item.attributes.data && <Typography variant="subtitle1" color="textSecondary">

                                    </Typography>}
                                    <Typography variant="subtitle1" className="profileName">
                                      {item.attributes.note_type}
                                    </Typography>
                                  </Box>
                                }
                              </Box>
                            </Box>
                            <Box>
                              <IconButton onClick={() => navigation.navigate("CourseNotes")}>
                                <ArrowForwardIosIcon />
                              </IconButton>
                            </Box>
                          </Box>
                        </Paper>
                      </Grid>
                    )


                  }) :
                    <Box className={classes.noData} >Data Not Found</Box>
                }
                  {/* <Box style={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center', padding: '12px', width: '100%' }}>
                    <Typography variant="h6" className="profileName" style={{ color: '#7e24a1', cursor: 'pointer' }} onClick={() => navigation.navigate("CourseNotes")}>
                      View all
                    </Typography>
                  </Box> */}
                </>
              )
          }
        </Grid>
      </TabPanel>
    </div>
  );
}

function RecentTabs({ recentLoading, recentInsightLoading, recentInsight, recentActivity, handleRecentTab }: { recentLoading: any, recentInsightLoading: any, recentInsight: any, recentActivity: any, handleRecentTab: any }) {
  const classes = useStyles();
  const [value, setValue] = React.useState(0);

  const handleChange = (event: any, newValue: any) => {
    setValue(newValue);
    handleRecentTab(newValue)
  };

  return (
    <div className={classes.root}>
      <TabsRecent value={value} onChange={handleChange} aria-label="simple tabs example">
        {/* @ts-ignore */}
        <AntTab label="Recent Insights" {...a11yProps(0)} />
        {/* @ts-ignore */}
        <AntTab label="Recent Activity" {...a11yProps(1)} />
      </TabsRecent>
      <TabPanel value={value} index={0}>
        <Grid container spacing={3} style={{ marginTop: "10px" }}>
          {
            recentInsightLoading ?
              <Box className={classes.noData} > <CircularProgress /> </Box> :
              <>
                <Grid item xs={12}>
                  <Paper elevation={5} className={classes.cardsPaper}>
                    <Typography variant="subtitle1" component="div" style={{ margin: "15px 15px", fontWeight: "bold", color: "#6c328b" }}>
                      Last video viewed
                    </Typography>
                    {
                      recentInsight.last_video_viewed ?
                        <Box style={{ width: '100%', paddingLeft: '15px', display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                          <Box display="flex" style={{ alignItems: 'center' }}>
                            <img src={recentInsight?.last_video_viewed?.image} height="80px" width="80px" style={{ marginRight: "10px", borderRadius: '8px' }} />
                            <Box>
                              <Typography variant="h6" className="prof" style={{ fontWeight: "bold" }}>
                                {recentInsight?.last_video_viewed?.name}
                              </Typography>
                              {/* <Box style={{display:'flex',marginTop:"5px",justifyContent:"space-between"}}>
                                  <Typography variant="subtitle1" color="textSecondary" style={{marginLeft:"15px"}}>
                                    {recentInsight.last_video_viewed.name}
                                  </Typography>
                                </Box> */}
                            </Box>
                          </Box>
                        </Box>
                        :
                        <Box className={classes.noData}>
                          <Typography >You have No any Recent Insights</Typography>
                        </Box>
                    }

                  </Paper>
                  <Paper elevation={5} className={classes.cardsPaper}>
                    <Typography variant="subtitle1" component="div" style={{ margin: "15px 15px", fontWeight: "bold", color: "#6c328b" }}>
                      Last course completed
                    </Typography>
                    {
                      recentInsight.last_course_completed ?
                        <Box style={{ width: '100%', paddingLeft: '15px', display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                          <Box display="flex" style={{ alignItems: 'center' }}>
                            <img src={recentInsight?.last_course_completed?.image} height="80px" width="80px" style={{ marginRight: "10px", borderRadius: '8px' }} />
                            <Box>
                              <Typography variant="h6" className="prof" style={{ fontWeight: "bold" }}>
                                {recentInsight?.last_course_completed?.title}
                              </Typography>

                            </Box>
                          </Box>
                        </Box>
                        :
                        <Box className={classes.noData}>
                          <Typography >You have not completed any Course</Typography>
                        </Box>
                    }

                  </Paper>
                  <Paper elevation={5} className={classes.cardsPaper}>
                    <Typography variant="subtitle1" component="div" style={{ margin: "15px 15px", fontWeight: "bold", color: "#6c328b" }}>
                      Recently purchased course
                    </Typography>
                    {
                      recentInsight.recent_purchase_course ?
                        <Box style={{ width: '100%', paddingLeft: '15px', display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                          <Box display="flex" style={{ alignItems: 'center', height: '50%' }}>
                            <img src={recentInsight?.recent_purchase_course?.image} height="80px" width="80px" style={{ marginRight: "10px", borderRadius: '8px' }} />
                            <Box>
                              <Typography variant="h6" className="prof" style={{ fontWeight: "bold" }}>
                                {recentInsight?.recent_purchase_course?.title}
                              </Typography>

                            </Box>
                          </Box>
                        </Box>
                        :
                        <Box className={classes.noData}>
                          <Typography >You have not purchased any course</Typography>
                        </Box>
                    }

                  </Paper>
                  <Paper elevation={5} className={classes.cardsPaper}>
                    <Typography variant="subtitle1" component="div" style={{ margin: "15px 15px", fontWeight: "bold", color: "#6c328b" }}>
                      Recent Achievement
                    </Typography>
                    {
                      recentInsight.recent_achievement ?
                        <Box style={{ width: '100%', paddingLeft: '15px', display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                          <Box display="flex" style={{ alignItems: 'center' }}>
                            <img src={recentInsight?.recent_achievement?.icon} height="80px" width="80px" style={{ marginRight: "10px" }} />
                            <Box>
                              <Typography variant="h6" className="prof" style={{ fontWeight: "bold" }}>
                                {recentInsight?.recent_achievement?.name}
                              </Typography>

                            </Box>
                          </Box>
                        </Box>
                        :
                        <Box className={classes.noData}>
                          <Typography >You have No Recent Achievement</Typography>
                        </Box>
                    }

                  </Paper>

                  <Paper elevation={5} className={classes.cardsPaper}>
                    <Typography variant="subtitle1" component="div" style={{ margin: "15px 15px", fontWeight: "bold", color: "#6c328b" }}>
                      Recent Reward
                    </Typography>
                    {
                      recentInsight.recent_reward ?
                        <Box style={{ width: '100%', paddingLeft: '15px', display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                          <Box display="flex" style={{ alignItems: 'center' }}>
                            <img src={recentInsight?.recent_reward?.icon} height="80px" width="80px" style={{ marginRight: "10px" }} />
                            <Box>
                              <Typography variant="h6" className="prof" style={{ fontWeight: "bold" }}>
                                {recentInsight?.recent_reward?.name}
                              </Typography>

                            </Box>
                          </Box>
                        </Box>
                        :
                        <Box className={classes.noData}>
                          <Typography >You have No Recent Reward</Typography>
                        </Box>
                    }

                  </Paper>
                  <Paper elevation={5} className={classes.cardsPaper}>
                    <Typography variant="subtitle1" component="div" style={{ margin: "15px 15px", fontWeight: "bold", color: "#6c328b" }}>
                      Recently finished goal
                    </Typography>
                    {
                      recentInsight.recent_finished_goal ?
                        <Box style={{ width: '100%', paddingLeft: '15px', display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                          <Box display="flex" style={{ alignItems: 'center' }}>
                            <img src={recentInsight?.recent_finished_goal?.image} height="80%" width="80px" style={{ marginRight: "10px" }} />
                            <Box>
                              <Typography variant="h6" className="prof" style={{ fontWeight: "bold" }}>
                                {recentInsight?.recent_finished_goal?.name}
                              </Typography>

                            </Box>
                          </Box>
                        </Box>
                        :
                        <Box className={classes.noData}>
                          <Typography >You have no recently finished goal</Typography>
                        </Box>
                    }

                  </Paper>
                  <Paper elevation={5} className={classes.cardsPaper}>
                    <Typography variant="subtitle1" component="div" style={{ margin: "15px 15px", fontWeight: "bold", color: "#6c328b" }}>
                      Recently finished assignment
                    </Typography>
                    {
                      recentInsight.recent_finished_assignment ?
                        <Box style={{ width: '100%', paddingLeft: '15px', display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                          <Box display="flex" style={{ alignItems: 'center' }}>
                            <img src={recentInsight?.recent_finished_assignment?.image} height="80%" width="80px" style={{ marginRight: "10px" }} />
                            <Box>
                              <Typography variant="h6" className="prof" style={{ fontWeight: "bold" }}>
                                {recentInsight?.recent_finished_assignment?.name}
                              </Typography>

                            </Box>
                          </Box>
                        </Box>
                        :
                        <Box className={classes.noData}>
                          <Typography >You have no  recently finished assignment</Typography>
                        </Box>
                    }

                  </Paper>
                </Grid>


              </>
          }


        </Grid>
      </TabPanel>
      <TabPanel value={value} index={1}>
        <Grid container spacing={3} style={{ marginTop: "10px" }}>
          {
            recentLoading ? <Box className={classes.noData} > <CircularProgress /> </Box> :
              (
                <>
                  {
                    recentActivity && recentActivity.length > 0 ? recentActivity.slice(0, 8).map((item: any, index: any) => {

                      return (
                        <Grid item xs={12} key={index}>
                          <Paper elevation={5} style={{ borderRadius: "10px", height: "160px", display: 'flex', flexDirection: "column" }}>
                            <Typography variant="subtitle1" component="div" style={{ margin: "15px 15px", fontWeight: "bold", color: "#6c328b" }}>
                              {item.attributes.activity_comment}
                            </Typography>
                            <Box style={{ width: '100%', paddingLeft: '15px' }}>
                              <Typography variant="h6" className="prof" style={{ fontWeight: "bold" }}>
                                {item.attributes.activity_model_name}
                              </Typography>
                              <Box style={{ display: 'flex', marginTop: "5px", justifyContent: "space-between" }}>
                                <Typography variant="subtitle1" color="textSecondary" style={{ marginLeft: "15px" }}>
                                  {item.attributes.duration}
                                </Typography>
                              </Box>
                            </Box>


                          </Paper>
                        </Grid>
                      )
                    }) :
                      <Box className={classes.noData} >Data Not Found</Box>
                  }
                </>
              )}

        </Grid>
      </TabPanel>
    </div>
  );
}
// Customizable Area End
