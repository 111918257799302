import React from 'react'

const ProgressBar = (props: any) => {
    const { allReportsData } = props;
    let video = allReportsData.length > 0 && allReportsData?.map((x:any)=>x.watched_video)?.reduce((acc:any,cur:any)=> acc + cur);
    let totalVideo = allReportsData.length > 0 && allReportsData?.map((x : any) => x.total_video_count)?.reduce((acc:any,cur:any)=> acc+cur);
    let percent = video/ totalVideo *100;
    let number = percent.toFixed();
    
    const webStyle = {
        labelStyles : {
            padding: 5,
            color: '#fff',
            fontWeight: 'bold',
            position:'absolute',
            top:'5px',
            left:'50%',
            transform:'translate(-50%)'
          },
          fillerStyles  :{
            height: '100%',
            width: `${percent}%`,
            background: percent > 80 ?'linear-gradient(90deg, rgba(122,48,139,1) 0%, rgba(215,35,121,1) 100%)' : 'linear-gradient(90deg, rgba(242,55,75,1) 0%, rgba(253,134,65,1) 100%)',
            textAlign: 'right',
            borderRadius: 2,
          },
          containerStyles : {
            height: 40,
            width: '80%',
            backgroundColor: "#e3f5f8",
            borderRadius: 2,
            position:'relative'
          },
          content:{
            fontSize:'25px'
          }
    }
  
    return (
        <>
        {/*@ts-ignore*/}
      <div style={webStyle.containerStyles}>
        {/*@ts-ignore*/}
        <div style={webStyle.fillerStyles}>   
        </div>
        {/*@ts-ignore*/}
        <span style={webStyle.labelStyles}>{`Video ${video}/${totalVideo}`}</span>
      </div>
      <p style={webStyle.content}>{`Progress: ${number}%`}</p>
      </>
    );
  };
  
  export default ProgressBar;