Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.validationApiContentType = "application/json";
exports.apiMethodTypeGet = "GET";
exports.productAPiEndPoint = "catalogue/catalogues";
exports.brandAPiEndPoint = "catalogue/brands";
exports.filterbrandAPiEndPoint = "filter_items/filtering?q[brand_id]=";
exports.singleCategoryAPiEndPoint = "filter_items/filtering?q[category_id]=";
exports.cataloguesAPiEndPoint = "filter_items/filtering?";
exports.search = "bx_block_library2/libraries/search";
exports.channelsSubTitleString = "This results shows Channels based on the keywords of your search";
exports.xpertsSubTitleString = "This results shows Xperts based on the keywords of your search";
exports.coursesSubTitleString = "This results shows your Courses based on the keywords of your search";
exports.addToCart = "/bx_block_shopping_cart/cart"
exports.addToList = "/bx_block_library2/enroll_courses"
// Customizable Area End