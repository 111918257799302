// Customizable Area Start
import React from "react";
import {
  Grid,
  Button,
  FormControl,
  Select,
  MenuItem,
  InputAdornment,
  TextField,
} from "@material-ui/core";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { createStyles, Theme, withStyles } from "@material-ui/core/styles";
import "./styles.css";
import { SearchIcon } from "./assets";
import UserManagementController from "./UserManagementController";
import PageTitle from "../../../../../components/src/DynamicTitle";
import Loader from "../../../../../components/src/Loader";
import UserTable from "./components/UserTable";
export interface Props {
  classes: any;
}
export interface State {
  columnData: any;
  filter: string;
}
class UserManagement extends UserManagementController {

  render() {
    const userManagementControlsContent = (
      <Grid container spacing={2} className={this.props.classes.controlsContainer}>
        <Grid item container lg={6} xs={12} spacing={2}>
          <Grid item md={9} xs={12}>
            <Autocomplete
              freeSolo
              value={this.state.selectedSearch}
              id="user-search-freesolo"
              options={this.state.autoCompleteOptions.map((option: string) => option)}
              classes={{ paper: this.props.classes.paper }}
              onChange={(event, newValue: string | null) => {
                this.setState({ selectedSearch: newValue });
              }}
              onInputChange={(event, newInputValue) => {
                this.setState({ search: newInputValue });
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  fullWidth
                  id="search"
                  name="search"
                  variant="outlined"
                  placeholder="Search"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  className="search"
                  InputProps={{
                    ...params.InputProps,
                    type: "search",
                    startAdornment: (
                      <>
                        <InputAdornment position="start">
                          <img src={SearchIcon} alt="search" />
                        </InputAdornment>
                        {params.InputProps.startAdornment}
                      </>
                    ),
                    endAdornment: null,
                  }}
                />
              )}
            />
          </Grid>
          <Grid item md={3} xs={12}>
            <Button 
              className="create-user-btn"
              onClick={() => this.props.navigation.navigate("CreateUser")}
            >
              Create User
            </Button>
          </Grid>
        </Grid>
        <Grid item container lg={6} xs={12} spacing={2}>
          <Grid
            item
            lg={9}
            md={2}
            xs={12}
            className={this.props.classes.justifyEnd}
          >
            <FormControl className="select-filter">
              <Select
                disabled
                labelId="demo-controlled-open-select-label"
                id="demo-controlled-open-select"
                value={this.state.filter}
                startAdornment={
                  <InputAdornment position="start" className="filter-label">
                    Filter By:
                  </InputAdornment>
                }
                // IconComponent={() => null}
                variant="outlined"
                fullWidth
                onChange={(e) =>
                  this.setState({
                    filter: e.target.value as string,
                  })
                }
              >
                <MenuItem value="team" className="menu-itemss">
                  Team
                </MenuItem>
                <MenuItem value="default-screen" className="menu-itemss">
                  Default Screen
                </MenuItem>
                <MenuItem value="value2" className="menu-itemss">
                  Value 2
                </MenuItem>
              </Select>
            </FormControl>
          </Grid>
          <Grid
            item
            lg={3}
            md={3}
            xs={12}
            className={this.props.classes.justifyEnd}
          >
            <Button className="send-invite-user-btn" disabled>Send Invite(s)</Button>
          </Grid>
        </Grid>
      </Grid>
    );

    let tableContentUI;
    const { isLoading, users } = this.state;
    if (!isLoading && users.length === 0) {
      tableContentUI = (
        <p className={this.props.classes.notFoundText}>
          No data found
        </p>
      );
    } else {
      tableContentUI = (
        <UserTable 
          users={this.state.users}
          onSortIconClick={this.sortUsers}
          prevPageExists={!!this.state.pagination.prevPage}
          nextPageExists={!!this.state.pagination.nextPage}
          handleNextPageNavigation={this.handleNextPageNavigation}
          handlePrevPageNavigation={this.handlePrevPageNavigation}
        />
      );
    }

    return (
      <>
        <Loader loading={this.state.isLoading} />
        <PageTitle title="User Management" />
        <div className="user-management">
          {userManagementControlsContent}
          {tableContentUI}
        </div>
      </>
    );
  }
}

const styles = (theme: Theme) =>
  createStyles({
    controlsContainer: {
      margin: "20px 0"
    },
    justifyEnd: {
      display: "flex",
      justifyContent: "flex-start",
      alignItems: "center",
      [theme.breakpoints.up("lg")]: {
        justifyContent: "flex-end",
      },
    },
    notFoundText: {
      textAlign: "center",
      fontSize: 20,
      marginTop: 50,
    },
    paper: {
      background: "#EEEEEE",
      color: "#11142D",
      borderRadius: 18,
      marginTop: 10,
      boxShadow: "none",
      fontSize: "16px", 
      fontWeight: 500
    }
  });

export default withStyles(styles)(UserManagement);
// Customizable Area End
