export const imgPasswordVisible = require("../assets/ic_password_visible.png");
export const imgPasswordInVisible = require("../assets/ic_password_invisible.png");
export const crown = require("../assets/crown.png");
export const bg = require("../assets/bg.png");
export const goal = require("../assets/goal.png");
export const calender = require("../assets/cale.png");
export const assignment = require("../assets/assignment.png");
export const NOTIFICATION_ICON = require("../assets/notification.png")
export const ADD = require("../assets/Add.png");
export const EDIT = require("../assets/edit.png");
export const CALENDER = require("../assets/calender.png");
export const downArrow = require("../assets/down.png");
export const upArrow = require("../assets/up.png");
export const completionTickBorder = require("../assets/completionTickBorder.png");