import React from "react";
import clsx from "clsx";
import { Box, Checkbox, CircularProgress, IconButton } from "@material-ui/core";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import VolumeUpIcon from "@material-ui/icons/VolumeUp";
import VolumeOffIcon from "@material-ui/icons/VolumeOff";
import { CheckedIcon, UncheckedIcon } from "../assets";
import ActionButtons from "./ActionButtons.web";
import Icon from "./Icon.web";
import Content from "./Content.web";

interface INotificationList {
  isLoading: boolean;
  isEmpty: boolean;
  type: string;
  notifications: any;
  onLearningClick: (notification: any) => void;
  onReminderClick: (notification: any) => void;
  handleSingleNotificationSilent: (
    notification: any,
    shouldSilence: boolean
  ) => void;
  onDismissClick: (id: number) => void;
  handleNotificationSelectionToDelete: (notification: any) => void;
  handleNotificationSelectionToSilent: (notification: any) => void;
  silentNotifications: any;
  selectedNotificationIds: any;
}

interface ISilenceButton {
  showButton: boolean;
  onClick: () => void;
  isSilence: boolean;
}

function SilenceButton({ showButton, onClick, isSilence }: ISilenceButton) {
  const classes = useStyles();

  if (!showButton) return null;
  return (
    <Box className={classes.silenceBtnContainer}>
      <IconButton className={classes.iconBtn} onClick={onClick}>
        {isSilence ? (
          <VolumeOffIcon className={classes.silentIcon} />
        ) : (
          <VolumeUpIcon className={classes.silentIcon} />
        )}
      </IconButton>
    </Box>
  );
}

export default function NotificationList({
  isLoading,
  isEmpty,
  type,
  notifications,
  silentNotifications,
  selectedNotificationIds,
  onLearningClick,
  onReminderClick,
  onDismissClick,
  handleSingleNotificationSilent,
  handleNotificationSelectionToDelete,
  handleNotificationSelectionToSilent,
}: INotificationList) {
  const classes = useStyles();

  if (isEmpty) {
    return (
      <Box className={classes.itemOuter}>
        <p>No new notifications</p>
      </Box>
    );
  }

  if (type === "normal") {
    return (
      <Box className={classes.itemOuter}>
        {isLoading && <CircularProgress className={classes.progress} />}
        {notifications.map((notification: any, key: any) => {
          return (
            <Box key={notification.id} className={classes.itemWrapper}>
              <Box className={classes.itemBox}>
                <Icon type={notification.attributes.notification_type} />
                <Content type={type} notification={notification} />
                <SilenceButton
                  showButton={
                    notification.attributes.notification_type !== "note"
                  }
                  isSilence={notification.attributes.is_silence}
                  onClick={() =>
                    handleSingleNotificationSilent(
                      notification.attributes,
                      !notification.attributes.is_silence
                    )
                  }
                />
              </Box>
              <ActionButtons
                type={notification.attributes.notification_type}
                title={notification.attributes.course_title}
                rewardAchievementEarnedDate={notification.attributes.created_at}
                onLearningClick={() => onLearningClick(notification)}
                onReminderClick={() => onReminderClick(notification)}
                onDismissClick={() =>
                  onDismissClick(notification.attributes.id)
                }
              />
            </Box>
          );
        })}
      </Box>
    );
  }

  return (
    <Box className={classes.itemOuter}>
      {isLoading && <CircularProgress className={classes.progress} />}
      {notifications.map((notification: any, key: any) => {
        const notificationIndex = silentNotifications.findIndex(
          (n: any) => n.id === notification.attributes.id
        );
        const isChecked =
          type === "delete"
            ? selectedNotificationIds.includes(notification.attributes.id)
            : notificationIndex > -1;
        return (
          <Box
            key={notification.id}
            className={
              isChecked
                ? classes.itemWrapperSelected
                : clsx(classes.itemWrapper, isLoading && classes.blur)
            }
          >
            <Box className={clsx(classes.itemBox, classes.itemBoxCheckbox)}>
              <Checkbox
                icon={<img src={UncheckedIcon} alt="uncheck-icon" />}
                checkedIcon={<img src={CheckedIcon} alt="check-icon" />}
                checked={isChecked}
                onClick={() =>
                  type === "delete"
                    ? handleNotificationSelectionToDelete(
                        notification.attributes.id
                      )
                    : handleNotificationSelectionToSilent(
                        notification.attributes
                      )
                }
                disableRipple={true}
                name="select-notification"
                color="default"
              />
              <Icon type={notification.attributes.notification_type} />
              <Content type={type} notification={notification} />
              <SilenceButton
                showButton={
                  notification.attributes.notification_type !== "note"
                }
                isSilence={notification.attributes.is_silence}
                onClick={() =>
                  handleSingleNotificationSilent(
                    notification.attributes,
                    !notification.attributes.is_silence
                  )
                }
              />
            </Box>
            <ActionButtons
              type={notification.attributes.notification_type}
              title={notification.attributes.course_title}
              rewardAchievementEarnedDate={notification.attributes.created_at}
              onLearningClick={() => onLearningClick(notification)}
              onReminderClick={() => onReminderClick(notification)}
              onDismissClick={() => onDismissClick(notification.attributes.id)}
            />
          </Box>
        );
      })}
    </Box>
  );
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    itemOuter: {
      overflowY: "scroll" as "scroll",
      width: "100%",
      minHeight: 100,
      display: "flex",
      flexDirection: "column" as "column",
      alignItems: "center",
      paddingBottom: 10,
      position: "relative",
      [theme.breakpoints.up("sm")]: {
        marginBottom: 20,
      },
    },
    itemWrapper: {
      display: "flex",
      width: "90%",
      marginTop: 10,
      padding: 16,
      // cursor: "pointer",
      background: "#fff",
      borderRadius: "18px",
      boxShadow: "1px 2px 10px rgba(0, 0, 0, 0.0676628)",
      flexDirection: "column",
    } as const,
    itemWrapperSelected: {
      display: "flex",
      width: "90%",
      marginTop: 10,
      padding: 16,
      // cursor: "pointer",
      background: "rgba(108, 50, 139, 0.1)",
      borderRadius: "15px",
      boxShadow: "1px 2px 10px rgba(0, 0, 0, 0.0676628)",
      flexDirection: "column",
    },
    itemBox: {
      display: "flex",
      flexDirection: "row" as "row",
      justifyContent: "flex-start",
      alignItems: "flex-start",
    },
    itemBoxCheckbox: {
      alignItems: "center",
    },
    progress: {
      color: "#6C328B",
      position: "absolute",
      zIndex: 9999,
    },
    blur: {
      opacity: "0.7",
    },
    silentIcon: {
      color: "#6C328B",
    },
    iconBtn: {
      
    },
    silenceBtnContainer: {
      width: "10%",
      alignSelf: "center",
    }
  })
);
