import React from "react";
import {
  Box, Grid
  // Customizable Area End
  ,
  // Customizable Area Start
  Paper, TextField, Typography
} from "@material-ui/core";
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardMedia from '@material-ui/core/CardMedia';
import LinearProgress from "@material-ui/core/LinearProgress";
import { withStyles } from '@material-ui/core/styles';
import InProgessController from './InProgressController.web';
import SearchIcon from '@material-ui/icons/Search';
import './videostyles.css';
// Customizable Area Start
const img4 = require("../assets/slider-1.png");
const img2 = require("../assets/courseimage4.png");
const play = require("../assets/play.png")
const img3 = require("../assets/courseimage5.png")
const group = require("../assets/Group 8.png")
const shop = require("../assets/image_Bitmap Copy 4.png")

// Customizable Area End
const BorderLinearProgress = withStyles((theme) => ({
    root: {
      height: 10,
      borderRadius: 5,
      width: '60%',
      marginTop: '15px',
      marginLeft: '17px',
    },
    colorPrimary: {
      // backgroundColor: theme.palette.grey[theme.palette.type === 'light' ? 200 : 700],
      backgroundColor:
        theme.palette.grey[theme.palette.type === "light" ? 200 : 700]
    },
    bar: {
      borderRadius: 5,
      background: `linear-gradient(87deg, rgba(240,50,76,1) 0%, rgba(254,132,64,1) 100%)`
    },
  }))(LinearProgress);

export default class InProgess extends InProgessController {
    //   constructor() {
    // super();
    // Customizable Area Start
    // Customizable Area End
    //   }

    // Customizable Area Start

    // Customizable Area End
    _onFocus = () => {
      this.props.history.push('/Search')
    }

    render() {
        return (
            <>

                <Grid container style={{paddingTop:"0px"}}>
                    <Grid item lg={12} md={12} sm={12} xs={12}>
                <Typography variant="h3" className="profile_header" style={{ display: 'contents' }}>In Progress</Typography>
                <Grid container style={{ marginTop: '30px' }}>
                    <Grid item lg={12} md={12} sm={12} xs={12}>
                        <TextField onFocus={this._onFocus} className="inputRounded" style={{width: '373px', borderRadius:'12px'}}  
                        onChange={this.onChangeHandle}
                         value={this.state.search}
                         placeholder=" Search" variant="outlined" />
                          <Box style={{marginTop:'-38px',marginLeft:'8px'}}>
                  <SearchIcon />
                </Box>
                    </Grid>
                </Grid>

                {/* <Grid item lg={12} md={12} sm={12} xs={12}> */}
                <Grid container style={{ marginTop: "25px" }}>
                    {this.state.allInProgressData.length !== 0 ?
                    this.state.allInProgressData?.map((ele: any, index: any) => {
                      return (
                        <Grid item lg={3} md={4} sm={6} xs={12} key={index}>
                             <>
                             <Box style={{padding:"0 10px 0 10px" , borderRadius: '25px 25px 0 0', marginTop: '20px' }} >
                <CardMedia className="cardmedia"
                  style={{ height: "240px", width: '100%', borderRadius: '25px 25px 0 0' }}
                  // image={img2}
                  image={`${ele?.attributes?.collection?.attributes?.thumbnail_image?.url}`}
                />
                <Paper className="imagepaper" style={{ width: '100%', borderRadius: '25px', marginTop: '-20px', height: '168px' }}>
                  <Typography 
                  onClick={() => this.handleNavigate(ele)}
                   style={{ minHeight: 65, marginBottom: '-10px', padding: '17px', fontWeight: 600, fontSize: '14px', lineHeight: '20px', cursor: 'pointer' }}
                   >
                    {/* HTML 5 Expert course and CSS 3 with sample project */}
                    {ele?.attributes?.collection?.attributes?.title}
                  </Typography>
                  <Typography variant="body2" color="textSecondary" component="p"
                    style={{ margin: 'auto', marginTop: '-15px', padding: '0 17px', color: 'black' }}>
                    {ele?.attributes?.collection?.attributes?.created_by}
                  </Typography>
                  <Box>
                    <BorderLinearProgress variant="determinate" value={(ele?.attributes?.completed_video_count / ele?.attributes?.total_video_count) * 100} />
                    <Typography variant="body2" color="textSecondary" component="p"
                      style={{
                        margin: 'auto', fontSize: '12px', marginTop: '10px', padding: '0 17px',
                        color: 'rgba(254,132,64,1)'
                      }}>
                      {ele?.attributes?.completed_video_count}/{ele?.attributes?.total_video_count} Videos Completed
                    </Typography>
                    <Card style={{
                      width: '40px', height: '40px', borderRadius: '10px', float: 'right',
                      marginTop: '-50px', marginRight: '25px'
                    }}>
                      <CardActionArea>
                        <img src={play} 
                        onClick={() => this.props.history.push(`/title/${ele?.attributes?.collection?.attributes?.course_video_track?.last_video_id}${ele?.attributes?.collection?.attributes?.title.replace(/\s/g, '-')}`,{id:ele?.attributes?.collection?.attributes?.course_video_track?.last_video_id})} 
                        style={{ margin: 'auto', padding: '8px' }} />
                      </CardActionArea>
                    </Card>
                  </Box>
                </Paper>
              </Box>
                            </>
                        </Grid>
          )  }) :
                    <><h2 style={{textAlign:'center',marginTop:'10%'}}>No Courses available</h2></>}
                </Grid>

                </Grid>

                </Grid>

            </>



        )

    }

}
