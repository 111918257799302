import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { toast } from "react-toastify";
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  location:any;
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  data:any
  image:any
  filename:any
  imgUrl:any
  img:any
  file:any
  id:any,
  isFreeTrial:boolean,
  loading:boolean,
  duration:any,
  course:any
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class VideosController extends BlockComponent<Props, S, SS> {
  // Customizable Area Start
  getStockImagesApiCallId:string
  getAddVideoApiCallId:string
  getFreeTrialApiCallId:string
  getCourseDetailApiCallId: string;
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    this.subScribedMessages = [
      // Customizable Area Start
    getName(MessageEnum.RestAPIResponceMessage),
    getName(MessageEnum.NavigationPayLoadMessage),
    getName(MessageEnum.RestAPIResponceSuccessMessage)
      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start
      data:[],
      image:"",
      filename:"",
      imgUrl:"",
      img:"",
      file:"",
      id:"",
      isFreeTrial:false,
      loading:false,
      duration:0,
      course:""
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    this.handleGetCourseDetailResponse = this.handleGetCourseDetailResponse.bind(this);
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );
      let responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      if (this.getStockImagesApiCallId === apiRequestCallId) {
        if (responseJson && responseJson.data) {
          const images = responseJson.data  && responseJson.data.attributes && responseJson.data.attributes && responseJson.data.attributes.images.length>0 && responseJson.data.attributes.images.map((item: any) => {
            return {
              id: item.id,
              selected: false, url: item.url,
              featured: true
            }
          });
          this.setState({ data: images });

        }

     }
     else if(this.getFreeTrialApiCallId === apiRequestCallId){
      if(responseJson && responseJson.is_free_trial){
        this.setState({isFreeTrial:responseJson.is_free_trial})
      }
     }
      else if(this.getAddVideoApiCallId === apiRequestCallId){
        if (responseJson && responseJson.data) {
        this.setState({loading:false})
         toast.success("Video Add successfuly",{delay:2000});
         this.props.navigation.navigate("BuildCourse",{
          id:this.props.navigation.getParam('id')
         })
        }
        else if(responseJson?.errors) {
          let value = responseJson.errors.map((item:any) => Object.keys(item))
          toast.error(responseJson.errors[0][value[0]],{delay:2000});  
        }
      }
      else if(this.getCourseDetailApiCallId === apiRequestCallId){
        this.handleGetCourseDetailResponse(responseJson);
      }
    }
    runEngine.debugLog("Message Recived", message);
    // Customizable Area End
  }

  // Customizable Area Start
  async componentDidMount(){
    if(this.props?.navigation?.getParam('id')==="login"){
      this.props?.navigation?.navigate("EmailAccountLoginBlock")
    }
    this.getStockImage()
    this.getFreeTrial()
    this.getCourseDetail(this.props?.navigation?.getParam('id'))
  }
  handleGetCourseDetailResponse(responseJson:any) {
    if (responseJson && responseJson.data) {
      this.setState({ course: responseJson.data});
    }
  }
  getStockImage () {    
    this.doGetStockImages({
      contentType: configJSON.validationApiContentType,
      method: configJSON.validationApiMethodType,
      endPoint: configJSON.stockImageApiEndPoint,
  });
  }
  getFreeTrial () {    
    this.doGetFreeTrial({
      contentType: configJSON.validationApiContentType,
      method: configJSON.validationApiMethodType,
      endPoint: configJSON.getFreeTrialApiEndPoint+`?course_id=${this.props.navigation.getParam('id')}`,
  });
  }  
  doGetFreeTrial(data: any) {
    const { contentType, method, endPoint, body } = data;
    const header = {
      "Content-Type": contentType,
      token: localStorage.getItem("token"),
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
  
  );
  requestMessage.addData(
  getName(MessageEnum.RestAPIRequestHeaderMessage),
  JSON.stringify(header)
  );
  this.getFreeTrialApiCallId = requestMessage.messageId
  requestMessage.addData(
  getName(MessageEnum.RestAPIResponceEndPointMessage),
  endPoint
  );
  requestMessage.addData(
  getName(MessageEnum.RestAPIRequestMethodMessage),
  method
  );
  body &&
  requestMessage.addData(
    getName(MessageEnum.RestAPIRequestBodyMessage),
    body
  );
  runEngine.sendMessage(requestMessage.id, requestMessage);
  return requestMessage.messageId;
  }
  
  addVideo(data:any){
    this.doPostAddVideo({
      contentType: configJSON.validationApiContentType,
      method: configJSON.reviewAPiMethod,
      endPoint: configJSON.addVideoApiEndPoint,
      body:data
  });
  }
  doPostAddVideo(data: any) {
    const { method, endPoint, body } = data;
    console.log("filename_local",data);
    
    const header = {
      "Content-Type": undefined,
      token: localStorage.getItem("token"),
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
  
  );
  requestMessage.addData(
  getName(MessageEnum.RestAPIRequestHeaderMessage),
  JSON.stringify(header)
  );
  this.getAddVideoApiCallId = requestMessage.messageId
  requestMessage.addData(
  getName(MessageEnum.RestAPIResponceEndPointMessage),
  endPoint
  );
  requestMessage.addData(
  getName(MessageEnum.RestAPIRequestMethodMessage),
  method
  );
  body &&
  requestMessage.addData(
    getName(MessageEnum.RestAPIRequestBodyMessage),
    body
  );
  runEngine.sendMessage(requestMessage.id, requestMessage);
  return requestMessage.messageId;
  }
  doGetStockImages(data: any) {
    const { contentType, method, endPoint, body } = data;
    const header = {
      "Content-Type": contentType,
      token: localStorage.getItem("token"),
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
  
  );
  requestMessage.addData(
  getName(MessageEnum.RestAPIRequestHeaderMessage),
  JSON.stringify(header)
  );
  this.getStockImagesApiCallId = requestMessage.messageId
  requestMessage.addData(
  getName(MessageEnum.RestAPIResponceEndPointMessage),
  endPoint
  );
  requestMessage.addData(
  getName(MessageEnum.RestAPIRequestMethodMessage),
  method
  );
  body &&
  requestMessage.addData(
    getName(MessageEnum.RestAPIRequestBodyMessage),
    body
  );
  runEngine.sendMessage(requestMessage.id, requestMessage);
  return requestMessage.messageId;
  }
  getCourseDetail(id: any) {
    this.doGetCourseDetail({
      contentType: configJSON.validationApiContentType,
      method: configJSON.validationApiMethodType,
      endPoint: configJSON.getCourseDetailApiEndPoint + `/${id}`,
    });
  }
  doGetCourseDetail(data: any) {
    const { contentType, method, endPoint } = data;
    const header = {
      "Content-Type": contentType,
      token: localStorage.getItem("token")
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)

    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    this.getCourseDetailApiCallId = requestMessage.messageId
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      endPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      method
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return requestMessage.messageId;
  }
  // Customizable Area End
}
