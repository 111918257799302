import React from "react";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import { Grid, FormControlLabel, Radio, Button } from "@material-ui/core";
import { capitalizeFirstLetter } from "../../../../framework/src/Helpers.web";
import clsx from "clsx";
import { trashIcon } from "../assets";

interface ISubscriptionCartItem {
  id: string;
  selectedItemId: string;
  title: string;
  author: string;
  price: string;
  period: string;
  onRemoveClick: () => void;
}

export default function SubscriptionCartItem({
  id,
  title,
  author,
  price,
  period,
  onRemoveClick,
  selectedItemId,
}: ISubscriptionCartItem) {
  const classes = useStyles();
  const isSelected = id === selectedItemId;

  return (
    <div
      className={clsx(
        classes.cartItemContainer,
        isSelected && classes.cartItemContainerSelected
      )}
    >
      <Grid container spacing={2} justifyContent="center" alignItems="center">
        <Grid item xs={12} sm={10}>
          <FormControlLabel
            value={id}
            className={classes.radioLabel}
            control={
              <Radio
                className={clsx(
                  classes.radioBtn,
                  isSelected && classes.radioBtnSelected
                )}
              />
            }
            label={
              <>
                <p
                  className={clsx(
                    classes.title,
                    isSelected && classes.titleSelected
                  )}
                >
                  {capitalizeFirstLetter(title)}
                </p>
                <p
                  className={clsx(
                    classes.author,
                    isSelected && classes.authorSelected
                  )}
                >
                  By {author}
                </p>
                <div className={classes.priceContainer}>
                  <p
                    className={clsx(
                      classes.price,
                      isSelected && classes.priceSelected
                    )}
                  >
                    $ {parseFloat(price).toFixed(2)}
                  </p>
                  <p
                    className={clsx(
                      classes.duration,
                      isSelected && classes.durationSelected
                    )}
                  >
                    {capitalizeFirstLetter(period)}
                  </p>
                </div>
              </>
            }
          />
        </Grid>
        <Grid container justifyContent="center" item xs={12} sm={2}>
          <Button className={classes.removeBtn} onClick={onRemoveClick}>
            <img
              src={trashIcon}
              className={classes.trashIcon}
              alt="remove from cart"
            />
            <p className={classes.removeText}>Remove</p>
          </Button>
        </Grid>
      </Grid>
    </div>
  );
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    cartItemContainer: {
      background: "#FAFAFA",
      border: "1px solid #E6E8EC",
      boxShadow: "0px 0px 16px rgba(213, 213, 213, 0.350581)",
      borderRadius: "16px",
      padding: "10px 20px",
      marginBottom: 20,
    },
    cartItemContainerSelected: {
      background: "#F6E9FB",
      border: "1px solid #6C328B",
      boxShadow: "0px 0px 16px rgba(213, 213, 213, 0.350581)",
      borderRadius: "16px",
      padding: "10px 20px",
      marginBottom: 20,
    },
    radioLabel: {
      "& .Mui-checked": {
        color: "#6C328E",
      },
    },
    radioBtn: {
      color: "#777E90",
      marginBottom: 78,
    },
    radioBtnSelected: {
      color: "#6C328B",
    },
    title: {
      fontWeight: 600,
      fontSize: "20px",
      lineHeight: "20px",
      color: "#777E90",
      margin: "20px 0",
      marginLeft: 10,
    },
    titleSelected: {
      color: "#6C328B",
    },
    author: {
      fontWeight: 400,
      fontSize: "15px",
      lineHeight: "22px",
      color: "#777E90",
      textTransform: "capitalize",
      margin: "10px 0",
      marginLeft: 10,
    },
    authorSelected: {
      color: "#545650",
    },
    priceContainer: {
      display: "flex",
      justifyContent: "flex-start",
      alignItems: "center",
    },
    price: {
      fontWeight: 700,
      fontSize: "28px",
      lineHeight: "36px",
      color: "#777E90",
      margin: 0,
      // marginBottom: 5
    },
    priceSelected: {
      color: "#6C328B",
    },
    duration: {
      fontWeight: 600,
      fontSize: "15px",
      lineHeight: "18px",
      color: "#777E90",
      marginLeft: 5,
      // margin: "0 0 15px 0"
    },
    durationSelected: {
      color: "#6C328B",
    },
    removeBtn: {
      display: "flex",
      "&.MuiButton-root": {
        textTransform: "capitalize",
      },
    },
    trashIcon: {
      width: 19,
      height: 24,
      marginRight: 10,
    },
    removeText: {
      fontWeight: 700,
      fontSize: "16px",
      lineHeight: "16px",
      color: "#1A1A1A",
    },
  })
);
