import React from "react";

import {
  Container,
  Box,
  Input,
  Button,
  InputLabel,
  Typography,
  InputAdornment,
  IconButton,
  // Customizable Area Start
  Radio,
  RadioGroup,
  FormControlLabel,
  // Customizable Area End
} from "@material-ui/core";

// Customizable Area Start
import { createStyles, Theme, withStyles } from "@material-ui/core/styles";
import DebitCreditPayment from "./components/DebitCreditPayment.web";
import StripePayment from "./components/StripePayment.web";
import SavedCards from "./components/SavedCards.web";
import { DebitCreditCardsImg } from "./assets";
import "../assets/css/styles.css";
import Loader from "../../../components/src/Loader.web";
// Customizable Area End

import PaymentsController, {
  Props,
} from "./PaymentsController.web";

// Customizable Area Start
class Payments extends PaymentsController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End

  render() {
    const { classes } = this.props;
    let paymentMehod;
    if (this.state.paymentMethod === "debit") {
      paymentMehod = (
        <DebitCreditPayment
          onSubmission={this.handleDebitCreditCardPayment}
          payableAmount={this.props.payableAmount}
          transitionError={this.state.transitionError}
        />
        );
      } else if (this.state.paymentMethod === "stripe") {
        paymentMehod = (
          <StripePayment
            publishableKey={this.state.publishableKey}
            clientSecret={this.state.clientSecret}
            handleStripeSuccess={this.props.onSuccess}
            handleStripeFailure={this.props.onFailure}
          />
      );
    }

    const debitCreditLabel = (
      <div className={classes.debitCreditLabel}>
        <p>Debit/Credit Card</p>
        <img 
          src={DebitCreditCardsImg} 
          className={classes.debitCreditImg} 
          alt="Debit/Credit Card" 
        />
      </div>
    )

    let subscriptionPeriod;
    let subscriptionPeriodShort;
    const { cartType, selectedSubsCartItem } = this.props;
    if (cartType === "otp") {
      subscriptionPeriod = "null";
      subscriptionPeriodShort = "null";
    } else {
      switch (selectedSubsCartItem.subscriptionPeriod) {
        case "monthly":
          subscriptionPeriod = "month";
          subscriptionPeriodShort = "/mo";
          break;
          case "semi-anually":
          subscriptionPeriod = "semi annual";
          subscriptionPeriodShort = "/semi-ann";
          break;
          case "anually":
          subscriptionPeriod = "annual";
          subscriptionPeriodShort = "/ann";
          break;
      
        default:
          break;
      }
    }

    if (this.props.payableAmount === 0) {
      return null;
    }

    return (
      <Box className={classes.paymentBox}>
        <Loader loading={this.state.isLoading} />
        <Typography className={classes.primaryHeading}>payment information</Typography>
        <SavedCards 
          savedCards={this.state.savedCards}
          selectedCardId={this.state.cardId}
          handleCardDelete={this.handleCardDelete}
          onCardClick={this.handleDebitCreditCardPayment}
        />
        {/* Selection of payment methods */}
        <section className={classes.paymentMehodSection}>
          <Typography className={classes.secondaryHeading}>Select mode of payment</Typography> 
          <RadioGroup
            aria-label="method"
            name="paymentMethod"
            value={this.state.paymentMethod}
            onChange={(e: any) => this.handlePaymentMethodChange(e)}
          >
            <FormControlLabel
              className={classes.radio}
              value="debit"
              control={<Radio />}
              label={debitCreditLabel}
            />
            <FormControlLabel
              className={classes.radio}
              value="stripe"
              control={<Radio />}
              label="Stripe Payments"
            />
          </RadioGroup>
          {paymentMehod}
        </section>
        {
          this.state.paymentMethod === "" && (
            <>
              <section className={classes.orderTotalSection}>
                {
                  this.props.cartType === "subscription" ? (
                    <div className={classes.orderTextContainer}>
                      <p className={classes.orderText}>order total</p>
                      <div className={classes.subsContainer}>{subscriptionPeriod}</div>
                    </div>
                  ) : (
                    <p className={classes.orderText}>order total</p>
                  )
                }
                <p className={classes.totalText}>
                  ${this.props.payableAmount.toFixed(2)}
                  {this.props.cartType === "subscription" && (
                    <span className={classes.subscriptionPeriod}>
                      {subscriptionPeriodShort}
                    </span>
                  )}
                </p>
              </section>
            </>
          )
        }
      </Box>
    );
  }
}
// Customizable Area End

// Customizable Area Start

const styles = (theme: Theme) => createStyles({
  paymentBox: {
    borderRadius: "20px 30px",
    background: "linear-gradient(184.52deg, #FFFFFF 10.37%, #F0F3F6 96.34%);",
    padding: 20,
  },
  primaryHeading: {
    fontWeight: 700,
    fontSize: "24px",
    lineHeight: "29px",
    color: "#01040D",
    margin: "10px 0",
    textTransform: "capitalize",
  },
  secondaryHeading: {
    fontWeight: 600,
    fontSize: "18px",
    lineHeight: "27px",
    color: "#01040D",
    margin: "10px 0",
  },
  card: {
    height: "100%",
    display: "flex",
    flexDirection: "column",
    maxWidth: "24rem",
    position: "relative",
    border: "1px solid #ccc"
  },
  paymentMehodSection: {
    marginTop: 35,
    paddingBottom: 10,
  },
  radio: {
    "& .Mui-checked": {
      color: "#6C328E",
    },
  },
  cardDeatailsH1: {
    fontSize: "16px",
    fontWeight: 700,
  },
  debitCreditLabel: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-start",
    alignItems: "center",
    marginBottom: 15,
    [theme.breakpoints.up("sm")]: {
      flexDirection: "row",
      justifyContent: "space-between",
      marginBottom: 0,
    },
    "& p": {
      margin: "10px 0"
    }
  },
  debitCreditImg: {
    marginLeft: 0,
    [theme.breakpoints.up("sm")]: {
      marginLeft: 30,
    }
  },
  orderTotalSection: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    alignItems: "center",
    borderTop: "1px solid #01040D",
    [theme.breakpoints.up("sm")]: {
      flexDirection: "row",
    }
  },
  orderTextContainer: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  orderText: {
    fontSize: "16px",
    fontWeight: 400,
    lineHeight: "22px",
    textTransform: "capitalize",
  },
  subsContainer: {
    borderRadius: "4px",
    border: "1px solid #6C328B",
    background: "#FFF",
    color: "#6C328B",
    fontSize: "14px",
    fontWeight: 500,
    lineHeight: "22px",
    padding: "5px 25px",
    marginLeft: "10px",
    textTransform: "capitalize",
  },
  totalText: {
    fontWeight: 800,
    fontSize: "22px",
    lineHeight: "28px",
    textAlign: "right",
    color: "#545650"
  },
  subscriptionPeriod: {
    fontSize: 14,
    fontWeight: 400,
  },
  checkoutBtn:{
    width: '100%',
    height: '64px',
    borderRadius: '16px',
    backgroundColor: '#6c328b',
    color: '#fff',
    fontSize: '15px',
    fontWeight: 700,
    lineHeight: '24px',
    textTransform: 'none',
    marginTop:'15px',
    "&:hover": {
      backgroundColor: '#6c328b',
    }
   },
})

export default withStyles(styles)(Payments);
// Customizable Area End