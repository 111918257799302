export const imgPasswordVisible = require("../assets/ic_password_visible.png");
export const imgPasswordInVisible = require("../assets/ic_password_invisible.png");
export const calender = require("../assets/calender.png");
export const filter = require("../assets/filter.png");
export const addGoal = require("../assets/add_goal.png");
export const achievement_heading = require("../assets/achievement_heading.png");
export const goal_target = require("../assets/goal_target.png");
export const profile = require("../assets/profile.png");
export const path = require("../assets/path.png");
export const target = require("../assets/target.png");
export const graph_bg = require("../assets/graph_bg.png");
export const trophy = require("../assets/trophy.png");



