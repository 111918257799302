// import 'react-toastify/dist/ReactToastify.css';
import { toast } from "react-toastify";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { imgPasswordInVisible, imgPasswordVisible } from "./assets";
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  history: any;
  location: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  txtInputValue: string;
  txtSavedValue: string;
  enableField: boolean;
  // Customizable Area Start
  anchorEl: any;
  allXpandLibararyData: any;
  grapValues: any;
  postXpandLibararyData: any;
  search: any;
  themeMode: boolean,
  Modalopen: boolean,
  dialogopen: boolean,
  createFolder: any,
  allVideoData: any,
  newId: any,
  rename: boolean,
  editRename: any,
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class ManageContentFolderController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  GetXpandLibraryData: any;
  PostCeateNewFolder: any;
  FolderLock: any;
  UpdateRename: any;
  GetVideoData: any;
  GetDeleteVideo: any;
  GetSearchData: any;
  GetdeleteFolder: any;
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      // Customizable Area End
    ];

    this.state = {
      txtInputValue: "",
      txtSavedValue: "A",
      enableField: false,
      // Customizable Area Start
      anchorEl: '',
      grapValues: 'Week',
      allXpandLibararyData: {},
      postXpandLibararyData: {},
      search: "",
      themeMode: false,
      Modalopen: false,
      dialogopen: false,
      rename: false,
      createFolder: '',
      editRename: '',
      newId: '',
      allVideoData: {},

      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
  }

  // @ts-ignore
  componentDidMount() {

    this.getXpandLibraryData();
    this.getVideoData();
    // console.log(this.props.history.push('/ManageContentLibrary'))
  }

  componentDidUpdate(prevProps:any) {
    if (this.props.location.pathname !== prevProps.location.pathname) {
      this.getXpandLibraryData();
    this.getVideoData();
    }
  }

  async receive(from: string, message: Message) {
    runEngine.debugLog("Message Recived", message);

    if (message.id === getName(MessageEnum.AccoutLoginSuccess)) {
      let value = message.getData(getName(MessageEnum.AuthTokenDataMessage));

      this.showAlert(
        "Change Value",
        "From: " + this.state.txtSavedValue + " To: " + value
      );

      this.setState({ txtSavedValue: value });
    }

    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      let responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      if (apiRequestCallId && responseJson) {
        if (apiRequestCallId === this.GetXpandLibraryData) {
          this.setState({ allXpandLibararyData: responseJson })

        }
        if (apiRequestCallId === this.GetVideoData) {
          this.setState({ allVideoData: responseJson })
        }
        if (apiRequestCallId === this.GetDeleteVideo) {
          this.getVideoData();
        }

        if (apiRequestCallId == this.PostCeateNewFolder) {
          this.getXpandLibraryData();
          this.setState({ dialogopen: false })
        }
        if (apiRequestCallId === this.FolderLock) {
          this.getXpandLibraryData();
          toast.success("Folder Lock Succesfully");
          this.getVideoData();
          this.setState({ Modalopen: false })
          this.setState({ rename: false })
        }
        if (apiRequestCallId === this.UpdateRename) {
          this.getXpandLibraryData();
          this.getVideoData();
          this.setState({ Modalopen: false })
          this.setState({ rename: false })
        }
        if (apiRequestCallId === this.GetSearchData) {
          this.setState({ allXpandLibararyData: responseJson });
          if (this.state.search === "") {
            this.getXpandLibraryData();
          }
        }
        if (apiRequestCallId === this.GetdeleteFolder) {
            this.getXpandLibraryData();
            this.props.history.push('/ManageContentLibrary')
            this.setState({ Modalopen: false })
          
        }

      }

    }
    // Customizable Area End
  }

  txtInputWebProps = {
    onChangeText: (text: string) => {
      this.setState({ txtInputValue: text });
    },
    secureTextEntry: false,
  };

  txtInputMobileProps = {
    ...this.txtInputWebProps,
    autoCompleteType: "email",
    keyboardType: "email-address",
  };

  txtInputProps = this.isPlatformWeb()
    ? this.txtInputWebProps
    : this.txtInputMobileProps;

  btnShowHideProps = {
    onPress: () => {
      this.setState({ enableField: !this.state.enableField });
      this.txtInputProps.secureTextEntry = !this.state.enableField;
      this.btnShowHideImageProps.source = this.txtInputProps.secureTextEntry
        ? imgPasswordVisible
        : imgPasswordInVisible;
    },
  };

  btnShowHideImageProps = {
    source: this.txtInputProps.secureTextEntry
      ? imgPasswordVisible
      : imgPasswordInVisible,
  };

  btnExampleProps = {
    onPress: () => this.doButtonPressed(),
  };

  doButtonPressed() {
    let msg = new Message(getName(MessageEnum.AccoutLoginSuccess));
    msg.addData(
      getName(MessageEnum.AuthTokenDataMessage),
      this.state.txtInputValue
    );
    this.send(msg);
  }

  // web events
  setInputValue = (text: string) => {
    this.setState({ txtInputValue: text });
  };

  setEnableField = () => {
    this.setState({ enableField: !this.state.enableField });
  };

  // Customizable Area Start

  handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    this.setState({ anchorEl: event.currentTarget });
  };

  tostSuccessfully = () => {
    toast.success("Coming soon");
    this.setState({ Modalopen: false })
  }

  handleClose = () => {
    this.setState({ anchorEl: null });
  };

  ModalhandleClose = () => {
    this.setState({ Modalopen: false })
  }

  ModalhandleOpen = (data: any) => {
    this.setState({ Modalopen: true })
    this.setState({ editRename: data })
  }

  dialogHandleClose = () => {
    this.setState({ dialogopen: false })
  }

  dialogHandleOpen = () => {
    this.setState({ dialogopen: true })
  }
  RenameHandleOpen = () => {
    this.setState({ rename: true })
  }

  RenameHandleClose = () => {
    this.setState({ rename: false })
  }

  addNewVideo = () => {
    this.props.history.push(`/Addvideo/${this.props.navigation.getParam('id')}`, { id: this.props.history.location.state.id })
  }

  viewQuizBank = () => {
    this.props.history.push(`/create-quiz`, { id: this.props.history.location.state.id })
  }

  delete_folder = (id: any) => {
    console.log('delete');
    const header = {
      "Content-Type": "application/json",
      token: localStorage.getItem("token")
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.GetDeleteVideo = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_attachment/library_video/${id}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      header
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      'delete'
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  getVideoData = async () => {
    const header = {
      "Content-Type": "application/json",
      token: localStorage.getItem("token")
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.GetVideoData = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_admin/folder/?folder_id=${this.props.history.location.state.id}&folder_type=content_library`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      header
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      'get'
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  handleNavigate = (data: any) => {
    this.props.history.push(`/ManageContentFolder/${data}`, { id: data });
    console.log(data, 'kkkkkkk');
    this.setState({ newId: data }, () => {
      this.getXpandLibraryData()
    })
   
  };
  getXpandLibraryData = async () => {
    const header = {
      "Content-Type": "application/json",
      token: localStorage.getItem("token")
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.GetXpandLibraryData = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_admin/folder/?folder_id=${this.props.history.location.state.id}&folder_type=content_library`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      header
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      'get'
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }


  postplayButton = async (id: any) => {
    this.props.history.push(`/course-detail/${id}`, { name: id })
  }


  createNewFolder = () => {

    let formdata = new FormData();
    formdata.append("name", this.state.createFolder);
    formdata.append("folder_type", 'content_library');
    formdata.append("parent_folder_id", this.props.history.location.state.id)

    const header = {
      ContentType: "multipart/form-data",
      token: localStorage.getItem("token")
    };
    const httpBody = formdata;

    console.log("httpBody", (httpBody))
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.PostCeateNewFolder = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_admin/folder`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      header
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      'Post'
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      httpBody
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  getSearchData = async (event: any) => {
    this.setState({ search: event.target.value })
    console.log(this.state.search);

    const header = {
      "Content-Type": "application/json",
      token: localStorage.getItem("token")
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.GetSearchData = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_admin/folder?folder_id=${this.props.history.location.state.id}&folder_type=content_library&search=${this.state.search}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      header
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      'get'
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);

  }

  DeleteFolderAPI = () => {
    const header = {
      "Content-Type": "application/json",
      token: localStorage.getItem("token")
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.GetdeleteFolder = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_admin/folder/${this.props.history.location.state.id}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      header
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      'delete'
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);

  }

  FolderRenameAPI = () => {
    let formdata = new FormData();
    formdata.append("name", this.state.editRename);


    const header = {
      ContentType: "multipart/form-data",
      token: localStorage.getItem("token")
    };
    const httpBody = formdata;

    console.log("httpBody", (httpBody))
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.UpdateRename = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_admin/folder/${this.props.history.location.state.id}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      header
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      'Put'
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      httpBody
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }


  FolderLockAPI = () => {
    const header = {
      'Content-Type': 'application/json',
      token: localStorage.getItem("token")
    };
    const httpBody = {
      "folder_id": this.props.history.location.state.id
    };

    console.log("httpBody", (httpBody))
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.FolderLock = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_admin/folder/lock_folder`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      header
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      'Post'
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }
  // Customizable Area End
}