// Customizable Area 
import React from "react";

import {
  Container,
  Box,
  Input,
  Button,
  InputLabel,
  Typography,
  InputAdornment,
  IconButton,
  // Customizable Area Start
  Grid,
  Paper,
  TextField,
  Checkbox,
  FormControlLabel,
  Switch,
  FormControl,
  MenuItem,
  Select,
  OutlinedInput,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  TextareaAutosize
  

} from "@material-ui/core";

//@ts-ignore
import { createTheme, ThemeProvider,createStyles,Theme ,withStyles} from "@material-ui/core/styles";
// import withStyles from '@material-ui/core/styles';
import  {ExpandMore} from "@material-ui/icons";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import Visibility from "@material-ui/icons/Visibility";
import { useFormik, Formik, ErrorMessage } from 'formik';
import * as yup from 'yup';
import CourseCreationController, {
    Props,
    configJSON,
  } from "./CourseCreationController.web";
import './CourseCreation.css';
import { BackgroundColor, CirclePlus, Library, Trash, upload, Vector } from "./assets";
class AddPreview extends CourseCreationController{
    handleFileChange(e:any){
        this.setState({//@ts-ignore
            imgVidFile:e.target.files[0],imgVidUrl:window.URL.createObjectURL(e.target.files[0]),filename:e.target.files[0].name})
    }
render(){
    return <>
    <div className="add-reward">
    <Formik
                  initialValues={{
                    email: "",
                    showPassword: false,
                    password: "",
                  }}
                  onSubmit={ (values) => {
                     this.addPreview()
                  }} /*  */
                  
                >
                  {(props) => {
                    const {
                      values,
                      touched,
                      errors,
                      dirty,
                      isSubmitting,
                      setFieldValue,
                      handleChange,
                      handleBlur,
                      handleSubmit,
                      handleReset,
                    } = props;
                    return (
                        <form style={{width:"100%"}} onSubmit={handleSubmit}>
                        <Grid container md={12} xs={12} spacing={4}>
                            <Grid item  md={6} xs={12}>
                                <p className="inner-heading">Choose Video</p>
                            </Grid>
                            <Grid item  md={6} xs={12}>
                                <p className="inner-heading">Upload Photo/Video</p>
                            </Grid>
                        </Grid>
                        <Grid container md={12} xs={12} spacing={4}>
                           
                            <Grid item container md={6} xs={12} >
                                <div className="dashed-border-achievement">
                                    <Grid item  md={2}></Grid>
                                    <Grid item  md={8} xs={12}>
                                        <div className="inner-border2">
                                        <img src={Library} />
                                        <p className="text">
                                        Choose video from content Library
                                        </p>
                                        </div>

                                    </Grid>
                                    <Grid item  md={2}></Grid>
                                </div>
                            </Grid>
                            <Grid item container md={6} xs={12} >
                                <div className="dashed-border-achievement">
                                    {this.state.imgVidUrl !== "" ? <>
                                    <Grid item md={6}>
                                    <img  src={this.state.imgVidUrl} 
                                    className="img-preview"/>
                                    </Grid>
                                    <Grid item md={6} style={{height:'100%'}}>
                                      <div className="add_preview_img">
                                      <span className="file-name-container"><Button className="filename-btn" title={this.state.filename}><img src={Vector}/> <span>{this.state.filename?.length > 10 ? `${this.state.filename.substring(0,10)}...`:this.state.filename}</span></Button>
                                      <span><Button className="trash-btn" onClick={() => this.setState({imgVidUrl:""})}><img src={Trash}/></Button></span>
                                      </span>
                                      <div className="">
                                        <Button className="change-thumbnail-btn1">Change Thumbnail</Button>
                                      </div>
                                      </div>
                                    </Grid>
                                    </>:<>
                                    <Grid item  md={3}></Grid>

                                    <Grid item  md={6} xs={12}>
                                    <div className="inner-border2" >
                                    <label htmlFor="uploadFile" style={{ display: "block" }}>
                            <input type="file" name="files" id="uploadFile" style={{ visibility: "hidden", display:'none' }} accept="image/*,video/*" onChange={(e) => this.handleFileChange(e)} />
                            <img src={CirclePlus} />

                          </label>
                                    {/* <input type="file" id="files" accept="image/*,video/*" onChange={(e) => this.handleFileChange(e)} />
                                    <img src={CirclePlus} /> */}
                                    <p className="text">
                                    Upload from device
                                    </p>

                                    </div>
                                    </Grid>
                                    <Grid item  md={3}></Grid>
                                    </>}
                                    
                                </div>
                            </Grid>
                        </Grid>
                        <Grid container md={12} xs={12}>
                            <Grid item md={12} xs={12} style={{padding:"30px",textAlign:"center"}}>
                                <Button className="create-reward-btn" type="submit">Create Add Preview/Ad</Button>
                            </Grid>
                        </Grid>
                        </form>
                    );
                  }}
                </Formik>
        
        
    </div>
    
    </>
}
}
export  default AddPreview;
// Customizable Area End