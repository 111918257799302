Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.apiContentType = "application/json";
exports.getApiMethod = "GET";
exports.postApiMethod = "POST";
exports.userListEndPoint = "account_block/account/user_listing";
exports.userSuggestionsEndPoint = "bx_block_teambuilder/usermanage/user_search";
exports.listOfTeamsAndBranchesEndPoint = "bx_block_teambuilder/usermanage/branch_list";
exports.createUserEndPoint = "account_block/account/create_user";
// Customizable Area End