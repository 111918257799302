import React, { useEffect } from "react";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import {
  Pagination,
} from "../CustomTableV3";
import TableRow from "@material-ui/core/TableRow";
import TableSortLabel from "@material-ui/core/TableSortLabel";
import { Paper, Typography, Checkbox, Box } from "@material-ui/core";
import Chip from "@material-ui/core/Chip";
import "./index.css";
import { summary } from "./asset";
import { toast } from "react-toastify";

interface Data {
  expand_type: string;
  id: number;
  course_id: string;
  total_chapter: number;
  user_enrol: number;
  name: string;
  xpert_name: string;
  content_role: string;
  last_day_modified: number;
  summary: string;
  status: string;
  url: string;
}

function createData(
  expand_type: string,
  id: number,
  name: string,
  course_id: string,
  xpert_name: string,
  content_role: string,
  total_chapter: number,
  user_enrol: number,
  last_day_modified: number,
  summary: string,
  status: string,
  url: string
): Data {
  return {
    expand_type,
    id,
    name,
    course_id,
    xpert_name,
    content_role,
    total_chapter,
    user_enrol,
    last_day_modified,
    summary,
    status,
    url,
  };
}
//@ts-ignore
let rows = [];

function descendingComparator<T>(a: T, b: T, orderBy: keyof T) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

type Order = "asc" | "desc";

function getComparator<Key extends keyof any>(
  order: Order,
  orderBy: Key
): (
  a: { [key in Key]: number | string },
  b: { [key in Key]: number | string }
) => number {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort<T>(array: T[], comparator: (a: T, b: T) => number) {
  const stabilizedThis = array.map((el, index) => [el, index] as [T, number]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

interface HeadCell {
  disablePadding: boolean;
  id: keyof Data;
  label: string;
  numeric: boolean;
}

const headCells: HeadCell[] = [
  { id: "name", numeric: false, disablePadding: false, label: "Name" },
  { id: "course_id", numeric: true, disablePadding: false, label: "Course Id" },
  {
    id: "xpert_name",
    numeric: true,
    disablePadding: false,
    label: "Xpert Name",
  },
  {
    id: "content_role",
    numeric: true,
    disablePadding: false,
    label: "Content Role(s)",
  },
  {
    id: "total_chapter",
    numeric: true,
    disablePadding: false,
    label: "Total Chapters",
  },
  {
    id: "user_enrol",
    numeric: true,
    disablePadding: false,
    label: "User Enrolled",
  },
  {
    id: "last_day_modified",
    numeric: true,
    disablePadding: false,
    label: "Last Day Modified"
  },
  { id: "summary", numeric: true, disablePadding: false, label: "Summary" },
  { id: "status", numeric: true, disablePadding: false, label: "Status" },
];

interface EnhancedTableProps {
  classes: ReturnType<typeof useStyles>;
  numSelected: number;
  onRequestSort: (
    event: React.MouseEvent<unknown>,
    property: keyof Data
  ) => void;
  onSelectAllClick: (event: React.ChangeEvent<HTMLInputElement>) => void;
  order: Order;
  orderBy: string;
  rowCount: number;
}

function EnhancedTableHead(props: EnhancedTableProps) {
  const { classes, order, orderBy, onRequestSort, numSelected, rowCount, onSelectAllClick } = props;
  const createSortHandler = (property: keyof Data) => (
    event: React.MouseEvent<unknown>
  ) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        {/* <TableCell padding="checkbox">
          <Checkbox
            indeterminate={numSelected > 0 && numSelected < rowCount}
            checked={rowCount > 0 && numSelected === rowCount}
            onChange={onSelectAllClick}
            inputProps={{ 'aria-label': 'select all desserts' }}
          />
        </TableCell> */}
        <TableCell padding="checkbox" />
        <TableCell padding="checkbox" />
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={headCell.numeric ? "right" : "left"}
            padding={headCell.disablePadding ? "none" : "normal"}
            sortDirection={orderBy === headCell.id ? order : false}
            style={{ verticalAlign: "top" }}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : "asc"}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <span className={classes.visuallyHidden}>
                  {order === "desc" ? "sorted descending" : "sorted ascending"}
                </span>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: "100%",
    },
    paper: {
      width: "100%",
      marginBottom: theme.spacing(2),
    },
    table: {
      minWidth: 750,
    },
    visuallyHidden: {
      border: 0,
      clip: "rect(0 0 0 0)",
      height: 1,
      margin: -1,
      overflow: "hidden",
      padding: 0,
      position: "absolute",
      top: 20,
      width: 1,
    },
  })
);
export default function EnhancedTable(props: any) {
  const classes = useStyles();
  const [order, setOrder] = React.useState<Order>("asc");
  const [orderBy, setOrderBy] = React.useState<keyof Data>("course_id");
  const [selected, setSelected] = React.useState<string[]>([]);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  rows = [];
  rows = props.data.map((item: any) =>
    createData(
      item.attributes.expand_type,
      item.attributes.id,
      item.attributes.title,
      item.attributes.number,
      item.attributes.expert_name,
      item.attributes.content_role,
      item.attributes.total_chapters,
      item.attributes.user_enrolled,
      item.attributes.updated_at, "",
      item.attributes.status,
      item.attributes.thumbnail_image == null
        ? ""
        : item.attributes.thumbnail_image.url,
    )
  );
  const handleRequestSort = (
    event: React.MouseEvent<unknown>,
    property: keyof Data
  ) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleSelectAllClick = (event: React.ChangeEvent<HTMLInputElement>) => {
    console.log(event.target.checked, 'hhhhhhhhhhhhhhhhhh');

    if (event.target.checked) {
      //@ts-ignore
      const newSelecteds = rows.map((n) => n.name);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleClick = (
    event: React.MouseEvent<unknown>,
    expand_type: string,
    id: number
  ) => {
    props.navigateTo(id, expand_type);
  };

  const handle1Click = (event: any, name: any) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected: any = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1),
      );
    }

    setSelected(newSelected);
  };

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const isSelected = (name: string) => selected.indexOf(name) !== -1;

  useEffect(() => {
    props.getCourse({
      search: props.search,
      filter: props.contentType,
      page: page,
      per: rowsPerPage,
    });
  }, [page, rowsPerPage]);

  return (
    <div
      className={classes.root}
      style={{ marginTop: "2%" }}
      id="course-program-table"
    >
      <Paper className={classes.paper}>
        <TableContainer>
          <Table
            className={classes.table}
            aria-labelledby="tableTitle"
            size={"medium"}
            aria-label="enhanced table"
          >
            <EnhancedTableHead
              classes={classes}
              numSelected={selected.length}
              order={order}
              orderBy={orderBy}
              onSelectAllClick={handleSelectAllClick}
              onRequestSort={handleRequestSort}
              rowCount={rows.length}
            />
            <TableBody>
              {rows.length === 0 ? (
                <TableRow>
                  <TableCell colSpan={9} align="center">
                    Course is not available.
                  </TableCell>
                </TableRow>
              ) : stableSort(rows, getComparator(order, orderBy)).map(
                (row, index) => {
                  //@ts-ignore
                  const isItemSelected = isSelected(row.name);
                  const labelId = `enhanced-table-checkbox-${index}`;

                  return (
                    <TableRow
                      hover
                      onClick={(event) => handle1Click(event, row.name)}
                      role="checkbox"
                      aria-checked={isItemSelected}
                      tabIndex={-1}
                      key={row.id}
                      selected={isItemSelected}
                    >
                      <TableCell padding="checkbox">
                        <Checkbox
                          checked={isItemSelected}
                          inputProps={{ 'aria-labelledby': labelId }}
                        />
                      </TableCell>
                      <TableCell padding="checkbox">
                        <img
                          //@ts-ignore
                          src={row.url}
                          style={{
                            height: "40px",
                            width: "40px",
                            border: "1px solid #979797",
                            filter:
                              "drop-shadow(0px 2px 14px rgba(140, 140, 140, 0.715335))",
                          }}
                        />
                      </TableCell>
                      <TableCell
                        component="th"
                        id={labelId}
                        scope="row"
                        padding="none"
                        onClick={(event) => {
                          //@ts-ignore
                          handleClick(event, row.expand_type, row.id);
                        }}
                      >
                        {row.name}
                      </TableCell>
                      <TableCell align="right">{row.course_id}</TableCell>
                      <TableCell align="right">{row.xpert_name}</TableCell>
                      <TableCell align="right">{row.content_role}</TableCell>
                      <TableCell align="right">{row.total_chapter}</TableCell>
                      <TableCell align="right">{row.user_enrol}</TableCell>
                      <TableCell align="right">{row.last_day_modified}</TableCell>
                      <TableCell align="right">
                        <img
                          //@ts-ignore
                          src={summary}
                        />
                      </TableCell>
                      <TableCell align="right">
                        <Chip
                          variant="outlined"
                          size="small"
                          label={row.status}
                          onClick={(event) => {
                            //@ts-ignore
                            handleClick(event, row.expand_type, row.id);
                          }}
                        />
                      </TableCell>
                    </TableRow>
                  );
                }
              )}
            </TableBody>
          </Table>
        </TableContainer>
        <Box style={{display:'flex'}}>
          <Typography className="Table_textAll">Select All</Typography>
          <div className="last_pagination">
            <Pagination disabledPrevBtn={false} disabledNextBtn={false} onNextClick={function (): void {
              throw new Error("Function not implemented.");
            }} onPrevClick={function (): void {
              throw new Error("Function not implemented.");
            }} />
          </div>
        </Box>
      </Paper>
    </div>
  );
}
