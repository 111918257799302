import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import { toast } from 'react-toastify';

// Customizable Area Start
import { slider1, slider2, slider3 } from "./assets";
import React from "react";
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  classes?: any;
  otherProps?: any
  location?: any
  // Customizable Area Start
  history?: any;
  handleCartItemsCountInTopbar?: (count: number) => void;
  // Customizable Area End
}

interface S {
  txtInputValue: string;
  txtSavedValue: string;
  enableField: boolean;
  // Customizable Area Start
  anchorEl: any;
  libraryData: any;
  grapValues: any;
  toggleSideBar: any;
  allLibraryData: any;
  libraryCheckedData: any;
  filter: boolean;
  values: any;
  selected: any;
  chartData: any;
  weekValue: any;
  show: boolean;
  show1: boolean,
  weekSelected: any;
  checked: any;
  libraryCheckedData2: any,
  libraryCheckedData3: any,
  isToggledSearch: boolean,
  barWeek: boolean,
  paramTitle: any,
  style: boolean,
  checkedValue: boolean,
  loaderOpen: boolean,
  sort: boolean,
  collections: any,
  percentage: number,
  searchData: any,
  cartItemsCount: number;
  autoComplete: any,
  checkedValueProgram: boolean,
  Search_DetailData: any;
  checkboxChecked: any,
  checkboxChecked1: any,
  selectedPrograms: any,
  selectedIndices: any,
  search: string,
  onClick: boolean,
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class Library2Controller extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  GetLibraryData: any;
  PostLibraryData: any;
  GetLibraryChartData: any;
  GetAllcategoriesData: any;
  Last_VideoTrackData: any;
  myRef: any;
  popupRef: any;
  param_id: any;
  AddCartItemID: any;
  libraryCheckedData2: any;
  libraryCheckedDataSort: any;
  GetLibrarySortData: any;
  GetTopBarSearch: any;
  GetTopBarSearch1: any;
  GetAllSearchData: any;
  GetSearch_DetailData: any;
  GetLibrarySearchSortData: any;
  getCartItemsApiCallId: string;
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
    this.myRef = React.createRef();
    this.popupRef = React.createRef();
    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),

      // Customizable Area End
    ];

    this.state = {
      txtInputValue: "",
      txtSavedValue: "A",
      enableField: false,
      // Customizable Area Start
      anchorEl: '',
      grapValues: 'Week',
      values: ["week", "month", "year"],
      selected: "week",
      weekValue: ["This Week", "Last Week"],
      weekSelected: "This Week",
      barWeek: true,
      checked: [],
      show: true,
      isToggledSearch: false,
      show1: true,
      filter: false,
      sort: false,
      toggleSideBar: false,
      chartData: {},
      paramTitle: "",
      style: true,
      loaderOpen: false,
      collections: {},
      percentage: 50,
      searchData: '',
      autoComplete: {},
      checkedValue: true,
      checkboxChecked: {},
      checkboxChecked1: {},
      search: "",
      onClick: true,
      checkedValueProgram: false,
      libraryData: [
        {
          id: 1,
          image_url: slider1
        },
        {
          id: 2,
          image_url: slider2
        },
        {
          id: 3,
          image_url: slider3
        },
        {
          id: 4,
          image_url: slider2
        },
        {
          id: 5,
          image_url: slider1
        },
        {
          id: 6,
          image_url: slider2
        },
        {
          id: 7,
          image_url: slider3
        },
        {
          id: 8,
          image_url: slider1
        },
        {
          id: 9,
          image_url: slider2
        }
      ],
      libraryCheckedData: {},
      libraryCheckedData2: [
        {
          id: 1,
          text: 'Sort by Due Date',
          name: 'due_date',
          checked1: true,
        },
        {
          id: 2,
          text: 'Course/Program Name',
          name: 'course',
          checked3: true,
        },
        {
          id: 3,
          text: 'Xpert Name',
          name: 'xpert',
          checked4: true,
        },
        {
          id: 4,
          text: 'Add Date',
          name: 'add_date',
          checked5: true,
        },

      ],
      libraryCheckedData3: [
        {
          id: 0,
          text: 'Free',
          name: '0',
          checked1: true,
        },
        {
          id: 1,
          text: 'Subscription',
          name: '1',
          checked3: true,
        },
        {
          id: 2,
          text: 'One Time Payments',
          name: '2',
          checked4: true,
        },

      ],
      allLibraryData: {},
      Search_DetailData: {},
      selectedPrograms: [],
      selectedIndices: '',
      cartItemsCount: 0
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    this.param_id = this.props.navigation.getParam("id");
    // Customizable Area Start
    // Customizable Area End
  }



  // @ts-ignore
  componentDidMount() {

    const open = Boolean(this.state.anchorEl);
    const id = open ? 'simple-popover' : undefined;
    this.Allcategories_list();
    this.getLibraryData();
    window.scrollTo(0, 0)
    this.param_id = this.props.navigation.getParam("id");
    this.getCartItems();
  }

  async receive(from: string, message: Message) {
    runEngine.debugLog("Message Received", message);

    if (message.id === getName(MessageEnum.AccoutLoginSuccess)) {
      const value = message.getData(getName(MessageEnum.AuthTokenDataMessage));
      const alertMessage = `Change Value: From ${this.state.txtSavedValue} To ${value}`;
      this.showAlert("Change Value", alertMessage);
      this.setState({ txtSavedValue: value });
    }

    if (message.id === getName(MessageEnum.RestAPIResponceMessage)) {
      const apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));
      const responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));

      if (apiRequestCallId && responseJson) {
        switch (apiRequestCallId) {
          case this.GetLibraryData:
            this.setState({ allLibraryData: responseJson, collections: responseJson.collections });
            break;
          case this.GetAllSearchData:
            this.setState({ collections: responseJson.collection, loaderOpen: false });
            break;
          case this.GetSearch_DetailData:
            this.setState({ Search_DetailData: responseJson, isToggledSearch: !this.state.isToggledSearch });
            break;
          case this.GetLibrarySortData:
            this.setState({ allLibraryData: responseJson, loaderOpen: false });
            break;
          case this.GetLibrarySearchSortData:
            this.setState({ collections: responseJson.collections, loaderOpen: false });
            break;
          case this.PostLibraryData:
            if (responseJson) {
              this.getLibraryData();
            }
            toast.success('Added in Personal Library');
            break;
          case this.GetLibraryChartData:
            this.setState({ chartData: responseJson });
            break;
          case this.Last_VideoTrackData:
            if (responseJson) {
              this.props.history.push(`/title/${responseJson.data?.attributes?.course_video_id}${this.state.paramTitle.replace(/\s/g, '-')}`, { program: responseJson.data?.attributes?.program_id });
            }
            break;
          case this.AddCartItemID:
            this.handleAddCartItemResponse(responseJson);
            break;
          case this.GetAllcategoriesData:
            this.setState({ libraryCheckedData: responseJson });
            break;
          case this.GetTopBarSearch:
            this.setState({ collections: responseJson.collection, autoComplete: responseJson.collection });
            if (this.state.searchData === '') {
              this.setState({ onClick: true })
            }
            break;
          case this.GetTopBarSearch1:
            this.setState({ Search_DetailData: responseJson, autoComplete: responseJson });
            if (this.state.searchData === '') {
              this.setState({ onClick: true })
            }
            break;
          case this.getCartItemsApiCallId:
            this.handleGetCartItemsApiResponse(responseJson);
            break;
          default:
            break;
        }
      }
    }
  }



  txtInputWebProps = {
    onChangeText: (text: string) => {
      this.setState({ txtInputValue: text });
    },
    secureTextEntry: false,
  };

  txtInputMobileProps = {
    ...this.txtInputWebProps,
    autoCompleteType: "email",
    keyboardType: "email-address",
  };

  txtInputProps = this.isPlatformWeb()
    ? this.txtInputWebProps
    : this.txtInputMobileProps;

  btnShowHideProps = {
    onPress: () => {
      this.setState({ enableField: !this.state.enableField });
      this.txtInputProps.secureTextEntry = !this.state.enableField;
      this.btnShowHideImageProps.source = this.txtInputProps.secureTextEntry
    },
  };

  btnShowHideImageProps = {
    source: this.txtInputProps.secureTextEntry
  };

  btnExampleProps = {
    onPress: () => this.doButtonPressed(),
  };

  doButtonPressed() {
    let msg = new Message(getName(MessageEnum.AccoutLoginSuccess));
    msg.addData(
      getName(MessageEnum.AuthTokenDataMessage),
      this.state.txtInputValue
    );
    this.send(msg);
  }

  tostSuccessfully = () => {
    toast.success("Coming soon");
  }

  HandleChangeSeacrh = (data: any) => {
    this.setState({ onClick: false })
    this.setState({ searchData: data?.attributes?.title }, () => {
      const header = {
        "Content-Type": "application/json",
        token: localStorage.getItem("token")
      };
      const requestMessage = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
      );
      this.GetTopBarSearch = requestMessage.messageId;
      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        `bx_block_library2/libraries/search?search=${this.state.searchData}`
      );
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        header
      );
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        'get'
      );
      runEngine.sendMessage(requestMessage.id, requestMessage);
    })
  }

  handleisToggledClick = () => {
    const searchedName = this.state.searchData;
    const header = {
      "Content-Type": "application/json",
      token: localStorage.getItem("token")
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.GetSearch_DetailData = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_library2/libraries/search_detail?search=${searchedName}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      header
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      'get'
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  handleisToggled1Click = (event: any) => {
    const searchedName = event.target.value;
    this.setState({ searchData: searchedName }, () =>
      this.state.searchData)
    const header = {
      "Content-Type": "application/json",
      token: localStorage.getItem("token")
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.GetTopBarSearch1 = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_library2/libraries/search_detail?search=${searchedName}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      header
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      'get'
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  searchFilter = () => {
    this.setState({ filter: true })
  }
  closeSearchFilter = () => {
    this.setState({ filter: false })
  }
  searchShort = () => {
    this.setState({ sort: true })
  }
  closeSearchSort = () => {
    this.setState({ sort: false })
  }

  _onFocus = () => {
    this.props.history.push('/Search')
  }

  toggleDisplay = () => {
    this.setState({ show1: !this.state.show1 });
    this.getLibraryData();
  }

  BarWeektoggle = () => {
    this.setState({ barWeek: !this.state.barWeek });
  }

  percentage_handler = (event: any, value: any) => {
    this.setState({ percentage: value });
  };


  checkedhandleChange = (id: any, is_selected: any, event: any) => {
    const { checkboxChecked } = this.state;
    checkboxChecked[id] = event.target.checked;
    let data = this.state.checked;
    if (event.target.checked) {
      data.push(id);
    } else {
      const index = data.indexOf(id);
      if (index !== -1) {
        data.splice(index, 1);
      }
    }
    this.setState({ checkboxChecked, checked: data });
    const params = data.map((id: any) => `filter_ids[]=${id}`).join('&');
    this.libraryCheckedDataSort = params;
  }

  checkedhandleChange2 = (event: any) => {
    this.setState({ selectedIndices: event.target.value });
  };


  checkedhandleChange3 = (id: any, event: any) => {
    event.persist();
    this.setState((prevState) => {
      const { checkboxChecked1, checked } = prevState;
      checkboxChecked1[id] = event.target.checked;
      let updatedChecked;
      if (event.target.checked) {
        updatedChecked = [...checked, id];
      } else {
        updatedChecked = checked.filter((item: any) => item !== id);
      }
      const params = updatedChecked.map((id: any) => `payment_methods[]=${id}`).join('&');
      this.libraryCheckedDataSort = params;
      return { checkboxChecked1, checked: updatedChecked };
    });
  };



  // web events
  setInputValue = (text: string) => {
    this.setState({ txtInputValue: text });
  };

  setEnableField = () => {
    this.setState({ enableField: !this.state.enableField });
  };

  // Customizable Area Start

  handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    this.setState({ anchorEl: event.currentTarget });
  };

  handleClose = () => {
    this.setState({ anchorEl: null });
  };

  handleCheckboxChange = (event: any) => {
    const isChecked = event.target.checked;
    this.setState({ checkedValue: isChecked });
    const params = isChecked ? 'courses_only=true' : ''; // Set 'courses_only=true' if checked, otherwise an empty string
    this.libraryCheckedDataSort = params;
    console.log(this.libraryCheckedDataSort);
  };

  handleCheckboxChangeProgram = (event: any) => {
    const checkedValueProgram = event.target.checked;
    this.setState({ checkedValueProgram });

    if (checkedValueProgram) {
      this.libraryCheckedDataSort = 'programs_only=true';
    } else {
      this.libraryCheckedDataSort = '';
    }
  };


  GetAllSearch_data = () => {
    const header = {
      "Content-Type": "application/json",
      token: localStorage.getItem("token")
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.GetAllSearchData = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_library2/libraries/search`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      header
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      'get'
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }


  topBarSearch = (event: any) => {
    const searchedName = event.target.value;
    this.setState({ searchData: searchedName }, () =>
      this.state.searchData)
    const header = {
      "Content-Type": "application/json",
      token: localStorage.getItem("token")
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.GetTopBarSearch = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_library2/libraries/search?search=${searchedName}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      header
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      'get'
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  applyShorting = () => {
    this.setState({ loaderOpen: true })
    const header = {
      "Content-Type": "application/json",
      token: localStorage.getItem("token")
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.GetLibrarySortData = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_library2/libraries?sort=${this.state.selectedIndices}&filter_price=${this.state.percentage}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      header
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      'get'
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  applySearchShorting = () => {
    this.setState({ loaderOpen: true })
    const header = {
      "Content-Type": "application/json",
      token: localStorage.getItem("token")
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.GetAllSearchData = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_library2/libraries/search?sort=${this.state.selectedIndices}&filter_price=${this.state.percentage}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      header
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      'get'
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  CheckedDataSort = () => {
    this.setState({ loaderOpen: true })
    const header = {
      "Content-Type": "application/json",
      token: localStorage.getItem("token")
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.GetLibrarySortData = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_library2/libraries?${this.libraryCheckedDataSort}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      header
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      'get'
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  CheckedSearchDataSort = () => {
    this.setState({ loaderOpen: true })
    const header = {
      "Content-Type": "application/json",
      token: localStorage.getItem("token")
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.GetAllSearchData = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_library2/libraries/search?${this.libraryCheckedDataSort}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      header
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      'get'
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }


  last_trackVideo = (data: any) => {

    this.setState({ paramTitle: data?.attributes?.collection?.attributes?.title })
    let date = new Date();
    let current_date = date.getFullYear() + "-" + (date.getMonth() + 1) + "-" + date.getDate();
    let course_video_id: any = data?.attributes?.collection?.attributes?.course_video_track?.last_video_id;
    let track_time: any = data?.attributes?.collection?.attributes?.course_video_track?.seen_time;
    let is_completed: any = localStorage.getItem("Last_track-data");
    let is_video_completed = JSON.parse(is_completed)


    let formdata = new FormData();
    formdata.append("course_video_id", course_video_id);
    formdata.append("track_time", track_time);
    formdata.append("is_completed", is_video_completed.is_video_completed);
    formdata.append("track_date", current_date);
    formdata.append("is_update", "true");
    if (data.attributes.collection.attributes.course_video_track.program_id) {
      formdata.append("program_id", data.attributes.collection.attributes.course_video_track.program_id)
    }


    const header = {
      ContentType: "multipart/form-data",
      token: localStorage.getItem("token")
    };
    const httpBody = formdata;

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.Last_VideoTrackData = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `/bx_block_adhocreporting/course_video_track`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      header
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      'Post'
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      httpBody
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }



  getLibraryData = async () => {
    const header = {
      "Content-Type": "application/json",
      token: localStorage.getItem("token")
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.GetLibraryData = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_library2/libraries?platform=web`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      header
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      'get'
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  handleNavigate = (type: string, id: any, pathname: string, state: any) => {
    this.props.history.push({
      pathname,
      state
    })
  }

  postplayButton = async (data: any) => {
    this.props.history.push(`/course-detail/${data.id}/${data?.attributes?.title.replace(/\s/g, '-')}`, { name: data.id })
  }

  handleChange = (event: any) => {
    this.setState({ selected: event.target.value });
  }

  weekhandleChange = (event: any) => {
    this.setState({ weekSelected: event.target.value });
  }


  handleClickWeek = (value: any) => {
    if (value === "This Week") {
      this.setState({ show: true });
    } else if (value === "Last Week") {
      this.setState({ show: false });
    }
  }

  Allcategories_list = () => {
    const header = {
      "Content-Type": "application/json",
      token: localStorage.getItem("token")
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.GetAllcategoriesData = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_categories/categories`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      header
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      'get'
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  handleClickChart = (value: any) => {
    const header = {
      "Content-Type": "application/json",
      token: localStorage.getItem("token")
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.GetLibraryChartData = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_adhocreporting/course_video_track/video_web_track?filter=${value || this.state.selected}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      header
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      'get'
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);

  }

  handleSubscription = (data: any) => {
    this.props.history.push('/Subscription', { name: data })
  }

  addCartItem = async (id: any, type: string) => {
    const header = {
      "Content-Type": "application/json",
      token: localStorage.getItem("token")
    };
    const httpBody =
      type == 'Program' ?
        {
          "program_id": id,
        } : {
          "course_id": id
        }
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.AddCartItemID = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.addCartItemApiEndPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      header
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      'Post'
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  handleAddCartItemResponse = (response: any) => {
    if (response?.errors) {
      toast.error("Something went wrong");
      return;
    }

    if (response.message) {
      toast.warning('Item already in cart')
    } else {
      toast.success('Item has been added to your cart.')
      this.setState({
        cartItemsCount: this.state.cartItemsCount + 1
      }, () => {
        this.props?.handleCartItemsCountInTopbar &&
          this.props.handleCartItemsCountInTopbar(this.state.cartItemsCount);
      });
    }
  }

  postXpandLibraryData = async (id: any, type: string) => {
    const header = {
      "Content-Type": "application/json",
      token: localStorage.getItem("token")
    };
    const httpBody = {
      "courseable_id": id,
      "courseable_type": `BxBlockCoursecreation::${type}` // course: "BxBlockCoursecreation::Course, program: "BxBlockCoursecreation::Program"
    }
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.PostLibraryData = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_library2/enroll_courses`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      header
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      'Post'
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  // Get current cart items
  getCartItems = async () => {
    const header = {
      "Content-Type": "application/json",
      token: localStorage.getItem("token")
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getCartItemsApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.addCartItemApiEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      header
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getApiMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  // Handle get cart items api response
  handleGetCartItemsApiResponse = (response: any) => {
    if (response?.error) {
      console.error("ERROR FETCHING CART ITEMS");
    } else if (response?.message) {
      this.setState({
        cartItemsCount: 0,
      }, () => {
        this.props?.handleCartItemsCountInTopbar &&
          this.props.handleCartItemsCountInTopbar(this.state.cartItemsCount);
      });
    } else if (response?.data) {
      const oneTimePaymentCartItems = response.data.attributes.cart_item;
      const subscriptionCartItems = response.data.attributes.subscription_cart_item;
      const totalCartItemsCount = oneTimePaymentCartItems.length + subscriptionCartItems.length;
      this.setState({
        cartItemsCount: totalCartItemsCount,
      }, () => {
        this.props?.handleCartItemsCountInTopbar &&
          this.props.handleCartItemsCountInTopbar(this.state.cartItemsCount);
      });
    }
  };

  // Customizable Area End
}