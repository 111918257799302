import React from "react";
// Customizable Area Start
import {
  Box,
  Grid,
  Typography
} from "@material-ui/core";
import CardMedia from '@material-ui/core/CardMedia';
import {
  Theme,
  createStyles
} from "@material-ui/core/styles";
import "./SplashScreen.css";
import SplashscreenController, { Props } from "./SplashscreenController.web";

const xpandLogo = require("../assets/XpandLogo.png");
const styles: any = (theme: Theme) =>
  createStyles({
    root: {
      width: "100%",
      backgroundColor: theme.palette.background.paper,
      position: "relative",
      maxHeight: "100vh",
    },
    listSection: {
      backgroundColor: "inherit",
    },
    ul: {
      backgroundColor: "inherit",
      padding: 0,
    },
  });

class WelcomeSplashscreen extends SplashscreenController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }
  render() {
    
    return (
      <Grid container style={{ height: "100vh", width: "100vw", background: "linear-gradient(360deg, #6C328B 0%, #F42074 72.63%, #EF2B4B 100%)" }}>
        <Grid item lg={6} md={6}></Grid>
        <Grid item lg={6} md={6} sm={12} xs={12}>
          <Box className="login-main">
            <Box className="login-inner">
              <CardMedia
                style={{ height: '118px', padding: '67px', width: '100%' }}
                image={xpandLogo}
                title="xpandLogo"
              />
            </Box>
            <Typography className="logo-text">Welcome to Xpand Your Life</Typography>
          </Box>
        </Grid>
      </Grid>
    );
  }
}
//@ts-ignore
export default WelcomeSplashscreen;
// Customizable Area End
