// Customizable Area Start
import React from 'react';
import {
    Grid,
    Button,
    FormControl,
    Select,
    MenuItem,
    InputAdornment,
    Card,
    Avatar,
    IconButton
} from '@material-ui/core';
import { Close } from "@material-ui/icons";
import CourseCreationController from './CourseCreationController.web';
import './CourseCreation.css';
import '../../videos/src/video.css';
import { Caret1, Folder } from './assets';
import { uploadIcon } from '../../videos/src/assets';
class AddIcon extends CourseCreationController {
    constructor(props: any) {
        super(props);
    }
    render() {
        return (<>
            <div className="">
                <Grid container>
                    <Grid item className='icon-library' md={6} xs={12}>
                        <FormControl className="branch-select">
                            <label htmlFor='branch' className="label-select">Select Branch</label>
                            <Select
                                labelId="branch"
                                id="branch"
                                name='branch_name'
                                value={""}
                                variant="outlined"
                                IconComponent={() => null}
                                endAdornment={
                                    <InputAdornment position="end">
                                        <img src={Caret1} />
                                    </InputAdornment>

                                }
                                fullWidth>
                                <MenuItem value="">
                                    <em>Default Branch</em>
                                </MenuItem>
                                <MenuItem value={1}>Phase 1</MenuItem>
                                <MenuItem value={2}>Phase 2</MenuItem>
                                <MenuItem value={3}>Phase 3</MenuItem>
                                <MenuItem value={4}>Phase 4</MenuItem>
                                <MenuItem value={5}>Phase 5</MenuItem>
                            </Select>
                        </FormControl>
                    </Grid>
                </Grid>
                <Grid container>
                    <Grid item md={6} xs={12} className="video">
                        <Grid container className='file-container' md={12}>
                            {this.state.imgUrl === "" && this.state.image === "" ? <>
                                <Grid className="file-content" item md={1}>
                                    <Button variant="contained" className="upload-btn" component="label">
                                        <input hidden accept="image/*" type="file" />
                                        <Avatar src={uploadIcon} />
                                    </Button>

                                </Grid>
                                <Grid item md={12}>
                                    <p className="drag-text">Drag and Drop or <span className="drag-browse">Browse</span> to upload</p>
                                </Grid>
                                <Grid item md={12}>
                                    <p className="drag-subtext">Upload upto 15 MB and file dimension should be 768*1024</p>
                                </Grid>
                            </> : <>
                                <div style={{ display: "flex", flexWrap: "wrap", justifyContent: "center" }} className="custom-card-preview" >
                                    <div style={{ minWidth: "200px", maxWidth: "200px", margin: "1px", textAlign: "center" }} >
                                        <Card >
                                            <div
                                                className='card-header'
                                            >
                                                <IconButton aria-label="close" onClick={() => this.setState({ imgUrl: "", image: "" })}>
                                                    <Close />
                                                </IconButton>
                                            </div>
                                            <img className="select-img-preview" src={this.state.image === "" ? this.state.imgUrl : this.state.image} />
                                        </Card>
                                        <p className='file-name'>{this.state.filename}</p>
                                    </div>
                                </div>
                            </>}


                        </Grid>                
                        </Grid>
                </Grid>
                <Grid container>
                    <Grid item md={6} xs={12} className="add-folder" style={{textAlign:"end",marginTop:"10px"}}>
                    <Button className="add-folder-btn">Add icon</Button>
                    </Grid>
                </Grid>

            </div>
        </>);
    }
}
export default AddIcon;
// Customizable Area End