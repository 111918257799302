// Customizable Area Start
import React from 'react';
import {
  Grid,
  Button,
  FormControl,
  Select,
  MenuItem,
  InputAdornment,
  TextField,
} from '@material-ui/core';
import { Search } from "@material-ui/icons";
import DynamicTitle from "../../../components/src/DynamicTitle";
import CourseCreationController from './CourseCreationController.web';
import debouce from "lodash.debounce";
import './CourseCreation.css';
import '../../videos/src/video.css';
import { Caret1 } from './assets';
import RewardListTable from '../../../components/src/RewardList';
const heading = [
  { label: "", sort: false },
  { label: "", sort: false },
  { label: "Name", sort: true },
  { label: "Assigned", sort: false },
  { label: "Earned", sort: true },
  { label: "Branch", sort: false },
  { label: "Requirements", sort: true },
  { label: "Status", sort: true },
  { label: "", sort: false }
];
class RewardList extends CourseCreationController {
  constructor(props: any) {
    super(props);
    this.debouncedResults = this.debouncedResults.bind(this)
  }
  selected = (index: any) => {
    let items = [...this.state.reward_list];
    let item = { ...items[index] }
    item.is_selected = !item.is_selected
    items[index] = item
    this.setState({ reward_list: items })
  }

  debouncedResults: any = debouce(
    (text) => this.getRewardList({ search: text, filter: "", page: 1, per: 10 }),
    700
  );
  handleChange(e: any) {
    const { value } = e.target;
    if (e.target.name === "search") {
      this.debouncedResults(value);
    }
    else if (e.target.name === "sort") {
      this.getRewardList({ search: "", filter: value, page: 1, per: 10 })
    }
  }

  render() {
    return (<>
      <div className="existing_course reward-list">
      <DynamicTitle title="Rewards" />
        <Grid container style={{ paddingBottom: "30px" }}>
          <Grid item md={4} xs={10}>

            <TextField
              fullWidth
              id="search"
              variant="outlined"
              name="search"
              onChange={(e:any) => {this.handleChange(e)}}
              placeholder="Search"
              InputLabelProps={{
                shrink: true
              }}
              className="search"
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <Search />
                  </InputAdornment>
                ),
              }}
            />

          </Grid>
          <Grid item md={3}></Grid>
          <Grid item md={2} xs={12}>
            <FormControl className="bulk-action">
              <Select
                labelId="bulk action"
                id="bulk action"
                name='branch_name'
                variant="outlined"
                IconComponent={() => null}
                endAdornment={
                  <InputAdornment position="end">
                    <img src={Caret1} />
                  </InputAdornment>

                }
                fullWidth>
                <MenuItem value="">
                  <em>Bulk Action</em>
                </MenuItem>
                <MenuItem value={1}>Phase 1</MenuItem>
                <MenuItem value={2}>Phase 2</MenuItem>
                <MenuItem value={3}>Phase 3</MenuItem>
                <MenuItem value={4}>Phase 4</MenuItem>
                <MenuItem value={5}>Phase 5</MenuItem>
              </Select>
            </FormControl>
          </Grid>
          <Grid item md={2} style={{ textAlign: "end" }}>
            <Button className='create-reward-btn'>Create Reward</Button>
          </Grid>
        </Grid>
        <RewardListTable
          tableHeading={heading}
          //@ts-ignore
          columnData={this.state.reward_list}
          getRewardList={(obj:any) => this.getRewardList(obj)}
          meta={this.state.meta}
          tableRows={this.state.reward_list}
          selected={(index:any) => this.selected(index)}
        />

      </div>
    </>);
  }
}
export default RewardList;
// Customizable Area End