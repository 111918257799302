// Customizable Area Start
import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import { Alert } from "react-native";
import AsyncStorage from "@react-native-async-storage/async-storage";

// Customizable Area End
// Customizable Area Start
export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;


}

interface S {
  token: string;
  data: any;
  arrayHolder: any;
  librarySearchPage: any;
  searchTextInput: any;
  itemClickedData: any;
  playClicked: any;
  priceAmount: number;
  sortData: any;
  showCoursesFilter: boolean;
  sortParams: any;
  courseFilterData: any;
  filter_dues: any;
  isProgramOnly: boolean;
  isCoursesOnly: boolean;
  isNewlyAdded: boolean;
  categoryList: any;
  filterPaymentData: any;
  filterCheck: boolean;
  channelFilter: boolean;
  filterLibraryId: any;
  toggleCategorie: boolean;
  togglePayment: boolean;
  filterData: any;
  isLibraryFilterApplied: boolean;
  showSelectFilter: boolean;
  isLoading: boolean;
  maxPrice: number;
  filterPayload: string;
  sortPayload: string;
  suggestionList: any;
  selectedSuggestion: string;
}

interface SS {
  id: any;
}
// Customizable Area End
// Customizable Area Start
export default class FilteritemsController extends BlockComponent<
  Props,
  S,
  SS
> {
  getSearchLibraryApiCallId: any;
  addToListApiCallId: any;
  categoryListApiCallId: any;
  addToCartApiCallId: any;
  getAutoSuggestionListApiCallId: any;
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);


    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionSaveMessage),
      getName(MessageEnum.SessionResponseMessage)
    ];

    this.state = {
      token: "",
      selectedSuggestion: "",
      data: "",
      arrayHolder: [],
      librarySearchPage: [],
      searchTextInput: '',
      itemClickedData: [],
      playClicked: false,
      isLoading: false,
      priceAmount: 0,
      suggestionList: [],
      sortData: [
        { id: 1, name: 'Sort by Due Date', isChecked: false, param: 'due_date' },
        { id: 2, name: 'Add Date', isChecked: false, param: 'add_date' },
        { id: 3, name: 'Course/Program Name', isChecked: false, param: 'course' },
        { id: 4, name: 'Xpert Name', isChecked: false, param: 'xpert' },
      ],
      showCoursesFilter: false,
      sortParams: [],
      courseFilterData: [
        { id: 1, name: 'Due this week', isChecked: false, param: 'filter_dues[]=weekly_due' },
        { id: 2, name: 'Due this month', isChecked: false, param: 'filter_dues[]=monthly_due' },
        { id: 3, name: 'Total Dues', isChecked: false, param: 'filter_dues[]=all_due' },
      ],
      filter_dues: [],
      isProgramOnly: false,
      isCoursesOnly: false,
      isNewlyAdded: false,
      categoryList: [],
      filterPaymentData: [
        { id: 0, name: 'Free', isChecked: false, params: 'payment_methods[]=0' },
        { id: 1, name: 'Subscription', isChecked: false, params: 'payment_methods[]=1' },
        { id: 2, name: 'One Time Payments', isChecked: false, params: 'payment_methods[]=2' },
      ],
      filterCheck: false,
      channelFilter: false,
      filterLibraryId: [],
      toggleCategorie: false,
      togglePayment: false,
      filterData: [
        { id: 1, name: 'Newly Added Courses', isChecked: false },
        { id: 2, name: 'Language Courses', isChecked: false },
        { id: 3, name: 'Technology Courses', isChecked: false },
        { id: 4, name: 'Music Courses', isChecked: false },
        { id: 5, name: 'Language Courses', isChecked: false },
        { id: 6, name: 'Technology Courses', isChecked: false },
        { id: 7, name: 'Music Courses', isChecked: false },
      ],
      isLibraryFilterApplied: false,
      showSelectFilter: false,
      maxPrice: 0,
      filterPayload: '',
      sortPayload: ''
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }
  async componentDidMount() {
    super.componentDidMount();
    AsyncStorage.getItem('token')
      .then((token: any) => {
        this.setState({ token: token });
        this.getLibrarySearchPageInfo(token, null);
        this.getCategoryList()
      })
      .catch((error: any) => {

        AsyncStorage.clear();
        this.props.navigation.navigate('Authentication');
      });

  }

  goBack() {
    this.props.navigation.goBack(null)
  }

  onChangeTextLibrarySearchField(text: any) {
    this.setState({ searchTextInput: text }, () => {
      let payload
      payload = `?search=${this.state.searchTextInput}&${this.state.filterPayload}&${this.state.sortPayload}`
      this.getLibrarySearchPageInfo(this.state.token, payload);
    })
  }

  async receive(from: string, message: Message) {

    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );
      let errorReponse = message.getData(getName(MessageEnum.RestAPIResponceErrorMessage));
      let responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
      if (responseJson.status === 500) {
        this.showAlert('Error', 'Internal Server Error')
        return;
      }
      if (responseJson && !responseJson.errors) {
        this.responseSucessCall(apiRequestCallId, responseJson);
      } else if (responseJson && responseJson.errors) {
        this.responseErrorsCell(apiRequestCallId, responseJson)
      } else if (errorReponse) {
        this.showAlert('Error', 'Internal Server Error')
      }
    }
  }

  responseSucessCall = async (apiRequestCallId: any, responseJson: any) => {
    if (apiRequestCallId === this.getSearchLibraryApiCallId) {
      this.getSearchLibraryApiSuccessCallBack(responseJson);
    }
    if (apiRequestCallId === this.addToCartApiCallId) {
      this.getAddToCartApiSuccessCallBack(responseJson);
    }
    if (apiRequestCallId === this.categoryListApiCallId) {
      this.getCategoryListApiSuccessCallBack(responseJson);
    }
    if (apiRequestCallId === this.getAutoSuggestionListApiCallId) {
      this.getAutoSuggestionListApiSuccessCallBack(responseJson);
    }
    if (apiRequestCallId === this.addToListApiCallId) {
      this.getAddToListApiSuccessCallBack(responseJson);
    }

  }

  responseErrorsCell = async (apiRequestCallId: any, responseJson: any) => {
    if (apiRequestCallId === this.getSearchLibraryApiCallId) {
      this.getSearchLibraryApiFailureCallBack(responseJson);
    }
    if (apiRequestCallId === this.addToCartApiCallId) {
      this.getAddToCartApiFailureCallBack(responseJson);
    }
    if (apiRequestCallId === this.categoryListApiCallId) {
      this.getCategoryListApiFailureCallBack(responseJson);
    }
    if (apiRequestCallId === this.getAutoSuggestionListApiCallId) {
      this.getAutoSuggestionListApiFailureCallBack(responseJson);
    }
    if (apiRequestCallId === this.addToListApiCallId) {
      this.getAddToListApiFailureCallBack(responseJson);
    }

  }



  getSearchLibraryApiSuccessCallBack = (responseJson: any) => {
    console.log('LIB:R', responseJson);
    this.setState({ isLoading: false, librarySearchPage: responseJson });
  }


  getSearchLibraryApiFailureCallBack = (responseJson: any) => {

  }


  getAddToCartApiSuccessCallBack = (responseJson: any) => {
    if (responseJson && responseJson.data) {
      alert('Added Into Cart')
    } else {
      alert(`${responseJson.message}`)

    }
  }


  getAddToCartApiFailureCallBack = (responseJson: any) => {

  }

  getCategoryListApiSuccessCallBack = (responseJson: any) => {
    this.setState({ categoryList: responseJson?.data, maxPrice: responseJson?.meta?.max_price });
  }

  getAutoSuggestionListApiSuccessCallBack = (responseJson: any) => {
    console.log("@@@@========getAutoSuggestionListApiSuccessCallBack", responseJson)
    this.setState({ suggestionList: responseJson?.data })
  }


  getCategoryListApiFailureCallBack = (responseJson: any) => {
    Alert.alert('Error', responseJson.errors[0].token);
  }

  getAutoSuggestionListApiFailureCallBack = (responseJson: any) => {
    console.log("@@@@========getAutoSuggestionListApiFailureCallBack", responseJson)
  }

  getAddToListApiSuccessCallBack = (responseJson: any) => {
    let Data
    let isProgram = false
    if (this.state.itemClickedData && this.state.itemClickedData?.attributes?.expand_type == "Course") {
      Data = this.state.itemClickedData
    } else {
      Data = this.state.itemClickedData
      isProgram = true
    }
    if (this.state.playClicked) {
      if (Data?.attributes?.course_video_track?.last_video_id) {
        this.props.navigation.navigate('PhotographyScreen', { itemId: Data?.attributes?.course_video_track?.last_video_id, courseId: this.state.itemClickedData?.id, program_id: isProgram ? this.state.itemClickedData?.id : '', isProgram: isProgram })
      } else { alert(' Video not available ') }
    } else {
      if (responseJson && !responseJson.message) {
        alert('Added To Personal Library')
      } else {
        alert(`${responseJson.message}`)
        // setTimeout(() => { this.setState({ isLoading: false, itemClickedData: [] }) }, 1000);
      }
    }
  }


  getAddToListApiFailureCallBack = (responseJson: any) => {
    Alert.alert('Error', responseJson.errors[0].token);
  }



  addToCart = (id: any, type: any) => {

    let httpBody = {}
    if (type && type === 'Course') { httpBody = { course_id: id } }
    else { httpBody = { program_id: id } }


    const header = {
      "Content-Type": configJSON.validationApiContentType,
      token: this.state.token,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.addToCartApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.addToCart
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      (header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );

    requestMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage), "POST");
    console.log("requestMessage@@@@@@@", requestMessage)
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  }

  addToList = (item: any, val: any) => {
    this.setState({ itemClickedData: item, playClicked: val })

    let httpBody = {
      courseable_id: item.id,
      courseable_type: `BxBlockCoursecreation::${item.attributes.expand_type}`
    };
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      token: this.state.token,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.addToListApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.addToList
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      (header)
    );

    requestMessage.addData(getName(MessageEnum.RestAPIRequestBodyMessage), JSON.stringify(httpBody));

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "POST"
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  }

  getLibrarySearchPageInfo = (token: any, payload: any) => {
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      token: token
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getSearchLibraryApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      // this.state.searchTextInput == '' ? configJSON.search : configJSON.search + '?search=' + this.state.searchTextInput
      payload == '' || payload == null ? configJSON.search : configJSON.search + payload
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.apiMethodTypeGet
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  getCategoryList(): boolean {

    const header = {
      "Content-Type": configJSON.dashboardContentType,
      token: this.state.token,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.categoryListApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.categoryListGetUrl
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.apiMethodTypeGet
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  }

  getAutoSuggestionList(text: any): boolean {

    const header = {
      "Content-Type": configJSON.dashboardContentType,
      token: this.state.token,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getAutoSuggestionListApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `/bx_block_library2/libraries/autocomplete?search=${text}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.apiMethodTypeGet
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  }

  getLibrarySortSearchPageInfo = (params: any) => {
    this.setState({ isLoading: true })
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      token: this.state.token
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getSearchLibraryApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.search + params
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.apiMethodTypeGet
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  onApplyLibrarySortPressed() {
    this.setState({ showCoursesFilter: !this.state.showCoursesFilter })
    let selectedSortParams: any = [];
    this.state.sortData.map((item: any) => {
      if (item?.isChecked) {
        selectedSortParams.push(item?.param);
      }
    });
    this.setState({ sortParams: selectedSortParams },
      () => { this.sortLibrary(this.state.sortParams) });
  }

  sortLibrary = (value: any) => {
    const param = value.toString()
    let val = ''
    if (param == '' && this.state.priceAmount > 0) {
      val = `filter_price=${this.state.priceAmount}`
    }
    else if (param != "" && this.state.priceAmount > 0) {
      val = `sort=${param}&filter_price=${this.state.priceAmount}`
    }
    else if (param != "") {
      val = `sort=${param}`
    } 
    
    this.setState({ sortPayload: val })
    this.deployPayload(val)
  }

  deployPayload(val: any) {
    let payload
    payload = `?search=${this.state.searchTextInput}&${this.state.filterPayload}&${val}`
    if (!val && !this.state.searchTextInput && !this.state.filterPayload) {
      payload = ``
    }
    this.getLibrarySortSearchPageInfo(payload)
  }

  onLibrarySortItemSelected(id: any) {
    let check = this.state.sortData;
    let newIndex = this.state.sortData.findIndex((item: any) => item.id === id);
    check[newIndex].isChecked = !check[newIndex].isChecked;

    check.map((element: any) => {
      if (element.id !== id) {
        element.isChecked = false
      }
    })
    this.setState({ sortData: check })
  }

  isFilterAppliedListAvailable() {
    this.setState({ showCoursesFilter: !this.state.showCoursesFilter });
    this.state.courseFilterData.map((item: any) => {
      if (this.state.filter_dues.includes(item.id)) {
        item.isChecked = true
      } else {
        item.isChecked = false
      }
    });
  }

  onFilterItemSelected(id: any) {

    let filterSelectedDatas = this.state.categoryList.map((item: any) => {
      if (item?.id === id) {
        if (!item.isChecked) {
          item.isChecked = true;
          return item
        } else {
          item.isChecked = false;
          return item
        }
      } else {
        return item
      }
    });
    this.setState({ categoryList: filterSelectedDatas });
  }

  onFilterPaymentSelected(id: any) {

    let filterSelectData = this.state.filterPaymentData.map((item: any) => {
      if (item?.id === id) {


        if (!item.isChecked) {

          item.isChecked = true;
          return item

        } else {

          item.isChecked = false;

          return item
        }

      } else {

        return item

      }


    });
    this.setState({ filterPaymentData: filterSelectData });

  }

  closeLibraryFilter() {
    this.setState({ channelFilter: false })
  }

  onApplyLibraryFilterPressed() {
    this.setState({ channelFilter: false })
    let selectedFilterOption: any = [];
    let selectedParams: any = [];
    this.state.categoryList.map((item: any) => {
      if (item?.isChecked) {
        selectedFilterOption.push(item?.id);
      }
    });
    this.setState({ filterLibraryId: selectedFilterOption },
      () => {
        this.filterId(this.state.filterLibraryId);

      });
  }

  filterId(values: any) {
    let params: string = '';
    values.map((item: any) => {
      params = params + `filter_ids[]=${item}&`
    })

    let paramsPayment: string = '';
    this.state.filterPaymentData.map((item: any) => {
      if (item.isChecked) {
        paramsPayment = paramsPayment + item.params + `&`
      }
    })

    let filterParams: string = '';
    const courses = this.state.isCoursesOnly === true ? `courses_only=true&` : ''
    const program = this.state.isProgramOnly === true ? `programs_only=true` : ''
    const newlyAdded = this.state.isNewlyAdded === true ? `filter_ids[]=0&` : ''
    filterParams = newlyAdded + params + paramsPayment + courses + program
    this.setState({ filterPayload: filterParams })
    // payload = `?` + newlyAdded + params + paramsPayment + courses + program
    this.filterPayload(filterParams)
  }

  filterPayload(filterParams: any) {
    let payloadFilter
    payloadFilter = `?search=${this.state.searchTextInput}&${filterParams}&${this.state.sortPayload}`
    if (!this.state.sortPayload && !this.state.searchTextInput && !filterParams) {
      payloadFilter = ``
    }
    this.getLibrarySortSearchPageInfo(payloadFilter)
  }
}
// Customizable Area End
