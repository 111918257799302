import React, { useState } from "react";
import {
  FormControl,
  Select,
  MenuItem,
  ListSubheader,
  TextField,
  InputAdornment,
  Checkbox,
  OutlinedInput,
  ListItemText, InputLabel
} from "@material-ui/core";
import SearchIcon from "@material-ui/icons/Search";
import {Caret} from "./asset";

let allOptions: any[] = [  

];

export default function NotifyAutocomplete(props:any) {
  allOptions = props.options;
  const options = allOptions.filter((item:any) => item.attributes.is_selected === true)
  const [selectedOption, setSelectedOption] = useState([...options]);
  const [displayedOptions,setDisplayedOptions] = useState([...allOptions])

  const [searchText, setSearchText] = useState("");
  const textChange = (e: any) => {
    if (e.target.value === "") {
      if (selectedOption.length === 0) setSelectedOption([]);
      setDisplayedOptions(props.options);
      setSearchText("");
    } else {
      let searchTerm = e.target.value;
      let regex = new RegExp(searchTerm, "i");
      const display = () =>
        allOptions.filter((option: any) => {
          return regex.test(option.attributes.full_name);
        });
      setDisplayedOptions(display);
      setSearchText(e.target.value);
    }
  };

  const handleSelect = (e: any) => {
    const {
      target: { value }
    } = e;
    if (value.length > 0 && value[0]) {
      let duplicateRemoved: any[] = [];
      value.forEach((item: any) => {
        if (item) {
          if (duplicateRemoved.findIndex((o) => o.id === item.id) >= 0) {
            duplicateRemoved = duplicateRemoved.filter((x) => x.id === item.id);
          } else {
            duplicateRemoved.push(item);
          }
        }
      });
      props.getSelectedData(duplicateRemoved);
      setSelectedOption(duplicateRemoved);
    } else {
      props.getSelectedData([]);
      setSelectedOption([]);
    }
  };
  return (
      <FormControl fullWidth className="select-notify">
        <Select
          MenuProps={{ autoFocus: false }}
          labelId="search-select-label"
          id="search-select"
          name="age"
          value={selectedOption}
          variant="outlined"
          IconComponent={() => null}
          endAdornment={
            <InputAdornment position="end">
              <img src={Caret} />
            </InputAdornment>

          }
          onChange={(e:any) => {handleSelect(e)}}
          onClose={() => setSearchText("")}
          input={<OutlinedInput  />}
          multiple
          //@ts-ignore
          renderValue={(selectedOption) => {
          //@ts-ignore
         return selectedOption.map(item => item.attributes.full_name).join(', ')
        }}
          fullWidth
        >
          <ListSubheader>
            <TextField
              size="small"
              autoFocus
              variant="outlined"
              placeholder="Search"
              id="my-custom-search"
              InputLabelProps={{
                shrink: true
              }}
              value={searchText}
              className="search"
              fullWidth
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <SearchIcon />
                  </InputAdornment>
                )
              }}
              onChange={(e) => {textChange(e)}}
              onKeyDown={(e) => {if (e.key !== "Escape") e.stopPropagation();}}
            />
          </ListSubheader>
          <MenuItem value="">
            <ListItemText primary="Search and Select" />
            </MenuItem>
          {displayedOptions.length!==0 && displayedOptions.map((option:any,index:any) => (
            <MenuItem key={index} value={option}>
            <Checkbox checked={selectedOption.findIndex((item) => item.id === option.id) >= 0} />
            <ListItemText primary={option.attributes.full_name} />
            </MenuItem>
          ))}
        </Select>
      </FormControl>
  );
}
