import React from "react";
import clsx from "clsx";
import { Box, Typography, Button, IconButton } from "@material-ui/core";
import { withStyles, createStyles, Theme } from "@material-ui/core/styles";
import CloseIcon from '@material-ui/icons/Close';
import Modal from "@material-ui/core/Modal";
import Fade from "@material-ui/core/Fade";
import Backdrop from "@material-ui/core/Backdrop";
const famework = require("../assets/Frame.png")
interface Props {
    // Customizable Area Start
    isModalOpen: boolean;
    handelEditDailogClose: () => void;
    classes?: any;
    history:any;
    // Customizable Area End
}

function Congratulations({ isModalOpen, handelEditDailogClose, classes, history }: Props) {

    const handleCloseAndNavigate = () => {
        handelEditDailogClose(); // Call the first function
        history.push("/library"); // Call the second function
      };
    return (
        <Modal
            aria-labelledby="transition-modal-title"
            aria-describedby="transition-modal-description"
            className="modalStyle"
            open={isModalOpen}
            // onClose={this.props.handelEditDailogClose}
            closeAfterTransition
            BackdropComponent={Backdrop}
            BackdropProps={{
                timeout: 500,
            }}
        >
            <Box className={classes.parentBox}>
                <IconButton
                    aria-label="delete"
                    onClick={handelEditDailogClose}
                    className={clsx(
                        classes.closeIconBtn,
                        localStorage.getItem("user_role") === "super_admin" &&
                        classes.closeIconBtnAdmin
                    )}
                >
                    <CloseIcon
                        className={
                            localStorage.getItem("user_role") === "super_admin"
                                ? classes.closeIconAdmin
                                : classes.closeIcon
                        }
                    />
                </IconButton>
                <Fade in={isModalOpen}>
                    <Box style={style.mainBox}>
                        <Box style={style.imgBox}>
                            <img style={style.img} src={famework} />
                        </Box>
                        <Typography style={style.typography}>Congratulations!</Typography>
                        <Typography style={style.text}>Your account has successfully been activated</Typography>
                        <Box style={style.buttonBox}>
                            <Button onClick={handleCloseAndNavigate} style={style.button}>Start Learning</Button>
                        </Box>
                    </Box>
                </Fade>
            </Box>
        </Modal>
    )
}

const styles = (theme: Theme) =>
    createStyles({
        parentBox: {
            position: 'relative'
        },
        closeIconBtn: {
            color: "black",
            "&:hover": {
                backgroundColor: "white",
            },
            background: "white",
            position: "absolute",
            left: "95%",
            top: 0,
            display: "none",
            [theme.breakpoints.up("sm")]: {
                display: "block",
                top: "-20px",
            },
        },
        closeIconBtnAdmin: {
            background: "#6C328B",
            colo: "#000000",
            "&:hover": {
                backgroundColor: "#6C328B",
            },
        },
        closeIconAdmin: {
            color: "#FFFFFF",
        },
        closeIcon: {
            color: "#000000",
        },
    })

export default withStyles(styles)(Congratulations);

const style = {
    mainBox: {
        width: 'auto',
        height: 'auto',
        background: '#fff',
        borderRadius: '15px',
        display: 'flex',
        flexDirection: 'column' as 'column',
        justifyContent: 'center' as 'center',
        padding: '25px'
    },
    imgBox: {
        display: 'flex',
        flexDirection: 'column' as 'column',
        justifyContent: 'center' as 'center',
        alignItems: 'center' as 'center',
    },
    img: {
        display: 'flex',
        margin: 'auto',
        maxWidth: '100%',
    },
    typography: {
        textAlign: 'center' as 'center',
        fontSize: '24px',
        paddingTop: '15px',
        fontWeight: 700,
        color: '#6C328B'
    },
    text: {
        textAlign: 'center' as 'center',
        fontSize: '16px',
        paddingTop: '8px',
        fontWeight: 400,
        color: '#1A1A1A'
    },
    buttonBox: {
        textAlign: 'center' as 'center',
        cursor: 'pointer',
        marginTop: '4%'
    },
    button: {
        width: '65%',
        backgroundColor: "#FF8B3F",
        borderRadius: '20px',
        color: '#fff',
        fontWeight: 700,
        height: '48px',
    },
}

