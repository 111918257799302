import React from 'react';
// Customizable Area Start
import { View, TouchableOpacity, Text, StyleSheet, Dimensions, Image, FlatList } from 'react-native';
import * as IMG_CONST from '../assets';
import EvilIcons from "react-native-vector-icons/EvilIcons";
interface IProps {
  courses: any,
  gotoPersonalDetailsScreen: (item: any) => void,
  addToCartButton: (item: any) => void
  onPressPlayButton: (item: any) => void

}
// Customizable Area End

export default function CoursesProgram(props: IProps) {
  return (
    // Customizable Area Start
    <FlatList
      data={props.courses}
      contentContainerStyle={styles.contentContainerstyle}
      ListEmptyComponent={() => {
        return <View>
          <Text style={styles.nodatafound}>No Courses Found...</Text>
        </View>
      }}
      renderItem={({ item }: { item: any }) => {
        console.log('itemitemitem', item)
        return <TouchableOpacity style={styles.cardContainer}
          onPress={() => props?.gotoPersonalDetailsScreen(item)}

        >

          <View style={styles.channelImageContainer}>
            <Image source={{ uri: item?.attributes?.thumbnail_image?.url }}
              style={styles.courseImage}
            />
          </View>

          <View style={styles.basicflex}>
            <View style={styles.flexcontainer}>
              <Text numberOfLines={1} style={styles.title}>{item?.attributes?.title}</Text>
              <Text numberOfLines={2} style={styles.subtitle}>{item?.attributes?.description}</Text>
            </View>

            <View style={styles.bottomcontainer}>
              <Text style={styles.createdby}>{item?.attributes?.created_by}</Text>

              {/* {
                item?.attributes?.is_paid ?
                  <TouchableOpacity
                    onPress={() => props?.addToCartButton(item)}
                  >
                    <Image
                      style={styles.cartWithColorIcon}
                      source={require('../../../dashboard/assets/Cartwithcolour.png')} />
                  </TouchableOpacity>
                  :
                  <TouchableOpacity>
                    <Image source={require('../../../dashboard/assets/playbutton.png')}
                      style={{ height: 40, width: 70, borderRadius: 50 }}
                    />
                  </TouchableOpacity>
              } */}

              {item?.attributes?.button === 'enroll' ?
                <TouchableOpacity
                  onPress={() => props?.onPressPlayButton(item)}
                >
                  <Image source={require('../../../dashboard/assets/playbutton.png')}
                    style={{ height: 40, width: 70, borderRadius: 50 }}
                  />
                </TouchableOpacity>
                :
                item?.attributes?.button === 'cart' ?

                  <TouchableOpacity
                    onPress={() => props?.addToCartButton(item)}
                    style={styles.cartView}
                  >
                    <Image
                      style={styles.cartWithColorIcon}
                      resizeMode={'contain'}
                      source={require('../../../dashboard/assets/Cart2.png')} />
                  </TouchableOpacity>
                  :
                  item?.attributes?.button === 'subscription' ?
                    <TouchableOpacity style={{ justifyContent: 'center', height: 27, width: 85, backgroundColor: '#000', borderRadius: 40, alignItems: 'center' }}
                      onPress={() => props?.gotoPersonalDetailsScreen(item)}
                    // onPress={() => { Data?.course_video_track?.last_video_id ? this.props.navigation.navigate('PhotographyScreen', { itemId: Data?.course_video_track?.last_video_id, courseId: this.state.courseData?.id, isProgram: false, program_id: program_id }) : alert('Video not available') }}
                    >
                      <Text style={{ color: '#fff', fontFamily: 'Montserrat-Bold', fontSize: 12 }}>Subscribe</Text>
                    </TouchableOpacity>
                    :
                    <TouchableOpacity style={{ justifyContent: 'center' }}
                    // onPress={() => { Data?.course_video_track?.last_video_id ? this.props.navigation.navigate('PhotographyScreen', { itemId: Data?.course_video_track?.last_video_id, courseId: this.state.courseData?.id, isProgram: false, program_id: program_id }) : alert('Video not available') }}
                    >
                      <Image
                        style={styles.cartWithColorIcon}
                        source={require('../../../dashboard/assets/Cartwithcolour.png')} />
                    </TouchableOpacity>
              }
            </View>
          </View>
        </TouchableOpacity>
      }}
    />
  );
  // Customizable Area End
}

const styles = StyleSheet.create({
  // Customizable Area Start
  cardContainer: {
    shadowColor: "#000",
    shadowOffset: {
      width: 0,
      height: 2,
    },
    shadowOpacity: 0.25,
    shadowRadius: 3.84,
    elevation: 5, width: Dimensions.get('screen').width - 40,
    borderRadius: 20, marginBottom: 20,
    alignSelf: 'center', backgroundColor: '#fff', flexDirection: 'row', flex: 1
  },
  nodatafound: { alignSelf: 'center', color: '#595959', fontFamily: 'Montserrat-Regular', fontSize: 12 },
  contentContainerstyle: { marginTop: 20 },
  channelImageContainer: { flex: 0.38, backgroundColor: '#EEE', height: '100%', justifyContent: 'center', borderRadius: 20, },
  courseImage: { width: '100%', height: 125, alignSelf: 'center', borderRadius: 20 },
  cartWithColorIcon: { width: 17, height: 18, tintColor: '#fff' },
  title: { fontFamily: 'Montserrat-Bold', fontSize: 14, maxWidth: 180 },
  subtitle: { fontFamily: 'Montserrat-Medium', fontSize: 12, marginTop: 3, maxWidth: 180 },
  bottomcontainer: { flexDirection: 'row', justifyContent: 'space-between', paddingBottom: 5, marginStart: 10, marginEnd: 10 },
  createdby: { fontFamily: 'Montserrat-SemiBold', fontSize: 9, alignSelf: 'center' },
  flexcontainer: { flex: 1, marginTop: 10, marginStart: 10 },
  basicflex: { flex: 0.62 },
  View5: { flexDirection: 'row', padding: 10, marginTop: 5, alignItems: 'center', borderRadius: 30, backgroundColor: '#2B2B2B' },
  Text8: { fontSize: 20, fontFamily: 'Montserrat-Bold', paddingHorizontal: 5, color: 'white' },
  View50: { flexDirection: 'row', padding: 10, marginTop: 5, alignItems: 'center' },
  Text80: { fontSize: 22, fontFamily: 'Montserrat-Bold', paddingHorizontal: 5, color: '#1A1A1A' },
  cartView:{height:28,width:64,borderRadius:14,justifyContent:'center',alignItems:'center',backgroundColor:"#000"}
  // Customizable Area End
});