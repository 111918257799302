import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
    getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
    navigation: any;
    id: string;
    // Customizable Area Start
    // Customizable Area End
}

interface S {
    arrayHolder: any;
    token: string;
    // Customizable Area Start
  back: String;
  myChannel: string;
  personalChannels: String,
  assignedChannel: String,
  recommendedChannel: String
  recommendedCategory: String
  viewAll: String
  image: String;
  creativeWritings: String;
  channelDescription: String,
  channelContent: String,
  createdBy: String,
  category: String
  lorem: String
  loading: boolean
  categoryId: any
  searchTitle: String
    // Customizable Area End
}

interface SS {
    id: any;
}

export default class CatalogueController extends BlockComponent<Props, S, SS> {
    getProductApiCallId: any;
    constructor(props: Props) {
        super(props);
        this.receive = this.receive.bind(this);

        this.subScribedMessages = [
            getName(MessageEnum.RestAPIResponceMessage),
            getName(MessageEnum.SessionSaveMessage),
            getName(MessageEnum.SessionResponseMessage),
            // Customizable Area Start
            // Customizable Area End
        ];

        this.state = {
            arrayHolder: [],
            token: "",
    // Customizable Area Start
      back: configJSON.back,
      myChannel: configJSON.myChannel,
      personalChannels: configJSON.personalChannels,
      assignedChannel: configJSON.assignedChannels,
      recommendedChannel: configJSON.RecomendedChannels,
      recommendedCategory: configJSON.RecomendedCategory,
      viewAll: configJSON.viewAll,
      image: configJSON.image,
      creativeWritings: configJSON.createWriting,
      channelDescription: configJSON.channelDesc,
      channelContent: configJSON.channelContent,
      createdBy: configJSON.createdBy,
      category: configJSON.category,
      lorem: configJSON.lorem,
      loading: false,
      categoryId: this.props.navigation.state.params?.item.itemId,
      searchTitle: this.props.navigation.state.params?.item.title,
    // Customizable Area End
        };
        runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
        // Customizable Area Start
        // Customizable Area End
    }
    async componentDidMount() {
        super.componentDidMount();
        this.getToken();
      // Customizable Area Start
        if (this.isPlatformWeb() === false) {
            this.props.navigation.addListener("willFocus", () => {
        this.setState({
          categoryId: this.props.navigation.state.params?.item.itemId,
          searchTitle: this.props.navigation.state.params?.item.title,
        }, () => {
          this.getToken();
          //callback
        })
          });
        }
      // Customizable Area End
    }

    getToken = () => {
        const msg: Message = new Message(
            getName(MessageEnum.SessionRequestMessage)
        );
        this.send(msg);
    };

    getListRequest = (token: any) => {
    // Customizable Area Start
    console.log("Value------------", this.state.categoryId, this.state.searchTitle);

    const attrs = {
      "category_id": this.state.categoryId,
      "name": this.state.searchTitle
    };

    const data = {
      attributes: attrs,
    };

    const httpBody = {
      data: data,
    };

    const header = {
      "Content-Type": configJSON.productApiContentType,
      token: token,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getProductApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      "bx_block_usergroups/channels/my_channel_dashboard?name=" + (this.state.searchTitle != undefined ? this.state.searchTitle : "") + "&category_id=" + (this.state.categoryId ? this.state.categoryId : "")
    );
    // requestMessage.addData(
    //   getName(MessageEnum.RestAPIResponceDataMessage),
    //   JSON.stringify(httpBody)
    // );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.apiMethodTypeGet
    );
    this.setState({ loading: true });
      runEngine.sendMessage(requestMessage.id, requestMessage);
    // Customizable Area End
    };

    async receive(from: string, message: Message) {
        // Customizable Area Start
    this.setState({ loading: false });
    runEngine.debugLog("Message Recived", message);
    if (getName(MessageEnum.SessionResponseMessage) === message.id) {
      let token = message.getData(getName(MessageEnum.SessionResponseToken));
      console.log(token + "token-------------------------------");
      // let token = localStorage.getItem('authToken');
      this.setState({ token: token });
      this.getListRequest(token);
    }

    if (
      getName(MessageEnum.RestAPIResponceMessage) === message.id &&
      this.getProductApiCallId != null &&
      this.getProductApiCallId ===
      message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
    ) {

      var responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      if (responseJson && !responseJson.errors && responseJson) {
        this.setState({ arrayHolder: responseJson });
        runEngine.debugLog("arrayHolder", this.state.arrayHolder);
      } else {
        var errorReponse = message.getData(
          getName(MessageEnum.RestAPIResponceErrorMessage)
        );
        this.parseApiCatchErrorResponse(errorReponse);
      }
    }
        // Customizable Area End
    }

    // Customizable Area Start
    // Customizable Area End
}
