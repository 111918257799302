import React, { Component } from 'react'
import {
  Container,
  Box,
  Input,
  Button,
  InputLabel,
  Typography,
  InputAdornment,
  IconButton,
  TextField,
  // Customizable Area Start
  Paper,
  Grid
  // Customizable Area End
} from "@material-ui/core";
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardMedia from '@material-ui/core/CardMedia';
import CourseCompletionController from './CourseCompletionController.web';
import Rating from '@material-ui/lab/Rating';
import './videostyles.css';
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import NavigateNextOutlinedIcon from '@material-ui/icons/NavigateNextOutlined';
import { courseimage4, relatedData } from "./assets";
// import { RatingComponent } from 'react-rating-emoji'
// import 'react-rating-emoji/dist/index.css'
// Customizable Area Start
const img = require("../assets/sdfg@2x.png");
const img1 = require("../assets/CourseCompletion.png");
const play = require("../assets/play.png")
const img3 = require("../assets/courseimage5.png")
const group = require("../assets/Group 8.png")
const shop = require("../assets/image_Bitmap Copy 4.png")
const emoji1 = require("../assets/Asset 10@3x Copy.png")
const emoji2 = require("../assets/Asset 11@3x Copy.png")
const emoji3 = require("../assets/Asset 12@3x Copy.png")
const emoji4 = require("../assets/Asset 13@3x Copy.png")

// Customizable Area End

export default class CourseCompletion extends CourseCompletionController {
  //   constructor() {
  // super();
  // Customizable Area Start
  // Customizable Area End
  //   }

  // Customizable Area Start

  // Customizable Area End


  render() {

    return (
      <>
        <Grid container style={{ paddingTop: "0px" }}>
          <Grid item lg={12} md={12} sm={12} xs={12}>
            <Typography variant="h3" className="profile_header" style={{ display: 'contents' }}>Course Completion</Typography>
            <Box style={{ margin: 'auto', display: 'flex', justifyContent: 'space-around' }}>
              <img src={img} style={{ width: '450px', zIndex: -1 }} />
            </Box>
            <img src={img1} style={{ position: 'absolute', display: 'flex', justifyContent: 'space-around', marginTop: '-24%', marginLeft: '37%', width: '16%' }} />
            <Card style={{ width: '526px', padding: '25px', borderRadius: '10px', boxShadow: '0px 9px 10px -6px rgba(175, 68, 232, 0.788325)', height: 'auto', background: 'linear-gradient(320.84deg, #592A93 -10.23%, #BB1AB9 108.17%)', margin: '-65px auto' }}>
              <Typography style={{ fontSize: '35px', textAlign: 'center', color: 'fff', fontWeight: 700, lineHeight: '30px' }}>Congratulations!</Typography>
              <Typography style={{ fontSize: '20px', fontWeight: 500, lineHeight: '24px', color: 'fff', marginTop: '15px', textAlign: 'center' }}>You have successfully completed the course 
              {this.props.history.location.state.data.achievements.data === null ||
                this.props.history.location.state.data.rewards.data === null ? "" : "and earned a reward"}</Typography>
            </Card>
            {this.MessageReward === null ?
              <></> :
              <Container maxWidth={false}>
                <Grid container spacing={4} style={{ paddingTop: '10%' }}>
                  <Grid item lg={8}>
                    <Card style={{ width: '100%', height: '865px', borderRadius: '10px', background: '#FAFAFA', boxShadow: '2px 8px 21px -6px rgba(0, 0, 0, 0.321105)' }}>
                      <Grid container style={{ paddingTop: '5%' }}>
                        <Grid lg={1}></Grid>
                        <Grid lg={5}>
                          <Typography style={{ fontSize: '24px', fontWeight: 700, lineHeight: '30px' }}>Comprehension</Typography>
                          <Typography style={{ width: '243px', fontSize: '16px', fontWeight: 500, lineHeight: '20px', color: '#1A1A1A', marginTop: '12px' }}>How well did you understand the concepts?</Typography>
                        </Grid>
                        <Grid lg={5}>
                          <Box style={{ display: 'flex', justifyContent: 'space-between' }}>
                            {this.state.Emoji.map((data: any) => (
                              <img
                                key={data.id}
                                src={data.selected ? data.selected : data.emoji} // Display selected image if selected, otherwise display default emoji
                                style={{ width: '70px', height: '70px', cursor: 'pointer' }}
                                onClick={() => this.handleEmojiClick(data.id)} // Attach click event handler
                              />
                            ))}
                          </Box>
                        </Grid>
                        <Grid lg={1}></Grid>
                      </Grid>

                      <Grid container style={{ paddingTop: '8%' }}>
                        <Grid lg={1}></Grid>
                        <Grid lg={5}>
                          <Typography style={{ fontSize: '24px', fontWeight: 700, lineHeight: '30px' }}>Rate your experience</Typography>
                          <Typography style={{ width: '243px', fontSize: '16px', fontWeight: 500, lineHeight: '20px', color: '#1A1A1A', marginTop: '12px' }}>Hope you like the course</Typography>
                        </Grid>
                        <Grid lg={5}>
                          <Rating
                            name="simple-controlled"
                            value={this.state.value}
                            onChange={(event, newValue) => {
                              this.setState({ value: newValue });
                            }}
                            style={{ fontSize: '4.5rem', marginTop: '-15px' }}
                          />
                        </Grid>
                        <Grid lg={1}></Grid>
                      </Grid>

                      <Grid container style={{ paddingTop: '7%' }}>
                        <Grid lg={1}></Grid>
                        <Grid lg={10}>
                          <Typography style={{ fontSize: '24px', fontWeight: 700, lineHeight: '30px' }}>What do you like to say?</Typography>
                          <Box className='TextInput'>
                            <TextField
                              value={this.state.txtCommentMsg}
                              onChange={(event) => this.setState({ txtCommentMsg: event.target.value })}
                              style={{ marginTop: '10px', width: '100%', height: '140px' }}
                              margin="dense"
                              id="outlined-basic"
                              label="Please write any additional comments here…"
                              variant="outlined"
                              inputProps={{ style: { textAlign: 'left', borderRadius: '15px' } }}
                              multiline
                              rows={6}
                            />
                          </Box>
                        </Grid>
                        <Grid lg={1}></Grid>
                      </Grid>

                      <Grid container style={{ paddingTop: '3%' }}>
                        <Grid lg={1}></Grid>
                        <Grid lg={10}>
                          <Typography style={{ fontSize: '24px', fontWeight: 700, lineHeight: '30px' }}>What did you learn?</Typography>
                          <Box className='TextInput'>
                            <TextField
                              value={this.state.ExtCommentMsg}
                              onChange={(event) => this.setState({ ExtCommentMsg: event.target.value })}
                              style={{ marginTop: '10px', width: '100%', height: '140px' }}
                              margin="dense"
                              id="outlined-basic"
                              label="Please write your learnings here…"
                              variant="outlined"
                              inputProps={{ style: { textAlign: 'left', borderRadius: '15px' } }}
                              multiline
                              rows={6}
                            />
                          </Box>
                        </Grid>
                        <Grid lg={1}></Grid>
                      </Grid>

                      <Grid container style={{ paddingTop: '0%' }}>
                        <Grid lg={1}></Grid>
                        <Grid lg={5}></Grid>
                        <Grid lg={5}>
                          <Box style={{ display: 'flex', justifyContent: 'center', marginTop: '20px' }}>
                            <Button onClick={() => this.reviewSubmit()} style={{ width: '100%', fontWeight: 700, padding: '15px', borderRadius: '20px', backgroundColor: '#FF8B3F' }} variant="contained" color="primary">
                              Submit
                            </Button>
                          </Box>
                        </Grid>
                        <Grid lg={1}></Grid>
                      </Grid>
                    </Card>
                  </Grid>

                  <Grid item lg={4}>
                    <Typography style={{ width: '88%', fontSize: '24px', fontWeight: 700, lineHeight: '30px' }}>What you learned in this course</Typography>
                    {[...Array(5)].map((e, i) => (
                      <Box style={{ marginTop: '15px' }}>
                        <hr style={{ width: '8px', height: '8px', background: '#FF8B3F', float: 'left', marginTop: '10px', borderRadius: '10px' }} />
                        <Typography style={{ width: '85%', paddingLeft: '15px', display: 'flex', fontSize: '16px', fontWeight: 500, lineHeight: '25px' }}>You learned taking amazing photos that can impress your family and friends</Typography>
                      </Box>
                    ))}
                  </Grid>
                </Grid>
              </Container>
            }

            <Grid container style={{ marginTop: '5%' }}>
              <Grid item lg={12} md={12} sm={12} xs={12}>
                <Box style={{ height: '500px', margin: 'auto', maxWidth: '83vw', overflow: 'hidden', flex: 1 }}>
                  <h3>Recommended courses for you!</h3>
                  <PreviousNextMethods />
                </Box>
              </Grid>
            </Grid>

          </Grid>
        </Grid>

      </>

    )

  }

}

export class PreviousNextMethods extends Component {
  slider = React.createRef<any>();
  constructor(props: any) {
    super(props);
    this.next = this.next.bind(this);
    this.previous = this.previous.bind(this);
  }

  next() {
    // @ts-ignore
    this.slider.slickNext();
  }
  previous() {
    // @ts-ignore
    this.slider.slickPrev();
  }
  render() {
    var settings = {
      dots: false,
      infinite: true,
      speed: 500,
      slidesToShow: 4.5,
      slidesToScroll: 1,
      initialSlide: 0,
      responsive: [
        {
          breakpoint: 1300,
          settings: {
            slidesToShow: 3,
            slidesToScroll: 1,
            infinite: true,
            dots: false
          }
        },
        {
          breakpoint: 1200,
          settings: {
            slidesToShow: 3,
            slidesToScroll: 1,
            infinite: true,
            dots: false
          }
        },
        {
          breakpoint: 1024,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 1,
            infinite: true,
            dots: false
          }
        },
        {
          breakpoint: 600,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
            initialSlide: 2,
            dots: false
          }
        },
        {
          breakpoint: 480,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
            dots: false
          }
        }
      ]
    };


    return (
      <>
        <Box style={{ position: 'relative' }}>
          <Slider
            ref={c => (
              // @ts-ignore
              this.slider = c
            )}
            {...settings}>
            {relatedData ?
              relatedData.map((data: any, index: any) => (
                <>
                  <Box key={index} style={{ padding: "0 10px 0 10px", borderRadius: '25px 25px 0 0', marginTop: '20px' }} >
                    <CardMedia
                      style={{ height: "240px", width: '100%', borderRadius: '25px 25px 0 0' }}
                      image={img3}
                    />
                    <Paper style={{ width: '100%', borderRadius: '25px', marginTop: '-20px', height: '172px' }}>
                      <Typography style={{ margin: 'auto', padding: '17px', fontWeight: 600, fontSize: '14px', lineHeight: '20px' }}>
                        HTML 5 Expert course and CSS 3 with sample project
                      </Typography>
                      <Typography variant="body2" color="textSecondary" component="p"
                        style={{ margin: 'auto', marginTop: '-15px', marginBottom: '3px', padding: '0 17px', color: 'black' }}>
                        Dr Shephard
                      </Typography>

                      <Typography variant="body2" color="textSecondary" component="p"
                        style={{
                          margin: 'auto', fontSize: '12px', marginBottom: '5px', padding: '0 17px',
                          color: '#44444F'
                        }}>
                        Due in 22 Days
                      </Typography>
                      <Card style={{
                        width: '87px', height: '45px', borderRadius: '30px', float: 'left',
                        marginTop: '-2px', marginLeft: '25px', background: '#EF2B4B'
                      }}>
                        <CardActionArea>
                          <img src={group} style={{ margin: 'auto', padding: '15px', display: 'flex' }} />
                        </CardActionArea>
                      </Card>

                      <Card style={{
                        width: '87px', height: '45px', borderRadius: '30px', float: 'right',
                        marginTop: '-2px', marginRight: '25px', background: '#6C328B'
                      }}>
                        <CardActionArea>
                          <img src={play} style={{ margin: 'auto', padding: '12px', display: 'flex' }} />
                        </CardActionArea>
                      </Card>
                    </Paper>
                  </Box>
                </>
              )) :
              <></>}
          </Slider>

          <Box>
            <IconButton aria-label="delete" onClick={this.next} style={{ position: 'absolute', right: 0, top: '45%', background: 'black' }}>
              <NavigateNextOutlinedIcon style={{ color: '#fff' }} />
            </IconButton>
          </Box>
        </Box>
      </>
    );
  }
}