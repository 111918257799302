import React from "react";
import { Box, Typography, FormControlLabel, Checkbox } from "@material-ui/core";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import { CheckedIcon, UncheckedIcon } from "../assets";

interface ISelectAll {
  type: string;
  show: boolean;
  disabled: boolean;
  isChecked: boolean;
  onSelectAll: () => void;
}

export default function SelectAll({
  type,
  show,
  disabled,
  isChecked,
  onSelectAll,
}: ISelectAll) {
  const classes = useStyles();

  if (type === "normal") return null;

  if (!show) return null;

  return (
    <Box className={classes.selectAllCheck}>
      <FormControlLabel
        disabled={disabled}
        control={
          <Checkbox
            icon={<img src={UncheckedIcon} alt="uncheck-icon" />}
            checkedIcon={<img src={CheckedIcon} alt="check-icon" />}
            checked={isChecked}
            onChange={onSelectAll}
            name="select-all"
            color="default"
          />
        }
        label={
          <Typography className={classes.selectAllLabel}>
            Select All
          </Typography>
        }
      />
    </Box>
  );
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    selectAllCheck: {
      display: "flex" as "flex",
      flexDirection: "row" as "row",
      justifyContent: "start" as "start",
      width: "100%",
      padding: "0 33px",
      [theme.breakpoints.up("sm")]: {
        padding: "0 55px",
      },
    },
    selectAllLabel: {
      fontWeight: 600,
      fontSize: "16px",
      lineHeight: "19px",
      color: "#000000",
    },
  })
);
