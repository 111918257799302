import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import {toast} from 'react-toastify';
// import 'react-toastify/dist/ReactToastify.css';

// Customizable Area Start
import { imgPasswordInVisible, imgPasswordVisible, slider1, slider2, slider3 } from "./assets";
// Customizable Area End

export const configJSON = require("./config");  

export interface Props {
  navigation: any;
  history:any;
  location:any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  txtInputValue: string;
  txtSavedValue: string;
  enableField: boolean;
  // Customizable Area Start
  anchorEl:any;
  libraryData:any;
  allInProgressData:any;
  grapValues:any;
  postXpandLibararyData:any;
  search:any;
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class InProgressController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  GetXpandLibraryData:any;
  PostXpandLibraryData:any;
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      // Customizable Area End
    ];

    this.state = {
      txtInputValue: "",
      txtSavedValue: "A",
      enableField: false,
      // Customizable Area Start
      anchorEl:'',
      grapValues:'Week',
      allInProgressData: [],
      postXpandLibararyData:{},
      search:"",
      libraryData:[
        {
          id:1,
          image_url:slider1,
          value:true,
        },
        {
          id:2,
          image_url:slider2,
          value:true,
        },
        {
          id:3,
          image_url:slider3,
          value:false,
        },
        {
          id:4,
          image_url:slider2,
          value:true,
        },
        {
          id:5,
          image_url:slider1,
          value:false,
        },
        {
          id:6,
          image_url:slider2,
          value:true,
        },
        {
          id:7,
          image_url:slider3,
          value:false,
        },
        {
          id:8,
          image_url:slider1,
          value:true,
        },
        {
          id:9,
          image_url:slider2,
          value:false,
        }
      ]
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
  }

  // @ts-ignore
  componentDidMount(){

  const open = Boolean(this.state.anchorEl);
  const id = open ? 'simple-popover' : undefined;
    this.setState({allInProgressData:this.props.history.location.state.data})
    window.scrollTo(0, 0)
  }

  async receive(from: string, message: Message) {
    runEngine.debugLog("Message Recived", message);

    if (message.id === getName(MessageEnum.AccoutLoginSuccess)) {
      let value = message.getData(getName(MessageEnum.AuthTokenDataMessage));

      this.showAlert(
        "Change Value",
        "From: " + this.state.txtSavedValue + " To: " + value
      );

      this.setState({ txtSavedValue: value });
    }

    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
        const apiRequestCallId = message.getData(
          getName(MessageEnum.RestAPIResponceDataMessage)
        );
  
        var responseJson = message.getData(
          getName(MessageEnum.RestAPIResponceSuccessMessage)
        );
  
      }
    // Customizable Area End
  }

  txtInputWebProps = {
    onChangeText: (text: string) => {
      this.setState({ txtInputValue: text });
    },
    secureTextEntry: false,
  };

  txtInputMobileProps = {
    ...this.txtInputWebProps,
    autoCompleteType: "email",
    keyboardType: "email-address",
  };

  txtInputProps = this.isPlatformWeb()
    ? this.txtInputWebProps
    : this.txtInputMobileProps;

  btnShowHideProps = {
    onPress: () => {
      this.setState({ enableField: !this.state.enableField });
      this.txtInputProps.secureTextEntry = !this.state.enableField;
      this.btnShowHideImageProps.source = this.txtInputProps.secureTextEntry
        ? imgPasswordVisible
        : imgPasswordInVisible;
    },
  };

  btnShowHideImageProps = {
    source: this.txtInputProps.secureTextEntry
      ? imgPasswordVisible
      : imgPasswordInVisible,
  };

  btnExampleProps = {
    onPress: () => this.doButtonPressed(),
  };

  doButtonPressed() {
    let msg = new Message(getName(MessageEnum.AccoutLoginSuccess));
    msg.addData(
      getName(MessageEnum.AuthTokenDataMessage),
      this.state.txtInputValue
    );
    this.send(msg);
  }

  // web events
  setInputValue = (text: string) => {
    this.setState({ txtInputValue: text });
  };

  setEnableField = () => {
    this.setState({ enableField: !this.state.enableField });
  };

  // Customizable Area Start

  handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    this.setState({anchorEl : event.currentTarget});
  };

  handleClose = () => {
    this.setState({anchorEl : null});
  };

 

  handleNavigate = (ele:any) => {
    if(ele.attributes?.collection?.attributes.expand_type === 'Course'){
        this.props.history.push(`/course-detail/${ele.attributes?.collection?.attributes.expand_type}/${ele?.attributes?.collection?.id}/${ele?.attributes?.collection?.attributes?.title.replace(/\s/g, '-')}`,{name: ele?.attributes?.collection})    
    }else if(ele.attributes?.collection?.attributes.expand_type === 'Program'){
      this.props.history.push(`/program-detail/${ele.attributes?.collection?.attributes.expand_type}/${ele?.attributes?.collection?.id}/${ele?.attributes?.collection?.attributes?.title.replace(/\s/g, '-')}`,{name: ele?.attributes?.collection}) 
    }
}

onChangeHandle = (event: any) => {
  this.setState({ search:event.target.value });
}

postplayButton = async (id:any) => {
  this.props.history.push(`/course-detail/${id}`,{name: id})
}



  // Customizable Area End
}