import React from 'react';
import { View, Text, StyleSheet, Dimensions, Image, FlatList, TouchableOpacity } from 'react-native';

interface IProps {
  xperts: any
  onXpertUserPressed: any
}

export default function Xperts(props: IProps) {
  return (

    <FlatList
      data={props.xperts}
      numColumns={3}
      contentContainerStyle={{ marginTop: 20 }}
      ListEmptyComponent={() => {
        return <View>
          <Text style={styles.nodatafound}>No Xperts Found...</Text>
        </View>
      }}
      renderItem={({ item }: { item: any }) => {
        return (
          <TouchableOpacity onPress={() => props.onXpertUserPressed(item)} style={styles.container}>
            <Image source={{ uri: item?.attributes?.avatar?.url }}
              style={styles.image}
            />
            <Text style={styles.firstNamestyles}>{item?.attributes?.first_name}</Text>

            <Text style={styles.profession}>{item?.attributes?.profession}</Text>
          </TouchableOpacity>
        );
      }}
    />
  );
}

const styles = StyleSheet.create({
  cardContainer: {
    shadowColor: "#000",
    shadowOffset: {
      width: 0,
      height: 2,
    },
    shadowOpacity: 0.25,
    shadowRadius: 3.84,
    elevation: 5, width: Dimensions.get('screen').width - 40,
    borderRadius: 20, marginTop: 10,
    alignSelf: 'center', backgroundColor: '#fff', flexDirection: 'row', flex: 0.33,
  },
  nodatafound: { alignSelf: 'center', color: '#595959', fontFamily: 'Montserrat-Regular', fontSize: 12 },
  container: { flex: 1, justifyContent: 'center', marginBottom: 20 },
  image: { width: 75, height: 75, borderRadius: 75 / 2, alignSelf: 'center' },

  firstNamestyles: {
    alignSelf: 'center',
    fontFamily: 'Montserrat-Bold',
    fontSize: 12, marginTop: 5
  },
  profession: {
    alignSelf: 'center',
    fontFamily: 'Montserrat-Regular', color: '#9A9A9A',
    fontSize: 10, marginTop: 5
  }
});