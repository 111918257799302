// Customizable Area Start
import { IBlock } from "../../../../../framework/src/IBlock";
import { Message } from "../../../../../framework/src/Message";
import { BlockComponent } from "../../../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../../../framework/src/RunEngine";
import { apiCall, sortTableData } from "../../../../../framework/src/Utilities";
import { EditIcon } from "./assets";
import { toast } from "react-toastify";
import { debounce } from "lodash";

export interface Props {
  navigation: any;
  history: any;
  location: any;
  classes: any;
}

export interface UserResponse {
  id: number;
  name: string;
  role: string;
  created_date: string | null;
  last_online: string | null;
  created_by: string | null;
  branch_name: string | null;
  active_account: boolean;
  team_count: number | null;
  user_teams: string[];
}

interface PaginationResponse {
  current_page: number;
  next_page: number | null;
  prev_page: number | null;
}

interface Meta {
  pagination: PaginationResponse;
}

interface UserListingResponse {
  data: UserResponse[];
  meta: Meta;
}

interface Action {
  id: number;
  icon: string;
  handleClick: () => void;
}

interface User {
  id: number;
  name: string;
  branch: string;
  teamCount: number;
  createdBy: string;
  createdDate: string | null;
  loginHistory: string | null;
  accountStatus: string;
  actions: Action[];
  teamNames: string[];
}

interface UserSuggestionResponse {
  id: number;
  first_name: string;
  last_name: string;
}

interface UserSuggestionsResponse {
  data: UserSuggestionResponse[];
}

interface Pagination {
  curPage: number;
  nextPage: number | null;
  prevPage: number | null;
}

interface S {
  users: User[];
  isLoading: boolean;
  filter: string;
  search: string;
  selectedSearch: string | null;
  autoCompleteOptions: string[];
  openAutoComplete: boolean;
  pagination: Pagination;
}

interface SS {
  id: any;
}

const configJSON = require("./config.js");
export default class UserManagementController extends BlockComponent<
  Props,
  S,
  SS
> {
  userListApiCallId: string = "";
  userSuggestionsApiCallId: string = "";

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionSaveMessage),
      getName(MessageEnum.SessionResponseMessage),
    ];

    this.state = {
      isLoading: false,
      users: [],
      filter: "team",
      search: "", // What user is typing in autocomplete box
      selectedSearch: "", // What user is selecting in autocomplete box
      autoCompleteOptions: [],
      openAutoComplete: false,
      pagination: {
        curPage: 1,
        nextPage: null,
        prevPage: null,
      }
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async componentDidMount(): Promise<void> {
    this.getUserList("", this.state.pagination.curPage);
  }

  componentDidUpdate(
    prevProps: Readonly<Props>,
    prevState: Readonly<S>,
    snapshot?: SS | undefined,
  ): void {
    // User is writing in the search bar
    if (prevState.search !== this.state.search) {
      if (this.state.search) {
        // Generate suggestions
        this.debouncedGetUserSuggestions(this.state.search);
      } else {
        // Remove suggestions & show all data
        this.setState({ autoCompleteOptions: [] });
        this.getUserList("", this.state.pagination.curPage);
      }
    }
    // User has selected one option from the suggestions
    if (
      this.state.selectedSearch &&
      this.state.selectedSearch !== prevState.selectedSearch
    ) {
      this.getUserList(this.state.selectedSearch, this.state.pagination.curPage);
    }
    // User has clicked the next of prev button
    if (this.state.pagination.curPage !== prevState.pagination.curPage) {
      this.getUserList("", this.state.pagination.curPage);
    }
  }

  async receive(from: string, message: Message) {
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage),
      );
      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage),
      );
      // Receive api responses
      if (apiRequestCallId === this.userListApiCallId) {
        this.handleGetUserList(responseJson);
      }
      if (apiRequestCallId === this.userSuggestionsApiCallId) {
        this.handleGetUserSuggestions(responseJson);
      }
    }
  }

  // Get users list
  getUserList = async (search: string, page: number) => {
    if (!search) {
      this.setState({ isLoading: true });
    }
    this.userListApiCallId = await apiCall({
      contentType: configJSON.apiContentType,
      method: configJSON.getApiMethod,
      endPoint: `${configJSON.userListEndPoint}?search=${search}&page=${page}`,
    });
  };

  // Handle users list api response
  handleGetUserList = (response: UserListingResponse) => {
    if (!response?.data) {
      toast.error("Something went wrong");
      return;
    }

    const users: User[] = response.data.map((user: UserResponse) => {
      return {
        id: user.id,
        name: user?.name ?? "-",
        branch: user?.branch_name ?? "-",
        teamCount: user?.team_count ?? 0,
        createdBy: user.created_by ?? "-",
        createdDate: user.created_date,
        loginHistory: user.last_online,
        accountStatus: user.active_account ? "Active" : "Inactive",
        teamNames: user.user_teams, 
        actions: [
          {
            id: 1,
            icon: EditIcon,
            handleClick: () => console.log(`Edit user ${user.id}`),
          },
        ],
      };
    });

    const pagination: Pagination = {
      curPage: response?.meta?.pagination?.current_page,
      nextPage: response?.meta?.pagination?.next_page,
      prevPage: response?.meta?.pagination?.prev_page,
    }

    this.setState({
      isLoading: false,
      users,
      pagination,
    });
  };

  // Get user suggestion
  getUserSuggestions = async (search: string) => {
    if (!search) {
      this.setState({ isLoading: true });
    }

    this.userSuggestionsApiCallId = await apiCall({
      contentType: configJSON.apiContentType,
      method: configJSON.getApiMethod,
      endPoint: `${configJSON.userSuggestionsEndPoint}?search=${search}`,
    });
  };

  // Debounced version of get user suggestions
  debouncedGetUserSuggestions = debounce(async (search: string) => {
    await this.getUserSuggestions(search);
  }, 150);

  // Handle response of user suggestions
  handleGetUserSuggestions = (response: UserSuggestionsResponse) => {
    if (!response?.data) return;
    if (this.state.search) {
      const options = response.data.map(
        (user: UserSuggestionResponse) => user.first_name,
      );
      this.setState({ autoCompleteOptions: options });
    }
  };

  // Sort data
  sortUsers = (dataType: string, key: string, sortType: string) => {
    const sortedUsers = sortTableData(
      this.state.users,
      dataType,
      key,
      sortType,
    );
    this.setState({ users: sortedUsers });
  };

  // Handle autocomplete open/close
  handleAutoCompleteOpen = (status: boolean) => {
    this.setState({ openAutoComplete: status });
  };

  // Handle navigation to the next page in pagination
  handleNextPageNavigation = () => {
    this.setState((curState) => {
      return {
        ...curState,
        pagination: {
          ...curState.pagination,
          curPage: curState.pagination.curPage + 1,
        }
      }
    })
  }

  // Handle navigation to the prev page in pagination
  handlePrevPageNavigation = () => {
    this.setState((curState) => {
      return {
        ...curState,
        pagination: {
          ...curState.pagination,
          curPage: curState.pagination.curPage - 1,
        }
      }
    })
  }
}
// Customizable Area End