import React from "react";
import {
  Paper,
  Grid,
  TextField,
  InputAdornment,
  Typography,
  Box,
  FormControl,
  RadioGroup,
  FormControlLabel,
  Radio,
} from "@material-ui/core";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import { useCommonStyles } from "./Styles.web";

interface Props {
  values: any;
  touched: any;
  errors: any;
  handleBlur: any;
  handleChange: any;
}

export default function QuizBasics({
  values,
  touched,
  errors,
  handleBlur,
  handleChange,
}: Props) {
  const commonClasses = useCommonStyles();
  const classes = useStyles();

  return (
    <Paper className={commonClasses.paper}>
      <Typography className={commonClasses.label} style={{ marginBottom: 10 }}>
        quiz info & settings
      </Typography>
      {/* Name, duration, pass percentage starts */}
      <Grid container spacing={2}>
        <Grid item xs={12} lg={3}>
          <label htmlFor="quizName" className={commonClasses.label}>
            quiz name
          </label>
          <br />
          <TextField
            fullWidth
            id="quizName"
            name="quizName"
            placeholder="Quiz title"
            variant="outlined"
            value={values.quizName}
            onChange={handleChange}
            onBlur={handleBlur}
            className={commonClasses.textField}
            error={touched.quizName && Boolean(errors.quizName)}
            helperText={touched.quizName && errors.quizName}
          />
        </Grid>
        <Grid item xs={12} lg={1} style={{ padding: 0 }} />
        <Grid container item xs={12} lg={8} spacing={1}>
          <Grid item xs={12} md={4}>
            <label htmlFor="numOfQuestions" className={commonClasses.label}>
              number of questions
            </label>
            <br />
            <TextField
              fullWidth
              id="numOfQuestions"
              name="numOfQuestions"
              placeholder="Number of questions"
              variant="outlined"
              value={values.numOfQuestions}
              onChange={handleChange}
              onBlur={handleBlur}
              className={commonClasses.textField}
              error={touched.numOfQuestions && Boolean(errors.numOfQuestions)}
              helperText={touched.numOfQuestions && errors.numOfQuestions}
            />
          </Grid>
          <Grid item xs={12} md={4}>
            <label htmlFor="quizDuration" className={commonClasses.label}>
              quiz time{" "}
              <span className={classes.optionalLabel}>(optional)</span>
            </label>
            <br />
            <TextField
              fullWidth
              id="quizDuration"
              name="quizDuration"
              placeholder="Quiz duration"
              variant="outlined"
              value={values.quizDuration}
              onChange={handleChange}
              onBlur={handleBlur}
              className={commonClasses.textField}
              error={touched.quizDuration && Boolean(errors.quizDuration)}
              helperText={touched.quizDuration && errors.quizDuration}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="start">min</InputAdornment>
                ),
              }}
            />
          </Grid>
          <Grid item xs={12} md={4}>
            <label htmlFor="passPercentage" className={commonClasses.label}>
              pass percentage
            </label>
            <br />
            <TextField
              fullWidth
              id="passPercentage"
              name="passPercentage"
              placeholder="Pass percentage"
              variant="outlined"
              value={values.passPercentage}
              onChange={handleChange}
              onBlur={handleBlur}
              className={commonClasses.textField}
              error={touched.passPercentage && Boolean(errors.passPercentage)}
              helperText={touched.passPercentage && errors.passPercentage}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="start">%</InputAdornment>
                ),
              }}
            />
          </Grid>
        </Grid>
      </Grid>
      {/* Name, duration, pass percentage ends */}
      {/* Fail test starts */}
      <Box style={{ marginTop: "20px" }}>
        <Typography
          className={commonClasses.label}
          style={{ textTransform: "none" }}
        >
          Pass test only if user achieves pass percentage
        </Typography>
        <FormControl component="fieldset">
          <RadioGroup
            row
            aria-label="Fail test if user gets an answer incorrect"
            name="failTest"
            value={values.failTest}
            onChange={handleChange}
          >
            <FormControlLabel
              value="yes"
              control={<Radio />}
              label="Yes"
              className={commonClasses.radio}
            />
            <FormControlLabel
              value="no"
              control={<Radio />}
              label="No"
              className={commonClasses.radio}
            />
          </RadioGroup>
        </FormControl>
      </Box>
      {/* Fail test ends */}
      {/* Show test stats starts */}
      <Box style={{ marginTop: "20px" }}>
        <Typography
          className={commonClasses.label}
          style={{ textTransform: "none" }}
        >
          Show stats after completing test
        </Typography>
        <FormControl component="fieldset">
          <RadioGroup
            row
            aria-label="Show test stats before and after"
            name="showStats"
            value={values.showStats}
            onChange={handleChange}
          >
            <FormControlLabel
              value="yes"
              control={<Radio />}
              label="Yes"
              className={commonClasses.radio}
            />
            <FormControlLabel
              value="no"
              control={<Radio />}
              label="No"
              className={commonClasses.radio}
            />
          </RadioGroup>
        </FormControl>
      </Box>
      {/* Show test stats ends */}
    </Paper>
  );
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    optionalLabel: {
      fontWeight: 400,
      fontSize: "13px",
      lineHeight: "25px",
      // color: "#1A1A1A",
      // textTransform: "capitalize",
      [theme.breakpoints.up("sm")]: {
        fontSize: "16px",
        lineHeight: "24px",
      },
    },
  })
);
