// Customizable Area Start
import React from "react";
import DashboardController, { Props } from "./DashboardController.web";
import Greetings from "./Greetings";
import Statistics from "./Statistics";
import DashboardCarousel from "../../../components/src/DashboardCarousel";
import Courses from "./Courses";
import RemindersWeb from "./Reminders.web";

export default class Dashboard extends DashboardController {
  constructor(props: Props) {
    super(props);
  }

  render() {
    const {
      courseStatus,
      courses,
      statTimeFrame,
      keyStatistics: {
        timeWatched,
        totalTimeWatched,
        completedRewards,
        totalRewards,
        courseOverallProgress,
      },
    } = this.state;

    const slicedCourses = courses.slice(0, 4);
    return (
      <>
        <Greetings />
        <DashboardCarousel />
        <Statistics
          timeFrame={statTimeFrame}
          timeWatched={timeWatched}
          totalTimeWatched={totalTimeWatched}
          completedRewards={completedRewards}
          totalRewards={totalRewards}
          courseOverallProgress={courseOverallProgress}
          handleStatTimeFrameChange={this.handleStatTimeFrameChange}
        />

        <Courses
          isLoading={this.state.loading}
          courseStatus={courseStatus}
          courses={slicedCourses}
          handleCourseStatusChange={this.handleCourseStatusChange}
        />
        <RemindersWeb
          userRole="user"
          fullScreen={this.props.fullScreen}
          navigation={this.props.navigation}
        />
      </>
    );
  }
}

// Customizable Area End
