import React from "react";
import Carousel from "react-material-ui-carousel";
import { Button, Box, Typography } from "@material-ui/core";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import RemoveIcon from "@material-ui/icons/Remove";
import useWindowDimensions from "../Hooks/useWindowDimensions";
import { BannerImg } from "./assets";

interface Props {
  title: string;
  caption: string;
  buttonText: string;
}

const BANNER_LIST = [
  {
    title: "Sample Banner #1",
    caption: "Caption #1",
    ctaButtonText: "CTA Button #1",
    ctaButtonLink: "",
  },
  {
    title: "Sample Banner #2",
    caption: "Caption #2",
    ctaButtonText: "CTA Button #2",
    ctaButtonLink: "",
  },
  {
    title: "Sample Banner #3",
    caption: "Caption #3",
    ctaButtonText: "CTA Button #3",
    ctaButtonLink: "",
  },
];

function Banner({ title, caption, buttonText }: Props) {
  const classes = useStyles();

  return (
    <Box className={classes.banner}>
      <Box className={classes.content}>
        <Typography className={classes.title}>{title}</Typography>
        <Typography className={classes.caption}>{caption}</Typography>
        <Button variant="contained" className={classes.ctaBtn}>
          {buttonText}
        </Button>
      </Box>
    </Box>
  );
}

export default function DashboardCarousel() {
  const classes = useStyles();
  const { width } = useWindowDimensions();

  return (
    <Carousel
      autoPlay={false}
      animation="slide"
      navButtonsAlwaysVisible={width > 600}
      swipe={true}
      IndicatorIcon={<RemoveIcon className={classes.indicatorIcon} />}
      navButtonsProps={{
        className: classes.navButton,
      }}
      indicatorIconButtonProps={{
        className: classes.indicatorIconButton,
      }}
      activeIndicatorIconButtonProps={{
        className: classes.activeIndicatorIconButton,
      }}
      indicatorContainerProps={{
        className: classes.indicatorContainer,
      }}
    >
      {BANNER_LIST.map((item, i) => (
        <Banner
          key={i}
          title={item.title}
          caption={item.caption}
          buttonText={item.ctaButtonText}
        />
      ))}
    </Carousel>
  );
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    banner: {
      backgroundImage: `url(${BannerImg})`,
      backgroundPosition: "center center",
      backgroundSize: "cover",
      borderRadius: "24px",
    },
    content: {
      padding: "50px 0",
      marginLeft: "20px",
      [theme.breakpoints.up("sm")]: {
        marginLeft: "90px",
      },
    },
    title: {
      fontStyle: "normal",
      fontWeight: 600,
      fontSize: " 40px",
      lineHeight: " 49px",
      letterSpacing: "-1px",
      color: "#FFFFFF",
    },
    caption: {
      fontStyle: "normal",
      fontWeight: 700,
      fontSize: "14px",
      lineHeight: "24px",
      letterSpacing: "-1px",
      color: "rgba(255, 255, 255, 0.699929)",
      margin: "10px 0 20px 0",
    },
    ctaBtn: {
      filter: "drop-shadow(0px 4px 16px rgba(0, 0, 0, 0.250929))",
      fontWeight: 700,
      fontSize: "14px",
      lineHeight: "20px",
      textAlign: "center",
      background: "#FFFFFF",
      color: "#11142D",
      borderRadius: "16px",
      textTransform: "capitalize",
      padding: "10px",
      [theme.breakpoints.up("sm")]: {
        padding: "18px 48px",
      },
    },
    navButton: {
      padding: "5px",
      backgroundColor: "#000",
    },
    indicatorIconButton: {
      color: "rgba(187, 184, 184, 0.452522)",
      margin: "-5px",
      "&:hover": {
        backgroundColor: "inherit",
      },
    },
    activeIndicatorIconButton: {
      color: "#FFFFFF",
      margin: "-5px",
    },
    indicatorContainer: {
      zIndex: 1,
      marginTop: "-38px",
      position: "relative",
      [theme.breakpoints.up("sm")]: {
        marginTop: "-45px",
      },
    },
    indicatorIcon: {
      fontSize: "50px",
      [theme.breakpoints.up("sm")]: {
        fontSize: "70px",
      },
    },
  })
);
