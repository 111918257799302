// Customizable Area Start
import React, { createRef, LegacyRef } from "react";
import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import ReactPlayer from "react-player";
import { apiCall } from "../../../framework/src/Utilities";
import { toast } from "react-toastify";

export interface Props {
  navigation: any;
  history: any;
  location: any;
  classes: any;
}

interface S {
  data: any;
  isLoading: boolean;
  fullScreenPdf: boolean;
  openNotesModal: boolean;
}

interface SS {
  id: any;
}

const configJSON = require("./config.js");

export default class ObjectDetailsController extends BlockComponent<
  Props,
  S,
  SS
> {
  apiGetObjectDetailsCallId: string = "";
  myRef: LegacyRef<ReactPlayer> | undefined;

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
    this.myRef = createRef();
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionSaveMessage),
      getName(MessageEnum.SessionResponseMessage),
    ];

    this.state = {
      isLoading: false,
      data: {},
      fullScreenPdf: false,
      openNotesModal: false,
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async componentDidMount(): Promise<void> {
    this.getObjectDetails();
  }

  componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<S>, snapshot?: SS | undefined): void {
    // Fetch object details if object id in the url changes
    if (
      prevProps.navigation.getParam("objectId") !==
      this.props.navigation.getParam("objectId")
    ) {
      this.getObjectDetails();
    }    
  }

  async receive(from: string, message: Message) {
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );
      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      // Receive api responses
      if (apiRequestCallId === this.apiGetObjectDetailsCallId) {
        this.handleGetObjectDetails(responseJson);
      }
    }
  }

  // Get Object details
  getObjectDetails = async () => {
    this.setState({ isLoading: true });
    const programId = this.props.navigation.getParam("id");
    const objectId = this.props.navigation.getParam("objectId");
    this.apiGetObjectDetailsCallId = await apiCall({
      contentType: configJSON.apiContentTypeAppJson,
      method: configJSON.getApiMethod,
      endPoint: `${
        configJSON.getObjectDetailsApiEndPoint
      }?program_id=${programId}&id=${objectId}`,
    });
  };

  // Handle object details api response
  handleGetObjectDetails = (response: any) => {
    if (!response?.data) {
      this.setState({ isLoading: false });
      toast.error("Something went wrong");
      return;
    }
    this.setState({
      isLoading: false,
      data: response?.data?.attributes,
    });
  };

  handleCourseNavigation = (course: {id: number, title: string}) => {
    this.props.history.push(
      `/course-detail/${this.props.navigation.getParam("type")}/${
        course.id
      }/${course.title.replace(/\s/g, "-")}`,
      { program: this.state.data?.id },
    );
  };
  
  handleObjectNavigation = (objectId: number) => {
    const { pathname } = this.props.location;
    const { history } = this.props;

    const splittedPathName = pathname.split("/");
    splittedPathName.splice(-2);
    const newPathName = splittedPathName.join("/");
    history.push(
      `${newPathName}/objects/${objectId}`,
    );
  };

  handleFullScreenPdfToggle = (status: boolean) => {
    this.setState({ fullScreenPdf: status });
  }

  downloadPdf = () => {
    fetch(this.state.data?.attachment?.url, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/pdf',
      },
    })
    .then((response) => response.blob())
    .then((blob) => {
      // Create blob link to download
      const url = window.URL.createObjectURL(
        new Blob([blob]),
      );
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute(
        'download',
        this.state.data?.attachment?.file_name,
      );

      // Append to html link element page
      document.body.appendChild(link);

      // Start download
      link.click();

      // Clean up and remove the link
      link?.parentNode?.removeChild(link);
    });
  }

  // Handle close/open notes modal
  handleOpenNotesModal = (openNotesModal: boolean) => {
    this.setState({ openNotesModal });
  }
}
// Customizable Area End
