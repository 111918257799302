Object.defineProperty(exports, "__esModule", {
    value: true
});

// Customizable Area Start
exports.productApiContentType = "application/json";
exports.apiMethodTypeGet = "GET";
exports.productAPiEndPoint = "catalogue/catalogues";
exports.productAPiEndPoint1 = "bx_block_usergroups/channels/my_channel_dashboard";
exports.channelDetailEndPoint = "bx_block_usergroups/channels/";
exports.recomendedCategoryEndPoint = "bx_block_usergroups/channels/recommended_channel"
exports.allRecomendedCategoryEndPoint = "bx_block_usergroups/channels/all_recommended_channel"
exports.personalCategoryEndPoint = "bx_block_usergroups/channels/my_channel_category_wise"
//exports.productAPiEndPoint = "catalogue/catalogues";
exports.back = "Back";
exports.myChannel = "My Channels";
exports.personalChannels = "Personal Channels";
exports.assignedChannels = "Assigned Channels";
exports.RecomendedChannels = "Recommended for you";
exports.viewAll = "View All"
exports.RecomendedCategory = "Recommended";
exports.createWriting = "Creative Writing"
exports.channelDesc = "Channel Description";
exports.channelContent = "Channel Content"
exports.createdBy = "created By"
exports.category = "category"
exports.personal = "Personal"
exports.lorem = "Lorem Ipsum is a dummy or placeholder textIt's often used in laying out print,In layman's terms, Lorem Ipsum is a dummy or placeholder textIt's often used in laying out print, infographics, or web design"
exports.demo = "assets/b6662536892a73acf765efdf133aaead0cf7b054.png";
exports.itemImg = "assets/office.png";
// Customizable Area End
