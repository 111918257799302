// App.js - WEB
import React, { Component } from 'react';
import CssBaseline from '@material-ui/core/CssBaseline';
import { createTheme, ThemeProvider } from '@material-ui/core/styles';
import { View } from 'react-native';
import firebase from 'firebase';
import { connect } from 'react-firebase';
import montserrat from 'typeface-montserrat';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import "./styles.css";

import WebRoutesGenerator from '../../components/src/NativeWebRouteWrapper';
import { ModalContainer } from 'react-router-modal';
import { Switch, Route } from 'react-router-dom';
import HomeScreen from '../../components/src/HomeScreen';

import InfoPage from '../../blocks/info-page/src/InfoPageBlock';
import AlertBlock from '../../blocks/alert/src/AlertBlock.web';
import VisualAnalytics from '../../blocks/visualanalytics/src/VisualAnalytics';
import PaidContent from '../../blocks/PaidContent/src/PaidContent';
import AutomaticCheckoutCalculation2 from '../../blocks/AutomaticCheckoutCalculation2/src/AutomaticCheckoutCalculation2';
import VideoLibrary from '../../blocks/VideoLibrary/src/VideoLibrary';
import ShopifyIntegration11 from '../../blocks/ShopifyIntegration11/src/ShopifyIntegration11';
import Ordermanagement from '../../blocks/ordermanagement/src/Ordermanagement';
import OrderDetails from '../../blocks/ordermanagement/src/OrderDetails';
import TaskAllocator from '../../blocks/TaskAllocator/src/TaskAllocator';
import CarouselDisplay from '../../blocks/carouseldisplay/src/CarouselDisplay';
import Catalogue from '../../blocks/catalogue/src/Catalogue';
import CertificationTracking from '../../blocks/CertificationTracking/src/CertificationTracking';
import ContentManagement from '../../blocks/ContentManagement/src/ContentManagement';
import CountryCodeSelector from '../../blocks/country-code-selector/src/CountryCodeSelector';
import CourseCreation from '../../blocks/CourseCreation/src/CourseCreation';
import CustomisableUserProfiles from '../../blocks/CustomisableUserProfiles/src/CustomisableUserProfiles';
import AllNoteList from '../../blocks/CustomisableUserProfiles/src/AllNoteList.web';
import AddNoteModal from '../../blocks/CustomisableUserProfiles/src/AddNoteModal.web';
import CourseNotes from '../../blocks/CustomisableUserProfiles/src/CourseNotes.web';
import Dashboard from '../../blocks/dashboard/src/Dashboard.web';
import SocialMediaAccountLoginScreen from '../../blocks/social-media-account-login/src/SocialMediaAccountLoginScreen';
import SplitPayments from '../../blocks/SplitPayments/src/SplitPayments';
import QuestionBank from '../../blocks/QuestionBank/src/QuestionBank';
import OrganisationHierarchy from '../../blocks/OrganisationHierarchy/src/OrganisationHierarchy';
import TicketspiceIntegration2 from '../../blocks/TicketspiceIntegration2/src/TicketspiceIntegration2';
import BreadcrumbNavigation from '../../blocks/BreadcrumbNavigation/src/BreadcrumbNavigation';
import PricingEngine2 from '../../blocks/PricingEngine2/src/PricingEngine2';
import Documentation from '../../blocks/Documentation/src/Documentation';
import DataImportexportcsv from '../../blocks/DataImportexportcsv/src/DataImportexportcsv';
import ShoppingCartOrders2 from '../../blocks/shoppingcart/src/ShoppingCartOrders2';
import AddShoppingCartOrderItem from '../../blocks/shoppingcart/src/AddShoppingCartOrderItem';
import ProjectTemplates from '../../blocks/ProjectTemplates/src/ProjectTemplates';
import ProjecttaskTracking2 from '../../blocks/ProjecttaskTracking2/src/ProjecttaskTracking2';
import OTPInputAuth from '../../blocks/otp-input-confirmation/src/OTPInputAuth';
import UserGroups from '../../blocks/UserGroups/src/UserGroups';
import RolesPermissions2 from '../../blocks/RolesPermissions2/src/RolesPermissions2';
import Payments from '../../blocks/Payments/src/Payments';
import Reviews from '../../blocks/reviews/src/Reviews';
import AddReview from '../../blocks/reviews/src/AddReview';
import Videos from '../../blocks/videos/src/Videos';
import StripeGatewayApifrontend from '../../blocks/StripeGatewayApifrontend/src/StripeGatewayApifrontend';
import Notes from '../../blocks/Notes/src/Notes';
import PromoteContent from '../../blocks/PromoteContent/src/PromoteContent';
import Customform from '../../blocks/customform/src/Customform';
import GroupChat from '../../blocks/GroupChat/src/GroupChat';
import Pushnotifications from '../../blocks/pushnotifications/src/Pushnotifications';
import TwilioIntegration2 from '../../blocks/TwilioIntegration2/src/TwilioIntegration2';
import VideoAds from '../../blocks/VideoAds/src/VideoAds';
import ForgotPassword from '../../blocks/forgot-password/src/ForgotPassword';
import ForgotPasswordOTP from '../../blocks/forgot-password/src/ForgotPasswordOTP';
import NewPassword from '../../blocks/forgot-password/src/NewPassword';
import DeepLinking from '../../blocks/DeepLinking/src/DeepLinking';
import Notifications from '../../blocks/notifications/src/Notifications';
import DocumentOpener from '../../blocks/documentopener/src/DocumentOpener';
import LiveStreaming from '../../blocks/LiveStreaming/src/LiveStreaming';
import Playlist4 from '../../blocks/Playlist4/src/Playlist4';
import DynamicContent from '../../blocks/DynamicContent/src/DynamicContent';
import Analytics from '../../blocks/analytics/src/Analytics';
import Customisableusersubscriptions from '../../blocks/customisableusersubscriptions/src/Customisableusersubscriptions';
import SubscriptionDetails from '../../blocks/customisableusersubscriptions/src/SubscriptionDetails';
import Filteritems from '../../blocks/filteritems/src/Filteritems';
import Filteroptions from '../../blocks/filteritems/src/Filteroptions';
import FormApprovalWorkflow from '../../blocks/FormApprovalWorkflow/src/FormApprovalWorkflow';
import Referrals from '../../blocks/Referrals/src/Referrals';
import AdminConsole3 from '../../blocks/AdminConsole3/src/AdminConsole3';
import Settings5 from '../../blocks/Settings5/src/Settings5';
import Library2 from '../../blocks/Library2/src/Library2';
import CustomAdvertisements from '../../blocks/CustomAdvertisements/src/CustomAdvertisements';
import BulkUploading from '../../blocks/BulkUploading/src/BulkUploading';
import AutomaticRenewals from '../../blocks/automaticrenewals/src/AutomaticRenewals';
import ThemeBlock from '../../blocks/themeblock/src/ThemeBlock';
import Scheduling from '../../blocks/scheduling/src/Scheduling';
import Categoriessubcategories from '../../blocks/categoriessubcategories/src/Categoriessubcategories';
import LeadManagement from '../../blocks/LeadManagement/src/LeadManagement';
import PhoneNumberInput from '../../blocks/mobile-account-registration/src/PhoneNumberInput';
import AdditionalDetailForm from '../../blocks/mobile-account-registration/src/AdditionalDetailForm';
import SocialMediaAccountRegistrationScreen from '../../blocks/social-media-account-registration/src/SocialMediaAccountRegistrationScreen';
import AutomaticReminders from '../../blocks/AutomaticReminders/src/AutomaticReminders';
import Sorting from '../../blocks/sorting/src/Sorting';
import TeamBuilder from '../../blocks/TeamBuilder/src/TeamBuilder';
import Notificationsettings from '../../blocks/notificationsettings/src/Notificationsettings';
import PremiumPlanComparison from '../../blocks/PremiumPlanComparison/src/PremiumPlanComparison';
import HamburgerMenu from '../../blocks/HamburgerMenu/src/HamburgerMenu';
import EmailAccountRegistration from '../../blocks/email-account-registration/src/EmailAccountRegistration';
import VideoEmbeddingYoutube from '../../blocks/VideoEmbeddingYoutube/src/VideoEmbeddingYoutube';
import SwitchAccounts from '../../blocks/SwitchAccounts/src/SwitchAccounts';
import Splashscreen from '../../blocks/splashscreen/src/TermPage.web';
import WelcomeSplashscreen from '../../blocks/splashscreen/src/WelcomeSplashscreen.web';
import TargetedFeed from '../../blocks/TargetedFeed/src/TargetedFeed';
import Onboardingguide from '../../blocks/onboardingguide/src/Onboardingguide';
import ElasticSearch from '../../blocks/ElasticSearch/src/ElasticSearch';
import AdManager from '../../blocks/AdManager/src/AdManager';
import EmailAccountLoginBlock from '../../blocks/email-account-login/src/EmailAccountLoginBlock';
import LiveChat2 from '../../blocks/LiveChat2/src/LiveChat2';
import DragDropInterface from '../../blocks/dragdropinterface/src/DragDropInterface';
import InappPurchasing from '../../blocks/InappPurchasing/src/InappPurchasing';
import EmailNotifications from '../../blocks/EmailNotifications/src/EmailNotifications';
import UserGroups2 from '../../blocks/UserGroups2/src/UserGroups2';
import OnboardingguideWeb from '../../blocks/onboardingguide/src/OnboardingCategory.web';
import CreateQuiz from '../../blocks/QuestionBank/src/CreateQuiz.web';
import DummyQuizListing from '../../blocks/QuestionBank/src/DummyQuizListing';
import Search from '../../blocks/Library2/src/Search.web';
// web library
import Library2Web from '../../blocks/Library2/src/Library2.web';
// course
import CoursePage from '../../blocks/Library2/src/CoursePage.web';
//program page
import ProgramPage from '../../blocks/Library2/src/ProgramPage.web';
//phase program
import PhaseProgram from '../../blocks/Library2/src/PhaseProgram.web';
//title page
import TitlePage from '../../blocks/Library2/src/TiltePage.web';
//personal library
import PersonalLibrary from '../../blocks/Library2/src/PersonalLibrary.web';
//xpand library
import XpandLibrary from '../../blocks/Library2/src/XpandLibrary.web';
//CourseCompletion
import CourseCompletion from '../../blocks/Library2/src/CourseCompletion.web';
import InProgress from '../../blocks/Library2/src/InProgress.web';
import NewlyAdded from '../../blocks/Library2/src/NewlyAdded.web';
import Completed from '../../blocks/Library2/src/Completed.web';

import EmailAccountRegistrationWeb from '../../blocks/email-account-registration/src/EmailAccountRegistration.web';
import EmailAccountLoginBlockWeb from '../../blocks/email-account-login/src/EmailAccountLoginBlock.web';
import ForgotPasswordWeb from '../../blocks/forgot-password/src/ForgotPassword.web';
import ResetPasswordWeb from '../../blocks/forgot-password/src/ResetPassword.web';
import OnboardingCategoryWeb from '../../blocks/onboardingguide/src/OnboardingCategory.web';
import CategoryWeb from '../../blocks/categoriessubcategories/src/Category.web.tsx';
import VideoWeb from '../../blocks/videos/src/video.web';
import VideoAddWeb from '../../blocks/videos/src/videoAdd.web';
import BuildCourse from '../../blocks/CourseCreation/src/BuldCourse.web';
import AddPreview from '../../blocks/CourseCreation/src/AddPreview.web';
import AddReward from '../../blocks/CourseCreation/src/AddReward.web';
// import InsightOverview from '../../blocks/CustomisableUserProfiles/src/InsightOverview.web'
// import Reports from '../../blocks/CustomisableUserProfiles/src/Reports.web'
import CourseReports from '../../blocks/CustomisableUserProfiles/src/CourseReports.web';
import AdminCustomisableUserProfiles from '../../blocks/CustomisableUserProfiles/src/AdminCustomisableUserProfiles.web';
import ManageContentLibrarylist from '../../blocks/ContentManagement/src/ManageContentLibrarylist.web';
import ManageContentFolder from '../../blocks/ContentManagement/src/ManageContentFolder.web';
import ManageThumbnailLibrary from '../../blocks/ContentManagement/src/ManageThumbnailLibrary.web';
import ManageThumbnailUser from '../../blocks/ContentManagement/src/ManageThumbnailUser.web';
import ManageVideoCourse from '../../blocks/ContentManagement/src/ManageVideoCourse.web';
import Subscription from '../../blocks/shoppingcart/src/Subscription.web';

import InsightOverview from '../../blocks/CustomisableUserProfiles/src/InsightOverview.web';
import Reports from '../../blocks/CustomisableUserProfiles/src/Reports.web';
import AddAchievement from '../../blocks/CourseCreation/src/AddAchievement.web';
import IconLibraryWeb from '../../blocks/CourseCreation/src/IconLibrary.web';
import AddFolderWeb from '../../blocks/CourseCreation/src/AddFolder.web';
import AddIconWeb from '../../blocks/CourseCreation/src/AddIcon.web';
import RewardListWeb from '../../blocks/CourseCreation/src/RewardList.web';
import AchievementsListWeb from '../../blocks/CourseCreation/src/AchievementsList.web';
// import UserInviteWeb from '../../blocks/email-account-login/src/UserInvite.web';
import AssignContent from '../../blocks/email-account-login/src/AssignContent.web';
import UserManagementWeb from '../../blocks/UserGroups2/src/web/UserManagement';
import CreateUser from '../../blocks/UserGroups2/src/web/UserManagement/CreateUser';
import UserSendInviteWeb from '../../blocks/email-account-login/src/UserSendInvite.web';
import ProgramCreationWeb from '../../blocks/CourseCreation/src/ProgramCreation.web';
import BuildProgramWeb from '../../blocks/CourseCreation/src/BuildProgram.web';
import AddObjectWeb from '../../blocks/CourseCreation/src/AddObject.web';
import ContentRoleList from '../../blocks/CourseCreation/src/ManageContentRole.web';
import ManageContentRoleList from '../../blocks/CourseCreation/src/ManageContentRoleList.web';

import AdminDashboard from '../../blocks/dashboard/src/AdminDashboard.web';
import Addvideo from '../../blocks/ContentManagement/src/Addvideo.web';
import ExistingCourseList from '../../blocks/CourseCreation/src/ExistingCourseList.web'
import Editvideo from '../../blocks/ContentManagement/src/Editvideo.web';
import NotFoundPage from '../../components/src/NotFoundPage';
import ObjectDetails from '../../blocks/Library2/src/ObjectDetails.web';

//Achievements
import AchievementListing from '../../blocks/UserGroups/src/AchievementListing.web';
import AchievementDetails from '../../blocks/UserGroups/src/AchievementDetails.web';
import AllRewardsListing from '../../blocks/UserGroups/src/AllRewardsListing.web';
import AllRewardsDetails from '../../blocks/UserGroups/src/AllRewardsDetails.web';
import AchievementCongratulations from '../../blocks/UserGroups/src/ AchievementCongratulations.web';

const routeMap = {
  UserGroups2: {
    path: '/UserGroups2'
  },
  VisualAnalytics: {
    component: VisualAnalytics,
    path: '/VisualAnalytics'
  },
  CustomisableUserProfiles: {
    component: CustomisableUserProfiles,
    path: '/CustomisableUserProfiles',
    roles: ['user']
  },
  AllNoteList: {
    component: AllNoteList,
    path: '/AllNoteList',
    roles: ['user']
  },
  AddNoteModal: {
    component: AddNoteModal,
    path: '/AddNoteModal',
    roles: ['user']
  },
  CourseNotes: {
    component: CourseNotes,
    path: '/CourseNotes',
    roles: ['user']
  },
  InsightOverview: {
    component: InsightOverview,
    path: '/InsightOverview',
    roles: ['user']
  },
  Reports: {
    component: Reports,
    path: '/Reports',
    roles: ['user']
  },
  CourseReports: {
    component: CourseReports,
    path: '/CourseReports',
    roles: ['user']
  },
  Subscription: {
    component: Subscription,
    path: '/Subscription',
    roles: ['user']
  },
  AssignContent: {
    component: AssignContent,
    path: '/AssignContent',
    roles: ['user']
  },
  PaidContent: {
    component: PaidContent,
    path: '/PaidContent'
  },
  AutomaticCheckoutCalculation2: {
    component: AutomaticCheckoutCalculation2,
    path: '/AutomaticCheckoutCalculation2'
  },
  VideoLibrary: {
    component: VideoLibrary,
    path: '/VideoLibrary'
  },
  ShopifyIntegration11: {
    component: ShopifyIntegration11,
    path: '/ShopifyIntegration11'
  },
  Ordermanagement: {
    component: Ordermanagement,
    path: '/Ordermanagement'
  },
  OrderDetails: {
    component: OrderDetails,
    path: '/OrderDetails'
  },
  TaskAllocator: {
    component: TaskAllocator,
    path: '/TaskAllocator'
  },
  CarouselDisplay: {
    component: CarouselDisplay,
    path: '/CarouselDisplay'
  },
  SocialMediaAccountLoginScreen: {
    component: SocialMediaAccountLoginScreen,
    path: '/SocialMediaAccountLoginScreen'
  },
  SplitPayments: {
    component: SplitPayments,
    path: '/SplitPayments'
  },
  QuestionBank: {
    component: QuestionBank,
    path: '/QuestionBank'
  },
  OrganisationHierarchy: {
    component: OrganisationHierarchy,
    path: '/OrganisationHierarchy'
  },
  TicketspiceIntegration2: {
    component: TicketspiceIntegration2,
    path: '/TicketspiceIntegration2'
  },
  BreadcrumbNavigation: {
    component: BreadcrumbNavigation,
    path: '/BreadcrumbNavigation'
  },
  PricingEngine2: {
    component: PricingEngine2,
    path: '/PricingEngine2'
  },
  Documentation: {
    component: Documentation,
    path: '/Documentation'
  },
  DataImportexportcsv: {
    component: DataImportexportcsv,
    path: '/DataImportexportcsv'
  },
  ShoppingCartOrders: {
    component: ShoppingCartOrders2,
    path: '/cart',
    roles: ['user']
  },
  AddShoppingCartOrderItem: {
    component: AddShoppingCartOrderItem,
    path: '/AddShoppingCartOrderItem'
  },
  ProjectTemplates: {
    component: ProjectTemplates,
    path: '/ProjectTemplates'
  },
  ProjecttaskTracking2: {
    component: ProjecttaskTracking2,
    path: '/ProjecttaskTracking2'
  },
  OTPInputAuth: {
    component: OTPInputAuth,
    path: '/OTPInputAuth'
  },
  UserGroups: {
    component: UserGroups,
    path: '/UserGroups'
  },
  RolesPermissions2: {
    component: RolesPermissions2,
    path: '/RolesPermissions2'
  },
  Payments: {
    component: Payments,
    path: '/Payments'
  },
  Reviews: {
    component: Reviews,
    path: '/Reviews'
  },
  AddReview: {
    component: AddReview,
    path: '/AddReview',
    roles: ['expert', 'admin', 'super_admin']
  },
  Videos: {
    component: VideoWeb,
    path: '/videos',
    roles: ['expert', 'admin', 'super_admin']
  },
  IconLibrary: {
    component: IconLibraryWeb,
    path: '/iconLibrary',
    roles: ['expert', 'admin', 'super_admin']
  },
  UserManagement: {
    component: UserManagementWeb,
    path: '/usermanagement',
    exact: true,
    roles: ['expert', 'admin', 'super_admin']
  },
  CreateUser: {
    component: CreateUser,
    path: '/usermanagement/create-user',
    roles: ['expert', 'admin', 'super_admin']
  },
  AddFolder: {
    component: AddFolderWeb,
    path: '/addFolder',
    roles: ['expert', 'admin', 'super_admin']
  },
  VideoAdd: {
    component: VideoAddWeb,
    path: '/VideoAdd/:id',
    roles: ['expert', 'admin', 'super_admin']
  },
  StripeGatewayApifrontend: {
    component: StripeGatewayApifrontend,
    path: '/StripeGatewayApifrontend'
  },
  Notes: {
    component: Notes,
    path: '/Notes'
  },
  PromoteContent: {
    component: PromoteContent,
    path: '/PromoteContent'
  },
  Customform: {
    component: Customform,
    path: '/Customform'
  },
  GroupChat: {
    component: GroupChat,
    path: '/GroupChat'
  },
  Pushnotifications: {
    component: Pushnotifications,
    path: '/Pushnotifications'
  },
  TwilioIntegration2: {
    component: TwilioIntegration2,
    path: '/TwilioIntegration2'
  },
  VideoAds: {
    component: VideoAds,
    path: '/VideoAds'
  },
  ForgotPassword: {
    component: ForgotPasswordWeb,
    path: '/forgotpassword'
  },
  ForgotPasswordOTP: {
    component: ForgotPasswordOTP,
    path: '/ForgotPasswordOTP'
  },
  NewPassword: {
    component: ResetPasswordWeb,
    path: '/reset_password/:token'
  },
  DeepLinking: {
    component: DeepLinking,
    path: '/DeepLinking'
  },
  Notifications: {
    component: Notifications,
    path: '/Notifications'
  },
  DocumentOpener: {
    component: DocumentOpener,
    path: '/DocumentOpener'
  },
  LiveStreaming: {
    component: LiveStreaming,
    path: '/LiveStreaming'
  },
  Playlist4: {
    component: Playlist4,
    path: '/Playlist4'
  },
  DynamicContent: {
    component: DynamicContent,
    path: '/DynamicContent'
  },
  Analytics: {
    component: Analytics,
    path: '/Analytics'
  },
  Customisableusersubscriptions: {
    component: Customisableusersubscriptions,
    path: '/Customisableusersubscriptions'
  },
  SubscriptionDetails: {
    component: SubscriptionDetails,
    path: '/SubscriptionDetails'
  },
  Filteritems: {
    component: Filteritems,
    path: '/Filteritems'
  },
  Filteroptions: {
    component: Filteroptions,
    path: '/Filteroptions'
  },
  FormApprovalWorkflow: {
    component: FormApprovalWorkflow,
    path: '/FormApprovalWorkflow'
  },
  Referrals: {
    component: Referrals,
    path: '/Referrals'
  },
  AdminConsole3: {
    component: AdminConsole3,
    path: '/AdminConsole3'
  },
  Settings5: {
    component: Settings5,
    path: '/Settings5'
  },
  Library2: {
    component: Library2,
    path: '/Library2'
  },
  CustomAdvertisements: {
    component: CustomAdvertisements,
    path: '/CustomAdvertisements'
  },
  BulkUploading: {
    component: BulkUploading,
    path: '/BulkUploading'
  },
  AutomaticRenewals: {
    component: AutomaticRenewals,
    path: '/AutomaticRenewals'
  },
  ThemeBlock: {
    component: ThemeBlock,
    path: '/ThemeBlock'
  },
  Scheduling: {
    component: Scheduling,
    path: '/Scheduling'
  },
  Categoriessubcategories: {
    component: CategoryWeb,
    path: '/Categoriessubcategories'
  },
  ContentManagement: {
    component: ContentManagement,
    path: '/ContentManagement',
    roles: ['expert', 'admin', 'super_admin']
  },
  ExistingCourseList:{  
    component:ExistingCourseList,
    path:'/ExistingCourseList',
    roles: ['expert', 'admin', 'super_admin']
  },
  CertificationTracking: {
    component: CertificationTracking,
    path: '/CertificationTracking'
  },
  CountryCodeSelector: {
    component: CountryCodeSelector,
    path: '/CountryCodeSelector'
  },
  LeadManagement: {
    component: LeadManagement,
    path: '/LeadManagement'
  },
  PhoneNumberInput: {
    component: PhoneNumberInput,
    path: '/PhoneNumberInput'
  },
  AdditionalDetailForm: {
    component: AdditionalDetailForm,
    path: '/AdditionalDetailForm'
  },
  SocialMediaAccountRegistrationScreen: {
    component: SocialMediaAccountRegistrationScreen,
    path: '/SocialMediaAccountRegistrationScreen'
  },
  AutomaticReminders: {
    component: AutomaticReminders,
    path: '/AutomaticReminders'
  },
  Sorting: {
    component: Sorting,
    path: '/Sorting'
  },
  TeamBuilder: {
    component: TeamBuilder,
    path: '/TeamBuilder'
  },
  Catalogue: {
    component: Catalogue,
    path: '/Catalogue'
  },
  Notificationsettings: {
    component: Notificationsettings,
    path: '/Notificationsettings'
  },
  PremiumPlanComparison: {
    component: PremiumPlanComparison,
    path: '/PremiumPlanComparison'
  },
  HamburgerMenu: {
    component: HamburgerMenu,
    path: '/HamburgerMenu'
  },
  EmailAccountRegistration: {
    component: EmailAccountRegistrationWeb,
    path: '/signup'
  },
  VideoEmbeddingYoutube: {
    component: VideoEmbeddingYoutube,
    path: '/VideoEmbeddingYoutube'
  },
  Dashboard: {
    component: Dashboard,
    path: '/Dashboard',
    roles: ['user']
  },
  SwitchAccounts: {
    component: SwitchAccounts,
    path: '/SwitchAccounts'
  },
  Splashscreen: {
    component: Splashscreen,
    path: '/terms',
    roles: ['user']
  },
  TargetedFeed: {
    component: TargetedFeed,
    path: '/TargetedFeed'
  },
  Onboardingguide: {
    component: OnboardingguideWeb,
    path: '/Onboardingguide'
  },
  ElasticSearch: {
    component: ElasticSearch,
    path: '/ElasticSearch'
  },
  AdManager: {
    component: AdManager,
    path: '/AdManager'
  },
  EmailAccountLoginBlock: {
    component: EmailAccountLoginBlockWeb,
    path: '/login'
  },
  WelcomeSplashscreen: {
    component: WelcomeSplashscreen,
    path: '/WelcomeSplashscreen',
    roles: ['user']
  },
  LiveChat2: {
    component: LiveChat2,
    path: '/LiveChat2'
  },
  CourseCreation: {
    component: CourseCreation,
    path: '/CourseCreation/:id/:type',
    roles: ['expert', 'admin', 'super_admin']
  },
  ProgramCreation: {
    component: ProgramCreationWeb,
    path: '/ProgramCreation/:id/:type',
    roles: ['expert', 'admin', 'super_admin']
  },
  BuildCourse: {
    component: BuildCourse,
    path: '/BuildCourse/:id',
    roles: ['expert', 'admin', 'super_admin']
  },
  BuildProgram: {
    component: BuildProgramWeb,
    path: '/BuildProgram/:id',
    roles: ['expert', 'admin', 'super_admin']
  },
  AddPreview: {
    component: AddPreview,
    path: '/AddPreview',
    roles: ['expert', 'admin', 'super_admin']
  },
  AddReward: {
    component: AddReward,
    path: '/AddReward',
    roles: ['expert', 'admin', 'super_admin']
  },
  AddAchievement: {
    component: AddAchievement,
    path: '/AddAchievement',
    roles: ['expert', 'admin', 'super_admin']
  },
  AddIcon: {
    component: AddIconWeb,
    path: '/AddIcon',
    roles: ['expert', 'admin', 'super_admin']
  },
  AddObject: {
    component: AddObjectWeb,
    path: '/AddObject/:id',
    roles: ['expert', 'admin', 'super_admin']
  },
  RewardList: {
    component: RewardListWeb,
    path: '/RewardList',
    roles: ['expert', 'admin', 'super_admin']
  },
  AchievementsList: {
    component: AchievementsListWeb,
    path: '/AchievementsList',
    roles: ['expert', 'admin', 'super_admin']
  },
  ContentRoleList: {
    component: ContentRoleList,
    path: '/ContentRoleList',
    roles: ['expert', 'admin', 'super_admin']
  },
  ManageContentRoleList: {
    component: ManageContentRoleList,
    path: '/ManageContentRoleList/:id',
    roles: ['expert', 'admin', 'super_admin']
  },
  UserSendInvite: {
    component: UserSendInviteWeb,
    path: '/UserSendInvite',
    roles: ['expert', 'admin', 'super_admin']
  },
  DragDropInterface: {
    component: DragDropInterface,
    path: '/DragDropInterface'
  },
  InappPurchasing: {
    component: InappPurchasing,
    path: '/InappPurchasing'
  },
  EmailNotifications: {
    component: EmailNotifications,
    path: '/EmailNotifications'
  },

  Home: {
    component: Dashboard,
    path: '/',
    exact: true,
    roles: ['user']
  },
  InfoPage: {
    component: InfoPage,
    path: '/InfoPage'
  },

  AlertWeb: {
    component: AlertBlock,
    path: '*/AlertWeb',
    modal: true
  },

  Library2WebPage: {
    component: Library2Web,
    path: '/library',
    roles: ['user']
  },
  SearchWebPage: {
    component: Search,
    path: '/Search',
    roles: ['user']
  },
  CourseWebPage: {
    component: CoursePage,
    path: '/course-detail/:type/:id/:title',
    roles: ['user']
  },

  ProgramWebPage: {
    component: ProgramPage,
    path: '/program-detail/:type/:id/:title',
    exact: true,
    roles: ['user']
  },

  ObjectDetailsPage: {
    component: ObjectDetails,
    path: '/program-detail/:type/:id/:title/objects/:objectId',
    roles: ['user']
  },

  XpandPageWeb: {
    component: XpandLibrary,
    path: '/Xpand',
    roles: ['user']
  },

  PhaseProgramWebPage: {
    component: PhaseProgram,
    path: '/phase'
  },

  TitlePageWeb: {
    component: TitlePage,
    path: '/title/:id',
    roles: ['user']
  },

  CourseCompletionWebPage: {
    component: CourseCompletion,
    path: '/CourseCompletion',
    roles: ['user']
  },

  PersonalPageWeb: {
    component: PersonalLibrary,
    path: '/personal',
    roles: ['user']
  },
  InProgressPageWeb: {
    component: InProgress,
    path: '/course-inProgess',
    roles: ['user']
  },

  NewlyAddedPageWeb: {
    component: NewlyAdded,
    path: '/course-newlyAdded',
    roles: ['user']
  },

  CompletedPageWeb: {
    component: Completed,
    path: '/course-completed',
    roles: ['user']
  },

  CreateQuiz: {
    component: CreateQuiz,
    path: '/create-quiz',
    roles: ['expert', 'admin', 'super_admin']
  },
  EditQuiz: {
    component: CreateQuiz,
    path: '/edit-quiz/:id',
    roles: ['expert', 'admin', 'super_admin']
  },
  QuizListing: {
    component: DummyQuizListing,
    path: '/quiz-list',
    roles: ['expert', 'admin', 'super_admin']
  },
  ManageContentLibrarylist: {
    component: ManageContentLibrarylist,
    path: '/ManageContentLibrary',
    roles: ['expert', 'admin', 'super_admin']
  },
  ManageContentFolder: {
    component: ManageContentFolder,
    path: '/ManageContentFolder/:id',
    roles: ['expert', 'admin', 'super_admin']
  },
  ManageThumbnailLibrary: {
    component: ManageThumbnailLibrary,
    path: '/ManageThumbnailLibrary',
    roles: ['expert', 'admin', 'super_admin']
  },
  ManageThumbnailUser: {
    component: ManageThumbnailUser,
    path: '/ManageThumbnailUser/:id',
    roles: ['expert', 'admin', 'super_admin']
  },
  ManageVideoCourse: {
    component: ManageVideoCourse,
    path: '/ManageVideoCourse',
    roles: ['expert', 'admin', 'super_admin']
  },
  AdminDashboard: {
    component: AdminDashboard,
    path: '/Home',
    roles: ['expert', 'admin', 'super_admin']
  },
  Addvideo: {
    component: Addvideo,
    path: '/Addvideo/:id',
    roles: ['expert', 'admin', 'super_admin']
  },
  Editvideo: {
    component: Editvideo,
    path: '/Editvideo/:id',
    roles: ['expert', 'admin', 'super_admin']
  },
  
  AdminCustomisableUserProfiles: {
    component: AdminCustomisableUserProfiles,
    path: '/AdminCustomisableUserProfiles/:id',
    roles: ['expert', 'admin', 'super_admin']
  },
  AchievementListing: {
    component: AchievementListing,
    path: '/AchievementListing',
    roles: ["user"]
  },
  AchievementDetails: {
    component: AchievementDetails,
    path: '/AchievementDetails/:id',
    roles: ["user"]
  },
  AchievementCongratulations: {
    component: AchievementCongratulations,
    path: '/AchievementCongratulations/:id',
    roles: ["user"]
  },
  AllRewardsListing: {
    component: AllRewardsListing,
    path: '/AllRewardsListing',
    roles: ["user"]
  },
  AllRewardsDetails: {
    component: AllRewardsDetails,
    path: '/AllRewardsDetails/:id',
    roles: ["user"]
  },
  NotFoundPage: {
    component: NotFoundPage,
    path: '*'
  }
};

const firebaseAPI = firebase.initializeApp({
  apiKey: 'AIzaSyDgl9aTbKMdRZ9-ijSZRionh3V591gMJl4',
  authDomain: 'rnmasterapp-c11e9.firebaseapp.com',
  databaseURL: 'https://rnmasterapp-c11e9.firebaseio.com',
  projectId: 'rnmasterapp-c11e9',
  storageBucket: 'rnmasterapp-c11e9.appspot.com',
  messagingSenderId: '649592030497',
  appId: '1:649592030497:web:7728bee3f2baef208daa60',
  measurementId: 'G-FYBCF3Z2W3'
});

class App extends Component {
  render() {
    const defaultAnalytics = firebaseAPI.analytics();
    defaultAnalytics.logEvent('APP_Loaded');

    const theme = createTheme({
      typography: {
        fontFamily: 'Montserrat'
      },
      palette: {
        background: {
          default: '#FFFFFF',
          paper: '#FFFFFF'
        }
      },
      overrides: {
        MuiCssBaseline: {
          '@global': {
            '@font-face': [montserrat]
          }
        }
      }
    });

    return (
      <>
        <View>
          <ThemeProvider theme={theme}>
            <CssBaseline />
            <Switch>
              {WebRoutesGenerator({ routeMap })}
            </Switch>
            <ModalContainer />
          </ThemeProvider>
        </View>
        <ToastContainer />
      </>
    );
  }
}

export default App;
