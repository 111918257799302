// Customizable Area Start
import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";


export const configJSON = require("./config");

export interface Props {
  navigation?: any;
  id?: string;
  handleClose?:any;
  handleOpen?:any;
}

interface S {
  userProfileData:any;
  assignContent:any;
  userType:any;
  closeModal:any;
  notify:any;
  course:any;
  calendar:any;

}

interface SS {
  id: any;
}

export default class AssignContentController extends BlockComponent<
  Props,
  S,
  SS
> {
  getUserProfileID: string = "";

  constructor(props: Props) {

    super(props);
    this.receive = this.receive.bind(this);

    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
    ]
    
    this.state = {
      userProfileData:{},
      assignContent:'',
      userType:'',
      closeModal: false,
      notify: 'false' ,
      course: 'false' ,
     calendar: new Date(),
    };

    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);


  }

  async componentDidMount() {

  }


  async receive(from: string, message: Message) {
    runEngine.debugLog("Message Recived", message);


    // Customizable Area Start
    if (message.id === getName(MessageEnum.RestAPIResponceMessage)) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      var responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );


      var errorReponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );

      // api call start =======================================
        // user profile api response 
        if (apiRequestCallId === this.getUserProfileID) {
          if(responseJson && !responseJson.error){
              // console.log(responseJson.user_info.avatar)     
              this.setState({
                userProfileData:responseJson
              })       
          } else {
            var errorReponse = message.getData(
              getName(MessageEnum.RestAPIResponceErrorMessage)
            );
            if (errorReponse === undefined) {
              this.setState({
                // errorMsg: "Something went wrong"
              });
              // console.log(errorReponse)
            } else {
              this.setState({
                // errorMsg: errorReponse,
               
              });
              // console.log(errorReponse)
            }
          }
          // console.log(responseJson)
        }
      }
    
    // Customizable Area End
  }



   // get api for user profile data 
   getUserProfileData(){
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      token: localStorage.getItem('token')
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getUserProfileID = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getMyProfileAPiEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      'GET'
    );

    runEngine.sendMessage(requestMessage.id, requestMessage)
  }

  handleAssignContent = (event:any) =>{
    this.setState({
        assignContent: event.target.value
    })
    console.log(event);
    
  }
  handleUserType = (event:any) =>{
    this.setState({
        userType: event.target.value
    })
    // console.log('hii');
    
  }

  handleAssignOpenModal=()=> {
    this.setState({
      closeModal:true
    })
    
  }
  handleAssignCloseModal=()=> {
    this.setState({
      closeModal:false
    })
  }

  handleNotifyUser=(event:any)=> {
    this.setState({
      notify:event.target.value
    })
    console.log(event.target.value);
    
  }

  handleCalendar=(event:any)=> {
    this.setState({
      calendar:event
    })
    console.log(event);
    
  }

}

// Customizable Area End