// Customizable Area Start
import React from "react";
import { Dialog, IconButton } from "@material-ui/core";
import { createStyles, Theme, withStyles } from "@material-ui/core/styles";
import CloseIcon from "@material-ui/icons/Close";
import Card from "./components/Card.web";
import { CheckIcon } from "./assets";
// Customizable Area End

import RemindersController from "./RemindersController.web";

class Reminders extends RemindersController {
  // Customizable Area Start
  // Customizable Area End

  render() {
    const { classes, fullScreen, userRole } = this.props;
    const {
      reminders,
      isReminderOpen,
      reminderIndex,
      isDismissing,
    } = this.state;
    const curReminder = reminders[reminderIndex]; 
    const remindersRemaining = reminders.length - (reminderIndex + 1);

    let remindersContent = null;
    if (this.state.reminderIndex === this.state.reminders.length) {
      remindersContent = (
        <div className={classes.noRemindersContainer}>
          <img src={CheckIcon} alt="no reminders" />
          <p className={classes.noRemindersText}>You don’t have any notification pending</p>
        </div>
      );
    } else {
      remindersContent = (
        <>
          <div // Box 1
            className={remindersRemaining >= 2 ? classes.box1 : classes.hiddenBox}
          />
          <div // Box 2
            className={remindersRemaining >= 1 ? classes.box2 : classes.hiddenBox}
          />
          <div className={classes.mainBox}>
            <Card
              {...curReminder.attributes}
              handleDismiss={this.handleDismissReminders}
              isDismissing={isDismissing}
            />
          </div>
        </>
      );
    }

    if (reminders.length === 0) {
      return null;
    }

    return (
      // Customizable Area Start
      <Dialog
        fullScreen={fullScreen}
        fullWidth
        maxWidth="xs"
        open={isReminderOpen}
        onClose={this.handleCloseReminders}
        aria-labelledby="reminders-dialog"
        className={classes.dialog}
      >
        <div
          className={classes.dialogTitle}
          style={{
            marginBottom: remindersRemaining >= 2 ? 15 : 40,
          }}
        >
          <IconButton
            style={{
              background: userRole === "super_admin" ? "#6C328B" : "#FFFFFF",
            }}
            onClick={this.handleCloseReminders}
          >
            <CloseIcon
              style={{
                color: userRole === "super_admin" ? "#FFFFFF" : "#000000",
              }}
            />
          </IconButton>
        </div>
        <div className={classes.cardsContainer}>
          {remindersContent}
        </div>
      </Dialog>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
const styles = (theme: Theme) =>
  createStyles({
    dialog: {
      "& .MuiDialog-scrollPaper": {
        marginTop: 0,
        [theme.breakpoints.up("sm")]: {
          alignItems: "flex-start",
          marginTop: 20,
        },
      },
      "& .MuiPaper-root": {
        borderRadius: 0,
        background: "#F4F4F4",
        padding: 10,
        [theme.breakpoints.up("sm")]: {
          borderRadius: 24,
          padding: "15px 30px 30px 30px",
        },
      },
      "& .MuiDialog-paperWidthXs": {
        maxWidth: "none",
        [theme.breakpoints.up("sm")]: {
          maxWidth: 508,
        },
      },
    },
    dialogTitle: {
      display: "flex",
      justifyContent: "flex-end",
      alignItems: "center",
      marginBottom: "15px",
    },
    cardsContainer: {
      display: "flex",
      flexDirection: "column",
      justifyContent: "flex-start",
      alignItems: "center",
    },
    box1: {
      width: "90%",
      height: "50px",
      background: "white",
      border: "1px solid #6c328b",
      borderRadius: "12px",
    },
    box2: {
      width: "95%",
      height: "50px",
      background: "white",
      border: "1px solid #6c328b",
      borderRadius: "12px",
      marginTop: "-25px",
    },
    mainBox: {
      background: "#ffffff",
      border: "1px solid #6c328b",
      boxShadow: "0px 2px 32px rgba(246, 246, 246, 0.5)",
      borderRadius: "12px",
      padding: "20px",
      marginTop: "-25px",
      width: "100%",
      [theme.breakpoints.up("sm")]: {
        width: "auto",
      },
    },
    hiddenBox: {
      display: "none",
    },
    noRemindersContainer: {
      textAlign: "center"
    },
    noRemindersText: {
      color: "#92929D",
      textAlign: "center",
      fontSize: "24px",
      fontWeight: 700,
      lineHeight: "normal",
      margin: "40px 0 100px 0",
    }
  });

// @ts-ignore
export default withStyles(styles)(Reminders);
// Customizable Area End
