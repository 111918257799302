import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { imgPasswordInVisible, imgPasswordVisible } from "./assets";
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  handelClose: any;
  history?: any;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  txtInputValue: string;
  txtSavedValue: string;
  enableField: boolean;
  // Customizable Area Start
  isModalOpen: boolean;
  isNoteModalOpen: boolean;
  userProfileData: any,
  allNotesData: any,
  userloading: boolean,
  isloading: any,
  handleTabValue: any,
  searchValue: any,
  recentInsightsData: any,
  recentActivityData: any,
  listWatchedData: any;
  listTimeData: any,
  progressLoading: any,
  videoWatchLoading: any,
  sort:boolean,
  allReportsData: any,
  reportsLoading: any,
  reportsSearch: any,
  recentSearchValue: any,
  recentTabValue: any,
  recentLoading: any,
  recentInsightLoading: any,
  timeSelected: any,
  videoSelected: any,
  values: any,
  listTimeHours: any,
  userData_Admin: any,
  chartData: any,
  watchChartData: any,
  listReportTimeData: any,
  listReportTimeHours: any,
  listReportWatchedData: any,
  libraryCheckedData2:any,
  selectedIndices:any,
  filter:boolean,
  libraryCheckedData3:any,
  checkboxChecked1:any,
  checked: any,
  reportCategaries:any,
  checkboxChecked:any,
  //settype:any;
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class CustomisableUserProfilesController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  getUserProfileID: string = ""
  getAllNotesID: string = ""
  getRecentInsightsID: string = ""
  getRecentActivityID: string = ""
  getListWatchedID: string = ""
  getReportListWatchedID: string = ""
  getReportListTimeID: string = ""
  getListTimeID: string = ""
  getAllReportsID: string = ""
  addTimeSpentID: string = ""
  GetUserDetail: string = ""
  video_web_track: string = ""
  video_web_Watch: string = ""
  libraryCheckedDataSort: string = ""
  GetAllcategoriesData:string = ""
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionSaveMessage),
      getName(MessageEnum.SessionResponseMessage),
      // Customizable Area End
    ];

    this.state = {
      txtInputValue: "",
      txtSavedValue: "A",
      enableField: false,
      // Customizable Area Start
      isModalOpen: false,
      isNoteModalOpen: false,
      sort:false,
      filter:false,
      userProfileData: {},
      allNotesData: [],
      userloading: true,
      isloading: true,
      handleTabValue: 0,
      searchValue: '',
      listReportWatchedData: [],
      recentInsightsData: [],
      recentActivityData: [],
      listWatchedData: [],
      listReportTimeHours: [],
      listTimeData: [],
      listReportTimeData: [],
      progressLoading: true,
      videoWatchLoading: true,
      allReportsData: [],
      reportsLoading: true,
      reportsSearch: '',
      recentSearchValue: '',
      recentTabValue: 0,
      recentLoading: true,
      recentInsightLoading: true,
      userData_Admin: {},
      chartData: {},
      checked:[],
      watchChartData: {},
      timeSelected: 'week',
      videoSelected: 'week',
      values: ["week", "month", "year"],
      listTimeHours: 0,
      selectedIndices:'',
      libraryCheckedData2: [
        {
          id: 1,
          text: 'Course/Program Name',
          name: 'course_name',
          checked1: true,
        },
        {
          id: 2,
          text: 'Xpart Name',
          name: 'expart_name',
          checked3: true,
        },
      ],
      checkboxChecked1: {},
      reportCategaries: {},
      checkboxChecked:{},
      libraryCheckedData3: [
        {
          id: 0,
          text: 'Due this week',
          name: 'due_this_week',
          checked1: true,
        },
        // {
        //   id: 1,
        //   text: 'Due this mounth',
        //   name: 'due_this_mounth',
        //   checked3: true,
        // },
        // {
        //   id: 2,
        //   text: 'Past Due',
        //   name: 'all_due',
        //   checked4: true,
        // },
        // {
        //   id: 3,
        //   text: 'Newly Added',
        //   name: 'new_added',
        //   checked4: true,
        // },

      ],

      //settype:false,
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    //this.handlechange = this.handlechange.bind(this) 
    this.handleOpenModal = this.handleOpenModal.bind(this)
    this.handleCloseModal = this.handleCloseModal.bind(this)
    this.handleAddNotesOpenModal = this.handleAddNotesOpenModal.bind(this)
    this.handleAddNotesCloseModal = this.handleAddNotesCloseModal.bind(this)
    // Customizable Area End
  }



  getDataFromAPi = () => {
    this.getReportListWatchedData();
    this.getReportListTimeData();
  }

  async receive(from: string, message: Message) {
    runEngine.debugLog("Message Recived", message);


    // Customizable Area Start
    if (message.id === getName(MessageEnum.RestAPIResponceMessage)) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      let responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      let errorReponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );

      // api call start =======================================
      // user profile api response 
      if (apiRequestCallId === this.GetAllcategoriesData){
        this.setState({reportCategaries: responseJson})
      }
      if (apiRequestCallId === this.getUserProfileID) {
        if (responseJson && !responseJson.error) {
          this.getReportListWatchedData();
          // console.log(responseJson.user_info.avatar)
          const profile = responseJson.user_info
          localStorage.setItem('profile', JSON.stringify(profile))
          this.setState({
            userloading: false,
            userProfileData: responseJson
          })
        } else {
          this.setState({
            userloading: false,
          });
        }
      }
      // all notes api response 
      if (apiRequestCallId === this.getAllNotesID) {


        if (responseJson && !responseJson.error) {

          this.setState({
            allNotesData: responseJson.data,
            isloading: false
          })
        } else {
          this.setState({
            isloading: false,
          });
          console.log(errorReponse)
        }
      }
      // all recent insights response 
      if (apiRequestCallId === this.getRecentInsightsID) {


        if (responseJson && !responseJson.error) {
          // console.log(responseJson.recent_insight , "recent insight")     
          this.setState({
            recentInsightsData: responseJson.recent_insight,
            recentInsightLoading: false
          })
        } else {
          this.setState({
            recentInsightLoading: false
          });
          console.log(errorReponse)

        }
      }
      // all recent activity response 
      if (apiRequestCallId === this.getRecentActivityID) {


        if (responseJson && !responseJson.error) {
          // console.log(responseJson.data , "recent activity")     
          this.setState({
            recentActivityData: responseJson.data,
            recentLoading: false
          })
        } else {
          this.setState({
            recentLoading: false,
          });
          console.log(errorReponse)
        }
      }
      // all list watched response 
      if (apiRequestCallId === this.getListWatchedID) {


        if (responseJson && !responseJson.error) {
          // console.log(responseJson , "time watched")   
          this.setState({
            listWatchedData: responseJson,
            videoWatchLoading: false
          })
        } else {


          this.setState({
            videoWatchLoading: false,
          });
          console.log(errorReponse)

        }
      }
      if (apiRequestCallId === this.getReportListWatchedID) {
        if (responseJson && !responseJson.error) {
          this.setState({
            listReportWatchedData: responseJson,
            videoWatchLoading: false
          }, () => { })
        } else {
          this.setState({
            videoWatchLoading: false,
          });
        }
      }
      if (apiRequestCallId === this.getReportListTimeID) {
        if (responseJson && !responseJson.error) {
          let newarr = responseJson?.data?.map((data: any) => {
            return [data?.track_date, data?.track_time]
          })
          this.setState({
            listReportTimeData: newarr,
            listReportTimeHours: responseJson.total_time,
            progressLoading: false
          })
        } else {
          this.setState({
            progressLoading: false,
          });
          console.log(errorReponse)
        }
      }
      // all list time response 
      if (apiRequestCallId === this.getListTimeID) {


        if (responseJson && !responseJson.error) {

          this.setState({
            listTimeData: responseJson.data,
            listTimeHours: responseJson.data,
            progressLoading: false
          })



        } else {


          this.setState({
            progressLoading: false,
          });

        }
      }

      // AdminUserProfile
      if (apiRequestCallId === this.GetUserDetail) {
        this.setState({ userData_Admin: responseJson })
      }
      //video_web_track
      if (apiRequestCallId === this.video_web_track) {
        this.setState({ chartData: responseJson })
      }

      //video_web_watch
      if (apiRequestCallId === this.video_web_Watch) {
        this.setState({ watchChartData: responseJson })
      }
      // all recent activity response 
      if (apiRequestCallId === this.getAllReportsID) {
        if (responseJson && !responseJson.error) {
          this.getReportListWatchedData();
          this.setState({
            allReportsData: responseJson.data,
            reportsLoading: false
          })
        }
      }
      // add time spent 
      if (apiRequestCallId === this.addTimeSpentID) {

        if (responseJson) {
          console.log(responseJson, "add time spent ")
        } else {
          console.log(errorReponse, 'add time spent');

        }
      }
    }



    // Customizable Area End
  }

  txtInputWebProps = {
    onChangeText: (text: string) => {
      this.setState({ txtInputValue: text });
    },
    secureTextEntry: false,
  };

  txtInputMobileProps = {
    ...this.txtInputWebProps,
    autoCompleteType: "email",
    keyboardType: "email-address",
  };

  txtInputProps = this.isPlatformWeb()
    ? this.txtInputWebProps
    : this.txtInputMobileProps;

  btnShowHideProps = {
    onPress: () => {
      this.setState({ enableField: !this.state.enableField });
      this.txtInputProps.secureTextEntry = !this.state.enableField;
      this.btnShowHideImageProps.source = this.txtInputProps.secureTextEntry
        ? imgPasswordVisible
        : imgPasswordInVisible;
    },
  };

  btnShowHideImageProps = {
    source: this.txtInputProps.secureTextEntry
      ? imgPasswordVisible
      : imgPasswordInVisible,
  };

  btnExampleProps = {
    onPress: () => this.doButtonPressed(),
  };

  doButtonPressed() {
    let msg = new Message(getName(MessageEnum.AccoutLoginSuccess));
    msg.addData(
      getName(MessageEnum.AuthTokenDataMessage),
      this.state.txtInputValue
    );
    this.send(msg);
  }


  // web events
  setInputValue = (text: string) => {
    this.setState({ txtInputValue: text });
  };

  setEnableField = () => {
    this.setState({ enableField: !this.state.enableField });
  };

  searchShort = () => {
    this.setState({ sort: true })
  }
  closeSearchSort = () => {
    this.setState({ sort: false })
  }
  closeSearchFilter = () => {
    this.setState({filter: false})
  }
  OpenSearchFilter = () => {
    this.setState({filter: true})
  }
  checkedhandleChange2 = (event: any) => {
    this.setState({ selectedIndices: event.target.value });
  };

  // checkedhandleChange3 = (id: any, event: any) => {
  //   event.persist();
  //   this.setState((prevState) => {
  //     const { checkboxChecked1, checked } = prevState;
  //     checkboxChecked1[id.checked1] = event.target.checked;
  //     let updatedChecked;
  //     if (event.target.checked) {
  //       updatedChecked = [...checked, id];
  //     } else {
  //       updatedChecked = checked.filter((item: any) => item !== id);
  //     }
  //     const params = updatedChecked.map((id: any) => `all_due=${id}`).join('&');
  //     this.libraryCheckedDataSort = params;
  //     return { checkboxChecked1, checked: updatedChecked };
  //   });
  // };

  // checkedhandleChange3 = (data: any, event: any) => {
  //   event.persist();
  //   this.setState((prevState) => {
  //     const { checkboxChecked1, checked } = prevState;
  //     checkboxChecked1[data.checked1] = event.target.checked;
  //     let updatedChecked;
  //     if (event.target.checked) {
  //       updatedChecked = [...checked, data.checked1];
  //     } else {
  //       updatedChecked = checked.filter((item: any) => item !== data.checked1);
  //     }
  //     const params = updatedChecked.map((id: any) => `all_due=${id}`).join('&');
  //     this.libraryCheckedDataSort = params;
  //     return { checkboxChecked1, checked: updatedChecked };
  //   });
  // };

  checkedhandleChange3 = (id:any, event:any) => {
    event.persist();
    this.setState((prevState) => {
      const { checkboxChecked1, checked } = prevState;
      checkboxChecked1[id] = event.target.checked;
      let updatedChecked;
      if (event.target.checked) {
        updatedChecked = [...checked, id];
      } else {
        updatedChecked = checked.filter((item:any) => item !== id);
      }
      const params = updatedChecked.map((id:any) => `all_due=${id}`).join('&');
      this.libraryCheckedDataSort = params;
      return { checkboxChecked1, checked: updatedChecked };
    });
  };
  
  
  
  
  
  

  checkedhandleChange = (id: any, is_selected: any, event: any) => {
    const { checkboxChecked } = this.state;
    checkboxChecked[id] = event.target.checked;
    let data = this.state.checked;
    if (event.target.checked) {
      data.push(id);
    } else {
      const index = data.indexOf(id);
      if (index !== -1) {
        data.splice(index, 1);
      }
    }
    this.setState({ checkboxChecked, checked: data });
    const params = data.map((id: any) => `category_ids=${id}`).join('&');
    this.libraryCheckedDataSort = params;
  }

  // Customizable Area Start

  Allcategories_list = () => {
    const header = {
      "Content-Type": "application/json",
      token: localStorage.getItem("token")
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.GetAllcategoriesData = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_categories/categories`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      header
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      'get'
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  CheckedDataSort = () => {
    this.setState({reportsLoading: true}) 
    const header = {
      "Content-Type": "application/json",
      token: localStorage.getItem("token")
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getAllReportsID = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getAllReportsEndPoint + `?${this.libraryCheckedDataSort}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      header
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      'get'
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  // get api for user profile data 
  getUserProfileData() {
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      token: localStorage.getItem('token')
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getUserProfileID = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getMyProfileAPiEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      'GET'
    );

    runEngine.sendMessage(requestMessage.id, requestMessage)
  }

  // get api for all notes data 
  getAllNotesData(query: any = '', noteType: any = '') {
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      token: localStorage.getItem('token')
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getAllNotesID = requestMessage.messageId;
    {localStorage.getItem('user_role') === "super_admin" ? 
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getAllNotesAPiEndPoint + `?search=${query}&note_type=${noteType}&user_id=${this.props.navigation.getParam("id")}`
    ) : 
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getAllNotesAPiEndPoint + `?search=${query}&note_type=${noteType}`
    )}

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      'GET'
    );

    runEngine.sendMessage(requestMessage.id, requestMessage)
  }
  // get api for recent insights data 
  getRecentInsightsData(searchVal: any = '') {
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      token: localStorage.getItem('token')
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getRecentInsightsID = requestMessage.messageId;
    {localStorage.getItem('user_role') === "super_admin" ? 
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getRecentInsightsAPiEndPoint + `?search=${searchVal}&user_id=${this.props.navigation.getParam("id")}`
    ) :
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getRecentInsightsAPiEndPoint + `?search=${searchVal}`
    )}

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      'GET'
    );

    runEngine.sendMessage(requestMessage.id, requestMessage)
  }

  // get api for recent activity data 
  getRecentActivityData(searchVal: any = '') {
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      token: localStorage.getItem('token')
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getRecentActivityID = requestMessage.messageId;

    {localStorage.getItem('user_role') === "super_admin" ? 
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getRecentActivityAPiEndPoint + `?search=${searchVal}&user_id=${this.props.navigation.getParam("id")}`
    ) :
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getRecentActivityAPiEndPoint + `?search=${searchVal}`
    );}

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      'GET'
    );

    runEngine.sendMessage(requestMessage.id, requestMessage)
  }

  // get api for list watched data 
  getListWatchedData(filter: any = 'year') {
    console.log(filter, '7777777777777777');

    const header = {
      "Content-Type": configJSON.validationApiContentType,
      token: localStorage.getItem('token')
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getListWatchedID = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getAllVideoWatchDataEndPoint + `?filter=week`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      'GET'
    );

    runEngine.sendMessage(requestMessage.id, requestMessage)
  }

  // get api for list time data 
  getListTimeData = (filter: any = 'year') => {
    console.log(filter, '5555555555555555555555');

    const header = {
      "Content-Type": configJSON.validationApiContentType,
      token: localStorage.getItem('token')
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getListTimeID = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getTimeSpanddataAPiEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      'GET'
    );

    runEngine.sendMessage(requestMessage.id, requestMessage)
  }

  // get api for list time data 
  getAllReportsData(title: any = "") {
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      token: localStorage.getItem('token')
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getAllReportsID = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getAllReportsEndPoint + `?title=${title}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      'GET'
    );

    runEngine.sendMessage(requestMessage.id, requestMessage)
  }

  applyShorting = () => {
   this.setState({reportsLoading: true}) 
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      token: localStorage.getItem('token')
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getAllReportsID = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getAllReportsEndPoint + `?sort_by=${this.state.selectedIndices}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      'GET'
    );

    runEngine.sendMessage(requestMessage.id, requestMessage)
  }

  // add time spent traning track data
  addTimeSpent(tiem: any = '', date: any = '') {
    console.log("called API ")
    let data = new FormData();
    data.append("track_time", "100");
    data.append("track_date", "2022-12-19");


    const header = {
      token: localStorage.getItem('token')
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    this.addTimeSpentID = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getAddTimeSpentAPiEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      data
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      'POST'
    );

    runEngine.sendMessage(requestMessage.id, requestMessage)
    return requestMessage.messageId;

  }

  routeChange = () => {
    this.props.navigation.navigate("CourseNotes");
  }

  handleOpenModal() {
    this.setState({
      isModalOpen: true
    })
  }
  handleNoteOpenModal = () => {
    this.setState({
      isNoteModalOpen: !this.state.isNoteModalOpen,
    })

    // console.log("model open")
  }

  handleCloseModal() {
    this.setState({
      isModalOpen: false
    })
  }

  handleAddNotesOpenModal() {
    this.setState({
      isNoteModalOpen: true
    })

  }
  handleAddNotesCloseModal() {
    this.setState({
      isNoteModalOpen: false
    })
  }

  handleChangeTime = (event: any) => {
    this.setState({ timeSelected: event.target.value });
  }

  handleChangeVideo = (event: any) => {
    this.setState({ videoSelected: event.target.value });
  }


  getAdminUserProfileData = async () => {
    const header = {
      "Content-Type": "application/json",
      token: localStorage.getItem("token"),
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.GetUserDetail = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_admin/user/${this.props.navigation.getParam("id")}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      header
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "get"
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  getVideo_web_track_Admin = async () => {
    const header = {
      "Content-Type": "application/json",
      token: localStorage.getItem("token"),
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.video_web_track = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_adhocreporting/course_video_track/video_web_track?filter=week&user_id=${this.props.navigation.getParam("id")}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      header
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "get"
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  getVideo_web_Watch_Admin = async () => {
    const header = {
      "Content-Type": "application/json",
      token: localStorage.getItem("token"),
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.video_web_Watch = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_adhocreporting/training_track/web_track?user_id=${this.props.navigation.getParam("id")}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      header
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "get"
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };


  getReportListWatchedData = (filter: any = 'week') => {
    console.log(filter, '7777777777777777');

    const header = {
      "Content-Type": configJSON.validationApiContentType,
      token: localStorage.getItem('token')
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getReportListWatchedID = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getListWatchedAPiEndPoint + `?filter=${filter}&web=true`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      'GET'
    );

    runEngine.sendMessage(requestMessage.id, requestMessage)
  }

  // get api for list time data 
  getReportListTimeData = (filter: any = 'week') => {
    console.log(filter, '5555555555555555555555');

    const header = {
      "Content-Type": configJSON.validationApiContentType,
      token: localStorage.getItem('token')
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getReportListTimeID = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getListTimeAPiEndPoint + `?web=true&filter=${filter || this.state.timeSelected}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      'GET'
    );

    runEngine.sendMessage(requestMessage.id, requestMessage)
  }


  // Customizable Area End
}