import React, { Component } from 'react'
// Customizable Area Start
import {
  Grid,
  TextField,
  InputAdornment,
  Button,
  IconButton,
  Avatar,
  Card,
  FormControl,
  Select,
  MenuItem,
  CircularProgress,
  Box
} from "@material-ui/core";
import {  Close } from "@material-ui/icons";
import {toast} from 'react-toastify';
import { Caret, libraryIcon, uploadIcon } from "./assets"
import { Formik } from "formik";
import * as yup from "yup";
import './video.css';
import VideosController from "./VideosController.web";
class VideoAdd extends VideosController {
  constructor(props: any) {
    super(props);
    this.handleImageChange = this.handleImageChange.bind(this);
  }
  handleSubmit(data: any) {
    const fd = new FormData();
    fd.append('course_video[title]', data.title);
    fd.append('course_video[description]', data.description);
    fd.append('course_video[phase]', data.phase);
    fd.append('course_video[course_id]', this.props.navigation.getParam('id'));
    fd.append('course_video[video_size]',this.state?.duration);
    this.state.file !== "" ? fd.append('course_video[video]', this.state.file) : fd.append('course_video[video_text_url]', data.video_text_url);
    console.log(data,"data")
    console.log(fd,'filename_local');
    
    this.setState({loading:true})
    this.addVideo(fd);
  }
   handleImageChange = async (e: any) => {
    const files = e.target.files[0];
    if (files === undefined) {
      return;
    }
     const MIN_FILE_SIZE = 30720; 
     const fileSizeKiloBytes = files.size / 1024;
     if (fileSizeKiloBytes >= MIN_FILE_SIZE) {
      toast.error("Please upload a file smaller than 30 MB",{delay:2000});
      return ;
    }
    let filenames = "";
    filenames += files.name + "\n";
    const duration:any = await this.getDuration(files);
    this.getStockImage()
    this.setState({ filename: filenames, image: URL.createObjectURL(files), imgUrl: "", file: files, id: "" ,duration:duration.toFixed(2)})
    console.log('filename_local',this.state.filename,this.state.file);
    
  }
  async  getDuration(file:any) {
    const url = URL.createObjectURL(file);
   
    return new Promise((resolve) => {
      const audio = document.createElement("audio");
      audio.muted = true;
      const source = document.createElement("source");
      source.src = url; //--> blob URL
      audio.preload= "metadata";
      audio.appendChild(source);
      audio.onloadedmetadata = function(){
         resolve(audio.duration)
      };
    });
   }
  render() {
    return (<>
      {!this.state.loading &&<Formik
        initialValues={{
          title: "",
          description: "",
          phase: this.state.isFreeTrial ? 0 : 1,
          video_text_url: ""
        }}
        onSubmit={async (values) => {
          this.handleSubmit(values);
        }}
        validationSchema={yup.object().shape({
          title: yup.string().required("Please enter title").min(5,"min 5 characters required").max(150,"max 150 characters required"),
          description: yup.string().required("Please enter description").min(5,"min 5 characters required").max(1500,"max 1500 characters required"),
          phase: yup.number().required('Please enter phase'),
          video_text_url: yup
            .string()
            .matches(
              /^(?:(?:https|http):\/\/)?(?:www\.)?(?:youtube\.com|youtu\.be).*(?<=\/|v\/|u\/|embed\/|shorts\/|watch\?v=)(?<!\/user\/)(?<id>[\w\-]{11})(?=\?|&|$)/,
              'Enter valid url!'
            )
          ,
        })}

      >
        {(props) => {
          const {
            values,
            touched,
            errors,
            handleChange,
            handleBlur,
            handleSubmit,
          } = props;
          return (
            <form onSubmit={handleSubmit}>
              <Grid container className='video' spacing={4}>
                <Grid container item className='file-container' md={12} >
                  {this.state.image === "" ? <>

                    <Grid className="file-content" item md={1}>
                      <Button variant="contained" className="upload-btn" component="label">
                        <input hidden accept="video/*" disabled={values.video_text_url === "" ? false : true} type="file" onChange={(e) => this.handleImageChange(e)} />
                        <Avatar src={uploadIcon} />
                      </Button>

                    </Grid>
                    <Grid item md={12}>
                      <p className="drag-text">Drag and Drop or <span className="drag-browse">Browse</span> to upload</p>
                    </Grid>
                    <Grid item md={12}>
                      <p className="drag-subtext">Upload upto 15 MB and file dimension should be 768*1024</p>
                    </Grid>
                  </> : <>
                    <div style={{ display: "flex", flexWrap: "wrap", justifyContent: "center" }} className="custom-card-preview" >
                      <div style={{ minWidth: "200px", maxWidth: "200px", margin: "-16px", textAlign: "center" }} >
                        <Card >
                          <div
                            className='card-header'


                          >
                            <IconButton aria-label="close" onClick={() => this.setState({image:"",file:"" })}>
                              <Close />
                            </IconButton>
                          </div>
                          <img className="select-img-preview" src={
                            "https://images.unsplash.com/photo-1634157703702-3c124b455499?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1528&q=80"
                          } />
                        </Card>
                        <p className='file-name'>{this.state.filename}</p>
                      </div>
                    </div>
                  </>}
                </Grid>
                <Grid container spacing={4} >
                  <Grid item md={6} xs={12}>
                    <div className='file-container'>
                      <Grid item md={12} className="linkheading-content">
                        <p className="link-heading">Paste link here</p>
                      </Grid>
                      <Grid item md={12} className="subheading-content">
                        <p className="link-subheading">You can paste your youtube/vimeo video link here</p>
                      </Grid>
                      <Grid item xs={12} className="inputlink-content">
                        <TextField variant='standard' className="input-link" label="Enter your link here"
                          name='video_text_url'
                          value={values.video_text_url}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          error={touched.video_text_url && Boolean(errors.video_text_url)}
                          helperText={touched.video_text_url && errors.video_text_url}
                          disabled={this.state.file === "" ? false : true}
                        />
                      </Grid>
                    </div>
                  </Grid>
                  <Grid item md={6} xs={12}>
                    <div className='file-container'>
                      <Grid item md={12} className="library-content">
                        <p className="library-heading">Choose From Content Library</p>
                      </Grid>
                      <Grid item md={12} style={{ textAlign: "center" }}>
                        <Button className="library-btn">
                          <img src={libraryIcon} width="36" height="36" />
                        </Button>
                      </Grid>
                    </div>
                  </Grid>
                </Grid>
                <Grid container md={12}>
                  <Grid item md={12}>
                    <p className="basic">Basic Detail</p>
                  </Grid>
                </Grid>
                <Grid container md={12} xs={12} spacing={4}>
                  <Grid item md={7} xs={12}>
                    <TextField
                      fullWidth
                      id="title"
                      name="title"
                      value={values.title}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      error={touched.title && Boolean(errors.title)}
                      helperText={touched.title && errors.title}
                      variant="outlined"
                      className="vtitle-field"
                      placeholder="Enter Title"
                      InputLabelProps={{
                        shrink: true
                      }} />
                  </Grid>
                  <Grid item md={5} xs={12}>
                    <FormControl style={{width:'100%'}} className="select-phases">
                      <Select
                        labelId="demo-controlled-open-select-label"
                        id="demo-controlled-open-select"
                        name='phase'
                        value={values.phase}
                        error={touched.phase && Boolean(errors.phase)}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        variant="outlined"
                        disabled={this.state?.course!=="" && this.state?.course?.attributes?.content_release_schedule===false}
                        IconComponent={() => null}
                        endAdornment={
                          <InputAdornment position="end">
                            <img src={Caret} />
                          </InputAdornment>

                        }
                        fullWidth>
                        <MenuItem value={1}>
                          Select Phase
                        </MenuItem>
                       {this.state.isFreeTrial && <MenuItem value={0}>Free Trial</MenuItem>}
                        <MenuItem value={1}>Phase 1</MenuItem>
                        <MenuItem value={2}>Phase 2</MenuItem>
                        <MenuItem value={3}>Phase 3</MenuItem>
                        <MenuItem value={4}>Phase 4</MenuItem>
                        <MenuItem value={5}>Phase 5</MenuItem>
                        <MenuItem value={6}>Phase 6</MenuItem>
                        <MenuItem value={7}>Phase 7</MenuItem>
                        <MenuItem value={8}>Phase 8</MenuItem>
                        <MenuItem value={9}>Phase 9</MenuItem>
                        <MenuItem value={10}>Phase 10</MenuItem>
                      </Select>
                      <p className='invalid-feedback'>{touched.phase && errors.phase}</p>
                    </FormControl>
                  </Grid>
                </Grid>
                <Grid container md={12}>
                  <Grid item md={12}>
                    <p className="basic">Object Description</p>
                  </Grid>
                </Grid>
                <Grid container md={12} xs={12}>
                  <Grid item md={12} xs={12}>
                    <TextField
                      fullWidth
                      className="object-desc"
                      id="object_desc"
                      name="description"
                      value={values.description}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      error={touched.description && Boolean(errors.description)}
                      helperText={touched.description && errors.description}
                      variant="outlined"
                      placeholder="Please write your description here"
                      multiline
                      rows={6}
                      maxRows={12}
                      InputLabelProps={{
                        shrink: true
                      }}
                    />
                  </Grid>
                </Grid>
                <Grid container style={{ justifyContent: "center" }}>
                  <Grid item md={2}>
                    <Button className="btn-thumbnail" fullWidth type='submit'>
                      Confirm Content
                    </Button>
                  </Grid>
                </Grid>
              </Grid>
            </form>
          );
        }}
      </Formik>}
      {this.state.loading && <Box style={{ display: "flex", height: "35%", width: "100%", justifyContent:"center", alignItems:"center" }} > <CircularProgress/> </Box>}
      </>
    )
  }
}
export default VideoAdd;
// Customizable Area End