import React from "react";
// Customizable Area Start
import {
  StyleSheet,
  Text,
  Image,
  TouchableOpacity,
  View,
  ScrollView,
  TouchableWithoutFeedback,
  SafeAreaView,
  TextInput,
  Dimensions,
  Platform,
  FlatList,
} from "react-native";
import CoursesFilter from '../../dashboard/src/components/CoursesFilter';
import Filter from '../../dashboard/src/components/Filter';
import Loader from "../../../components/src/Loader";
import FilteritemsController, { Props } from "./FilteritemsController";
import Icon from "react-native-vector-icons/FontAwesome";
import AppHeader from "../../dashboard/src/components/AppHeader";
import Headings from '../../dashboard/src/components/Heading';
import CoursesProgram from "./components/CoursesProgram";
import Xperts from "./components/Xperts";
import Channels from "./components/Channels";
import { SLANTARROW, searchButton } from "./assets";
import Scale from "../../../components/src/Scale";
const config = require('./config.js');

let screenWidth = Dimensions.get('window').width;
// Customizable Area End
// Customizable Area Start
export default class Filteritems extends FilteritemsController {
  constructor(props: Props) {
    super(props);

  }
  getList(item: any) {
    let value = item.item.attributes;
    return (
      <View style={styles.productBox}>
        <View style={styles.ImgContainer}>
          <Image
            style={styles.productImg}
            source={{ uri: value.images[0].url }}
            resizeMode="cover"
          />
        </View>
        <View style={styles.detailContent}>
          <Text style={styles.productName}>{value.name}</Text>
          <Text style={styles.price}>{value.price}</Text>
          <View style={[styles.flexBox, styles.starBox]}>
            <Text style={styles.rating}>{value.average_rating}</Text>
            <Icon
              name="star"
              size={12}
              style={styles.sortIcon}
              color="orange"
            />
          </View>
        </View>
      </View>
    );
  }

  renderCoursesPrograms = () => {
    return (
      <View style={{ zIndex: -1 }} >
        <Headings
          smallFont
          title={'Courses/Programs'}
          viewAll={false}
          onViewAllButtonPressed={() => {

          }}
        />
        <View style={styles.subtitlecontainer}>
          <Text style={styles.descriptionsubtitle}>{config.coursesSubTitleString}</Text>
        </View>
        <CoursesProgram courses={this.state.librarySearchPage?.collection?.data.slice(0, 6)}
          addToCartButton={(item: any) => this.addToCart(item.id, item?.attributes?.expand_type === "Course" ? 'Course' : 'Program')}
          gotoPersonalDetailsScreen={(item: any) => this.props.navigation.navigate(item?.attributes?.expand_type === "Course" ? 'CourseCreation' : 'ProgramCourses', { UserItem: item })}
          onPressPlayButton={(item: any) => this.onPressPlayButton(item)}
        />
        <View style={styles.seperator} />
      </View>
    )
  }

  onPressPlayButton = (item: any) => {
    if (item.attributes?.is_enrolled) {
      if (item.attributes?.course_video_track?.last_video_id) {
        this.props.navigation.navigate('PhotographyScreen', {
          itemId: item.attributes?.course_video_track?.last_video_id,
          courseId: item?.attributes?.expand_type === "Course" ? item?.id : '',
          isProgram: item?.attributes?.expand_type === "Course" ? false : true,
          program_id: item?.attributes?.expand_type === "Course" ? '' : item?.id
        });
      } else {
        alert('Video not available');
      }
    } else {
      this.addToList(item, true);
    }

  }

  renderExpertProfile = () => {
    return (
      <View style={{ zIndex: -1 }} >
        <Headings
          smallFont
          title={'Xperts'}
          viewAll={false}
          onViewAllButtonPressed={() => { }}
        />

        <View style={{ marginStart: 18, marginEnd: 10 }}>
          <Text style={styles.descriptionsubtitle}>{config.xpertsSubTitleString}</Text>
        </View>

        <Xperts
          onXpertUserPressed={(item: any) =>
            this.props.navigation.navigate('ExpertProfile', { xpertData: item })
          }
          xperts={this.state.librarySearchPage?.experts?.data.slice(0, 7)} />

        <View style={styles.seperator} />
      </View>
    )
  }

  renderMyChannel = () => {
    return (
      <View>
        <Headings

          smallFont
          title={'Channels'}
          viewAll={false}
          onViewAllButtonPressed={() => { }}
        />

        <View style={{ marginStart: 18, marginEnd: 10 }}>
          <Text style={styles.descriptionsubtitle}>{config.channelsSubTitleString}</Text>
        </View>

        <Channels channels={this.state.librarySearchPage?.channels?.data} />


      </View>
    )
  }

  renderSuggestionList = () => {
    return (
      <View style={{
        maxHeight: Platform.OS === "ios" ? Scale(180) : Scale(192), width: Scale(307), marginLeft: Scale(24), borderBottomEndRadius: Scale(10), borderBottomLeftRadius: Scale(10), borderWidth: Scale(0.5), zIndex: 1, position: 'absolute',
        marginTop: Platform.OS === "ios" ? Scale(48) : Scale(66)
        , backgroundColor: '#fff'
      }}>
        <FlatList
          showsHorizontalScrollIndicator={false}
          data={this.state.suggestionList}
          scrollEnabled={false}
          renderItem={(item: any) => {
            return (
              <TouchableOpacity
                onPress={() => this.setState({ searchTextInput: item.item, suggestionList: [] }, () => this.onChangeTextLibrarySearchField(this.state.searchTextInput))}
                style={{ flexDirection: 'row', alignItems: 'center', paddingVertical: Scale(3), paddingHorizontal: Scale(13), justifyContent: 'space-between' }}>

                <View style={{ flexDirection: 'row', alignItems: 'center' }}>
                  <Image
                    style={{
                      width: Scale(15),
                      height: Scale(15),
                      tintColor: '#959595'
                    }}
                    source={searchButton} />
                  <Text numberOfLines={1} style={{ fontSize: Scale(16), marginLeft: Scale(10), fontFamily: 'Montserrat-Medium', width: Scale(235) }}>{item?.item}</Text>
                </View>
                <Image
                  style={{
                    width: Scale(15),
                    height: Scale(15),
                    tintColor: '#959595',

                  }}
                  source={SLANTARROW} />
              </TouchableOpacity>
            )
          }}
        />
      </View>
    )
  }

  renderTextInput = () => {
    return (
      <View>
        <View style={{ flexDirection: 'row', alignItems: 'center', paddingHorizontal: Scale(35), paddingVertical: Scale(10) }}>

          <Image
            style={styles.searchImage}
            source={searchButton} />

          <TextInput
            onChangeText={(text: any) => {
              this.onChangeTextLibrarySearchField(text)
              this.getAutoSuggestionList(text)
            }}
            placeholder="Search"
            placeholderTextColor="#959595"
            value={this.state.searchTextInput}
            style={styles.textInputStyle}
          />
          <TouchableOpacity

            onPress={() => this.setState({
              showCoursesFilter: !this.state.showCoursesFilter,
              showSelectFilter: false
            })}
          >
            <Image source={require('../assets/filterPng.png')}
              resizeMode={"cover"}
              style={styles.optionImg}
            />
          </TouchableOpacity>
          <TouchableOpacity
            onPress={() => {
              this.setState({ channelFilter: !this.state.channelFilter, showSelectFilter: false })
            }}
          >
            <Image source={require('../assets/sortPng.png')}
              resizeMode={"contain"}
              style={styles.optionSortImg}
            />
          </TouchableOpacity>
        </View>
        {/* <View style={{ borderWidth: Scale(0.6), borderColor: '#1A1A1A', width: Scale(305), marginLeft: Scale(25) }} /> */}
        {this.state.suggestionList &&
          this.renderSuggestionList()}
      </View>
    )
  }


  render() {
    return (
      //Merge Engine DefaultContainer
      <SafeAreaView style={styles.container}>


        <ScrollView keyboardShouldPersistTaps="always">
          <TouchableWithoutFeedback
            style={{ backgroundColor: 'red' }}
            onPress={() => {
              this.setState({ suggestionList: [] })
              // this.hideKeyboard();
            }}
          >
            {/* Merge Engine UI Engine Code */}
            <View>


              <Loader loading={this.state.isLoading} />

              <CoursesFilter
                priceData={this.state.maxPrice}
                checked
                userDetails
                priceValue={this.state.priceAmount}
                filterData={this.state.sortData}
                onApplyFilterPressed={() => { this.onApplyLibrarySortPressed(); }}
                onFilterItemSelected={(id: any) => { this.onLibrarySortItemSelected(id); }}
                onValueChangeSlider={(value: any) => this.setState({ priceAmount: value })}
                showFilter={this.state.showCoursesFilter}
                tapToCloseFilter={() => {
                  this.isFilterAppliedListAvailable();
                }}
                courseFilter={undefined}
                filterMarginTop={Platform.OS === "ios" ? "33%" : "27%"}
                isPrice={true}
              />

              <Filter
                isNewlyAdded={this.state.isNewlyAdded}
                programsOnly={this.state.isProgramOnly}
                onProgramsOnlySelected={() => this.setState({ isProgramOnly: !this.state.isProgramOnly })}
                onCoursesOnlySelected={() => this.setState({ isCoursesOnly: !this.state.isCoursesOnly })}
                onNewlyAddedSelected={() => this.setState({ isNewlyAdded: !this.state.isNewlyAdded })}
                filterPaymentData={this.state.filterPaymentData}
                checked={this.state.filterCheck}
                filterData={this.state.categoryList}
                coursesOnly={this.state.isCoursesOnly}
                onFilterItemSelected={(id: any) => this.onFilterItemSelected(id)}
                tapToCloseFilter={() => {
                  this.closeLibraryFilter();
                  let filterApplied = this.state.filterData.includes((item: any) => item.isChecked === true);
                  console.log('FILTERAPPLIED:', filterApplied);
                  if (filterApplied === true) {
                    this.setState({ filterData: this.state.filterData });
                  } else {
                    this.setState({ isLibraryFilterApplied: false });
                  }
                }}
                onFilterPaymentItemSelected={(id: any) => this.onFilterPaymentSelected(id)}
                showFilter={this.state.channelFilter}

                onTapToggleCategories={() => this.setState({ toggleCategorie: !this.state.toggleCategorie })}
                onTapTogglePayment={() => this.setState({ togglePayment: !this.state.togglePayment })}
                filterMarginTop={Platform.OS === "ios" ? "33%" : "27%"}
                onApplyFilterPressed={() => {
                  this.onApplyLibraryFilterPressed()
                }}
                categoryToggle={this.state.toggleCategorie}
                paymentToggle={this.state.togglePayment}
              />

              <AppHeader
                onLibraryFilterPressed={() => { }}
                isBack
                title={"Search"}
                onSearchBarPressed={() => { this.props.navigation.navigate("Filteritems"); }}
                onChannelFilterPressed={() => { }}
                onBackPressed={() => { this.goBack(); }}

                counting={false}
                showSearch={false}
                showCart={false}
                showDotMenu={false}
                showCounting={false}
                showFilterChannel={false}
                showLibraryFilter={false}
                libraryStateIcon={false}

                onCartIconPressed={() => { }}
                onMenuPressed={function (): void {
                }}


              />
              {this.renderTextInput()}
              {
                this.state.librarySearchPage?.collection?.data ? this.renderCoursesPrograms()
                  :
                  <View />
              }

              {
                this.state.librarySearchPage?.experts?.data ?
                  this.renderExpertProfile()
                  : <View />
              }

              {
                this.state.librarySearchPage?.channels?.data ?
                  this.renderMyChannel()
                  : <View />
              }



            </View>
            {/* Merge Engine UI Engine Code */}

          </TouchableWithoutFeedback>
        </ScrollView>

      </SafeAreaView>
      //Merge Engine End DefaultContainer
    );
  }
}
// Customizable Area End

// Customizable Area Start
const styles = StyleSheet.create({

  productBox: {
    height: 250,
    borderBottomWidth: 1,
    flexDirection: "column",
    flex: 0.5,
    borderRightWidth: 1,
    borderColor: "#ccc"
  },

  container: {
    flex: 1,
    marginLeft: "auto",
    marginRight: "auto",
    width: "100%",
    maxWidth: 650,
    backgroundColor: "#ffffffff"
  },
  sortIcon: {
    marginRight: 12
  },

  flexBox: {
    display: "flex",
    flexDirection: "row"
  },



  productImg: {
    width: "100%",
    height: "100%"
  },

  detailContent: {
    padding: 10
  },

  ImgContainer: {
    //marginBottom: 15,
    height: 150
  },

  productName: {
    paddingVertical: 5,
    fontSize: 16
  },

  price: {
    color: "#444",
    fontSize: 16,
    marginBottom: 10
  },
  rating: {
    color: "#000",
    paddingRight: 6
  },

  starBox: {
    alignItems: "center"
  },

  cardContainer: {
    shadowColor: "#000",
    shadowOffset: {
      width: 0,
      height: 2,
    },

    shadowOpacity: 0.25,
    shadowRadius: 3.84,
    elevation: 5,
    width: Dimensions.get('screen').width - 40,
    borderRadius: 20,
    marginTop: 10,
    alignSelf: 'center',
    backgroundColor: '#fff',
    flexDirection: 'row',
    flex: 1
  },

  //  channels: 
  ChannelContainer: {
    marginBottom: 10,
    backgroundColor: '#fff',
    width: '100%',

    shadowColor: "#000",
    shadowOffset: {
      width: 0,
      height: 2,
    },
    shadowOpacity: 0.25,
    shadowRadius: 3.84,
    elevation: 5,
  },

  xpandTitlecontainer: {
    flexDirection: 'row',
    padding: 5,
    alignItems: 'center',
    paddingRight: 20,
    paddingLeft: 10
  },

  ChannelSliderContainer: {
    flex: 1,
    borderRadius: 10,
    marginLeft: 18,
    marginRight: 18,
    alignSelf: 'center', justifyContent: 'center'
  },

  ChannelTitlecontainer: {
    flexDirection: 'row',
    padding: 5,
    justifyContent: 'space-between',
    alignItems: 'center',
    paddingRight: 20,
    paddingLeft: 10,
    alignSelf: 'center'
  },

  ChannelImage: {
    height: screenWidth * 0.18,
    width: '100%',
    borderRadius: 10,
    alignSelf: 'center'
  },

  channelimgcontainer: {
    width: '100%',
    borderRadius: 10,
    elevation: 5,
    alignSelf: 'center'
  },

  // textInputContainer: {

  //   flexDirection: 'row',
  //   justifyContent:'center',
  //   alignItems: 'center'
  // },

  searchImage: {
    width: Scale(20),
    height: Scale(20),
    tintColor: '#959595'
  },

  textInputStyle: {
    color: '#4F4F4F',
    width: '69%',
    fontFamily: 'Montserrat-Medium',
    fontSize: Scale(17),
    marginLeft: Scale(15),

  },

  optionImg: {
    height: Scale(24),
    width: Scale(24),
    marginLeft: Scale(20)
  },

  optionSortImg: {
    height: Scale(17),
    width: Scale(22),
    marginRight: Scale(25),
    marginLeft: Scale(10)
  },

  subtitlecontainer: {
    marginStart: 22,
    marginEnd: 10
  },

  descriptionsubtitle: {
    color: '#595959',
    fontFamily: 'Montserrat-Medium',
    fontSize: 11
  },

  seperator: {
    borderBottomWidth: 1,
    borderBottomColor: '#E4E4E4',
    width: '90%',
    alignSelf: 'center',
    marginTop: 10,
    marginBottom: 10
  }

});
// Customizable Area End
