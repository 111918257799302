import React, { useCallback, useState, SyntheticEvent } from "react";
import { Box, Typography } from "@material-ui/core";
import { Theme, createStyles, makeStyles } from "@material-ui/core/styles";
import Slider from "react-slick";
import { NoCardsImg } from "../assets";
import SavedCard from "./SavedCard.web";

interface SCards {
  selectedCardId: string;
  savedCards: any;
  onCardClick: (card: any) => void;
  handleCardDelete: (cardId: string, confirmedCardDeletion?: boolean) => void;
}

export default function SavedCards({ 
  selectedCardId,
  savedCards, 
  onCardClick, 
  handleCardDelete 
}: SCards) {
  const [dragging, setDragging] = useState(false);
  const classes = useStyles();
  const sliderSettings = {
    arrows: false,
    slidesToShow: 1.5,
    slidesToScroll: 1,
    infinite: false,
    centerMode: true,
    responsive: [
      {
        breakpoint: 450,
        settings: {
          slidesToShow: 1.5,
          slidesToScroll: 1,
        },
      },
    ],
  };

  const handleBeforeChange = useCallback(() => {
    setDragging(true);
  }, [setDragging]);

  const handleAfterChange = useCallback(() => {
    setDragging(false);
  }, [setDragging]);

  const handleCardClick = useCallback(
    (e: SyntheticEvent, card: any) => {
      if (dragging) {
        e.stopPropagation();
        return;
      }
      onCardClick(card);
    },
    [dragging]
  );

  const handleCardRemovalClick = (
    e: SyntheticEvent, 
    cardId: string, 
    confirmedCardDeletion?: boolean) => {
    e.stopPropagation();
    handleCardDelete(cardId, confirmedCardDeletion);
  }

  if (savedCards?.length === 0) {
    return (
      <>
        <Typography
          className={`${classes.secondaryHeading} ${classes.lightText}`}
        >
          You don't have any saved cards
        </Typography>
        <Box className={classes.noCardsContainer} />
      </>
    );
  }

  return (
    <>
      <Typography className={classes.secondaryHeading}>Saved cards</Typography>
      <Slider
        className="saved-cards"
        {...sliderSettings}
        beforeChange={handleBeforeChange}
        afterChange={handleAfterChange}
      >
        {savedCards?.length &&
          savedCards.map((card: any) => {
            const expMonth = card?.card_detail?.exp_month;
            const expYear = card?.card_detail?.exp_year.toString().slice(-2);
            const last4Digits = card?.card_detail?.last4;
            const cardId = card?.card_detail?.id;
            const isSelected = cardId === selectedCardId;

            return (
              <SavedCard 
                key={card.id}
                last4Digits={last4Digits}
                expMonth={expMonth}
                expYear={expYear}
                isSelected={isSelected}
                onCardClick={(e) => handleCardClick(e, card)}
                onDeleteClick={(e) => handleCardRemovalClick(e, cardId)}
                onNoBtnClick={(e) => handleCardRemovalClick(e, "")}
                onYesBtnClick={(e) => handleCardRemovalClick(e, cardId, true)}
              />
            );
          })}
      </Slider>
    </>
  );
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    secondaryHeading: {
      fontWeight: 600,
      fontSize: "18px",
      lineHeight: "27px",
      color: "#01040D",
      margin: "10px 0",
    },
    lightText: {
      color: "#627078",
    },
    noCardsContainer: {
      backgroundImage: `url(${NoCardsImg})`,
      backgroundRepeat: "no-repeat",
      borderRadius: 12,
      width: 213,
      height: 135,
      backgroundSize: "cover",
      cursor: "pointer",
    },
  })
);