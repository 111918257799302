// @ts-nocheck
import React from "react";

// Customizable Area Start
import {
  Container,
  Box,
  Input,
  Button,
  InputLabel,
  Typography,
  InputAdornment,
  IconButton,
  Switch,
  TextField,
} from "@material-ui/core";
// Customizable Area End

// Customizable Area Start
import { createTheme, ThemeProvider } from "@material-ui/core/styles";

//@ts-ignore
import { HexColorPicker } from "react-colorful";

const theme = createTheme({
  palette: {
    primary: {
      main: "#fff",
      contrastText: "#fff",
    },
  },
  typography: {
    h6: {
      fontWeight: 500,
    },
    subtitle1: {
      margin: "20px 0px",
    },
  },
});
// Customizable Area End

import ThemeBlockController, {
  Props,
  configJSON,
} from "./ThemeBlockController";

export default class ThemeBlock extends ThemeBlockController {
  // Customizable Area Start
  // Customizable Area End
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End

  render() {
    // Customizable Area Start
    return (
      <ThemeProvider theme={theme}>
        <Container maxWidth={"xl"}>
          <div style={{ display: "flex" }}>
            <div>
              <HexColorPicker
                color={this.state.backgroundColor}
                onChange={(colors: any) => this.setColor(colors)}
              />
              <Typography
                style={{
                  color: this.state.textColor,
                  fontWeight: this.state.fontWeight,
                  fontStyle: this.state.fontType,
                }}
                variant="h6"
              >
                {"Choose Background Color"}
              </Typography>
            </div>
            <div style={{ marginLeft: 20 }}>
              <HexColorPicker
                color={this.state.textColor}
                onChange={(colors: any) => this.setTextColor(colors)}
              />
              <Typography
                style={{
                  color: this.state.textColor,
                  fontWeight: this.state.fontWeight,
                  fontStyle: this.state.fontType,
                }}
                variant="h6"
              >
                {"Choose Text Color"}
              </Typography>
            </div>
            <div style={{ marginLeft: 20 }}>
              <Switch
                checked={this.state.isBold}
                label={"is Bold: "}
                color={this.state.textColor}
                onChange={() => this.setBoldType()}
              />
              <Typography
                style={{
                  color: this.state.textColor,
                  fontWeight: this.state.fontWeight,
                  fontStyle: this.state.fontType,
                }}
                variant="h6"
              >
                {"is Bold:"}
              </Typography>
            </div>
            <div style={{ marginLeft: 20 }}>
              <Switch
                label={"is Italic: "}
                checked={this.state.isEnabled}
                color={this.state.textColor}
                onChange={() => this.setIsEnabled()}
              />
              <Typography
                style={{
                  color: this.state.textColor,
                  fontWeight: this.state.fontWeight,
                  fontStyle: this.state.fontType,
                }}
                variant="h6"
              >
                {"is Etalic:"}
              </Typography>
            </div>
          </div>
          <Box
            sx={{
              display: "flex",
              fontFamily: "Roboto-Medium",
              flexDirection: "column",
              alignItems: "center",
              paddingBottom: "30px",
              background: this.state.backgroundColor,
            }}
          >
            <Typography
              style={{
                color: this.state.textColor,
                fontWeight: this.state.fontWeight,
                fontStyle: this.state.fontType,
              }}
              variant="h6"
            >
              {configJSON.labelTitleText}
            </Typography>
            <Typography
              style={{
                color: this.state.textColor,
                fontWeight: this.state.fontWeight,
                fontStyle: this.state.fontType,
              }}
              variant="subtitle1"
              component="div"
            >
              {configJSON.labelBodyText}
            </Typography>
            <Box sx={webStyle.inputStyle}>
              <InputLabel
                style={{
                  color: this.state.textColor,
                  fontWeight: this.state.fontWeight,
                  fontStyle: this.state.fontType,
                }}
                id="service-shop-name"
              >
                This is text!
              </InputLabel>
            </Box>
          </Box>
        </Container>
      </ThemeProvider>
    );
    // Customizable Area End
  }
}

// Customizable Area Start
const webStyle = {
  mainWrapper: {
    display: "flex",
    fontFamily: "Roboto-Medium",
    flexDirection: "column",
    alignItems: "center",
    paddingBottom: "30px",
    background: "#fff",
  },
  inputStyle: {
    borderBottom: "1px solid rgba(0, 0, 0, 0.6)",
    width: "100%",
    height: "100px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
  },
  buttonStyle: {
    width: "100%",
    height: "45px",
    marginTop: "40px",
    border: "none",
    backgroundColor: "rgb(98, 0, 238)",
  },
  borderColor: {
    width: "100%",
    height: "45px",
    borderColor: "blue",
  },
};
// Customizable Area End
