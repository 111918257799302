import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { imgPasswordInVisible, imgPasswordVisible } from "./assets";
import React from 'react';
import { toast } from "react-toastify";
import { Caret1, copy, edit, forwards, medal, trash } from './assets';

// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  classes?: any;
  location?: any;
  history?: any;
  match?: { params: any }
  // Customizable Area End
}

interface S {
  txtInputValue: string;
  txtSavedValue: string;
  enableField: boolean;
  // Customizable Area Start
  expanded: boolean;
  list: any;
  timeline: any;
  imgVidUrl: any;
  imgVidFile: any;
  filename: any;
  category: any;
  courseId: any;
  open: boolean;
  data: any;
  icons: any;
  toggle: boolean;
  page: any;
  rowsPerPage: any;
  image: any;
  imgUrl: any
  img: any
  file: any
  id: any
  programs: any
  tags: any
  tagText: string
  course: any
  backupPhases: any
  droppableId: string
  rewardImg: any
  achievmentImg: any
  programList: any,
  start_date_show: boolean,
  end_date_show: boolean,
  isLoading: boolean,
  notificationList: any,
  adminCollection: any,
  contentRoleList: any,
  meta: any,
  text: any,
  selectedContentRole: any
  createFolder: any,
  folderModalOpen: any,
  reward: any,
  folderData: any;
  openAlert: boolean;
  phase_type: string;
  phase_type_id: number;
  program_phase: number;
  course_phase: number;
  existing_course: any;
  reward_list: any;
  achievement_list: any;
  Achievement: any;
  openAchievement: boolean;
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class CourseCreationController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  getCategoryApiCallId: string;
  getCreateCourseApiCallId: string;
  getCreateProgramApiCallId: string;
  getProgramDetailApiCallId: string;
  getProgramUpdateApiCallId: string;
  getUpdateCourseApiCallId: string;
  getCourseDetailApiCallId: string;
  reorderPhaseContentsApiCallId: string;
  addRewardApiCallId: string;
  addAchievementApiCallId: string;
  addPreviewApiCallId: string;
  getProgramListApiCallId: string;
  getAdminNotifyListApiCallId: string;
  getContentRoleListApiCallId: string;
  createFolderApiCallId: string;
  getFolderApiCallId: string;
  getDeleteVideoApiCallId: string;
  getDeleteQuizApiCallId: string;
  getCreateObjectApiCallId: string;
  getTagListApiCallId: string;
  getDeleteObjectApiCallId: string;
  getDeleteCourseApiCallId: string;
  getExistingCourseListApiCallId: string;
  getRewardListApiCallId: string;
  getAchievementListApiCallId: string;
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.handleCategoryResponse = this.handleCategoryResponse.bind(this);
    this.handleCreateCourseResponse = this.handleCreateCourseResponse.bind(this);
    this.handleUpdateCourseResponse = this.handleUpdateCourseResponse.bind(this);
    this.handleCreateFolderOpen = this.handleCreateFolderOpen.bind(this);
    this.handleCreateFolderClose = this.handleCreateFolderClose.bind(this);
    this.handleCreateProgramResponse = this.handleCreateProgramResponse.bind(this);
    this.handleAchievementReponse = this.handleAchievementReponse.bind(this);
    this.handleRewardReponse = this.handleRewardReponse.bind(this);
    this.handleGetDeleteVideoQuizResponse = this.handleGetDeleteVideoQuizResponse.bind(this);
    this.handleFolderResponse = this.handleFolderResponse.bind(this);
    this.handleGetFolderResponse = this.handleGetFolderResponse.bind(this);
    this.handleGetProgramResponse = this.handleGetProgramResponse.bind(this);
    this.handleProgramUpdateResponse = this.handleProgramUpdateResponse.bind(this);
    this.createCategory = this.createCategory.bind(this);
    this.handleContentRoleListResponse = this.handleContentRoleListResponse.bind(this);
    this.handleAdminNotifyListResponse = this.handleAdminNotifyListResponse.bind(this);
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.NavigationPayLoadMessage),
      getName(MessageEnum.RestAPIResponceSuccessMessage)
      // Customizable Area End
    ];

    this.state = {
      txtInputValue: "",
      txtSavedValue: "A",
      enableField: false,
      // Customizable Area Start
      expanded: true,
      list: [
        { id: 1, text: "Include Launch Date", key: "include_launch_date" },
        { id: 2, text: "Add Due Date", key: "add_due_date" },
        { id: 3, text: "Notify When completed", key: "notify_when_completed" },
        { id: 4, text: "Content release Schedule", key: "content_release_schedule" },
        { id: 5, text: "Forced Learning", key: "forced_learning" },
        { id: 6, text: "Add to Xpand Collection", key: "add_to_xpand_collection" },
        { id: 7, text: "Part of a Program", key: "part_program" },
        { id: 8, text: "Add Reward", key: "add_reward" },
        { id: 9, text: "Add Achievement", key: "add_achievement" },
        { id: 10, text: "Add Preview/Ad", key: "add_preview" },
        { id: 11, text: "Make this course sellable", key: "course_sellable" }
      ],
      timeline: [
        { id: 1, text: "Pick a Prompt", key: "pick_program" },
      ],
      imgVidUrl: "",
      imgVidFile: '',
      filename: "",
      category: [],
      courseId: "",
      open: false,
      data: [
        { content: "", selected: false, file: "" },
        { content: "", selected: false, file: "" },
        { content: "", selected: false, file: "" }
      ],
      icons: [],
      toggle: false,
      page: 2,
      rowsPerPage: 5,
      image: "",
      imgUrl: "",
      id: "",
      file: "",
      img: "",
      programs: "",
      tags: [],
      tagText: "",
      course: "",
      backupPhases: {},
      droppableId: "",
      rewardImg: '',
      achievmentImg: '',
      programList: [],
      start_date_show: false,
      end_date_show: false,
      isLoading: true,
      notificationList: [],
      adminCollection: [],
      contentRoleList: [],
      reward: {},
      openAchievement: false,
      Achievement: {},
      meta: "",
      text: "",
      selectedContentRole: [],
      createFolder: '',
      folderModalOpen: false,
      folderData: '',
      openAlert: false,
      phase_type: "",
      phase_type_id: 0,
      program_phase: 0,
      course_phase: 0,
      existing_course: [],
      reward_list: [],
      achievement_list: []
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
  }

  async componentDidMount() {
    this.getAdminNotifyList("");
    this.getProgramList();
    this.getTags();
    this.getExistingCourseList({ search: "", filter: "", page: 1, per: 10 });
    this.getRewardList({ search: "", filter: "", page: 1, per: 10 })
    this.getAchievementList({ search: "", filter: "", page: 1, per: 10 })
    if (this.props?.navigation?.getParam('id') === "new") {
      this.getContentRole({ course_id: "", search: "", per: "", page: "" })
    }
    else {
      this.getContentRole({ course_id: this.props?.navigation?.getParam('id'), search: "", per: "", page: "" })
    }
    if (this.props?.location?.state?.selectedContentRole) {
      this.setState({ selectedContentRole: this.props?.location?.state?.selectedContentRole })
    }
    if (this.props?.location?.state?.formObject) {
      this.setState({ isLoading: false })
    }
    if (this.props?.navigation?.getParam('type') === "login" || this.props?.navigation?.getParam('id') === "login") {
      this.props?.navigation?.navigate('EmailAccountLoginBlock');
    }
    if (!this.props?.location?.state?.categories) {
      this.getCategory()
    }
    else {
      this.setState({ category: this.props?.location?.state?.categories });
    }
    if (this.props.navigation.getParam('id') !== "addProgram" && this.props.navigation.getParam('type') === "editProgram") {
      setTimeout(() => {
        this.getProgramDetail(this.props.navigation.getParam('id'))
      }, 2000)

    }
    else if (this.props.navigation.getParam('id') !== "new" && this.props.navigation.getParam('type') === "editCourse" && !this.props?.location?.state?.categories) {
      setTimeout(() => {
        this.getCourseDetail(this.props.navigation.getParam('id'))
        this.getAdminNotifyList(this.props.navigation.getParam('id'))
      }, 1000)

    }
    else if (window.location.pathname === `/BuildCourse/${this.props.navigation.getParam('id')}`) {
      setTimeout(() => {
        this.getCourseDetail(this.props.navigation.getParam('id'))
      }, 2000)
    }
    else if (window.location.pathname === `/BuildProgram/${this.props.navigation.getParam('id')}`) {
      setTimeout(() => {
        this.getProgramDetail(this.props.navigation.getParam('id'))
      }, 2000)
    }
    else {
      this.setState({ programs: "" })
    }
  }

  async receive(from: string, message: Message) {
    runEngine.debugLog("Message Recived", message);

    if (message.id === getName(MessageEnum.AccoutLoginSuccess)) {
      let value = message.getData(getName(MessageEnum.AuthTokenDataMessage));

      this.showAlert(
        "Change Value",
        "From: " + this.state.txtSavedValue + " To: " + value
      );

      this.setState({ txtSavedValue: value });
    }

    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );
      let responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      if (this.getCategoryApiCallId === apiRequestCallId) {
        this.handleCategoryResponse(responseJson);
      } else if (this.getCreateCourseApiCallId === apiRequestCallId) {
        this.handleCreateCourseResponse(responseJson);
      } else if (this.getCreateProgramApiCallId === apiRequestCallId) {
        this.handleCreateProgramResponse(responseJson);
      }
      else if (this.getExistingCourseListApiCallId === apiRequestCallId) {
        this.handleExistingCourseListResponse(responseJson)
      }
      else if (this.getUpdateCourseApiCallId === apiRequestCallId) {
        this.handleUpdateCourseResponse(responseJson);
      }
      else if (this.getCourseDetailApiCallId === apiRequestCallId) {
        this.handleGetCourseDetailResponse(responseJson);
      }
      else if (this.getProgramUpdateApiCallId === apiRequestCallId) {
        this.handleProgramUpdateResponse(responseJson);

      }
      else if (this.getProgramDetailApiCallId === apiRequestCallId) {
        this.handleProgramDetailResponse(responseJson)
      }
      else if (this.addRewardApiCallId === apiRequestCallId) {
        this.setState({ reward: responseJson.data }, () =>
          this.handleRewardReponse(this.state.reward))
      }
      else if (this.getRewardListApiCallId === apiRequestCallId) {
        this.handleRewardListResponse(responseJson);
      }
      else if (this.getAchievementListApiCallId === apiRequestCallId) {
        this.handleAchivementListResponse(responseJson);
      }
      else if (this.addAchievementApiCallId === apiRequestCallId) {
        this.setState({ Achievement: responseJson }, () =>
          this.handleAchievementReponse(responseJson)
        )
      }
      else if (this.getProgramListApiCallId === apiRequestCallId) {
        this.handleProgramListResponse(responseJson)
      }

      else if (this.addPreviewApiCallId === apiRequestCallId) {
        this.handlePreviewReponse(responseJson);
      }
      else if (this.createFolderApiCallId === apiRequestCallId) {
        this.handleFolderResponse(responseJson)
      }
      else if (this.getFolderApiCallId === apiRequestCallId) {
        this.handleGetFolderResponse(responseJson)
      }
      else if (this.getDeleteVideoApiCallId === apiRequestCallId) {
        this.handleGetDeleteVideoQuizResponse(responseJson)
      }
      else if (this.getDeleteQuizApiCallId === apiRequestCallId) {
        this.handleGetDeleteVideoQuizResponse(responseJson)
      }
      else if (this.getDeleteObjectApiCallId === apiRequestCallId) {
        this.handleGetDeleteObjectCourseResponse(responseJson);
      }
      else if (this.getDeleteCourseApiCallId === apiRequestCallId) {
        this.handleGetDeleteObjectCourseResponse(responseJson);
      }
      else if (this.getCreateObjectApiCallId === apiRequestCallId) {
        this.handleCreateObjectResponse(responseJson)
      }
      else if (this.getTagListApiCallId === apiRequestCallId) {
        this.handleTagListResponse(responseJson)
      }
      else if (this.reorderPhaseContentsApiCallId === apiRequestCallId) {
        // Re-order items in build course
        if (responseJson && responseJson.phases) {
          // Update sequence numbers of cur phase
          const phase = Object.keys(responseJson.phases)[0];
          const updatedPhases = { ...this.state.course?.attributes?.phases };
          updatedPhases[phase] = responseJson.phases[phase];
          this.updatePhasesToState(updatedPhases);
        } else if (!responseJson.phases) {
          // Replace the cur phase with the backup
          toast.error("Something went wrong");
          const phase = Object.keys(this.state.backupPhases)[0];
          const updatedPhases = { ...this.state.course?.attributes?.phases };
          updatedPhases[phase] = this.state.backupPhases[phase];
          this.updatePhasesToState(updatedPhases);
        }
      }
      else if (this.getAdminNotifyListApiCallId === apiRequestCallId) {
        this.handleAdminNotifyListResponse(responseJson);
      }
      else if (this.getContentRoleListApiCallId === apiRequestCallId) {
        this.handleContentRoleListResponse(responseJson);
      }
    }
    runEngine.debugLog("Message Recived", message);
    // Customizable Area End
  }

  txtInputWebProps = {
    onChangeText: (text: string) => {
      this.setState({ txtInputValue: text });
    },
    secureTextEntry: false,
  };

  txtInputMobileProps = {
    ...this.txtInputWebProps,
    autoCompleteType: "email",
    keyboardType: "email-address",
  };

  txtInputProps = this.isPlatformWeb()
    ? this.txtInputWebProps
    : this.txtInputMobileProps;

  btnShowHideProps = {
    onPress: () => {
      this.setState({ enableField: !this.state.enableField });
      this.txtInputProps.secureTextEntry = !this.state.enableField;
      this.btnShowHideImageProps.source = this.txtInputProps.secureTextEntry
        ? imgPasswordVisible
        : imgPasswordInVisible;
    },
  };

  btnShowHideImageProps = {
    source: this.txtInputProps.secureTextEntry
      ? imgPasswordVisible
      : imgPasswordInVisible,
  };

  btnExampleProps = {
    onPress: () => this.doButtonPressed(),
  };

  doButtonPressed() {
    let msg = new Message(getName(MessageEnum.AccoutLoginSuccess));
    msg.addData(
      getName(MessageEnum.AuthTokenDataMessage),
      this.state.txtInputValue
    );
    this.send(msg);
  }

  // web events
  setInputValue = (text: string) => {
    this.setState({ txtInputValue: text });
  };

  setEnableField = () => {
    this.setState({ enableField: !this.state.enableField });
  };

   handleOpenAchievement = () => {
    this.setState({openAchievement:true})
  };

  handleCloseAchievement = () => {
    this.setState({openAchievement:false})
  };

  // Customizable Area Start
  handleAdminNotifyListResponse(responseJson: any) {
    if (responseJson && responseJson.data && responseJson.data.length > 0) {
      this.setState({ notificationList: responseJson.data });
    }
  }
  handleContentRoleListResponse(responseJson: any) {
    if (responseJson && responseJson.data && responseJson.data.length > 0) {
      this.setState({ contentRoleList: responseJson.data, meta: responseJson.meta });
    } else {
      this.setState({ contentRoleList: [], meta: responseJson?.meta })
    }
  }
  handleGetFolderResponse(responseJson: any) {
    if (responseJson && responseJson.data) {
      this.setState({
        folderData: responseJson.data
      })
    }
  }
  handleTagListResponse(responseJson: any) {
    if (responseJson && responseJson.data && responseJson.data.length > 0) {
      const tags = responseJson.data?.map((item: any) => {
        return { id: item.id, type: item.type, attributes: item.attributes, isSelected: false }
      });
      this.setState({ tags: tags });
    } else {
      this.setState({ tags: [] })
    }
  }
  handleFolderResponse(responseJson: any) {
    if (responseJson) {
      this.handleCreateFolderClose()
      this.getFolderData()
    }
  }
  handleExistingCourseListResponse(responseJson: any) {
    if (responseJson && responseJson.data && responseJson.data?.length > 0) {
      const data = responseJson.data.map((item: any) => {
        return {
          type: [
            { value: "checkbox", size: "18" },
            { value: "icon", size: "40", url: item?.attributes?.thumbnail_image?.url },
          ],
          "title": { value: item?.attributes?.title, size: "100px" },
          "course_number": { value: item?.attributes?.course_number, size: "100px" },
          "expert_name": { value: item?.attributes?.expert_name, size: "100px" },
          "content_roles": { value: item?.attributes?.content_roles, size: "100px" },
          "total_chapters": { value: item?.attributes?.total_chapters, size: "50px" },
          "user_enrolled": { value: item?.attributes?.user_enrolled, size: "50px" },
          "summary": { value: "", size: "30px" },
          "id": item.id,
          "is_selected": item?.attributes?.is_selected,
          actions: [{ value: "chip", size: "40", status: item?.attributes?.status }]
        }

      });
      this.setState({ existing_course: data, meta: responseJson?.meta })
    }
    else if (responseJson && responseJson.data && responseJson.data?.length === 0) {
      toast.error("Data not found", { delay: 700 })
      this.setState({ existing_course: responseJson.data, meta: "" });
      this.getExistingCourseList({ search: "", filter: "", page: 1, per: 10 })
    }
    else {
      if (responseJson.errors) {
        let value = responseJson.errors.map((item: any) => Object.keys(item))
        toast.error(responseJson.errors[0][value[0]], {
          autoClose: 2000
        })
      }
    }
  }
  handleRewardListResponse(responseJson: any) {
    if (responseJson && responseJson.data && responseJson.data?.length > 0) {
      const data = responseJson.data.map((item: any) => {
        return {
          type: [
            { value: "checkbox", size: "18" },
            { value: "icon", size: "20", url: item?.attributes?.thumbnail_image?.url },
          ],
          "name": { value: item?.attributes?.name, size: "100px" },
          "assigned_users": { value: item?.attributes?.assigned_users?.assigned_count, size: "100px", users: item?.attributes?.assigned_users?.users },
          "earned_users": { value: item?.attributes?.earned_users?.earned_count, size: "100px", users: item?.attributes?.earned_users?.users },
          "requirement_count": { value: item?.attributes?.requirement_count, size: "100px" },
          "branch_name": { value: item?.attributes?.branch_name, size: "50px" },
          "status": { value: item?.attributes?.active, size: "50px" },
          "id": item.id,
          "is_selected": false,
          actions: [
            {
              value: "icon",
              size: "18",
              url: forwards
            },
            { value: "icon", size: "20", url: copy },
            { value: "icon", size: "20", url: edit },
            { value: "icon", size: "20", url: trash }
          ]
        }

      });
      this.setState({ reward_list: data, meta: responseJson?.meta })
    }
    else if (responseJson && responseJson.data && responseJson.data?.length === 0) {
      toast.error("Data not found", { delay: 700 })
      this.setState({ reward_list: responseJson.data, meta: "" });
      this.getRewardList({ search: "", filter: "", page: 1, per: 10 })
    }
    else {
      if (responseJson.errors) {
        let value = responseJson.errors.map((item: any) => Object.keys(item))
        toast.error(responseJson.errors[0][value[0]], {
          autoClose: 2000
        })
      }
    }
  }
  handleAchivementListResponse(responseJson: any) {
    if (responseJson && responseJson.data && responseJson.data?.length > 0) {
      const data = responseJson.data.map((item: any) => {
        return {
          type: [
            { value: "checkbox", size: "18" },
            { value: "icon", size: "20", url: item?.attributes?.thumbnail_image?.url },
          ],
          "name": { value: item?.attributes?.name, size: "100px" },
          "assigned_users": { value: item?.attributes?.assigned_users?.assigned_count, size: "100px", users: item?.attributes?.assigned_users?.users },
          "earned_users": { value: item?.attributes?.earned_users?.earned_count, size: "100px", users: item?.attributes?.earned_users?.users },
          "requirement_count": { value: item?.attributes?.requirement_count, size: "100px" },
          "branch_name": { value: item?.attributes?.branch_name, size: "50px" },
          "status": { value: item?.attributes?.active, size: "50px" },
          "id": item.id,
          "is_selected": false,
          actions: [
            {
              value: "icon",
              size: "18",
              url: forwards
            },
            { value: "icon", size: "20", url: copy },
            { value: "icon", size: "20", url: edit },
            { value: "icon", size: "20", url: trash }
          ]
        }

      });
      this.setState({ achievement_list: data, meta: responseJson?.meta })
    }
    else if (responseJson && responseJson.data && responseJson.data?.length === 0) {
      toast.error("Data not found", { delay: 700 })
      this.setState({ achievement_list: responseJson.data, meta: "" });
      this.getAchievementList({ search: "", filter: "", page: 1, per: 10 })
    }
    else {
      if (responseJson.errors) {
        let value = responseJson.errors.map((item: any) => Object.keys(item))
        toast.error(responseJson.errors[0][value[0]], {
          autoClose: 2000
        })
      }
    }
  }
  handleProgramUpdateResponse(responseJson: any) {
    if (responseJson && responseJson.data) {
      toast.success("Program update successfuly", {
        autoClose: 2000
      });
      this.props.navigation.navigate(`BuildProgram`, { id: responseJson.data.id })
    } else {
      if (responseJson.errors) {
        let value = responseJson.errors.map((item: any) => Object.keys(item))
        toast.error(responseJson.errors[0][value[0]], {
          autoClose: 2000
        })
      }
    }
  }
  createCategory = (item: any, selected: any) => {
    return {
      id: item.id,
      type: item.type,
      attributes: item.attributes,
      selected,
      featured: true,
    };
  };
  handleProgramDetailResponse(responseJson: any) {
    if (responseJson && responseJson.data) {
      const checkCategory = (id: any) => {
        const b = responseJson.data.attributes?.categories.some((pro: any) => pro.attributes.id === id);
        return b;
      };

      const { selectedContentRole = [] } = this.props?.location?.state || {};
      const contentRoles = responseJson.data.attributes?.content_roles || [];

      const items = contentRoles.map(({ name, id }: any) => ({ content_role: name, id }));
      const selectedItems = [...selectedContentRole, ...items].filter(
        ({ content_role, id }, index, a) =>
          a.findIndex((e) => content_role === e.content_role && id === e.id) === index
      );
      const categories = this.state.category?.map((item: any) => {
        let selected;
        if (this.state.course?.attributes?.categories.length === 0) {
          selected = item.selected;
        } else {
          selected = checkCategory(item.attributes.id) ? !item.selected : item.selected;
        }
        return this.createCategory(item, selected);
      });
      this.setState({ programs: responseJson.data, isLoading: false, category: categories, selectedContentRole: selectedItems });
    }
  }
  handleProgramListResponse(responseJson: any) {
    if (responseJson && responseJson.length > 0) {
      this.setState({ programList: responseJson })
    }
    else {
      this.setState({ programList: [] })
    }
  }
  handlePreviewReponse = (responseJson: any) => {
    if (responseJson && responseJson.data) {
      this.props.navigation.goBack();
    }
  }
  handleRewardReponse = (data: any) => {
    this.props.history.push(`${this.props.location.state.path}`, { data: data, Achievement:""})
  }
  handleAchievementReponse = (responseJson: any) => {
    this.props.history.push(`${this.props.location.state.path}`, { data: "", Achievement: responseJson.data})
    
  }
  handleCategoryResponse = (responseJson: any) => {
    if (responseJson && responseJson.data) {
      const category = responseJson.data && responseJson.data.length > 0 && responseJson?.data?.map((item: any) => {
        return {
          id: item.id, type: item.type, attributes: item.attributes,
          selected: false,
          featured: true
        }
      });
      this.setState({ category: category });
    } else {
      if (responseJson.errors) {
        this.setState({ category: [] })
      }
    }
  }

  handleCreateCourseResponse = (responseJson: any) => {
    if (responseJson && responseJson.data) {
      toast.success("Course create successfuly", {
        autoClose: 2000
      })
      this.props?.location?.state?.part_of_program ? this.props?.navigation?.navigate('BuildProgram', { id: this.props?.location?.state?.programId }) : this.props.navigation.navigate(`BuildCourse`, { id: responseJson.data.id })
    } else {
      if (responseJson.errors) {
        let value = responseJson.errors.map((item: any) => Object.keys(item))
        toast.error(responseJson.errors[0][value[0]], {
          autoClose: 2000
        })
      }
    }
  }

  handleCreateProgramResponse = (responseJson: any) => {
    if (responseJson && responseJson.data) {
      toast.success("Program create successfuly", {
        autoClose: 2000
      })
      this.props.navigation.navigate(`BuildProgram`, { id: responseJson.data.id })
    } else {
      if (responseJson.errors) {
        let value = responseJson.errors.map((item: any) => Object.keys(item))
        toast.error(responseJson.errors[0][value[0]], {
          autoClose: 2000
        })
      }
    }
  }

  handleUpdateCourseResponse = (responseJson: any) => {
    if (responseJson && responseJson.data) {
      toast.success("Course update successfuly", {
        autoClose: 2000
      });
      this.props.navigation.navigate(`BuildCourse`, { id: responseJson.data.id })
    } else {
      if (responseJson.errors) {
        let value = responseJson.errors.map((item: any) => Object.keys(item))
        toast.error(responseJson.errors[0][value[0]], {
          autoClose: 2000
        })
      }
      else if (responseJson.message) {
        toast.error(responseJson.message, {
          autoClose: 2000
        })
      }
    }
  }
  handleCreateObjectResponse = (responseJson: any) => {
    if (responseJson && responseJson.data) {
      toast.success("Program Object create successfuly", {
        autoClose: 2000
      });
      this.props.navigation.navigate(`BuildProgram`, { id: this.props?.navigation?.getParam('id') })
    } else {
      if (responseJson.errors) {
        let value = responseJson.errors.map((item: any) => Object.keys(item))
        toast.error(responseJson.errors[0][value[0]], {
          autoClose: 2000
        })
      }
      else if (responseJson.message) {
        toast.error(responseJson.message, {
          autoClose: 2000
        })
      }
    }
  }

  handleGetCourseDetailResponse(responseJson: any) {
    if (responseJson && responseJson.data) {
      const checkCategory = (id: any) => {
        const b = responseJson.data.attributes?.categories.some((pro: any) => pro.attributes.id === id);
        return b;
      };

      const { selectedContentRole = [] } = this.props?.location?.state || {};
      const contentRoles = responseJson.data.attributes?.content_roles || [];

      const items = contentRoles.map(({ name, id }: any) => ({ content_role: name, id }));
      const selectedItems = [...selectedContentRole, ...items].filter(
        ({ content_role, id }, index, a) =>
          a.findIndex((e) => content_role === e.content_role && id === e.id) === index
      );

      const categories = this.state.category?.map((item: any) => {
        let selected;
        if (this.state.course?.attributes?.categories.length === 0) {
          selected = item.selected;
        } else {
          selected = checkCategory(item.attributes.id) ? !item.selected : item.selected;
        }
        return this.createCategory(item, selected);
      });

      this.setState({ course: responseJson.data, isLoading: false, category: categories, selectedContentRole: selectedItems });
    }
  }

  handleGetProgramResponse(responseJson: any) {
    if (responseJson && responseJson.data) {
      toast.success("Program update successfuly", { autoClose: 2000 });
    } else {
      let value = responseJson.errors.map((item: any) => Object.keys(item))
      toast.error(responseJson.errors[0][value[0]], {
        autoClose: 2000
      })
    }
  }
  handleGetDeleteVideoQuizResponse(responseJson: any) {
    if (responseJson && responseJson.data) {
      this.getCourseDetail(this.props.navigation.getParam('id'))
    } else {
      let value = responseJson.errors.map((item: any) => Object.keys(item))
      toast.error(responseJson.errors[0][value[0]], {
        autoClose: 2000
      })
    }
  }
  handleGetDeleteObjectCourseResponse(responseJson: any) {
    if (responseJson && responseJson.data) {
      this.getProgramDetail(this.props.navigation.getParam('id'))
    } else {
      let value = responseJson.errors.map((item: any) => Object.keys(item))
      toast.error(responseJson.errors[0][value[0]], {
        autoClose: 2000
      })
    }
  }
  getTags() {
    this.doGetTagList({
      contentType: configJSON.validationApiContentType,
      method: configJSON.validationApiMethodType,
      endPoint: configJSON.getTagListApiEndPoint,
    });
  }
  doGetTagList(data: any) {
    const { contentType, method, endPoint } = data;
    const header = {
      "Content-Type": contentType,
      token: localStorage.getItem("token"),
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)

    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    this.getTagListApiCallId = requestMessage.messageId
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      endPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      method
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return requestMessage.messageId;
  }
  getExistingCourseList({ search, filter, page, per }: any) {
    this.doGetExistingCourseList({
      contentType: configJSON.validationApiContentType,
      method: configJSON.validationApiMethodType,
      endPoint: configJSON.getExistingCourseListApiEndPoint + `?search=${search}&sort=${filter}&page=${page}&per=${per}`,
    });
  }
  doGetExistingCourseList(data: any) {
    const { contentType, method, endPoint } = data;
    const header = {
      "Content-Type": contentType,
      token: localStorage.getItem("token"),
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)

    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    this.getExistingCourseListApiCallId = requestMessage.messageId
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      endPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      method
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return requestMessage.messageId;
  }
  getRewardList({ search, filter, page, per }: any) {
    this.doGetRewardList({
      contentType: configJSON.validationApiContentType,
      method: configJSON.validationApiMethodType,
      endPoint: configJSON.getRewardListApiEndPoint + `?search=${search}&sort=${filter}&page=${page}&per=${per}`,
    });
  }
  doGetRewardList(data: any) {
    const { contentType, method, endPoint } = data;
    const header = {
      "Content-Type": contentType,
      token: localStorage.getItem("token"),
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)

    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    this.getRewardListApiCallId = requestMessage.messageId
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      endPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      method
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return requestMessage.messageId;
  }
  getAchievementList({ search, filter, page, per }: any) {
    this.doGetAchievementList({
      contentType: configJSON.validationApiContentType,
      method: configJSON.validationApiMethodType,
      endPoint: configJSON.getAchievementListApiEndPoint + `?search=${search}&sort=${filter}&page=${page}&per=${per}`,
    });
  }
  doGetAchievementList(data: any) {
    const { contentType, method, endPoint } = data;
    const header = {
      "Content-Type": contentType,
      token: localStorage.getItem("token"),
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)

    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    this.getAchievementListApiCallId = requestMessage.messageId
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      endPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      method
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return requestMessage.messageId;
  }
  getCreateObject(data: any) {
    this.doCreateObject({
      contentType: undefined,
      method: configJSON.PostMethod,
      endPoint: configJSON.createObjectApiEndPoint,
      body: data
    });
  }
  doCreateObject(data: any) {
    const { contentType, method, endPoint, body } = data;
    const header = {
      // "Content-Type": contentType,
      token: localStorage.getItem("token"),
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)

    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    this.getCreateObjectApiCallId = requestMessage.messageId
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      endPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      method
    );
    body &&
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        body
      );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return requestMessage.messageId;
  }
  getProgramList() {
    this.doGetProgramList({
      contentType: configJSON.validationApiContentType,
      method: configJSON.validationApiMethodType,
      endPoint: configJSON.getProgramListApiEndPoint,
    });
  }
  doGetProgramList(data: any) {
    const { contentType, method, endPoint, body } = data;
    const header = {
      "Content-Type": contentType,
      token: localStorage.getItem("token"),
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)

    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    this.getProgramListApiCallId = requestMessage.messageId
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      endPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      method
    );
    body &&
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        body
      );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return requestMessage.messageId;
  }
  getAdminNotifyList(data: any) {
    this.doGetAdminNotifyList({
      contentType: configJSON.validationApiContentType,
      method: configJSON.validationApiMethodType,
      endPoint: data === "" ? configJSON.getAdminNotificationList : configJSON.getAdminNotificationList + `?course_id=${data}`,
    });
  }
  doGetAdminNotifyList(data: any) {
    const { contentType, method, endPoint, body } = data;
    const header = {
      "Content-Type": contentType,
      token: localStorage.getItem("token"),
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)

    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    this.getAdminNotifyListApiCallId = requestMessage.messageId
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      endPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      method
    );
    body &&
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        body
      );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return requestMessage.messageId;
  }
  getCategory() {
    if (typeof (this.props?.location?.state?.item) !== undefined) {
      this.setState({
        courseId: this.props.location?.state?.item,
      }, () => {
        //callback
      })
    }

    this.doGetCategoryUser({
      contentType: configJSON.validationApiContentType,
      method: configJSON.validationApiMethodType,
      endPoint: configJSON.categoryFetchAPiEndPoint,
    });
  }
  doGetCategoryUser(data: any) {
    const { contentType, method, endPoint, body } = data;
    const header = {
      "Content-Type": contentType,
      token: localStorage.getItem("token"),
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)

    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    this.getCategoryApiCallId = requestMessage.messageId
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      endPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      method
    );
    body &&
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        body
      );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return requestMessage.messageId;
  }
  createCourse(data: any) {
    this.doCreateCourse({
      contentType: configJSON.validationApiContentType,
      method: configJSON.exampleAPiMethod,
      endPoint: configJSON.createCourseAPiEndPoint,
      body: data
    });
  }
  doCreateCourse(data: any) {
    const { method, endPoint, body } = data;
    const header = {
      "Content-Type": undefined,
      token: localStorage.getItem("token")
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)

    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    this.getCreateCourseApiCallId = requestMessage.messageId
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      endPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      method
    );
    body &&
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        body
      );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return requestMessage.messageId;
  }
  updateCourse(data: any, id: any) {
    this.doUpdateCourse({
      contentType: configJSON.validationApiContentType,
      method: configJSON.PutMethod,
      endPoint: configJSON.updateCourseDetailApiEndPoint + `/${id}`,
      body: data
    });
  }
  doUpdateCourse(data: any) {
    const { method, endPoint, body } = data;
    const header = {
      "Content-Type": undefined,
      token: localStorage.getItem("token"),
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)

    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    this.getUpdateCourseApiCallId = requestMessage.messageId
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      endPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      method
    );
    body &&
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        body
      );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return requestMessage.messageId;
  }

  getCourseDetail(id: any) {
    this.doGetCourseDetail({
      contentType: configJSON.validationApiContentType,
      method: configJSON.validationApiMethodType,
      endPoint: configJSON.getCourseDetailApiEndPoint + `/${id}`,
    });
  }
  doGetCourseDetail(data: any) {
    const { contentType, method, endPoint } = data;
    const header = {
      "Content-Type": contentType,
      token: localStorage.getItem("token")
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)

    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    this.getCourseDetailApiCallId = requestMessage.messageId
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      endPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      method
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return requestMessage.messageId;
  }
  createProgram(data: any) {
    this.doCreateProgram({
      contentType: configJSON.validationApiContentType,
      method: configJSON.exampleAPiMethod,
      endPoint: configJSON.createProgramAPiEndPoint,
      body: data
    });
  }
  doCreateProgram(data: any) {
    const { method, endPoint, body } = data;
    const header = {
      "Content-Type": undefined,
      token: localStorage.getItem("token"),
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)

    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    this.getCreateProgramApiCallId = requestMessage.messageId
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      endPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      method
    );
    body &&
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        body
      );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return requestMessage.messageId;
  }
  updateProgram(data: any, id: any) {
    this.doUpdateProgram({
      contentType: configJSON.validationApiContentType,
      method: configJSON.PutMethod,
      endPoint: configJSON.updateProgramDetailApiEndPoint + `/${id}`,
      body: data
    });
  }
  doUpdateProgram(data: any) {
    const { method, endPoint, body } = data;
    const header = {
      "Content-Type": undefined,
      token: localStorage.getItem("token"),
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)

    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    this.getProgramUpdateApiCallId = requestMessage.messageId
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      endPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      method
    );
    body &&
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        body
      );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return requestMessage.messageId;
  }
  getProgramDetail(id: any) {
    this.doGetProgramDetail({
      contentType: configJSON.validationApiContentType,
      method: configJSON.validationApiMethodType,
      endPoint: configJSON.getProgramDetailApiEndPoint + `/${id}`,
    });
  }
  doGetProgramDetail(data: any) {
    const { contentType, method, endPoint } = data;
    const header = {
      "Content-Type": contentType,
      token: localStorage.getItem("token"),
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)

    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    this.getProgramDetailApiCallId = requestMessage.messageId
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      endPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      method
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return requestMessage.messageId;
  }
  doGetIcons(data: any) {
    const { contentType, method, endPoint, body } = data;
    const header = {
      "Content-Type": contentType,
      token: localStorage.getItem("token")
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)

    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    this.getCategoryApiCallId = requestMessage.messageId
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      endPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      method
    );
    body &&
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        body
      );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return requestMessage.messageId;
  }

  addReward(user: any) {
    console.log(this.state.data, this.props.navigation.getParam('id'), "called API ")
    let payload = new FormData()
    payload.append("reward[name]", user.name)
    payload.append("reward[description]", user.description);
    if (this.props?.location?.state?.courseId) {
      const courseId = [this.props?.location?.state?.courseId]
      courseId.forEach((ele: any) => {
        payload.append("reward[course_ids][]", ele);
      })
    }
    else if (this.props?.location?.state?.programId) {
      const programId = [this.props?.location?.state?.programId];
      programId.forEach((ele: any) => {
        payload.append("reward[program_ids][]", ele);
      });
    }
    this.state.data.forEach((value: any, index: any) => {
      if (value.content) {
        payload.append(`reward[reward_contents_attributes][${index}][content]`, value.content)
      } else if (value.file) {
        payload.append(`reward[reward_contents_attributes][${index}][image]`, value.file, value.file.name)
      }
    })
    if (this.state.rewardImg) {
      console.log("nffsfjskfnsjfnksj", this.state.rewardImg);

      payload.append("reward[thumbnail_image]", this.state.rewardImg, this.state.rewardImg.name)
    }


    const header = {
      token: localStorage.getItem('token')
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    this.addRewardApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.addRewardsApiEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      payload
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      'POST'
    );

    runEngine.sendMessage(requestMessage.id, requestMessage)
    return requestMessage.messageId;

  }

  addAchievment(user: any) {
    console.log(this.state.achievmentImg, "called API ")
    let data = new FormData()
    if (this.props?.location?.state?.courseId) {
      const courseId = [this.props?.location?.state?.courseId]
      courseId.forEach((ele: any) => {
        data.append("achievement[course_ids][]", ele);
      })
    }
    else if (this.props?.location?.state?.programId) {
      const programId = [this.props?.location?.state?.programId];
      programId.forEach((ele: any) => {
        data.append("achievement[program_ids][]", ele);
      });
    }
    data.append("achievement[name]", user.name)
    data.append("achievement[description]", user.description)
    if (this.state.achievmentImg) {
      console.log("achievment img", this.state.achievmentImg);

      data.append("achievement[thumbnail_image]", this.state.achievmentImg, this.state.achievmentImg.name)
    }


    const header = {
      token: localStorage.getItem('token')
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    this.addAchievementApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.addAchievmentApiEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      data
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      'POST'
    );

    runEngine.sendMessage(requestMessage.id, requestMessage)
    return requestMessage.messageId;

  }

  addPreview() {
    console.log(this.state.imgVidFile, this.state.filename, this.props.navigation.getParam('id'), "called API ")
    let data = new FormData()
    if (this.props?.location?.state?.courseId) {
      const courseId = [this.props?.location?.state?.courseId]
      courseId.forEach((ele: any) => {
        data.append("course[course_ids][]", ele);
      })
    }
    else if (this.props?.location?.state?.programId) {
      const programId = [this.props?.location?.state?.programId];
      programId.forEach((ele: any) => {
        data.append("course[program_ids][]", ele);
      });
    }
    data.append("course[preview_file]", this.state.imgVidFile, this.state.filename);

    const header = {
      token: localStorage.getItem('token')
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    this.addPreviewApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.updateCourseDetailApiEndPoint + `/1`
      // configJSON.updateCourseDetailApiEndPoint+ `/${this.props.navigation.getParam('id')}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      data
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      'PUT'
    );

    runEngine.sendMessage(requestMessage.id, requestMessage)
    return requestMessage.messageId;

  }

  updatePhasesToState = (newPhases: any) => {
    this.setState(curState => ({
      ...curState,
      course: {
        ...curState.course,
        attributes: {
          ...curState.course.attributes,
          phases: newPhases
        }
      }
    }));
  }

  reorderPhaseContents = (data: any) => {

    const token = localStorage.getItem("token");

    const headers = {
      "Content-Type": configJSON.validationApiContentType,
      token,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.reorderPhaseContentsApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.reorderPhaseContetnsApiEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(data)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.PostMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);

    return true;
  };

  onDragEnd = (result: any) => {
    const { source, destination, draggableId } = result;
    // Handle drop outside of droppable
    if (!destination) {
      return;
    }
    // Handle drop to the initial position
    if (destination.index === source.index) {
      return;
    }

    // Get the dragged item
    const curPhase = source.droppableId;
    const contentsOfCurPhase = [...this.state.course?.attributes?.phases[curPhase]];
    const draggedContent = contentsOfCurPhase.find(
      (content: any) => content.id.toString() === draggableId
    );

    // Take a backup of contents of the cur phase to restore the re-order
    // if network call fails
    const backupContentsOfCurPhase = [...contentsOfCurPhase];
    this.setState({
      backupPhases: {
        [curPhase]: backupContentsOfCurPhase
      }
    });

    // Re order
    contentsOfCurPhase.splice(source.index, 1);
    contentsOfCurPhase.splice(destination.index, 0, draggedContent);

    // Update UI optimisticly
    const updatedPhases = { ...this.state.course?.attributes?.phases };
    updatedPhases[curPhase] = contentsOfCurPhase;
    this.updatePhasesToState(updatedPhases);

    // Update reorder on the server
    const data = {
      data: [
        {
          id: backupContentsOfCurPhase[destination.index].id,
          type: backupContentsOfCurPhase[destination.index].type,
          sequence: backupContentsOfCurPhase[destination.index].sequence_number
        },
        {
          id: backupContentsOfCurPhase[source.index].id,
          type: backupContentsOfCurPhase[source.index].type,
          sequence: backupContentsOfCurPhase[source.index].sequence_number
        }
      ]
    }

    this.reorderPhaseContents(data);
  }

  onDragStart = (start: any) => {
    this.setState({
      droppableId: start.source.droppableId
    });
  }
  getContentRole({ course_id, search, per, page }: any) {
    this.doGetContentRole({
      contentType: configJSON.validationApiContentType,
      method: configJSON.validationApiMethodType,
      endPoint: configJSON.getContentRoleListApiEndPoint + `?course_id=${course_id}&search=${search}&per=${per}&page=${page}`,
    });
  }
  doGetContentRole(data: any) {
    const { contentType, method, endPoint, body } = data;
    const header = {
      "Content-Type": contentType,
      token: localStorage.getItem("token"),
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)

    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    this.getContentRoleListApiCallId = requestMessage.messageId
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      endPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      method
    );
    body &&
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        body
      );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return requestMessage.messageId;
  }

  createFolder() {
    console.log(this.state.createFolder, "add folder");

    let data = new FormData()
    data.append("name", this.state.createFolder)
    data.append("folder_type", "icon");

    const header = {
      token: localStorage.getItem('token')
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    this.createFolderApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.createFolderApiEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      data
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      'POST'
    );

    runEngine.sendMessage(requestMessage.id, requestMessage)
    return requestMessage.messageId;

  }
  DeleteVideo(id: number, status: any) {
    const header = {
      token: localStorage.getItem('token')
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    this.getDeleteVideoApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getDeleteVideosApiEndPoint + `/${id}`
    );

    status !== "" && requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      status
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      status === "" ? 'DELETE' : 'PUT'
    );

    runEngine.sendMessage(requestMessage.id, requestMessage)
    return requestMessage.messageId;

  }
  DeleteQuiz(id: number, status: any) {
    const header = {
      token: localStorage.getItem('token')
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    this.getDeleteQuizApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getDeleteQuizApiEndPoint + `/${id}`
    );

    status !== "" && requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      status
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      status === "" ? 'DELETE' : 'PUT'
    );

    runEngine.sendMessage(requestMessage.id, requestMessage)
    return requestMessage.messageId;

  }
  DeleteObject(id: number, status: any) {
    const header = {
      token: localStorage.getItem('token')
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    this.getDeleteObjectApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getdeleteObjectApiEndPoint + `/${id}`
    );

    status !== "" && requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      status
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      status === "" ? 'DELETE' : 'PUT'
    );

    runEngine.sendMessage(requestMessage.id, requestMessage)
    return requestMessage.messageId;
  }
  DeleteCourse(id: number, status: any) {
    const header = {
      token: localStorage.getItem('token')
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    this.getDeleteCourseApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getdeleteCourseApiEndPoint + `/${id}`
    );

    status !== "" && requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      status
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      status === "" ? 'DELETE' : 'PUT'
    );

    runEngine.sendMessage(requestMessage.id, requestMessage)
    return requestMessage.messageId;

  }
  getFolderData() {


    const header = {
      token: localStorage.getItem('token')
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    this.getFolderApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getFolderApiEndPoint + `?folder_type=icon`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      'Get'
    );

    runEngine.sendMessage(requestMessage.id, requestMessage)
    return requestMessage.messageId;

  }

  handleCreateFolderClose = () => {
    this.setState({ folderModalOpen: false })
  }

  handleCreateFolderOpen = () => {
    this.setState({ folderModalOpen: true })
  }

  // Customizable Area End
}