import React from 'react';
// Customizable Area Start
import { View, Text, TouchableOpacity, StyleSheet, Dimensions, Image, FlatList } from 'react-native';

interface IProps {
  channels: any
}
let screenWidth = Dimensions.get('window').width;
// Customizable Area End

export default function Channels(props: IProps) {
  // Customizable Area Start
  return (

    <FlatList
      data={props.channels}
      contentContainerStyle={styles.contentContainerStyle}
      numColumns={4}
      ListEmptyComponent={() => {
        return <View>
          <Text style={styles.nodatafound}>No Channels Found...</Text>
        </View>
      }}
      renderItem={({ item }: { item: any }) => {
        return <TouchableOpacity style={styles.ChannelSliderContainer} onPress={() => alert('Coming soon')}>
          <View style={styles.channelimgcontainer}>

            <Image style={styles.ChannelImage}
              source={{
                uri: item?.attributes?.cover_image ?
                  item?.attributes?.cover_image
                  : undefined
              }}
            />
          </View>

          <View>
            <Text style={styles.title} numberOfLines={2}>{item?.attributes?.title}</Text>
          </View>

        </TouchableOpacity>
      }}
    />
  );
  // Customizable Area End
}

const styles = StyleSheet.create({
  // Customizable Area Start
  ChannelSliderContainer: {
    flex: 0.25,
    padding: 10,
    borderRadius: 10,
  },

  ChannelTitlecontainer: {
    flexDirection: 'row', padding: 5, justifyContent: 'space-between', alignItems: 'center', paddingRight: 20, paddingLeft: 10, alignSelf: 'center'
  },

  ChannelImage: { height: screenWidth * 0.18, width: '100%', borderRadius: 10, alignSelf: 'center', backgroundColor: '#E2E2E2' },
  channelimgcontainer: {
    width: '100%', borderRadius: 10, elevation: 5, alignSelf: 'center'
  },
  contentContainerStyle: { marginTop: 20, justifyContent: 'center', marginStart: 18, marginEnd: 18 },
  nodatafound: { alignSelf: 'center', color: '#595959', fontFamily: 'Montserrat-Regular', fontSize: 12 },
  title: {
    fontSize: 10, textAlign: 'center', fontFamily: 'Montserrat-Bold', marginHorizontal: 5, marginTop: 5, color: '#828282'
  }
  // Customizable Area End

});