// Customizable Area Start
import React from 'react'
import {
    Box,
    Typography,
    Grid,
    Paper,
    Button,
    Card,
    CardContent,
    CircularProgress


} from "@material-ui/core";
import './SplashScreen.css'
import SplashscreenController, { Props } from './SplashscreenController.web';
import { withStyles, createStyles, Theme } from "@material-ui/core/styles";
import { Link } from 'react-router-dom'
const styles: any = (theme: Theme) =>
    createStyles({
        root: {
            width: '100%',
            backgroundColor: theme.palette.background.paper,
            position: 'relative',
            maxHeight: '100vh',

        },
        listSection: {
            backgroundColor: 'inherit',
        },
        ul: {
            backgroundColor: 'inherit',
            padding: 0,

        },
    });

class TermPage extends SplashscreenController {
    constructor(props: Props) {
        super(props);
    }
    render() {
        return (
            <>
                {this.state.isLoading ? <Box style={{ display: "flex", alignItems: "center", justifyContent: "center", height: "100vh", width: "100%" }}></Box> : <>
                    <Grid container className="splash">
                        {/* <Grid item xs={12} md={12} component={Paper} elevation={6} square> */}
                        <Box
                            sx={{
                                display: "flex",
                                flexDirection: "row",
                                alignItems: "center",
                            }}
                        >
                            <Grid container>
                                <Grid item md={12} xs={12}>
                                    <Card className="custom-card">
                                        <Typography style={{ textAlign: "center" }} component="h6" variant="h5" className="heading">
                                            Terms and Conditions
                                        </Typography>
                                        <CardContent style={{ textAlign: "center" }}>
                                            <div className='MuiList-root'
                                                dangerouslySetInnerHTML={{ __html: `${this.state.data}` }} />

                                        </CardContent>

                                    </Card>
                                    <Grid container style={{ justifyContent: "center", marginTop: "2%", marginBottom: "6%" }}>
                                        <Grid item md={6} sm={6}>
                                            {this.props?.location?.state?.path === '/signup' ? <Link to={{
                                                pathname: '/signup',
                                                state: this.props?.location?.state?.values
                                            }}><Button fullWidth className="back-btn">Back</Button></Link> :
                                                <Button fullWidth className="back-btn" onClick={this.props?.handleClose}>Back</Button>}
                                        </Grid>

                                    </Grid>
                                </Grid>

                            </Grid>


                        </Box>
                    </Grid>
                    {/* </Grid> */}
                </>}
            </>
        )
    }
}

export default (withStyles(styles)(TermPage));
// Customizable Area End