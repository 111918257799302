Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.validationApiContentType = "application/json";
exports.validationApiMethodType = "GET";
exports.createGoalAPiEndPoint = "bx_block_plan/goal";
exports.exampleAPiMethod = "POST";
exports.exampleApiContentType = "application/json";
exports.textInputPlaceHolder = "Enter Text";
exports.labelTitleText = "AutomaticReminders";
exports.labelBodyText = "AutomaticReminders Body";
exports.back = "Back";
exports.addGoals = "Add a Goals"
exports.goalName = "Goal Name"
exports.goalDescription = "Goal Description"
exports.typeOfGoal = "Type of Goal"
exports.save = "Save"
exports.btnExampleTitle = "CLICK ME";
// Customizable Area End