export const imgPasswordVisible = require("../assets/ic_password_visible.png");
export const imgPasswordInVisible = require("../assets/ic_password_invisible.png");
export const BACK_ICON = require("../assets/back.png")
export const Telegram_ICON = require("../assets/telegram.png")
export const EDIT_ICON = require("../assets/edit.png")
export const SETTING_ICON = require("../assets/setting.png")
export const Display_ICON = require("../assets/display.png")
export const RIGHT_ICON = require("../assets/right_icon.png")
export const INFO_ICON = require("../assets/info.png")
export const MESSAGE_ICON = require("../assets/message.png")
export const NOTIFICATION_ICON = require("../assets/notification.png")
export const STAR_ICON = require("../assets/star.png")

export const FLOWER_ICON = require("../assets/flower.png")
export const TROPHY_ICON = require("../assets/trophy.png")
export const IMAGE_ICON = require("../assets/image.png")
export const FITNESS_ICON = require("../assets/fitness.png")
export const GROUP_ICON = require("../assets/group.png")
export const FORWARD_ICON = require("../assets/forward.png")
export const _ICON = require("../assets/forward.png")
export const ONSWITCH_ICON = require("../assets/onswitch.png")
export const OFFSWITCH_ICON = require("../assets/offswitch.png")
export const RECENT_ICON = require("../assets/userrecently.png")
export const PHOTO_ICON = require("../assets/flower.png")
export const IconsLock = require("../assets/icons_lock.png")
export const VIDEO_ICON = require("../assets/video.png")
export const TROLLEY_ICON = require("../assets/trolley.png")
export const BACKGROUND_ICON = require("../assets/flower.png")
export const GIFT_ICON = require("../assets/gift.png")
export const PROFILE_ICON = require("../assets/profile.png")
export const MAIL_ICON = require("../assets/mail.png")
export const CUP_ICON = require("../assets/cup.png")
export const TRUE_ICON = require("../assets/true.png")
export const CROWN = require("../assets/crown.png")
export const WALLET = require("../assets/wallet.png")
export const REWARD_ICON = require("../assets/rewardIcon.png")
export const MARATHON_ICON = require("../assets/marathonicon.png")
export const PRIZE_IMG = require("../assets/prizeimg.png")
export const TAKES_ICON = require("../assets/takesicon.png")
export const SHARE = require("../assets/share.png")
export const THREE_DOT = require("../assets/threedot.png")
export const DOC = require("../assets/doc.png")
export const search = require("../assets/search.png");
export const expert1 = require("../assets/expert1.png");
export const Video_ICON = require("../assets/icon_goal.png");
export const CALENDER_ICON = require("../assets/calendericon.png");
export const CLOSE = require("../assets/close.png");
export const DELETE = require("../assets/ic_detete.png");
export const SilenceNotification = require("../assets/notifcationSlienc.png")
export const notesIcon = require("../assets/ic_nots.png")
export const playIcon = require("../assets/ic_play.png")


export const BoxImg = require("../assets/Starts.svg")
export const Reward = require("../assets/Reward.svg")
export const RightArrow = require("../assets/viewpassword.svg")
export const WhiteLogo = require("../assets/Whitelogo.svg")
export const Award = require("../assets/Awards.png")
export const CheckRewards = require("../assets/CheckRewards.png")

export const BackImg = require("../assets/Product_696-copia-1.png")
export const UserImage = require("../assets/Frame.png")
export const Starts = require("../assets/StarsNew.svg")
export const RewardRes= require("../assets/RewardRece.png")

export const Arrow = require("../assets/Arrow.svg")
export const Star = require("../assets/Star.svg")
export const Recieved = require("../assets//Recieved.svg")
export const InDesign = require("../assets/InDesign.svg")
export const Understand = require("../assets/Understand.svg")
export const habit = require("../assets/icon_goal_habit.svg")
export const achievement = require("../assets/icon_goal_achievement.svg")

export const Inprogress = require("../assets/Progress.svg")
export const Recived_yellow = require("../assets/Recived_yellow.svg")
export const Redeemed = require("../assets/Redeemed.svg")
export const Staged = require("../assets/staged.svg")
export const Gift = require("../assets/Gift.svg")
export const Spare = require("../assets/spare.svg")