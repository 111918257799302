import React, { Component } from "react";
// Customizable Area Start

import {
    Box,
    Button,
    Paper, Typography
} from "@material-ui/core";
import Accordion from '@material-ui/core/Accordion';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import Backdrop from '@material-ui/core/Backdrop';
import Card from '@material-ui/core/Card';
import CircularProgress from '@material-ui/core/CircularProgress';
import { createTheme, makeStyles } from "@material-ui/core/styles";
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import PlayCircleFilledIcon from '@material-ui/icons/PlayCircleFilled';
import PlayCircleOutlineIcon from '@material-ui/icons/PlayCircleOutline';
import ShoppingCartOutlinedIcon from '@material-ui/icons/ShoppingCartOutlined';
import Rating from '@material-ui/lab/Rating';
import './videostyles.css';
import Grid from '@material-ui/core/Grid';
import RecommendedCourses from "../../../components/src/RecommendedCourses";
const group = require("../assets/Group 8.png")

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        marginBottom: "10px",
    },
    details: {
        display: 'flex',
        flexDirection: 'column',
    },
    content: {
        flex: '1 0 auto',
    },
    controls: {
        display: 'flex',
        alignItems: 'center',
        paddingLeft: theme.spacing(1),
        paddingBottom: theme.spacing(1),
    },
    playIcon: {
        height: 38,
        width: 38,
    },
}));

const theme = createTheme({
    palette: {
        primary: {
            main: "#fff",
            contrastText: "#fff",
        },
    },
    typography: {
        h6: {
            fontWeight: 500,
        },
        subtitle1: {
            margin: "20px 0px",
        },
    },
});


import CoursePageController, { Props } from "./CoursePageController.web";
// Customizable Area End
let id: any;
export default class CoursePage extends CoursePageController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start

        // Customizable Area End
    }


    // Customizable Area Start
    // Customizable Area End

    render() {
        id = this.state.allCourseLibraryData?.data;

        const handleApi = () => {
            if (this.state.allCourseLibraryData?.data?.attributes?.button === "enroll") {
                this.postXpandLibraryData(this.state.allCourseLibraryData?.data?.id)
            }
            else if (this.state.allCourseLibraryData?.data?.attributes?.button === "cart") {
                this.addCartItem(this.state.allCourseLibraryData?.data?.id, this.state.allCourseLibraryData?.data?.type)
            } else if (this.state.allCourseLibraryData?.data?.attributes?.button === "start") {
                this.startPlay(this.state.allCourseLibraryData.data)

            }
        }
        let videoTime = parseFloat(this.state.allCourseLibraryData?.data?.attributes?.total_video_length).toFixed(0);
        const totalSeconds: any = videoTime;
        const hours = Math.floor(totalSeconds / 3600);
        const minutes = Math.floor(totalSeconds / 60);
        const seconds = totalSeconds % 60;

        function padTo2Digits(num: any) {
            return num.toString().padStart(2, '0');
        }
        const result = `${padTo2Digits(hours)}h ${padTo2Digits(minutes)}m ${padTo2Digits(seconds)}s`;
        return (
            // Customizable Area Start
            // <Sidebar>
            <>
                <Grid container style={styles.profileHeaderGrid}>
                    <Grid item lg={12} md={12} sm={12} xs={12}>
                        <Typography style={styles.profileHeader} variant="h3" className="profile_header">Course Description
                        </Typography>
                        <>
                            <Backdrop style={styles.backdrop} open={this.state.open} >
                                <CircularProgress color="inherit" />
                            </Backdrop>
                        </>
                        <Grid container spacing={2} style={styles.mainGridBox} key={this.state.allCourseLibraryData?.data?.id}>
                            <Grid item lg={8} md={8} sm={12} xs={12}>
                                <Box
                                    style={styles.mainImg_box}>
                                    <img src={this.state.allCourseLibraryData?.data?.attributes?.thumbnail_image?.url} style={styles.main_courseImg} alt="img" />
                                </Box>
                                <Box style={styles.main_titleBox}>
                                    <p
                                        style={styles.main_title}>
                                        {this.state.allCourseLibraryData?.data?.attributes?.title}</p>
                                </Box>
                                <Grid container style={styles.mainGridButton}>
                                    <Grid item lg={3} md={3} sm={3} xs={6}>
                                        <Typography style={styles.create_typo}>Created by</Typography>
                                        <Box style={styles.boxCreate}>{this.state.allCourseLibraryData?.data?.attributes?.created_by}</Box>
                                    </Grid>
                                    <Grid item lg={4} md={2} sm={3} xs={6}>
                                        <Typography style={styles.cateTypo}>Categories</Typography>
                                        <Box style={styles.box_cate}>{this.state.allCourseLibraryData?.data?.attributes?.categories} </Box>
                                    </Grid>
                                    <Grid item lg={3} md={4} sm={3} xs={6} style={styles.rattingGrid}>
                                        <Typography style={styles.rattingTypo}>Rating</Typography>
                                        <Box style={styles.rattingBox}>
                                            <span style={styles.ratting}>{this.state.allCourseLibraryData?.data?.attributes?.average_rating}</span>
                                            <Rating
                                                name='read-only'
                                                value={this.state.allCourseLibraryData?.data?.attributes?.average_rating.toFixed(2)}
                                                // value={this.state.currRating}
                                                readOnly
                                            />
                                        </Box>
                                    </Grid>
                                    <Grid item lg={2} md={3} sm={3} xs={6} >
                                        {this.state.allCourseLibraryData?.data?.attributes?.button === "start" ?
                                            <Button onClick={() => handleApi()}
                                                startIcon={<PlayCircleFilledIcon />}
                                                style={styles.start_button}
                                            >Start </Button>
                                            : <>{
                                                this.state.allCourseLibraryData.data?.attributes?.button === "subscription"
                                                    ?
                                                    <Button
                                                        onClick={() => this.addCartItem(this.state.allCourseLibraryData?.data?.id, this.state.allCourseLibraryData?.data?.type)}
                                                        style={styles.course_buy}
                                                    >Buy Subscription ${this.state.allCourseLibraryData?.data?.attributes?.price}</Button> :
                                                    <Button
                                                        onClick={() => handleApi()}
                                                        startIcon={this.state.allCourseLibraryData?.data?.attributes?.button === "enroll" ? <></> : <ShoppingCartOutlinedIcon />}
                                                        style={styles.course_start}
                                                    >{this.state.allCourseLibraryData?.data?.attributes?.button === "enroll" ? <><img src={group} /></> : <>${this.state.allCourseLibraryData?.data?.attributes?.price}</>}</Button>
                                            }</>
                                        }

                                    </Grid>
                                </Grid>
                                <p style={styles.data_detail_main}>{this.state.allCourseLibraryData?.data?.attributes?.description}</p>
                                <p
                                    style={styles.data_detailP}>
                                    What you will learn</p>
                                <p style={styles.data_detail}>{this.state.allCourseLibraryData?.data?.attributes?.learning_details}</p>
                            </Grid>
                            <Grid item lg={4} md={4} sm={12} xs={12}>
                                <Paper elevation={4} style={styles.sidebar_paper}>
                                    <h2 style={styles.mainh2_sidebar}>Curriculum</h2>
                                    <Accordion style={styles.main_Accordion}>
                                        <AccordionSummary
                                            expandIcon={<ExpandMoreIcon />}
                                            aria-controls="panel1a-content"
                                            id="panel1a-header"
                                        >
                                            <Typography>{this.state.allCourseLibraryData?.data?.attributes?.number_of_videos} Videos | {result} Total Length</Typography>
                                        </AccordionSummary>
                                        <AccordionDetails style={styles.course_detail_phase}>
                                            <h2 style={styles.phase_h2}>Phases are released  Weekly</h2>
                                            {this.state.allCourseLibraryData.data?.attributes?.content_release_schedule ?
                                                <>
                                                    {this.state.allCourseLibraryData.data?.attributes?.phases && Object.entries(this.state.allCourseLibraryData.data?.attributes?.phases).map(([k, v]: any) => {
                                                        return (
                                                            <Accordion style={styles.course_accordion}>
                                                                <AccordionSummary
                                                                    expandIcon={<ExpandMoreIcon />}
                                                                    aria-controls="panel1a-content"
                                                                    id="panel1a-header"
                                                                >
                                                                    <p style={styles.course_p}>{k}</p>
                                                                </AccordionSummary>
                                                                <AccordionDetails style={styles.course_detail}>
                                                                    {v?.videos?.map((ele: any, index: any) => {

                                                                        return (
                                                                            <Box key={index}>
                                                                                <MediaControlCard isFreeTrial={k} button={this.state.allCourseLibraryData?.data?.attributes?.button} condition={this.state.allCourseLibraryData?.data?.attributes?.is_enrolled} ButtonStart={this.startButton} index={index} ele={ele} />
                                                                            </Box>
                                                                        )
                                                                    })}
                                                                </AccordionDetails>
                                                            </Accordion>
                                                        )
                                                    })}
                                                </>
                                                :
                                                <>
                                                    {this.state.allCourseLibraryData.data?.attributes?.videos.map((ele: any, index: any) => {
                                                        return (
                                                            <Box key={index}>
                                                                <MediaControlCard button={this.state.allCourseLibraryData?.data?.attributes?.button} condition={this.state.allCourseLibraryData?.data?.attributes?.is_enrolled} ButtonStart={this.startButton} index={index} ele={ele} />
                                                            </Box>
                                                        )
                                                    })}
                                                </>
                                            }
                                        </AccordionDetails>
                                    </Accordion>
                                </Paper>
                            </Grid>
                        </Grid>

                        <Grid container>
                            <Grid item lg={12} md={12} sm={12} xs={12}>
                                <RecommendedCourses style={this.state.style} addCartItem={this.addCartItem} postXpandLibraryData={this.postXpandLibraryData} playButton={this.getCourseLibraryData} data={this.state.allCourseLibraryData?.data?.attributes?.recommended_courses} />
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </>
            // Customizable Area End
        );
    }
}

// Customizable Area Start




export function MediaControlCard({ ele, ButtonStart, condition, index, button, isFreeTrial }: any) {
    const classes = useStyles();

    return (
        <Card className={classes.root} style={styles.cardMedia}>
            <Box style={styles.media_mainBox} >
                <img
                    src={ele?.video_thumbnail}
                    style={styles.media_img}
                />
                <Box style={styles.media_boxplay}>
                    <PlayCircleOutlineIcon />
                </Box>
            </Box>
            <Box>
                <Box style={styles.media_boxtitle}>
                    {button === "start" ?
                        <h5 onClick={() => ButtonStart(ele)}
                            style={styles.media_title}>
                            {ele?.title}
                        </h5>
                        :
                        <>
                            {isFreeTrial === "free_trial" ?
                                <h5 onClick={() => ButtonStart(ele)}
                                    style={styles.media_title}>
                                    {ele?.title}
                                </h5>
                                :
                                <h5
                                    style={styles.media_title}>
                                    {ele?.title}
                                </h5>
                            }
                        </>
                    }
                    <Typography variant="subtitle1" color="textSecondary" style={styles.media_chapter}>
                        Chapter {index + 1}
                    </Typography>
                </Box>
            </Box>
        </Card>
    )
}




const styles = {
    cardMedia: {
        boxShadow: 'none'
    },
    media_mainBox: {
        position: 'relative' as 'relative',
    },
    media_img: {
        height: '65px',
        width: '106px',
        borderRadius: '9px'
    },
    media_boxplay: {
        position: 'absolute' as 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%,-50%)'
    },
    media_boxtitle: {
        marginLeft: '10px'
    },
    media_title: {
        width: '85%', margin: 'auto',
        marginLeft: '0px', fontSize: '16px',
        fontWeight: 700, lineHeight: '16px',
        marginBottom: '10px', cursor: 'pointer'
    },
    media_chapter: {
        margin: 'auto',
        fontSize: '14px',
        lineHeight: '18px',
        fontWeight: 400,
        width: '250px'
    },
    course_box: {
        height: '500px',
        maxWidth: '83vw',
        overflow: 'hidden',
        flex: 1
    },
    course_h3: {
        fontFamily: 'Montserrat'
    },
    course_detail: {
        flexDirection: 'column' as 'column',
        padding: 0,
        alignItems: 'center',
        display: 'block'
    },
    course_p: {
        fontWeight: 'normal' as 'normal',
        margin: 0,
        textTransform: 'capitalize' as 'capitalize'
    },
    course_accordion: {
        boxShadow: 'none'
    },
    phase_h2: {
        marginTop: '-5px',
        fontFamily: 'Montserrat'
    },
    course_detail_phase: {
        flexDirection: 'column' as 'column',
        padding: 0,
    },
    main_Accordion: {
        boxShadow: 'none',
        position: 'inherit' as 'inherit',
        marginTop: '-20px'
    },
    mainh2_sidebar: {
        marginTop: '-5px',
        fontFamily: 'Montserrat'
    },
    sidebar_paper: {
        marginBottom: '2rem',
        padding: '30px',
        borderRadius: '24px'
    },
    data_detail: {
        color: 'gray'
    },
    data_detailP: {
        fontSize: "20px",
        fontStyle: 'roman' as 'roman',
        fontWeight: 'bold' as 'bold',
        fontFamily: 'Montserrat' as 'Montserrat'
    },
    data_detail_main: {
        color: 'rgba(80, 80, 80, 1)',
        fontFamily: 'Montserrat',
        fontWeight: 400
    },
    course_start: {
        background: '#2b2b2b',
        color: '#fff',
        borderRadius: '17px',
        padding: '6px 18px',
        marginTop: '-30px',
        cursor: 'pointer',
        marginLeft: '-10px'
    },
    course_buy: {
        background: '#2b2b2b',
        color: '#fff',
        borderRadius: '17px',
        padding: '6px 18px',
        marginTop: '-30px',
        cursor: 'pointer',
        marginLeft: '-10px'
    },
    start_button: {
        background: '#2b2b2b',
        color: '#fff',
        width: '160px',
        height: '50px',
        borderRadius: '24px',
        padding: '6px 18px',
        marginTop: '-30px',
        cursor: 'pointer',
        marginLeft: '-10px'
    },
    ratting: {
        marginTop: '3px',
        color: '#f9b651'
    },
    rattingBox: {
        display: 'flex',
    },
    rattingTypo: {
        marginTop: '0px',
        marginBottom: '0px',
        fontSize: '13px',
        fontWeight: 600
    },
    rattingGrid: {
        marginTop: '-15px',
        height: '15px'
    },
    cateTypo: {
        fontFamily: 'Montserrat' as 'Montserrat',
        marginTop: '-10px',
        marginBottom: '15px',
        fontSize: '13px',
        fontWeight: 600
    },
    box_cate: {
        marginTop: '-12px'
    },
    boxCreate: {
        marginTop: '-9px'
    },
    create_typo: {
        fontFamily: 'Montserrat' as 'Montserrat',
        marginTop: '-10px',
        marginBottom: '15px',
        fontSize: '13px',
        fontWeight: 600
    },
    mainGridButton: {
        maxHeight: '-webkit-fill-available',
        // marginBottom: '-45px'
    },
    main_title: {
        fontSize: "20px",
        fontStyle: 'roman' as 'roman',
        fontWeight: 'bold' as 'bold',
        fontFamily: 'Montserrat' as 'Montserrat'
    },
    main_titleBox: {
        display: 'flex',
        flexDirection: 'column' as 'column'
    },
    main_courseImg: {
        width: '100%',
        height: '100%',
        borderTopRightRadius: "20px",
        borderTopLeftRadius: "20px"
    },
    mainImg_box: {
        height: "343.95px",
        borderTopRightRadius: "10px",
        borderTopLeftRadius: "10px",
    },
    mainGridBox: {
        marginTop: '15px'
    },
    backdrop: {
        zIndex: theme.zIndex.drawer + 1,
        color: '#fff'
    },
    profileHeader: {
        display: 'contents',
        fontFamily: 'Montserrat' as 'Montserrat'
    },
    profileHeaderGrid: {
        paddingTop: '0px',
        marginTop: '-15px'
    }
}


// Customizable Area End