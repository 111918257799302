// Customizable Area 
import React, { Fragment } from "react";

import {
    Button,
    // Customizable Area Start
    Grid,
    Card,
    CardContent,
    Dialog,
    DialogContent,
    Accordion,
    Typography,
    AccordionSummary,
    AccordionDetails,
    DialogActions,
    DialogTitle,
    DialogContentText,
    Switch, Box
} from "@material-ui/core";
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { createStyles, Theme, withStyles } from "@material-ui/core/styles";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
//@ts-ignore
import CourseCreationController, {
} from "./CourseCreationController.web";
import './CourseCreation.css';
import { AddQuizIcon, PlayCopy, quiz, Rectangle, ShapePng, trash, AddMore } from "./assets";
import { Link } from "react-router-dom";
import DynamicTitle from "../../../components/src/DynamicTitle";

const styles = (theme: Theme) => createStyles({
    root: {
        width: '100%',
    },
    heading: {
        fontStyle: "normal",
        fontWeight: 500,
        fontSize: "22px !important",
        lineHeight: "36px !important",
        color: "#000000",
        textTransform: "capitalize",
        [theme.breakpoints.up("sm")]: {
            fontSize: "22px",
            lineHeight: "36px !important",
        }
    },
    switch: {
        "& .MuiSwitch-colorSecondary.Mui-checked": {
            color: "#6C328B",
        },
        "& .MuiSwitch-colorSecondary.Mui-checked + .MuiSwitch-track": {
            backgroundColor: "#C4AED0",
        },
    },
    switchBase: {
        color: "#545650",
        "&$checked": {
            color: "#6C328B"
        },
        "&$checked + $track": {
            background: "#c4aed0",
            mixBlendMode: "normal",
            opacity: "0.37"
        }
    },
    track: {
        background: "#c8cbd2",
        mixBlendMode: "normal",
        opacity: "0.37"
    },

})

const draggingOverBackground = (isDraggingOver: boolean) => {
    return isDraggingOver ? "lightgray" : "white";
};

class BuildCourse extends CourseCreationController {
    updateStatus(status: any) {
        const fd = new FormData();
        fd.append('course[status]', status)
        this.updateCourse(fd, this.props.navigation?.getParam('id'))
    }
    handleChange(e: any, id: number, type: string, key: string, index: number) {
        const fd = new FormData();
        if (e.target.name === "status_video") {
            if (type === "video") {
                let items = key === "video" ? [...this.state?.course?.attributes?.videos] : [...this.state?.course?.attributes?.phases[key]];
                let item = { ...items[index] }
                item.active = !item.active
                items[index] = item;
                let array = this.state?.course
                if (key !== "video") {
                    array.attributes.phases[key][index] = items
                }
                else {
                    array.attributes.videos[index] = items
                }
                this.setState({ course: array })
                fd.append('course_video[active]', e.target.checked);
                this.DeleteVideo(id, fd);
            }
        }
        else {
            if (type === "quiz") {
                let items = key === "quiz" ? [...this.state?.course?.attributes?.videos] : [...this.state?.course?.attributes?.phases[key]];
                let item = { ...items[index] }
                item.active = !item.active
                items[index] = item;
                const array = this.state?.course
                if (key !== "quiz") {
                    array.attributes.phases[key][index] = items
                }
                else {
                    array.attributes.videos[index] = items
                }
                this.setState({ course: array })
                fd.append('course_quiz[active]', e.target.checked);
                this.DeleteQuiz(id, fd);
            }
        }
    }
    render() {
        const handleClickOpen = () => {
            this.setState({ open: true });
        };

        const handleClose = () => {
            this.setState({ open: false });
        };
        const handleClickOpenAlert = (type: string, id: number) => {
            this.setState({ openAlert: true, phase_type: type, phase_type_id: id });
        };

        const handleCloseAlert = () => {
            if (this.state.phase_type === "video") {
                this.setState({ openAlert: false });
                this.DeleteVideo(this.state.phase_type_id, "")
            }
            else if (this.state.phase_type === "quiz") {
                this.setState({ openAlert: false });
                this.DeleteQuiz(this.state.phase_type_id, "");
            }
            else {
                this.setState({ openAlert: false });
            }
        };
        const handleCloseOutsideAlert = () => {
            this.setState({ openAlert: false });
        };
        return <DragDropContext onDragStart={this.onDragStart} onDragEnd={this.onDragEnd}>
            <DynamicTitle title="Build Course" />
            <Box className="build-course">
                <Grid container spacing={4} style={{ padding: "30px 30px" }}>
                    <Box className="build-content-border">
                        {this.state.course !== "" && this.state?.course?.attributes?.content_release_schedule === true || this.state?.course?.attributes?.is_free_trial ? Object.keys(this.state.course?.attributes?.phases).map((key: any, keyIndex: number) => {
                            return <React.Fragment key={key}>
                                <Accordion className="user-box">
                                    <AccordionSummary
                                        expandIcon={<ExpandMoreIcon />}
                                        aria-controls="panel1a-content"
                                        id={key}
                                    >
                                        <Box className="accordion-summary-container">
                                            <Typography className={this.props.classes.heading}>
                                                {key.includes("_") ? `Free Trial` : `Phase ${keyIndex + 1}`}
                                            </Typography>
                                            {
                                                this.state.course?.attributes?.content_release_schedule &&
                                                <Typography className={this.props.classes.heading}>
                                                    {this.state.course?.attributes?.content_release_schedule_value}
                                                </Typography>
                                            }
                                        </Box>
                                    </AccordionSummary>
                                    <Droppable droppableId={key} isDropDisabled={key !== this.state.droppableId}>
                                        {(droppableProvided, droppableSnapshot) => {
                                            return <AccordionDetails {...droppableProvided.droppableProps} ref={droppableProvided.innerRef} style={{ flexDirection: "column" }} >
                                                {this.state.course?.attributes?.phases[key].length > 0 && this.state.course?.attributes?.phases[key].map((item: any, index: number) => {
                                                    return <Draggable draggableId={(item.id || index).toString()} index={index} key={item.id}>
                                                        {(draggableProvided, draggableSnapshot) => {
                                                            return <Box>
                                                                {item.type === "video" && <Grid container>
                                                                    <Grid item xs={12}>
                                                                        <Card
                                                                            {...draggableProvided.draggableProps}
                                                                            className={item.active ? "customize-border" : "customize-border blur-background"}
                                                                            ref={draggableProvided.innerRef}
                                                                            style={{
                                                                                ...draggableProvided.draggableProps.style,
                                                                                background: draggableSnapshot.isDragging
                                                                                    ? "#f3eded"
                                                                                    : draggingOverBackground(droppableSnapshot.isDraggingOver)
                                                                            }}
                                                                        >
                                                                            <CardContent>
                                                                                <Grid container className="build-container">
                                                                                    <Grid item xs={12} sm={6} lg={1}>
                                                                                        <img className="build-course-img" src={item.video_thumbnail}
                                                                                        />
                                                                                    </Grid>
                                                                                    <Grid item xs={12} sm={6} lg={2}>
                                                                                        <Box className="build-course-title text-center">
                                                                                            <p className="build-course-heading">Introduction</p>
                                                                                            <p className="build-course-subheading">{item.time} mins</p>
                                                                                        </Box>
                                                                                    </Grid>
                                                                                    <Grid item xs={6} sm={6} lg={2} >
                                                                                        <p className="build-course-heading">Video Name</p>
                                                                                        <p className="build-course-subheading">{item.title}</p>
                                                                                    </Grid>
                                                                                    <Grid item xs={6} sm={6} lg={1} >
                                                                                        <p className="build-course-heading">Phase #</p>
                                                                                        <p className="build-course-subheading">{item.phase}</p>
                                                                                    </Grid>
                                                                                    <Grid item xs={6} sm={6} lg={3} >
                                                                                        <p className="build-course-heading">Description</p>
                                                                                        <p className="build-course-subheading-desc">{`${item.description.slice(0, 30)}...`}</p>
                                                                                    </Grid>
                                                                                    {item.active ?
                                                                                        <Grid item xs={6} sm={6} lg={1} >
                                                                                            <p className="build-course-heading active-text">Active</p>
                                                                                            <p className="build-course-heading">
                                                                                                <Switch
                                                                                                    onChange={(e: any) => { this.handleChange(e, item.id, item.type, key, index); }}
                                                                                                    name="status_video"
                                                                                                    checked={item.active}
                                                                                                    inputProps={{ 'aria-label': "status" }}
                                                                                                    className={this.props.classes.switch}
                                                                                                    classes={{
                                                                                                        switchBase: this.props.classes.switchBase,
                                                                                                        track: this.props.classes.track,
                                                                                                    }}
                                                                                                />

                                                                                            </p>
                                                                                        </Grid>
                                                                                        :
                                                                                        <Grid item xs={6} sm={6} lg={1}>
                                                                                            <p className="build-course-heading inactive-text">Inactive</p>
                                                                                            <p className="build-course-heading">
                                                                                                <Switch
                                                                                                    onChange={(e: any) => { this.handleChange(e, item.id, item.type, key, index); }}
                                                                                                    name="status_video"
                                                                                                    checked={item.active}
                                                                                                    inputProps={{ 'aria-label': "status" }}
                                                                                                    className={this.props.classes.switch}
                                                                                                    classes={{
                                                                                                        switchBase: this.props.classes.switchBase,
                                                                                                        track: this.props.classes.track,
                                                                                                    }}
                                                                                                />

                                                                                            </p>
                                                                                        </Grid>
                                                                                    }
                                                                                    <Grid item xs={6} sm={6} lg={1} className="flex-center" style={{ paddingBottom: "20px" }}>
                                                                                        <img className="build-course-icon" src={trash} onClick={() => handleClickOpenAlert(item.type, item.id)} style={{ width: "30px", height: "30px" }} />
                                                                                    </Grid>
                                                                                    <Grid item xs={6} sm={6} lg={1} className="flex-center" style={{ paddingBottom: "20px" }} {...draggableProvided.dragHandleProps}>
                                                                                        <img className="build-course-icon" src={ShapePng} style={{ width: "30px", height: "30px" }} />
                                                                                    </Grid>
                                                                                </Grid>
                                                                            </CardContent>
                                                                        </Card>
                                                                    </Grid>
                                                                </Grid>}
                                                                {item.type === "quiz" && <Grid container>
                                                                    <Grid item md={12} xs={12}>
                                                                        <Card
                                                                            {...draggableProvided.draggableProps}
                                                                            className={item.active ? "customize-border" : "customize-border blur-background"}
                                                                            ref={draggableProvided.innerRef}
                                                                            style={{
                                                                                ...draggableProvided.draggableProps.style,
                                                                                background: draggableSnapshot.isDragging
                                                                                    ? "#f3eded"
                                                                                    : draggingOverBackground(droppableSnapshot.isDraggingOver)
                                                                            }}
                                                                        >
                                                                            <CardContent>
                                                                                <Grid container className="build-container">
                                                                                    <Grid item xs={12} sm={6} lg={1}>
                                                                                        <img className="build-course-img" src={Rectangle} />
                                                                                    </Grid>
                                                                                    <Grid item xs={12} sm={6} lg={2}>
                                                                                        <Box className="build-course-title text-center">
                                                                                            <p className="build-course-heading">Quiz Title</p>
                                                                                            <p className="build-course-subheading">{item.title}</p>
                                                                                        </Box>
                                                                                    </Grid>
                                                                                    <Grid item xs={6} sm={6} lg={2}>
                                                                                        <p className="build-course-heading">N° Of Questions</p>
                                                                                        <p className="build-course-subheading">{item.number_of_questions}</p>
                                                                                    </Grid>
                                                                                    <Grid item xs={6} sm={6} lg={1}>
                                                                                        <p className="build-course-heading">Phase #</p>
                                                                                        <p className="build-course-subheading">{item.phase}</p>
                                                                                    </Grid>
                                                                                    <Grid item xs={6} sm={6} lg={3}>
                                                                                        <p className="build-course-heading">Minimum to Pass</p>
                                                                                        <p className="build-course-subheading">{item.minimum}%</p>
                                                                                    </Grid>
                                                                                    {item.active ?
                                                                                        <Grid item xs={6} sm={6} lg={1} >
                                                                                            <p className="build-course-heading active-text">Active</p>
                                                                                            <p className="build-course-heading">
                                                                                                <Switch
                                                                                                    onChange={(e: any) => { this.handleChange(e, item.id, item.type, key, index); }}
                                                                                                    name="status_quiz"
                                                                                                    checked={item.active}
                                                                                                    inputProps={{ 'aria-label': "status" }}
                                                                                                    className={this.props.classes.switch}
                                                                                                    classes={{
                                                                                                        switchBase: this.props.classes.switchBase,
                                                                                                        track: this.props.classes.track,
                                                                                                    }}
                                                                                                />

                                                                                            </p>

                                                                                        </Grid>
                                                                                        :
                                                                                        <Grid item xs={6} sm={6} lg={1}>
                                                                                            <p className="build-course-heading inactive-text">Inactive</p>
                                                                                            <p className="build-course-heading">
                                                                                                <Switch
                                                                                                    onChange={(e: any) => { this.handleChange(e, item.id, item.type, key, index); }}
                                                                                                    name="status_quiz"
                                                                                                    checked={item.active}
                                                                                                    value={item.active}
                                                                                                    inputProps={{ 'aria-label': "status" }}
                                                                                                    className={this.props.classes.switch}
                                                                                                    classes={{
                                                                                                        switchBase: this.props.classes.switchBase,
                                                                                                        track: this.props.classes.track,
                                                                                                    }}
                                                                                                />

                                                                                            </p>

                                                                                        </Grid>
                                                                                    }
                                                                                    <Grid item xs={6} sm={6} lg={1} className="flex-center" style={{ paddingBottom: "27px" }}>
                                                                                        <img className="build-course-icon" src={trash} style={{ width: "30px", height: "30px" }} onClick={() => handleClickOpenAlert(item.type, item.id)} />
                                                                                    </Grid>
                                                                                    <Grid item xs={6} sm={6} lg={1} className="flex-center" style={{ paddingBottom: "27px" }} {...draggableProvided.dragHandleProps}>
                                                                                        <img className="build-course-icon" src={ShapePng} style={{ width: "30px", height: "30px" }} />
                                                                                    </Grid>
                                                                                </Grid>
                                                                            </CardContent>
                                                                        </Card>
                                                                    </Grid>
                                                                </Grid>}
                                                            </Box>
                                                        }}
                                                    </Draggable>
                                                })}
                                                {droppableProvided.placeholder}
                                                <Grid container>
                                                    <Grid item md={12} xs={12}>
                                                        <Card className="customize-dashed-border">
                                                            <CardContent>
                                                                <Grid item container md={12} xs={12} className="build-container">
                                                                    <Grid item md={2} xs={6} style={{ display: "flex", textAlign: "start", cursor: "pointer" }}>
                                                                        {/* <img className="build-course-img" src={AddQuizIcon}
                                                                        /> */}
                                                                        <Box className="Addmore-icon" onClick={() => { handleClickOpen(); this.setState({ course_phase: key.includes("phase") ? parseInt(key.replace("phase", "")) : 0 }) }}>
                                                                            <img width="100%" height="100%" src={AddMore} />
                                                                        </Box>
                                                                        <Box className="build-course-title">
                                                                            <p className="build-course-heading" style={{ padding: "10px 10px 20px 10px" }} onClick={() => { handleClickOpen(); this.setState({ course_phase: key.includes("phase") ? parseInt(key.replace("phase", "")) : 0 }) }}>Add more</p>
                                                                        </Box>
                                                                    </Grid>
                                                                </Grid>
                                                            </CardContent>
                                                        </Card>
                                                    </Grid>
                                                </Grid>
                                            </AccordionDetails>
                                        }}
                                    </Droppable>
                                </Accordion>
                            </React.Fragment>
                        }) :

                            <React.Fragment>
                                <Accordion className="user-box">
                                    <AccordionSummary
                                        expandIcon={<ExpandMoreIcon />}
                                        aria-controls="panel1a-content"

                                    >
                                        <Box className="accordion-summary-container">
                                            <Typography className={this.props.classes.heading}>
                                                {`Videos`}
                                            </Typography>
                                        </Box>
                                    </AccordionSummary>
                                    <Droppable
                                        droppableId={'1'} isDropDisabled={'1' !== this.state.droppableId}
                                    >
                                        {(droppableProvided, droppableSnapshot) => {
                                            return <AccordionDetails {...droppableProvided.droppableProps} ref={droppableProvided.innerRef} style={{ flexDirection: "column" }} >
                                                {this.state?.course?.attributes?.videos && this.state?.course?.attributes?.videos.length > 0 && this.state.course?.attributes?.videos.map((item: any, index: number) => {
                                                    return <Draggable draggableId={(item.id || index).toString()} index={index} key={item.id}>
                                                        {(draggableProvided, draggableSnapshot) => {
                                                            return <Box>
                                                                {item.type === "video" && <Grid container>
                                                                    <Grid item xs={12}>
                                                                        <Card
                                                                            {...draggableProvided.draggableProps}
                                                                            className={item.active ? "customize-border" : "customize-border blur-background"}
                                                                            ref={draggableProvided.innerRef}
                                                                            style={{
                                                                                ...draggableProvided.draggableProps.style,
                                                                                background: draggableSnapshot.isDragging
                                                                                    ? "#f3eded"
                                                                                    : draggingOverBackground(droppableSnapshot.isDraggingOver)
                                                                            }}
                                                                        >
                                                                            <CardContent>
                                                                                <Grid container className="build-container">
                                                                                    <Grid item xs={12} sm={6} lg={1}>
                                                                                        <img className="build-course-img" src={item.video_thumbnail}
                                                                                        />
                                                                                    </Grid>
                                                                                    <Grid item xs={12} sm={6} lg={2}>
                                                                                        <Box className="build-course-title text-center">
                                                                                            <p className="build-course-heading">Introduction</p>
                                                                                            <p className="build-course-subheading">{item.time} mins</p>
                                                                                        </Box>
                                                                                    </Grid>
                                                                                    <Grid item xs={6} sm={6} lg={2} >
                                                                                        <p className="build-course-heading">Video Name</p>
                                                                                        <p className="build-course-subheading">{item.title}</p>
                                                                                    </Grid>
                                                                                    <Grid item xs={6} sm={6} lg={1} >
                                                                                        <p className="build-course-heading">Phase #</p>
                                                                                        <p className="build-course-subheading">{item.phase}</p>
                                                                                    </Grid>
                                                                                    <Grid item xs={6} sm={6} lg={3} >
                                                                                        <p className="build-course-heading">Description</p>
                                                                                        <p className="build-course-subheading-desc">{`${item.description.slice(0, 30)}...`}</p>
                                                                                    </Grid>
                                                                                    {item.active ?
                                                                                        <Grid item xs={6} sm={6} lg={1} >
                                                                                            <p className="build-course-heading active-text">Active</p>
                                                                                            <p className="build-course-heading">
                                                                                                <Switch
                                                                                                    onChange={(e: any) => { this.handleChange(e, item.id, "video", "video", index); }}
                                                                                                    name="status_video"
                                                                                                    checked={item.active}
                                                                                                    inputProps={{ 'aria-label': "status" }}
                                                                                                    className={this.props.classes.switch}
                                                                                                    classes={{
                                                                                                        switchBase: this.props.classes.switchBase,
                                                                                                        track: this.props.classes.track,
                                                                                                    }}
                                                                                                />

                                                                                            </p>
                                                                                        </Grid>
                                                                                        :
                                                                                        <Grid item xs={6} sm={6} lg={1}>
                                                                                            <p className="build-course-heading inactive-text">Inactive</p>
                                                                                            <p className="build-course-heading">
                                                                                                <Switch
                                                                                                    onChange={(e: any) => { this.handleChange(e, item.id, "video", "video", index); }}
                                                                                                    name="status_video"
                                                                                                    checked={item.active}
                                                                                                    inputProps={{ 'aria-label': "status" }}
                                                                                                    className={this.props.classes.switch}
                                                                                                    classes={{
                                                                                                        switchBase: this.props.classes.switchBase,
                                                                                                        track: this.props.classes.track,
                                                                                                    }}
                                                                                                />

                                                                                            </p>
                                                                                        </Grid>
                                                                                    }
                                                                                    <Grid item xs={6} sm={6} lg={1} className="flex-center" style={{ paddingBottom: "20px" }}>
                                                                                        <img className="build-course-icon" src={trash} onClick={() => handleClickOpenAlert(item.type, item.id)} style={{ width: "30px", height: "30px" }} />
                                                                                    </Grid>
                                                                                    <Grid item xs={6} sm={6} lg={1} className="flex-center" style={{ paddingBottom: "20px" }} {...draggableProvided.dragHandleProps}>
                                                                                        <img className="build-course-icon" src={ShapePng} style={{ width: "30px", height: "30px" }} />
                                                                                    </Grid>
                                                                                </Grid>
                                                                            </CardContent>
                                                                        </Card>
                                                                    </Grid>
                                                                </Grid>}
                                                                {item.type === "quiz" && <Grid container>
                                                                    <Grid item md={12} xs={12}>
                                                                        <Card
                                                                            {...draggableProvided.draggableProps}
                                                                            className={item.active ? "customize-border" : "customize-border blur-background"}
                                                                            ref={draggableProvided.innerRef}
                                                                            style={{
                                                                                ...draggableProvided.draggableProps.style,
                                                                                background: draggableSnapshot.isDragging
                                                                                    ? "#f3eded"
                                                                                    : draggingOverBackground(droppableSnapshot.isDraggingOver)
                                                                            }}
                                                                        >
                                                                            <CardContent>
                                                                                <Grid container className="build-container">
                                                                                    <Grid item xs={12} sm={6} lg={1}>
                                                                                        <img className="build-course-img" src={Rectangle} />
                                                                                    </Grid>
                                                                                    <Grid item xs={12} sm={6} lg={2}>
                                                                                        <Box className="build-course-title text-center">
                                                                                            <p className="build-course-heading">Quiz Title</p>
                                                                                            <p className="build-course-subheading">{item.title}</p>
                                                                                        </Box>
                                                                                    </Grid>
                                                                                    <Grid item xs={6} sm={6} lg={2}>
                                                                                        <p className="build-course-heading">N° Of Questions</p>
                                                                                        <p className="build-course-subheading">{item.number_of_questions}</p>
                                                                                    </Grid>
                                                                                    <Grid item xs={6} sm={6} lg={1}>
                                                                                        <p className="build-course-heading">Phase #</p>
                                                                                        <p className="build-course-subheading">{item.phase}</p>
                                                                                    </Grid>
                                                                                    <Grid item xs={6} sm={6} lg={3}>
                                                                                        <p className="build-course-heading">Minimum to Pass</p>
                                                                                        <p className="build-course-subheading">{item.minimum}%</p>
                                                                                    </Grid>
                                                                                    {item.active ?
                                                                                        <Grid item xs={6} sm={6} lg={1} >
                                                                                            <p className="build-course-heading active-text">Active</p>
                                                                                            <p className="build-course-heading">
                                                                                                <Switch
                                                                                                    onChange={(e: any) => { this.handleChange(e, item.id, "quiz", "quiz", index); }}
                                                                                                    name="status_quiz"
                                                                                                    checked={item.active}
                                                                                                    inputProps={{ 'aria-label': "status" }}
                                                                                                    className={this.props.classes.switch}
                                                                                                    classes={{
                                                                                                        switchBase: this.props.classes.switchBase,
                                                                                                        track: this.props.classes.track,
                                                                                                    }}
                                                                                                />

                                                                                            </p>

                                                                                        </Grid>
                                                                                        :
                                                                                        <Grid item xs={6} sm={6} lg={1}>
                                                                                            <p className="build-course-heading inactive-text">Inactive</p>
                                                                                            <p className="build-course-heading">
                                                                                                <Switch
                                                                                                    onChange={(e: any) => { this.handleChange(e, item.id, "quiz", "quiz", index); }}
                                                                                                    name="status_quiz"
                                                                                                    checked={item.active}
                                                                                                    value={item.active}
                                                                                                    inputProps={{ 'aria-label': "status" }}
                                                                                                    className={this.props.classes.switch}
                                                                                                    classes={{
                                                                                                        switchBase: this.props.classes.switchBase,
                                                                                                        track: this.props.classes.track,
                                                                                                    }}
                                                                                                />

                                                                                            </p>

                                                                                        </Grid>
                                                                                    }
                                                                                    <Grid item xs={6} sm={6} lg={1} className="flex-center" style={{ paddingBottom: "27px" }}>
                                                                                        <img className="build-course-icon" src={trash} style={{ width: "30px", height: "30px" }} onClick={() => handleClickOpenAlert(item.type, item.id)} />
                                                                                    </Grid>
                                                                                    <Grid item xs={6} sm={6} lg={1} className="flex-center" style={{ paddingBottom: "27px" }} {...draggableProvided.dragHandleProps}>
                                                                                        <img className="build-course-icon" src={ShapePng} style={{ width: "30px", height: "30px" }} />
                                                                                    </Grid>
                                                                                </Grid>
                                                                            </CardContent>
                                                                        </Card>
                                                                    </Grid>
                                                                </Grid>}
                                                            </Box>
                                                        }}
                                                    </Draggable>
                                                })}
                                                {droppableProvided.placeholder}
                                                <Grid container>
                                                    <Grid item md={12} xs={12}>
                                                        <Card className="customize-dashed-border">
                                                            <CardContent>
                                                                <Grid item container md={12} xs={12} className="build-container">
                                                                    <Grid item md={2} xs={6} style={{ display: "flex", textAlign: "start", cursor: "pointer" }}>
                                                                        <img className="build-course-img" src={AddQuizIcon}
                                                                        />
                                                                        <Box className="build-course-title">
                                                                            <p className="build-course-heading" style={{ padding: "10px 10px 20px 10px" }}>Add more</p>
                                                                        </Box>
                                                                    </Grid>
                                                                </Grid>
                                                            </CardContent>
                                                        </Card>
                                                    </Grid>
                                                </Grid>
                                            </AccordionDetails>
                                        }}
                                    </Droppable>
                                </Accordion>
                            </React.Fragment>

                        }
                        <Grid container>
                            <Grid item md={12} xs={12}>
                                <Card className="customize-dashed-border">
                                    <CardContent>

                                        <Grid item container md={12} xs={12} className="build-container">
                                            <Grid
                                                item
                                                md={2}
                                                xs={6}
                                                style={{ display: "flex", textAlign: "start", cursor: "pointer" }}
                                                onClick={handleClickOpen}
                                            >
                                                {/* <img className="build-course-img" src={AddVideoIcon}
                                                /> */}
                                                <Box className="Addmore-icon">
                                                    <img width="100%" height="100%" src={AddMore} />
                                                </Box>
                                                <Box>
                                                    <p className="build-course-heading" style={{ padding: "10px 10px 20px 10px" }}>Add Video/Quiz</p>
                                                </Box>
                                            </Grid>
                                        </Grid>
                                    </CardContent>
                                </Card>
                            </Grid>
                        </Grid>
                    </Box>
                    <Box>
                        {this.state?.course?.attributes && this.state?.course?.attributes?.add_reward || this.state?.course?.attributes && this.state?.course?.attributes?.add_achievement ?
                            <Typography className="aft-text">After Course Complete User will Get </Typography> : ""}
                    </Box>
                    <Grid item container md={12} xs={12} spacing={4}>
                        {this.state?.course?.attributes && this.state?.course?.attributes?.add_reward && <Grid item md={6} xs={12}>
                            <p className="reward-heading">Rewards</p>
                            <Box className="customize-dashed-border" style={{ padding: "20px" }}>
                                <Grid container >
                                    {this.state.course?.attributes?.rewards.length > 0 && this.state.course?.attributes?.rewards.map((item: any) => <Fragment key={item.id}>
                                        <Grid item lg={4} md={4} sm={6} xs={12}>
                                            <Box className="reward-icon">
                                                {item.attributes.thumbnail_image && item.attributes.thumbnail_image.url ? (
                                                    <img width="100%" height="100%" src={item.attributes.thumbnail_image.url} />
                                                ) : (
                                                    <img src="" />
                                                )}
                                            </Box>
                                            <p className="reward-title">{item.attributes.name}</p>
                                        </Grid>
                                    </Fragment>)}
                                </Grid>
                            </Box>
                        </Grid>}
                        {this.state?.course?.attributes && this.state?.course?.attributes?.add_achievement && <Grid item md={6} xs={12}>
                            <p className="achieve-heading">Achievement</p>
                            <Box className="customize-dashed-border" style={{ padding: "20px" }}>
                                <Grid container>
                                    {this.state.course?.attributes?.achievements.length > 0 && this.state.course?.attributes?.achievements.map((item: any) => <Fragment key={item.id}>
                                        <Grid item lg={4} md={4} sm={6} xs={12}>
                                            {/* <img src={item.attributes.thumbnail_image.url} className="achieve-icon" />
                                            <p className="achieve-title">{item.attributes.name}</p> */}
                                            <Box className="achieve-icon">
                                                {item.attributes.thumbnail_image && item.attributes.thumbnail_image.url ? (
                                                    <img width="100%" height="100%" src={item.attributes.thumbnail_image.url} />
                                                ) : (
                                                    <img src="" />
                                                )}
                                            </Box>
                                            <p className="achieve-title">{item.attributes.name}</p>
                                        </Grid>
                                    </Fragment>)}

                                </Grid>
                            </Box>
                        </Grid>}
                    </Grid>
                </Grid>
                <Grid container >
                    <Grid item md={12} xs={12} className="flex-center" style={{ marginTop: "10px" }}>
                        <Button className="draft-btn" fullWidth onClick={() => this.updateStatus('draft')}>Save to Draft</Button>
                    </Grid>
                    <Grid item md={12} xs={12} className="flex-center">
                        <Button className="proceed-content-btn" onClick={() => this.updateStatus('xpand_collection')} fullWidth>Build Course</Button>
                    </Grid>
                </Grid>

                <Dialog
                    fullWidth={false}
                    maxWidth={"xs"}
                    //@ts-ignore
                    open={this.state.open}
                    className="custom-dialog2"
                    onClose={handleClose}
                    aria-labelledby="max-width-dialog-title"
                    id="dialog-title"
                >
                    <DialogContent style={{ background: "#6C328B", width: "400px", display: "flex" }}>
                        <Grid container md={12} xs={12} className="main-content-dialog-grid">
                            <Grid item md={6} className="custom-line" xs={12} onClick={() => { this.props.navigation.navigate('VideoAdd', { id: this.props?.navigation.getParam('id') }) }}>
                                <img src={PlayCopy} className="plain-copy" />
                                <p className="dialog-text">Video</p>
                            </Grid>
                            <Grid item md={6} xs={12} >
                                <Link to={{ pathname: "/create-quiz", state: { courseId: this.props?.navigation.getParam('id') } }}>
                                    <img src={quiz} className="quiz-icon" />
                                    <p className="dialog-text">Quiz</p>
                                </Link>
                            </Grid>
                        </Grid>
                    </DialogContent>

                </Dialog>
                <Dialog
                    open={this.state.openAlert}
                    onClose={handleCloseOutsideAlert}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                    className="custom-dialog3"
                >
                    <DialogTitle id="alert-dialog-title-delete">{"Are you sure you want to delete “NAME” from the Course/Program"}</DialogTitle>
                    <DialogContent>
                        <DialogContentText id="alert-dialog-description-delete">
                            Deleted videos or quizes can still be accessed in the content library
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={handleCloseOutsideAlert} className="no-btn">
                            No
                        </Button>
                        <Button onClick={handleCloseAlert} className="yes-btn" autoFocus>
                            Yes
                        </Button>
                    </DialogActions>
                </Dialog>
            </Box>
        </DragDropContext>
    }
}
//@ts-ignore
export default withStyles(styles)(BuildCourse);
// Customizable Area End