Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.validationApiContentType = "application/json";
exports.PostApiContenType = "multipart/form-data";
exports.validationApiMethodType = "GET";
exports.exampleAPiEndPoint = "EXAMPLE_URL";
exports.exampleAPiMethod = "POST";
exports.exampleApiContentType = "application/json";
exports.textInputPlaceHolder = "Enter Text";
exports.labelTitleText = "CustomisableUserProfiles";
exports.labelBodyText = "CustomisableUserProfiles Body";

// USER DETAILS API END POINT
exports.getUserDetailsAPiEndPoint = "/account_block/account";
exports.getUserDetailsAPiMethod = "GET";
exports.getUserDetailsApiContentType = "application/json";

// MY PROFILE                       // /bx_block_customisableuserprofiles/profiles
exports.getMyProfileAPiEndPoint = "/bx_block_customisableuserprofiles/profiles";
exports.getMyProfileModalAPiEndPoint = "/account_block/account"
exports.getMyInterestDataAPiEndPoint = "/account_block/account/my_interest";
exports.getMyProfileAPiMethod = "GET";
exports.getMyProfileApiContentType = "application/json";
exports.updateMyProfileApiEndPoint = "/account_block/account/update_profile"
exports.updateMyProfilePasswordApiEndPoint = "/account_block/account/update_password"


// MY PROFILE REWARDS
exports.getMyProfileRewardsAPiEndPoint = "/bx_block_customisableuserprofiles/profiles/rewards";
exports.getMyProfileRewardsAPiMethod = "GET";
exports.getMyProfileRewardsApiContentType = "application/json";

// All Notes End Point
exports.getAllNotesAPiEndPoint = "/bx_block_library2/notes";
exports.getAllNotesAPiMethod = "GET";
exports.getAllNotesApiContentType = "application/json";

// Recent Insights End Point
exports.getRecentInsightsAPiEndPoint = "/bx_block_customisableuserprofiles/recent_insights";
exports.getRecentInsightsAPiMethod = "GET";
exports.getRecentInsightsApiContentType = "application/json";

// Recent Activity End Point
exports.getRecentActivityAPiEndPoint = "/bx_block_activitylog/activity_logs";
exports.getRecentActivityAPiMethod = "GET";
exports.getRecentActivityApiContentType = "application/json";

// List Watched End Point
exports.getListWatchedAPiEndPoint = "/bx_block_adhocreporting/course_video_track";
exports.getAllVideoWatchDataEndPoint = "/bx_block_adhocreporting/course_video_track/video_web_track"

// add time spent traning track api
exports.getAddTimeSpentAPiEndPoint = "/bx_block_adhocreporting/training_track";

// List Time End Point
exports.getListTimeAPiEndPoint = "/bx_block_adhocreporting/training_track";
exports.getTimeSpanddataAPiEndPoint = "/bx_block_adhocreporting/training_track/web_track"

// all report End Point
exports.getAllReportsEndPoint = "/bx_block_adhocreporting/course_video_track/all_report"

// MY PROFILE Insights Overview
exports.getMyProfileRewardsAPiEndPoint = "/bx_block_customisableuserprofiles/insights";
exports.getMyProfileRewardsAPiMethod = "GET";
exports.getMyProfileRewardsApiContentType = "application/json";

// MY PROFILE Recent Insights 
exports.getMyProfileInsightsAPiEndPoint = "{{site_url}}/bx_block_customisableuserprofiles/recent_insights";
exports.getMyProfileInsightsAPiMethod = "GET";
exports.getMyProfileInsightsApiContentType = "application/json";

exports.btnExampleTitle = "CLICK ME";
// Customizable Area End