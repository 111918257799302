import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
// Customizable Area Start
import { RouteComponentProps } from "react-router-dom";
export interface Props extends RouteComponentProps {
  navigation: any;
  id: string;
}

export interface FormilValues {
  id: string,
  name: string,
  description: string,
  account_ids: string[]
}
export interface TeamUser {
  id: string;
  type: string;
  attributes: {
    activated: boolean;
    country_code: string;
    email: string;
    first_name: string;
    last_name: string;
    title: string | null;
    full_phone_number: string;
    phone_number: string;
    type: string | null;
    created_at: string;
    updated_at: string;
    account_expiration_date: string | null;
    device_id: string[];
    unique_auth_id: string | null;
    profession: string | null;
    full_name: string;
    about: string | null;
    referal_code: string | null;
    user_categories: {
      id: number;
      name: string;
    }[];
    avatar: {
      id: number;
      url: string;
    } | null;
    my_channels: any;
    courses_and_program: any;
    reviews: any;
    avg_review: any;
    extra_data: any;
  };
}

export interface TeamBuilderDetaildata {
  id: string;
  type: string;
  attributes: {
    id: number;
    name: string;
    description: string;
    admin_user_id: number;
    member_count: number;
    created_at: string;
    users: TeamUser[];
  };
}

export interface User {
  id: string;
  first_name: string;
  last_name: string;
}
type ErrorItem = {
  message: string;
};

interface ApiCallData {
  contentType: string;
  method: string;
  endPoint: string;
  body: object | null;
}
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  location: any;
  history: any;
  match: any;
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  achievementListingData: any,
  teamBuilderDetaildata: any,
  achievementDetailsData: any,
  allRewardsListingData: any,
  allRewardsDetailsData: any,
  isloading: boolean,
  error: string,
  searchTerm: string;
  selected: string;
  show: boolean;
  chartData: any;
  viewAll: any;
  arrayLen: any;
  graphData: any;
  date: any;
  thisWeekCount: any;
  lastWeekCount: any;
  thisDateWeek: any;
  lastDateWeek: any;
  statusColor:any;
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class AchievementListingController extends BlockComponent<Props, S, SS> {
  // Customizable Area Start
  achievementListingApiCallId: string = "";
  achievementDetailsApiCallId: string = "";
  allRewardsListingApiCallId: string = "";
  allRewardsDetailsApiCallId: string = "";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    this.subScribedMessages = [
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.NavigationPayLoadMessage),
      getName(MessageEnum.RestAPIResponceSuccessMessage)
      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start
      achievementListingData: [],
      teamBuilderDetaildata: {},
      achievementDetailsData: {},
      isloading: true,
      show: false,
      chartData: {},
      error: "",
      searchTerm: "",
      selected: " ",
      allRewardsListingData: {},
      allRewardsDetailsData: {},
      viewAll: true,
      arrayLen: 8,
      graphData: {},
      date: [],
      thisWeekCount: [],
      lastWeekCount: [],
      thisDateWeek: [],
      lastDateWeek: [],
      statusColor:""
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
  }
  async receive(from: string, message: Message) {
    const id = getName(MessageEnum.RestAPIResponceMessage);
    const apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));
    const responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));

    if (id !== message.id) return;

    switch (apiRequestCallId) {
      case this.achievementDetailsApiCallId:
        if (responseJson.hasOwnProperty("data")) {
          
          const thisDateWeek = responseJson?.meta?.graph_data?.this_week_data?.map((val: any) => (
            val.track_date
          ))
          const lastDateWeek = responseJson?.meta?.graph_data?.last_week_data?.map((val: any) => (
            val.track_date
          ))
          const thisWeekCount = responseJson?.meta?.graph_data?.this_week_data?.map((val: any) => (
            val.count
          ))
          const lastWeekCount = responseJson?.meta?.graph_data?.last_week_data?.map((val: any) => (
            val.count
          ))

          this.setState({
            achievementDetailsData: responseJson,
            chartData: responseJson.meta,
            thisWeekCount,
            lastWeekCount,
            thisDateWeek,
            lastDateWeek,
          });
          setTimeout(() => { this.setState({ isloading: false }) }, 2000);
          // this.props.history.push("/TeamBuilderListing")
        }
        //else {
        //   let value = responseJson.errors.map((item:ErrorItem) => Object.keys(item))
        //   toast.error(responseJson.errors[0][value[0]],{delay:2000});
        // }
        break;
      case this.achievementListingApiCallId:
        if (responseJson.hasOwnProperty("data")) {
          const thisDateWeek = responseJson?.latest_achivement?.graph_data?.this_week_data?.map((val: any) => (
            val.track_date
          ))
          const lastDateWeek = responseJson?.latest_achivement?.graph_data?.last_week_data?.map((val: any) => (
            val.track_date
          ))
          const date = [...lastDateWeek, ...thisDateWeek]

          const thisWeekCount = responseJson?.latest_achivement?.graph_data?.this_week_data?.map((val: any) => (
            val.count
          ))
          const lastWeekCount = responseJson?.latest_achivement?.graph_data?.last_week_data?.map((val: any) => (
            val.count
          ))
          this.setState({
            achievementListingData: responseJson,
            thisWeekCount: [...lastWeekCount, ...thisWeekCount],
            lastWeekCount,
            date
          });
          setTimeout(() => { this.setState({ isloading: false }) }, 2000);
        }
        break;
      case this.allRewardsListingApiCallId:
        if (responseJson.hasOwnProperty("data")) {
          this.setState({
            allRewardsListingData: responseJson
          });
          setTimeout(() => { this.setState({ isloading: false }) }, 2000);
        }
        break;
      case this.allRewardsDetailsApiCallId:
        if (responseJson.hasOwnProperty("data")) {
          this.setState({
            allRewardsDetailsData: responseJson?.data
          });
          setTimeout(() => { this.setState({ isloading: false }) }, 2000);
        }
        break;
      default:
        break;
    }
  }

  // Customizable Area Start
  async componentDidMount() {
    this.achievementListing();
    this.allRewardsListing();
  }

  apiCall = async (data: ApiCallData) => {
    const { method, endPoint, body } = data;

    const token = localStorage.getItem('token');

    const header = {
      "Content-Type": configJSON.exampleApiContentType,
      token
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      endPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      method
    );
    body && requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(body)
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return requestMessage.messageId;
  };

  allRewardsListing = async () => {
    this.allRewardsListingApiCallId = await this.apiCall({
      contentType: configJSON.validationApiContentType,
      method: configJSON.validationApiMethodType,
      endPoint: `${configJSON.allrewardsListingAPiEndPoint}`,
      body: null
    });
  }

  allRewardsDetails = async (id: any) => {
    this.allRewardsDetailsApiCallId = await this.apiCall({
      contentType: configJSON.validationApiContentType,
      method: configJSON.validationApiMethodType,
      endPoint: `${configJSON.allrewardsDetailsAPiEndPoint}${id}`,
      body: null
    });
  }

  achievementDetails = async (id: string) => {
    this.achievementDetailsApiCallId = await this.apiCall({
      contentType: configJSON.validationApiContentType,
      method: configJSON.validationApiMethodType,
      endPoint: `${configJSON.achievementDetailsAPiEndPoint}${id}`,
      body: null
    });
  }

  achievementListing = async () => {
    this.achievementListingApiCallId = await this.apiCall({
      contentType: configJSON.validationApiContentType,
      method: configJSON.validationApiMethodType,
      endPoint: configJSON.achievementListingAPiEndPoint,
      body: null
    });
  }

  achievementView = (id: any, unlocked: any) => {
    if (unlocked) {
      this.props.history.push(`/AchievementCongratulations/${id}`)
    } else {
      this.props.history.push(`/AchievementDetails/${id}`)
    }
  }

  rewardsView = (id: any) => {
    this.props.history.push(`/AllRewardsDetails/${id}`)
  }

  handleChange = (event: any) => {
    this.setState({ selected: event.target.value });
  }

  viewAllset = () => {
    const arrayLen = this.state?.achievementListingData?.data.length
    this.setState({ viewAll: false, arrayLen: arrayLen,isloading:true });
    setTimeout(() => { this.setState({ isloading: false }) }, 2000);
  }

  viewLessSet = () => {
    this.setState({ viewAll: true, arrayLen: 8,isloading:true});
    setTimeout(() => { this.setState({ isloading: false }) }, 2000);
  }


  handleNavigation = (expand_type: string, id: any, title: string) => {
    let courseUrl = '';
    if (expand_type === "Course") {
      courseUrl = `/course-detail/${expand_type}/${id}/${title.replace(/\s/g, "-")}`;
    } else if (expand_type === "Program") {
      courseUrl = `/program-detail/${expand_type}/${id}/${title.replace(/\s/g, "-")}`;
    }
    this.props.history.push(courseUrl, { name: id });
  }

  // Customizable Area End
}
