import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import {toast} from 'react-toastify';

// Customizable Area Start
import { imgPasswordInVisible, imgPasswordVisible, slider1, slider2, slider3 } from "./assets";
// Customizable Area End

export const configJSON = require("./config");  

export interface Props {
  navigation: any;
  history:any;
  location:any;
  id: string;
  // Customizable Area Start
  handleCartItemsCountInTopbar?: (count: number) => void;
  // Customizable Area End
}

interface S {
  txtInputValue: string;
  txtSavedValue: string;
  enableField: boolean;
  // Customizable Area Start
  anchorEl:any;
  libraryData:any;
  allXpandLibararyData:any;
  grapValues:any;
  postXpandLibararyData:any;
  search:any;
  cartItem:any;
  page:number;
  cartItemsCount: number;
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class XpandLibraryController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  GetXpandLibraryData:any;
  PostXpandLibraryData:any;
  AddCartItemID:any;
  GetSearchData:any;
  getCartItemsApiCallId: string;
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      // Customizable Area End
    ];

    this.state = {
      txtInputValue: "",
      txtSavedValue: "A",
      enableField: false,
      // Customizable Area Start
      anchorEl:'',
      grapValues:'Week',
      allXpandLibararyData: {},
      postXpandLibararyData:{},
      search:"",
      cartItem: true,
      page:1,
      libraryData:[
        {
          id:1,
          image_url:slider1,
          value:true,
        },
        {
          id:2,
          image_url:slider2,
          value:true,
        },
        {
          id:3,
          image_url:slider3,
          value:false,
        },
        {
          id:4,
          image_url:slider2,
          value:true,
        },
        {
          id:5,
          image_url:slider1,
          value:false,
        },
        {
          id:6,
          image_url:slider2,
          value:true,
        },
        {
          id:7,
          image_url:slider3,
          value:false,
        },
        {
          id:8,
          image_url:slider1,
          value:true,
        },
        {
          id:9,
          image_url:slider2,
          value:false,
        }
      ],
      cartItemsCount: 0,
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
  }

  async componentDidMount(){
    const open = Boolean(this.state.anchorEl);
    const id = open ? 'simple-popover' : undefined;
    this.getXpandLibraryData();
    this.getCartItems();
  }

  async receive(from: string, message: Message) {
    runEngine.debugLog("Message Received", message);
  
    const messageType = message.id;
    const dataMessage = getName(MessageEnum.RestAPIResponceDataMessage);
    const successMessage = getName(MessageEnum.RestAPIResponceSuccessMessage);

  // Customizable Area Start
    switch (messageType) {
      case getName(MessageEnum.AccoutLoginSuccess):
        this.handleAccountLoginSuccess(message);
        break;
  
      case getName(MessageEnum.RestAPIResponceMessage):
        const apiRequestCallId = message.getData(dataMessage);
        const responseJson = message.getData(successMessage);
  
        if (apiRequestCallId && responseJson) {
          switch (apiRequestCallId) {
            case this.GetXpandLibraryData:
              this.setState({ allXpandLibararyData: responseJson });
              break;
  
            case this.PostXpandLibraryData:
              if (responseJson) {
                this.getXpandLibraryData();
                toast.success('Course Add in Personal');
              }
              break;
  
            case this.GetSearchData:
              this.handleSearchDataResponse(responseJson);
              break;
  
            case this.AddCartItemID:
              this.handleAddCartItemResponse(responseJson);
              break;
  
            case this.getCartItemsApiCallId:
              this.handleGetCartItemsApiResponse(responseJson);
              break;
  
            default:
              // Handle unknown apiRequestCallId if necessary
              break;
          }
        }
        break;
  
      default:
        // Handle unknown messageType if necessary
        break;
    }
    // Customizable Area End
  }
  
  handleAccountLoginSuccess(message: Message) {
    const authTokenData = message.getData(getName(MessageEnum.AuthTokenDataMessage));
    this.showAlert(
      "Change Value",
      "From: " + this.state.txtSavedValue + " To: " + authTokenData
    );
    this.setState({ txtSavedValue: authTokenData });
  }
  
  handleSearchDataResponse(responseJson: any) {
    this.setState({ allXpandLibararyData: responseJson.collection });
    if (this.state.search === "") {
      this.getXpandLibraryData();
    }
  }
  

  txtInputWebProps = {
    onChangeText: (text: string) => {
      this.setState({ txtInputValue: text });
    },
    secureTextEntry: false,
  };

  txtInputMobileProps = {
    ...this.txtInputWebProps,
    autoCompleteType: "email",
    keyboardType: "email-address",
  };

  txtInputProps = this.isPlatformWeb()
    ? this.txtInputWebProps
    : this.txtInputMobileProps;

  btnShowHideProps = {
    onPress: () => {
      this.setState({ enableField: !this.state.enableField });
      this.txtInputProps.secureTextEntry = !this.state.enableField;
      this.btnShowHideImageProps.source = this.txtInputProps.secureTextEntry
        ? imgPasswordVisible
        : imgPasswordInVisible;
    },
  };

  btnShowHideImageProps = {
    source: this.txtInputProps.secureTextEntry
      ? imgPasswordVisible
      : imgPasswordInVisible,
  };

  btnExampleProps = {
    onPress: () => this.doButtonPressed(),
  };

  doButtonPressed() {
    let msg = new Message(getName(MessageEnum.AccoutLoginSuccess));
    msg.addData(
      getName(MessageEnum.AuthTokenDataMessage),
      this.state.txtInputValue
    );
    this.send(msg);
  }

  // web events
  setInputValue = (text: string) => {
    this.setState({ txtInputValue: text });
  };

  setEnableField = () => {
    this.setState({ enableField: !this.state.enableField });
  };

  // Customizable Area Start

  handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    this.setState({anchorEl : event.currentTarget});
  };

  tostSuccessfully = () =>{
    toast.success("Coming soon");
 }

  handleClose = () => {
    this.setState({anchorEl : null});
  };
 
  pagination = (evaent:any,value:any) => {
      this.setState({page:value}, ()=> 
        this.getXpandLibraryData()
      ) 
     
  }
  
 

  handleNavigate = (data:any) => {
    if(data.attributes.expand_type === 'Course'){
        this.props.history.push(`/course-detail/${data.attributes.expand_type}/${data.id}/${data.attributes.title.replace(/\s/g, '-')}`,{name: data})    
    }else if(data.attributes.expand_type === 'Program'){
      this.props.history.push(`/program-detail/${data.attributes.expand_type}/${data.id}/${data.attributes.title.replace(/\s/g, '-')}`,{name: data}) 
    }
}

handleSubscription = (data:any) => {
  this.props.history.push('/Subscription',{name: data})    
}

  getSearchData = async (event:any) => {
    this.setState({search:event.target.value})
    const header = {
      "Content-Type": "application/json",
      token: localStorage.getItem("token")
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.GetSearchData = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_library2/libraries/search?search=${this.state.search}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      header
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      'get'
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  getXpandLibraryData = async () => {
    const header = {
      "Content-Type": "application/json",
      token: localStorage.getItem("token")
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.GetXpandLibraryData = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_coursecreation/collections?page=${this.state.page}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      header
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      'get'
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

postplayButton = async (data:any) => {
  this.props.history.push(`/course-detail/${data.id}/${data.attributes.expand_type}/${data.attributes.title.replace(/\s/g, '-')}`,{name: data.id})
}

  addCartItem = async (id: any, type: string) => {
    const header = {
      "Content-Type": "application/json",
      token: localStorage.getItem("token"),
    };
    const httpBody =
      type == "Program"
        ? {
            program_id: id,
          }
        : {
            course_id: id,
          };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.AddCartItemID = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.addCartItemApiEndPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      header
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "Post"
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  handleAddCartItemResponse = (response: any) => {
    if (response?.errors) {
      toast.error("Something went wrong");
      return;
    }

    if (response.message) {
      toast.warning('Item already in cart');
    } else {
      toast.success('Item has been added to your cart.')
      this.setState({
        cartItemsCount: this.state.cartItemsCount + 1
      }, () => {
        this.props?.handleCartItemsCountInTopbar &&
        this.props.handleCartItemsCountInTopbar(this.state.cartItemsCount);
      });
    } 
  }



  postXpandLibraryData = async (id:any, type:string) => {
    const header = {
      "Content-Type": "application/json",
      token: localStorage.getItem("token")
    };
    const httpBody = {
      "courseable_id": id,
      "courseable_type": `BxBlockCoursecreation::${type}` // course: "BxBlockCoursecreation::Course, program: "BxBlockCoursecreation::Program"
  }
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.PostXpandLibraryData = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_library2/enroll_courses`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      header
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      'Post'
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

   // Get current cart items
   getCartItems = async () => {
    const header = {
      "Content-Type": "application/json",
      token: localStorage.getItem("token")
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getCartItemsApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.addCartItemApiEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      header
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getApiMethod
    );
    
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  // Handle get cart items api response
  handleGetCartItemsApiResponse = (response: any) => {
    if (response?.error) {
      console.error("ERROR FETCHING CART ITEMS");
    } else if (response?.message) {
      this.setState({
        cartItemsCount: 0,
      }, () => {
        this.props?.handleCartItemsCountInTopbar &&
        this.props.handleCartItemsCountInTopbar(this.state.cartItemsCount);
      });
    } else if (response?.data) {
      const oneTimePaymentCartItems = response.data.attributes.cart_item;
      const subscriptionCartItems = response.data.attributes.subscription_cart_item;
      const totalCartItemsCount = oneTimePaymentCartItems.length + subscriptionCartItems.length;
      this.setState({
        cartItemsCount: totalCartItemsCount,
      }, () => {
        this.props?.handleCartItemsCountInTopbar &&
        this.props.handleCartItemsCountInTopbar(this.state.cartItemsCount);
      });
    }
  };

  // Customizable Area End
}