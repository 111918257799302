// Customizable Area Start
import React from "react";
import {
    Box,
    Button,
    Card,
    CardActionArea,
    Paper,
    Typography,
    CardMedia,
    Modal,
    Grid,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import PlayCircleFilledIcon from '@material-ui/icons/PlayCircleFilled';
import ShoppingCartOutlinedIcon from '@material-ui/icons/ShoppingCartOutlined';
import StarIcon from '@material-ui/icons/Star';
import StarBorderIcon from '@material-ui/icons/StarBorder';
import StarHalfIcon from '@material-ui/icons/StarHalf';
import Rating from '@material-ui/lab/Rating';
import Slider from "react-slick";
import './videostyles.css';
import ProgramIncludes from "./components/ProgramIncludes.web";
import Loader from "../../../components/src/Loader.web";
import { play, img3, group, objectIcon } from "./assets";


const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
    },
    details: {
        display: 'flex',
        flexDirection: 'column',
    },
    content: {
        flex: '1 0 auto',
    },
    controls: {
        display: 'flex',
        alignItems: 'center',
        paddingLeft: theme.spacing(1),
        paddingBottom: theme.spacing(1),
    },
    playIcon: {
        height: 38,
        width: 38,
    },
}));

import ProgramPageController, {
    Props
} from "./ProgramPageController.web";

// Customizable Area End
export default class ProgramPage extends ProgramPageController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start

        // Customizable Area End
    }

    // Customizable Area Start

    // Customizable Area End

    render() {
        const totalCourses = this.state.allProgramLibraryData?.data?.attributes?.total_courses ?? 0;
        const totalObjects: number = this.state.allProgramLibraryData?.data?.attributes?.total_objects ?? 0;

        const handleApi = () => {
            if (this.state.allProgramLibraryData?.data?.attributes?.button === "enroll") {
                this.postXpandLibraryData(this.state.allProgramLibraryData?.data?.id)
            }
            else if (this.state.allProgramLibraryData?.data?.attributes?.button === "cart") {
                this.addCartItem(this.state.allProgramLibraryData?.data?.id, this.state.allProgramLibraryData?.data?.type)
            } else if (this.state.allProgramLibraryData?.data?.attributes?.button === "start") {
                this.startPlay(this.state.allProgramLibraryData)

            }
        }

        return (
            // Customizable Area Start
            <>
                <Loader loading={this.state.isLoading} />
                <Grid container key={this.state.allProgramLibraryData.data?.id}>
                    <Grid item lg={12} md={12} sm={12} xs={12} style={{ marginTop: '-14px' }}>
                        <Typography variant="h3" className="profile_header" style={{ display: 'contents' }}>Program Description
                        </Typography>

                        <Grid container spacing={2} style={{ marginTop: '20px' }}>

                            <Grid item lg={8} xs={12}>
                                <Box
                                    style={{
                                        width: "100%",
                                        height: "300px",
                                        borderTopRightRadius: "10px",
                                        borderTopLeftRadius: "10px",

                                    }}>
                                    <img src={this.state.allProgramLibraryData.data?.attributes?.thumbnail_image?.url} style={{ width: '100%', height: '300px', borderTopRightRadius: "20px", borderTopLeftRadius: "20px" }} alt="img" />
                                </Box>
                                <p
                                    style={{
                                        fontSize: "20px",
                                        fontStyle: "roman",
                                        fontWeight: "bold",
                                    }}>
                                    {this.state.allProgramLibraryData.data?.attributes?.title}</p>
                                <Grid container style={{ height: '100px', marginBottom: '-45px' }}>
                                    <Grid item lg={4} md={3} sm={3} xs={6}>
                                        <Typography style={{ marginTop: '-10px', marginBottom: '15px', fontSize: '13px', fontWeight: 600 }}>Created by</Typography>
                                        <Box style={{ marginTop: '-9px' }}>{this.state.allProgramLibraryData.data?.attributes?.created_by}</Box>
                                    </Grid>
                                    <Grid item lg={3} md={2} sm={3} xs={6}>
                                        <Typography style={{ marginTop: '-10px', marginBottom: '15px', fontSize: '13px', fontWeight: 600 }}>Categories</Typography>
                                        <Box style={{ marginTop: '-12px' }}>{this.state.allProgramLibraryData?.data?.attributes?.categories} </Box>
                                    </Grid>
                                    <Grid item lg={3} md={4} sm={3} xs={6} style={{ marginTop: '-15px' }}>
                                        <Typography style={{ marginTop: '0px', marginBottom: '0px', fontSize: '13px', fontWeight: 600 }}>Rating</Typography>
                                        <Box style={{ display: 'inline-flex' }}>
                                            <span style={{ marginTop: '3px', color: '#f9b651' }}>{this.state.allProgramLibraryData?.data?.attributes?.average_rating}</span>
                                            <Rating
                                                name='read-only'
                                                value={this.state.allProgramLibraryData?.data?.attributes?.average_rating}


                                            />

                                        </Box>
                                    </Grid>
                                    <Grid item lg={2} md={3} sm={3} xs={6} >
                                        {this.state.allProgramLibraryData.data?.attributes?.is_enrolled ?
                                            <Button onClick={() => handleApi()}
                                                startIcon={<PlayCircleFilledIcon />}
                                                style={{
                                                    background: '#2b2b2b', color: '#fff',
                                                    width: '160px',
                                                    height: '50px',
                                                    borderRadius: '24px',
                                                    padding: '6px 18px',
                                                    marginTop: '-27px',
                                                    cursor: 'pointer',
                                                    marginLeft: '-10px'
                                                }}
                                            >Start</Button>
                                            :
                                            <>{
                                                this.state.allProgramLibraryData.data?.attributes?.button === "subscription"
                                                    ?
                                                    <Button
                                                        onClick={() => this.addCartItem(this.state.allProgramLibraryData?.data?.id, this.state.allProgramLibraryData?.data?.type)}
                                                        style={{
                                                            background: '#2b2b2b', color: '#fff',
                                                            borderRadius: '17px',
                                                            padding: '6px 18px',
                                                            marginTop: '-30px',
                                                            cursor: 'pointer',
                                                            marginLeft: '-10px'
                                                        }}
                                                    >Buy Subscription ${this.state.allProgramLibraryData?.data?.attributes?.price}</Button> :

                                                    <Button onClick={() => handleApi()}
                                                        startIcon={<ShoppingCartOutlinedIcon />}
                                                        style={{
                                                            background: '#2b2b2b', color: '#fff',
                                                            borderRadius: '17px',
                                                            padding: '6px 18px',
                                                            marginTop: '-27px',
                                                            cursor: 'pointer',
                                                            marginLeft: '-10px'
                                                        }}
                                                    >${this.state.allProgramLibraryData.data?.attributes?.price}</Button>
                                            }</>
                                        }



                                    </Grid>
                                </Grid>
                                <p>{this.state.allProgramLibraryData.data?.attributes?.description}</p>

                                <p
                                    style={{
                                        fontSize: "20px",
                                        fontStyle: "roman",
                                        fontWeight: "bold",
                                    }}>
                                    What you will learn</p>

                                <p>{this.state.allProgramLibraryData.data?.attributes?.learning_details}</p>

                            </Grid>
                            <Grid item lg={4} xs={12}>
                                <ProgramIncludes 
                                    totalCourses={totalCourses}
                                    totalObjects={totalObjects}
                                    phases={this.state.allProgramLibraryData.data?.attributes?.phases}
                                    programIncludes={this.state.allProgramLibraryData?.data?.attributes?.program_includes}
                                    phaseReleaseSchedule={this.state.allProgramLibraryData?.data?.attributes?.content_release_schedule_value}
                                    isEnrolled={this.state.allProgramLibraryData?.data?.attributes?.is_enrolled}
                                    handleNavigate={this.handleNavigate}
                                    handleObjectNavigation={this.handleObjectNavigation}
                                />
                            </Grid>



                        </Grid>

                        <Grid container>
                            <Grid item lg={12} md={12} sm={12} xs={12} style={{ marginTop: '20px' }}>
                                <Box style={{ height: '500px', maxWidth: '83vw', overflow: 'hidden', flex: 1 }}>
                                    <h3>Recommended courses for you!</h3>
                                    <SliderComponent style={this.state.style} data={this.state.allProgramLibraryData?.data?.attributes?.recommended_courses} type='buttons' />
                                </Box>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </>
            // Customizable Area End
        );
    }
}

// Customizable Area Start
const webStyle = {
    mainWrapper: {
        display: "flex",
        fontFamily: "Roboto-Medium",
        flexDirection: "column",
        alignItems: "center",
        paddingBottom: "30px",
        background: "#fff",
    },
    inputStyle: {
        borderBottom: "1px solid rgba(0, 0, 0, 0.6)",
        width: "100%",
        height: "100px",
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
    },
    buttonStyle: {
        width: "100%",
        height: "45px",
        marginTop: "40px",
        border: "none",
        backgroundColor: "rgb(98, 0, 238)",
    },
};

export const SliderComponent = ({ data, style }: any) => {
    var settings = {
        dots: false,
        infinite: true,
        speed: 500,
        slidesToShow: 4.5,
        slidesToScroll: 1,
        initialSlide: 0,
        responsive: [
            {
                breakpoint: 1300,
                settings: {
                    slidesToShow: 3.5,
                    slidesToScroll: 1,
                    infinite: true,
                    dots: false
                }
            },
            {
                breakpoint: 1200,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 1,
                    infinite: true,
                    dots: false
                }
            },
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1,
                    infinite: true,
                    dots: false
                }
            },
            {
                breakpoint: 600,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    initialSlide: 2,
                    dots: false
                }
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    dots: false
                }
            }
        ]
    };


    return (
        <Slider {...settings} className="slider1">
            {data
                ? data.map((element: any, index: any) => {
                    return (
                        <Box key={index}>
                            <ImgMediaCard props={element} />
                        </Box>
                    )
                })
                : <></>}

        </Slider>
    )
}

export function ImgMediaCard(props: any) {
    return (
        <Box>
            <Box style={{ padding: '0 10px 0 10px', borderRadius: '25px 25px 0 0', marginTop: '20px' }} >
                <CardMedia
                    style={{ height: "240px", width: '100%', borderRadius: '25px 25px 0 0' }}
                    image={img3}
                />
                <Paper style={{ width: '100%', borderRadius: '25px', marginTop: '-20px', height: '172px' }}>
                    <Typography style={{ margin: 'auto', padding: '17px', fontWeight: 600, fontSize: '14px', lineHeight: '20px' }}>
                        HTML 5 Expert course and CSS 3 with sample project
                    </Typography>
                    <Typography variant="body2" color="textSecondary" component="p"
                        style={{ margin: 'auto', marginTop: '-15px', marginBottom: '3px', padding: '0 25px', color: 'black' }}>
                        Dr Shephard
                    </Typography>

                    <Typography variant="body2" color="textSecondary" component="p"
                        style={{
                            margin: 'auto', fontSize: '12px', marginBottom: '5px', padding: '0 25px',
                            color: '#44444F'
                        }}>
                        Due in 22 Days
                    </Typography>
                    <Card style={{
                        width: '87px', height: '45px', borderRadius: '30px', float: 'left',
                        marginTop: '-2px', marginLeft: '25px', background: '#EF2B4B'
                    }}>
                        <CardActionArea>
                            <img src={group} style={{ margin: 'auto', padding: '15px', display: 'flex' }} />
                        </CardActionArea>
                    </Card>

                    <Card style={{
                        width: '87px', height: '45px', borderRadius: '30px', float: 'right',
                        marginTop: '-2px', marginRight: '25px', background: '#6C328B'
                    }}>
                        <CardActionArea>
                            <img src={play} style={{ margin: 'auto', padding: '12px', display: 'flex' }} />
                        </CardActionArea>
                    </Card>
                </Paper>
            </Box>
        </Box>
    );
}

export function MediaControlCard2({ ele, dialogHandleOpen, dialogHandleClose, dialogopen, objectIconData }: any) {
    const classes = useStyles();

    return (
        <>
            <Card key={ele.id} className={classes.root}>
                <Box style={{ position: 'relative', }} >

                    <img
                        // src={ele?.thumbnail_image}
                        style={{ height: '100px', width: '100px', borderRadius: '4px' }}
                    />

                </Box>

                <Grid container>
                    <Grid item lg={8}>
                        <Box style={{ marginLeft: '10px', padding: '7px' }}>
                            <h5 style={{ margin: 'auto', fontSize: '15px', fontWeight: 700, marginBottom: '2px' }}>
                                Course {ele.type}
                            </h5>
                            <Typography variant="subtitle1" color="textSecondary" style={{ margin: 'auto', fontSize: '13px', lineHeight: '18px', fontWeight: 400, marginBottom: '4px' }}>
                                {/* Low light photography and double exposure */}
                                {ele?.title}
                            </Typography>


                            <Box style={{ display: 'inline-flex', marginBottom: '10px' }}>
                                <span style={{ margin: 'auto' }}>4.5</span>
                                <span><StarIcon style={{ color: '#f9b651', marginLeft: '5px' }} /></span>
                                <span><StarIcon style={{ color: '#f9b651' }} /></span>
                                <span><StarIcon style={{ color: '#f9b651' }} /></span>
                                <span><StarIcon style={{ color: '#f9b651' }} /></span>
                                <span><StarHalfIcon style={{ color: '#f9b651' }} /></span>
                                <span><StarBorderIcon style={{ color: '#f9b651' }} /></span>
                            </Box>

                            <p style={{ fontWeight: 600, margin: 'auto', fontSize: '11px', lineHeight: '10.97px' }}>
                                {/* Justin Timberlake */}
                                {ele?.created_by}
                            </p>

                        </Box>
                    </Grid>
                    <Grid item lg={4}>
                        <Box onClick={() => dialogHandleOpen(ele)}>
                            <img
                                src={objectIcon}
                                style={{
                                    height: '32px', width: '32px', float: 'right',
                                    marginTop: '65px', cursor: 'pointer'
                                }}
                            />
                        </Box>
                    </Grid>
                </Grid>

            </Card>

            <Modal
                open={dialogopen}
                onClose={dialogHandleClose}
                aria-labelledby="simple-modal-title"
                aria-describedby="simple-modal-description"
                style={{ backgroundColor: "rgba(0, 0, 0, -0.5)" }}
            >
                <Box style={{
                    overflowY: 'scroll',
                    position: 'absolute', width: '350px', height: '300px', backgroundColor: '#fff',
                    margin: '12% 40% 12% 40%', borderRadius: '15px', boxShadow: '-6px -6px 25px rgba(230, 230, 230, 0.25), 6px 6px 25px rgba(229, 229, 229, 0.25)'

                }}>
                    <h5 style={{ margin: 'auto', fontSize: '15px', textAlign: 'center', fontWeight: 700, marginTop: '15px' }}>
                        {objectIconData?.data?.attributes.name}
                    </h5>
                    <img width="100%" height="65%" src={objectIconData?.data?.attributes?.thumbnail_image?.url} />
                    <h5 style={{ margin: 'auto 20px', fontSize: '15px', fontWeight: 700 }}>
                        {objectIconData?.data?.attributes.program_objectIcon_type}
                    </h5>
                    <p style={{ textAlign: 'center' }}>{objectIconData?.data?.attributes.description}</p>
                </Box>
            </Modal>

        </>

    )
}



// Customizable Area End