// Customizable Area 
import moment from "moment";


const { baseURL } = require("framework/src/config");


export const handleTrackerTime = async(time:any='', date:any='')=>{
const TRACKING_TIME_URL = `${baseURL}/bx_block_adhocreporting/training_track`
  let token = localStorage.getItem('token')

  try {
    // @ts-ignore
    const response = await fetch(TRACKING_TIME_URL, {  method: 'POST',
    headers: {
      token: `${token}`,
      'Content-Type': 'application/json'
    }, body: JSON.stringify({track_time: time, track_date: date}) } )
    if(response){
      console.log("tracking time", response)
    }
  } catch (error) {
    console.log(error,"tracking time not update")
  }
}

export const compareTime = ()=>{

  let start_time = Date.parse(window.localStorage.getItem('tracker_time') as any)
  let present_time = new Date();
  

  console.log(getDifferenceInMinutes(start_time, present_time),'time2')
  let minutes = getDifferenceInMinutes(start_time, present_time)
  let date = moment().format('L')
  console.log(minutes, date, "time");
  handleTrackerTime(minutes,date)

}
function getDifferenceInMinutes(date1:any, date2:any) {
  const diffInMs = Math.abs(date2 - date1);
  return diffInMs / (1000 * 60);
}
// Customizable Area End