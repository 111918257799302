import React from "react";
// Customizable Area Start
import clsx from "clsx";
import { IconButton, Dialog } from "@material-ui/core";
import { withStyles, createStyles, Theme } from "@material-ui/core/styles";
import CloseIcon from '@material-ui/icons/Close';
import SelectAll from "./components/SelectAll.web";
import NotificationList from "./components/NotificationList.web";
import NotificationHeader from "./components/NotificationHeader.web";
// Customizable Area End

import NotificationsController, {
  Props
} from "./NotificationsController.web";

class Notifications extends NotificationsController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  handleNotificationListTypeChange = (type: string) => {
    this.setState({ listType: type });
  }
  // Customizable Area End

  render() {
    const { classes, open, fullScreen } = this.props;
    // Customizable Area Start
    const { 
      notificationData, 
      listType, 
      isLoading,
      selectedNotificationIds,
      silentNotifications,
    } = this.state;
    const disabledMoreButton = notificationData.length === 0;
    const disabledDeleteButton = selectedNotificationIds.length === 0;
    const disabledSilentButton = silentNotifications.length === 0;
    const handleSelectAll =
      listType === "delete"
        ? this.handleAllNotificationSelectionToDelete
        : this.handleAllNotificationSelectionToSilent;
    const disabledSelectAll = isLoading || notificationData.length === 0;

    let isChecked;
    if (listType === "delete") {
      isChecked = notificationData.length === selectedNotificationIds.length;
    } else {
      isChecked = notificationData.length === silentNotifications.length;
    }
    // Customizable Area End

    return (
      // Customizable Area Start
      <Dialog
        scroll="paper"
        onClose={this.handleNotificationClose}
        aria-labelledby="notification"
        open={open}
        className={clsx(
          classes.dialog,
          localStorage.getItem("user_role") === "super_admin" &&
            classes.dialogAdmin
        )}
        fullScreen={fullScreen}
        fullWidth
      >
        <IconButton
          aria-label="delete"
          onClick={this.handleNotificationClose}
          className={clsx(
            classes.closeIconBtn,
            localStorage.getItem("user_role") === "super_admin" &&
              classes.closeIconBtnAdmin
          )}
        >
          <CloseIcon
            className={
              localStorage.getItem("user_role") === "super_admin"
                ? classes.closeIconAdmin
                : classes.closeIcon
            }
          />
        </IconButton>
        <NotificationHeader
          onMoreClick={this.handleNotificationListTypeChange}
          onCloseClick={this.handleNotificationClose}
          listType={listType}
          onDeleteClick={this.deleteNotifications}
          onSilentClick={this.silentNotifications}
          disabledMoreButton={disabledMoreButton}
          disabledDeleteButton={disabledDeleteButton}
          disabledSilentButton={disabledSilentButton}
        />
        <SelectAll
          type={listType}
          show={notificationData.length > 0}
          disabled={disabledSelectAll}
          isChecked={isChecked}
          onSelectAll={handleSelectAll}
        />
        <NotificationList
          type={listType}
          notifications={notificationData}
          isLoading={isLoading}
          isEmpty={!isLoading && notificationData.length === 0}
          silentNotifications={silentNotifications}
          selectedNotificationIds={selectedNotificationIds}
          onLearningClick={this.handleNavigation}
          onReminderClick={this.callRemindMeAPI}
          onDismissClick={this.dismissNotification}
          handleSingleNotificationSilent={this.silentSingleNotification}
          handleNotificationSelectionToDelete={
            this.handleNotificationSelectionToDelete
          }
          handleNotificationSelectionToSilent={
            this.handleNotificationSelectionToSilent
          }
        />
      </Dialog>
      // Customizable Area End
    );
  }
}

// Customizable Area Start

const styles = (theme: Theme) =>
  createStyles({
    closeIconBtn: {
      color: "black",
      "&:hover": {
        backgroundColor: "white",
      },
      background: "white",
      position: "absolute",
      right: 2,
      top: -25,
      display: "none",
      [theme.breakpoints.up("sm")]: {
        display: "block",
        right: -15,
        top: -20,
      },
    },
    closeIcon: {
      color: "#000000",
    },
    closeIconAdmin: {
      color: "#FFFFFF",
    },
    closeIconBtnAdmin: {
      background: "#6C328B",
      colo: "#000000",
      "&:hover": {
        backgroundColor: "#6C328B",
      },
    },
    dialog: {
      "& .MuiDialog-scrollPaper": {
        alignItems: "flex-start",
        justifyContent: "flex-end",
        marginTop: 0,
        [theme.breakpoints.up("sm")]: {
          marginTop: 20,
        },
      },
      "& .MuiPaper-root": {
        overflow: "unset",
        borderRadius: 0,
        // background: "#f4f4f4",
        background:
          "linear-gradient(184.52deg, #FFFFFF 10.37%, #F0F3F6 96.34%)",
        boxShadow: "0px 6px 58px rgba(121, 145, 173, 0.195504)",
        [theme.breakpoints.up("sm")]: {
          overflow: "unset",
          borderRadius: 24,
        },
      },
      "& .MuiDialog-paper": {
        overflowX: "hidden",
      },
    },
    dialogAdmin: {
      "& .MuiPaper-root": {
        background: "#FFFFFF",
      },
    },
  });

// @ts-ignore
export default withStyles(styles)(Notifications);
// Customizable Area End
