import React from "react";
// Customizable Area Start
import "./EmailAccountRegistration.css";
import {
  Box,
  Typography,
  Grid,
  Paper,
  TextField,
  InputLabel,
  Checkbox,
  FormControlLabel,
  InputAdornment,
  Button,
  IconButton,
} from "@material-ui/core";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import { withStyles, styled } from "@material-ui/core/styles";
import { Formik, ErrorMessage } from "formik";
import * as yup from "yup";
import { Link, withRouter, Redirect } from "react-router-dom";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import Visibility from "@material-ui/icons/Visibility";
import Dialog from '@material-ui/core/Dialog';


import { LoginImage } from "./assets";

import EmailAccountRegistrationController, {
  Props,
} from "./EmailAccountRegistrationController.web";
import AlertBlock from "../../alert/src/ActionAlert.web";
import TermPageWeb from "../../splashscreen/src/TermPage.web";

const Item = styled(Paper)(({ theme }) => ({
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: "end",
  color: theme.palette.text.secondary,
}));
const CheckboxWithCustomCheck = withStyles({
  root: {
    "& .MuiSvgIcon-root": {
      fill: "white",
      "&:hover": {
        backgroundColor: "white",
      },
    },
    "&$checked": {
      "& .MuiIconButton-label": {
        position: "relative",
        zIndex: 0,
        border: "1px solid #802F88",
        borderRadius: 3,
      },
      "& .MuiIconButton-label:after": {
        content: '""',
        left: 3,
        top: 2,
        height: 8,
        width: 8,
        position: "absolute",
        backgroundColor: "#802F88",
        zIndex: -1,
        borderColor: "#802F88",
      },
    },
    "&:not($checked) .MuiIconButton-label": {
      position: "relative",
      zIndex: 0,
      border: "1px solid #802F88",
      borderRadius: 3,
    },
    "&:not($checked) .MuiIconButton-label:after": {
      content: '""',
      left: 4,
      top: 4,
      height: 5,
      width: 5,
      position: "absolute",
      backgroundColor: "#fff",
      zIndex: -1,
      borderColor: "#802F88",
    },
  },
  checked: {},
})(Checkbox);
class EmailAccountRegistration extends EmailAccountRegistrationController {
  constructor(props: Props) {
    super(props);
  }
  handleSubmit(user: any) {
    let payload = {
      email: user.email,
      full_phone_number: "+91" + user.mobileNumber,
      first_name: user.firstName,
      last_name: user.lastName,
      password: user.password,
      confirm_password: user.confirmPassword,
    };

    this.getRegistered(payload);
  }

  render() {
    if (localStorage.getItem("token")) {
      return <Redirect to="/" />;
    }

    return (
      // Required for all blocks
      <>
        <AlertBlock
          data={{
            show: this.state.alert.isOpen,
            close: () => {
              this.setState({ alert: { isOpen: false, msg: "", type: "" } });
            },
            msg: this.state.alert.msg,
            type: this.state.alert.type,
            vertical: "top",
            horizontal: "right",
          }}
        />
        <Grid
          container
          style={{ height: "100vh", width: "100vw" }}
          className="register"
        >
          <Grid
            xs={"auto"}
            sm={4}
            lg={3}
            style={{
              backgroundImage: `url(${LoginImage})`,
              backgroundRepeat: "no-repeat",
              backgroundSize: "100% 100%",
              backgroundPosition: "center",
            }}
          />
          <Grid
            item
            xs={12}
            sm={8}
            lg={9}
            component={Paper}
            elevation={6}
            square
            style={{ overflowY: "scroll", height: "100vh" }}
          >
            <Box
              sx={{
                my: 8,
                mx: 4,
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <Grid item className="login-route">
                <Item>
                  <Link to={"/login"} className="text">
                    Already have an account ?<span style={{ paddingLeft: '20px' }}>Login</span>
                  </Link>
                </Item>
              </Grid>
              <Formik
                initialValues={
                  this.props.location.state === undefined
                    ? {
                      email: "",
                      firstName: "",
                      lastName: "",
                      mobileNumber: "",
                      showPassword: false,
                      password: "",
                      confirmPassword: "",
                      showConPassword: false,
                      acceptTerms: false,
                    }
                    : this.props.location.state
                }
                onSubmit={async (values) => {
                  this.handleSubmit(values);
                }}
                validationSchema={yup.object().shape({
                  email: yup
                    .string()
                    .matches(
                      /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
                      "Enter valid email"
                    )
                    .required("Email is required"),
                  firstName: yup
                    .string()
                    .matches(/^[A-Za-z]+$/, "Enter Valid firstName")
                    .required("First name is required"),
                  lastName: yup
                    .string()
                    .matches(/^[A-Za-z]+$/, "Enter Valid lastName")
                    .required("Last name is required"),
                  mobileNumber: yup
                    .string()
                    .required("Mobile number is required")
                    .matches(
                      /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/,
                      "Mobile number is not valid"
                    )
                    .min(10, "Enter 10 digits only")
                    .max(10, "Enter 10 digits only"),
                  password: yup
                    .string()
                    .matches(
                      /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{5,}$/,
                      "Must Contain 5 Characters, One Uppercase, One Lowercase, One Number and one special case Character"
                    )
                    .required("Password is required"),
                  confirmPassword: yup
                    .string()
                    .required("Confirm Password is required")
                    .oneOf(
                      [yup.ref("password"), null],
                      "Passwords do not match"
                    ),
                  acceptTerms: yup
                    .bool()
                    .oneOf([true], "Please accept the Terms & Conditions"),
                })}
              >
                {(props) => {
                  const {
                    values,
                    touched,
                    errors,
                    setFieldValue,
                    handleChange,
                    handleBlur,
                    handleSubmit,
                  } = props;
                  return (
                    <form className="regform" onSubmit={handleSubmit}>
                      <Grid item xs={8} className="typo">
                        <Typography
                          component="h6"
                          variant="h5"
                          className="typo_title"
                        >
                          Sign up
                        </Typography>
                        <Typography
                          component="h6"
                          variant="h6"
                          className="typo_sub_title"
                        >
                          Please enter your personal details
                        </Typography>
                      </Grid>
                      <Grid className="form" container spacing={5}>
                        <Grid item md={12} xs={12}>
                          <InputLabel>Email</InputLabel>
                          <TextField
                            id="email"
                            name="email"
                            value={values.email}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            error={touched.email && Boolean(errors.email)}
                            helperText={touched.email && errors.email}
                            variant="outlined"
                            placeholder="Please type email address"
                            InputLabelProps={{
                              shrink: true,
                            }}
                            fullWidth
                          />
                        </Grid>
                      </Grid>
                      <Grid container className="pr" spacing={3}>
                        <Grid item md={6} xs={6}>
                          <InputLabel htmlFor="firstName">
                            First Name
                          </InputLabel>
                          <TextField
                            id="firstName"
                            name="firstName"
                            value={values.firstName}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            error={
                              touched.firstName && Boolean(errors.firstName)
                            }
                            helperText={touched.firstName && errors.firstName}
                            variant="outlined"
                            placeholder="Please type first name"
                            InputLabelProps={{
                              shrink: true,
                            }}
                          />
                        </Grid>
                        <Grid item md={6} xs={6}>
                          <InputLabel htmlFor="lastName">Last Name</InputLabel>
                          <TextField
                            id="lastName"
                            name="lastName"
                            value={values.lastName}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            error={touched.lastName && Boolean(errors.lastName)}
                            helperText={touched.lastName && errors.lastName}
                            variant="outlined"
                            placeholder="Please type last name"
                            InputLabelProps={{
                              shrink: true,
                            }}
                          />
                        </Grid>
                      </Grid>
                      <Grid container spacing={5}>
                        <Grid item md={12} xs={12}>
                          <InputLabel htmlFor="mobileNumber">
                            Mobile number
                          </InputLabel>
                          <TextField
                            id="mobileNumber"
                            name="mobileNumber"
                            value={values.mobileNumber}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            error={
                              touched.mobileNumber &&
                              Boolean(errors.mobileNumber)
                            }
                            helperText={
                              touched.mobileNumber && errors.mobileNumber
                            }
                            variant="outlined"
                            placeholder="Please enter mobile number"
                            InputLabelProps={{
                              shrink: true,
                            }}
                            fullWidth
                          />
                        </Grid>
                      </Grid>
                      <Grid container spacing={5}>
                        <Grid item md={12} xs={12}>
                          <InputLabel htmlFor="filled-adornment-password">
                          Enter your password
                          </InputLabel>
                          <TextField
                            id="filled-adornment-password"
                            type={values.showPassword ? "text" : "password"}
                            name="password"
                            InputLabelProps={{
                              shrink: true,
                            }}
                            fullWidth
                            placeholder="Please enter password"
                            variant="outlined"
                            value={values.password}
                            error={touched.password && Boolean(errors.password)}
                            helperText={touched.password && errors.password}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            InputProps={{
                              endAdornment: (
                                <InputAdornment position="end">
                                  <IconButton
                                    aria-label="toggle password visibility"
                                    onClick={() => {
                                      setFieldValue(
                                        "showPassword",
                                        !values.showPassword
                                      );
                                    }}
                                    edge="end"
                                  >
                                    {values.showPassword ? (
                                      <VisibilityOff />
                                    ) : (
                                      <Visibility />
                                    )}
                                  </IconButton>
                                </InputAdornment>
                              ),
                            }}
                          />
                        </Grid>
                      </Grid>
                      <Grid container spacing={5}>
                        <Grid item md={12} xs={12}>
                          <InputLabel htmlFor="filled-adornment-cpassword">
                          Confirm your password
                          </InputLabel>
                          <TextField
                            id="filled-adornment-cpassword"
                            type={values.showConPassword ? "text" : "password"}
                            InputLabelProps={{
                              shrink: true,
                            }}
                            fullWidth
                            variant="outlined"
                            name="confirmPassword"
                            placeholder="Confirm your password"
                            value={values.confirmPassword}
                            error={
                              touched.confirmPassword &&
                              Boolean(errors.confirmPassword)
                            }
                            helperText={
                              touched.confirmPassword && errors.confirmPassword
                            }
                            onChange={handleChange}
                            onBlur={handleBlur}
                            InputProps={{
                              endAdornment: (
                                <InputAdornment position="end">
                                  <IconButton
                                    aria-label="toggle password visibility"
                                    onClick={() => {
                                      setFieldValue(
                                        "showConPassword",
                                        !values.showConPassword
                                      );
                                    }}
                                    edge="end"
                                  >
                                    {values.showConPassword ? (
                                      <VisibilityOff />
                                    ) : (
                                      <Visibility />
                                    )}
                                  </IconButton>
                                </InputAdornment>
                              ),
                            }}
                          />
                        </Grid>
                      </Grid>

                      <Grid container spacing={5}>
                        <Grid item md={6} xs={6}>
                          <FormControlLabel
                            value="start"
                            control={<CheckboxWithCustomCheck />}
                            label="Do you have referral code"
                            labelPlacement="end"
                            disabled={true}
                          />
                        </Grid>
                        <Grid item md={6} xs={6}>
                          <TextField
                            id="referal-code"
                            variant="outlined"
                            placeholder="Enter Referral code"
                            InputLabelProps={{
                              shrink: true,
                            }}
                            InputProps={{
                              endAdornment: (
                                <InputAdornment
                                  position="end"
                                  style={{ color: "#6C328B" }}
                                >
                                  <IconButton edge="end">
                                    <CheckCircleIcon
                                      style={{ color: "#6C328B" }}
                                    />
                                  </IconButton>
                                </InputAdornment>
                              ),
                            }}
                            disabled={true}
                          />
                        </Grid>
                      </Grid>
                      <Grid container spacing={5}>
                        <Grid item md={12} xs={12}>
                          <FormControlLabel
                            value="start"
                            control={
                              <CheckboxWithCustomCheck
                                checked={values.acceptTerms}
                                onChange={handleChange}
                                name="acceptTerms"
                                onBlur={handleBlur}
                              />
                            }
                            label="By signing up I agree the User Agreements Privacy Policy"
                            labelPlacement="end"
                          />
                        </Grid>
                      </Grid>
                      <Grid container spacing={2}>
                        <Grid item md={12} xs={12} style={{ margin: "3px" }}>
                          {/* <Link
                            to={{
                              pathname: "/terms",
                              state: { values, path: window.location.pathname },
                            }}
                            className="terms"
                          >
                            Terms and Conditions
                          </Link> */}
                          <Typography onClick={() => this.handleClickOpen()} className="terms"> Terms and Conditions</Typography>
                          <ErrorMessage
                            name="acceptTerms"
                            component="div"
                            className="invalid-feedback"
                          />
                        </Grid>
                      </Grid>
                      <Grid container spacing={5}>
                        <Grid item md={12} xs={12}>
                          <Button type="submit" fullWidth>
                            Sign up
                          </Button>
                        </Grid>
                      </Grid>
                    </form>
                  );
                }}
              </Formik>
            </Box>
          </Grid>
        </Grid>

        <Dialog
          open={this.state.open}
          // onClose={this.handleClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          className="custom-dialog-paper"
          PaperProps={{
            style: {
              marginLeft: '22%', 
              overflowY:'auto',
              scrollbarWidth: 'thin',
              scrollbarColor: 'rgba(155, 155, 155, 0.5) rgba(0, 0, 0, 0.1)',
            }
          }}
        >
          <TermPageWeb  handleClose={this.handleClose} id={""} navigation={undefined} />
        </Dialog>
      </>
    );
  }
}
//@ts-ignore
export default withRouter(EmailAccountRegistration);
// Customizable Area End