// Customizable Area 
import React, { Fragment } from "react";

import {
    Button,
    // Customizable Area Start
    Grid,
    Card,
    CardContent,
    Dialog,
    DialogContent,
    Accordion,
    Typography,
    AccordionSummary,
    AccordionDetails,
    DialogActions,
    DialogTitle,
    DialogContentText,
    Switch
} from "@material-ui/core";
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { createStyles, Theme, withStyles } from "@material-ui/core/styles";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
//@ts-ignore
import CourseCreationController, {
} from "./CourseCreationController.web";
import './CourseCreation.css';
import { AddQuizIcon, AddVideoIcon, IconObject, Rectangle, ShapePng, Teacher, trash, Union } from "./assets";
import { Link } from "react-router-dom"
import DynamicTitle from "../../../components/src/DynamicTitle";

const styles = (theme: Theme) => createStyles({
    root: {
        width: '100%',
    },
    heading: {
        fontStyle: "normal",
        fontWeight: 500,
        fontSize: "15px",
        lineHeight: "24px",
        color: "#000000",
        textTransform: "capitalize",
        [theme.breakpoints.up("sm")]: {
            fontSize: "24px",
            lineHeight: "36px",
        }
    },
    switch: {
        "& .MuiSwitch-colorSecondary.Mui-checked": {
          color: "#6C328B",
        },
        "& .MuiSwitch-colorSecondary.Mui-checked + .MuiSwitch-track": {
          backgroundColor: "#C4AED0",
        },
      },
      switchBase: {
        color: "#545650",
        "&$checked": {
          color: "#6C328B"
        },
        "&$checked + $track": {
          background: "#c4aed0",
          mixBlendMode: "normal",
          opacity: "0.37"
        }
      },
      track: {
        background: "#c8cbd2",
        mixBlendMode: "normal",
        opacity: "0.37"
      },
  
})

const draggingOverBackground = (isDraggingOver: boolean) => {
    return isDraggingOver ? "lightgray" : "white";
};

class BuildProgram extends CourseCreationController {
    updateStatus(status: any) {
        const fd = new FormData();
        fd.append('program[status]', status)
        this.updateProgram(fd, this.props.navigation?.getParam('id'))
    }
    handleChange(e: any,id:number,type:string,key:string,index:number) {
    const fd = new FormData();
    if(e.target.name === "status_object"){
     if(type === "object"){
        let items =  key ==="object" ? [...this.state?.course?.attributes?.program_includes] : [...this.state?.programs?.attributes?.phases[key]];
        let item = { ...items[index] }
        item.active = !item.active
        items[index] = item;
        const array = this.state?.programs
        array.attributes.phases[key][index] = items
        this.setState({ programs: array })
        fd.append('program_object[active]',e.target.checked);
        this.DeleteObject(id,fd);
     }
    }
    else{
        if(type === "course"){
            let items =  key ==="course" ? [...this.state?.course?.attributes?.program_includes] : [...this.state?.programs?.attributes?.phases[key]];
            let item = { ...items[index] }
            item.active = !item.active
            items[index] = item;
            const array = this.state?.programs
            array.attributes.phases[key][index] = items
            this.setState({ programs: array })
            fd.append('program_course[active]',e.target.checked);
            this.DeleteCourse(id,fd);
         }
    }
}
    render() {
        const handleClickOpen = () => {
            this.setState({ open: true });
        };

        const handleClose = () => {
            this.setState({ open: false });
        };
        const handleClickOpenAlert = (type: string, id: number) => {
            this.setState({ openAlert: true, phase_type: type, phase_type_id: id });
        };

        const handleCloseAlert = () => {
            if (this.state.phase_type === "object") {
                this.setState({ openAlert: false });
                this.DeleteObject(this.state.phase_type_id,"")
            }
            else if (this.state.phase_type === "course") {
                this.setState({ openAlert: false });
                this.DeleteCourse(this.state.phase_type_id,"");
            }
            else {
                this.setState({ openAlert: false });
            }
        };
        const handleCloseOutsideAlert = () => {
                this.setState({ openAlert: false });
        };
        return <DragDropContext onDragStart={this.onDragStart} onDragEnd={this.onDragEnd}>
            <DynamicTitle title="Build Program" />
            <div className="build-course">
                <Grid container spacing={4}>
                    <div className="build-content-border">
                    {this.state.programs !== "" && this.state?.programs?.attributes?.content_release_schedule === true || this.state?.programs?.attributes?.is_free_trial  ? 
                     Object.keys(this.state.programs?.attributes?.phases).map((key: any, keyIndex: number) => {
                        return <React.Fragment key={key}>
                            <Accordion className="user-box">
                                <AccordionSummary
                                    expandIcon={<ExpandMoreIcon />}
                                    aria-controls="panel1a-content"
                                    id={key}
                                >
                                    <div className="accordion-summary-container">
                                        <Typography className={this.props.classes.heading}>
                                            {key.includes("_") ? `Free Trial`  :`Phase ${key.replace("phase","")}`}
                                        </Typography>
                                        {
                                            this.state.programs?.attributes?.content_release_schedule &&
                                            <Typography className={this.props.classes.heading}>
                                                {this.state.programs?.attributes?.content_release_schedule_value}
                                            </Typography>
                                        }
                                    </div>
                                </AccordionSummary>
                                <Droppable droppableId={key} isDropDisabled={key !== this.state.droppableId}>
                                    {(droppableProvided, droppableSnapshot) => {
                                        return <AccordionDetails {...droppableProvided.droppableProps} ref={droppableProvided.innerRef} style={{ flexDirection: "column" }} >
                                            {this.state.programs?.attributes?.phases[key].length > 0 && this.state.programs?.attributes?.phases[key].map((item: any, index: number) => {
                                                return <><Draggable draggableId={(item.id || index ).toString()} index={index} key={item.id}>
                                                    {(draggableProvided, draggableSnapshot) => {
                                                        return <div>
                                                            {item.type === "course" && <Grid container>
                                                                <Grid item xs={12}>
                                                                    <Card
                                                                        {...draggableProvided.draggableProps}
                                                                        className={item.active ? "customize-border" : "customize-border blur-background"}
                                                                        ref={draggableProvided.innerRef}
                                                                        style={{
                                                                            ...draggableProvided.draggableProps.style,
                                                                            background: draggableSnapshot.isDragging
                                                                                ? "#f3eded"
                                                                                : draggingOverBackground(droppableSnapshot.isDraggingOver)
                                                                        }}
                                                                    >
                                                                        <CardContent>
                                                                            <Grid container className="build-container">
                                                                                <Grid item xs={12} sm={6} lg={1}>
                                                                                    <img className="build-course-img" src={item.thumbnail_image}
                                                                                    />
                                                                                </Grid>
                                                                                <Grid item xs={12} sm={6} lg={2}>
                                                                                    <div className="build-course-title text-center">
                                                                                        <p className="build-course-heading">Course Title</p>
                                                                                        <p className="build-course-subheading">{item.title} mins</p>
                                                                                    </div>
                                                                                </Grid>
                                                                                <Grid item xs={6} sm={6} lg={2} >
                                                                                    <p className="build-course-heading">Chapters</p>
                                                                                    <p className="build-course-subheading">{item?.chapter_number ?? ""}</p>
                                                                                </Grid>
                                                                                <Grid item xs={6} sm={6} lg={1} >
                                                                                    <p className="build-course-heading">Phase #</p>
                                                                                    <p className="build-course-subheading">{item.phase}</p>
                                                                                </Grid>
                                                                                <Grid item xs={6} sm={6} lg={3} >
                                                                                    <p className="build-course-heading">Course Description</p>
                                                                                    <p className="build-course-subheading-desc">{`${item.description.slice(0, 30)}...`}</p>
                                                                                </Grid>
                                                                                {item.active ?
                                                                                    <Grid item xs={6} sm={6} lg={1} >
                                                                                        <p className="build-course-heading active-text">Active</p>
                                                                                        <p className="build-course-heading">
                                                                                            <Switch
                                                                                                onChange={(e:any) => { this.handleChange(e,item.id,item.type,key,index); }}
                                                                                                name="status_video"
                                                                                                checked={item.active}
                                                                                                inputProps={{ 'aria-label': "status" }}
                                                                                                className={this.props.classes.switch}
                                                                                                classes={{
                                                                                                    switchBase: this.props.classes.switchBase,
                                                                                                    track: this.props.classes.track,
                                                                                                }}
                                                                                            />

                                                                                        </p>
                                                                                    </Grid>
                                                                                    :
                                                                                    <Grid item xs={6} sm={6} lg={1}>
                                                                                        <p className="build-course-heading inactive-text">Inactive</p>
                                                                                        <p className="build-course-heading">
                                                                                            <Switch
                                                                                                onChange={(e:any) => { this.handleChange(e,item.id,item.type,key,index); }}
                                                                                                name="status_video"
                                                                                                checked={item.active}
                                                                                                inputProps={{ 'aria-label': "status" }}
                                                                                                className={this.props.classes.switch}
                                                                                                classes={{
                                                                                                    switchBase: this.props.classes.switchBase,
                                                                                                    track: this.props.classes.track,
                                                                                                }}
                                                                                            />

                                                                                        </p>
                                                                                    </Grid>
                                                                                }
                                                                                <Grid item xs={6} sm={6} lg={1} className="flex-center" style={{ paddingBottom: "20px" }}>
                                                                                    <img className="build-course-icon" src={trash} onClick={() => handleClickOpenAlert(item.type, item.id)} style={{ width: "30px", height: "30px" }} />
                                                                                </Grid>
                                                                                <Grid item xs={6} sm={6} lg={1} className="flex-center" style={{ paddingBottom: "20px" }} {...draggableProvided.dragHandleProps}>
                                                                                    <img className="build-course-icon" src={ShapePng} style={{ width: "30px", height: "30px" }} />
                                                                                </Grid>
                                                                            </Grid>
                                                                        </CardContent>
                                                                    </Card>
                                                                </Grid>
                                                            </Grid>}
                                                            {item.type === "object" && <Grid container>
                                                                <Grid item md={12} xs={12}>
                                                                    <Card
                                                                        {...draggableProvided.draggableProps}
                                                                        className={item.active ? "customize-border" : "customize-border blur-background"}
                                                                        ref={draggableProvided.innerRef}
                                                                        style={{
                                                                            ...draggableProvided.draggableProps.style,
                                                                            background: draggableSnapshot.isDragging
                                                                                ? "#f3eded"
                                                                                : draggingOverBackground(droppableSnapshot.isDraggingOver)
                                                                        }}
                                                                    >
                                                                        <CardContent>
                                                                            <Grid container className="build-container">
                                                                                <Grid item xs={12} sm={6} lg={1}>
                                                                                    <img className="build-course-img" src={Rectangle} />
                                                                                </Grid>
                                                                                <Grid item xs={12} sm={6} lg={2}>
                                                                                    <div className="build-course-title text-center">
                                                                                        <p className="build-course-heading">Object Title</p>
                                                                                        <p className="build-course-subheading">{item.title}</p>
                                                                                    </div>
                                                                                </Grid>
                                                                                <Grid item xs={6} sm={6} lg={2}>
                                                                                    <p className="build-course-heading">Object Type</p>
                                                                                    <p className="build-course-subheading">{item?.object_type.replace("po_","") ?? ""}</p>
                                                                                </Grid>
                                                                                <Grid item xs={6} sm={6} lg={1}>
                                                                                    <p className="build-course-heading">Phase #</p>
                                                                                    <p className="build-course-subheading">{item.phase}</p>
                                                                                </Grid>
                                                                                <Grid item xs={6} sm={6} lg={3} >
                                                                                    <p className="build-course-heading">Object Description</p>
                                                                                    <p className="build-course-subheading-desc">{`${item.description.slice(0, 30)}...`}</p>
                                                                                </Grid>
                                                                                {item.active ?
                                                                                    <Grid item xs={6} sm={6} lg={1} >
                                                                                        <p className="build-course-heading active-text">Active</p>
                                                                                        <p className="build-course-heading">
                                                                                            <Switch
                                                                                                onChange={(e:any) => { this.handleChange(e,item.id,item.type,key,index); }}
                                                                                                name="status_object"
                                                                                                checked={item.active}
                                                                                                inputProps={{ 'aria-label': "status" }}
                                                                                                className={this.props.classes.switch}
                                                                                                classes={{
                                                                                                    switchBase: this.props.classes.switchBase,
                                                                                                    track: this.props.classes.track,
                                                                                                }}
                                                                                            />

                                                                                        </p>

                                                                                    </Grid>
                                                                                    :
                                                                                    <Grid item xs={6} sm={6} lg={1}>
                                                                                        <p className="build-course-heading inactive-text">Inactive</p>
                                                                                        <p className="build-course-heading">
                                                                                            <Switch
                                                                                                onChange={(e:any) => { this.handleChange(e,item.id,item.type,key,index); }}
                                                                                                name="status_object"
                                                                                                checked={item.active}
                                                                                                value={item.active}
                                                                                                inputProps={{ 'aria-label': "status" }}
                                                                                                className={this.props.classes.switch}
                                                                                                classes={{
                                                                                                    switchBase: this.props.classes.switchBase,
                                                                                                    track: this.props.classes.track,
                                                                                                }}
                                                                                            />

                                                                                        </p>

                                                                                    </Grid>
                                                                                }
                                                                                <Grid item xs={6} sm={6} lg={1} className="flex-center" style={{ paddingBottom: "27px" }}>
                                                                                    <img className="build-course-icon" src={trash} style={{ width: "30px", height: "30px" }} onClick={() => handleClickOpenAlert(item.type, item.id)} />
                                                                                </Grid>
                                                                                <Grid item xs={6} sm={6} lg={1} className="flex-center" style={{ paddingBottom: "27px" }} {...draggableProvided.dragHandleProps}>
                                                                                    <img className="build-course-icon" src={ShapePng} style={{ width: "30px", height: "30px" }} />
                                                                                </Grid>
                                                                            </Grid>
                                                                        </CardContent>
                                                                    </Card>
                                                                </Grid>
                                                            </Grid>}
                                                        </div>
                                                    }}
                                                </Draggable>
                                                </>
                                                
                                            })}
                                            {droppableProvided.placeholder}

                                            <Grid container>
                                                <Grid item md={12} xs={12}>
                                                    <Card className="customize-dashed-border">
                                                        <CardContent>
                                                            <Grid item container md={12} xs={12} className="build-container">
                                                                <Grid item md={2} xs={6} style={{ display: "flex", textAlign: "start", cursor: "pointer" }}>
                                                                    <img className="build-course-img" src={AddQuizIcon}
                                                                    />
                                                                    <div className="build-course-title">
                                                                        <p className="build-course-heading" style={{ padding: "10px 10px 20px 10px" }} 
                                                                         onClick={() => {handleClickOpen();this.setState({program_phase:key.includes("phase") ? parseInt(key.replace("phase","")) : 0})}}
                                                                        >Add more</p>
                                                                    </div>
                                                                </Grid>
                                                            </Grid>
                                                        </CardContent>
                                                    </Card>
                                                </Grid>
                                                </Grid>
                                        </AccordionDetails>
                                    }}
                                </Droppable>
                            </Accordion>
                        </React.Fragment>
                    })
                    :                       
                    this.state?.programs?.attributes?.program_includes.length>0 &&   <React.Fragment>
                                <Accordion className="user-box">
                                    <AccordionSummary
                                        expandIcon={<ExpandMoreIcon />}
                                        aria-controls="panel1a-content"
                                        
                                    >
                                         <div className="accordion-summary-container">
                                            <Typography className={this.props.classes.heading}>
                                                {`Program Includes`}
                                            </Typography>
                                        </div>
                                    </AccordionSummary>
                                    <Droppable 
                                    droppableId={'1'} isDropDisabled={'1' !== this.state.droppableId}
                                    >
                                        {(droppableProvided, droppableSnapshot) => {
                                            return <AccordionDetails {...droppableProvided.droppableProps} ref={droppableProvided.innerRef} style={{ flexDirection: "column" }} >
                                                {this.state?.programs?.attributes?.program_includes && this.state?.programs?.attributes?.program_includes.length>0 &&  this.state.programs?.attributes?.program_includes.map((item: any, index: number) => {
                                                    return <Draggable draggableId={(item.id || index ).toString()} index={index} key={item.id}>
                                                        {(draggableProvided, draggableSnapshot) => {
                                                            return <div>
                                                            {item.type === "course" && <Grid container>
                                                                <Grid item xs={12}>
                                                                    <Card
                                                                        {...draggableProvided.draggableProps}
                                                                        className={item.active ? "customize-border" : "customize-border blur-background"}
                                                                        ref={draggableProvided.innerRef}
                                                                        style={{
                                                                            ...draggableProvided.draggableProps.style,
                                                                            background: draggableSnapshot.isDragging
                                                                                ? "#f3eded"
                                                                                : draggingOverBackground(droppableSnapshot.isDraggingOver)
                                                                        }}
                                                                    >
                                                                        <CardContent>
                                                                            <Grid container className="build-container">
                                                                                <Grid item xs={12} sm={6} lg={1}>
                                                                                    <img className="build-course-img" src={item.thumbnail_image}
                                                                                    />
                                                                                </Grid>
                                                                                <Grid item xs={12} sm={6} lg={2}>
                                                                                    <div className="build-course-title text-center">
                                                                                        <p className="build-course-heading">Course Title</p>
                                                                                        <p className="build-course-subheading">{item.title} mins</p>
                                                                                    </div>
                                                                                </Grid>
                                                                                <Grid item xs={6} sm={6} lg={2} >
                                                                                    <p className="build-course-heading">Chapters</p>
                                                                                    <p className="build-course-subheading">{item?.chapter_number ?? ""}</p>
                                                                                </Grid>
                                                                                <Grid item xs={6} sm={6} lg={1} >
                                                                                    <p className="build-course-heading">Phase #</p>
                                                                                    <p className="build-course-subheading">{item.phase}</p>
                                                                                </Grid>
                                                                                <Grid item xs={6} sm={6} lg={3} >
                                                                                    <p className="build-course-heading">Course Description</p>
                                                                                    <p className="build-course-subheading-desc">{`${item.description.slice(0, 30)}...`}</p>
                                                                                </Grid>
                                                                                {item.active ?
                                                                                    <Grid item xs={6} sm={6} lg={1} >
                                                                                        <p className="build-course-heading active-text">Active</p>
                                                                                        <p className="build-course-heading">
                                                                                            <Switch
                                                                                                onChange={(e:any) => { this.handleChange(e,item.id,"course","course",index); }}
                                                                                                name="status_video"
                                                                                                checked={item.active}
                                                                                                inputProps={{ 'aria-label': "status" }}
                                                                                                className={this.props.classes.switch}
                                                                                                classes={{
                                                                                                    switchBase: this.props.classes.switchBase,
                                                                                                    track: this.props.classes.track,
                                                                                                }}
                                                                                            />

                                                                                        </p>
                                                                                    </Grid>
                                                                                    :
                                                                                    <Grid item xs={6} sm={6} lg={1}>
                                                                                        <p className="build-course-heading inactive-text">Inactive</p>
                                                                                        <p className="build-course-heading">
                                                                                            <Switch
                                                                                                onChange={(e:any) => { this.handleChange(e,item.id,"course","course",index); }}
                                                                                                name="status_video"
                                                                                                checked={item.active}
                                                                                                inputProps={{ 'aria-label': "status" }}
                                                                                                className={this.props.classes.switch}
                                                                                                classes={{
                                                                                                    switchBase: this.props.classes.switchBase,
                                                                                                    track: this.props.classes.track,
                                                                                                }}
                                                                                            />

                                                                                        </p>
                                                                                    </Grid>
                                                                                }
                                                                                <Grid item xs={6} sm={6} lg={1} className="flex-center" style={{ paddingBottom: "20px" }}>
                                                                                    <img className="build-course-icon" src={trash} onClick={() => handleClickOpenAlert(item.type, item.id)} style={{ width: "30px", height: "30px" }} />
                                                                                </Grid>
                                                                                <Grid item xs={6} sm={6} lg={1} className="flex-center" style={{ paddingBottom: "20px" }} {...draggableProvided.dragHandleProps}>
                                                                                    <img className="build-course-icon" src={ShapePng} style={{ width: "30px", height: "30px" }} />
                                                                                </Grid>
                                                                            </Grid>
                                                                        </CardContent>
                                                                    </Card>
                                                                </Grid>
                                                            </Grid>}
                                                            {item.type === "object" && <Grid container>
                                                                <Grid item md={12} xs={12}>
                                                                    <Card
                                                                        {...draggableProvided.draggableProps}
                                                                        className={item.active ? "customize-border" : "customize-border blur-background"}
                                                                        ref={draggableProvided.innerRef}
                                                                        style={{
                                                                            ...draggableProvided.draggableProps.style,
                                                                            background: draggableSnapshot.isDragging
                                                                                ? "#f3eded"
                                                                                : draggingOverBackground(droppableSnapshot.isDraggingOver)
                                                                        }}
                                                                    >
                                                                        <CardContent>
                                                                            <Grid container className="build-container">
                                                                                <Grid item xs={12} sm={6} lg={1}>
                                                                                    <img className="build-course-img" src={Rectangle} />
                                                                                </Grid>
                                                                                <Grid item xs={12} sm={6} lg={2}>
                                                                                    <div className="build-course-title text-center">
                                                                                        <p className="build-course-heading">Object Title</p>
                                                                                        <p className="build-course-subheading">{item.title}</p>
                                                                                    </div>
                                                                                </Grid>
                                                                                <Grid item xs={6} sm={6} lg={2}>
                                                                                    <p className="build-course-heading">Object Type</p>
                                                                                    <p className="build-course-subheading">{item?.object_type.replace("po_","") ?? ""}</p>
                                                                                </Grid>
                                                                                <Grid item xs={6} sm={6} lg={1}>
                                                                                    <p className="build-course-heading">Phase #</p>
                                                                                    <p className="build-course-subheading">{item.phase}</p>
                                                                                </Grid>
                                                                                <Grid item xs={6} sm={6} lg={3} >
                                                                                    <p className="build-course-heading">Object Description</p>
                                                                                    <p className="build-course-subheading-desc">{`${item.description.slice(0, 30)}...`}</p>
                                                                                </Grid>
                                                                                {item.active ?
                                                                                    <Grid item xs={6} sm={6} lg={1} >
                                                                                        <p className="build-course-heading active-text">Active</p>
                                                                                        <p className="build-course-heading">
                                                                                            <Switch
                                                                                                onChange={(e:any) => { this.handleChange(e,item.id,"object","object",index); }}
                                                                                                name="status_object"
                                                                                                checked={item.active}
                                                                                                inputProps={{ 'aria-label': "status" }}
                                                                                                className={this.props.classes.switch}
                                                                                                classes={{
                                                                                                    switchBase: this.props.classes.switchBase,
                                                                                                    track: this.props.classes.track,
                                                                                                }}
                                                                                            />

                                                                                        </p>

                                                                                    </Grid>
                                                                                    :
                                                                                    <Grid item xs={6} sm={6} lg={1}>
                                                                                        <p className="build-course-heading inactive-text">Inactive</p>
                                                                                        <p className="build-course-heading">
                                                                                            <Switch
                                                                                                onChange={(e:any) => { this.handleChange(e,item.id,"object","object",index); }}
                                                                                                name="status_object"
                                                                                                checked={item.active}
                                                                                                value={item.active}
                                                                                                inputProps={{ 'aria-label': "status" }}
                                                                                                className={this.props.classes.switch}
                                                                                                classes={{
                                                                                                    switchBase: this.props.classes.switchBase,
                                                                                                    track: this.props.classes.track,
                                                                                                }}
                                                                                            />

                                                                                        </p>

                                                                                    </Grid>
                                                                                }
                                                                                <Grid item xs={6} sm={6} lg={1} className="flex-center" style={{ paddingBottom: "27px" }}>
                                                                                    <img className="build-course-icon" src={trash} style={{ width: "30px", height: "30px" }} onClick={() => handleClickOpenAlert(item.type, item.id)} />
                                                                                </Grid>
                                                                                <Grid item xs={6} sm={6} lg={1} className="flex-center" style={{ paddingBottom: "27px" }} {...draggableProvided.dragHandleProps}>
                                                                                    <img className="build-course-icon" src={ShapePng} style={{ width: "30px", height: "30px" }} />
                                                                                </Grid>
                                                                            </Grid>
                                                                        </CardContent>
                                                                    </Card>
                                                                </Grid>
                                                            </Grid>}
                                                            </div>
                                                        }}
                                                    </Draggable>
                                                })}
                                                {droppableProvided.placeholder}
                                                <Grid container>
                                                    <Grid item md={12} xs={12}>
                                                        <Card className="customize-dashed-border">
                                                            <CardContent>
                                                                <Grid item container md={12} xs={12} className="build-container">
                                                                    <Grid item md={2} xs={6} style={{ display: "flex", textAlign: "start", cursor: "pointer" }}>
                                                                        <img className="build-course-img" src={AddQuizIcon}
                                                                        />
                                                                        <div className="build-course-title">
                                                                            <p className="build-course-heading"
                                                                             onClick={() => {handleClickOpen();this.setState({program_phase:1})}}
                                                                            style={{ padding: "10px 10px 20px 10px" }}>Add more</p>
                                                                        </div>
                                                                    </Grid>
                                                                </Grid>
                                                            </CardContent>
                                                        </Card>
                                                    </Grid>
                                                </Grid>
                                            </AccordionDetails>
                                        }}
                                    </Droppable>
                                </Accordion>
                            </React.Fragment>
                        
                    }
                        {this.state.programs !== "" && Object.keys(this.state.programs?.attributes?.phases).length===0 && this.state?.programs?.attributes?.program_includes.length===0  && <Grid container>
                            <Grid item md={12} xs={12}>
                                <Card className="customize-dashed-border">
                                    <CardContent>

                                        <Grid item container md={12} xs={12} className="build-container">
                                            <Grid
                                                item
                                                md={3}
                                                xs={6}
                                                style={{ display: "flex", textAlign: "start", cursor: "pointer" }}
                                                onClick={handleClickOpen}
                                            >
                                                <img className="build-course-img" src={AddVideoIcon}
                                                />
                                                <div className="build-course-title">
                                                    <p className="build-course-heading" style={{ padding: "10px 10px 20px 10px" }}>Add Course/Object</p>
                                                </div>
                                            </Grid>
                                        </Grid>
                                    </CardContent>
                                </Card>
                            </Grid>
                        </Grid>}
                    </div>
                    <Grid item container md={12} xs={12} spacing={4}>
                        {this.state?.programs?.attributes && this.state?.programs?.attributes?.add_reward && <Grid item md={6} xs={12}>
                            <p className="reward-heading">Rewards</p>
                            <div className="customize-dashed-border" style={{ padding: "20px" }}>
                                <Grid container >
                                    {this.state.programs?.attributes?.rewards.length > 0 && this.state.programs?.attributes?.rewards.map((item: any) => <Fragment key={item.id}>
                                        <Grid item md={2} xs={12}>
                                            <img src={item.attributes.thumbnail_image.url} className="reward-icon" />
                                            <p className="reward-title">{item.attributes.name}</p>
                                        </Grid>
                                    </Fragment>)}
                                </Grid>
                            </div>
                        </Grid>}
                        {this.state?.programs?.attributes && this.state?.programs?.attributes?.add_achievement && <Grid item md={6} xs={12}>
                            <p className="achieve-heading">Achievement</p>
                            <div className="customize-dashed-border" style={{ padding: "20px" }}>
                                <Grid container>
                                    {this.state.programs?.attributes?.achievements.length > 0 && this.state.programs?.attributes?.achievements.map((item: any) => <Fragment key={item.id}>
                                        <Grid item md={2}>
                                            <img src={item.attributes.thumbnail_image.url} className="achieve-icon" />
                                            <p className="achieve-title">{item.attributes.name}</p>
                                        </Grid>
                                    </Fragment>)}

                                </Grid>
                            </div>
                        </Grid>}
                    </Grid>
                </Grid>
                <Grid container >
                    <Grid item md={12} xs={12} className="flex-center" style={{marginTop:"10px"}}>
                        <Button className="draft-btn" fullWidth onClick={() => this.updateStatus('draft')}>Save to Draft</Button>
                    </Grid>
                    <Grid item md={12} xs={12} className="flex-center">
                        <Button className="proceed-content-btn" onClick={() => this.updateStatus('xpand_collection')} fullWidth>Build Program</Button>
                    </Grid>
                </Grid>

                <Dialog
                    fullWidth={true}
                    maxWidth={"sm"}
                    //@ts-ignore
                    open={this.state.open}
                    className="custom-dialog2"
                    onClose={handleClose}
                    aria-labelledby="max-width-dialog-title"
                    id="dialog-title"
                >
                    <DialogContent style={{ background: "#6C328B", display: "flex" }}>
                        <Grid container md={12} xs={12} className="main-content-dialog-grid">
                            <Grid item md={4} className="custom-line1" xs={12} style={{position:'relative'}}>
                            <Link to={{ pathname: "/CourseCreation/new/addCourse", state: { programId: this.state.programList.filter((item:any) => item.id == this.props?.navigation?.getParam('id')),part_of_program:true, phase:this.state?.program_phase} }}>
                                <img src={Teacher} className="plain-copy" />
                                <p className="dialog-text1">New Course</p>
                            </Link>
                            </Grid>
                            <Grid item md={4} xs={12} className="custom-line1" style={{position:'relative'}}>
                                <Link to={{ pathname: "/ExistingCourseList", state: { programId: this.props?.navigation.getParam('id') ,part_of_program:true, phase:this.state?.program_phase,title:this.state?.programs?.attributes?.title} }}>
                                    <img src={Union} className="quiz-icon" />
                                    <p className="dialog-text1">Existing Course</p>
                                </Link>
                            </Grid>
                            <Grid item md={4} xs={12} >
                                <Link to={{ pathname: `/addObject/${this.props?.navigation.getParam('id')}`, state: { programId: this.props?.navigation.getParam('id'),phase:this.state?.program_phase } }}>
                                    <img src={IconObject} className="quiz-icon" />
                                    <p className="dialog-text1">Object</p>
                                </Link>
                            </Grid>
                        </Grid>
                    </DialogContent>

                </Dialog>
                <Dialog
                    open={this.state.openAlert}
                    onClose={handleCloseOutsideAlert}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                    className="custom-dialog3"
                >
                    <DialogTitle id="alert-dialog-title-delete">{"Are you sure you want to delete “NAME” from the Course/Program"}</DialogTitle>
                    <DialogContent>
                        <DialogContentText id="alert-dialog-description-delete">
                            Deleted course or object can still be accessed in the content library
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={handleCloseOutsideAlert} className="no-btn">
                            No
                        </Button>
                        <Button onClick={handleCloseAlert} className="yes-btn" autoFocus>
                            Yes
                        </Button>
                    </DialogActions>
                </Dialog>
            </div>
        </DragDropContext>
    }
}
//@ts-ignore
export default withStyles(styles)(BuildProgram);
// Customizable Area End