// Customizable Area Start
import React from 'react';
import {
    Grid,
    Button,
    FormControl,
    Select,
    MenuItem,
    InputAdornment,
    Card,
    Avatar,
    IconButton,
    TextField,
    TablePagination,
    FormControlLabel,
    InputLabel,
    Dialog,
    DialogContent,
    Typography,
    List,
    ListItem,
    ListItemProps,
    ListItemIcon,
    Divider,
    ListItemText,
    DialogActions,
    ListItemSecondaryAction,
    DialogContentText

} from '@material-ui/core';
import { Search } from "@material-ui/icons";
import EmailAccountLoginController from './EmailAccountLoginController';
import {
    MuiPickersUtilsProvider,
    KeyboardTimePicker,
    KeyboardDatePicker,
    DatePicker
} from '@material-ui/pickers';
import 'date-fns';
import Radio, { RadioProps } from '@material-ui/core/Radio';
import DateFnsUtils from '@date-io/date-fns';
import { Theme, createStyles, makeStyles, withStyles, WithStyles } from '@material-ui/core/styles';
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import CloseIcon from "@material-ui/icons/Close";
import DeleteIcon from '@material-ui/icons/Delete';
import './UserSendInvite.css';
// import '../../videos/src/video.css';
import { Caret1, Caret2, Download, edit, message, share } from './assets';
const useStyles: any = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            width: '100%',
        },
        heading: {
            fontStyle: "normal",
            fontWeight: 600,
            fontSize: "22px",
            lineHeight: "33px",
            color: "#12142B"
        },
        secondaryHeading: {
            fontSize: theme.typography.pxToRem(15),
            color: theme.palette.text.secondary,
        },
        icon: {
            verticalAlign: 'bottom',
            height: 20,
            width: 20,
        },
        details: {
            alignItems: 'center',
        },
        column: {
            flexBasis: '33.33%',
        },
        helper: {
            borderLeft: `2px solid ${theme.palette.divider}`,
            padding: theme.spacing(1, 2),
        },
        link: {
            color: theme.palette.primary.main,
            textDecoration: 'none',
            '&:hover': {
                textDecoration: 'underline',
            },
        },
    }),
);
export interface Props {
    classes?: any
}
export interface State {
    selectDate: any,
    Open: Boolean,
    label: String,
    data: any,
    text: String
}

//@ts-ignore
const styles: any = (theme: Theme) =>
    createStyles({
        root: {
            margin: 0,
            padding: theme.spacing(2)
        },
        closeButton: {
            position: "absolute",
            right: theme.spacing(1),
            top: theme.spacing(1),
            color: theme.palette.grey[500]
        }
    });
//@ts-ignore
export interface DialogTitleProps extends WithStyles<typeof styles> {
    id: string;
    children: React.ReactNode;
    onClose: () => void;
}

const DialogTitle = withStyles(styles)((props: DialogTitleProps) => {
    const { children, classes, onClose, ...other } = props;
    return (
        <MuiDialogTitle disableTypography className={classes.root} {...other}>
            <Typography variant="h6">{children}</Typography>
            {onClose ? (
                <IconButton
                    aria-label="close"
                    className={classes.closeButton}
                    onClick={onClose}
                >
                    <CloseIcon />
                </IconButton>
            ) : null}
        </MuiDialogTitle>
    );
});

class UserSendInvite extends React.Component<Props, State> {
    constructor(props: any) {
        super(props)
        this.state = {
            selectDate: new Date('2014-08-18T21:11:54'),
            Open: false,
            label: "",
            data: [],
            text: ""
        }


    }
    handleDateChange = (date: Date | null) => {
        this.setState({ selectDate: date })

    }
    openDialog(data: any) {
        this.setState({ Open: !this.state.Open, label: data })
    }
    add() {
        this.state.data.push({ label: this.state.label, text: this.state.text })
        this.setState({ data: this.state.data })
    }
    handleChange(e: any) {
        if (e.target.value === "") {
            this.setState({ text: "" })
        }
        else {
            this.setState({ text: e.target.value })
        }
    }
    removeItem(index: any) {
        this.state.data.splice(index, 1)
        this.setState({ data: this.state.data })
    }

    render() {
        return (<>
            <div className="user-send-invite">
                <Grid container style={{ paddingBottom: "30px" }} spacing={4}>

                    <Grid item md={4} xs={10}>

                        <TextField
                            fullWidth
                            id="search"
                            name="search"
                            variant="outlined"
                            placeholder="Search"
                            InputLabelProps={{
                                shrink: true
                            }}
                            className="search"
                            InputProps={{
                                startAdornment: (
                                    <InputAdornment position="start">
                                        <Search />
                                    </InputAdornment>
                                ),
                            }}
                        />

                    </Grid>
                    <Grid item md={2} style={{ textAlign: "center" }} >
                        <Button className='create-user-btn'>Create User</Button>
                    </Grid>
                    <Grid item md={2}></Grid>
                    <Grid item md={2} xs={12}>
                    </Grid>
                    <Grid item md={2} style={{ textAlign: "end" }}>
                        <Button className='send-invite-user-btn'>Send Invite(s)</Button>
                    </Grid>
                </Grid>
                <Grid container style={{ marginTop: "10px" }}>
                    <Grid item md={12} style={{ textAlign: "end" }}>
                        <Button className='create-user-btn' onClick={() => this.openDialog("bulk")}>Bulk User Upload</Button>
                    </Grid>
                </Grid>
                <Grid container spacing={4}>
                    <Grid item md={12} xs={12}>
                        <div className={this.props.classes.root}>
                            <form style={{ width: "100%" }}>
                                <Grid container spacing={4}>
                                    <Grid item md={6} xs={12}>
                                        <InputLabel className="input-label" htmlFor='select_branch'>Select Branch</InputLabel>
                                        <FormControl className="select-all">
                                            <Select
                                                labelId="select_branch"
                                                id="select_branch"
                                                name='select_branch'
                                                variant="outlined"
                                                IconComponent={() => null}
                                                endAdornment={
                                                    <InputAdornment position="end">
                                                        <img src={Caret1} />
                                                    </InputAdornment>

                                                }
                                                value=""
                                                fullWidth>
                                                <MenuItem value="">
                                                    <em>Select Branch</em>
                                                </MenuItem>
                                                <MenuItem value={1}>Phase 1</MenuItem>
                                                <MenuItem value={2}>Phase 2</MenuItem>
                                                <MenuItem value={3}>Phase 3</MenuItem>
                                                <MenuItem value={4}>Phase 4</MenuItem>
                                                <MenuItem value={5}>Phase 5</MenuItem>
                                            </Select>
                                        </FormControl>

                                    </Grid>
                                    <Grid item md={6} xs={12}>
                                        <InputLabel className="input-label" htmlFor='select_team'>Select Team <span className='optional-field'>(Optional)</span></InputLabel>
                                        <FormControl className="select-all">
                                            <Select
                                                labelId="select_team"
                                                id="select_team"
                                                name='select_team'
                                                variant="outlined"
                                                IconComponent={() => null}
                                                endAdornment={
                                                    <InputAdornment position="end">
                                                        <img src={Caret1} />
                                                    </InputAdornment>

                                                }
                                                value=""
                                                fullWidth>
                                                <MenuItem value="">
                                                    <em>Select Team</em>
                                                </MenuItem>
                                                <MenuItem value={1}>Phase 1</MenuItem>
                                                <MenuItem value={2}>Phase 2</MenuItem>
                                                <MenuItem value={3}>Phase 3</MenuItem>
                                                <MenuItem value={4}>Phase 4</MenuItem>
                                                <MenuItem value={5}>Phase 5</MenuItem>
                                            </Select>
                                        </FormControl>
                                    </Grid>

                                </Grid>
                                <Grid container spacing={4}>
                                    <Grid item md={6} xs={12}>
                                        <InputLabel className="input-label" htmlFor='select_content_role'>Select Content Role <span className='optional-field'>(Optional)</span></InputLabel>
                                        <FormControl className="select-all">
                                            <Select
                                                labelId="select_content_role"
                                                id="select_content_role"
                                                name='select_content_role'
                                                variant="outlined"
                                                IconComponent={() => null}
                                                endAdornment={
                                                    <InputAdornment position="end">
                                                        <img src={Caret1} />
                                                    </InputAdornment>

                                                }
                                                value=""
                                                fullWidth>
                                                <MenuItem value="">
                                                    <em>Select Content Role</em>
                                                </MenuItem>
                                                <MenuItem value={1}>Phase 1</MenuItem>
                                                <MenuItem value={2}>Phase 2</MenuItem>
                                                <MenuItem value={3}>Phase 3</MenuItem>
                                                <MenuItem value={4}>Phase 4</MenuItem>
                                                <MenuItem value={5}>Phase 5</MenuItem>
                                            </Select>
                                        </FormControl>

                                    </Grid>
                                    <Grid item md={6} xs={12}>
                                        <InputLabel className="input-label" htmlFor='select_content_role'>Select Content Bundle <span className='optional-field'>(Optional)</span></InputLabel>
                                        <FormControl className="select-all">
                                            <Select
                                                labelId="select_content_bundle"
                                                id="Select Content Bundle"
                                                name='Select Content Bundle'
                                                variant="outlined"
                                                IconComponent={() => null}
                                                endAdornment={
                                                    <InputAdornment position="end">
                                                        <img src={Caret1} />
                                                    </InputAdornment>

                                                }
                                                value=""
                                                fullWidth>
                                                <MenuItem value="">
                                                    <em>Select Content Bundle</em>
                                                </MenuItem>
                                                <MenuItem value={1}>Phase 1</MenuItem>
                                                <MenuItem value={2}>Phase 2</MenuItem>
                                                <MenuItem value={3}>Phase 3</MenuItem>
                                                <MenuItem value={4}>Phase 4</MenuItem>
                                                <MenuItem value={5}>Phase 5</MenuItem>
                                            </Select>
                                        </FormControl>

                                    </Grid>

                                </Grid>
                                <Grid container spacing={4}>
                                    <Grid item md={6} xs={12}>
                                        <InputLabel className="input-label" htmlFor='select_user_type'>Select User Type</InputLabel>
                                        <FormControl className="select-all">
                                            <Select
                                                labelId="select_user_type"
                                                id="select_user_type"
                                                name='select_user_type'
                                                variant="outlined"
                                                IconComponent={() => null}
                                                endAdornment={
                                                    <InputAdornment position="end">
                                                        <img src={Caret1} />
                                                    </InputAdornment>

                                                }
                                                value=""
                                                fullWidth>
                                                <MenuItem value="">
                                                    <em>Select User Type</em>
                                                </MenuItem>
                                                <MenuItem value={1}>Phase 1</MenuItem>
                                                <MenuItem value={2}>Phase 2</MenuItem>
                                                <MenuItem value={3}>Phase 3</MenuItem>
                                                <MenuItem value={4}>Phase 4</MenuItem>
                                                <MenuItem value={5}>Phase 5</MenuItem>
                                            </Select>
                                        </FormControl>

                                    </Grid>
                                    <Grid item md={6} xs={12}>
                                        <InputLabel className="input-label" htmlFor='select_course_program'>Select Course/Program <span className='optional-field'>(Optional)</span></InputLabel>
                                        <FormControl className="select-all">
                                            <Select
                                                labelId="select_course_program"
                                                id="select_course_program"
                                                name='select_course_program'
                                                variant="outlined"
                                                IconComponent={() => null}
                                                endAdornment={
                                                    <InputAdornment position="end">
                                                        <img src={Caret1} />
                                                    </InputAdornment>

                                                }
                                                value=""
                                                fullWidth>
                                                <MenuItem value="">
                                                    <em>Select Course/Program</em>
                                                </MenuItem>
                                                <MenuItem value={1}>Phase 1</MenuItem>
                                                <MenuItem value={2}>Phase 2</MenuItem>
                                                <MenuItem value={3}>Phase 3</MenuItem>
                                                <MenuItem value={4}>Phase 4</MenuItem>
                                                <MenuItem value={5}>Phase 5</MenuItem>
                                            </Select>
                                        </FormControl>
                                    </Grid>

                                </Grid>
                            </form>


                        </div>
                    </Grid>
                </Grid>
                <Grid container spacing={4}>
                    <Grid item md={6} xs={12}>
                        <Card className='user-box'>
                            <div className={this.props.classes.root}>
                                <form style={{ width: "100%" }}>
                                    <InputLabel className="input-label-heading">Limited Time Access*</InputLabel>
                                    <div className='' style={{ display: "flex", flexDirection: "row" }}>
                                        <FormControlLabel value="yes" control={<Radio />} label="Yes" />
                                        <FormControlLabel value="no" control={<Radio />} label="No" />

                                    </div>
                                    <InputLabel className="date-label">Start and End date for Access</InputLabel>
                                    <Grid container spacing={4}>
                                        <Grid item md={12} xs={12}>
                                            <Grid container md={12} spacing={4}>
                                                <Grid item md={6} xs={12}>
                                                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                                        <KeyboardDatePicker
                                                            disableToolbar
                                                            variant="inline"
                                                            format="MM/dd/yyyy"
                                                            margin="normal"
                                                            id="start_date"
                                                            label="Start Date"
                                                            fullWidth
                                                            className='custom-calender'
                                                            onChange={(e) => console.log(e)}
                                                            value={this.state.selectDate}
                                                        />
                                                    </MuiPickersUtilsProvider>
                                                </Grid>
                                                <Grid item md={6} xs={12}>
                                                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                                        <KeyboardDatePicker
                                                            disableToolbar
                                                            variant="inline"
                                                            format="MM/dd/yyyy"
                                                            margin="normal"
                                                            id="end_date"
                                                            fullWidth
                                                            className='custom-calender'
                                                            label="End Date"
                                                            KeyboardButtonProps={{
                                                                'aria-label': 'change date',
                                                            }} onChange={(e) => this.handleDateChange(e)} value={this.state.selectDate} />
                                                    </MuiPickersUtilsProvider>
                                                </Grid>



                                            </Grid>
                                        </Grid>

                                    </Grid>


                                </form>
                            </div>
                        </Card>
                    </Grid>
                    <Grid item md={6} xs={12}>
                        <Card className="user-box">
                            <InputLabel className="input-label-heading">Does the Link Expire*</InputLabel>
                            <div className='' style={{ display: "flex", flexDirection: "row" }}>
                                <FormControlLabel value="yes" control={<Radio />} label="Yes" />
                                <FormControlLabel value="no" control={<Radio />} label="No" />

                            </div>
                            <InputLabel className="date-label">End date of Link Access</InputLabel>

                            <Grid item md={6} xs={12}>
                                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                    <KeyboardDatePicker
                                        disableToolbar
                                        variant="inline"
                                        format="MM/dd/yyyy"
                                        margin="normal"
                                        id="start_date"
                                        className='custom-calender'
                                        label="Start Date"
                                        KeyboardButtonProps={{
                                            'aria-label': 'change date',
                                        }} onChange={(e) => this.handleDateChange(e)} value={this.state.selectDate}

                                    />
                                </MuiPickersUtilsProvider>


                            </Grid>
                        </Card>
                    </Grid>
                </Grid>
                <InputLabel className="input-label">Share Link:</InputLabel>
                <Grid container style={{ marginTop: "10px" }}>
                    <Grid item md={2} xs={12}>
                        <Button className='send-invite-user-btn'><img src={message} onClick={() => this.openDialog("email")} /> Email</Button>
                    </Grid>
                    <Grid item md={2} xs={12}>
                        <Button className='send-invite-user-btn'><img src={message} onClick={() => this.openDialog("phone")} /> Text Message</Button>
                    </Grid>

                    <Grid item md={2} xs={12}>
                        <Button className='send-invite-user-btn'><img src={share} onClick={() => this.openDialog("link")} /> Copy URL</Button>
                    </Grid>
                </Grid>
                <Dialog
                    //@ts-ignore
                    open={this.state.Open}
                    onClose={() => this.openDialog("")}
                    aria-labelledby="form-dialog-title"
                    className='custom-dialog'
                    fullWidth={true}
                    maxWidth={"sm"}

                >

                    {//@ts-ignore
                        <DialogTitle
                            id="customized-dialog-title"
                        >

                            <InputLabel className='input-label-heading'>{this.state.label === "link" && "Send Link to:"}
                                {this.state.label === "bulk" && "CSV Bulk Upload"}
                            </InputLabel></DialogTitle>}


                    <DialogContent>
                        {this.state.label === "bulk" && <DialogContentText>
                            <InputLabel className='csv-text'><img src={Download}/> Download Example CSV File</InputLabel>
                            </DialogContentText>
                        }
                        {this.state.label !== "bulk" && <>
                            <Grid container md={12} xs={12} style={{ marginTop: "10px" }}>
                                <Grid item md={12}>
                                    <InputLabel className='input-label'>{this.state.label.charAt(0).toUpperCase() + this.state.label.slice(1)}</InputLabel>
                                    <TextField
                                        //@ts-ignore
                                        name={this.state.label}
                                        type="text"
                                        variant="outlined"
                                        onChange={(e) => { this.handleChange(e) }}
                                        placeholder={`${this.state.label.charAt(0).toUpperCase() + this.state.label.slice(1)}`}
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                        fullWidth
                                        InputProps={{
                                            endAdornment: (this.state.label !== "link" && <>
                                                <InputAdornment position="end">
                                                    <IconButton className='add-list' onClick={() => this.add()}>
                                                        +
                                                    </IconButton>
                                                </InputAdornment>
                                            </>
                                            )
                                        }}

                                    />
                                </Grid>

                            </Grid>
                            <Grid container md={12} xs={12} style={{ marginTop: "10px" }}>
                                <Grid item md={12} xs={12}>
                                    <List component="nav" aria-label="main mailbox folders">
                                        {this.state.data.map((item: any, index: any) => {
                                            return <>
                                                {this.state.label === "phone" && item.label === "phone" && <ListItem button>
                                                    <ListItemIcon>
                                                        #{index}
                                                    </ListItemIcon>
                                                    <ListItemText primary={item.text} />
                                                    <ListItemSecondaryAction>
                                                        <IconButton edge="end" aria-label="delete" onClick={() => this.removeItem(index)}>
                                                            <DeleteIcon />
                                                        </IconButton>
                                                    </ListItemSecondaryAction>
                                                </ListItem>}
                                                {this.state.label === "email" && item.label === "email" && <ListItem button>
                                                    <ListItemIcon>
                                                        #{index}
                                                    </ListItemIcon>
                                                    <ListItemText primary={item.text} />
                                                    <ListItemSecondaryAction>
                                                        <IconButton edge="end" aria-label="delete" onClick={() => this.removeItem(index)}>
                                                            <DeleteIcon />
                                                        </IconButton>
                                                    </ListItemSecondaryAction>
                                                </ListItem>
                                                }

                                            </>

                                        })}
                                    </List>

                                </Grid>
                            </Grid></>}
                        {this.state.label === "bulk" && <>
                            <Grid container>
                                <Grid item md={12} xs={12}>
                                    <div className='dashed-border'>
                                        <p className='drag-text'>Drag and Drop CSV File</p>
                                        <div style={{display:"flex",width:"100%"}}>
                                        <hr className='outer-border' />
                                        <p className='drag-text2'>Or</p>
                                        <hr className='outer-border' />
                                        </div>
                                        <p className='bottom-text'>Select from Files</p>
                                    </div>
                                </Grid>
                            </Grid>
                        </>}


                    </DialogContent>
                    <DialogActions>
                        <Button onClick={() => this.openDialog("")} className="cancel-btn">
                            Cancel
                        </Button>
                        <Button onClick={() => this.openDialog("")} className="send-btn">
                            Send
                        </Button>
                    </DialogActions>
                </Dialog>



            </div>
        </>);
    }
}
export default withStyles(useStyles)(UserSendInvite);
// Customizable Area End