import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  handleClose:any;
  classes?: any;
  location?: any;
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  timeout: any;
  data: any;
  isLoading: boolean;
  // Customizable Area End
}

interface SS {
  id: any;
}

export default class SplashscreenController extends BlockComponent<
  Props,
  S,
  SS
> {
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    this.state = {
      timeout: 2000,
      // Customizable Area Start
      data: "",
      isLoading: true,
      // Customizable Area End
    };

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.NavigationPayLoadMessage),
      getName(MessageEnum.CountryCodeMessage),
    ];
    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );
      var responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      var errorReponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );

      if (this.getTermsApiCallId === apiRequestCallId) {
        if (responseJson && responseJson.data) {
          this.setState({
            data: responseJson.data.description,
            isLoading: false,
          });
        } else {
          this.setState({ data: "" });
        }
      }
    }
    // Customizable Area End
  }

  // Customizable Area Start
  async componentDidMount() {
    super.componentDidMount();
    this.getTermsData("");
    // setTimeout(() => {
    //   this.goToHome()
    // }, this.state.timeout);
  }
  getTermsApiCallId: any;
  goToHome() {
    if (this.state.timeout > 0) {
      const msg: Message = new Message(
        getName(MessageEnum.NavigationHomeScreenMessage)
      );
      msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
      this.send(msg);
    }
  }
  getTermsData(data: any) {
    this.doGetTermsData({
      contentType: configJSON.termApiContentType,
      method: configJSON.validationApiMethodType,
      endPoint: configJSON.termAPiEndPoint,
    });
  }

  // API Call API Call
  doGetTermsData(data: any) {
    const { contentType, method, endPoint, body } = data;
    // const token = (await StorageProvider.get("Userdata")) || "";
    const header = {
      "Content-Type": contentType,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    this.getTermsApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      endPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      method
    );
    body &&
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        body
      );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return requestMessage.messageId;
  }
  // Customizable Area End
}
