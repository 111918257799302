import React, { ReactNode } from "react";
import { Button, IconButton, ButtonProps } from "@material-ui/core";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import "./styles.css";

interface Props {
  children?: ReactNode;
}

export const Table = (props: Props) => {
  return (
    <div className="table-container">
      <table className="table">{props.children}</table>
    </div>
  );
};

export const Thead = (props: Props) => {
  return <thead>{props.children}</thead>;
};

export const Tr = (props: Props) => {
  return <tr className="tr">{props.children}</tr>;
};

export const Th = (props: Props) => {
  return <th className="th">{props.children}</th>;
};

export const Tbody = (props: Props) => {
  return <tbody>{props.children}</tbody>;
};

export const Td = (props: Props) => {
  return <td className="td">{props.children}</td>;
};

export const SelectAllBtn = (props: ButtonProps) => {
  return (
    <Button {...props} className="selectall-btn">
      Select all
    </Button>
  );
}

interface PaginationProps {
  disabledPrevBtn: boolean;
  disabledNextBtn: boolean;
  onNextClick: () => void;
  onPrevClick: () => void;
}

export const Pagination = ({
  disabledNextBtn,
  disabledPrevBtn,
  onNextClick,
  onPrevClick,
}: PaginationProps) => {
  return (
    <div className="pagination">
      <IconButton 
        disabled={disabledPrevBtn}
        onClick={onPrevClick}
      >
        <ChevronLeftIcon 
          className="arrow-icon" 
          style={{ color: !disabledPrevBtn ? "#6c328b" : "" }} 
        />
      </IconButton>
      <Button
        className="btn"
        disabled={disabledNextBtn}
        endIcon={<ChevronRightIcon className="arrow-icon" />}
        onClick={onNextClick}
      >
        Next
      </Button>
    </div>
  );
}