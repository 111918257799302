Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.validationApiContentType = "application/json";
exports.validationApiMethodType = "GET";
exports.exampleAPiEndPoint = "EXAMPLE_URL";
exports.exampleAPiMethod = "POST";
exports.overAllCourseReport = "bx_block_adhocreporting/course_video_track/all_report"
exports.spentTrainingTime = "bx_block_adhocreporting/training_track"
exports.spentVideoTrainingTime ="bx_block_adhocreporting/course_video_track"
exports.exampleApiContentType = "application/json";
exports.textInputPlaceHolder = "Enter Text";
exports.labelTitleText = "CertificationTracking";
exports.labelBodyText = "CertificationTracking Body";
exports.report = "Report";
exports.back ="Back"
exports.courseReport = "Course Report";

exports.btnExampleTitle = "CLICK ME";
// Customizable Area End