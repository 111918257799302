/* eslint-disable no-use-before-define */
import React from 'react';
import {forwardRef, useImperativeHandle, useRef} from 'react';
import { useTheme, alpha, makeStyles, Theme, createStyles } from '@material-ui/core/styles';
import Popper from '@material-ui/core/Popper';
import SettingsIcon from '@material-ui/icons/Settings';
import DeleteIcon from '@material-ui/icons/Close';
import DoneIcon from '@material-ui/icons/Done';
//@ts-ignore
import Autocomplete, { AutocompleteCloseReason } from '@material-ui/lab/Autocomplete';
import Button from '@material-ui/core/Button';
import InputBase from '@material-ui/core/InputBase';
import './AutoComplete.css';
import { set } from 'lodash';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: 221,
      fontSize: 13,
    },
    button: {
      fontSize: 13,
      width: '100%',
      textAlign: 'left',
      paddingBottom: 8,
      color: '#586069',
      fontWeight: 600,
      '&:hover,&:focus': {
        color: '#0366d6',
      },
      '& span': {
        width: '100%',
      },
      '& svg': {
        width: 16,
        height: 16,
      },
    },
    tag: {
      marginTop: 3,
      height: 20,
      padding: '.15em 4px',
      fontWeight: 600,
      lineHeight: '15px',
      borderRadius: 2,
    },
    popper: {
      border: '1px solid rgba(27,31,35,.15)',
      boxShadow: '0 3px 12px rgba(27,31,35,.15)',
      borderRadius: 3,
      width: 300,
      zIndex: 1,
      fontSize: 13,
      color: '#586069',
      backgroundColor: '#f6f8fa',
    },
    header: {
      borderBottom: '1px solid #e1e4e8',
      padding: '8px 10px',
      fontWeight: 600,
    },
    inputBase: {
      padding: 10,
      width: '100%',
      borderBottom: '1px solid #dfe2e5',
      '& input': {
        borderRadius: 4,
        backgroundColor: theme.palette.common.white,
        padding: 8,
        transition: theme.transitions.create(['border-color', 'box-shadow']),
        border: '1px solid #ced4da',
        fontSize: 14,
        '&:focus': {
          boxShadow: `${alpha(theme.palette.primary.main, 0.25)} 0 0 0 0.2rem`,
          borderColor: theme.palette.primary.main,
        },
      },
    },
    paper: {
      background: "#FAFAFA",
      border: "1px solid #DEDEDE",
      borderRadius: "12px",
      boxShadow: 'none',
      margin: 0,
      color: '#586069',
      fontSize: 13,
    },
    option: {
      minHeight: 'auto',
      alignItems: 'flex-start',
      padding: 8,
      '&[aria-selected="true"]': {
        backgroundColor: 'transparent',
      },
      '&[data-focus="true"]': {
        backgroundColor: theme.palette.action.hover,
      },
    },
    popperDisablePortal: {
      position: 'relative',
    },
    iconSelected: {
      width: 17,
      height: 17,
      marginRight: 5,
      marginLeft: -2,
    },
    color: {
      width: 14,
      height: 14,
      flexShrink: 0,
      borderRadius: 3,
      marginRight: 8,
      marginTop: 2,
    },
    text: {
      flexGrow: 1,
    },
    close: {
      opacity: 0.6,
      width: 18,
      height: 18,
    },
  }),
);
interface LabelType {
  name: string;
  price:string;
}

// From https://github.com/abdonrd/github-labels
const labels = [
  {
    name: 'George Brown',
    price: '$2500',
  },
  {
    name: 'George Brown',
    price: '$2500',
  },
  {
    name: 'George Brown',
    price: '$2500',
  },
  {
    name: 'George Brown',
    price: '$2500',
  },
  {
    name: 'George Brown',
    price: '$2500',
  },
  {
    name: 'George Brown',
    price: '$2500',
  },
  {
    name: 'George Brown',
    price: '$2500',
  },

];
 export interface Props {
  openDialog:any
  handleOpenPopUp: any
 }
  const   Autocompletes : React.FC<Props> = forwardRef ((props,ref) => {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const [value, setValue] = React.useState<LabelType[]>([labels[1], labels[11]]);
  // const [open, setOpen] = React.useState<Boolean>(false);
  const [pendingValue, setPendingValue] = React.useState<LabelType[]>([]);
  const theme = useTheme();
  useImperativeHandle(ref, () => ({
     handleClick (event: React.MouseEvent<HTMLElement>)  {
      setPendingValue(value);
      setAnchorEl(event.currentTarget);
    }
  }));
console.log(props,"props")

  const handleClose = (event: React.ChangeEvent<{}>, reason: AutocompleteCloseReason) => {
    if (reason === 'toggleInput') {
      return;
    }
    setValue(pendingValue);
    if (anchorEl) {
      anchorEl.focus();
    }
    // setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? 'github-label' : undefined;

  const handleClickNew = (e:any) => {
    e.preventDefault();
    e.stopPropagation();
    props.openDialog();
    setAnchorEl(null)
  }

  return (
    <React.Fragment>
      <Popper
        id={id}
        open={open}
        anchorEl={anchorEl}
        placement="bottom-start"
        className={classes.popper}
        onMouseLeave={() => setAnchorEl(null)}
      >
        {/* <div className={classes.header} style={{justifyContent:"center",textAlign:"center"}}> */}
        <Button className="download-btn" style={{textAlign:"center",margin:"10px"}} onClick={(e) => {handleClickNew(e)}}>+ New Subscription Payment</Button>
        {/* </div> */}
        <Autocomplete
         //@ts-ignore
          open
          onClose={handleClose}
          multiple
          classes={{
            paper: classes.paper,
            option: classes.option,
            popperDisablePortal: classes.popperDisablePortal,
          }}
          value={pendingValue}
          //@ts-ignore
          onChange={(event:Event, newValue:any) => {
            setPendingValue(newValue);
          }}
          disableCloseOnSelect
          disablePortal
          renderTags={() => null}
          noOptionsText="No labels"
          renderOption={(option:any, { selected }:any) => (
            <React.Fragment>
              <DoneIcon
                className={classes.iconSelected}
                style={{ visibility: selected ? 'visible' : 'hidden' }}
              />
              <span className={classes.color} style={{ backgroundColor: option.color }} />
              <div className={classes.text}>
                {option.name}
                <br />
                {option.price}
              </div>
              <DeleteIcon
                className={classes.close}
                style={{ visibility: selected ? 'visible' : 'hidden' }}
              />
            </React.Fragment>
          )}
          options={[...labels].sort((a, b) => {
            // Display the selected labels first.
            let ai = value.indexOf(a);
            ai = ai === -1 ? value.length + labels.indexOf(a) : ai;
            let bi = value.indexOf(b);
            bi = bi === -1 ? value.length + labels.indexOf(b) : bi;
            return ai - bi;
          })}
          getOptionLabel={(option:any) => option.name}
          renderInput={(params:any) => (
            <InputBase
              ref={params.InputProps.ref}
              inputProps={params.inputProps}
              autoFocus
              className={classes.inputBase}
              placeholder="Search"
            />
          )}
        />
      </Popper>
    </React.Fragment>
  );
})
export default Autocompletes


