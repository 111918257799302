import React, { useState } from "react";
import { Card, CardContent, IconButton, Menu } from "@material-ui/core";
import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";
import {
  Table,
  Thead,
  Tbody,
  Th,
  Tr,
  Td,
  SelectAllBtn,
  Pagination,
} from "../../../../../../components/src/CustomTableV3";
import { UpArrowIcon, DownArrowIcon } from "../assets";

const TABLE_HEADERS = [
  { id: 1, label: "", sort: false },
  { id: 2, label: "User Name", sort: true, dataType: "string", key: "name" },
  { id: 3, label: "Branch", sort: true, dataType: "string", key: "branch" },
  { id: 4, label: "Teams", sort: false },
  { id: 5, label: "Created By", sort: false },
  {
    id: 6,
    label: "Created Date",
    sort: true,
    dataType: "date",
    key: "createdDate",
  },
  {
    id: 7,
    label: "Login History",
    sort: true,
    dataType: "date",
    key: "loginHistory",
  },
  {
    id: 8,
    label: "User Status",
    sort: true,
    dataType: "string",
    key: "accountStatus",
  },
  { id: 9, label: "", sort: false },
];

interface TableHeader {
  id: number;
  label: string;
  sort: boolean;
  dataType?: string;
  key?: string;
}

interface Props {
  users: any;
  onSortIconClick: (dataType: string, key: string, sortType: string) => void;
  prevPageExists: boolean;
  nextPageExists: boolean;
  handleNextPageNavigation: () => void;
  handlePrevPageNavigation: () => void;
}

const formatDate = (inputDate: string) => {
  const date = new Date(inputDate);
  const year = date.getFullYear() % 100; // Get last two digits of the year
  const month = date.getMonth() + 1; // Months are zero-based
  const day = date.getDate();

  return `${month}/${day}/${year}`;
};

export default function UserTable({
  users,
  onSortIconClick,
  prevPageExists,
  nextPageExists,
  handleNextPageNavigation,
  handlePrevPageNavigation,
}: Props) {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [userId, setUserId] = useState(0);

  const handleHover = (
    event: React.MouseEvent<HTMLSpanElement>,
    id: number
  ) => {
    if (anchorEl !== event.currentTarget) {
      setAnchorEl(event.currentTarget);
      setUserId(id);
    }
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  let teamNamesContent: React.ReactNode;
  if (userId > 0) {
    const user = users.find((user: any) => user.id === userId);
    teamNamesContent = user?.teamCount > 0 ? (
      <Menu
        id="simple-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
        MenuListProps={{ onMouseLeave: handleClose }}
        classes={{ paper: classes.paper }}
      >
        <div className={classes.teamNamesContainer}>
          <p>Teams</p>
          {user.teamNames.map((name: string) => {
            return (
              <p key={name} onClick={handleClose}>
                {name}
              </p>
            );
          })} 
        </div>
      </Menu>
    ) : null;
  }

  return (
    <Card className={classes.card}>
      <CardContent>
        <Table>
          <Thead>
            <Tr>
              {TABLE_HEADERS.map((header: TableHeader) => {
                return header.label === "" ? (
                  <Th key={header.id} />
                ) : (
                  <Th key={header.id}>
                    <p className={classes.headingLabel}>
                      {header.label}{" "}
                      <span className={classes.arrowIconsContainer}>
                        {header.sort && (
                          <>
                            <img
                              src={UpArrowIcon}
                              width="15px"
                              onClick={() =>
                                onSortIconClick(
                                  header?.dataType ?? "",
                                  header?.key ?? "",
                                  "asc"
                                )
                              }
                            />

                            <img
                              src={DownArrowIcon}
                              width="15px"
                              onClick={() =>
                                onSortIconClick(
                                  header?.dataType ?? "",
                                  header?.key ?? "",
                                  "desc"
                                )
                              }
                            />
                          </>
                        )}
                      </span>
                    </p>
                  </Th>
                );
              })}
            </Tr>
          </Thead>
          <Tbody>
            {users.map((user: any) => {
              return (
                <Tr key={user.id}>
                  <Td>
                    <input
                      type="checkbox"
                      style={{
                        width: 18,
                        height: 18,
                      }}
                    />
                  </Td>
                  <Td>{user.name}</Td>
                  <Td>{user.branch}</Td>
                  <Td>
                    <span
                      style={{ display: "block", width: "100%" }}
                      onMouseOver={(e: React.MouseEvent<HTMLButtonElement>) => {
                        handleHover(e, user.id);
                      }}
                    >
                      {user.teamCount}
                    </span>
                    {teamNamesContent}
                  </Td>
                  <Td>{user.createdBy}</Td>
                  <Td>
                    {user.createdDate
                      ? formatDate(user.createdDate)
                      : "-"}
                  </Td>
                  <Td>
                    {user.loginHistory
                      ? formatDate(user.loginHistory)
                      : "-"}
                  </Td>
                  <Td>{user.accountStatus}</Td>
                  <Td>
                    {user?.actions?.length > 0 &&
                      user.actions.map((action: any) => {
                        return (
                          <IconButton
                            key={action.id}
                            onClick={action.handleClick}
                            disabled
                          >
                            <img src={action.icon} alt="icon" />
                          </IconButton>
                        );
                      })}
                  </Td>
                </Tr>
              );
            })}
          </Tbody>
        </Table>
        <div className={classes.tableFooer}>
          <SelectAllBtn disabled />
          <Pagination
            disabledPrevBtn={!prevPageExists}
            disabledNextBtn={!nextPageExists}
            onNextClick={handleNextPageNavigation}
            onPrevClick={handlePrevPageNavigation}
          />
        </div>
      </CardContent>
    </Card>
  );
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    card: {
      borderRadius: "19.5px",
      border: "1px solid #E7E7E7",
      background: "#FAFAFA",
    },
    headingLabel: {
      display: "flex",
      flexDirection: "row",
      justifyContent: "center",
      marginLeft: "10px",
    },
    arrowIconsContainer: {
      display: "flex",
      flexDirection: "column",
      margin: "3px 0px 0px 10px",
      "& > img": {
        cursor: "pointer",
      },
      "& > img:first-child": {
        marginBottom: 5,
      },
    },
    tableFooer: {
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
    },
    teamCount: {
      width: "50%",
      margin: "0 auto",
    },
    paper: {
      borderRadius: "18px",
      background: "#FFF",
      padding: "0 10px",
      boxShadow: "1px 2px 10px 0px rgba(0, 0, 0, 0.07)",
    },
    teamNamesContainer: {
      "& > p": {
        color: "#1A1A1A",
        fontSize: "16px",
        fontWeight: 500,
        textTransform: "capitalize",
        margin: "10px 0",
      },
      "& > p:first-child": {
        color: "#12142B",
        fontSize: "20px",
        fontWeight: 600,
        margin: "10px 0",
      },
    },
    teamHeader: {
      color: "#12142B", 
      fontSize: "20px", 
      fontWeight: 600,
      margin: "10px 0"
    }
  })
);
