import React from 'react';
import {
    Button, Grid, InputAdornment,
    // Customizable Area Start
    Paper, Switch
    // Customizable Area End
    ,
    TextField, Typography
} from "@material-ui/core";
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import Checkbox from '@material-ui/core/Checkbox';
import Modal from '@material-ui/core/Modal';
import ManageContentFolderController from "./ManageContentFolderController.web";
import { Search } from "@material-ui/icons";
import SearchIcon from '@material-ui/icons/Search';
import Dialog from "@material-ui/core/Dialog";
import './style.css';
import './ContentManagement.css';
// Customizable Area Start
const Vector = require("../assets/Vector.png");
const video = require("../assets/videoFolder.png");
const videoIcon = require("../assets/videoIcon.png");
const arrow = require("../assets/VectorLessthen.png");
const Dots = require("../assets/GroupDots.png");
const playButton = require("../assets/Group190.png")
const delet = require("../assets/delete.png");
const dwonload = require("../assets/dwonload.png")
const profile = require("../assets/share.png")
const copylink = require("../assets/copylink.png")
const Rename = require("../assets/Rename.png")
const lock = require("../assets/lockFolder.png")
const trash = require("../assets/trash-can.png")
const close = require("../assets/imagenav_close.png")
const folder = require("../assets/Vector2.png");
// Customizable Area End

export default class ManageContentFolder extends ManageContentFolderController {
    //   constructor() {
    // super();
    // Customizable Area Start
    // Customizable Area End
    //   }

    // Customizable Area Start

    // Customizable Area End


    render() {
        let last = this.state.allVideoData?.meta;
        let rename = last && last[last.length - 1];
        console.log(last, 'ggggggggggggg');
        console.log(rename, 'ggggggggggggg');
        return (
            <div>
                <Grid container>
                    <Grid item lg={12} md={12} sm={12} xs={12}>
                        <Typography style={{ fontSize: '48px', fontWeight: 700, }}>Manage Content Library</Typography>
                    </Grid>
                    <Grid container spacing={2} style={{ marginTop: '25px' }} className="list">
                        <Grid item lg={5} className="search-outline">
                           
                            <TextField
                                fullWidth
                                id="search"
                                name="search"
                                variant="outlined"
                                placeholder="Search"
                                onChange={this.getSearchData}
                                value={this.state.search}
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                className="search"
                                InputProps={{
                                    startAdornment: (
                                        <InputAdornment position="start">
                                            <Search />
                                        </InputAdornment>
                                    ),
                                }}
                            />
                        </Grid>
                        <Grid item lg={1}></Grid>
                        <Grid item lg={2}><Button style={{ borderRadius: '12px', width: '186px', height: '56px', textTransform: 'capitalize' }} variant="outlined" onClick={() => this.tostSuccessfully()}>View Quiz Bank</Button></Grid>
                        <Grid item lg={2}><Button style={{ borderRadius: '12px', width: '212px', height: '56px', marginLeft: '-12px', textTransform: 'capitalize' }} variant="outlined" onClick={() => this.addNewVideo()} >Upload New Video</Button></Grid>
                        <Grid item lg={2}><Button style={{ borderRadius: '12px', width: '219px', height: '56px', textTransform: 'capitalize' }} variant="outlined" onClick={() => this.tostSuccessfully()}>Bulk Upload Videos</Button></Grid>
                    </Grid>

                    <Grid container style={{ marginTop: '25px' }}>
                        <Grid item lg={8}>
                            <div style={{ display: 'flex' }}>
                                <Typography style={{ fontSize: '20px', fontWeight: 400, color: '#979797', marginTop: '22px' }}>Folders</Typography>
                                {this.state.allVideoData?.meta?.reverse().map((data: any) => {

                                    return (
                                        <>
                                            <img style={{ width: '10px', height: '15px', marginTop: '28px', marginLeft: '15px', marginRight: '15px' }} src={arrow} />
                                            <Typography style={{ fontSize: '20px', fontWeight: 400, color: '#979797', marginTop: '22px', textTransform: 'capitalize' }}>{data}</Typography>
                                        </>
                                    )
                                })}
                                <img onClick={() => this.ModalhandleOpen(rename)} style={{ width: '4px', height: '13px', marginTop: '30px', marginLeft: '15px', cursor: 'pointer' }} src={Dots} />

                            </div>
                        </Grid>
                        <Grid item lg={2}>
                            <div style={{ display: 'flex', justifyContent: 'space-evenly', marginTop: '10px' }}>
                                <div style={{ display: 'flex', marginTop: '10px' }}>
                                    <img style={{ width: '20px', height: '12px', marginTop: '7px' }} src={Vector} />
                                    <Typography style={{ fontSize: '18px', fontWeight: 500, color: '#6C328B', paddingLeft: '15px' }}>List View</Typography>
                                </div>
                                <Switch className='Switch' checked={this.state.themeMode} onChange={() => this.setState({ themeMode: !this.state.themeMode })} />
                            </div>
                        </Grid>
                        <Grid item lg={2}><Button onClick={() => this.dialogHandleOpen()} style={{ borderRadius: '12px', width: '212px', height: '56px', backgroundColor: '#653889', textTransform: 'capitalize' }} variant="contained" color="primary" >Add New Folder</Button></Grid>
                    </Grid>
                    {this.state.themeMode ?
                        <></>
                        :
                        <Grid container spacing={2} style={{ marginTop: '40px',marginLeft:'15px' }}>
                            {this.state.allXpandLibararyData?.data?.map((data: any, index: any) => (
                                <>
                                    <Grid key={index.id} item lg={3} ><Paper onClick={() => this.handleNavigate(data.id)} style={{ width: '267px', height: '68px', backgroundColor: '#fff', border: '1px solid rgba(0, 0, 0, 0.25)', boxShadow: 'rgb(230 230 230 / 25%) -6px -6px 25px, rgb(230 232 236) 6px 6px 25px', borderRadius: '10px' }}>
                                        <Grid container style={{ padding: '15px' }}>
                                            <Grid item lg={2} style={{ margin: 'auto' }}>
                                                <>
                                                    <img src={folder} />
                                                </>
                                            </Grid>
                                            <Grid item lg={8} style={{ margin: 'auto' }}>
                                                <Typography style={{ cursor: 'Pointer', fontSize: '14px', fontWeight: 600, color: '#222836', marginLeft: '0px', marginTop: '0px', textTransform: 'capitalize' }}>{data.name}</Typography>
                                            </Grid>
                                            <Grid item lg={2} style={{ margin: 'auto' }}>
                                            </Grid>
                                        </Grid>
                                    </Paper></Grid>
                                </>
                            ))}

                        </Grid>
                    }
                    <Modal
                        open={this.state.dialogopen}
                        onClose={this.dialogHandleClose}
                        aria-labelledby="simple-modal-title"
                        aria-describedby="simple-modal-description"
                        style={{ backgroundColor: "rgba(0, 0, 0, -0.5)" }}
                    >
                        <div style={{
                            position: 'absolute', width: '890px', height: '225px', backgroundColor: '#fff',
                            margin: '12%', marginLeft: '21%', borderRadius: '15px', boxShadow: '-6px -6px 25px rgba(230, 230, 230, 0.25), 6px 6px 25px rgba(229, 229, 229, 0.25)'

                        }}>
                            <img src={close} onClick={this.dialogHandleClose} style={{ float: 'right', marginTop: '20px', marginRight: '20px' }} />
                            <Typography style={{ fontSize: '20px', fontWeight: 600, marginTop: '20px', marginLeft: '25px' }}>Create New Folder</Typography>
                            <Grid container spacing={4} style={{ marginLeft: '30px', marginTop: '25px' }}>
                                <Grid item lg={9}>
                                    <TextField className='inputField' style={{ borderRadius: '16px', width: '665px' }} error={this.state.createFolder.length > 15}
                                        helperText={this.state.createFolder.length > 15 ? '15 characters is required' : ''} value={this.state.createFolder} onChange={(event: any) => this.setState({ createFolder: event.target.value })} placeholder="Type the name of the folder" variant="outlined" />
                                </Grid>
                                <Grid item lg={3}>
                                    <Button onClick={() => this.createNewFolder()} style={{ borderRadius: '18px', width: '130px', height: '56px', backgroundColor: '#653889' }} variant="contained" color="primary" >Create</Button>
                                </Grid>

                            </Grid>
                        </div>
                    </Modal>

                    <Dialog
                        open={this.state.Modalopen}
                        onClose={this.ModalhandleClose}
                        aria-labelledby="simple-dialog-title"
                        className='Edit_dialog'
                        hideBackdrop
                    >
                        <div style={{ width: '225px', height: '200px', backgroundColor: '#fff',

                        }}>
                            <div style={{ display: 'flex', marginTop: '13px', marginLeft: '25px', cursor: 'pointer' }} onClick={() => this.tostSuccessfully()}>
                                <img src={profile} />
                                <Typography style={{ fontSize: '16px', fontWeight: 400, color: '#2B2E3C', paddingLeft: '15px' }}>Share</Typography>
                            </div>
                            <hr style={{ width: '200px', marginTop: '5px', border: '1px solid rgba(0, 0, 0, 0.1)' }} />
                            <div onClick={() => this.tostSuccessfully()} style={{ cursor: 'pointer', display: 'flex', marginTop: '0px', marginLeft: '25px' }}>
                                <img src={copylink} />
                                <Typography style={{ fontSize: '16px', fontWeight: 400, color: '#2B2E3C', paddingLeft: '15px' }}>Copy Link</Typography>
                            </div>
                            <hr style={{ width: '200px', marginTop: '5px', border: '1px solid rgba(0, 0, 0, 0.1)' }} />
                            <div onClick={() => this.RenameHandleOpen()} style={{ cursor: 'pointer', display: 'flex', marginTop: '0px', marginLeft: '25px' }}>
                                <img src={Rename} />
                                <Typography style={{ fontSize: '16px', fontWeight: 400, color: '#2B2E3C', paddingLeft: '15px' }}>Rename</Typography>
                            </div>
                            <hr style={{ width: '200px', marginTop: '5px', border: '1px solid rgba(0, 0, 0, 0.1)' }} />
                            <div onClick={() => this.FolderLockAPI()} style={{ cursor: 'pointer', display: 'flex', marginTop: '0px', marginLeft: '25px' }}>
                                <img src={lock} />
                                <Typography style={{ fontSize: '16px', fontWeight: 400, color: '#2B2E3C', paddingLeft: '15px' }}>Lock Folder</Typography>
                            </div>
                            <hr style={{ width: '200px', marginTop: '5px', border: '1px solid rgba(0, 0, 0, 0.1)' }} />
                            <div onClick={() => this.DeleteFolderAPI()} style={{ cursor: 'pointer', display: 'flex', marginTop: '0px', marginLeft: '25px' }}>
                                <img src={trash} />
                                <Typography style={{ fontSize: '16px', fontWeight: 400, color: '#2B2E3C', paddingLeft: '15px' }}>Delete Folder</Typography>
                            </div>
                        </div>
                    </Dialog>

                    <Modal
                        open={this.state.rename}
                        onClose={this.RenameHandleClose}
                        aria-labelledby="simple-modal-title"
                        aria-describedby="simple-modal-description"
                        style={{ backgroundColor: "rgba(0, 0, 0, -0.5)" }}
                    >
                        <div style={{
                            position: 'absolute', width: '890px', height: '225px', backgroundColor: '#fff',
                            margin: '12%', marginLeft: '21%', borderRadius: '15px', boxShadow: '-6px -6px 25px rgba(230, 230, 230, 0.25), 6px 6px 25px rgba(229, 229, 229, 0.25)'

                        }}>
                            <img src={close} onClick={this.RenameHandleClose} style={{ float: 'right', marginTop: '20px', marginRight: '20px' }} />
                            <Typography style={{ fontSize: '20px', fontWeight: 600, marginTop: '20px', marginLeft: '25px' }}>Create New Folder</Typography>
                            <Grid container spacing={4} style={{ marginLeft: '30px', marginTop: '25px' }}>
                                <Grid item lg={9}>
                                    <TextField className='inputField' style={{ borderRadius: '16px', width: '665px' }} value={this.state.editRename} onChange={(event: any) => this.setState({ editRename: event.target.value })} placeholder="Type the name of the folder" variant="outlined" />
                                </Grid>
                                <Grid item lg={3}>
                                    <Button onClick={() => this.FolderRenameAPI()} style={{ borderRadius: '18px', width: '130px', height: '56px', backgroundColor: '#653889' }} variant="contained" color="primary" >Update</Button>
                                </Grid>

                            </Grid>
                        </div>
                    </Modal>
                    {this.state.themeMode ?
                        <>
                            <Grid container style={{ marginTop: '40px', marginBottom: '25px' }}>
                                <Grid item lg={3}>
                                    <Typography style={{ fontSize: '16px', fontWeight: 600, textAlign: 'center' }}>Name</Typography>
                                </Grid>
                                <Grid item lg={2}>
                                    <Typography style={{ fontSize: '16px', fontWeight: 600, textAlign: 'center' }}>Created By</Typography>
                                </Grid>
                                <Grid item lg={2}>
                                    <Typography style={{ fontSize: '16px', fontWeight: 600, textAlign: 'center' }}>Total Assets</Typography>
                                </Grid>
                                <Grid item lg={5}>
                                    <Typography style={{ fontSize: '16px', fontWeight: 600, float: 'right', marginRight: '2%' }}>Delete Forever</Typography>
                                    <Typography style={{ fontSize: '16px', fontWeight: 600, float: 'right', marginRight: '3%' }}>Download</Typography>
                                </Grid>
                            </Grid>

                            {this.state.allVideoData?.videos?.data?.map((data: any, index: any) => {
                                return (
                                    <>
                                        <Paper style={{
                                            boxShadow: 'rgb(214, 214, 214) 0px 0px 7px',
                                            width: '100%',
                                            margin: 'auto',
                                            backgroundColor: '#fcfcfd',
                                            height: '70px',
                                            borderRadius: '20px',
                                            marginBottom: '15px',
                                        }}>
                                            <Grid container style={{ marginTop: '20px' }}>

                                                <Grid item lg={1} style={{ textAlign: 'center' }}>
                                                    <Checkbox
                                                        color="primary"
                                                        inputProps={{ 'aria-label': 'secondary checkbox' }}
                                                        style={{ marginTop: '-8px' }}
                                                    />
                                                </Grid>
                                                <Grid item lg={2}>
                                                    <Typography style={{ fontSize: '14px', fontWeight: 500, textAlign: 'center' }}> {data?.attributes?.link}</Typography>
                                                </Grid>
                                                <Grid item lg={2}>
                                                    <Typography style={{ fontSize: '14px', fontWeight: 500, textAlign: 'center' }}>{data?.attributes?.created_by}</Typography>
                                                </Grid>
                                                <Grid item lg={2}>
                                                    <Typography style={{ fontSize: '14px', fontWeight: 500, textAlign: 'center' }}>{data?.attributes?.video_size}</Typography>
                                                </Grid>
                                                <Grid item lg={5}>
                                                    <Grid container>
                                                        <Grid item lg={8}></Grid>
                                                        <Grid item lg={2}>
                                                            {data?.attributes?.video?.url ? <> <a href={data?.attributes?.video?.url} > <img src={dwonload} style={{ cursor: 'pointer' }} /></a> </> : <></>}
                                                        </Grid>
                                                        <Grid item lg={2}>
                                                            <img onClick={() => this.delete_folder(data?.attributes.id)} src={delet} style={{ cursor: 'pointer' }} />
                                                        </Grid>
                                                    </Grid>
                                                </Grid>

                                            </Grid>

                                        </Paper>
                                    </>
                                )
                            })}
                        </>
                        :

                        <Grid container spacing={2} style={{ marginTop: '40px' }}>
                            {this.state.allVideoData?.videos?.data?.map((data: any, index: any) => {

                                return (
                                    <>
                                        <Grid item lg={3}>
                                            <Card onClick={() => this.props.history.push(`/Editvideo/${data?.attributes?.id}`)} style={{ maxWidth: 285, boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.1)', cursor:'pointer' }}>
                                                <CardMedia
                                                    component="img"
                                                    alt="Contemplative Reptile"
                                                    height="200"
                                                    image={data?.attributes?.video_thumb === null ? video : data?.attributes?.video_thumb?.url}
                                                    title="Contemplative Reptile"
                                                />
                                                <img src={playButton} style={{ marginTop: '-16%', padding: '7%', position: 'absolute', display: 'flex' }} />
                                                <CardContent>
                                                    <Grid container>
                                                        <Grid item lg={3}>
                                                            <img src={videoIcon} />
                                                        </Grid>
                                                        <Grid item lg={9}>
                                                            <Typography style={{ fontSize: '16px', fontWeight: 400 }}>
                                                                {data?.attributes?.link}
                                                            </Typography>
                                                        </Grid>
                                                    </Grid>

                                                </CardContent>

                                            </Card>
                                        </Grid>
                                    </>
                                )
                            })}
                        </Grid>
                    }
                </Grid>
            </div>
        )

    }

}