import React from "react";
// Customizable Area Start
import { Box, Container, Grid, Typography, Divider, Button } from "@material-ui/core";
import {
  Reward, 
  Arrow, 
  Star, 
  Recieved, 
  InDesign, 
  Understand,
  achievement,
  Inprogress,
  Recived_yellow, 
  Redeemed,
  Staged,
  Gift
} from "./assets";

// Customizable Area End

import AchievementListingController, {
  Props,
} from "./AchievementListingController.web";
import Loader from "../../../components/src/Loader";
export default class AllRewardsDetails extends AchievementListingController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  async componentDidMount() {
    const currentUrl = window?.location?.href;
    const url = new URL(currentUrl);
    const id = url.pathname.split("/").pop();
    if (id) {
     await this.allRewardsDetails(id)
    }
    else {
      this.props.history.push("/AllRewardsListing")
    }
  }
  // Customizable Area End

  render() {
    return (
      // Customizable Area Start
      <>
        <div style={{ display: 'flex' }}>
          <div>
            <Box style={{ display: 'flex', margin: '0px 40px 20px 10px' }}>
              <Box style={{ marginTop: '8px' }}>
                <img src={Arrow}></img>
              </Box>
              <Typography
                variant='h5'
                style={{ fontWeight: 700, fontSize: '48px' }}
              >
                {'Reward'}
              </Typography>
            </Box>
            <Container maxWidth='lg' style={{ display: 'flex' }}>

              {/* Congratulations part */}
              {
                this.state?.allRewardsDetailsData?.attributes?.reward?.attributes?.status === "earned" ?
                  <Box
                    style={{
                      width: '100%',
                      background:
                        'linear-gradient(184.52deg, #FFFFFF 10.37%, #F0F3F6 96.34%)',
                      padding: '20px',
                      borderRadius: '18px',
                    }}
                  >
                    <Box style={{ display: 'flex', margin: '0px 80px 0px 80px' }}>
                      <Box sx={{ width: '100%' }}>
                        <Box
                          style={{ margin: '0px 120px' }}
                        >
                          <img src={Gift} alt={"icon"}></img>
                        </Box>
                        <Grid container spacing={3} alignItems='center'>
                          <Grid item xs={2}>
                            <Box>
                              <img width={100} height={100} src={this.state?.allRewardsDetailsData?.attributes?.reward?.attributes?.thumbnail_image?.url || Reward} alt={"icon"}></img>
                            </Box>
                          </Grid>
                          <Grid item xs={10}>
                            <Typography
                              style={{ fontSize: '24px', fontWeight: 700 }}
                            >
                              {this.state?.allRewardsDetailsData?.attributes?.reward?.attributes?.name} Reward Received
                            </Typography>
                            <Typography style={{ color: 'rgba(108, 50, 139, 1)', fontSize: '34px', fontWeight: 700, marginLeft: '45px' }}>
                              Congratulations !
                            </Typography>

                          </Grid>
                        </Grid>
                      </Box>
                    </Box>
                  </Box>

                  :

                  <Box
                    style={{
                      width: '100%',
                      background:
                        'linear-gradient(184.52deg, #FFFFFF 10.37%, #F0F3F6 96.34%)',
                      padding: '20px',
                      borderRadius: '18px',
                    }}
                  >
                    <Box
                      sx={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'space-between',
                      }}
                    >
                    </Box>
                    <Box style={{ display: 'flex', margin: '0px 22px' }}>
                      <Box sx={{ width: '100%' }}>
                        <Grid container spacing={3} alignItems='center'>
                          <Grid item xs={2}>
                            <Box  >
                              <img width={100} height={100} src={this.state.allRewardsDetailsData?.attributes?.reward?.attributes?.thumbnail_image?.url || Reward}></img>
                            </Box>
                          </Grid>
                          <Grid item xs={10}>
                            <Typography
                              style={{ fontSize: '24px', fontWeight: 700 }}
                            >
                              {this.state.allRewardsDetailsData?.attributes?.reward?.attributes?.name}
                            </Typography>
                          </Grid>
                        </Grid>
                      </Box>
                    </Box>
                  </Box>
              }
            </Container>
            <Container
              maxWidth='lg'
              style={{ display: 'flex', marginTop: '30px' }}
            >
              <Box sx={{ width: '100%' }}>
                <Grid container spacing={4}>
                  <Grid item xs={7}>
                    <Box>
                      <Typography
                        variant='h5'
                        style={{
                          marginBottom: '10px',
                          fontWeight: 700,
                          fontSize: '24px',
                        }}
                      >
                        Reward Description
                      </Typography>
                      <Typography
                        component='p'
                        style={{
                          fontSize: '16px',
                          fontWeight: 400,
                        }}
                      >
                        {this.state.allRewardsDetailsData?.attributes?.reward.attributes?.description}
                      </Typography>
                    </Box>

                  </Grid>
                  <Grid item xs={5}>
                    <Box
                      style={{
                        background:
                          'linear-gradient(184.52deg, #FFFFFF 10.37%, #F0F3F6 96.34%)',
                        borderRadius: '16px',
                        border: '1px solid rgba(230, 232, 236, 1)',
                        display: 'flex',
                        flexDirection: 'column',
                        height: '173px',
                        justifyContent: 'center',
                      }}
                    >
                      <Box style={{ margin: '30px' }}>
                        <Typography
                          variant='h1'
                          style={{ fontWeight: 700, fontSize: '16px' }}
                        >
                          Reward Status
                        </Typography>
                        <Typography
                          style={{
                            fontSize: '16px',
                            fontWeight: 400,
                            marginTop: '5px',
                          }}
                        >
                          <Box
                            style={{
                              display: 'flex',
                              alignItems: 'center',
                              justifyContent: 'space-between',
                            }}
                          >
                            <Box>
                              <img src={Understand} />
                              <Typography>Unstarted </Typography>
                            </Box>

                            <Box>
                              <img
                                src={this.state?.allRewardsDetailsData?.attributes?.reward?.attributes?.status === "progress" ? Inprogress : InDesign} />
                              <Typography>In Progress </Typography>
                            </Box>

                            <Box>
                              <img
                                src={this.state?.allRewardsDetailsData?.attributes?.reward?.attributes?.status === "earned" ? Recived_yellow : Recieved} />
                              <Typography>Received </Typography>
                            </Box>

                            <Box>
                              <img
                                src={this.state?.allRewardsDetailsData?.attributes?.reward?.attributes?.status=== "Redeemed" ? Redeemed : Star} />
                              <Typography>Redeemed </Typography>
                            </Box>
                          </Box>
                        </Typography>
                      </Box>
                    </Box>
                  </Grid>
                </Grid>
                <Divider style={{ marginTop: '20px' }} />

                <Box sx={{ marginTop: '30px' }}>
                  <Typography
                    variant='h5'
                    style={{
                      marginBottom: '10px',
                      fontWeight: 700,
                      fontSize: '24px',
                      display: 'flex',
                      alignItems: 'center',
                      gap: '60px',
                    }}
                  >
                    What it takes
                  </Typography>
                </Box>

                <Box
                  style={{
                    display: 'flex',
                    flexWrap: 'wrap',
                    justifyContent: 'space-between',
                    width: '100%',
                    marginTop: '10px',
                  }}
                >
                  {this.state?.allRewardsDetailsData?.attributes?.reward?.attributes?.content?.map(
                    (val: any, index: any) => (
                      <Box
                        key={index}
                        style={{
                          display: 'flex',
                          flexDirection: 'row',
                          width: '50%',
                          boxSizing: 'border-box',
                          padding: '5px', // Add spacing between items
                        }}
                      >
                        <Box style={{ display: 'flex', alignItems: 'center' }}>
                          <img
                            src={val?.attributes?.image?.url || achievement}
                            style={{ height: '40px', marginTop: '10px', marginRight: '3%' }}
                          />
                          <Typography component='p' style={{ width: '100%' }}>
                            {val?.attributes?.content}
                          </Typography>
                        </Box>
                      </Box>
                    )
                  )}
                </Box>
              </Box>
              {this.state.isloading ? <Loader loading={this.state.isloading} /> : null}
            </Container>
          </div>
        </div>
      </>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
// Customizable Area End
