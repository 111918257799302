import React, { Component } from 'react'
// Customizable Area Start


import {
  Box,
  Paper,
  Typography, Modal, Button, Checkbox
} from "@material-ui/core";
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardMedia from '@material-ui/core/CardMedia';
import LinearProgress from "@material-ui/core/LinearProgress";
import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';
import Select from '@material-ui/core/Select';
import Slider from "react-slick";
import './videostyles.css';
import FormControl from "@material-ui/core/FormControl";
import Grid from '@material-ui/core/Grid';
import MenuItem from '@material-ui/core/MenuItem';
import { withStyles, createTheme } from "@material-ui/core/styles";
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import { shapecopy } from './assets';
import {
  CategoryScale,
  Chart as chartjs,
  LinearScale,
  LineElement,
  PointElement
} from 'chart.js';
import { Line } from 'react-chartjs-2';

chartjs.register(
  LineElement,
  CategoryScale,
  LinearScale,
  PointElement,
)

const theme = createTheme({
  palette: {
    primary: {
      main: "#fff",
      contrastText: "#fff",
    },
  },
  typography: {
    h6: {
      fontWeight: 500,
    },
    subtitle1: {
      margin: "20px 0px",
    },
  },
});

import PersonalLibraryController, {
  Props
} from "./PersonalLibraryController.web";
const play = require("../assets/play.png")
const play1 = require("../assets/Object.png");
const notes_icon = require("../assets/Notes.png");
const Tick = require("../assets/Tick.png")
const Shape = require("../assets/Shape.jpg");
const close = require("../assets/imagenav_close.png");
const filter = require("../assets/PersonalFilter.png");
// Customizable Area End

export default class PersonalLibrary extends PersonalLibraryController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End


  render() {
    return (
      // Customizable Area Start
      <>
        <Grid container style={{ paddingTop: '0px', marginTop: '-15px' }}>
          <Grid item lg={12} md={12} sm={12} xs={12} style={{ padding: '0px' }}>
            <Typography variant="h3" style={{ display: 'contents' }} className="profile_header" >Personal Library</Typography>
            <Backdrop style={{ zIndex: theme.zIndex.drawer + 1, color: '#fff' }} open={this.state.loaderOpen} >
              <CircularProgress color="inherit" />
            </Backdrop>
            <Paper className="paper" style={{
              overflow: 'hidden', height: '384px', margin: 'auto', padding: '30px', borderRadius: '24px',
              background: 'linear-gradient(180deg, rgba(253,253,255,1) 0%, rgba(239,244,250,1) 100%)', maxWidth: '90vw', flex: 1,
              marginTop: '15px'
            }}>
              <Grid container>
                <Grid lg={9} md={9} sm={12} xs={12}>
                  <Typography style={{ fontWeight: 700, fontSize: '24px' }}>Learning Progress</Typography>
                  {this?.state?.show ? <LineChart2 state={this.state.chartData?.this_time_data} /> :
                    <LineChart state={this.state.chartData?.last_time_data} />}
                </Grid>
                <Grid lg={3} md={3} sm={12} xs={12}>
                  <Box style={{ display: 'flex', marginLeft: '-45%', marginTop: '-20px' }}>
                    <Typography style={{ fontWeight: 700, fontSize: '17px', float: 'none', marginTop: '17px', cursor: 'pointer' }}>
                      <FormControl style={{ color: "#3F526D", borderColor: "none", borderWidth: "0px", borderStyle: "solid", borderRadius: "0px", minWidth: "120px", justifyContent: "center" }}>
                        <Select
                          style={{ textTransform: 'capitalize', width: "220px", height: '45px', fontSize: "18px", backgroundColor: "#f5f7f6", paddingLeft: '30px', borderRadius: '10px', border: 'none' }}
                          value={this.state.selected}
                          onChange={this.handleChange}
                          disableUnderline
                          inputProps={{
                            name: "agent",
                            id: "age-simple"
                          }}
                        >
                          {this.state.values.map((value: any, index: any) => {
                            return <MenuItem onClick={() => this.handleClickChart(value)} value={value}>{value}</MenuItem>;
                          })}
                        </Select>
                      </FormControl></Typography>
                    <Typography style={{ paddingLeft: '15px', fontWeight: 700, fontSize: '17px', float: 'right', marginTop: '20px', cursor: 'pointer' }}>
                      <FormControl style={{ color: "#3F526D", borderColor: "none", borderWidth: "0px", borderStyle: "solid", borderRadius: "0px", minWidth: "120px", justifyContent: "center" }}>
                        <Select
                          style={{ textTransform: 'capitalize', width: "220px", height: '45px', fontSize: "18px", backgroundColor: "#f5f7f6", paddingLeft: '30px', borderRadius: '10px', border: 'none' }}
                          value={this.state.weekSelected}
                          onChange={this.weekhandleChange}
                          disableUnderline
                          inputProps={{
                            name: "agent",
                            id: "age-simple"
                          }}
                        >
                          {this.state.weekValue.map((value: any, index: any) => {
                            return <MenuItem onClick={() => this.handleClickWeek(value)} value={value}>{value}</MenuItem>;
                          })}
                        </Select>
                      </FormControl></Typography>
                  </Box>
                  <Typography style={{ fontWeight: 700, fontSize: '18px', marginTop: "35px", marginLeft: '40px' }}>Videos Watched</Typography>
                  <Grid container style={{ paddingTop: '0px' }}>
                    <Grid item lg={6} md={6} sm={12} xs={12} style={{ paddingLeft: '30px' }}>
                      <Box style={{ display: 'flex', marginTop: '30px', }}>
                        <Box style={{ width: '16px', height: '16px', backgroundColor: '#1b10e6', borderRadius: '10px' }}></Box>
                        <Typography style={{ fontWeight: 300, fontSize: '16px', paddingLeft: '15px', marginTop: '-5px' }}>This Week</Typography>
                      </Box>
                      <Typography style={{ fontWeight: 700, fontSize: '18px', marginTop: "21px", marginLeft: '15px' }}>{this.state.chartData?.meta?.this_count}</Typography>
                    </Grid>
                    <Grid item lg={6} md={6} sm={12} xs={12} style={{ paddingLeft: '30px' }}>
                      <Box style={{ display: 'flex', marginTop: '30px' }}>
                        <Box style={{ width: '16px', height: '16px', backgroundColor: '#1fd1a8', borderRadius: '10px' }}></Box>
                        <Typography style={{ fontWeight: 300, fontSize: '16px', paddingLeft: '15px', marginTop: '-5px' }}>Last Week</Typography>
                      </Box>
                      <Typography style={{ fontWeight: 700, fontSize: '18px', marginTop: "21px", marginLeft: '15px' }}>{this.state.chartData?.meta?.last_count}</Typography>
                    </Grid>
                    <Grid item lg={12} md={12} sm={12} xs={12}>
                      <Box style={{ display: 'flex', marginTop: '30px', marginLeft: '40px' }}>
                        <Box style={{ width: '16px', height: '16px', backgroundColor: '#1097e6', borderRadius: '10px' }}></Box>
                        <Typography style={{ fontWeight: 300, fontSize: '16px', paddingLeft: '15px', marginTop: '-5px' }}>Total Videos</Typography>
                      </Box>
                      <Typography style={{ fontWeight: 700, fontSize: '18px', marginTop: "5px", marginLeft: '75px' }}>{this.state.chartData?.meta?.total_count[0].count}</Typography>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Paper>
            <Paper className="paper" style={{
              overflow: 'hidden', padding: '30px',
              background: 'linear-gradient(180deg, rgba(253,253,255,1) 0%, rgba(239,244,250,1) 100%)',
              borderRadius: '24px',
              flex: 1, maxWidth: '90vw', height: '510px', margin: 'auto', marginTop: '25px'
            }}>
              <Box style={{ display: 'flex', justifyContent: 'space-between', fontFamily: "Montserrat" }}><h3 style={{ marginTop: '10px' }}>Courses In Progress</h3>
                <img onClick={() => {
                  return this.searchFilter()
                }} src={filter} style={{ marginLeft: '70%', height: '25px', cursor: 'pointer' }} />
                <Typography style={{ fontWeight: 700, fontSize: '17px', float: 'right', marginTop: '0px', cursor: 'pointer' }}
                  onClick={() => { return this.props.history.push('/course-inProgess', { data: this.state.InProgress.data }) }}>
                  View More<img src={Shape} style={{ paddingLeft: '13px' }} /></Typography>
              </Box>
              <>
                <SliderComponent style={this.state.style} last_trackVideo={this.last_trackVideo} handleNavigate={this.handleNavigate} data={this.state.InProgress} props={this.props} />
              </>
            </Paper>
            <Paper className="paper" style={{
              overflow: 'hidden', padding: '30px',
              background: 'linear-gradient(180deg, rgba(253,253,255,1) 0%, rgba(239,244,250,1) 100%)',
              borderRadius: '24px',
              flex: 1, maxWidth: '90vw', height: '510px', margin: 'auto', marginTop: '25px'
            }}>
              <Box style={{ display: 'flex', justifyContent: 'space-between', fontFamily: "Montserrat" }}><h3 style={{ marginTop: '10px' }}>Newly Added Courses</h3>
                <img onClick={() => {
                  return this.searchFilter1()
                }} src={filter} style={{ marginLeft: '68%', height: '25px', cursor: 'pointer' }} />
                <Typography style={{ fontWeight: 700, fontSize: '17px', float: 'right', marginTop: '0px', cursor: 'pointer' }}
                  onClick={() => { return this.props.history.push('/course-newlyAdded', { data: this.state.panding?.data }) }}>View More <img src={Shape} style={{ paddingLeft: '13px' }} /></Typography>
              </Box>
              <>
                <SliderComponent1 style={this.state.style} postplayButton={this.postplayButton} handleNavigate={this.handleNavigate} data1={this.state.panding} type='buttons' cardButtons={true} />
              </>
            </Paper>
            <Paper className="paper" style={{
              overflow: 'hidden', padding: '30px',
              background: 'linear-gradient(180deg, rgba(253,253,255,1) 0%, rgba(239,244,250,1) 100%)',
              borderRadius: '24px',
              flex: 1, maxWidth: '90vw', height: '510px', margin: 'auto', marginTop: '25px'
            }}>
              <Box style={{ display: 'flex', justifyContent: 'space-between', fontFamily: "Montserrat" }}><h3 style={{ marginTop: '10px' }}>Completed</h3>
                <img onClick={() => {
                  return this.searchFilter2()
                }} src={filter} style={{ marginLeft: '75%', height: '25px', cursor: 'pointer' }} />
                <Typography style={{ fontWeight: 700, fontSize: '17px', float: 'right', marginTop: '0px', cursor: 'pointer' }}
                  onClick={() => { return this.props.history.push('/course-completed', { data: this.state.Completed?.data }) }}>View More <img src={Shape} style={{ paddingLeft: '13px' }} /></Typography>
              </Box>
              <Box>
                <SliderComponent2 style={this.state.style} data={this.state.Completed} type='buttons' />
              </Box>
            </Paper>

            <Modal
              open={this.state.filter}
              onClose={this.handleClosefilter}
              aria-labelledby="simple-modal-title"
              aria-describedby="simple-modal-description"
              BackdropProps={{
                style: {
                  background: 'transparent',
                },
              }}
            >
              <Paper style={{ width: '204px', height: '330px', marginLeft: '75%', marginTop: '17%', borderRadius: '10px' }}>
                <Box style={{ padding: '15px' }}>
                  <Box style={{ display: 'flex', justifyContent: 'flex-start' }}>
                    <Typography style={{ fontSize: '16px', lineHeight: '18px', fontWeight: 700, color: '#1A1A1A' }}>Filter Courses</Typography>
                  </Box>
                  <Box style={{ display: 'flex', justifyContent: 'flex-start', marginTop: '11px', width: '100%' }}>
                    <Typography style={{ fontSize: '13px', lineHeight: '20px', fontWeight: 400, color: '#1A1A1A' }}>Show courses according to the following selected options</Typography>
                  </Box>
                  <Box style={{ width: '100%', display: 'flex', justifyContent: 'flex-start', marginTop: '11px', marginRight: '30%' }}>
                    <hr style={{ width: '100%', height: '1px', border: '1px solid #979797', opacity: 0.43 }} />
                  </Box>
                </Box>
                {this.state.libraryCheckedData3?.map((data: any, index: number) => (
                  <Box key={index} style={{ display: 'flex', justifyContent: 'space-between', padding: '10px 30px 0 10px' }}>
                    <Typography style={{ fontSize: '12px', lineHeight: '15px', fontWeight: 600, color: '#1A1A1A' }}>{data.text}</Typography>
                    <Checkbox
                      onChange={(event: any) => this.handleCheckboxChangeProgram(data.name, event)}
                      inputProps={{ 'aria-label': 'secondary checkbox' }}
                      style={{ marginTop: '-12px', marginRight: '-25px', color: '#6C328B' }}
                    />
                  </Box>
                ))}

                <Box style={{ padding: '15px' }}>
                  <Button
                    onClick={() => this.getIn_progressLibraryData()}
                    variant="outlined" style={{ marginBottom: '17px', color: '#6C328B', float: 'right', height: '31px', width: '100%', border: '1.8px solid #6C328B', fontWeight: 700, borderRadius: '10px' }}>
                    Apply
                  </Button>
                </Box>
              </Paper>
            </Modal>

            <Modal
              open={this.state.filter1}
              onClose={this.handleClosefilter1}
              aria-labelledby="simple-modal-title"
              aria-describedby="simple-modal-description"
              BackdropProps={{
                style: {
                  background: 'transparent',
                },
              }}
            >
              <Paper style={{ width: '204px', height: '330px', marginLeft: '75%', marginTop: '17%', borderRadius: '10px' }}>
                <Box style={{ padding: '15px' }}>
                  <Box style={{ display: 'flex', justifyContent: 'flex-start' }}>
                    <Typography style={{ fontSize: '16px', lineHeight: '18px', fontWeight: 700, color: '#1A1A1A' }}>Filter Courses</Typography>
                  </Box>
                  <Box style={{ display: 'flex', justifyContent: 'flex-start', marginTop: '11px', width: '100%' }}>
                    <Typography style={{ fontSize: '13px', lineHeight: '20px', fontWeight: 400, color: '#1A1A1A' }}>Show courses according to the following selected options</Typography>
                  </Box>
                  <Box style={{ width: '100%', display: 'flex', justifyContent: 'flex-start', marginTop: '11px', marginRight: '30%' }}>
                    <hr style={{ width: '100%', height: '1px', border: '1px solid #979797', opacity: 0.43 }} />
                  </Box>
                </Box>
                {this.state.libraryCheckedData3?.map((data: any, index: number) => (
                  <Box key={index} style={{ display: 'flex', justifyContent: 'space-between', padding: '10px 30px 0 10px' }}>
                    <Typography style={{ fontSize: '12px', lineHeight: '15px', fontWeight: 600, color: '#1A1A1A' }}>{data.text}</Typography>
                    <Checkbox
                      onChange={(event: any) => this.handleCheckboxChangeProgram(data.name, event)}
                      inputProps={{ 'aria-label': 'secondary checkbox' }}
                      style={{ marginTop: '-12px', marginRight: '-25px', color: '#6C328B' }}
                    />
                  </Box>
                ))}

                <Box style={{ padding: '15px' }}>
                  <Button
                    onClick={() => this.getPendingLibraryData()}
                    variant="outlined" style={{ marginBottom: '17px', color: '#6C328B', float: 'right', height: '31px', width: '100%', border: '1.8px solid #6C328B', fontWeight: 700, borderRadius: '10px' }}>
                    Apply
                  </Button>
                </Box>
              </Paper>
            </Modal>

            <Modal
              open={this.state.filter2}
              onClose={this.handleClosefilter2}
              aria-labelledby="simple-modal-title"
              aria-describedby="simple-modal-description"
              BackdropProps={{
                style: {
                  background: 'transparent',
                },
              }}
            >
              <Paper style={{ width: '204px', height: '330px', marginLeft: '75%', marginTop: '17%', borderRadius: '10px' }}>
                <Box style={{ padding: '15px' }}>
                  <Box style={{ display: 'flex', justifyContent: 'flex-start' }}>
                    <Typography style={{ fontSize: '16px', lineHeight: '18px', fontWeight: 700, color: '#1A1A1A' }}>Filter Courses</Typography>
                  </Box>
                  <Box style={{ display: 'flex', justifyContent: 'flex-start', marginTop: '11px', width: '100%' }}>
                    <Typography style={{ fontSize: '13px', lineHeight: '20px', fontWeight: 400, color: '#1A1A1A' }}>Show courses according to the following selected options</Typography>
                  </Box>
                  <Box style={{ width: '100%', display: 'flex', justifyContent: 'flex-start', marginTop: '11px', marginRight: '30%' }}>
                    <hr style={{ width: '100%', height: '1px', border: '1px solid #979797', opacity: 0.43 }} />
                  </Box>
                </Box>
                {this.state.libraryCheckedData3?.map((data: any, index: number) => (
                  <Box key={index} style={{ display: 'flex', justifyContent: 'space-between', padding: '10px 30px 0 10px' }}>
                    <Typography style={{ fontSize: '12px', lineHeight: '15px', fontWeight: 600, color: '#1A1A1A' }}>{data.text}</Typography>
                    <Checkbox
                      onChange={(event: any) => this.handleCheckboxChangeProgram(data.name, event)}
                      inputProps={{ 'aria-label': 'secondary checkbox' }}
                      style={{ marginTop: '-12px', marginRight: '-25px', color: '#6C328B' }}
                    />
                  </Box>
                ))}

                <Box style={{ padding: '15px' }}>
                  <Button
                    onClick={() => this.getCompletedLibraryData()}
                    variant="outlined" style={{ marginBottom: '17px', color: '#6C328B', float: 'right', height: '31px', width: '100%', border: '1.8px solid #6C328B', fontWeight: 700, borderRadius: '10px' }}>
                    Apply
                  </Button>
                </Box>
              </Paper>
            </Modal>

            <Box style={{
              position: 'fixed',
              cursor: 'pointer',
              top: this.state.toggleSideBar ? '15%' : '45%',
              right: this.state.toggleSideBar ? '24px' : 0,
              borderLeft: this.state.toggleSideBar ? '6px solid #504f53' : 'none',
              width: this.state.toggleSideBar ? '430px' : "55px",
              height: this.state.toggleSideBar ? '480px' : '75px',
              backgroundColor: this.state.toggleSideBar ? '#fff' : "#504f53",
              borderTopLeftRadius: "10px",
              borderBottomLeftRadius: "10px",
              boxShadow: 'rgb(237 237 237) -6px 16px 24px, rgb(237 237 237) 5px 4px 6px',
              borderTopRightRadius: this.state.toggleSideBar ? "10px" : 0,
              borderBottomRightRadius: this.state.toggleSideBar ? "10px" : 0,
            }}
              onClick={() => this.setState({ toggleSideBar: !this.state.toggleSideBar })}
            >
              <Box style={{
                fontFamily: "Montserrat",
                color: "white", fontWeight: "bold",
                textAlign: "center",
              }}>

                {!this.state.toggleSideBar ?
                  <img src={notes_icon}
                    style={{
                      marginTop: this.state.toggleSideBar ? '10px' : '20px',
                      fontFamily: 'sans-serif',
                      color: this.state.toggleSideBar ? '#000' : '#fff',
                      padding: this.state.toggleSideBar ? '1rem' : 0
                    }} />
                  : <></>}
                <Box style={{}}>
                  {!this.state.toggleSideBar ?
                    <></>
                    :
                    <Box>
                      <img onClick={() => this.setState({ toggleSideBar: !this.state.toggleSideBar })} style={{ float: 'right', marginTop: '-32px', marginRight: '20px' }} src={close} />
                    </Box>
                  }

                  <Box style={{ display: this.state.toggleSideBar ? 'block' : 'none', marginTop: this.state.toggleSideBar ? '40px' : 'none', overflowY: this.state.toggleSideBar ? 'scroll' : 'hidden', height: this.state.toggleSideBar ? '438px' : 'none' }}>
                    {this.state.notes?.data && this.state.notes?.data?.length > 0 ?
                      this.state.notes?.data?.map((e: any, i: any) => {


                        return (
                          <>
                            {e.attributes.note_type === "personal" ?
                              <Card key={i} style={{
                                color: '#000',
                                margin: '1rem',
                                padding: '5px',
                                display: 'flex',
                                borderRadius: '10px',
                                fontFamily: 'sans-serif',
                                borderLeft: e?.attributes.is_private ? '4px solid #fd8c40' : '4px solid #6e328b',
                                boxShadow: 'rgb(237 237 237) -15px 16px 24px, rgb(237 237 237) 5px 4px 6px'
                              }}>

                                <Grid container>
                                  <Grid item lg={8}>
                                    <Box style={{ marginTop: '-7px' }}>
                                      <h3 style={{ display: 'flex', marginLeft: '10px' }}>{e?.attributes.note_type ? `${e.attributes.title} - Personal` : `${e.attributes.title}Title - Public`}</h3>
                                      <p style={{ color: 'gray', marginLeft: '13px', marginTop: '-2px', float: 'left' }}>{e.attributes.created_at}</p>
                                    </Box>
                                  </Grid>
                                  <Grid item lg={4}>
                                    <Box style={{ color: 'gray', margin: '-3px auto' }}>
                                      <Box style={{
                                        padding: '10px',
                                        textAlign: 'right',
                                        position: 'relative',
                                        top: '0px',

                                      }}
                                        onClick={() => this.props.history.push('/CourseNotes', { id: e.id })}>
                                        <NavigateNextIcon />
                                      </Box>
                                      <p style={{ fontSize: '14px', marginTop: '7px', marginLeft: '-20px' }}>
                                        Sample Caption
                                      </p>
                                    </Box>
                                  </Grid>
                                </Grid>
                              </Card>
                              :
                              <Card style={{
                                color: '#000',
                                margin: '1rem',
                                padding: '10px',
                                display: 'flex',
                                borderRadius: '10px',
                                fontFamily: 'sans-serif',
                                borderLeft: i % 2 == 0 ? '4px solid #6e328b' : '4px solid #fd8c40',
                                boxShadow: 'rgb(237 237 237) -15px 16px 24px, rgb(237 237 237) 5px 4px 6px'
                              }}>
                                <Grid container>
                                  <Grid item lg={4}>
                                    <Box style={{ position: 'relative', marginLeft: '-25px' }} >
                                      <img
                                        src={shapecopy}
                                        style={{ height: '70px', width: '70px' }}
                                      />
                                      <Box style={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%,-50%)' }}>
                                      </Box>
                                    </Box>
                                  </Grid>
                                  <Grid item lg={6}>
                                    <Box style={{ color: 'gray', padding: '10px', display: 'flex', marginTop: '-10px', marginLeft: '-15' }}>

                                      <Typography variant="subtitle1" color="textSecondary" style={{ margin: '5px 0', fontWeight: "bold", color: "black", fontFamily: "sans-serif" }}>
                                        {e.attributes.title} <br /> <Typography style={{ display: 'flex', fontWeight: "bold", color: "black", fontFamily: "sans-serif" }}>{e?.attributes.note_type}</Typography>
                                      </Typography>
                                    </Box>
                                  </Grid>
                                  <Grid item lg={2}>
                                    <Box onClick={() => this.props.history.push('/CourseNotes', { id: e.id })}>
                                      <NavigateNextIcon />
                                    </Box>
                                  </Grid>

                                </Grid>
                              </Card>
                            }
                          </>
                        )
                      })
                      :
                      <h1 style={{ color: 'black' }}>No Notes available</h1>}
                  </Box>


                </Box>
              </Box>
            </Box>
          </Grid>
        </Grid >
      </>
      // Customizable Area End
    );
  }
}

// Customizable Area Start


const BorderLinearProgress = withStyles((theme) => ({
  root: {
    height: 10,
    borderRadius: 5,
    width: '60%',
    marginTop: '15px',
    marginLeft: '17px',
  },
  colorPrimary: {
    // backgroundColor: theme.palette.grey[theme.palette.type === 'light' ? 200 : 700],
    backgroundColor:
      theme.palette.grey[theme.palette.type === "light" ? 200 : 700]
  },
  bar: {
    borderRadius: 5,
    background: `linear-gradient(87deg, rgba(240,50,76,1) 0%, rgba(254,132,64,1) 100%)`
  },
}))(LinearProgress);


export const SliderComponent = ({ data, props, handleNavigate, last_trackVideo, style }: any) => {

  const HandleChange = (ele: any) => {
    if (ele?.attributes?.collection?.attributes?.expand_type === "Course") {
      handleNavigate("Course", data.id, `/course-detail/${ele?.attributes?.collection?.attributes?.expand_type}/${ele?.attributes?.collection.id}/${ele?.attributes?.collection?.attributes?.title.replace(/\s/g, '-')}`, { name: ele?.attributes?.collection })
    } else if (ele?.attributes?.collection?.attributes?.expand_type === "Program") {
      handleNavigate("Program", data.id, `/program-detail/${ele?.attributes?.collection?.attributes?.expand_type}/${ele?.attributes?.collection.id}/${ele?.attributes?.collection?.attributes?.title.replace(/\s/g, '-')}`, { name: ele?.attributes?.collection })
    }
  }


  let settings = {
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: 4.5,
    slidesToScroll: 1,
    initialSlide: 0,
    responsive: [
      {
        breakpoint: 1300,
        settings: {
          slidesToShow: 3.5,
          slidesToScroll: 1,
          infinite: false,
          dots: false
        }
      },
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          infinite: false,
          dots: false
        }
      },
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          infinite: false,
          dots: false
        }
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          initialSlide: 2,
          dots: false
        }
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          dots: false
        }
      }
    ]
  };

  const progressCards = data?.data;

  return (
    <>
      {progressCards?.length !== 0 ?
        <Slider className="slider1" {...settings}>

          {progressCards?.map((ele: any) => {
            return (
              <>
                <React.Fragment key={ele?.attributes?.collection?.id}>

                  <Box style={{ padding: "0 10px 0 10px", borderRadius: '25px 25px 0 0', marginTop: '20px' }} >
                    <CardMedia className="cardmedia"
                      style={{ height: "240px", width: '100%', borderRadius: '25px 25px 0 0' }}
                      // image={img2}
                      image={`${ele?.attributes?.collection?.attributes?.thumbnail_image?.url}`}
                    />
                    <Paper className="imagepaper" style={{ width: '100%', borderRadius: '25px', marginTop: '-20px', height: '168px' }}>
                      <Typography onClick={() => HandleChange(ele)} style={{ minHeight: 65, marginBottom: '-10px', padding: '17px', fontWeight: 600, fontSize: '14px', lineHeight: '20px', cursor: 'pointer' }}
                      >
                        {/* HTML 5 Expert course and CSS 3 with sample project */}
                        {ele?.attributes?.collection?.attributes?.title}
                      </Typography>
                      <Typography variant="body2" color="textSecondary" component="p"
                        style={{ margin: 'auto', marginTop: '-15px', padding: '0 17px', color: 'black' }}>
                        {ele?.attributes?.collection?.attributes?.created_by}
                      </Typography>
                      <Box>
                        <BorderLinearProgress variant="determinate" value={(ele?.attributes?.completed_video_count / ele?.attributes?.total_video_count) * 100} />
                        <Typography variant="body2" color="textSecondary" component="p"
                          style={{
                            margin: 'auto', fontSize: '12px', marginTop: '10px', padding: '0 17px',
                            color: 'rgba(254,132,64,1)'
                          }}>
                          {ele?.attributes?.completed_video_count}/{ele?.attributes?.total_video_count} Videos Completed
                        </Typography>
                        <Card style={{
                          width: '40px', height: '40px', borderRadius: '10px', float: 'right',
                          marginTop: '-50px', marginRight: '25px'
                        }}>
                          <CardActionArea>
                            <img src={play} onClick={() => last_trackVideo(ele)}
                              style={{ margin: 'auto', padding: '8px' }} />
                          </CardActionArea>
                        </Card>
                      </Box>
                    </Paper>
                  </Box>

                </React.Fragment>
              </>
            )
          })}

        </Slider>
        :
        <><h2 style={{ textAlign: 'center', marginTop: '10%' }}>No Courses available</h2></>}
    </>
  )
}


export const SliderComponent1 = ({ data1, handleNavigate, postplayButton, style }: any) => {
  const HandleChange = (ele: any) => {
    if (ele?.attributes?.collection?.attributes?.expand_type === "Course") {
      handleNavigate("Course", ele.id, `/course-detail/${ele?.attributes?.collection?.attributes?.expand_type}/${ele?.attributes?.collection?.id}/${ele?.attributes?.collection?.attributes?.title.replace(/\s/g, '-')}`, { name: ele?.attributes?.collection })
    } else if (ele?.attributes?.collection?.attributes?.expand_type === "Program") {
      handleNavigate("Program", ele.id, `/program-detail/${ele?.attributes?.collection?.attributes?.expand_type}/${ele?.attributes?.collection?.id}/${ele?.attributes?.collection?.attributes?.title.replace(/\s/g, '-')}`, { name: ele?.attributes?.collection })
    }
  }

  let settings = {
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: 4.5,
    slidesToScroll: 1,
    initialSlide: 0,
    responsive: [
      {
        breakpoint: 1300,
        settings: {
          slidesToShow: 3.5,
          slidesToScroll: 1,
          infinite: false,
          dots: false
        }
      },
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          infinite: false,
          dots: false
        }
      },
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          infinite: false,
          dots: false
        }
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          initialSlide: 2,
          dots: false
        }
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          dots: false
        }
      }
    ]
  };

  return (
    <>
      {data1.data?.length !== 0 ?
        <Slider className="slider1" {...settings}>
          {
            (data1?.data?.map((ele: any) => {
              return (
                <>
                  <Box style={{ padding: '0 10px 0 10px', borderRadius: '25px 25px 0 0', marginTop: '20px' }} >
                    <CardMedia
                      style={{ height: "240px", width: '100%', borderRadius: '25px 25px 0 0' }}
                      image={`${ele?.attributes?.collection?.attributes?.thumbnail_image?.url}`}
                    />
                    <Paper style={{ width: '100%', borderRadius: '25px', marginTop: '-20px', height: '168px' }}>
                      <Typography style={{ minHeight: 65, marginBottom: '-10px', padding: '17px', fontWeight: 600, fontSize: '14px', lineHeight: '20px', cursor: 'pointer' }}
                        onClick={() => HandleChange(ele)}
                      >
                        {ele?.attributes?.collection?.attributes?.title}
                      </Typography>
                      <Typography variant="body2" color="textSecondary" component="p"
                        style={{ margin: 'auto', marginBottom: '4px', padding: '0 25px', color: 'black' }}>
                        {ele?.attributes?.collection?.attributes?.created_by}
                      </Typography>

                      <Typography variant="body2" color="textSecondary" component="p"
                        style={{
                          margin: 'auto', fontSize: '12px', marginBottom: '6px', padding: '0 25px',
                          color: '#44444F'
                        }}>
                        Due in {ele?.attributes?.due_in_days} days
                      </Typography>
                      {ele?.attributes?.collection?.attributes?.is_paid
                        ?
                        <>
                          <Card style={{
                            width: '87px', height: '45px', borderRadius: '30px', float: 'right',
                            marginTop: '-2px', marginRight: '20px', background: '#1D1F4F'
                          }}>
                            <CardActionArea>
                            </CardActionArea>
                          </Card>
                        </>
                        :
                        <>
                          <Card style={{
                            width: '87px', height: '45px', borderRadius: '30px', float: 'right',
                            marginTop: '-2px', marginRight: '25px', background: '#6C328B'
                          }}>
                            <CardActionArea onClick={() => HandleChange(ele)}>
                              <img src={play1} style={{ margin: 'auto', padding: '8px', display: 'flex' }} />
                            </CardActionArea>
                          </Card>
                        </>}
                    </Paper>
                  </Box>
                </>
              )
            }))}
        </Slider>
        :
        <><h2 style={{ textAlign: 'center', marginTop: '10%' }}>No Courses available</h2></>}
    </>
  )
}



export const SliderComponent2 = ({ data, style }: any) => {
  let settings = {
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: 4.5,
    slidesToScroll: 1,
    initialSlide: 0,
    responsive: [
      {
        breakpoint: 1300,
        settings: {
          slidesToShow: 3.5,
          slidesToScroll: 1,
          infinite: false,
          dots: false
        }
      },
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          infinite: false,
          dots: false
        }
      },
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          infinite: false,
          dots: false
        }
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          initialSlide: 2,
          dots: false
        }
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          dots: false
        }
      }
    ]
  };

  return (
    <>
      {data?.data?.length !== 0 ?
        <Slider className="slider1" {...settings}>

          {

            (data?.data?.map((ele: any) => {
              return (
                <>
                  <Box style={{ padding: "0 10px 0 10px", borderRadius: '25px 25px 0 0', marginTop: '20px' }} >
                    <Box className="container">
                      <img src={`${ele?.attributes?.collection?.attributes?.thumbnail_image?.url}`} className="image" />
                      <Box className="overlay"><img src={Tick} style={{ margin: 'auto' }} /></Box>
                    </Box>

                    <Paper className="imagepaper" style={{ width: '100%', borderRadius: '25px', marginTop: '-20px', height: '168px' }}>
                      <Typography style={{ minHeight: 65, marginBottom: '-10px', padding: '17px', fontWeight: 600, fontSize: '14px', lineHeight: '20px', cursor: 'pointer' }}
                      >
                        {ele?.attributes?.collection?.attributes?.title}
                      </Typography>
                      <Typography variant="body2" color="textSecondary" component="p"
                        style={{ margin: 'auto', marginTop: '-15px', padding: '0 17px', color: 'black' }}>
                        {ele?.attributes?.collection?.attributes?.created_by}
                      </Typography>
                      <Box>
                        <Typography variant="body2" color="textSecondary" component="p"
                          style={{
                            margin: 'auto', fontSize: '12px', marginTop: '10px', padding: '0 17px',
                            color: 'rgba(254,132,64,1)'
                          }}>
                          {ele?.attributes?.completed_video_count}/{ele?.attributes?.total_video_count} Videos Completed
                        </Typography>
                      </Box>
                    </Paper>
                  </Box>
                </>
              )
            }))
          }
        </Slider>
        : <><h2 style={{ textAlign: 'center', marginTop: '10%' }}>No Courses available</h2></>}
    </>
  )
}



export const LineChart = ({ state }: any) => {
  const data = {
    labels: state?.map((data: any) => data.track_date),//['1 june', '2 june', '3 june', '4 june', '5 june', '6 june', '7 june', '8 june', '9 june', '10 june', '11 june', '12 june', '13 june'],
    datasets: [
      {
        id: 1,
        label: '',
        data: state?.map((data: any) => data.count),//[50, 40, 65, 55, 45, 80],
        backgroundColor: 'transparent',
        borderColor: '#1fd1a8',
        pointBorderColor: 'transparent',
        pointBorderWidth: 4,
        tension: 0.5,
      },
    ]
  }

  const options: any = {
    plugins: {
      legend: false
    },
    scales: {
      x: {
        grid: {
          display: false
        }
      },
      y: {
        min: 0,
        max: 25,
        ticks: {
          stepSize: 5,
          callback: (value: any) => value
        },
      }
    },
  };

  return (
    <>
      <Box style={{ width: '100%', height: '250px', marginTop: '20px' }}>
        <Line width="915px" height="250px" data={data} options={options}></Line>
      </Box>
    </>
  )
}



export const LineChart2 = ({ state }: any) => {
  const data = {
    labels: state?.map((data: any) => data.track_date),//['1 june', '2 june', '3 june', '4 june', '5 june', '6 june', '7 june'],
    datasets: [

      {
        id: 1,
        label: '',
        data: state?.map((data: any) => data.count),//[35, 45, 50, 56, 45, 70],
        backgroundColor: 'transparent',
        borderColor: '#0786ed',
        pointBorderColor: 'transparent',
        pointBorderWidth: 4,
        tension: 0.5,
      },

    ]
  }

  const options: any = {
    plugins: {
      legend: false
    },
    scales: {
      x: {
        grid: {
          display: false
        }
      },
      y: {
        min: 0,
        max: 25,
        ticks: {
          stepSize: 5,
          callback: (value: any) => value
        },
      }
    },
  };

  return (
    <>
      <Box style={{ width: '100%', height: '250px', marginTop: '20px' }}>
        <Line width="915px" height="250px" data={data} options={options}></Line>
      </Box>
    </>
  )
}


// Customizable Area End


