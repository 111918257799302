import React from "react";

// Customizable Area Start
import {
  Box,
  Typography,
  Grid,
  Paper,
  TextField,
  InputLabel,
  InputAdornment,
  Button,
  IconButton,
} from "@material-ui/core";
import { createTheme, styled } from "@material-ui/core/styles";
import { Formik} from "formik";
import * as yup from "yup";
import {Link} from 'react-router-dom'
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import Visibility from "@material-ui/icons/Visibility";
import { LoginImage } from "./assets";
import AlertBlock from "../../alert/src/ActionAlert.web";
import "./EmailAccountLogin.css";
// Customizable Area End

import EmailAccountLoginController, {
  Props,
} from "./EmailAccountLoginController.web";

const theme = createTheme({
  palette: {
    primary: {
      main: "#0000ff",
      contrastText: "#fff",
    },
  },
});
// Customizable Area Start
const Item = styled(Paper)(({ theme }) => ({
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: "end",
  color: theme.palette.text.secondary,
}));
// Customizable Area End

export default class EmailAccountLoginBlock extends EmailAccountLoginController {
  constructor(props: Props) {
    super(props);
  }
  // Customizable Area Start
  handleSubmit(user: any) {
    let payload = {
      data: {
        attributes: {
          email: JSON.parse(user).email,
          password: JSON.parse(user).password,
        },
      },
    };
    this.getLogin(payload);
  }
  // Customizable Area End

  render() {
    // Customizable Area Start
    return (
      // Required for all blocks
      <>
        <AlertBlock
          data={{
            show: this.state.alert.isOpen,
            close: () => {
              this.setState({ alert: { isOpen: false, msg: "", type: "" } });
            },
            msg: this.state.alert.msg,
            type: this.state.alert.type,
            vertical: "top",
            horizontal: "right",
          }}
        />
        <Grid
          container
          style={{ height: "100vh", width: "100vw" }}
          className="login"
        >
          <Grid
            xs={"auto"}
            sm={4}
            md={4}
            lg={3}
            item
            style={{
              backgroundImage: `url(${LoginImage})`,
              backgroundRepeat: "no-repeat",
              backgroundSize: "100% 100%",
              backgroundPosition: "center",
            }}
          />
          <Grid
            item
            xs={12}
            sm={8}
            md={8}
            lg={9}
            className="form-container"
            component={Paper}
            elevation={6}
            square
            style={{ overflowY: "scroll", height: "100vh" }}
          >
            <Box
              sx={{
                my: 8,
                mx: 4,
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <Grid item  className="login-route">
                <Item
                >
                  <Link to={'/signup'} className="text">
                    Don't have an account ? <span style={{paddingLeft:'20px'}}>Sign up for free</span>
                  </Link>
                </Item>
              </Grid>
              <Grid item xs={12} sm={12} lg={4} className="login-route mt-10">
                <Formik
                  initialValues={{
                    email: "",
                    showPassword: false,
                    password: "",
                  }}
                  onSubmit={async (values) => {
                    this.handleSubmit(JSON.stringify(values));
                  }}
                  validationSchema={yup.object().shape({
                    email: yup
                      .string()
                      .matches(/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,"Enter valid email")
                      .required("Email is required"),
                    password: yup
                      .string()
                      .required("Password is required")

                  })}
                >
                  {(props) => {
                    const {
                      values,
                      touched,
                      errors,
                      setFieldValue,
                      handleChange,
                      handleBlur,
                      handleSubmit,
                    } = props;
                    return (
                      <form className="regform" onSubmit={handleSubmit}>
                        <Grid item xs={8} className="typo" lg={8} spacing={10}>
                          <Typography component="h6" variant="h5">
                            Login
                          </Typography>
                        </Grid>
                        <Grid className="form user-form" container spacing={5}>
                          <Grid item md={12} xs={12} sm={12}>
                            <InputLabel>Email</InputLabel>
                            <TextField
                              id="email"
                              name="email"
                              value={values.email}
                              onChange={handleChange}
                              error={touched.email && Boolean(errors.email)}
                              helperText={touched.email && errors.email}
                              variant="outlined"
                              placeholder="Email address"
                              InputLabelProps={{
                                shrink: true,
                              }}
                              fullWidth
                            />
                          </Grid>
                        </Grid>
                        <Grid container spacing={5} className="user-form">
                          <Grid item md={12} xs={12} sm={12}>
                            <InputLabel htmlFor="filled-adornment-password">
                              Password
                            </InputLabel>
                            <TextField
                              id="filled-adornment-password"
                              type={values.showPassword ? "text" : "password"}
                              name="password"
                              InputLabelProps={{
                                shrink: true,
                              }}
                              placeholder="Password"
                              variant="outlined"
                              value={values.password}
                              error={
                                touched.password && Boolean(errors.password)
                              }
                              helperText={touched.password && errors.password}
                              onChange={handleChange}
                              onBlur={handleBlur}
                              fullWidth
                              InputProps={{
                                endAdornment: (
                                  <InputAdornment position="end">
                                    <IconButton
                                      aria-label="toggle password visibility"
                                      onClick={() => {
                                        setFieldValue(
                                          "showPassword",
                                          !values.showPassword
                                        );
                                      }}
                                      edge="end"
                                    >
                                      {values.showPassword ? (
                                        <VisibilityOff />
                                      ) : (
                                        <Visibility />
                                      )}
                                    </IconButton>
                                  </InputAdornment>
                                ),
                              }}
                            />
                          </Grid>
                        </Grid>
                        <Grid container spacing={5} style={{paddingBottom:"10px"}} className="user-form">
                          <Grid
                            item
                            md={12}
                            xs={12}
                            sm={12}
                            className="forgot-content"
                           
                          >
                            <Link  className="forgot-text" to={"/forgotpassword"}  >Forgot password ?</Link>
                          </Grid>
                        </Grid>
                        <Grid container spacing={5} className="user-form">
                          <Grid item md={12} xs={12} sm={12}>
                            <Button disabled={!(values.email && values.password)}
                             className={values.email && values.password ? "enable" : "disable"} type="submit" fullWidth>
                              Login
                            </Button>
                          </Grid>
                        </Grid>
                      </form>
                    );
                  }}
                </Formik>
              </Grid>
            </Box>
          </Grid>
        </Grid>
      </>
    );
    // Customizable Area End
  }
}
