Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.btnExampleTitle = "CLICK ME";
exports.validationApiContentType = "application/json";
exports.validationApiMethodType = "GET";
exports.exampleAPiEndPoint = "EXAMPLE_URL";
exports.exampleAPiMethod = "POST";
exports.exampleApiContentType = "application/json";
exports.textInputPlaceHolder = "Enter Text";
exports.labelTitleText = "Library2";
exports.labelBodyText = "Library2 Body";
exports.addCartItemApiEndPoint = "/bx_block_shopping_cart/cart";
exports.apiContentTypeAppJson = "application/json";
exports.getApiMethod = "GET";
exports.getObjectDetailsApiEndPoint = "bx_block_library2/user_objects";
// Customizable Area End