import React from 'react';
// Customizable Area Start
import { View, TouchableOpacity, Text, Dimensions, Image, StyleSheet } from 'react-native';
import Icon from "react-native-vector-icons/FontAwesome";
import EvilIcons from "react-native-vector-icons/EvilIcons";
import Feather from "react-native-vector-icons/Feather";
import Ionicons from "react-native-vector-icons/MaterialIcons";
import Scale from '../../../../components/src/Scale';

let screenWidth = Dimensions.get('window').width;
let screenHeight = Dimensions.get('window').height;

// Customizable Area End

interface Iprops {
  onBackPressed: () => void,
  onChannelFilterPressed: () => void,
  onLibraryFilterPressed: () => void,
  onMenuPressed: () => void,
  onSearchBarPressed: () => void,
  onCartIconPressed: () => void,
  title: any,
  isBack: any,
  counting: any,
  showCounting: any,
  showSearch?: boolean,
  showCart?: boolean,
  showLibraryFilter?: boolean,
  showDotMenu?: boolean,
  showFilterChannel?: boolean,
  libraryStateIcon?: boolean
}

export default function (props: Iprops) {
  // Customizable Area Start
  return (
    <View style={{ flexDirection: "row", alignItems: "center", paddingHorizontal: 20 }}>

      {
        props.isBack ?
          <TouchableOpacity style={{ width: screenWidth * 0.1 }} onPress={() => { props.onBackPressed() }}>
            <Text style={styles.backTitle}>Back</Text>
          </TouchableOpacity>
          :
          <View />
      }
      <Text style={styles.Title}>{props.title}</Text>

      {
        props.showSearch ? <TouchableOpacity onPress={() => { props.onSearchBarPressed() }} style={{ marginLeft: 17 }}>
          <Image
            resizeMode='cover'
            style={{ width: 22, height: 22, tintColor: "#6C328B" }}
            source={require('../../assets/search2.png')} />
        </TouchableOpacity> : <View />
      }

      {
        props.showCart ?
          <TouchableOpacity
            onPress={() => { props.onCartIconPressed() }}
            style={{ marginLeft: 15 }}>
            {props.showCart && props.showCounting && props.showCounting != '' ? <View style={{ height: Scale(13), width: Scale(13), backgroundColor: 'red', alignItems: "center", justifyContent: 'center', borderRadius: Scale(7), position: 'absolute', right: 0, top: 0, zIndex: 1 }}>

              <Text style={{ color: 'white', fontSize: Scale(9), fontFamily: 'Montserrat-Bold', }}>{props.counting}</Text>
            </View> : <></>
            }
            <Image
              style={{ width: 22, height: 23, tintColor: "#6C328B" }}
              source={require('../../assets/Cart2.png')} />
          </TouchableOpacity>

          : null
      }



      {
        props.showLibraryFilter ? <TouchableOpacity
          disabled={true}
          onPress={() => { props.onLibraryFilterPressed() }} style={{ marginLeft: 17 }}>
          <Image
            style={{ width: 20, height: 20, tintColor: !props.libraryStateIcon ? '#F6655F' : "#151515", }}
            source={require('../../assets/Sort.png')} />
        </TouchableOpacity> : <View />
      }

      {
        props.showDotMenu ? <TouchableOpacity
          disabled={true}
          onPress={() => { props.onMenuPressed() }} style={{ marginLeft: 17 }}>
          <Image
            resizeMode={"contain"}
            style={{ width: 20, height: 28, tintColor: '#6C328B' }}
            source={require('../../assets/threedot.png')} />
        </TouchableOpacity> : <View />
      }

      {
        props.showFilterChannel ? <TouchableOpacity style={{ marginLeft: 17 }} onPress={() => { props.onChannelFilterPressed() }}>
          <Image
            style={{ width: 20, height: 20, tintColor: "#151515" }}
            source={require('../../assets/Filter_inactive.png')} />
        </TouchableOpacity> : <View />
      }
    </View>
  );
  // Customizable Area Start
}

const styles = StyleSheet.create({
  backTitle: {
    fontSize: 14,
    fontFamily: 'Montserrat-Bold',
    color: 'grey'
  },

  Title: {
    backgroundColor: "#00000000",
    paddingHorizontal: 18,
    paddingVertical: 18,
    color: "black",
    flex: 1,
    flexDirection: "row",
    fontSize: 24,
    fontFamily: 'Montserrat-Bold',
  },


});