export const imgBell = require("../assets/bell.png");
export const ONSWITCH_ICON = require("../assets/enabled.png");
export const OFFSWITCH_ICON = require("../assets/offswitch.png");
export const imgDemo = require("../assets/profile.webp");
export const notesIcon = require("../assets/notes.svg");
export const playIcon = require("../assets/play.svg");
export const CheckedIcon = require("../assets/checked_box.png");
export const UncheckedIcon = require("../assets/unchecked_box.png");
export const NotifyIcon = require("../assets/notify_icon.png");
export const RewardIcon = require("../assets/reward.svg");
export const AchieventIcon = require("../assets/achievement.svg");
