// Customizable Area Start
import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import { toast } from "react-toastify";
import { apiCall } from "../../../framework/src/Utilities";
import { SelectedSubsCartItem } from "../../shoppingcart/src/ShoopingController.web";

const configJSON = require("./config.js");

export interface Props {
  classes?: any;
  cartType: string;
  payableAmount: number;
  onSuccess: (msg: string, lastSubscriptionOrder?: any) => void;
  onFailure: (msg: string) => void;
  selectedSubsCartItem: SelectedSubsCartItem;
}

interface S {
  savedCards: any;
  paymentMethod: string;
  transitionError: string;
  publishableKey: string;
  clientSecret: string;
  isLoading: boolean;
  checkoutUrl: string;
  cardId: string;
  confirmedCardDeletion: boolean;
}

interface SS {
  id: any;
}

export default class PaymentsControllerWeb extends BlockComponent<Props, S, SS> {
  apiGetPublishableKeyCallId: string = "";
  apiGetSavedCardsCallId: string = "";
  apiHandleDebitCreditCardPaymentCallId: string = "";
  apiHandleDebitCreditCardPaymentForSubsCallId: string = "";
  apiHandleInitiateStipePaymentCallId: string = "";
  apiHandleRemoveSavedCardCallId: string = "";

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionSaveMessage),
      getName(MessageEnum.SessionResponseMessage),
    ];

    this.state = {
      savedCards: [],
      paymentMethod: "",
      transitionError: "",
      publishableKey: "",
      clientSecret: "",
      isLoading: false,
      checkoutUrl: "",
      cardId: "",
      confirmedCardDeletion: false,
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async componentDidMount(): Promise<void> {
    this.getSavedCards();
    this.getPublishableKey();
  }

  componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<S>, snapshot?: SS | undefined): void {
    if (
      this.state.checkoutUrl &&
      prevState.checkoutUrl !== this.state.checkoutUrl
    ) {
      window.location.href = this.state.checkoutUrl;
    }
    if (
      !prevState.confirmedCardDeletion &&
      this.state.confirmedCardDeletion
    ) {
      this.handleRemoveSavedCard();
    }
  }

  async receive(from: string, message: Message) {
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );
      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      switch (apiRequestCallId) {
        case this.apiGetPublishableKeyCallId:
          this.handleGetPublishableKeyResponse(responseJson);
          break;
        case this.apiGetSavedCardsCallId:
          this.handleGetSavedCardsResponse(responseJson);
          break;
        case this.apiHandleDebitCreditCardPaymentCallId:
          this.handleDebitCreditCardPaymentResponse(responseJson);
          break;
        case this.apiHandleDebitCreditCardPaymentForSubsCallId:
          this.handleDebitCreditCardPaymentResponseForSusbscription(responseJson);
          break;
        case this.apiHandleInitiateStipePaymentCallId:
          this.handleInitiateStripePaymentResponse(responseJson);
          break;
        case this.apiHandleRemoveSavedCardCallId:
          this.handleRemoveSavedCardResponse(responseJson);
          break;
        default:
          break;
      }
    }
  }

  // Get publishable key & client secret
  getPublishableKey = async () => {
    this.apiGetPublishableKeyCallId = await apiCall({
      contentType: configJSON.exampleApiContentType,
      method: configJSON.postApiMethodType,
      endPoint: configJSON.createOrder,
    });
  }

  // Handle get publishable key response
  handleGetPublishableKeyResponse = (response: any) => {
    if (!response?.data) {
      console.error("create_order api called w/o any amount");
      return;
    }
    this.setState({
      publishableKey: response.data.publishableKey,
      clientSecret: response.data.paymentIntent,
    })
  }

  // Change payment method
  handlePaymentMethodChange = (e: any) => {
    if (this.props.cartType === "subscription" && e.target.value === "stripe") {
      // Handle stripe payment for subscription
      this.handleInitiateStipePayment();
    } else {
      this.setState({
        paymentMethod: e.target.value,
      });
    }
  }

  // Get saved card data
  getSavedCards = async () => {
    this.setState({ isLoading: true });
    this.apiGetSavedCardsCallId = await apiCall({
      contentType: configJSON.exampleApiContentType,
      method: configJSON.getApiMethodType,
      endPoint: configJSON.paymentApi,
    });
  }

  // Handle saved cards response
  handleGetSavedCardsResponse = (response: any) => {
    this.setState({ isLoading: false });
    if (response?.message) {
      // No cards
      return;
    }
    if (response?.data) {
      this.setState({
        savedCards: response.data,
      });
    } else {
      toast.error("Something went wrong");
    }
  }

  // Handle debit/credit card payment
  handleDebitCreditCardPayment = async (card: any) => {
    if (this.props.cartType === "subscription") {
      this.handleDebitCreditCardPaymentForSubscription(card);
      return;
    }
    const data = new FormData();
    if (card?.card_detail) {
      // Payment via saved card
      data.append("card_id", card.card_detail.id);
      data.append("use_existing_card", "true");
    } else {
      data.append("save_card", card.rememberCard);
      data.append("card_number", card.cardNumber);
      data.append("exp_month", card.MM);
      data.append("exp_year", card.YYYY);
      data.append("cvc", card.CVV);
      data.append("name", card.cardName);
    }

    this.setState({ isLoading: true });
    this.apiHandleDebitCreditCardPaymentCallId = await apiCall({
      method: configJSON.postApiMethodType,
      endPoint: configJSON.paymentApi,
      payload: data,
    });
  }
  
  // Handle debit/credit card payment response
  handleDebitCreditCardPaymentResponse = (response: any) => {
    this.setState({ isLoading: false });
    if (!response) return;
    if (response?.success) {
      this.props.onSuccess("Congratulations!");
    } else {
      this.props.onFailure(response.errors);
    }
  }

  // Handle debit/credit cart payment for subs
  handleDebitCreditCardPaymentForSubscription = async (card: any) => {
    let data = {};
    if (card?.card_detail) {
      // Payment via saved card
      data = {
        ...data,
        use_existing_card: true,
        card_id: card.card_detail.id,
      }
    }
    const { purchasableId, purchasableType } = this.props.selectedSubsCartItem;
    data = {
      ...data,
      courseable_id: purchasableId,
      courseable_type: purchasableType,
      save_card: card.rememberCard,
      card_number: card.cardNumber,
      exp_month: card.MM,
      exp_year: card.YYYY,
      cvc: card.CVV,
      name: card.cardName,
    }
    this.setState({ isLoading: true });
    this.apiHandleDebitCreditCardPaymentForSubsCallId = await apiCall({
      contentType: configJSON.exampleApiContentType,
      method: configJSON.postApiMethodType,
      endPoint: configJSON.subsDebitCreditApi,
      payload: JSON.stringify(data),
    });
  }

  // Handle debit/credit card payment response
  handleDebitCreditCardPaymentResponseForSusbscription = (response: any) => {
    this.setState({ isLoading: false });
    if (!response) return;
    if (response?.success) {
      this.props.onSuccess("Congratulations!", response?.product_detail);
    } else {
      this.props.onFailure(response.errors);
    }
  }

  // Handle stripe payment for subscription
  handleInitiateStipePayment = async () => {
    const { purchasableId, purchasableType } = this.props.selectedSubsCartItem;

    const data = new FormData();
    data.append("courseable_id", purchasableId.toString());
    data.append("courseable_type", purchasableType);
    data.append("web", "true");

    this.setState({ isLoading: true });
    this.apiHandleInitiateStipePaymentCallId = await apiCall({
      method: configJSON.postApiMethodType,
      endPoint: configJSON.initiateStipePayment,
      payload: data,
    });
  }

  // Hanlde initiate stripe response 
  handleInitiateStripePaymentResponse = (response: any) => {
    if (!response?.success) {
      this.setState({ isLoading: false });
      toast.error("Something went wrong. Please try again");
    } else if (response?.success && response?.data?.url) {
      this.setState({
        isLoading: false,
        checkoutUrl: response.data.url,
      });
    }
  }

  // Select card to be deleted
  handleCardDelete = (cardId: string, confirmedCardDeletion?: boolean) => {
    this.setState({ 
      cardId,
      confirmedCardDeletion: !!confirmedCardDeletion,
    });
  }

  // Remove saved card
  handleRemoveSavedCard = async () => {
    this.setState({ isLoading: true });
    const data = { id: this.state.cardId };
    this.apiHandleRemoveSavedCardCallId = await apiCall({
      contentType: configJSON.exampleApiContentType,
      endPoint: configJSON.removeCard,
      method: configJSON.postApiMethodType,
      payload: JSON.stringify(data),
    });
  }

  // Handle removal of saved cards response
  handleRemoveSavedCardResponse = (response: {success?: boolean, message?: string}) => {
    if (!response.success) {
      const message = response?.message ?? "Something went wrong";
      toast.info(message)
      this.setState({
        isLoading: false,
        cardId: "",
        confirmedCardDeletion: false,
      });
      return;
    }
    toast.success("Card removed successfully");
    const updatedSavedCards = this.state.savedCards.filter(
      (card: any) => card.card_detail.id !== this.state.cardId
    );
    this.setState({
      isLoading: false,
      cardId: "",
      savedCards: updatedSavedCards,
      confirmedCardDeletion: false,
    });
  }

}
// Customizable Area End