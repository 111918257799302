import React from "react";
import {
  Box, Grid
  // Customizable Area End
  ,
  // Customizable Area Start
  Paper, TextField, Typography
} from "@material-ui/core";
import LinearProgress from "@material-ui/core/LinearProgress";
import { withStyles } from '@material-ui/core/styles';
import SearchIcon from '@material-ui/icons/Search';
import CompletedController from './CompletedController.web';
import './videostyles.css';
// Customizable Area Start
const img4 = require("../assets/slider-1.png");
const img2 = require("../assets/courseimage4.png");
const play = require("../assets/play.png")
const img3 = require("../assets/courseimage5.png")
const group = require("../assets/Group 8.png")
const shop = require("../assets/image_Bitmap Copy 4.png")
const Tick = require("../assets/Tick.png")

// Customizable Area End
const BorderLinearProgress = withStyles((theme) => ({
  root: {
    height: 10,
    borderRadius: 5,
    width: '60%',
    marginTop: '15px',
    marginLeft: '17px',
  },
  colorPrimary: {
    // backgroundColor: theme.palette.grey[theme.palette.type === 'light' ? 200 : 700],
    backgroundColor:
      theme.palette.grey[theme.palette.type === "light" ? 200 : 700]
  },
  bar: {
    borderRadius: 5,
    background: `linear-gradient(87deg, rgba(240,50,76,1) 0%, rgba(254,132,64,1) 100%)`
  },
}))(LinearProgress);

export default class Completed extends CompletedController {
  //   constructor() {
  // super();
  // Customizable Area Start
  // Customizable Area End
  //   }

  // Customizable Area Start

  // Customizable Area End

  _onFocus = () => {
    this.props.history.push('/Search')
  }
  render() {
    return (
      <>

        <Grid container style={{ paddingTop: "0px" }}>
          <Grid item lg={12} md={12} sm={12} xs={12}>
            <Typography variant="h3" className="profile_header" style={{ display: 'contents' }}>Completed</Typography>
            <Grid container style={{ marginTop: '30px' }}>
              <Grid item lg={12} md={12} sm={12} xs={12}>
                <TextField onFocus={this._onFocus} className="inputRounded" style={{ width: '373px', borderRadius: '12px' }}
                  // onChange={this.onChangeHandle}
                  //  value={this.state.search}
                  placeholder=" Search" variant="outlined" />
                <div style={{ marginTop: '-38px', marginLeft: '8px' }}>
                  <SearchIcon />
                </div>
              </Grid>
            </Grid>

            {/* <Grid item lg={12} md={12} sm={12} xs={12}> */}
            <Grid container style={{ marginTop: "25px" }}>
              {this.state.allInProgressData.length !== 0 ?
                this.state.allInProgressData?.map((ele: any, index: any) => {
                  //   console.log(ele)
                  return (
                    <Grid item lg={3} md={4} sm={6} xs={12} key={index}>
                      <>
                        <Box style={{ padding: "0 10px 0 10px", borderRadius: '25px 25px 0 0', marginTop: '20px' }} >
                          {/* <div className='overlay'></div> */}
                          <div className="container">
                            <img src={`${ele?.attributes?.collection?.attributes?.thumbnail_image?.url}`} className="image" />
                            <div className="overlay"><img src={Tick} style={{ margin: 'auto' }} /></div>
                          </div>

                          <Paper className="imagepaper" style={{ width: '100%', borderRadius: '25px', marginTop: '-20px', height: '168px' }}>
                            <Typography style={{ minHeight: 65, marginBottom: '-10px', padding: '17px', fontWeight: 600, fontSize: '14px', lineHeight: '20px', cursor: 'pointer' }}
                            >
                              {ele?.attributes?.collection?.attributes?.title}
                            </Typography>
                            <Typography variant="body2" color="textSecondary" component="p"
                              style={{ margin: 'auto', marginTop: '-15px', padding: '0 17px', color: 'black' }}>
                              {ele?.attributes?.collection?.attributes?.created_by}
                            </Typography>
                            <Box>
                              <Typography variant="body2" color="textSecondary" component="p"
                                style={{
                                  margin: 'auto', fontSize: '12px', marginTop: '10px', padding: '0 17px',
                                  color: 'rgba(254,132,64,1)'
                                }}>
                                {ele?.attributes?.completed_video_count}/{ele?.attributes?.total_video_count} Videos Completed
                              </Typography>
                            </Box>
                          </Paper>
                        </Box>
                      </>
                    </Grid>
                  )
                }) :
                <><h2 style={{ textAlign: 'center', marginTop: '10%' }}>No Courses available</h2></>}
            </Grid>

          </Grid>

        </Grid>

      </>



    )

  }

}
