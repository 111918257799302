// Customizable Area Start

import React, { Component } from 'react'
import MuiAlert, { AlertProps } from '@material-ui/lab/Alert';
import Snackbar from '@material-ui/core/Snackbar';

interface Props{
    data:any,

}
export default class ActionAlert extends Component<Props,{}> {
    constructor(props:any) {
        super(props)
    }
  render() {
    return (
        <Snackbar id="alert-box"  open={this.props?.data?.show} autoHideDuration={2000} onClose={this.props?.data?.close} anchorOrigin={{ vertical:this.props?.data?.vertical, horizontal:this.props?.data?.horizontal }}>
          <MuiAlert elevation={6} variant="filled" onClose={this.props?.data?.close} severity={this.props?.data?.type} > 
                                                                                 {/* {/ severity = "success" || "warning" || "error" || "info" /} */}
            {this.props?.data?.msg}
          </MuiAlert>
        </Snackbar>
      );
  }
}

// Customizable Area End

