import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { imgPasswordInVisible, imgPasswordVisible, slider1, slider2, slider3 } from "./assets";
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  history:any;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  txtInputValue: string;
  txtSavedValue: string;
  enableField: boolean;
  // Customizable Area Start
  anchorEl: any;
  grapValues: any;
  InProgress:any;
  panding:any;
  Completed:any;
  toggleSideBar: any;
  values: any,
  selected: any,
  chartData:any,
  show:boolean,
  weekValue:any;
  weekSelected:any;
  paramTitle:any;
  style:boolean,
  notes:any;
  filter:boolean;
  filter1:boolean;
  filter2:boolean;
  libraryCheckedData3:any;
  checked:any;
  loaderOpen:boolean;
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class PersonalLibraryController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  GetIn_progressLibraryData: any;
  GetPendingLibraryData:any;
  GetCompletedLibraryData:any;
  GetLibraryChartData:any;
  Last_VideoTrackData:any;
  GetAllNotesdata:any;
  libraryCheckedDataSort:any;
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.RestAPIResponceMessage)
      // Customizable Area Start
      // Customizable Area End
    ];

    this.state = {
      txtInputValue: "",
      txtSavedValue: "A",
      enableField: false,
      // Customizable Area Start
      anchorEl: '',
      loaderOpen:false,
      grapValues: 'Week',
      values: ["week","month","year"],
      selected: "week",
      weekValue: ["This Week", "Last Week"],
      weekSelected: "This Week",
      toggleSideBar: false,
      InProgress: {},
      panding: {},
      Completed: {},
      show:true,
      chartData:{},
      paramTitle:"",
      notes:{},
      style:true,
      filter:false,
      filter1:false,
      filter2:false,
      checked: [],
      libraryCheckedData3: [
        {
          id: 0,
          text: 'Due this week',
          name: 'weekly_due',
          checked1: true,
        },
        {
          id: 1,
          text: 'Due this month',
          name: 'monthly_due',
          checked3: true,
        },
        {
          id: 2,
          text: 'Total Dues',
          name: 'all_due',
          checked4: true,
        },

      ],
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
  }

  // @ts-ignore
  componentDidMount() {

    
     
    this.getIn_progressLibraryData();
    this.getPendingLibraryData();
    this.getCompletedLibraryData();
    this.getAllNotesData();
    window.scrollTo(0, 0)
  }

  async receive(from: string, message: Message) {
    runEngine.debugLog("Message Recived", message);

    if (message.id === getName(MessageEnum.AccoutLoginSuccess)) {
      let value = message.getData(getName(MessageEnum.AuthTokenDataMessage));

      this.showAlert(
        "Change Value",
        "From: " + this.state.txtSavedValue + " To: " + value
      );

      this.setState({ txtSavedValue: value });
    }

    // Customizable Area Start



    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      let responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

     
        

      if (apiRequestCallId && responseJson) {
        if (apiRequestCallId === this.GetPendingLibraryData) {
          this.setState({ panding: responseJson, loaderOpen: false })
          
        }
        if(apiRequestCallId === this.GetCompletedLibraryData) {
          this.setState({ Completed: responseJson, loaderOpen: false })
         
        }
        if(apiRequestCallId === this.GetIn_progressLibraryData) {
          this.setState({ InProgress: responseJson, loaderOpen: false })
         
        }
        if (apiRequestCallId === this.GetLibraryChartData) {
          this.setState({ chartData: responseJson })
        }
        if (apiRequestCallId === this.Last_VideoTrackData) {
            this.props.history.push(`/title/${responseJson.data?.attributes?.course_video_id}${this.state.paramTitle.replace(/\s/g, '-')}`,{program:responseJson.data?.attributes?.program_id})
       }
       if (apiRequestCallId === this.GetAllNotesdata) {
        this.setState({ notes: responseJson })
        
      }
       
      }
    }

    // Customizable Area End
  }

  txtInputWebProps = {
    onChangeText: (text: string) => {
      this.setState({ txtInputValue: text });
    },
    secureTextEntry: false,
  };

  txtInputMobileProps = {
    ...this.txtInputWebProps,
    autoCompleteType: "email",
    keyboardType: "email-address",
  };

  txtInputProps = this.isPlatformWeb()
    ? this.txtInputWebProps
    : this.txtInputMobileProps;

  btnShowHideProps = {
    onPress: () => {
      this.setState({ enableField: !this.state.enableField });
      this.txtInputProps.secureTextEntry = !this.state.enableField;
      this.btnShowHideImageProps.source = this.txtInputProps.secureTextEntry
        ? imgPasswordVisible
        : imgPasswordInVisible;
    },
  };

  btnShowHideImageProps = {
    source: this.txtInputProps.secureTextEntry
      ? imgPasswordVisible
      : imgPasswordInVisible,
  };

  btnExampleProps = {
    onPress: () => this.doButtonPressed(),
  };

  doButtonPressed() {
    let msg = new Message(getName(MessageEnum.AccoutLoginSuccess));
    msg.addData(
      getName(MessageEnum.AuthTokenDataMessage),
      this.state.txtInputValue
    );
    this.send(msg);
  }

  // web events
  setInputValue = (text: string) => {
    this.setState({ txtInputValue: text });
  };

  setEnableField = () => {
    this.setState({ enableField: !this.state.enableField });
  };

  // Customizable Area Start

  handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    this.setState({ anchorEl: event.currentTarget });
  };

  searchFilter = () => {
    this.setState({filter:true})
  }

  searchFilter1 = () => {
    this.setState({filter1:true})
  }

  searchFilter2 = () => {
    this.setState({filter2:true})
  }

  handleCheckboxChangeProgram = (id: any, is_selected: any) => {
    let data = this.state.checked;
    data.push(id)
    const params = data.map((id: any) => `filter_dues[]=${id}`).join('&');
    this.libraryCheckedDataSort = params
  }

  handleClosefilter = () => {
    this.setState({filter:false})
  }

  handleClosefilter1 = () => {
    this.setState({filter1:false})
  }

  handleClosefilter2 = () => {
    this.setState({filter2:false})
  }

  handleClose = () => {
    this.setState({ anchorEl: null });
  };

  weekhandleChange = (event:any) => {
    this.setState({weekSelected:event.target.value});
  }

  postplayButton = async (ele:any) => {
    this.props.history.push(`/course-detail/${ele.attributes.collection.id}`,{name: ele.attributes.collection})
  }

  handleClickWeek = (value:any) => {

    if(value === "This Week"){
      this.setState({show:true});
    }else if(value === "Last Week"){
      this.setState({show:false});
    }
  }


  last_trackVideo = (data:any) => {
    
    // this.setState({paramTitle:data?.attributes?.collection?.attributes?.title})
    let date = new Date();
	let current_date = date.getFullYear()+"-"+(date.getMonth()+1)+"-"+ date.getDate();
  let course_video_id:any = data.attributes.collection.attributes.course_video_track.last_video_id;
  let track_time:any = data.attributes.collection.attributes.course_video_track.seen_time;
  let is_completed:any = localStorage.getItem("Last_track-data");
  let is_video_completed = JSON.parse(is_completed)
  

let formdata = new FormData();
formdata.append("course_video_id", course_video_id);
formdata.append("track_time", track_time);
formdata.append("is_completed", is_video_completed.is_video_completed);
formdata.append("track_date", current_date);
formdata.append("is_update", "true");
if(data.attributes.collection.attributes.course_video_track.program_id){
  formdata.append("program_id",data.attributes.collection.attributes.course_video_track.program_id)
}


    const header = {
      ContentType: "multipart/form-data",
      token: localStorage.getItem("token")
    };
      const httpBody = formdata;
    
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.Last_VideoTrackData = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `/bx_block_adhocreporting/course_video_track`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      header
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      'Post'
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      httpBody
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  handleClickChart = (value:any) => {
    const header = {
      "Content-Type": "application/json",
      token: localStorage.getItem("token")
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.GetLibraryChartData = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_adhocreporting/course_video_track/video_web_track?filter=${value || this.state.selected}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      header
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      'get'
    );
    runEngine.sendMessage(requestMessage.id, requestMessage); 
  }


  getIn_progressLibraryData = async () => {
    this.setState({ loaderOpen: true })
    const header = {
      "Content-Type": "application/json",
      token: localStorage.getItem("token")
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.GetIn_progressLibraryData = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_library2/personal_libraries?course_status=in_progress&${this.libraryCheckedDataSort}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      header
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      'get'
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  getPendingLibraryData = async () => {
    this.setState({ loaderOpen: true })
    const header = {
      "Content-Type": "application/json",
      token: localStorage.getItem("token")
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.GetPendingLibraryData = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_library2/personal_libraries?course_status=pending&${this.libraryCheckedDataSort}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      header
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      'get'
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  getCompletedLibraryData = async () => {
    this.setState({ loaderOpen: true })
    const header = {
      "Content-Type": "application/json",
      token: localStorage.getItem("token")
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.GetCompletedLibraryData = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_library2/personal_libraries?course_status=completed&${this.libraryCheckedDataSort}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      header
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      'get'
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  getAllNotesData = async () => {
    const header = {
      "Content-Type": "application/json",
      token: localStorage.getItem("token")
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.GetAllNotesdata = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_library2/notes`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      header
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      'get'
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }


  handleNavigate = (type:string,id:any, pathname: string, state:any) => {
    this.props.history.push({
      pathname,
      state
    })   
}

handleChange = (event:any) => {
  this.setState({selected:event.target.value});
}

  // Customizable Area End
}