import React from "react";
import {
  Box,
  Button,
  Typography,
  InputAdornment,
  // Customizable Area Start
  Grid,
  Paper,
  TextField,
  CircularProgress, Checkbox,
  Dialog, RadioGroup, Radio, FormControlLabel,
  Accordion, AccordionDetails, AccordionSummary
  // Customizable Area End
} from "@material-ui/core";
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
// Customizable Area Start
import { createTheme, makeStyles, withStyles } from "@material-ui/core/styles";
import { purple } from '@material-ui/core/colors';
import TuneIcon from '@material-ui/icons/Tune';
import LinearProgressBar from "./LinearProgress.web";
import Linear from './Linear.web'
import './style.css'
const achivement = require("../assets/achivement.png");
const Avatar = require("../assets/avatar.jpg");
const crown = require("../assets/crrown.png");
const arrow = require("../assets/arrow.png");
const cardBackground = require("../assets/cardBackground.png");
const diagram = require("../assets/diagram.png");
const exmaplePic = require("../assets/exmaplePic.png");
const pic1 = require("../assets/pic1.jpeg");
const searchIcon = require("../assets/searchIcon.png");
const course = require("../assets/course.png");
const tick = require("../assets/Tick.png");

const theme = createTheme({
  palette: {
    primary: {
      main: "#fff",
      contrastText: "#fff",
    },
  },
  typography: {
    h6: {
      fontWeight: 500,
    },
    subtitle1: {
      margin: "20px 0px",
    },
  },
});
// Customizable Area End

import CustomisableUserProfilesController, {
  Props
} from "./CustomisableUserProfilesController.web";

export default class InsightOverview extends CustomisableUserProfilesController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  async componentDidMount() {
    this.getAllReportsData();
    this.Allcategories_list();
  }
  handleSearch = (e: any) => {
    this.getAllReportsData(e.target.value);
    this.setState({
      reportsSearch: e.target.value,
      reportsLoading: true
    })
  }
  // Customizable Area End
  render() {
    const { allReportsData, reportsSearch, reportsLoading, sort } = this.state;
    return (
      // Customizable Area Start

      <Grid container>

        <Typography variant="h3" className="profile_header">Reports</Typography>
        <Grid item xs={12}>
          <Grid item xs={12} style={{ marginTop: "30px", background: "#f6f6f6", padding: "15px 20px 0px 20px", borderRadius: '15px', cursor: 'pointer' }}
            onClick={() => this.props.navigation.navigate("CourseReports")}>
            <Box style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
              <Typography variant="h6" className="profileName">
                Overall Course Report
              </Typography>
            </Box>
            <Grid container style={{ marginTop: "10px" }}>
              <Grid item xs={12} style={{ display: 'flex' }} >
                <Grid container spacing={2} justifyContent="center" style={{ padding: "35px 0", flexDirection: 'column', alignItems: 'center' }}>
                  {reportsLoading ? <></> : allReportsData.length > 0 ?
                    <Linear allReportsData={allReportsData} /> : <Box style={{ display: "flex", height: "35%", width: "100%", justifyContent: "center", alignItems: "center" }} > Data Not Found </Box>}

                </Grid>
              </Grid>
            </Grid>
          </Grid>

          <Grid container spacing={3} style={{ marginTop: "25px" }}>
            <Grid item xs={12}>
              <Paper style={{ boxShadow: '0px 0px 16px rgba(191, 190, 190, 0.350581)', borderRadius: "5px", marginRight: "15px" }}>

                <Box style={{ padding: "20px" }}>
                  <Box style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                    <Typography variant="h6" className="profileName">
                      All Courses
                    </Typography>
                    <Box style={{ display: 'flex', justifyContent: 'space-between' }}>
                      <TextField
                        size="small"
                        placeholder="Search"
                        variant="outlined"
                        style={{
                          width: "60%"
                        }}
                        id="input-with-icon-adornment"
                        onChange={(e) => this.handleSearch(e)}
                        value={reportsSearch}
                        InputProps={{
                          startAdornment: <InputAdornment position="start"><img src={searchIcon} /></InputAdornment>,
                        }}
                      />

                      {/* @ts-ignore */}
                      <TransparentButton onClick={() => this.OpenSearchFilter()} size="small">Filter</TransparentButton>
                      <TransparentButton onClick={() => this.searchShort()} size="small"> <TuneIcon /></TransparentButton>

                    </Box>
                  </Box>
                  {reportsLoading ? <Box style={{ display: "flex", height: "35%", width: "100%", justifyContent: "center", alignItems: "center" }} > <CircularProgress /> </Box> :
                    <AllReports
                      checkedhandleChange={this.checkedhandleChange}
                      CheckedDataSort={this.CheckedDataSort}
                      reportCategaries={this.state.reportCategaries}
                      checkedhandleChange3={this.checkedhandleChange3}
                      checkboxChecked1={this.state.checkboxChecked1}
                      libraryCheckedData3={this.state.libraryCheckedData3}
                      filter={this.state.filter}
                      checkboxChecked={this.state.checkboxChecked}
                      closeSearchFilter={this.closeSearchFilter}
                      applyShorting={this.applyShorting}
                      selectedIndices={this.state.selectedIndices}
                      checkedhandleChange2={this.checkedhandleChange2}
                      libraryCheckedData2={this.state.libraryCheckedData2}
                      closeSearchSort={this.closeSearchSort}
                      sortdata={sort}
                      allReportsData={allReportsData}
                      navigation={this.props.navigation} />}

                </Box>
              </Paper>
            </Grid>
          </Grid>
        </Grid>


      </Grid>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
const webStyle = {
  overallProgress: {
    width: '80%',
    height: '35px',
    backgroundColor: '#e4f5f8',
    "& .MuiLinearProgress-colorPrimary": {
      backgroundColor: "red",
    },
  },
  overallProgress2: {
    width: '98%',
    height: '30px',
    backgroundColor: '#e4f5f8',
    paddingTop: '10px',
    borderRadius: '10px',
    color: 'red',
    "& .MuiLinearProgress-barColorPrimary": {
      backgroundColor: "red",
      color: 'pink'
    },
  },

  inputStyle: {
    borderBottom: "1px solid rgba(0, 0, 0, 0.6)",
    width: "100%",
    height: "100px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
  },
  buttonStyle: {
    width: "100%",
    height: "45px",
    marginTop: "40px",
    border: "none",
    backgroundColor: "rgb(98, 0, 238)",
  },
  allCourseInnerContent: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItem: 'center',
    width: '97%',
    paddingBottom: '10px'
  }
};

const TransparentButton = withStyles((theme) => ({
  root: {
    backgroundColor: 'transparent',
    color: purple[500],
    borderRadius: "10px",
    borderColor: purple[500],
    border: '1px solid',
    '&:hover': {
      borderColor: purple[500],
      color: purple[500],
      backgroundColor: 'transparent',
    },
  },
}))(Button);

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
  },
}));


function AllReports({ checkboxChecked, checkedhandleChange, CheckedDataSort, reportCategaries, checkboxChecked1, checkedhandleChange3, libraryCheckedData3, filter, closeSearchFilter, applyShorting, selectedIndices, allReportsData, navigation, sortdata, closeSearchSort, libraryCheckedData2, checkedhandleChange2 }:
  { checkedhandleChange3: any, checkboxChecked: any, checkedhandleChange: any, CheckedDataSort: any, reportCategaries: any, checkboxChecked1: any, libraryCheckedData3: any, filter: any, closeSearchFilter: any, applyShorting: any, allReportsData: any, navigation: any, sortdata: boolean, closeSearchSort: any, libraryCheckedData2: any, checkedhandleChange2: any, selectedIndices: any }) {
  const classes = useStyles();

  return (
    <>
      <div className={classes.root}>
        <Grid container spacing={3} style={{ marginTop: "10px" }}>
          <Grid item xs={12}>
            {
              allReportsData && allReportsData.length > 0 ? allReportsData.map((item: any, key: any) => {
                return (
                  <Paper key={key} elevation={1} style={{ marginBottom: '20px', borderRadius: "10px", height: "165px", display: 'flex', flexDirection: "column", justifyContent: 'center', padding: '20px' }} >

                    <Box style={{ width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                      <Box display="flex" style={{ width: '100%' }}>
                        <Box style={{ width: '135', height: '120px' }}>
                          <img src={item?.image} height="100%" width="100%" style={{ marginRight: "10px", borderRadius: '15px' }} />
                        </Box>
                        {item.total_video_count === item.watched_video ? <img style={{ position: 'absolute', width: '65px', margin: '25px' }} src={tick} /> : <Box style={{ width: '120px', marginTop: '7%', position: 'absolute', height: '24px', borderRadius: '10px', backgroundColor: '#F42074' }}>
                          <Typography style={{ textAlign: 'center', color: '#fff', fontWeight: 500 }}>{item.start_date}</Typography></Box>}
                        <Box style={{ width: '100%', marginLeft: '15px' }}>
                          <Box style={webStyle.allCourseInnerContent}>
                            <Typography variant="h6" className="prof" style={{ fontWeight: "bold" }}>
                              {item?.title}
                            </Typography>
                            <Typography style={{ color: '#777E90', fontSize: '16px', fontWeight: 700 }} className="prof">
                              Start date: {item?.start_date}
                            </Typography>
                          </Box>
                          <Box style={webStyle.allCourseInnerContent}>
                            <Typography style={{ color: '#777E90', fontSize: '19px', fontWeight: 600 }} variant="h6" className="prof">
                              XpertName
                            </Typography>
                            <Typography style={{ color: '#777E90', fontSize: '16px', fontWeight: 700 }} className="prof">
                              End date: {item?.end_date}
                            </Typography>
                          </Box>
                          {allReportsData.length > 0 ? <LinearProgressBar totalVideo={item.total_video_count} video={item.watched_video} /> : <></>}

                        </Box>
                      </Box>
                    </Box>
                  </Paper>
                )
              }) :
                <Box style={{ display: "flex", height: "35%", width: "100%", justifyContent: "center", alignItems: "center" }} > Data Not Found</Box>
            }
          </Grid>

        </Grid>
      </div>
      <Dialog className="dialogSort_Report" hideBackdrop onClose={closeSearchSort} aria-labelledby="simple-dialog-title" open={sortdata}>
        <Paper style={{ borderRadius: '10px', width: '204px', padding: '15px', boxShadow: '0px 3px 10px rgba(0, 0, 0, 0.445832)' }}>
          <Box style={{ display: 'flex', justifyContent: 'flex-start', marginTop: '25px' }}>
            <Typography style={{ fontSize: '16px', lineHeight: '18px', fontWeight: 700, color: '#1A1A1A' }}>Sort Courses</Typography>
          </Box>
          <Box style={{ display: 'flex', justifyContent: 'flex-start', marginTop: '11px', width: '92%' }}>
            <Typography style={{ fontSize: '13px', lineHeight: '20px', fontWeight: 400, color: '#1A1A1A' }}>Show courses according to the following selected options</Typography>
          </Box>
          <Box style={{ width: '100%', display: 'flex', justifyContent: 'flex-start', marginTop: '11px', marginRight: '30%' }}>
            <hr style={{ width: '100%', height: '1px', border: '1px solid #979797', opacity: 0.43 }} />
          </Box>
          <Box style={{ marginTop: '15px' }}>
            <RadioGroup value={selectedIndices} onChange={checkedhandleChange2}>
              {libraryCheckedData2.map((data: any) => (
                <FormControlLabel
                  key={data.id}
                  value={data.name}
                  control={<Radio style={{ color: '#6C328B' }} />}
                  label={
                    <Typography style={{ fontSize: '12px', lineHeight: '15px', fontWeight: 600, color: '#1A1A1A' }}>
                      {data.text}
                    </Typography>
                  }
                />
              ))}
            </RadioGroup>
          </Box>

          <Box>
            <Button onClick={() => applyShorting()} variant="outlined" style={{ marginBottom: '17px', marginTop: '17px', color: '#6C328B', float: 'right', height: '31px', width: '100%', border: '1.8px solid #6C328B', fontWeight: 700, borderRadius: '10px' }}>
              Apply
            </Button>
          </Box>
        </Paper>
      </Dialog>
      <Box style={{
        position: "absolute",
        top: 'auto',
        left: 0,
        bottom: 0,
        zIndex: 999
      }}>
        <Dialog className="dialog_filter" hideBackdrop onClose={closeSearchFilter} aria-labelledby="simple-dialog-title" open={filter}>
          <Paper style={{ borderRadius: '10px', width: '204px', boxShadow: '0px 3px 10px rgba(0, 0, 0, 0.445832)', overflow: 'hidden auto', paddingBottom: '60px' }}>
            <Box style={{ padding: '15px' }}>
              <Box style={{ display: 'flex', justifyContent: 'flex-start' }}>
                <Typography style={{ fontSize: '16px', lineHeight: '18px', fontWeight: 700, color: '#1A1A1A' }}>Filter Courses</Typography>
              </Box>
              <Box style={{ display: 'flex', justifyContent: 'flex-start', marginTop: '11px', width: '100%' }}>
                <Typography style={{ fontSize: '13px', lineHeight: '20px', fontWeight: 400, color: '#1A1A1A' }}>Show courses according to the following selected options</Typography>
              </Box>
              <Box style={{ width: '100%', display: 'flex', justifyContent: 'flex-start', marginTop: '11px', marginRight: '30%' }}>
                <hr style={{ width: '100%', height: '1px', border: '1px solid #979797', opacity: 0.43 }} />
              </Box>
            </Box>
            <Accordion defaultExpanded={true} style={{ marginTop: '-23px', boxShadow: 'none' }}>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
              >
                <Typography style={{ fontSize: '13px', fontWeight: 600 }}>Categories</Typography>
              </AccordionSummary>
              <AccordionDetails style={{ marginTop: '-40px' }}>
                <Box style={{ marginTop: '15px' }}>
                  {reportCategaries?.data?.map((data: any, index: any) => (
                    <Box key={index} style={{ display: 'flex', justifyContent: 'space-between', marginBottom: '12px' }}>
                      <Typography style={{ fontSize: '13px', lineHeight: '18px', fontWeight: 400, color: '#1A1A1A' }}>{data.attributes.name}</Typography>
                      <Checkbox
                        checked={checkboxChecked[data.attributes.id] || false}
                        onChange={(event) => checkedhandleChange(data.attributes.id, data.attributes.is_selected, event)}
                        color="primary"
                        inputProps={{ 'aria-label': 'secondary checkbox' }}
                        style={{ marginTop: '-12px', marginRight: '-8px', color: '#6C328B' }}
                      />
                    </Box>
                  ))}

                </Box>
              </AccordionDetails>
            </Accordion>
            <Box style={{ width: '100%', display: 'flex', justifyContent: 'flex-start', marginTop: '0px', marginRight: '30%', padding: '0 15px' }}>
              <hr style={{ width: '100%', height: '1px', border: '1px solid #979797', opacity: 0.43 }} />
            </Box>
            <Accordion defaultExpanded={true} style={{ boxShadow: 'none' }}>
              <AccordionDetails style={{ marginTop: '-40px' }}>
                <Box style={{ marginTop: '15px' }}>
                  {libraryCheckedData3?.map((data: any, index: any) => (
                    <Box key={index} style={{ display: 'flex', justifyContent: 'space-between' }}>
                      <Typography style={{ fontSize: '13px', lineHeight: '18px', fontWeight: 400, color: '#1A1A1A' }}>{data.text}</Typography>
                      <Checkbox
                        // checked={checkboxChecked1[data.checked1] || false}
                        // onChange={(event: any) => checkedhandleChange3(data.checked1, event)}
                        // checked={checkboxChecked1[data.checked1] || false}
                        // onChange={(event: any) => checkedhandleChange3(data, event)}
                        checked={checkboxChecked1[data.checked1] || false}
                        onChange={(event: any) => checkedhandleChange3(data.checked1, event)}
                        inputProps={{ 'aria-label': 'secondary checkbox' }}
                        style={{ marginTop: '-12px', color: '#6C328B', marginRight: '-38px' }}
                      />
                    </Box>
                  ))}
                </Box>
              </AccordionDetails>
            </Accordion>
            <Box style={{ width: '100%', display: 'flex', justifyContent: 'flex-start', marginTop: '0px', marginRight: '30%', padding: '0 15px' }}>
              <hr style={{ width: '100%', height: '1px', border: '1px solid #979797', opacity: 0.43 }} />
            </Box>

            <Box style={{ padding: '15px' }}>
              <Button onClick={() => CheckedDataSort()
                // this.CheckedSearchDataSort()
              } variant="outlined" style={{ marginBottom: '17px', marginTop: '17px', color: '#6C328B', float: 'right', height: '31px', width: '100%', border: '1.8px solid #6C328B', fontWeight: 700, borderRadius: '10px' }}>
                Apply
              </Button>
            </Box>
          </Paper>
        </Dialog>
      </Box>
    </>
  );
}


// Customizable Area End
