import React from "react";
import {
    Button, Grid,
    // Customizable Area Start
    Paper, Switch
    // Customizable Area End
    , Typography
} from "@material-ui/core";
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import Checkbox from '@material-ui/core/Checkbox';
import Modal from '@material-ui/core/Modal';
import ManageVideoCourseController from "./ManageVideoCourseController.web";
// Customizable Area Start
const Vector = require("../assets/Vector.png");
const video = require("../assets/videoFolder.png");
const videoIcon = require("../assets/videoIcon.png");
const arrow = require("../assets/VectorLessthen.png");
const Dots = require("../assets/GroupDots.png");
const playButton = require("../assets/Group190.png")
const delet = require("../assets/delete.png");
const dwonload = require("../assets/dwonload.png")
const profile = require("../assets/share.png")
const copylink = require("../assets/copylink.png")
const Rename = require("../assets/Rename.png")
const lock = require("../assets/lockFolder.png")
const trash = require("../assets/trash-can.png")
// Customizable Area End

export default class ManageVideoCourse extends ManageVideoCourseController {
    //   constructor() {
    // super();
    // Customizable Area Start
    // Customizable Area End
    //   }

    // Customizable Area Start

    // Customizable Area End


    render() {
        return (
            <div>
                <Grid container>
                    <Grid item lg={12} md={12} sm={12} xs={12}>
                        <Typography style={{ fontSize: '48px', fontWeight: 700, }}>Manage Thumbnail Library</Typography>
                    </Grid>
                    {/* <Grid container style={{ marginTop: '25px' }}>
                        <Grid item lg={5}> <TextField inputProps={{ maxlength: 50 }} style={{ borderRadius: '12px', width: '350px' }} placeholder=" Search" variant="outlined" /></Grid>
                        <Grid item lg={1}></Grid>
                        <Grid item lg={2}><Button style={{ borderRadius: '12px', width: '186px', height: '56px' }} variant="outlined" >View Quiz Bank</Button></Grid>
                        <Grid item lg={2}><Button style={{ borderRadius: '12px', width: '212px', height: '56px' }} variant="outlined" >Upload New Video</Button></Grid>
                        <Grid item lg={2}><Button style={{ borderRadius: '12px', width: '219px', height: '56px' }} variant="outlined" >Bulk Upload Videos</Button></Grid>
                    </Grid> */}

                    <Grid container style={{ marginTop: '25px' }}>
                        <Grid item lg={8}>
                            <div style={{ display: 'flex' }}>
                                <Typography style={{ fontSize: '20px', fontWeight: 400, color: '#979797', marginTop: '22px' }}>Folders</Typography>
                                <img style={{ width: '10px', height: '15px', marginTop: '28px', marginLeft: '15px', marginRight: '15px' }} src={arrow} />
                                <Typography style={{ fontSize: '20px', fontWeight: 400, color: '#979797', marginTop: '22px' }}>User Uploads</Typography>
                                <img style={{ width: '10px', height: '15px', marginTop: '28px', marginLeft: '15px', marginRight: '15px' }} src={arrow} />
                                <Typography style={{ fontSize: '20px', fontWeight: 400, color: '#979797', marginTop: '22px' }}>Video Course</Typography>
                            </div>
                        </Grid>
                        <Grid item lg={2}>
                            <div style={{ display: 'flex', justifyContent: 'space-evenly', marginTop: '10px' }}>
                                <div style={{ display: 'flex', marginTop: '10px' }}>
                                    <img style={{ width: '20px', height: '12px', marginTop: '7px' }} src={Vector} />
                                    <Typography style={{ fontSize: '18px', fontWeight: 500, color: '#6C328B', paddingLeft: '15px' }}>List View</Typography>
                                </div>
                                <Switch className="Switch" checked={this.state.themeMode} onChange={() => this.setState({ themeMode: !this.state.themeMode })} />
                            </div>
                        </Grid>
                        <Grid item lg={2}><Button style={{ borderRadius: '12px', width: '212px', height: '56px', backgroundColor: '#653889' }} variant="contained" color="primary" >Add Thumbnails</Button></Grid>
                    </Grid>
                    <Modal
                        open={this.state.Modalopen}
                        onClose={this.ModalhandleClose}
                        aria-labelledby="simple-modal-title"
                        aria-describedby="simple-modal-description"
                        style={{ backgroundColor: "rgba(0, 0, 0, -0.5)" }}
                    >
                        <div style={{
                            position: 'absolute', width: '225px', height: '200px', backgroundColor: '#fff',
                            marginTop: '22%', marginLeft: '30%', borderRadius: '15px', boxShadow: '-6px -6px 25px rgba(230, 230, 230, 0.25), 6px 6px 25px rgba(229, 229, 229, 0.25)'

                        }}>
                            <div style={{ display: 'flex', marginTop: '13px', marginLeft: '25px' }}>
                                <img src={profile} />
                                <Typography style={{ fontSize: '16px', fontWeight: 400, color: '#2B2E3C', paddingLeft: '15px' }}>Share</Typography>
                            </div>
                            <hr style={{ width: '200px', marginTop: '5px', border: '1px solid rgba(0, 0, 0, 0.1)' }} />
                            <div style={{ display: 'flex', marginTop: '0px', marginLeft: '25px' }}>
                                <img src={copylink} />
                                <Typography style={{ fontSize: '16px', fontWeight: 400, color: '#2B2E3C', paddingLeft: '15px' }}>Copy Link</Typography>
                            </div>
                            <hr style={{ width: '200px', marginTop: '5px', border: '1px solid rgba(0, 0, 0, 0.1)' }} />
                            <div style={{ display: 'flex', marginTop: '0px', marginLeft: '25px' }}>
                                <img src={Rename} />
                                <Typography style={{ fontSize: '16px', fontWeight: 400, color: '#2B2E3C', paddingLeft: '15px' }}>Rename</Typography>
                            </div>
                            <hr style={{ width: '200px', marginTop: '5px', border: '1px solid rgba(0, 0, 0, 0.1)' }} />
                            <div style={{ display: 'flex', marginTop: '0px', marginLeft: '25px' }}>
                                <img src={lock} />
                                <Typography style={{ fontSize: '16px', fontWeight: 400, color: '#2B2E3C', paddingLeft: '15px' }}>Lock Folder</Typography>
                            </div>
                            <hr style={{width: '200px',marginTop: '5px',border: '1px solid rgba(0, 0, 0, 0.1)'}}/>
                            <div style={{ display: 'flex', marginTop: '0px', marginLeft: '25px' }}>
                                <img src={trash} />
                                <Typography style={{ fontSize: '16px', fontWeight: 400, color: '#2B2E3C', paddingLeft: '15px' }}>Lock Folder</Typography>
                            </div>
                        </div>
                    </Modal>
                    {this.state.themeMode ?
                        <>
                            <Grid container style={{ marginTop: '40px', marginBottom: '25px' }}>
                                <Grid item lg={3}>
                                    <Typography style={{ fontSize: '16px', fontWeight: 600, textAlign: 'center' }}>Name</Typography>
                                </Grid>
                                <Grid item lg={2}>
                                    <Typography style={{ fontSize: '16px', fontWeight: 600, textAlign: 'center' }}>Created By</Typography>
                                </Grid>
                                <Grid item lg={2}>
                                </Grid>
                                <Grid item lg={5}>
                                    <Typography style={{ fontSize: '16px', fontWeight: 600, float: 'right', marginRight: '20%' }}>Download</Typography>
                                </Grid>
                            </Grid>
                            {this.state.libraryData.map((data: any, index: any) => (
                                <Paper style={{
                                    boxShadow: '-6px -6px 25px #e0e0e0, 6px 6px 25px #e0e0e0',
                                    width: '100%',
                                    margin: 'auto',
                                    backgroundColor: '#fcfcfd',
                                    height: '70px',
                                    borderRadius: '10px',
                                    marginBottom: '15px',
                                }}>
                                    <Grid container style={{ marginTop: '20px' }}>
                                        <Grid item lg={1} style={{ textAlign: 'center' }}>
                                            <Checkbox
                                                defaultChecked
                                                color="primary"
                                                inputProps={{ 'aria-label': 'secondary checkbox' }}
                                                style={{ marginTop: '-8px' }}
                                            />
                                        </Grid>
                                        <Grid item lg={2}>
                                            <Typography style={{ fontSize: '14px', fontWeight: 500, textAlign: 'center' }}>Section 1.0_Solar.mp4</Typography>
                                        </Grid>
                                        <Grid item lg={2}>
                                            <Typography style={{ fontSize: '14px', fontWeight: 500, textAlign: 'center' }}>John Doe</Typography>
                                        </Grid>
                                        <Grid item lg={2}>
                                        </Grid>
                                        <Grid item lg={5}>
                                            <Grid container>
                                                <Grid item lg={8}></Grid>
                                                <Grid item lg={2}>
                                                    <img src={dwonload} style={{ cursor: 'pointer' }} />
                                                </Grid>
                                                <Grid item lg={2}>
                                                    <img src={delet} style={{ cursor: 'pointer' }} />
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </Grid>

                                </Paper>
                            ))}
                        </>
                        :
                        <Grid container spacing={2} style={{ marginTop: '40px' }}>
                            {this.state.libraryData.map((data: any, index: any) => (
                                <>
                                    <Grid key={index.id} item lg={3}>
                                        <Card style={{ maxWidth: 285, boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.1)' }}>

                                            <CardMedia
                                                component="img"
                                                alt="Contemplative Reptile"
                                                height="200"
                                                image={video}
                                                title="Contemplative Reptile"
                                            />
                                            <img src={playButton} style={{ marginTop: '-16%', padding: '7%', position: 'absolute', display: 'flex' }} />
                                            <CardContent>
                                                <Grid container>
                                                    <Grid item lg={3}>
                                                        <img src={videoIcon} />
                                                    </Grid>
                                                    <Grid item lg={9}>
                                                        <Typography style={{ fontSize: '16px', fontWeight: 400 }}>
                                                            Section 1.0_Solar.mp4
                                                        </Typography>
                                                    </Grid>
                                                </Grid>

                                            </CardContent>

                                        </Card>
                                    </Grid>
                                </>
                            ))}

                        </Grid>
                    }


                </Grid>
            </div>
        )

    }

}
