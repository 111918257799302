// Customizable Area Start
import React from "react";
import ReactPlayer from "react-player";
import { Grid, Box, Button, Modal, Backdrop, Fade } from "@material-ui/core";
import Rating from '@material-ui/lab/Rating';
import { createStyles, Theme, withStyles } from "@material-ui/core/styles";
import PageTitle from "../../../components/src/DynamicTitle";
import ProgramIncludes from "./components/ProgramIncludes.web";
import ObjectDetailsController from "./ObjectDetailsController.web";
import Loader from "../../../components/src/Loader.web";
import PdfViewer from "./components/PdfViewer.web";
import PdfViewerDialog from "./components/PdfViewerDialog.web";
import { NotesIcon } from "./assets";
import AddNoteModal from "../../CustomisableUserProfiles/src/AddNoteModal.web";

interface Tag {
  attributes: {
    name: string;
  }
}

class ObjectDetails extends ObjectDetailsController {
  render() {
    const { classes } = this.props;
    // Extracting the file extension from the filename
    const fileExtension = this.state.data?.attachment?.file_name?.split('.').pop().toLowerCase();
    // Checking the file extension to determine the file type
    let fileType;
    if (this.state.data?.custom_event === "po_documents") {
      fileType = "document";
    } else if (["mp4", "avi", "mov"].includes(fileExtension)) {
      fileType = "video";
    } else if (["jpg", "jpeg", "png", "gif"].includes(fileExtension)) {
      fileType = "image";
    } else {
      fileType = "unknown";
    }

    let attachmentContent = null;
    if (fileType === "video") {
      attachmentContent = (
        <Box className="player-wrapper">
          <ReactPlayer
            controls
            className="react-player"
            url={this.state.data?.attachment?.url}
            file="HLS(m3u8)"
            Speed={1}
            playing={true}
            width="100%"
            height="100%"
            played
            notes={<img src={NotesIcon} />}
            ref={this.myRef}
          />
        </Box>
      );
    } else if (fileType === "image") {
      attachmentContent = (
          <img 
            src={this.state.data?.attachment?.url} 
            alt="object image" 
            style={{ 
              width: "100%", 
              height: "585px", 
              objectFit: "cover"
            }} 
          />
      );
    } else if (fileType === "document") {
      attachmentContent = (
        <PdfViewer 
          url={this.state.data?.attachment?.url}
          fullScreenPdf={this.state.fullScreenPdf}
          onFullScreenBtnClick={
            () => this.handleFullScreenPdfToggle(
              !this.state.fullScreenPdf
            )
          }
        />
      );
    }

    let tagsContent = null;
    const tags = this.state.data?.custom_tag
      ?.map((tag: Tag) => tag.attributes.name)
      .join(", ");
    if (tags) {
      tagsContent = (
        <p className={`${classes.textMD} ${classes.textMDLight}`}>
          {tags}
        </p>
      )
    }

    let downloadBtn = null;
    if (fileType === "document") {
      downloadBtn = (
        <Button 
          variant="contained" 
          className={classes.downloadBtn}
          onClick={this.downloadPdf}
        >
          Download PDF
        </Button>
      );
    }

    const phaseExists = this.state.data?.phases_data?.content_release_schedule;
    const phaseReleaseSchedule = this.state.data?.phases_data?.content_release_schedule_value
    let phases;
    let programIncludes;
    
    if (!phaseExists) {
      phases = {};
      programIncludes = this.state.data?.program_includes;
    } else {
      programIncludes = [];
      phases = {
        [this.state.data?.phases_data?.phase]: {
          package: this.state.data?.program_includes,
        },
      };
    }

    return (
      <>
        <Loader loading={this.state.isLoading} />
        <PageTitle title="Object Details" />
        <Grid container>
          <Grid
            container
            spacing={4}
            style={{ width: "100%", marginTop: "12px" }}
          >
            <Grid item lg={8} xs={12}>
              <Box className={classes.attachmentContainer}>
                {attachmentContent}
              </Box>
              <Grid container justifyContent="space-between" alignItems="center">
                <Grid item md={11} xs={12}>
                  <h1 className={classes.textXL}>{this.state.data?.name}</h1>
                  <div className={classes.authorContainer}>
                    <p className={classes.textMD}>
                      {this.state.data?.created_by}
                    </p>
                    {tagsContent}
                    <div className={classes.ratingContainer}>
                      <span 
                        className={`${classes.textL} ${classes.textOrange}`}
                      >
                        {this.state.data?.average_rating}
                      </span>
                      <Rating 
                        readOnly
                        name="object-rating" 
                        size="small" 
                        defaultValue={this.state.data?.average_rating}
                        precision={0.5} 
                      />
                    </div>
                  </div>
                </Grid>
                <Grid item md={1} xs={12}>
                  <Button 
                    variant="contained" 
                    className={classes.notesBtn}
                    onClick={() => this.handleOpenNotesModal(true)}
                  >
                    <img src={NotesIcon} alt="take note" />
                  </Button>
                </Grid>
              </Grid>
              {/* Note modal */}
              <Modal
                aria-labelledby="transition-modal-title"
                aria-describedby="transition-modal-description"
                className="modalStyle"
                open={this.state.openNotesModal}
                onClose={() => this.handleOpenNotesModal(false)}
                closeAfterTransition
                BackdropComponent={Backdrop}
                BackdropProps={{
                    timeout: 500,
                }}
              >
                <Fade in={this.state.openNotesModal}>
                  <AddNoteModal 
                    id=""
                    getAllData={null}
                    navigation={this.props.navigation}
                    handleClose={() => this.handleOpenNotesModal(false)} 
                  />
                </Fade>
              </Modal>
              <p className={classes.textSM}>
                {this.state.data?.description}
              </p>
              {downloadBtn}
            </Grid>
            <Grid item lg={4} xs={12}>
              <ProgramIncludes 
                totalCourses={this.state.data?.total_courses}
                totalObjects={this.state.data?.total_objects}
                phases={phases}
                programIncludes={programIncludes}
                phaseReleaseSchedule={phaseReleaseSchedule}
                isEnrolled={true}
                handleNavigate={this.handleCourseNavigation}
                handleObjectNavigation={this.handleObjectNavigation}
                objectId={this.props.navigation.getParam("objectId")}
              />
            </Grid>
          </Grid>
        </Grid>
        <PdfViewerDialog 
          url={this.state.data?.attachment?.url}
          fullScreenPdf={this.state.fullScreenPdf}
          onNoteBtnClick={() => this.handleOpenNotesModal(true)}
          onFullScreenBtnClick={
            () => this.handleFullScreenPdfToggle(
              !this.state.fullScreenPdf
            )
          }
        />
      </>
    );
  }
}

const styles = (theme: Theme) =>
  createStyles({
    attachmentContainer: {
      width: "100%",
      borderTopRightRadius: "10px",
      borderTopLeftRadius: "10px",
      overflow: "hidden",
    },
    textXL: {
      color: "#171725",
      fontSize: "24px",
      fontStyle: "normal",
      fontWeight: 700,
      lineHeight: "36px",
      letterSpacing: "0.1px",
    },
    textL: {
      fontSize: "17px",
      fontStyle: "normal",
      fontWeight: 700,
      lineHeight: "16px",
    },
    textOrange: {
      color: "#F8B84F",
      marginRight: 10,
    },
    textMD: {
      color: "#696974",
      fontSize: "16px",
      fontStyle: "normal",
      fontWeight: 500,
      lineHeight: "normal",
      letterSpacing: "0.1px",
      margin: "10px 0",
    },
    textMDLight: {
      color: "#92929D",
      fontWeight: 400,
    },
    textSM: {
      color: "#505050",
      fontSize: "14px",
      fontStyle: "normal",
      fontWeight: 400,
      lineHeight: "26px",
    },
    authorContainer: {
      display: "flex",
      flexDirection: "column",
      justifyContent: "flex-start",
      alignItems: "flex-start",
      flexWrap: "wrap",
      "& > *": {
        marginRight: 20,
        "&::before": {
          content: "'|'",
          marginRight: 10,
          [theme.breakpoints.down("xs")]: {
            content: "''",
            marginRight: 0
          }
        },
        "&:first-child::before": {
          content: "''",
          marginRight: 0
        }
      },
      [theme.breakpoints.up("sm")]: {
        flexDirection: "row",
        alignItems: "center"
      }
    },
    ratingContainer: {
      display: "flex",
      justifyContent: "flex-start",
      alignItems: "center"
    },
    notesBtn: {
      background: "#6C328B",
      borderRadius: 8,
      padding: "10px",
      margin: "15px 0",
      [theme.breakpoints.up("sm")]: {
        margin: 0,
      },
      "&:hover": {
        background: "#6C328B",
      }
    },
    downloadBtn: {
      width: "329px",
      height: "48px",
      flexShrink: 0,
      borderRadius: "12px",
      background: "#FF8B3F",
      boxShadow: "0px 6px 12px 0px rgba(255, 139, 63, 0.30)",
      marginTop: 30,
      color: "#FFF",
      fontSize: "14px",
      fontWeight: 700,
      lineHeight: "16px",
      textTransform: "capitalize",
      "&:hover": {
        background: "#FF8B3F",
      }
    }
  });

export default withStyles(styles)(ObjectDetails);
// Customizable Area End
