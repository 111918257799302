import React from 'react';
 // Customizable Area Start
import { View, Text, StyleSheet, Modal, TouchableOpacity, FlatList,Image} from 'react-native';
import Scale from '../../../../components/src/Scale'
import * as IMG_CONST from '../assets'
//@ts-ignore
import Slider from "react-native-slider";
interface Iprops {
    videoWatch?: boolean;
    courseFilter?: any;
    notesSorting?: any;
    notesFilter?: any;
    isPrice?: boolean;
    userDetails: any;
    filterMarginTop: string;
    filterData?: any,
    priceData?: any,
    showFilter?: boolean,
    priceValue?: any,
    tapToCloseFilter: () => void,
    checked: boolean,
    onFilterItemSelected: (value: any) => void,
    onValueChangeSlider: (value: any) => void,
    onApplyFilterPressed: () => void

}

// Customizable Area End

    // Customizable Area Start

    // Customizable Area End
export default function CoursesFilter(props: Iprops) {
 // Customizable Area Start
 let title;

 if (props?.courseFilter) {
   title = "Filter Courses";
 } else if (props?.notesSorting) {
   title = "Sort Notes";
 } else if (props?.notesFilter) {
   title = "Filter notes";
 } else {
   title = "Sort channels";
 }

 let percentage;

if (props.userDetails) {
  percentage = "25%";
} else if (props.notesSorting || props.notesFilter) {
  percentage = "7%";
} else {
  percentage = "35%";
}


    return (
        <TouchableOpacity activeOpacity={1} onPress={() => { props.tapToCloseFilter() }}>

            <Modal
                visible={props.showFilter}
                transparent >
                <TouchableOpacity
                    activeOpacity={1}
                    onPress={() => { props.tapToCloseFilter() }}>
                    <View style={[styles.container, { marginTop:percentage}]}>
                        <View>
                            <Text style={{ fontFamily: 'Montserrat', marginLeft: Scale(7), marginTop: Scale(13), fontSize: Scale(15), fontWeight: '700', color: '#1a1a1a' }}> {title}</Text>

                            <Text style={{ marginStart: Scale(10), marginTop: Scale(10), fontSize: Scale(12), color: '#1a1a1a', fontFamily: 'Montserrat', fontWeight: '400' }}>Show {(props.notesSorting || props?.notesFilter) ? 'notes' : 'courses'} according{'\n'}to the following selected{'\n'}options</Text>
                        </View>

                        <View style={styles.separator} />
                        <View style={{}}>
                            <FlatList
                                data={props.filterData}
                                contentContainerStyle={{ marginTop: 10, marginBottom: Scale(10) }}
                                renderItem={({ item }: { item: any }) => {
                                    return <TouchableOpacity onPress={() => props.onFilterItemSelected(item?.id)} style={{ flexDirection: 'row', justifyContent: 'center', paddingHorizontal: Scale(10), paddingVertical: Scale(10) }}>
                                        <Text style={{ alignSelf: 'center', flex: 1, fontFamily: 'Montserrat-SemiBold', color: '#282828', fontSize: Scale(12) }}>{item?.name}</Text>
                                        {item?.isChecked ? <Image source={IMG_CONST.CheckedRadio} style={styles.checkedRadio} /> : <Image source={IMG_CONST.UncheckedRadio} style={styles.checkedRadio} />}
                                    </TouchableOpacity>
                                }}
                            />
                        </View>
                        {props.isPrice ? <>
                            <Text style={{ fontSize: Scale(14), fontFamily: 'Montserrat-Medium', marginLeft: Scale(12) }}>
                                Prices
                            </Text>

                            <View style={{ flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center', width: Scale(170), alignSelf: 'center', marginTop: Scale(5) }}>
                                <Text style={{ fontSize: Scale(14), fontFamily: 'Montserrat-Medium' }}>
                                    ${props.priceValue}
                                </Text>
                                <Text style={{ fontSize: Scale(14), fontFamily: 'Montserrat-Medium' }}>
                                    ${props.priceData}
                                </Text>
                            </View>

                            <Slider
                                value={props.priceValue}
                                onValueChange={(value: any) => props.onValueChangeSlider(value)}
                                minimumValue={0}
                                maximumValue={props.priceData}
                                step={1}
                                thumbStyle={styles.thumb}
                                thumbTintColor={'transparent'}
                                thumbImage={require('../../assets/thumbx.png')}
                                minimumTrackTintColor="#BB2E64"
                                maximumTrackTintColor="#D9D9D9"
                                style={{ width: Scale(165), alignSelf: 'center' }}
                                trackStyle={{ height: Scale(8), borderRadius: Scale(10) }}

                            />
                        </> : null}
                        <TouchableOpacity onPress={() => props.onApplyFilterPressed()} style={{ justifyContent: 'center', alignSelf: 'center', borderColor: '#6C328B', borderWidth: Scale(1), width: Scale(160), alignItems: 'center', borderRadius: Scale(7), marginTop: Scale(5), marginBottom: Scale(17) }}>
                            <Text style={{ color: '#702490', fontSize: Scale(15), fontFamily: 'Montserrat-Bold', paddingVertical: Scale(5) }}>Apply</Text>
                        </TouchableOpacity>


                    </View>

                </TouchableOpacity>
            </Modal>
        </TouchableOpacity>
    );
    // Customizable Area End
}

const styles = StyleSheet.create({
     // Customizable Area Start
    container: {
        width: Scale(200),
        backgroundColor: '#fff',
        alignSelf: 'flex-end',
        marginTop: '35%',
        marginEnd: '7%',
        shadowColor: "#000",
        shadowOffset: {
            width: 0,
            height: 3,
        },
        shadowOpacity: 0.45,
        shadowRadius: 3.90,
        elevation: 5,
        borderRadius: Scale(8),
        padding: Scale(2)
    },
    container1: {
        width: Scale(200),
        height: '35%',
        backgroundColor: '#fff',
        alignSelf: 'flex-end',
        marginTop: '32%',
        marginEnd: '7%',
        shadowColor: "#000",
        shadowOffset: {
            width: 0,
            height: 3,
        },
        shadowOpacity: 0.45,
        shadowRadius: 3.90,
        elevation: 5,

    },
    container2: {
        width: Scale(180),
        backgroundColor: '#fff',
        alignSelf: 'flex-end',
        marginTop: Scale(120),
        marginEnd: Scale(20),
        shadowColor: "#000",
        shadowOffset: {
            width: 0,
            height: 3,
        },
        shadowOpacity: 0.45,
        shadowRadius: 3.90,
        elevation: 5,

    },
    separator: {
        width: '90%',
        alignSelf: 'center',
        borderColor: '#E2E2E2',
        borderBottomWidth: 1,
        marginStart: Scale(15),
        marginEnd: Scale(10),
        marginTop: Scale(10)
    },
    watchView: {
        justifyContent: 'flex-end', flex: 0.75, marginTop: Scale(70)
    },

    checkedRadio: {
        height: Scale(16),
        width: Scale(16)
    },

    slider: {
        flex: 1,
        width: Scale(180),

    },
    thumb: {
        width: Scale(18),
        height: Scale(18),
        borderRadius: Scale(40),
        backgroundColor: '#D9D9D9',
        alignItems: 'center',
        justifyContent: 'center'
    },
    track: {
        height: 4,
        borderRadius: 2,
        width: Scale(50),

    }
        // Customizable Area End
})