Object.defineProperty(exports, "__esModule", {
  value: true,
});

// Customizable Area Start
exports.validationApiContentType = "application/json";
exports.validationApiMethodType = "GET";
exports.exampleAPiEndPoint = "EXAMPLE_URL";
exports.cartApiMethodType = "GET";
exports.cartPiEndPoint = "bx_block_shopping_cart/cart?full_details=true";
exports.cartItemRemoveSave = "bx_block_shopping_cart/cart/remove_item?";
exports.paymentApi = "bx_block_stripegatewayapifrontend/stripe";
exports.subsDebitCreditApi = "bx_block_library2/course_subscriptions";
exports.backToCartApi = "/bx_block_shopping_cart/cart/back_to_cart";
exports.addToCartApi = "bx_block_shopping_cart/cart";
exports.createOrder = "bx_block_stripegatewayapifrontend/stripe/create_order";
exports.createStripeSubsOrder =
  "/bx_block_stripegatewayapifrontend/stripe_subscription";
exports.lastOrder = "/bx_block_shopping_cart/cart/last_order";
exports.initiateStipePayment = "bx_block_stripegatewayapifrontend/stripe_subscription";
exports.applyPromotion =
  "bx_block_stripegatewayapifrontend/stripe/apply_promocode";
exports.removePromotion =
  "bx_block_stripegatewayapifrontend/stripe/remove_promocode";
exports.removeCard =
  "bx_block_stripegatewayapifrontend/stripe/delete_saved_card";
exports.exampleAPiMethod = "POST";
exports.exampleApiContentType = "application/json";
exports.textInputPlaceHolder = "Enter Text";
exports.labelTitleText = "Payments";
exports.labelBodyText = "Payments Body";
exports.back = "Back";
exports.checkout = "Checkout";
exports.cart = "Cart";
exports.total = "Total:";
exports.promotion = "Promotions:";
exports.apply = "Apply";
exports.enterCoupon = "Enter Coupon Code";
exports.orderConfirmation = "Order Confirmation";
exports.orderSummary = "Order summary";
exports.proceed = "Proceed";
exports.btnExampleTitle = "CLICK ME";
exports.orderComplete = "Order Complete";
exports.orderFailed = "Order Failed";
exports.congrats = "Congratulations!";
exports.oops = "OOPS!!";
exports.orderSuccessMsg =
  "Your order is successfully placed please go to the <B> Personal library </B>  to view your courses";
exports.orderFailMsg =
  "Your order has failed \n Please enter correct card details or check with your bank.";
exports.tryAgain = "Try Again";
exports.goToLibrary = "Go to Personal Library";
exports.goToHome = "Go to Home Page";
exports.youOrders = "You ordered";
exports.getApiMethodType = "GET";
exports.postApiMethodType = "POST";
exports.putApiMethodType = "PUT";

// Customizable Area End