import React, { useState } from "react";
import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";
import { IconButton, Menu, MenuItem } from "@material-ui/core";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import DeleteOutlineIcon from "@material-ui/icons/DeleteOutline";
import VolumeOffIcon from "@material-ui/icons/VolumeOff";

interface IIcon {
  type: string;
}

function Icon({ type }: IIcon) {
  const classes = useStyles();

  if (type === "normal") {
    return <MoreVertIcon />;
  } else if (type === "delete") {
    return <DeleteOutlineIcon className={classes.deleteIcon} />;
  } else {
    return <VolumeOffIcon className={classes.silentIcon} />;
  }
}

interface INotificationMoreBtn {
  listType: string;
  disabled: boolean;
  onClick: (type: string) => void;
  onDeleteClick: () => void;
  onSilentClick: () => void;
}

export default function NotificationMoreButton({
  onClick,
  listType,
  onDeleteClick,
  disabled,
  onSilentClick,
}: INotificationMoreBtn) {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    if (listType === "normal") {
      setAnchorEl(event.currentTarget);
    } else if (listType === "delete") {
      onDeleteClick();
    } else {
      onSilentClick();
    }
  };

  const handleClose = () => {
    setAnchorEl(null);
    // handelState()
  };

  const handleListItemClick = (type: string) => {
    onClick(type);
    handleClose();
  };

  return (
    <>
      <IconButton onClick={handleClick} disabled={disabled}>
        <Icon type={listType} />
      </IconButton>
      <Menu
        id="bulka-actions-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
        className={classes.menu}
      >
        <MenuItem
          className={classes.menuItem}
          onClick={() => handleListItemClick("delete")}
        >
          Delete Notifications
        </MenuItem>
        <MenuItem
          className={classes.menuItem}
          onClick={() => handleListItemClick("silence")}
        >
          Silence Notifications
        </MenuItem>
      </Menu>
    </>
  );
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    deleteIcon: {
      color: "#6C328B",
    },
    silentIcon: {
      color: "#6C328B",
    },
    menu: {
      "& .MuiList-padding": {
        paddingLeft: 15,
        paddingRight: 15,
      },
      "& .MuiPaper-rounded": {
        borderRadius: 15,
      },
    },
    menuItem: {
      color: "#1A1A1A",
      fontWeight: 700,
      fontSize: 16,
      textTransform: "capitalize",
      borderBottom: "1.59219px solid #979797",
      padding: "10px 0",
      "&:last-child": {
        border: 0,
      },
      "&:hover": {
        backgroundColor: "#FFFFFF",
      },
    },
  })
);
