import React from "react";
// Customizable Area Start
import {
  StyleSheet,
  Text,
  View,
  TextInput,
  Button,
  ScrollView,
  Platform,
  Image,
  Modal,
  TouchableOpacity,
  TouchableWithoutFeedback,
  KeyboardAvoidingView,
  ImageBackground,
  Dimensions,
  SafeAreaView,
} from "react-native";
//@ts-ignore
import ModalActivityIndicator from "react-native-modal-activityindicator";
import CountryCodeSelector from "../../country-code-selector/src/CountryCodeSelector";

import CustomCheckBox from "../../../components/src/CustomCheckBox";
import ForgotPasswordController, { Props } from "./ForgotPasswordController";
import { backgroundImage, Oval, keyImage, errorImage, perfectImage } from "./assets";
import ErrorMessage from "../../../components/src/ErrorMessage";
import Scale from "../../../components/src/Scale"
import CheckBox from "@react-native-community/checkbox";
const mobileWidth = Dimensions.get("window").width;
const mobileHeight = Dimensions.get("window").height;

// Customizable Area End
// Customizable Area Start
export default class EmailAccountRegistration extends ForgotPasswordController {
  constructor(props: Props) {
    super(props);

  }

  renderEmailSendModal = () => {
    return (
      <Modal visible={this.state.showSuccessVerifyModal} transparent>
        <View style={styles.modalWapper}>
          <View style={styles.St_termsandcondiotionView}>
            <Image
              style={{ position: "absolute", alignSelf: "center", top: 10 }}
              source={perfectImage}
            />
            <Text style={[styles.textlabel_TermsandConditionsText, { color: "#ff8b3e", marginTop: 100 },]}>PERFECT!</Text>
            <Text
              style={{
                paddingHorizontal: 30,
                fontSize: 18,
                paddingVertical: 10,
                fontFamily: "Montserrat-Medium",
                textAlign: "center",
                color: "#000",
              }}
            >Your password has been changed</Text>
            <TouchableOpacity
              data-elementId="button_back"
              onPress={() => {
                this.hideVerifyModal();
                this.props.navigation.navigate("EmailAccountLoginBlock");
              }}
              style={[styles.btnStyle, { marginBottom: 20 }]}
            >
              <Text data-elementId="TEXTCOLORSTYLE" style={styles.btnTextStyle}>
                OK
              </Text>
            </TouchableOpacity>
          </View>
        </View>
      </Modal>
    );
  };

  render() {

    let backgroundColor;
    if (
      this.state.password &&
      this.state.reTypePassword &&
      this.state.password === this.state.reTypePassword
    ) {
      backgroundColor = "#FF8B3F";
    } else {
      backgroundColor = "#979797";
    }
    return (
      <KeyboardAvoidingView
        behavior={this.isPlatformiOS() ? "padding" : undefined}
        style={styles.keyboardPadding}
      >
        <ImageBackground
          source={backgroundImage}
          style={{ width: "100%", height: "100%" }}
        >
          <SafeAreaView style={{ flex: 1 }}>
            {this.renderEmailSendModal()}
            {this.state.loading && (
              <ModalActivityIndicator
                visible={this.state.loading}
                size="large"
                color="white"
              />
            )}
            <ScrollView
              keyboardShouldPersistTaps="always"
              style={styles.container}
            >
              <ErrorMessage
                showErrorModal={this.state.showErrorModal}
                onPress={() => {
                  this.setState({ showErrorModal: false });
                }}
                content={this.state.content}
              />
              <TouchableWithoutFeedback
                testID={"Background"}
                onPress={() => {
                  this.hideKeyboard();
                }}
              >
                <View>
                  <Image
                    style={{ right: 10, marginTop: 10, position: "absolute" }}
                    source={Oval}
                  />

                  <TouchableOpacity
                    style={styles.containerBoxSignup}
                    onPress={() => { this.props.navigation.goBack(); }}
                  >
                    <Text style={styles.backTitle}>Back</Text>
                  </TouchableOpacity>
                  <View style={styles.containerBox}>
                    <View style={styles.headline}>
                      {this.isPlatformWeb() ? (
                        <Text style={styles.signUpText}>Sign Up</Text>
                      ) : null}
                      <Image
                        source={keyImage}
                        style={{
                          height: 80,
                          width: 80,
                          transform: [{ scaleX: -1 }],
                          tintColor: "#A698AD"
                        }}
                      />
                      <View>
                        <Text style={styles.titleWhySignUp}
                          numberOfLines={2}>
                          {this.thirdLabelText}
                        </Text>
                        <Text style={styles.subTitle} numberOfLines={1}>
                          {this.enterNewPassword}
                        </Text>
                      </View>
                    </View>
                    <View style={styles.bgPasswordContainer}>
                      <TextInput
                        testID={"txtInputPassword"}
                        style={styles.bgPasswordInput}
                        placeholder={this.labelPassword}
                        {...this.txtInputPasswordProps}
                        placeholderTextColor="#CCB0D5"
                      />

                      <TouchableOpacity
                        testID={"btnPasswordShowHide"}
                        {...this.btnPasswordShowHideProps}
                      >
                        <Image
                          testID={"imgEnablePasswordField"}
                          style={styles.imgPasswordShowhide}

                          {...this.imgEnablePasswordFieldProps}
                        />
                      </TouchableOpacity>
                    </View>

                    <Text>{this.state.passwordHelperText}</Text>
                    <View style={styles.bgPasswordContainer}>
                      <TextInput
                        testID={"txtInputConfirmPassword"}
                        style={styles.bgPasswordInput}
                        placeholder={this.labelRePassword}
                        placeholderTextColor="#CCB0D5"
                        {...this.txtInputConfirmPasswordProps}
                      />

                      <TouchableOpacity
                        testID={"btnConfirmPasswordShowHide"}
                        {...this.btnConfirmPasswordShowHideProps}
                      >
                        <Image
                          testID={"imgEnableRePasswordField"}
                          style={styles.imgPasswordShowhide}

                          {...this.imgEnableRePasswordFieldProps}
                        />
                      </TouchableOpacity>
                    </View>
                    <TouchableOpacity
                      testID={"btnPasswordShowHide"} //Merge Engine::From BDS
                      style={[styles.btnStyle, { backgroundColor: backgroundColor }]} //UI Engine::From Sketch
                      onPress={() => {
                        this.resetPassword();
                        //this.showModal();
                      }}
                    >
                      <Text style={styles.btnTextStyle}>{this.buttonTitle} </Text>
                    </TouchableOpacity>
                  </View>
                </View>
              </TouchableWithoutFeedback>
            </ScrollView>
            <View style={{ width: "100%", alignSelf: "center" }}>
              <Text
                style={{
                  color: "#fff",
                  textAlign: "center",
                  marginBottom: 10,
                  opacity: 0.5,
                  fontFamily: "Montserrat-Medium",
                }}
              >
                {this.xpandLogo}
              </Text>
            </View>
          </SafeAreaView>
        </ImageBackground>
      </KeyboardAvoidingView>
    );
  }
}
// Customizable Area End

// Customizable Area Start
const styles = StyleSheet.create({
  St_termsandcondiotionView: {
    backgroundColor: "rgba(255, 255, 255, 1)",
    borderRadius: Scale(15),
    alignItems: "flex-start",
    marginHorizontal: Scale(15),
  },
  textlabel_TermsandConditionsText: {
    alignSelf: "center",
    marginTop: Scale(30),
    opacity: 1,
    backgroundColor: "transparent",
    includeFontPadding: false,
    padding: 0,
    color: "rgba(26, 26, 26, 1)",
    textAlign: "center",
    textAlignVertical: "top",
    fontFamily: "Montserrat-Bold",
    fontSize: Scale(18),
  },

  textlabel_1Text: {
    paddingVertical: Scale(10),
    marginLeft: Scale(20),
    opacity: 1,
    backgroundColor: "transparent",
    includeFontPadding: false,
    padding: 0,
    color: "rgba(26, 26, 26, 1)",
    textAlign: "left",
    textAlignVertical: "top",
    fontFamily: "Montserrat-Medium",
    fontSize: Scale(13),
  },

  textlabel_AtveroeosetaccusText: {
    paddingVertical: Scale(10),
    paddingHorizontal: Scale(10),
    opacity: 1,
    backgroundColor: "transparent",
    includeFontPadding: false,
    padding: 0,
    color: "rgba(26, 26, 26, 1)",
    textAlign: "left",
    textAlignVertical: "top",
    fontFamily: "Montserrat-Medium",
    fontSize: Scale(14),
  },

  modalView: {
    backgroundColor: " rgba(0,0,0,0)",
    flex: 1,
    justifyContent: "center",
    alignItems: "center",
    paddingHorizontal: Scale(18),
  },
  modalWapper: {
    backgroundColor: " rgba(0,0,0,0.6)",
    flex: 1,
    justifyContent: "center",
    alignItems: "center",
    paddingHorizontal: Scale(18),
  },

  container: {
    flex: 1,

    marginLeft: "auto",
    marginRight: "auto",
    width: Platform.OS === "web" ? "75%" : "100%",
    paddingBottom: Scale(60),
  },
  isAutoRenewal: {
    marginLeft: 0,
  },
  containerBoxSignup: {
    padding: Scale(16),
    paddingBottom: Scale(30),
    alignSelf: "flex-start",
    justifyContent: "center",
    flexDirection: "row",
  },
  backTitle: {
    fontSize: Scale(16),
    textAlign: "left",
    color: "#fafafa",
    fontFamily: "Montserrat-Bold",
    marginBottom: Scale(10),
    lineHeight: Scale(25),
  },
  containerBox: {
    paddingVertical: Scale(60),
    alignSelf: "center",
    justifyContent: "center",
    padding: Scale(16),
    marginTop: Scale(80),
    marginBottom: Scale(50),
    marginLeft: "auto",
    marginRight: "auto",
    width: Platform.OS === "web" ? "75%" : "93%",
    backgroundColor: "#f8f4f926",
    borderRadius: Scale(15),
  },
  titleWhySignUp: {
    fontSize: Scale(21),
    textAlign: "left",
    color: "#fafafa",
    fontFamily: "Montserrat-Bold",

    marginLeft: Scale(5),
  },
  subTitle: {
    marginLeft: Scale(5),
    fontSize: Scale(13),
    color: "#fafafa",
    fontFamily: "Montserrat-Medium",
    width: "100%",
    marginTop: Scale(5),
  },
  titleOtpInfo: {
    marginBottom: Scale(30),
    fontSize: Scale(16),
    textAlign: "left",
    marginVertical: Scale(8),
  },
  btnStyle: {
    alignSelf: "center",
    paddingVertical: Scale(8),
    paddingHorizontal: Scale(30),
    marginTop: Scale(40),
    backgroundColor: "#bebebe",
    borderRadius: Scale(15),
  },
  btnTextStyle: {
    color: "#fff",
    fontSize: Scale(14),
    fontFamily: "Montserrat-Bold",
  },
  bgInput: {
    flexDirection: "row",
    fontSize: Scale(16),
    textAlign: "left",
    backgroundColor: "#00000000",
    marginVertical: Scale(15),
    borderBottomWidth: Scale(1),
    borderBottomColor: "#fafafa",
    borderRadius: Scale(2),
    includeFontPadding: true,
    padding: Scale(10),
    fontFamily: "Montserrat-Medium",
    color: "#fafafa",
  },

  inputWeb: {
    flex: 1,
    flexDirection: "row",
    marginTop: Scale(24),
    fontSize: Scale(18),
    padding: Scale(10),
    borderBottomColor: "#767676",
    includeFontPadding: true,
    borderBottomWidth: Scale(1),
  },

  bgRectBorder: {
    borderWidth: Scale(1),
    borderColor: "#767676",
    borderRadius: Scale(2),
    marginBottom: Scale(10),
  },
  bgPasswordInput: {
    width: Scale(290),
    fontSize: Scale(15),
    color: "#fff",
    fontFamily: "Montserrat-Medium",
  },
  bgPasswordContainer: {
    justifyContent: 'space-between',
    alignItems: 'center',
    flexDirection: "row",
    backgroundColor: "#00000000",
    borderBottomWidth: Scale(1),
    borderBottomColor: "#8D6894",
    borderRadius: Scale(2),
    paddingLeft: Scale(5),
    paddingRight: Scale(5),
    width: Scale(350),
    zIndex: -1,

    // height: mobileHeight * 0.05
  },
  imgPasswordShowhide: { height: Scale(30), width: Scale(30), tintColor: '#fff' },
  keyboardPadding: { flex: 1 },
  btnLegalTermsAndCondition: { color: "#6200EE" },
  btnLegalPrivacyPolicy: { color: "#6200EE", marginLeft: "auto" },
  leagalText: {
    color: "#fff",
    fontSize: Scale(16),
    marginLeft: Scale(8),
  },
  headline: {
    flex: 1,
    flexDirection: "row",
    width: mobileWidth * 1,
    marginBottom: Scale(15),
  },
  labelReferalText: {
    fontSize: Scale(16),
    color: "#fff",

    fontFamily: "Montserrat-Bold",
    marginTop: Scale(4),
    marginLeft: Scale(8),
  },
  signUpText: {
    fontSize: Scale(32),
    color: "#6200EE",
    fontFamily: "Montserrat-Bold",
  },
  labelLegalTermCondition: {
    fontSize: Scale(16),
    color: "#ff8b3e",
    fontFamily: "Montserrat-Bold",
    marginLeft: Scale(8),
  },
});
// Customizable Area End
