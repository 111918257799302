// Customizable Area Start
import React from "react";
import { IBlock } from "../../../../../framework/src/IBlock";
import { Message } from "../../../../../framework/src/Message";
import { BlockComponent } from "../../../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../../../framework/src/RunEngine";
import { apiCall } from "../../../../../framework/src/Utilities";
import { toast } from "react-toastify";

const configJSON = require("./config.js");

export interface Branch {
  id: number;
  name: string;
}

export interface Team {
  id: number;
  name: string;
}

export interface Props {
  navigation: any;
  history: any;
  location: any;
  classes: any;
}

interface S {
  Open: boolean;
  value: any;
  show: boolean;
  isLoading: boolean;
  branches: Branch[];
  teams: Team[];
}

interface SS {
  id: any;
}

export default class ManageUsersController extends BlockComponent<
  Props,
  S,
  SS
> {
  teamsAndBranchesListApiCallId: string = "";
  createUserApiCallId: string = "";
  resetFormCallback: Function;

  private childRef: React.RefObject<any>;
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionSaveMessage),
      getName(MessageEnum.SessionResponseMessage),
    ];

    this.state = {
      Open: false,
      value: new Date(),
      show: false,
      isLoading: false,
      branches: [],
      teams: [],
    };
    this.childRef = React.createRef();
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async componentDidMount(): Promise<void> {
    this.getTeamsAndBranches();
  }

  async receive(from: string, message: Message) {
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );
      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      // Receive api responses
      if (apiRequestCallId === this.teamsAndBranchesListApiCallId) {
        this.handleGetTeamsAndBranches(responseJson);
      } else if (apiRequestCallId === this.createUserApiCallId) {
        this.handleCreateUserResponse(responseJson);
      }
    }
  }

  // Get users list
  getTeamsAndBranches = async () => {
    this.teamsAndBranchesListApiCallId = await apiCall({
      contentType: configJSON.apiContentType,
      method: configJSON.getApiMethod,
      endPoint: configJSON.listOfTeamsAndBranchesEndPoint,
    });
  };

  // Handle users list api response
  handleGetTeamsAndBranches = (response: any) => {
    if (response?.branch) {
      this.setState({ branches: response.branch });
    }

    if (response?.teams) {
      this.setState({ teams: response.teams });
    }
  };

  // Create user
  createUser = async (values: any, resetForm: Function) => {
    this.resetFormCallback = resetForm;
    
    const payload = new FormData();
    payload.append("first_name", values.firstName);
    payload.append("last_name", values.lastName);
    values.title && payload.append("title", values.title);
    payload.append("email", values.email);
    values.phone && payload.append("full_phone_number", values.phone);
    payload.append("password", values.password);
    payload.append("confirm_password", values.confirmPassword);
    values.accExpDate && payload.append("account_expiration_date", values.accExpDate);
    payload.append("active_account", values.activeUser);
    values.branchId && payload.append("branch_id", values.branchId);
    values.teamId && payload.append("teams_accounts_attributes[0][team_id]", values.teamId);
    payload.append("show_reports", values.showReports);
    payload.append("can_manage_user", values.canManageUsers);
    payload.append("role", values.accessLevel);

    this.setState({ isLoading: true });

    this.createUserApiCallId = await apiCall({
      method: configJSON.postApiMethod,
      endPoint: configJSON.createUserEndPoint,
      payload,
    });
  }

  // Handle create user response
  handleCreateUserResponse = (response: any) => {
    this.setState({ isLoading: false });
    if (response?.data) {
      this.resetFormCallback();
      toast.success("User created");
      return;
    }

    // Show error messages
    if (response?.errors) {
      response.errors.map((err: {message: string}) => {
        toast.error(err.message);
      })
    }
  }

  handleClick = (e: any) => {
    this.childRef.current.handleClick(e);
  };

  openDialog() {
    console.log("open");
    this.setState({ Open: !this.state.Open });
    console.log(this.state.Open, "<===open");
  }

  onChange(e: any) {
    this.setState({ value: e.target.value });
  }

  paymentDialog() {
    this.setState({ show: !this.state.show });
  }
}
// Customizable Area End
