import React from "react";

import {
  Button,
  InputAdornment,
  // Customizable Area Start
  Grid,
  TextField,
  Select,
  FormControl,
  MenuItem,
  Box, Dialog, DialogActions
  // Customizable Area End
} from "@material-ui/core";
// Customizable Area Start
import DynamicTitle from "../../../components/src/DynamicTitle";
import Table from "../../../components/src/Table";
import { Search } from "@material-ui/icons";
import debouce from "lodash.debounce";
import { ArrowDown, course, program } from "./assets";
import "./ContentManagement.css";
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';

// Customizable Area End

import ContentManagementController, {
  Props,
} from "./ContentManagementController.web";

const styles = (theme: any) => ({
  customDialogPaper: {
    maxWidth: '285px',
    height: '145px',
    marginLeft: '65%',
    marginTop: '-6%',
    padding: '12px',
    borderRadius: '15px',
  },
});

class ContentManagement extends ContentManagementController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    this.handleChange = this.handleChange.bind(this);
    this.debouncedResults = this.debouncedResults.bind(this);
    // Customizable Area End
  }

  // Customizable Area Start
  handleChange(e: any) {
    const { value } = e.target;
    if (e.target.name === "contentType") {
      this.getCourse({
        search: this.state.text,
        filter: value,
        page: "",
        per: "",
      });
      this.setState({ contentType: value });
    } else if (e.target.name === "search") {
      this.setState({ text: value });
      this.debouncedResults(e.target.value);
    } else {
      this.getCourse({
        search: this.state.text,
        filter: this.state.contentType,
        page: "",
        per: "",
      });
      this.setState({ contentType: "", text: "" });
    }
  }
  NewCourse = () => {
    this.props.navigation.navigate("CourseCreation", {
      id: "new",
      type: "addCourse",
    });
  }
  NewProgram = () => {
    this.props.navigation.navigate("ProgramCreation", {
      id: "new",
      type: "addProgram",
    });
  }

  navigateTo(id: number, expand_type: string) {
    if (this.state.contentType === "course")
      this.props.navigation.navigate(
        `${expand_type === "Course" ? "CourseCreation" : "ProgramCreation"}`,
        {
          id: id,
          type: expand_type === "Course" ? "editCourse" : "editProgram",
        }
      );
    else
      this.props.navigation.navigate(`ProgramCreation`, {
        id: id,
        type: "editProgram",
      });
  }
  debouncedResults: any = debouce(
    (text) => this.getCourse({ search: text, filter: "", page: "", per: "" }),
    700
  );

  // Customizable Area End

  render() {
    const { classes } = this.props;
    return (
      // Customizable Area Start
      <>
        <DynamicTitle title="Manage Courses/Programs" />
        <Grid container className="list">
          <Grid item container spacing={4} md={12}>
            <Grid item md={6} xs={12} className="search-outline">
              <TextField
                fullWidth
                id="search"
                name="search"
                value={this.state.text}
                variant="outlined"
                placeholder="Search"
                InputLabelProps={{
                  shrink: true,
                }}
                className="search"
                onChange={(e) => this.handleChange(e)}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <Search />
                    </InputAdornment>
                  ),
                }}
              />
            </Grid>
            <Grid item md={6} xs={12} style={{ textAlign: "end" }}>
              <div className="box">
                {/* <FormControl className="select-course">
                  <Select
                    labelId="demo-controlled-open-select-label"
                    id="demo-controlled-open-select"
                    name="contentType"
                    style={{ borderRadius: '10px' }}
                    value={this.state.contentType}
                    onChange={this.handleChange}
                    // endAdornment={
                    //   <InputAdornment
                    //     position="end"
                    //     style={{ display: "flex", alignItems: "center", cursor:'pointer' }}
                    //   >
                    //     <img src={ArrowDown} />
                    //   </InputAdornment>
                    // }
                    inputProps={{
                      endAdornment: (
                        <InputAdornment position="end" className="arrow-down-icon">
                          <img src={ArrowDown} alt="Arrow Down" />
                        </InputAdornment>
                      ),
                    }}
                    startAdornment={
                      <InputAdornment position="start" className="filter-label">
                        Filter By :
                      </InputAdornment>
                    }
                    IconComponent={() => null}
                    variant="outlined"
                    autoWidth={true}
                    MenuProps={{ style: { marginTop: '80px' } }}
                  >
                    <MenuItem value="All" className="menu-item-type" selected>
                      All
                    </MenuItem>
                    <MenuItem value={"course"} className="menu-item-type">
                      Course
                    </MenuItem>
                    <MenuItem value={"program"} className="menu-item-type">
                      Program
                    </MenuItem>
                  </Select>
                </FormControl> */}
                <FormControl className="select-course">
                  <Select
                    labelId="demo-controlled-open-select-label"
                    id="demo-controlled-open-select"
                    name="contentType"
                    style={{ borderRadius: '10px' }}
                    value={this.state.contentType}
                    onChange={this.handleChange}
                    variant="outlined"
                    autoWidth={true}
                    MenuProps={{ style: { marginTop: '80px' } }}
                    inputProps={{
                      endAdornment: (
                        <InputAdornment style={{ display: "flex", alignItems: "center", cursor:'pointer' }} position="end" className="arrow-down-icon">
                          <hr className="vertical-line"></hr>
                          <img style={{ display: "flex", alignItems: "center", cursor:'pointer' }} src={ArrowDown} alt="Arrow Down" />
                        </InputAdornment>
                      ),
                    }}
                    startAdornment={
                      <InputAdornment position="start" className="filter-label">
                        Filter By :
                      </InputAdornment>
                    }
                  >
                    <MenuItem value="All" className="menu-item-type" selected>
                      All
                    </MenuItem>
                    <MenuItem value={"course"} className="menu-item-type">
                      Course
                    </MenuItem>
                    <MenuItem value={"program"} className="menu-item-type">
                      Program
                    </MenuItem>
                  </Select>
                </FormControl>
                <Box style={Styles.Accordion}>
                  <Button onClick={() => this.handleClickOpen()}
                    style={Styles.AccordionSummary}
                    // expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                  >
                    <Typography style={Styles.Accordion_Typography} >
                      <span style={{ fontSize: "28px", fontWeight: 600 }}>
                        +&nbsp;
                      </span>{" "}
                      Create New
                    </Typography>
                  </Button>
                  <Box style={Styles.accordionContent}>
                    <Dialog
                      open={this.state.open}
                      onClose={this.handleClose}
                      aria-labelledby="alert-dialog-title"
                      aria-describedby="alert-dialog-description"
                      classes={{ paper: classes.customDialogPaper }}
                    >
                      <DialogActions>
                        <Grid container spacing={2}>
                          <Grid item lg={12}>
                            <Button
                              className="course-btn"
                              onClick={() => {
                                this.NewCourse();
                              }}
                            >
                              <span style={{ fontSize: "28px", fontWeight: 600 }}>
                                <img style={{ paddingRight: '20px' }} src={course} />
                              </span>{" "}
                              Create New Course
                            </Button>
                          </Grid>
                          <hr style={{ backgroundColor: "#f3f0f0", width: "215px", height: '1px' }} />
                          <Grid item lg={12}>
                            <Button
                              className="course-btn"
                              onClick={() => {
                                this.NewProgram();
                              }}
                            >
                              <span style={{ fontSize: "28px", fontWeight: 600 }}>
                                <img style={{ paddingRight: '20px' }} src={program} />
                              </span>{" "}
                              Create New Program
                            </Button>
                          </Grid>
                        </Grid>
                      </DialogActions>
                    </Dialog>
                  </Box>
                </Box>

              </div>
            </Grid>
          </Grid>
          <Table
            data={this.state.data}
            getCourse={(obj: any) => this.getCourse(obj)}
            contentType={this.state.contentType}
            meta={this.state.meta}
            search={this.state.text}
            navigateTo={(id: number, expand_type: string) =>
              this.navigateTo(id, expand_type)
            }
            className="table"
          />
        </Grid>

      </>

      // Customizable Area End
    );
  }
}

export default withStyles(styles)(ContentManagement);

const Styles: any = {
  Accordion: {
    height: '56px',
    marginLeft: '15px',
    borderRadius: '15px',
    boxShadow: 'none',
    position: 'relative',
  },
  accordionContent: {
    position: 'absolute',
    top: '100%',
    left: '50%',
    transform: 'translateX(-50%)',
    zIndex: 1,
  },
  AccordionDetails: {
    backgroundColor: '#fff',
    position: 'absolute',
    borderRadius: '10px',
    zIndex: 999,
  },
  Accordion_Typography: {
    margin: 'auto',
    fontStyle: 'normal',
    display: ' flex',
    fontWeight: 600,
    fontSize: '16px',
    lineHeight: '16px',
    textAlign: 'center',
    color: '#FFFFFF',
    textTransform: 'capitalize',
  },
  AccordionSummary: {
    backgroundColor: '#6C328B',
    height: '56px',
    borderRadius: '15px',
    width: '192px',
  },
  course1_btn: {
    background: '#6C328B',
    borderRadius: "16px",
    height: "56px",
    width: "196px",
    fontStyle: "normal",
    fontWeight: 600,
    fontSize: "16px",
    lineHeight: "16px",
    textAlign: "center",
    color: "#FFFFFF",
    textTransform: "capitalize",

  },
  course2_btn: {
    background: '#6C328B',
    borderRadius: "16px",
    height: "56px",
    width: "196px",
    fontStyle: "normal",
    fontWeight: 600,
    fontSize: "16px",
    lineHeight: "16px",
    textAlign: "center",
    color: "#FFFFFF",
    textTransform: "capitalize",

  }
}
