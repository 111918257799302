import {
  AppBar,
  Avatar,
  Box,
  IconButton,
  Menu,
  MenuItem,
  Toolbar,
  Typography,
  useMediaQuery
} from "@material-ui/core";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import ExitToAppIcon from "@material-ui/icons/ExitToApp";
import MenuIcon from "@material-ui/icons/Menu";
import MoreIcon from "@material-ui/icons/MoreVert";
import SettingsIcon from "@material-ui/icons/Settings";
import ShoppingCartOutlinedIcon from '@material-ui/icons/ShoppingCartOutlined';
import React, { MouseEvent, useState, useEffect } from "react";
import { Link, withRouter, RouteComponentProps } from "react-router-dom";
import { EditIcon, MessageIcon, NotificationIcon, EmptyNotificationIcon, AvatarImage } from "./assets";
import Settings5 from "../../../blocks/Settings5/src/Settings5.web";
import { toast } from "react-toastify";
import Modal from "@material-ui/core/Modal";
import Fade from "@material-ui/core/Fade";
import Backdrop from "@material-ui/core/Backdrop";
import ProfileModal from "../../../blocks/CustomisableUserProfiles/src/ProfileModal.web";
import MyIntrest from "../../../blocks/CustomisableUserProfiles/src/MyInterest.web";
import Notifications2 from "../../../blocks/notifications/src/Notifications2.web";
const { baseURL } = require("../../../framework/src/config");
import { compareTime } from "../../../framework/src/CommonFunction";
import { capitalizeFirstLetter } from "../../../framework/src/Helpers.web";
import Congratulations from "../../../blocks/dashboard/src/Congratulations.web";
import EditEmail from "../../../blocks/CustomisableUserProfiles/src/EditEmail.web";

interface Props extends RouteComponentProps {
  handleDrawerToggle: any;
  drawerWidth: number;
  roles: string[];
  navigation: any;
  cartItemsCount?: number;
  routeNotFound: boolean;
}

const USER_DATA_URL =
  `${baseURL}/bx_block_roles_permissions/profiles`;
const NOTIFICATIONS_DATA_URL = `${baseURL}/notifications/notifications`;
const ACTIVATION_CONFIRMATION = `${baseURL}/account_block/account/close_email_confirmation_screen`;
const TimeSpentTrainingTrack = `${baseURL}/bx_block_adhocreporting/training_track`;

function Topbar({
  handleDrawerToggle,
  drawerWidth,
  history,
  roles,
  navigation,
  cartItemsCount,
  routeNotFound,
}: Props) {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [
    mobileMoreAnchorEl,
    setMobileMoreAnchorEl,
  ] = useState<null | HTMLElement>(null);
  const [open, setOpen] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState(false);
  const [username, setUsername] = useState("");
  const [avatarUrl, setAvatarUrl] = useState("");
  const [isOpen, setIsOpen] = useState(false);
  const [isOpenEditEmail, setIsOpenEditEmail] = useState(false);
  const [isModal, setIsModal] = useState(false);
  const [notiOpen, setNotiOpen] = useState(false);
  const [cartItem, setCartItem] = useState(0);
  const [role, setRole] = useState('');
  const [userRole, setUserRole] = useState("");
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [notificationExists, setNotificationExists] = useState(false);
  const fullScreen = useMediaQuery("(max-width:600px)");

  const classes = useStyles({
    drawerWidth,
  });

  const token = localStorage.getItem("token");
  const headers = { token };

  const isMenuOpen = Boolean(anchorEl);
  const isMobileMenuOpen = Boolean(mobileMoreAnchorEl);


  const handleMenuOpen = (event: MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMobileMenuClose = () => {
    setMobileMoreAnchorEl(null);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
    handleMobileMenuClose();
  };

  const handleLogout = () => {
    compareTime();
    setAnchorEl(null);
    handleMenuClose();
    handleMobileMenuClose();
    localStorage.removeItem("token");
    localStorage.removeItem("first_name");
    localStorage.removeItem("tracker_time");
    history.push("/login");
  };

  const handleMobileMenuOpen = (event: MouseEvent<HTMLElement>) => {
    setMobileMoreAnchorEl(event.currentTarget);
  };

  const handleSettingsOpen = () => {
    setOpen(true);
    handleMenuClose();
    handleMobileMenuClose();
  };

  const handleSettingsClose = () => {
    setOpen(false);
  };

 const handelEditEmailModelClose = () => {
    setIsOpenEditEmail(false)
  }
 const handelEditEmailModel = () => {
    setIsOpenEditEmail(true)
    handelEditModelClose();
  }

  const removeTokenAndRedirectTo = (path: string) => {
    localStorage.removeItem("token");
    localStorage.removeItem("first_name");
    history.push(path);
  }


  let callCount = 0;

  useEffect(() => {
    // Function to start the timer
    const startTimer: any = () => {
      const intervalId = setInterval(() => {
        callCount++;
      }, 1000);

      return () => {
        clearInterval(intervalId);
      };
    };

    startTimer();

    const handleBeforeUnload = (event: BeforeUnloadEvent) => {
      timeSpentOnTraining();
      event.preventDefault();
      localStorage.setItem('elapsedTime', callCount.toString());
    };

    window.addEventListener('beforeunload', handleBeforeUnload);

    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
      // clearInterval(startTimer);
    };
  }, []);

  var formdata = new FormData();
  const currentDate = new Date();
  const day = currentDate.getDate();
  const month = currentDate.getMonth() + 1;
  const year = currentDate.getFullYear();
  const formattedDate = `${day}/${month}/${year}`;
  const storedCallCount: any = localStorage.getItem('elapsedTime');
  formdata.append("track_time", storedCallCount);
  formdata.append("track_date", formattedDate.toString());
  var requestOptions = {
    method: 'POST',
    headers: {
      token,
      // 'Content-Type': 'multipart/form-data',
    },
    body: formdata,
    redirect: 'follow'
  };
  const timeSpentOnTraining = async () => {
    try {
      // @ts-ignore
      const response = await fetch(TimeSpentTrainingTrack, requestOptions);
      if (!response.ok) {
        const { status } = response;
        // Handle expired token
        if (status === 401) {
          toast.error("Your session has expired. Please log in again");
        } else if (status === 422) {
          toast.error("Your account is not valid");
        } else {
          toast.error("Something went wrong");
        }
      } else {
        // Handle data
        const userData = await response.json();
        const {
          success
        } = userData;

      }
    } catch (error) {
      toast.error("Something went wrong");
    } finally {
      setIsLoading(false);
    }
  }


  const getUserData = async () => {
    try {
      // @ts-ignore
      const response = await fetch(USER_DATA_URL, { headers });
      if (!response.ok) {
        removeTokenAndRedirectTo("/login");
        const { status } = response;
        // Handle expired token
        if (status === 401) {
          toast.error("Your session has expired. Please log in again");
        } else if (status === 422) {
          toast.error("Your account is not valid");
        } else {
          toast.error("Something went wrong");
        }
      } else {
        // Handle data
        const userData = await response.json();
        const {
          data: {
            attributes: { first_name, last_name, avatar, user_role, activation_confirmation },
          },
        } = userData;
        setUserRole(user_role);
        if (activation_confirmation === false) {
          handelEditDailogOpen();
        }
        // Set first name & user role in the local storage
        localStorage.setItem("first_name", first_name);
        localStorage.setItem("user_role", user_role);
        if (roles?.includes(user_role) || routeNotFound) {
          setUsername(`${first_name} ${last_name}`);
          setAvatarUrl(avatar?.url);
          setRole(user_role);
        } else {
          // Redirect users to login if their role doesn't permit to view
          // the page to be rendered
          toast.error("You are not authorized to view this page");
          removeTokenAndRedirectTo("/login");
        }
      }
    } catch (error) {
      toast.error("Something went wrong");
      removeTokenAndRedirectTo("/login");
    } finally {
      setIsLoading(false);
    }
  };

  const getNotifications = async () => {
    try {
      // @ts-ignore
      const response = await fetch(NOTIFICATIONS_DATA_URL, { headers });
      if (!response.ok) {
        toast.error("Something went wrong");
        return;
      }
      // Handle data
      const notifications = await response.json();
      if (notifications.data.length > 0) {
        setNotificationExists(true);
      }
    } catch (error) {
      toast.error("Something went wrong");
    }
  };

  const handelEditDailogOpen = () => {
    setIsModalOpen(true);
  }

  const handelEditDailogClose = async () => {
    try {
      // @ts-ignore
      const response = await fetch(ACTIVATION_CONFIRMATION, { headers });
      if (!response.ok) {
        removeTokenAndRedirectTo("/login");
        const { status } = response;
        // Handle expired token
        if (status === 401) {
          toast.error("Your session has expired. Please log in again");
        } else if (status === 422) {
          toast.error("Your account is not valid");
        } else {
          toast.error("Something went wrong");
        }
      } else {
        // Handle data
        const userData = await response.json();
        const {
          success
        } = userData;
        if (success === true) {
          setIsModalOpen(false);
        }
      }
    } catch (error) {
      toast.error("Something went wrong");
      removeTokenAndRedirectTo("/login");
    } finally {
      setIsLoading(false);
    }
  }

  const handelEditModel = () => {
    setIsOpen(true);
  }

  const handelEditModel2 = () => {
    setIsModal(false);
  }

  const handelEditModelClose = () => {
    setIsOpen(false);
  }

  const handelEditModelOpen = () => {
    setIsModal(true);
  }

  const handleOpenNotification = () => {
    setNotiOpen(true);
    handleMobileMenuClose();
  }

  const handleCloseNotification = () => {
    setNotiOpen(false);
  }

  const handleMessages = () => {
    toast.success("Coming soon");
    handleMobileMenuClose();
  }

  // Fetch user details
  useEffect(() => {
    if (token) {
      setIsLoading(true);
      getUserData();
    }

  }, [token]);

  useEffect(() => {
    getNotifications();
    {localStorage.getItem("user_role") === "user" ? timeSpentOnTraining() : ""}
  }, [])

  const menuId = "primary-search-account-menu";
  const renderMenu = (
    <Menu
      anchorEl={anchorEl}
      anchorOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      id={menuId}
      keepMounted
      transformOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      open={isMenuOpen}
      onClose={handleMenuClose}
    >
      <MenuItem onClick={handleSettingsOpen}>Settings</MenuItem>
      <MenuItem onClick={handleLogout}>Logout</MenuItem>
    </Menu>
  );

  const mobileMenuId = "primary-search-account-menu-mobile";
  const renderMobileMenu = (
    <Menu
      anchorEl={mobileMoreAnchorEl}
      anchorOrigin={{ vertical: "top", horizontal: "right" }}
      id={mobileMenuId}
      keepMounted
      transformOrigin={{ vertical: "top", horizontal: "right" }}
      open={isMobileMenuOpen}
      onClose={handleMobileMenuClose}
    >
      <MenuItem>
        <Avatar
          alt={capitalizeFirstLetter(username)}
          src={avatarUrl ? avatarUrl : AvatarImage}
          className={classes.iconMobile}
        />
        <p>{username}</p>
      </MenuItem>
      <MenuItem >
        <img
          src={EditIcon}
          alt="Edit profile"
          className={`${classes.icon} ${classes.iconMobile}`}
          onClick={handelEditModel}
        />
        <p>Edit profile</p>
      </MenuItem>
      <MenuItem onClick={handleMessages}>
        <img
          src={MessageIcon}
          alt="Send Message"
          className={`${classes.icon} ${classes.iconMobile}`}
        />
        <p>Messages</p>
      </MenuItem>
      <MenuItem
        onClick={handleOpenNotification}
      >
        <img
          src={notificationExists ? NotificationIcon : EmptyNotificationIcon}
          alt="Notifications"
          className={`${classes.icon} ${classes.iconMobile}`}
        />
        <p>Notifications</p>
      </MenuItem>
      {
        role === "user" &&
        <MenuItem onClick={() => history.push('/cart')}>
          <Box className={`${classes.shoopingCart} ${classes.iconMobile}`}>
            <ShoppingCartOutlinedIcon
              className={classes.shoopingCartIcon}
            />
            <Typography className={classes.shoopingCartNum}>
              {cartItemsCount && cartItemsCount > 0 ? cartItemsCount : null}
            </Typography>
          </Box>
          <p>Cart</p>
        </MenuItem>
      }
      <MenuItem onClick={handleSettingsOpen}>
        <SettingsIcon
          className={classes.iconMobile}
          style={{ color: "#6C328B" }}
        />
        <p>Settings</p>
      </MenuItem>
      <MenuItem onClick={handleLogout}>
        <ExitToAppIcon
          className={classes.iconMobile}
          style={{ color: "#6C328B" }}
        />
        <p>Logout</p>
      </MenuItem>
    </Menu>
  );

  return (
    <>
      <AppBar position="fixed" className={classes.appbar}>
        <Toolbar>
          <IconButton
            edge="start"
            className={classes.menuButton}
            color="inherit"
            aria-label="open drawer"
            onClick={handleDrawerToggle}
          >
            <MenuIcon color="primary" />
          </IconButton>
          <Box style={{ flexGrow: 1 }} />
          <Box className={classes.sectionDesktop}>
            {history.location.pathname === "/CustomisableUserProfiles" ?
              <>
                {
                  role == "user" ?
                    <IconButton
                      size="medium"
                      aria-label="Edit profile"
                      color="primary"
                      onClick={handelEditModel}
                    >
                      <img
                        src={EditIcon}
                        alt="Edit profile"
                        className={classes.icon}

                      />
                    </IconButton> :
                    <></>
                }</> : <></>}
            <IconButton
              size="medium"
              aria-label="Messages"
              color="primary"
              onClick={handleMessages}
            >
              <img
                src={MessageIcon}
                alt="Send Message"
                className={classes.icon}
              />
            </IconButton>
            <IconButton
              size="medium"
              aria-label="Notifications"
              color="primary"
              onClick={handleOpenNotification}
            >
              <img
                src={notificationExists ? NotificationIcon : EmptyNotificationIcon}
                alt="Notifications"
                className={classes.icon}
              />
            </IconButton>
            {
              role == "user" ?
                <IconButton
                  size="medium"
                  aria-label="ShoppingCart"
                  color="primary"
                  onClick={() => history.push('/cart')}
                >
                  <Box className={classes.shoopingCart}>
                    <ShoppingCartOutlinedIcon
                      className={classes.shoopingCartIcon}
                    />
                    <Typography className={classes.shoopingCartNum}>
                      {cartItemsCount && cartItemsCount > 0 ? cartItemsCount : null}
                    </Typography>
                  </Box>
                </IconButton> :
                <></>
            }

            <Avatar
              alt={capitalizeFirstLetter(username)}
              src={avatarUrl ? avatarUrl : AvatarImage}
              className={classes.avatar}
            />
            <Typography
              className={classes.username}
              component={Link}
              to={userRole === "user" ? "/CustomisableUserProfiles" : "#"}
            >
              {username}
            </Typography>
            <IconButton
              size="medium"
              aria-label="Notifications"
              color="primary"
              onClick={handleMenuOpen}
            >
              <ArrowDropDownIcon className={classes.downArrow} />
            </IconButton>
          </Box>
          <Box className={classes.sectionMobile}>
            <IconButton
              aria-label="show more"
              aria-controls={mobileMenuId}
              aria-haspopup="true"
              onClick={handleMobileMenuOpen}
              color="primary"
            >
              <MoreIcon style={{ color: "#6C328B" }} />
            </IconButton>
          </Box>
        </Toolbar>
        <Modal
          aria-labelledby="transition-modal-title"
          aria-describedby="transition-modal-description"
          className="modalStyle"
          // @ts-ignore
          open={isOpen}
          onClose={handelEditModel}
          closeAfterTransition
          BackdropComponent={Backdrop}
          BackdropProps={{
            timeout: 500,
          }}
        >
          {/*@ts-ignore*/}
          <Fade in={isOpen}>
            <ProfileModal
              handelEditEmailModel={handelEditEmailModel}
              handelClose={handelEditModelClose}
              handleOpen={handelEditModelOpen} handelEditEmailModelClose={undefined} />
          </Fade>
        </Modal>
        <Modal
          aria-labelledby="transition-modal-title"
          aria-describedby="transition-modal-description"
          className="modalStyle"
          // @ts-ignore
          open={isModal}
          // onClose={handelEditModel2}
          closeAfterTransition
          BackdropComponent={Backdrop}
          BackdropProps={{
            timeout: 500,
          }}
        >
          {/*@ts-ignore*/}
          <Fade in={isModal}>
            <MyIntrest
              handelClose={handelEditModelClose}
              handleOpen={handelEditModel2} handelEditEmailModel={undefined} handelEditEmailModelClose={undefined}            />
          </Fade>
        </Modal>
        <Modal
          aria-labelledby="transition-modal-title"
          aria-describedby="transition-modal-description"
          className="modalStyle"
          // @ts-ignore
          open={isOpenEditEmail}
          // onClose={handelEditModel2}
          closeAfterTransition
          BackdropComponent={Backdrop}
          BackdropProps={{
            timeout: 500,
          }}
        >
          {/*@ts-ignore*/}
          <Fade in={isModal}>
            <EditEmail
              handelClose={handelEditModelClose}
              handleOpen={handelEditModel2} handelEditEmailModel={undefined} handelEditEmailModelClose={handelEditEmailModelClose} />
          </Fade>
        </Modal>
        <Congratulations
          handelEditDailogClose={handelEditDailogClose}
          isModalOpen={isModalOpen}
          history={history}
        />
        <Notifications2
          history={history}
          onClose={handleCloseNotification}
          navigation={undefined}
          id={""}
          open={notiOpen}
          fullScreen={fullScreen}
        />
      </AppBar>
      {renderMobileMenu}
      {renderMenu}
      {/* @ts-ignore */}
      <Settings5
        open={open}
        handleSettingsClose={handleSettingsClose}
        handleLogout={handleLogout}
      />
    </>
  );
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    appbar: {
      background: "#fff",
      boxShadow: "none",
      marginLeft: (props: any) => props.drawerWidth,
      [theme.breakpoints.up("xs")]: {
        width: "100%",
      },
      [theme.breakpoints.up("sm")]: {
        width: (props: any) => `calc(100% - ${props.drawerWidth}px)`,
      },
    },
    sectionDesktop: {
      display: "none",
      justifyContent: "center",
      alignItems: "center",
      border: "1px solid rgba(225,225,225,0.58)",
      margin: "10px 0",
      padding: "5px 10px",
      borderRadius: "16px",
      boxShadow: "0px 0px 16px rgba(213,213,213,0.35)",
      [theme.breakpoints.up("sm")]: {
        display: "flex",
      },
    },
    sectionMobile: {
      display: "flex",
      padding: "5px 10px",
      margin: "10px 0",
      [theme.breakpoints.up("sm")]: {
        display: "none",
      },
    },
    username: {
      textTransform: "capitalize",
      color: "#000",
      marginLeft: "12px",
      textDecoration: "none",
    },
    downArrow: { color: "#92929D" },
    icon: {
      height: "20px",
      width: "20px",
    },
    shoopingCart: {
      position: "relative",
      width: "20px",
    },
    shoopingCartNum: {
      width: '20px',
      position: 'absolute',
      color: '#fff',
      background: '#ff8b3f',
      borderRadius: '50px',
      fontSize: '13px',
      top: '-10px',
      right: '-13px',
      textAlign: "center",
    },
    shoopingCartIcon: {
      height: "25px",
      width: "25px",
      color: "#6c328b",
    },
    iconMobile: {
      marginRight: "10px",
    },
    menuButton: {
      [theme.breakpoints.up("sm")]: {
        display: "none",
      },
    },
    avatar: {
      marginLeft: 10,
    }
  })
);

// @ts-ignore
export default withRouter(Topbar);
