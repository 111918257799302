import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { imgPasswordInVisible, imgPasswordVisible } from "./assets";
import { toast } from "react-toastify";
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  handleClose?:any;
  // Customizable Area Start
  editFormData?:any;
  getAllData:any;
  // Customizable Area End
}



interface S {
  txtInputValue: string;
  txtSavedValue: string;
  enableField: boolean;
  // Customizable Area Start
  //isModalOpen:boolean;
  noteImage: any;
  image:any;
  imageDemo:any;
  body_edit:any;
  // settype:any;
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class AddNoteModalController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  crateNoteId:string = "";
  updateNoteId:string = "";
  toolbarOptions:any;
  strippedHtml:any;

  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      // Customizable Area End
    ];

    this.state = {
      txtInputValue: "",
      txtSavedValue: "A",
      enableField: false,
      // Customizable Area Start
      noteImage: null,
      image:'',
      imageDemo:'',
      body_edit:'',
      //isModalOpen:false,
      // settype:false,
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start 
    // this.handlechange = this.handlechange.bind(this)   
    // this.handleAddNoteOpenModal = this.handleAddNoteOpenModal.bind(this)
    // this.handleAddNoteCloseModal = this.handleAddNoteCloseModal.bind(this)
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    runEngine.debugLog("Message Recived", message);

    if (message.id === getName(MessageEnum.RestAPIResponceMessage)) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      var responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );


      var errorReponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );

          // api call start =======================================
        // add notes api response 
        if (apiRequestCallId === this.crateNoteId) {
          if(responseJson && !responseJson.error) {
            toast.success("Successfully Submitted");   
            this.props?.handleClose();
          } else {
            toast.error("UnSuccessfull Submission");
          }
        }
          // update notes api response 
          if (apiRequestCallId === this.updateNoteId) {
            if(responseJson && !responseJson.error){
              toast.success("Successfully Submitted");   
                this.props.handleClose()
                setTimeout(function() {
                  window.location.reload();
                }, 4000);
                
            } else {
              toast.error("UnSuccessfull Submission");   

              
            }
          }
        
    // Customizable Area End
  }
    // Customizable Area End
  }

  createNotes(user: any) {
    console.log("called API ",user)
    console.log("Type API ",user.type === 1 ? "pbulic" : "private")
    var data = new FormData()
    data.append("note[title]", user.title)
    data.append("note[description]", user.description)
    data.append("note[is_private]", user.type === 1 ? 'false' : 'true')
    data.append("note[course_id]", "1")
    data.append("note[chapter_id]", "1")
    data.append("note[note_type]", "personal")
    if(this.state.image){
      data.append("note[image]", this.state.image,this.state.image.name )
    }
    
    console.log(data,"api data");
    
    const dataToSend = {
      note:{
        title: user.title,
        description: user.description,
        is_private: user.type === 1 ? "public" : "private",
        course_id: '1',
        chapter_id: '1',
        note_type: 'personal'
      }
    }


    const header = {
      token: localStorage.getItem('token')     
    };
  
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
  
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    this.crateNoteId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getAllNotesAPiEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      data
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      'POST'
    );
  
    runEngine.sendMessage(requestMessage.id, requestMessage)
    return requestMessage.messageId;
    
  }

   handleQuill = (value:any) => {
    this.setState({body_edit:value})
    console.log(this.state.body_edit);
  };

   getText(html:any){
    let divContainer= document.createElement("div");
    divContainer.innerHTML = html.description;
    return divContainer.textContent || divContainer.innerText || "";
}


  updateNote(user: any , id:any) {
    console.log("called API ",user)
    let strippedHtmlv = this.getText;
    var data = new FormData()
    data.append("note[title]", user.title)
    data.append("note[description]", user.description) //strippedHtmlv(user))
    data.append("note[is_private]", user.type === 1 ? 'false' : 'true')
    data.append("note[course_id]", "1")
    data.append("note[chapter_id]", "1")
    data.append("note[note_type]", "personal")
    if(this.state.image){
      data.append("note[image]", this.state.image,this.state.image.name )
    }
    
    const dataToSend = {
      note:{
        title: user.title,
        description: user.description,
        is_private: user.type === 1 ? "public" : "private",
        course_id: '1',
        chapter_id: '1',
        note_type: 'personal'
      }
    }


    const header = {
      token: localStorage.getItem('token')
    };
  
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
  
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    this.updateNoteId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getAllNotesAPiEndPoint+`/${id}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      data
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      'PUT'
    );
  
    runEngine.sendMessage(requestMessage.id, requestMessage)
    return requestMessage.messageId;
    
  }

  txtInputWebProps = {
    onChangeText: (text: string) => {
      this.setState({ txtInputValue: text });
    },
    secureTextEntry: false,
  };

  txtInputMobileProps = {
    ...this.txtInputWebProps,
    autoCompleteType: "email",
    keyboardType: "email-address",
  };

  txtInputProps = this.isPlatformWeb()
    ? this.txtInputWebProps
    : this.txtInputMobileProps;

  btnShowHideProps = {
    onPress: () => {
      this.setState({ enableField: !this.state.enableField });
      this.txtInputProps.secureTextEntry = !this.state.enableField;
      this.btnShowHideImageProps.source = this.txtInputProps.secureTextEntry
        ? imgPasswordVisible
        : imgPasswordInVisible;
    },
  };

  btnShowHideImageProps = {
    source: this.txtInputProps.secureTextEntry
      ? imgPasswordVisible
      : imgPasswordInVisible,
  };

  btnExampleProps = {
    onPress: () => this.doButtonPressed(),
  };

  doButtonPressed() {
    let msg = new Message(getName(MessageEnum.AccoutLoginSuccess));
    msg.addData(
      getName(MessageEnum.AuthTokenDataMessage),
      this.state.txtInputValue
    );
    this.send(msg);
  }

  // web events
  setInputValue = (text: string) => {
    this.setState({ txtInputValue: text });
  };

  setEnableField = () => {
    this.setState({ enableField: !this.state.enableField });
  };

  // Customizable Area Start
  
  // handlechange(event:any) {
  //   this.setState({
  //         settype:event.target.value
  //      })
  //     }

  // handleAddNoteOpenModal() {
  //   this.setState({
  //       isModalOpen:true
  //   })
  // }
  // handleAddNoteCloseModal() {
  //   this.setState({
  //       isModalOpen:false
  //   })
  // }
  // Customizable Area End
}
