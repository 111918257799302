import React from 'react';
// Customizable Area Start
import { View, Text, StyleSheet,ScrollView, Modal, Image, TouchableOpacity, FlatList } from 'react-native';
import CheckBox from 'react-native-check-box';
import Scale from '../../../../components/src/Scale';
import * as IMG_CONST from '../assets'

interface Iprops {
    filterData?: any,
    filterPaymentData?: any,
    filterMarginTop?: string,
    showFilter?: boolean,
    tapToCloseFilter: () => void,
    onCoursesOnlySelected: () => void,
    onProgramsOnlySelected: () => void,
    onNewlyAddedSelected: () => void,
    checked: boolean,
    onFilterItemSelected: (value: any) => void,
    onFilterPaymentItemSelected: (value: any) => void,
    onApplyFilterPressed: () => void,
    onTapToggleCategories: () => void,
    onTapTogglePayment: () => void,
    categoryToggle?: boolean,
    paymentToggle?: boolean,
    coursesOnly?: any,
    programsOnly?: any,
    isNewlyAdded?: any,

}
// Customizable Area End

 // Customizable Area Start

 // Customizable Area End
export default function Filter(props: Iprops) {
    // Customizable Area Start
    return (
        <TouchableOpacity activeOpacity={1} onPress={() => { props.tapToCloseFilter() }}>
            <Modal
                visible={props.showFilter}
                transparent >
                <TouchableOpacity
                    activeOpacity={1}
                    onPress={() => { props.tapToCloseFilter() }}>

                    <View style={[styles.container, { marginEnd: "7%", borderRadius: Scale(8), paddingLeft: Scale(5), marginTop: props.filterMarginTop }]}>
                        <View>
                            <Text style={{ fontFamily: 'Montserrat-Bold', marginStart: Scale(10), marginTop: Scale(10), fontSize: Scale(16) }}>Filter Courses</Text>

                            <Text style={{ marginStart: Scale(10), marginTop: Scale(10), fontSize: Scale(12), color: '#3B3B3B', fontFamily: 'Montserrat-Medium', paddingBottom: Scale(10) }}>Show courses according{'\n'}to the following selected{'\n'}options</Text>
                        </View>

                        <View style={styles.separator} />
                        <TouchableOpacity onPress={() => props.onTapToggleCategories()} style={{ paddingHorizontal: Scale(10), flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between', paddingVertical: Scale(10) }}>
                            <Text style={{ fontFamily: 'Montserrat-Bold', fontSize: Scale(12) }}>Categories</Text>
                            <Image resizeMode='contain' source={IMG_CONST.UpArrow} style={{ height: Scale(9), width: Scale(14), marginRight: Scale(6) }} />
                        </TouchableOpacity>

                        {props?.categoryToggle ?
                            <>
                                <View style={{ height: 155 }} >
                                    <ScrollView>
                                        <FlatList
                                            showsVerticalScrollIndicator={false}
                                            data={props.filterData}
                                            style={{ paddingBottom: Scale(15) }}
                                            contentContainerStyle={{}}
                                            scrollEnabled={false}
                                            renderItem={({ item }: { item: any }) => {
                                                
                                                return <TouchableOpacity
                                                    onPress={() => props.onFilterItemSelected(item?.id)}
                                                    style={{ flexDirection: 'row', justifyContent: 'center', paddingHorizontal: Scale(10), paddingVertical: Scale(3) }}>
                                                    <Text style={{ alignSelf: 'center', flex: 1, fontFamily: 'Montserrat-Medium', color: '#282828', fontSize: Scale(12) }}>{item?.attributes?.name}</Text>
                                                    <CheckBox
                                                        checkBoxColor={item?.isChecked ? '#702490' : '#C1C1C1'}
                                                        onClick={() => {
                                                            props.onFilterItemSelected(item?.id);
                                                        }}
                                                        isChecked={item?.isChecked}
                                                    />
                                                </TouchableOpacity>
                                            }}
                                        />
                                    </ScrollView>
                                </View>
                            </>
                            : null}
                        <View style={styles.separator} />
                        <TouchableOpacity
                            onPress={() => props.onTapTogglePayment()}
                            style={{ paddingHorizontal: Scale(10), flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between', paddingVertical: Scale(10), }}>
                            <Text style={{ fontFamily: 'Montserrat-Bold', fontSize: Scale(12) }}>Payment Method</Text>
                            <Image resizeMode='contain' source={IMG_CONST.UpArrow} style={{ height: Scale(9), width: Scale(14), marginRight: Scale(6) }} />
                        </TouchableOpacity>
                        {props.paymentToggle ?
                            <View style={{ height: Scale(100) }}>
                                <FlatList
                                    showsVerticalScrollIndicator={false}
                                    data={props.filterPaymentData}
                                    style={{ paddingBottom: Scale(15) }}
                                    contentContainerStyle={{}}
                                    renderItem={({ item }: { item: any }) => {
                                        return <TouchableOpacity
                                            onPress={() => props.onFilterPaymentItemSelected(item?.id)}
                                            style={{ flexDirection: 'row', justifyContent: 'center', paddingHorizontal: Scale(10), paddingVertical: Scale(3) }}>
                                            <Text style={{ alignSelf: 'center', flex: 1, fontFamily: 'Montserrat-Medium', color: '#282828', fontSize: Scale(12) }}>{item?.name}</Text>
                                            <CheckBox
                                                checkBoxColor={item?.isChecked ? '#702490' : '#C1C1C1'}
                                                onClick={() => {
                                                    props.onFilterPaymentItemSelected(item?.id);
                                                }}
                                                isChecked={item?.isChecked}
                                            />
                                        </TouchableOpacity>
                                    }}
                                /></View> : null}
                        <View style={styles.separator} />
                        <TouchableOpacity onPress={() => props.onCoursesOnlySelected()} style={{ paddingHorizontal: Scale(10), flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between', paddingVertical: Scale(6) }}>
                            <Text style={{ fontFamily: 'Montserrat-Bold', fontSize: Scale(12) }}>Courses Only</Text>
                            <CheckBox
                                checkBoxColor={props.coursesOnly ? '#702490' : '#C1C1C1'}
                                onClick={() => {
                                    props.onCoursesOnlySelected()
                                }}
                                isChecked={props.coursesOnly}
                            />
                        </TouchableOpacity>
                        <View style={styles.separator} />
                        <TouchableOpacity onPress={() => props.onNewlyAddedSelected()} style={{ paddingHorizontal: Scale(10), flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between', paddingVertical: Scale(6) }}>
                            <Text style={{ fontFamily: 'Montserrat-Bold', fontSize: Scale(12) }}>Newly Added Courses</Text>
                            <CheckBox
                              checkBoxColor={props.isNewlyAdded ? '#702490' : '#C1C1C1'}
                                onClick={() => {
                                    props.onNewlyAddedSelected()
                                }}
                                isChecked={props.isNewlyAdded}
                            />
                        </TouchableOpacity>
                        <View style={styles.separator} />
                        <TouchableOpacity onPress={() => props.onProgramsOnlySelected()} style={{ paddingHorizontal: Scale(10), flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between', paddingTop: Scale(6) }}>
                            <Text style={{ fontFamily: 'Montserrat-Bold', fontSize: Scale(12) }}>Programs Only</Text>
                            <CheckBox
                                checkBoxColor={props.programsOnly ? '#702490' : '#C1C1C1'}
                                onClick={() => {
                                    props.onProgramsOnlySelected()
                                }}
                                isChecked={props.programsOnly}
                            />
                        </TouchableOpacity>

                        <TouchableOpacity onPress={() => props.onApplyFilterPressed()} style={{ justifyContent: 'center', alignSelf: 'center', borderColor: '#6C328B', borderWidth: Scale(1), width: Scale(160), alignItems: 'center', borderRadius: Scale(7), marginVertical: Scale(10) }}>
                            <Text style={{ color: '#702490', fontSize: Scale(15), fontFamily: 'Montserrat-Bold', paddingVertical: Scale(5) }}>Apply</Text>
                        </TouchableOpacity>

                    </View>

                </TouchableOpacity>
            </Modal >
        </TouchableOpacity >
    );
    // Customizable Area End
}

const styles = StyleSheet.create({
   // Customizable Area Start
    container: {
        width: Scale(190),
        backgroundColor: '#fff',
        alignSelf: 'flex-end',
        marginEnd: '15%',
        shadowColor: "#000",
        shadowOffset: {
            width: 0,
            height: 3,
        },
        shadowOpacity: 0.45,
        shadowRadius: 3.90,
        elevation: 5,

    },
    separator: {
        width: '90%',
        alignSelf: 'center',
        borderColor: '#E2E2E2',
        borderBottomWidth: Scale(1),
        marginStart: Scale(15),
        marginEnd: Scale(10),
    }

        // Customizable Area End
})
