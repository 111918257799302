import React, { useState, useEffect } from "react";
import { Prompt } from "react-router-dom";
import WarningDialog from "../../../components/src/WarningDialog";

interface IProps {
  when: any;
  navigate: any;
  handleBulkActions: any;
  shouldBlockNavigation: any;
}

export default function RouteLeavingGuard({
  when,
  navigate,
  shouldBlockNavigation,
  handleBulkActions,
}: IProps) {
  const [open, setOpen] = useState(false);
  const [location, setLocation] = useState<any>(null);
  const [confirmedNavigation, setConfirmedNavigation] = useState(false);

  const handleNavigation = (nextLocation: any) => {
    // Show dialog if user wants to visit a new URL but didn't press OK
    if (!confirmedNavigation && shouldBlockNavigation(nextLocation)) {
      setOpen(true);
      setLocation(nextLocation);
      return false;
    }
    // Let the navigation happen
    return true;
  };

  // Handler for OK button
  const handleConfirmation = () => {
    const questionIdsString = localStorage.getItem("question_ids");
    const questionIds = questionIdsString ? JSON.parse(questionIdsString) : [];
    setConfirmedNavigation(true);
    handleBulkActions(questionIds, "delete");
  };

  const handleClose = () => {
    setOpen(false);
  };

  useEffect(() => {
    if (confirmedNavigation) {
      const { pathname } = location;
      navigate(pathname);
      setOpen(false);
    }
  }, [confirmedNavigation]);

  return (
    <>
      <Prompt when={when} message={handleNavigation} />
      <WarningDialog
        open={open}
        onCancelClick={handleClose}
        onOkClick={handleConfirmation}
        title="Are you sure?"
        description="Changes have been made but not saved. Do you wish to continue without
        saving?"
      />
    </>
  );
}
