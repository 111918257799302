// Customizable Area Start
import React from "react";
import {
  Modal,
  Box,
  Button,
  Grid,
  Typography,
  TextField,
  Divider,
  CircularProgress
} from "@material-ui/core";
import Checkbox from '@material-ui/core/Checkbox';
import Radio from '@material-ui/core/Radio';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Backdrop from "@material-ui/core/Backdrop";
import Fade from "@material-ui/core/Fade";
// @ts-ignore
import bgImage from "../assets/saveCard.png"
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import ShoppingCartModal from "./ShoppingCartModal.web";
import { Formik } from "formik";
import * as yup from "yup";
// @ts-ignore
import { loadStripe } from '@stripe/stripe-js';
// @ts-ignore
import { Elements, PaymentElement, useStripe, useElements } from '@stripe/react-stripe-js';
import SubscriptionController, {
  Props,
} from "./SubscriptionController.web";
import './ShoppingCart.css'

export default class Subscription extends SubscriptionController {
  constructor(props: Props) {
    super(props);

  }


  render() {
    const { paymentMethod, proceedModal, transitionMessage } = this.state


    const stripePromise = loadStripe('pk_test_51KKE5OAXxoQjvSEanjEDNxDYhiY4ow2BZLnFBXevkloZHd4tPXhxaApAixxe7G2xbGpBLD5RxH84IgN3j6MMDfBX00u0fBcBIM');
    console.log(this.props.location, "id");
    console.log(this.props.location?.state?.name?.price, this.props.location?.state?.name?.attributes?.price, 'id');


    return (
      <div>
        <Box style={{ width: "505px", display: 'flex', alignItems: "center", justifyContent: "space-between" }}>
          <ArrowBackIosIcon style={{ fontSize: '35px', cursor: 'pointer' }} onClick={() => this.props.navigation.goBack()} />
          <Typography style={styles.test2}>Buy Subscription</Typography>
        </Box>
        <Grid container style={{ justifyContent: 'space-between', marginTop: '40px' }}>
          <Grid item md={5} xs={12} style={{ margin: '0 auto' }}>
            <Box style={styles.paymentBox}>
              <Typography style={styles.paymentH1}>Saved Cards</Typography>
              {/* @ts-ignore */}
              <Grid style={styles.savedCardList}>
                {
                  [...Array(2)].map((_x, index) =>
                    <Box key={index} style={styles.savedCards}>
                      {/* @ts-ignore */}
                      <Typography style={styles.cardNo}>1110 xxxx 2345</Typography>
                      {/* @ts-ignore */}
                      <Typography style={styles.cardDate}>Valid thru 03/29</Typography>

                    </Box>
                  )
                }
              </Grid>
              <Typography style={styles.paymentH1}>Payment Methods</Typography>
              {/* @ts-ignore */}
              <RadioGroup style={styles.radioGrp} aria-label="method" name="paymentMethod" value={paymentMethod} onChange={(e: any) => this.handlePaymentMethod(e)}>
                <FormControlLabel style={styles.radioH1} value="debit" control={<Radio style={{ color: '#6c328b' }} />} label="Debit/Credit Card" />
                <FormControlLabel style={styles.radioH1} value="stripe" control={<Radio style={{ color: '#6c328b' }} />} label="Stripe Payments" />
              </RadioGroup>
              <Typography style={styles.cardDeatailsH1}>Enter card details</Typography>

              {
                paymentMethod == 'debit' ? <>

                  <Formik
                    initialValues={
                      {
                        cardNumber: '',
                        cardName: '',
                        MM: '',
                        YYYY: "",
                        CVV: "",
                        rememberCard: false
                      }
                    }

                    onSubmit={(values, { resetForm }) => {
                      console.log(values, 'form data test');
                      this.debitCardPayment(values);
                      // resetForm();
                      // alert(
                      //   "Successfull",

                      // );

                    }}/*  */
                    validationSchema={yup.object().shape({
                      cardNumber: yup.string().required('Title is required')
                        .max(16, 'Must be exactly 16 digits'),
                      cardName: yup.string()
                        .required('Card Name is required'),
                      YYYY: yup.string().required('Year is required')
                        .min(4, 'Must be exactly 4 characters')
                        .max(4, 'Must be exactly 4 characters'),
                      CVV: yup.string().required('CVC is required')
                        .min(3, `Your card's security code is incomplete`)
                        .max(4, 'Must be exactly 4 characters'),
                      MM: yup
                        .number()
                        .required('Month is required')

                        .min(2, 'Must be exactly 2 characters')
                        // .max(2,'Must be exactly 2 characters')
                        .lessThan(13, "Please enter a valid number"),
                    })}
                  >
                    {props => {
                      const {
                        values,
                        touched,
                        errors,
                        handleChange,
                        handleSubmit
                      } = props;
                      return (
                        <form className="cartForm" onSubmit={handleSubmit}>
                          <Box>
                            <TextField style={styles.cardsInput} id="standard-basic" label="Card number"
                              name="cardNumber"
                              value={values.cardNumber}
                              onChange={handleChange}
                              error={touched.cardNumber && Boolean(errors.cardNumber)}
                              helperText={touched.cardNumber && errors.cardNumber}
                              variant="standard"
                              InputLabelProps={{ style: { paddingLeft: '10px', } }}
                              onKeyPress={(e) => {
                                if (!/\d/.test(e.key)) {
                                  return e.preventDefault()
                                }
                              }}
                            />
                            <TextField style={styles.cardsInput} id="standard-basic" label="Name on card"
                              name="cardName"
                              value={values.cardName}
                              onChange={handleChange}
                              error={touched.cardName && Boolean(errors.cardName)}
                              helperText={touched.cardName && errors.cardName}
                              InputLabelProps={{ style: { paddingLeft: '10px', } }}
                              onKeyPress={(e) => {
                                if (!/[a-zA-Z]/.test(e.key)) {
                                  return e.preventDefault()
                                }
                              }
                              }
                            />

                            <Grid style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                              <TextField style={styles.cardsInput} id="standard-basic" label="MM"
                                name="MM"
                                value={values.MM}
                                onChange={handleChange}
                                error={touched.MM && Boolean(errors.MM)}
                                helperText={touched.MM && errors.MM}
                                InputLabelProps={{ style: { paddingLeft: '10px', } }}
                                onKeyPress={(e) => {

                                  if (!/\d/.test(e.key)) {
                                    return e.preventDefault()
                                  }
                                }
                                }
                              />

                              <Typography style={{ margin: '30px 20px 0 20px', fontSize: '16px', fontWeight: 400 }}>/</Typography>
                              <TextField style={styles.cardsInput} id="standard-basic" label="YYYY"
                                name="YYYY"
                                value={values.YYYY}
                                onChange={handleChange}
                                error={touched.YYYY && Boolean(errors.YYYY)}
                                helperText={touched.YYYY && errors.YYYY}
                                InputLabelProps={{ style: { paddingLeft: '10px', } }}
                                onKeyPress={(e) => {
                                  if (!/\d/.test(e.key)) {
                                    return e.preventDefault()
                                  }
                                }
                                }
                              />
                            </Grid>
                            <TextField style={styles.cardsInputCVV} id="standard-basic" label="CVV"
                              name="CVV"
                              value={values.CVV}
                              onChange={handleChange}
                              error={touched.CVV && Boolean(errors.CVV)}
                              helperText={touched.CVV && errors.CVV}
                              InputLabelProps={{ style: { paddingLeft: '10px', } }}
                              onKeyPress={(e) => {
                                if (!/\d/.test(e.key)) {
                                  return e.preventDefault()
                                }
                              }
                              }
                            />
                          </Box>
                          <FormControlLabel
                            control={<Checkbox
                              style={{ color: '#6c328b' }}
                              // @ts-ignore
                              name="rememberCard"
                              onChange={handleChange}
                            />}
                            label="Remember this card"
                          />
                          <Divider style={{ color: '#000' }} />
                          {/* @ts-ignore */}
                          <Box style={styles.orderAmnTotal}>
                            <Typography style={styles.ordertext}>Order Total</Typography>
                            <Typography style={styles.ordertextNo}>$ {this.props.location?.state?.name?.attributes?.price}</Typography>
                          </Box>
                          {this.state.transitionError ? <Typography style={{ color: 'red', fontSize: '22px' }}>{this.state.transitionError}</Typography> : <></>}
                          {/* @ts-ignore */}
                          <Button style={styles.payNowBtn} type='submit' variant="contained">Pay Now</Button>
                        </form>
                      )
                    }}
                  </Formik>
                </>
                  : <>
                    {
                      this.state.stripeLoader ?
                        <Box style={{ display: "flex", height: "35%", width: "100%", justifyContent: "center", alignItems: "center" }} > <CircularProgress /> </Box>
                        :
                        <>
                        </>
                        // <WebView
                        //   source={{ uri: this.state.stripeUrl }}
                        //   onMessage={this.onMessage}
                        // />
                    }
                    {/* <Elements stripe={stripePromise} options={{
                  clientSecret: 'pi_3M7G9wAXxoQjvSEa0EMMjPXH_secret_Vk4o7B6pND6Vsrut3JUnRhA05',
                  appearance:{
                    theme: 'stripe',
                    labels:'floating',
                    variables:{
                      borderRadius:'8px'
                    }
                  }
                }}>
                      <CheckoutForm />
                </Elements> */}
                  </>
              }

            </Box>
          </Grid>
        </Grid>

        <Modal
          aria-labelledby="transition-modal-title"
          aria-describedby="transition-modal-description"
          className="modalStyle"
          // @ts-ignore
          open={proceedModal}
          // @ts-ignore
          onClose={this.handleProceedModalClose}
          closeAfterTransition
          BackdropComponent={Backdrop}
          BackdropProps={{
            timeout: 500,
          }}
        >

          <Fade in={proceedModal}>
            <ShoppingCartModal navigation={undefined} id={""} handleClose={() => this.handleProceedModalClose()} message={transitionMessage} handleBackHome={() => this.handleBackHome()} handleBackPersonal={() => this.handleBackPersonal()} history={undefined} handleStripeFailure={undefined} handleStripeSuccess={undefined} />
          </Fade>
        </Modal>
      </div>
    )

  }
}

const styles = {
  savedCardList: { margin: "20px 0 40px 0", display: 'flex', flexDirection: 'row', overflowX: 'auto', overflowY: 'hidden' },
  orderAmnTotal: { display: 'flex', flexDirection: 'row', justifyContent: 'space-between', margin: '25px 0', width: '100%' },
  secCard: {
    margin: '20px 0',
    padding: '30px',
    borderRadius: '16px',
    border: "1px solid grey",
    background: "#ffffff",
    backgroundBlendMode: "normal",
    boxShadow: "0px 0px 16px rgba(213,213,213,0.35)"
  },
  cardsInput: {
    width: '100%',
    margin: '10px 0',
    // paddingLeft:'15px'
  },

  cartCheckBox: {
    display: 'flex',
    flexDirection: 'column',

  },
  cardsInputCVV: {
    width: '40%',
    margin: '10px 0'
  },
  ordertext: {
    fontSize: '18px',
    fontWeight: 400,
    lineHeight: '22px'
  },
  ordertext1: {
    fontSize: '18px',
    fontWeight: 700,
    lineHeight: '22px',
  },
  cardDeatailsH1: {
    fontSize: '16px',
    fontWeight: 700
  },
  payNowBtn: {
    width: '100%',
    height: '64px',
    borderRadius: '16px',
    backgroundColor: '#6c328b',
    color: '#fff',
    fontSize: '15px',
    fontWeight: 700,
    lineHeight: '24px',
    textTransform: 'none',
    marginTop: '15px'
  },
  payAbleBtn: {
    width: '100%',
    height: '64px',
    borderRadius: '16px',
    backgroundColor: 'transparent',
    color: 'rgb(108, 50, 139)',
    fontSize: '15px',
    fontWeight: 700,
    lineHeight: '24px',
    textTransform: 'none',
    padding: '0 30px',
    marginBottom: '25px',
    border: '1px solid rgb(108, 50, 139)'
  },
  ordertextNo: {
    fontSize: '22px',
    fontWeight: 700,
    lineHeight: '28px'
  },
  ordertextNo2: {
    textAlign: 'end',
    fontSize: '22px',
    fontWeight: 700,
    lineHeight: '28px'
  },
  savedeleteh: {
    color: "#1a1a1a",
    paddingTop: '8px',
    fontSize: "16px",
    fontWeight: 600,
    lineHeight: "16px",
    cursor: 'pointer'
  },
  radioH1: {
    fontSize: '16px',
    fontWeight: 400,
    lineHeight: '22px'
  },
  radioGrp: {
    margin: '20px 0'
  },
  cardNo: {
    textAlign: 'center',
    margin: '45px 0 0 10px',
    color: 'white'
  },
  cardDate: {
    textAlign: 'center',
    margin: '35px 25px 0 0',
    color: 'white'
  },
  bankname: {
    color: 'white',
    fontSize: '12px',
    textAlign: 'right'
  },
  savedCards: {
    flex: '0 0 213px',
    height: '180px',
    width: '300px',
    margin: ' 0 5px',
    backgroundImage: `url(${bgImage})`,
    backgroundRepeat: 'no-repeat',
    borderRadius: '12px',
    // padding: '10px 15px'
  },
  paymentH1: {
    fontSize: '24px',
    fontWeight: 700,
    lineHeight: '24px'
  },
  paymentBox: {
    borderRadius: '16px',
    backgroundColor: '#f6f6f6',
    padding: '15px'
  },
  paymentBoxInner: {
    padding: '30px'
  },
  saveDeleterow: {
    display: 'flex',
    flexDirection: 'row',
    margin: 'auto'
  },
  test2: {
    color: "#11142d",
    // fontFamily: "Poppins",
    fontSize: "48px",
    fontWeight: 600,
    letterSpacing: "-1px"
  },
  heading: {
    color: '#11142d',
    // fontFamily: 'Poppins',
    fontSize: "48px !important",
    fontWeight: 600,
    letterSpacing: "-1px",
  },
  coursePrice: {
    color: "#545650",
    // fontFamily: "Poppins",
    fontSize: "28px",
    fontWeight: 700,
    lineHeight: "36px",
    padding: '7px'
  },
  authorName: {
    color: "#797979",
    // fontFamily: "Poppins",
    fontSize: "15px",
    fontWeight: 400,
    padding: '2px 0',
  },
  courseName: {
    color: "#1a1a1a",
    padding: '2px 0',
    // fontFamily: "Poppins",
    fontSize: "18px",
    fontWeight: 700,
    lineHeight: "20px",
  },
  cartsGrid: {
    margin: "20px 0",
    display: "flex",
    flexDirection: "row",
  },
  cartImage: {
    backgroundColor: "#11142d",
    height: "35px",
    width: "35px",
    bordeRadius: "12px",
  },
  textgrid: {
    display: "flex",
    flexDirection: "column",
    margin: '0 30px'
  },
  cardH1: {
    color: "#6c328b",
    // fontFamily: "Poppins",
    fontSize: "20px",
    fontWeight: 700,
    lineHeight: "20px",
    padding: '10px 0'
  }
}

function CheckoutForm() {
  const stripe = useStripe();
  const elements = useElements();

  const handleSubmit = async (event: any) => {
    event.preventDefault();

    if (!stripe || !elements) {
      return;
    }
    stripe.confirmPayment({
      //`Elements` instance that was used to create the Payment Element
      elements,
      redirect: 'if_required'
    }).then((result: any) => {
      console.log('result', result)
    }).catch((err: any) => {
      console.log('err', err);

    })
  };

  return (
    <form onSubmit={handleSubmit} >
      {/* @ts-ignore */}
      {/* <label>
          Card number
          <CardNumberElement
            options={CARD_OPTIONS}
          />
        </label>
        <label>
          Expiration date
          <CardExpiryElement
            options={CARD_OPTIONS}
          />
        </label>
        <label>
          CVC
          <CardCvcElement
            options={CARD_OPTIONS}
          />
        </label> */}
      <PaymentElement />
      {/* @ts-ignore */}
      <Button style={styles.payNowBtn} type="submit" disabled={!stripe || !elements}>
        Pay
      </Button>
    </form>
  );
};