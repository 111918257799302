Object.defineProperty(exports, '__esModule', {
  value: true
})

// Customizable Area Start
exports.apiContentType = "application/json";
exports.getApiMethod = "GET";
exports.postApiMethod = "POST";
exports.deleteApiMethod = "DELETE";

exports.getOrdersApiEndPoint = "shopping_cart/orders";
exports.createOrderItemApiEndPoint = "shopping_cart/order_items";
exports.deleteOrderItemApiEndPoint = "shopping_cart/orders";
exports.addDebitPaymentApiEndPoint =
  "/bx_block_stripegatewayapifrontend/stripe";
exports.addSubscriptionPaymentApiEndPoint =
  "/bx_block_library2/course_subscriptions";
exports.getStripSubscriptionApiEndPoint =
  "/bx_block_stripegatewayapifrontend/stripe_subscription";
exports.getStripApiEndPoint =
  "/bx_block_stripegatewayapifrontend/stripe/create_order";
exports.getLastOrderApiEndPoint = "bx_block_shopping_cart/cart/last_order";
exports.getLastOrderStripeSubscriptionApiEndPoint = "bx_block_stripegatewayapifrontend/stripe_subscription/hstry_data";

exports.errorTitle = "Error";
exports.errorAllFieldsAreMandatory = "All fields are mandatory.";

// Recommended Course End Point
exports.getRecommendedCourseAPiEndPoint = "/bx_block_library2/user_courses";
exports.courseEnrollAPiEndPoint = "bx_block_library2/enroll_courses";
exports.getCartDataApiEndPoint = "bx_block_shopping_cart/cart";
exports.removeCartDataApiEndPoint = "/bx_block_shopping_cart/cart/remove_item";
exports.getSaveCardDataApiEndPoint =
  "/bx_block_stripegatewayapifrontend/stripe";
// Customizable Area End
