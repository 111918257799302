import React from "react";
import { createStyles, makeStyles } from "@material-ui/core/styles";
import {
  ReminderIcon,
  SelfEnrollmentIcon,
  RewardsBg,
  AchievementsBg,
  RewardAdminIcon,
  AchievementAdminIcon
} from "../assets";
import Title from "./Title.web";
import Description from "./Description.web";
import { OrangeButton, OutlinedButton } from "./Buttons.web";
import { genCourseOrProgramUrl } from "../../../../framework/src/Helpers.web";

import {
  NEW_COURSE_ENROLL,
  EARNED_REWARD,
  EARNED_ACHIEVEMENT,
  EARNED_REWARD_ADMIN,
  EARNED_ACHIEVEMENT_ADMIN,
} from "./reminderTypes";

interface IIcon {
  type: string;
}

function Icon({ type }: IIcon) {
  let src;
  switch (type) {
    case NEW_COURSE_ENROLL:
      src = SelfEnrollmentIcon;
      break;
    case EARNED_REWARD_ADMIN:
      src = RewardAdminIcon;
      break;
    case EARNED_ACHIEVEMENT_ADMIN:
      src = AchievementAdminIcon
      break;
    default:
      src = ReminderIcon
      break;
  }

  return <img src={src} alt={type} />
}

function useClassNamesForContainer(popup_type: string) {
  const classes = useStyles();
  if (popup_type === EARNED_REWARD) {
    return `${classes.cardContainer} ${classes.rewardsBg}`;
  } else if (popup_type === EARNED_ACHIEVEMENT) {
    return `${classes.cardContainer} ${classes.achievementsBg}`;
  }
  return classes.cardContainer;
}

interface IReminderEssentials {
  courseable_id: number;
  courseable_type: string;
  popup_type: string;
}

interface ICard {
  handleDismiss: (reminder: IReminderEssentials) => void;
  popup_type: string;
  title: string;
  due_in: number;
  courseable_id: number;
  courseable_type: string;
  isDismissing: boolean;
  name?: string;
  earned_date?: string;
  user_name?: string;
  is_multiple_course?: boolean;
}

export default function Card({
  handleDismiss,
  popup_type,
  title,
  due_in,
  courseable_id,
  courseable_type,
  isDismissing,
  name,
  earned_date,
  user_name,
  is_multiple_course,
}: ICard) {
  const containerClass = useClassNamesForContainer(popup_type);

  const courseOrProgramUrl = genCourseOrProgramUrl(courseable_type, courseable_id, title);

  const reminderEssentials = {
    courseable_id,
    courseable_type,
    popup_type,
  };

  const handleOutlinedBtnClick = () => {
    handleDismiss(reminderEssentials);
  };

  return (
    <div className={containerClass}>
      <Icon type={popup_type} />
      <Title
        type={popup_type}
        courseOrProgramName={title}
        dueIn={due_in}
        rewardOrAchieventName={name}
        userName={user_name}
      />
      <Description
        type={popup_type}
        due_in={due_in}
        earned_date={earned_date}
        courseOrProgramName={title}
        rewardOrAchieventName={name}
        is_multiple_course={is_multiple_course}
        courseOrProgramUrl={courseOrProgramUrl}
      />
      <OrangeButton type={popup_type} url={courseOrProgramUrl} />
      <OutlinedButton
        isLoading={isDismissing}
        onClick={handleOutlinedBtnClick}
      />
    </div>
  );
}

const useStyles = makeStyles((theme) =>
  createStyles({
    cardContainer: {
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "center",
    },
    rewardsBg: {
      background: `no-repeat url(${RewardsBg})`,
      backgroundPosition: "top",
      backgroundSize: "50%",
    },
    achievementsBg: {
      background: `no-repeat url(${AchievementsBg})`,
      backgroundPosition: "top",
      backgroundSize: "50%",
    },
  })
);