/**
 * Generate course or program url using
 * course_type
 * course or program id
 * & course or program name
 */

export function genCourseOrProgramUrl(
  course_type: string,
  id: number,
  name = 'title'
) {
  if (course_type === 'BxBlockCoursecreation::Program') {
    return `/program-detail/Program/${id}/${name.replace(/\s/g, '-')}`;
  } else if (course_type === 'BxBlockCoursecreation::Course') {
    return `/course-detail/Course/${id}/${name.replace(/\s/g, '-')}`;
  }
  return '';
}

/**
 * Capitalize the first letter of a sentence
 */
export function capitalizeFirstLetter(string: string) {
  return string.charAt(0).toUpperCase() + string.slice(1);
}

/**
 * Format durations in seconds i.e. 4000 into "1 hr 6 min 40 sec" 
 */
export function formatDurationToString(seconds: number) {
  const hours = Math.floor(seconds / 3600);
  const minutes = Math.floor((seconds % 3600) / 60);
  const remainingSeconds = seconds % 60;
  let durationString = '';

  if (hours > 0) {
    durationString += hours + ' hr ';
  }

  if (minutes > 0) {
    durationString += minutes + ' min ';
  }

  if (remainingSeconds > 0) {
    durationString += remainingSeconds + ' sec';
  }

  return durationString.trim();
}