// Customizable Area Start
import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import { toast } from "react-toastify";
import { apiCall } from "../../../framework/src/Utilities";

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  handleClose?: any;
  message: any;
  handleBackHome: any;
  handleBackPersonal: any;
  history: any;
  handleStripeSuccess: any;
  handleStripeFailure: any;
  handleCartItemsCountInTopbar?: (count: number) => void;
}

export interface SelectedSubsCartItem {
  id: string;
  price: string;
  purchasableId: number;
  purchasableType: string;
  subscriptionPeriod: string;
}

interface S {
  txtInputValue: string;
  txtSavedValue: string;
  enableField: boolean;

  //isModalOpen:boolean;
  orderConfirmationOpen: any;
  recommendedCourseData: any;
  recommendedLoader: any;
  proceedModal: any;
  cartType: string;
  cartData: any;
  subscriptionCartData: any;
  cartDataLoader: any;
  selectedOrder: any;
  saveOrder: any;
  payableAmount: number;
  paymentMethod: any;
  message: any;
  transitionError: any;
  stripeKey: any;
  removedCartItemId: string;
  orderSuccessMsg: string;
  orderFailureMsg: string;
  orderItems: any;
  selectedSubsCartItem: SelectedSubsCartItem;
  lastSubscriptionOrder: any;
  orderConfirmed: boolean;
  // settype:any;
}

interface SS {
  id: any;
}

export default class ShoppingCartController extends BlockComponent<
  Props,
  S,
  SS
> {
  getRecommendedCourseID: string = "";
  getCartDataID: string = "";
  removeCartDataID: string = "";
  addCardPaymentID: string = "";
  getLastOrderDataID: string = "";
  getLastOrderDataStripeSubscriptionID: string = "";
  postAddToCartId: string = "";
  postCourseEnrollmentId: string = "";

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),

      getName(MessageEnum.RestAPIResponceMessage),
    ];

    this.state = {
      txtInputValue: "",
      txtSavedValue: "A",
      enableField: false,

      orderConfirmationOpen: true,
      recommendedCourseData: [],
      recommendedLoader: true,
      proceedModal: false,
      cartType: "otp",
      cartData: [],
      subscriptionCartData: [],
      cartDataLoader: true,
      selectedOrder: [],
      saveOrder: false,
      payableAmount: 0,
      paymentMethod: "debit",
      message: "",
      transitionError: "",
      stripeKey: "",
      removedCartItemId: "",
      orderSuccessMsg: "",
      orderFailureMsg: "",
      orderItems: [],
      selectedSubsCartItem: {
        id: "",
        price: "",
        purchasableId: 0,
        purchasableType: "",
        subscriptionPeriod: "",
      },
      lastSubscriptionOrder: {},
      orderConfirmed: false,
      //isModalOpen:false,
      // settype:false,
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async componentDidMount() {
    this.getCartData();
    this.calculatePayableAmount();
    // Read the query string from url
    const queryParams = new URLSearchParams(window.location.search);
    const subscriptionOrderStatusStripe = queryParams.get("success");
    if (subscriptionOrderStatusStripe === "true") {
      // Initiate the process of showing order success page
      this.setState({ cartType: "subscription" });
      this.handleSubscriptionOrderSuccess("congrats");
    } else if (subscriptionOrderStatusStripe === "false") {
      // Initiate the process of showing order failure page
      this.setState({ cartType: "subscription" });
      this.handleOrderFaillure("Please try again.");
    }
    const sessionId = queryParams.get("session_id");
    if (sessionId) {
      // Get the last order info
      this.getLastOrderDataStripeSubscription(sessionId);
    }
  }

  componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<S>, snapshot?: SS | undefined): void {
    // Re calculate payable amount after selecting a new subscription cart item
    if (
      prevState.cartType !== this.state.cartType ||
      prevState.selectedSubsCartItem.id !== this.state.selectedSubsCartItem.id
      ) {
      this.setState({ payableAmount: 0 });
      this.calculatePayableAmount();
    }
    // Update cart items count in topbar after otp or subscription cart items change
    if (
      prevState.cartData.length !== this.state.cartData.length ||
      prevState.subscriptionCartData.length !==
        this.state.subscriptionCartData.length
    ) {
      this.props?.handleCartItemsCountInTopbar &&
        this.props.handleCartItemsCountInTopbar(
          this.state.cartData.length + this.state.subscriptionCartData.length
        );
    }
  }

  async receive(from: string, message: Message) {
    if (message.id === getName(MessageEnum.RestAPIResponceMessage)) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      switch (apiRequestCallId) {
        case this.getCartDataID:
          this.handleGetCartDataResponse(responseJson);
          break;
        case this.removeCartDataID:
          this.handleRemoveCartItemResponse(responseJson);
          break;
        case this.getLastOrderDataID:
          this.handleGetLastOrderDataResponse(responseJson);
          break;
        case this.getLastOrderDataStripeSubscriptionID:
          this.handleGetLastOrderDataStripeSubscription(responseJson);
          break;
        case this.postAddToCartId:
          this.handleAddToCartResponse(responseJson);
          break;
        default:
          break;
      }
    }
  }

  // Get cart data
  getCartData = async () => {
    this.getCartDataID = await apiCall({
      contentType: configJSON.apiContentType,
      method: configJSON.getApiMethod,
      endPoint: configJSON.getCartDataApiEndPoint + `?full_details=${true}`,
    });
  }

  // Handle cart data response
  handleGetCartDataResponse = (response: any) => {
    // Handle error
    if (response?.error) {
      this.setState({
        cartDataLoader: false,
      });
      toast.error("Something went wrong");
      return;
    }

    // Handle empty cart
    if (response?.message) {
      this.setState({
        cartData: [],
        subscriptionCartData: [],
        cartDataLoader: false,
        recommendedCourseData: [],
      }, () => {
        this.calculatePayableAmount();
      });
      return;
    }

    // Handle cart data
    if (response?.data) {
      const { cart_item, subscription_cart_item } = response.data.attributes;
      this.setState({
        cartData: cart_item,
        subscriptionCartData: subscription_cart_item,
        cartDataLoader: false,
        recommendedCourseData: response?.meta?.recommended_course.data ?? [],
      }, () => {
        this.calculatePayableAmount();
      });
    }
  }

  // Remove item from cart
  removeCartData = async (save: any = "", itemID: any = "") => {
    this.removeCartDataID = await apiCall({
      contentType: configJSON.apiContentType,
      method: configJSON.postApiMethod,
      endPoint: configJSON.removeCartDataApiEndPoint +
      `?order_item_id=${itemID}`,
    });
  }

  // Handle Remove cart item api response
  handleRemoveCartItemResponse = (response: any) => {
    if (response && !response.error) {
      if (this.state.cartType === "otp") {
        // Remove item from cart
        const curCartItems = [...this.state.cartData];
        const updatedCartItems = curCartItems.filter(
          (item: any) => item.id !== this.state.removedCartItemId
        );
        this.setState({
          cartDataLoader: false,
          cartData: updatedCartItems,
          removedCartItemId: "",
        }, () => this.calculatePayableAmount());
        return;
      }
      // Remove item from subscription cart
      const curSubsCartItems = [...this.state.subscriptionCartData];
      const updatedSubsCartItems = curSubsCartItems.filter(
        (item: any) => item.id !== this.state.removedCartItemId
      );
      this.setState({
        cartDataLoader: false,
        subscriptionCartData: updatedSubsCartItems,
        removedCartItemId: "",
      }, () => this.calculatePayableAmount());
    } else {
      toast.error("Something went wrong");
    }
  };

  handleConfirmation = () => {
    this.setState({
      orderConfirmationOpen: false,
    });
    console.log("open");
  };

  handleConfirmationClose = () => {
    this.setState({
      orderConfirmationOpen: true,
    });
    console.log("open");
  };

  handleProceed = () => {
    this.setState({
      proceedModal: true,
    });
  }

  handleProceedModalClose = () => {
    this.setState({
      proceedModal: false,
    });
  };

  handleStripeSuccess = () => {
    this.setState({
      message: "Congratulations",
      transitionError: "",
      orderConfirmationOpen: true,
    });

    this.handleProceed();
    this.getCartData();
  };

  handleStripeFailure = () => {
    this.setState({
      message: "OOPS!",
    });
  };

  handleRemoveItemFromCart = (id: any) => {
    this.removeCartData(this.state.saveOrder, id);
    this.setState({
      cartDataLoader: true,
      removedCartItemId: id,
    });
  };

  handleBackHome = () => {
    this.props.navigation.navigate("Dashboard");
  };

  handleBackPersonal = () => {
    this.props.history.push("/personal");
  };

  handleBackToCart = () => {
    this.props.navigation.navigate("ShoppingCartOrders");
    this.setState({
      orderSuccessMsg: "",
      orderFailureMsg: "",
    });
  }

  handlePaymentMethod = (e: any) => {
    this.setState({
      paymentMethod: e.target.value,
    });
  };

  // Calculate payable amount
  calculatePayableAmount = () => {
    const { cartType, selectedSubsCartItem } = this.state;
    if (cartType === "subscription") {
      this.setState({
        payableAmount: selectedSubsCartItem.price
          ? parseFloat(selectedSubsCartItem.price)
          : 0,
      });
      return;
    }
    // Calculate total of all otp cart items
    const { cartData } = this.state;
    const payableAmount = cartData.reduce(
      (accumulator: number, curVal: any) =>
        accumulator + parseFloat(curVal.attributes.price),
      0
    );
    this.setState({ payableAmount });
  };

  // Handle go back
  handleGoBack = () => {
    this.props.navigation.goBack();
  }

  // Handle order success
  handleOrderSuccess = (msg: string, lastSubsOrder?: any) => {
    if (this.state.cartType === "otp") {
      this.setState({
        orderSuccessMsg: msg,
        cartData: [],
      });
      return;
    }
    // Handle order success for subscription cart
    this.handleSubscriptionOrderSuccess(msg, lastSubsOrder);
  }

  // Handle order success for subscription cart
  handleSubscriptionOrderSuccess = (msg: string, lastSubsOrder?: any) => {
    const updatedSubsCartData = this.state.subscriptionCartData.filter(
      (item: any) => item.id !== this.state.selectedSubsCartItem.id
    );
    this.setState({
      orderSuccessMsg: msg,
      subscriptionCartData: updatedSubsCartData,
      lastSubscriptionOrder: lastSubsOrder ?? {},
    });
  }
  
  // Handle order failure
  handleOrderFaillure = (msg: string) => {
    this.setState({
      orderFailureMsg: msg,
    });
  }

  // Get data of the last order
  getLastOrderData = async () => {
    this.setState({ cartDataLoader: true });
    this.getLastOrderDataID = await apiCall({
      contentType: configJSON.apiContentType,
      method: configJSON.getApiMethod,
      endPoint: configJSON.getLastOrderApiEndPoint,
    });
  }

  // Handle response of last order
  handleGetLastOrderDataResponse = (response: any) => {
    if (!response.data) {
      toast.error("Something went wrong");
      this.setState({
        cartDataLoader: false,
      });
      return;
    }
    this.setState({
      cartDataLoader: false,
      orderItems: response.data.attributes.cart_item,
    });
  }

  // Get last order info for stripe subscription
  getLastOrderDataStripeSubscription = async (sessionId: string) => {
    this.setState({ cartDataLoader: true });
    this.getLastOrderDataStripeSubscriptionID = await apiCall({
      contentType: configJSON.apiContentType,
      method: configJSON.postApiMethod,
      endPoint: `${configJSON.getLastOrderStripeSubscriptionApiEndPoint}?session_id=${sessionId}`,
    });
  }

  // Handle reponse of last order stripe subscription
  handleGetLastOrderDataStripeSubscription = (response: any) => {
    if (!response?.data) {
      this.setState({ cartDataLoader: true });
      toast.error("Something went wrong");
      return;
    }
    this.getCartData();
    const { purchasable_type, course_detail, program_detail } = response.data.attributes.subscription_cart_item[0].attributes; 
    const isCourse = purchasable_type === "BxBlockCoursecreation::Course"; 
    const lastOrder = {
      expand_type: purchasable_type,
      image: isCourse ? course_detail.thumbnail_image : program_detail.thumbnail_image,
      title: isCourse ? course_detail.title : program_detail.title,
      created_by_name: isCourse ? course_detail.created_by : program_detail.created_by,
    }
    this.setState({
      cartDataLoader: false,
      lastSubscriptionOrder: lastOrder,
    })
  }

  // Handle selection of subs cart items
  handleSubsCartItemsSelection = (item: SelectedSubsCartItem) => {
    this.setState({ selectedSubsCartItem: item });
  }

  // Handle cart type change
  handleCartTypeChange = (type: string) => {
    this.setState({ cartType: type });
  }

  // Handle order cofirmaiton
  handleOrderConfirmation = () => {
    this.setState({ orderConfirmed: true });
  }

  /**
   * Methods for recommended courses
   */

  // Add item to cart
  addToCart = async (id: any, type: string) => {
    const payload =
      type == "course_cart"
        ? {
          course_id: id,
        }
        : {
          program_id: id,
        };
    this.postAddToCartId = await apiCall({
      contentType: configJSON.apiContentType,
      method: configJSON.postApiMethod,
      endPoint: configJSON.getCartDataApiEndPoint,
      payload: JSON.stringify(payload)
    });
  };

  // Handle add to cart response
  handleAddToCartResponse = (response: any) => {
    if (response?.errors) {
      toast.error("Something went wrong");
      return;
    }

    if (response.message) {
      toast.warning('Item already in cart');
    } else {
      toast.success('Item has been added to your cart');
      this.getCartData();
    }
  }

  enrollToCourses = async (id: any) => {
    const payload = {
      "courseable_id": id,
      "courseable_type": "BxBlockCoursecreation::Course"
    }
    this.postCourseEnrollmentId = await apiCall({
      contentType: configJSON.apiContentType,
      method: configJSON.postApiMethod,
      endPoint: configJSON.courseEnrollAPiEndPoint,
      payload: JSON.stringify(payload)
    });
  }
}
// Customizable Area End