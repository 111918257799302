import React from "react";
import { Typography, Box, IconButton } from "@material-ui/core";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import SelectDropdown from "./SelectDropdown.web";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";

interface Props {
  editQuiz: boolean;
  values: any;
  handleChange: any;
  handleGoBack: any;
}

const OPTIONS = [
  { id: 1, label: "Free Trial", value: 0 },
  { id: 2, label: "Phase 1", value: 1 },
  { id: 3, label: "Phase 2", value: 2 },
  { id: 4, label: "Phase 3", value: 3 },
  { id: 5, label: "Phase 4", value: 4 },
  { id: 6, label: "Phase 5", value: 5 },
  { id: 7, label: "Phase 6", value: 6 },
  { id: 8, label: "Phase 7", value: 7 },
  { id: 9, label: "Phase 8", value: 8 },
  { id: 10, label: "Phase 9", value: 9 },
  { id: 11, label: "Phase 10", value: 10 },
];

export default function QuizTitle({
  editQuiz,
  values,
  handleChange,
  handleGoBack,
}: Props) {
  const classes = useStyles();
  return (
    <Box className={classes.phaseContainer}>
      <Box className={classes.pageTitleContainer}>
        <IconButton onClick={handleGoBack}>
          <ChevronLeftIcon fontSize="large" style={{ color: "#92929D" }} />
        </IconButton>
        <Typography className={classes.title}>
          {editQuiz ? "edit quiz" : "create new quiz"}
        </Typography>
      </Box>
      <SelectDropdown
        options={OPTIONS}
        name="phase"
        value={values.phase}
        handleChange={handleChange}
      />
    </Box>
  );
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    pageTitleContainer: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
    },
    phaseContainer: {
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
      marginBottom: 5,
      [theme.breakpoints.up("sm")]: {
        marginBottom: 20,
      },
    },
    title: {
      fontWeight: 700,
      fontSize: "20px",
      lineHeight: "20px",
      color: "#1A1A1A",
      textTransform: "capitalize",
      [theme.breakpoints.up("sm")]: {
        fontSize: "30px",
        lineHeight: "30px",
      },
      [theme.breakpoints.up("md")]: {
        fontSize: "48px",
        lineHeight: "72px",
      },
    },
  })
);
