import React from "react";
import { Typography, Box, IconButton } from "@material-ui/core";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";

interface Props {
  title: string;
  showBackButton?: boolean;
  onBackClick?: () => void;
}

export default function Title({ title, showBackButton, onBackClick }: Props) {
  const classes = useStyles();

  return (
    <Box className={classes.pageTitleContainer}>
      {showBackButton && (
        <IconButton onClick={onBackClick}>
          <ChevronLeftIcon className={classes.backIcon} />
        </IconButton>
      )}
      <Typography className={classes.title}>{title}</Typography>
    </Box>
  );
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    pageTitleContainer: {
      display: "flex",
      justifyContent: "start",
      alignItems: "center",
      // marginBottom: "15px",
      // marginLeft: "-15px",
    },
    title: {
      fontWeight: 700,
      fontSize: "25px",
      lineHeight: "30px",
      color: "#1A1A1A",
      textTransform: "capitalize",
      [theme.breakpoints.up("sm")]: {
        fontSize: "30px",
        lineHeight: "30px",
      },
      [theme.breakpoints.up("md")]: {
        fontSize: "48px",
        lineHeight: "72px",
      },
    },
    backIcon: { fontSize: 35 },
  })
);