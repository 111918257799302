import React from "react";
import { Typography, Paper } from "@material-ui/core";
import { FieldArray } from "formik";
import QuestionControl from "./QuestionControl.web";
import QuizDetailsHeader from "./QuizDetailsHeader.web";
import { useCommonStyles } from "./Styles.web";
import QuestionsTable from "./QuestionTable";

interface Props {
  errors: any;
  values: any;
  touched: any;
  courseId: any;
  resetForm: any;
  setValues: any;
  handleChange: any;
  isSubmitting: any;
  handleEditQuestion: any;
  handleBulkActions: any;
  deleteQuestion: any;
  handleAddQuestion: any;
  handleAddMcqOption: any;
  handleQuestionTypeChange: any;
  handleCancelSavingQuestion: any;
  handleRemoveOptionsForMcqQuestions: any;
}

export default function QuizDetails({
  errors,
  values,
  touched,
  courseId,
  resetForm,
  setValues,
  isSubmitting,
  handleChange,
  handleEditQuestion,
  deleteQuestion,
  handleBulkActions,
  handleAddQuestion,
  handleAddMcqOption,
  handleQuestionTypeChange,
  handleCancelSavingQuestion,
  handleRemoveOptionsForMcqQuestions,
}: Props) {
  const commonClasses = useCommonStyles();
  return (
    <Paper className={commonClasses.paper}>
      <QuizDetailsHeader
        values={values}
        courseId={courseId}
        setValues={setValues}
        handleChange={handleChange}
        handleBulkActions={handleBulkActions}
        handleAddQuestion={handleAddQuestion}
      />
      {values.questions.length === 0 ? (
        <Typography align="center" style={{ marginTop: 10 }}>
          No questions found
        </Typography>
      ) : (
        <>
          <QuestionsTable
            values={values}
            setValues={setValues}
            handleEditQuestion={handleEditQuestion}
            deleteQuestion={deleteQuestion}
          />
          <FieldArray
            name="questions"
            render={() => (
              <QuestionControl
                questions={values.questions}
                handleQuestionTypeChange={handleQuestionTypeChange}
                handleCancelSavingQuestion={handleCancelSavingQuestion}
                handleAddMcqOption={handleAddMcqOption}
                handleChange={handleChange}
                isSubmitting={isSubmitting}
                setValues={setValues}
                resetForm={resetForm}
                values={values}
                touched={touched}
                errors={errors}
                handleRemoveOptionsForMcqQuestions={
                  handleRemoveOptionsForMcqQuestions
                }
              />
            )}
          />
        </>
      )}
    </Paper>
  );
}
