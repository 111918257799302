import React from 'react';
// Customizable Area Start
import { View, Text, StyleSheet } from 'react-native';

interface Iprops {
  title: string,
  viewAll: boolean,
  smallFont?: boolean,
  onViewAllButtonPressed: () => void,
}

// Customizable Area End
// Customizable Area Start
export default function Headings(props: Iprops) {
  return (
    <View style={styles.container}>
      <Text style={props.smallFont ? styles.persnalHeaddingsmallFont : styles.persnalHeadding}>
        {props?.title}
      </Text>
      {
        props.viewAll ?
          <Text onPress={() => { props.onViewAllButtonPressed() }} style={styles.viewAll}>
            View All
          </Text>
          : <View />
      }
    </View>
  );
}
// Customizable Area End

// Customizable Area Start
const styles = StyleSheet.create({
  container: {
    flexDirection: "row",
    alignItems: "center",
    paddingHorizontal: 20,
    paddingVertical: 10,

  },
  persnalHeadding: {
    backgroundColor: "#00000000",
    fontFamily: 'Montserrat-Bold',
    color: "black",
    flex: 1,
    flexDirection: "row",
    fontSize: 24,
    letterSpacing: 0.2
  },
  persnalHeaddingsmallFont: {
    backgroundColor: "#00000000",
    fontFamily: 'Montserrat-Bold',
    color: "black",
    flex: 1,
    flexDirection: "row",
    fontSize: 20,
    letterSpacing: 0.2
  },
  viewAll: {
    color: "#702490",
    fontSize: 12,
    fontFamily: 'Montserrat-Bold',
    marginEnd: 5
  },
})
// Customizable Area End
