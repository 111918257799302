import React from "react";

import {
    Box,
    Button,
    InputLabel,
    Typography,
    InputAdornment,
    IconButton,
    // Customizable Area Start
    Grid,
    TextField,
    Switch,
    FormControl,
    MenuItem,
    Select,
    Accordion,
    AccordionSummary,
    AccordionDetails,
    Badge,
    CircularProgress
    // Customizable Area End
} from "@material-ui/core";

// Customizable Area 
//@ts-ignore
import { createStyles, Theme, withStyles } from "@material-ui/core/styles";
import { ExpandMore } from "@material-ui/icons";
import Calendar from 'react-calendar';
import { withRouter, Link } from 'react-router-dom';
import { Formik } from 'formik';
import * as yup from "yup";
import moment from "moment";
import Autocomplete from '@material-ui/lab/Autocomplete';
import DynamicTitle from "../../../components/src/DynamicTitle";
import './CourseCreation.css';

// Customizable Area End

import CourseCreationController, {
    Props,
} from "./CourseCreationController.web";
import { Calender, Caret, CirclePlusRA, Dollar, EditCourse } from "./assets";
import NotifyAutocomplete from "../../../components/src/NotifyAutocomplete";

// Customizable Area Start
class ProgramCreation extends CourseCreationController {
    wrapperRefLaunchDate: any;
    wrapperRefDueDate: any;
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        this.wrapperRefLaunchDate = React.createRef();
        this.wrapperRefDueDate = React.createRef();
        this.handleClickLaunchDate = this.handleClickLaunchDate.bind(this);
        this.handleClickDueDate = this.handleClickDueDate.bind(this);
        this.tileDisabled = this.tileDisabled.bind(this);
        this.getData = this.getData.bind(this);
        // Customizable Area End
    }

    // Customizable Area Start
    async componentDidMount() {
        super.componentDidMount()
        document.addEventListener("mousedown", this.handleClickLaunchDate);
        document.addEventListener("mousedown", this.handleClickDueDate);

    }
    showLaunchDate() {
        this.setState({ start_date_show: !this.state.start_date_show })
    }
    showDueDate() {
        this.setState({ end_date_show: !this.state.end_date_show })
    }

    handleClickLaunchDate(event: any) {
        if (this.wrapperRefLaunchDate && this.wrapperRefLaunchDate.current && !this.wrapperRefLaunchDate.current.contains(event.target)) {
            event.stopPropagation();
            event.preventDefault();
            this.showLaunchDate();
        }
    }
    handleClickDueDate(event: any) {
        if (this.wrapperRefDueDate && this.wrapperRefDueDate.current && !this.wrapperRefDueDate.current.contains(event.target)) {
            event.stopPropagation();
            event.preventDefault();
            this.showDueDate();
        }
    }
    async componentWillUnmount() {
        document.removeEventListener("mousedown", this.handleClickLaunchDate);
        document.removeEventListener("mousedown", this.handleClickDueDate);
        this.getCategory()
    }
    shouldComponentUpdate(prevProps: any, prevState: any): boolean {
        if (this.state.start_date_show != prevState.start_date_show) {
            return true
        }
        if (this.state.end_date_show != prevState.end_date_show) {
            return true
        }
        if ((prevState.start_date_show === false || prevState.start_date_show === true) && this.state.start_date_show != prevState.start_date_show) {
            return false;
        }
        else if ((prevState.end_date_show === false || prevState.end_date_show === true) && prevState.end_date_show && this.state.end_date_show != prevState.end_date_show) {
            return false;
        }
        else {
            return true
        }


    }

    handleChange =
        (panel: boolean) => (event: React.SyntheticEvent, isExpanded: boolean) => {
            this.setState({ expanded: !this.state.expanded });
        };
    handleSubmit(data: any) {
        console.log(data,'hhhhhhhhhhhhhhhhh');
        
        let fd = new FormData();
        fd.append('program[title]', data.title);
        fd.append('program[description]', data.description);
        fd.append('program[program_for_sale]', data.is_sellable);
        fd.append('program[price]', data.price);
        fd.append('program[is_notify_when_completed]', data.is_notify_when_completed);
        data.is_notify_when_completed && this.state?.adminCollection?.forEach((item: any) => {
            fd.append('admin_user_id[]', item?.attributes?.id);
        });

        fd.append('program[add_preview]', data.add_preview);
        fd.append('program[days_to_complete_the_course]', data.days_to_complete_the_course);
        data.days_to_complete_the_course && fd.append('program[course_timeline]', data.course_timeline)
        fd.append('program[part_of_program]', data.part_of_program === "" ? "true" : data.part_of_program);
        data.part_of_program && fd.append('program[program_id]', data.program_id);
        data.part_of_program && fd.append('program[phase]', data.phases)
        fd.append('program[include_launch_date]', data.include_launch_date);
        data.include_launch_date && fd.append('program[launch_date]', data.launch_date.toLocaleString('en-GB', { timeZone: 'UTC' }))
        fd.append('program[add_due_date]', data.add_due_date);
        data.add_due_date && fd.append('program[due_date]', data.due_date.toLocaleString('en-GB', { timeZone: 'UTC' }));
        fd.append('program[add_due_date]', data.add_due_date)
        fd.append('program[content_release_schedule]', data.content_release_schedule);
        data.content_release_schedule && fd.append('program[content_release_schedule_value]', data.content_release_schedule_value);
        fd.append('program[subscription_period]', data.subscription_period);
        fd.append('program[status]', "draft")

        if ((this.props?.navigation.getParam('id') === "new" && this.props?.navigation.getParam('type') === "addProgram" || this.props?.navigation.getParam('type') === "editProgram") && this.props?.location?.state?.image || this.props?.location?.state?.imgUrl) {

            //@ts-ignore
            fd.append('program[add_thumbnail]', true);
            this.props?.location?.state?.image !== "" ? fd.append('program[thumbnail_image]', this.props?.location?.state?.file) : fd.append('program[stock_image_id]', this.props?.location?.state?.id || 157)
        }
        this.state?.selectedContentRole?.length !== 0 && this.state?.selectedContentRole?.forEach((element: any) => {
            fd.append('content_role_ids[]', element.id);
        });
        this.state.category.filter((item: any) => item.selected === true).map((item: any) => item.id).forEach((element: any) => {
            fd.append('categories_id[]', element);
        });

        fd.append('program[is_free_trial]', data.is_free_trial);
        this.props.navigation.getParam('id') !== "new" && this.props.navigation.getParam('type') === "editProgram" ? this.updateProgram(fd, this.props.navigation.getParam('id')) : this.createProgram(fd)
    }
    selectCategory = (index: any, categories: any) => {
        this.setState({ category: categories });
        let items = [...this.state.category];
        let item = { ...items[index] }
        item.selected = !item.selected
        items[index] = item
        this.setState({ category: items })
    }

    tileDisabled = ({ activeStartDate, date, view }: any) => {
        const yesterday = moment().subtract(1, 'day');
        let x = date < yesterday
        return x;
    }
    getData = (data: any) => {
        if (data.length > 0) {
            this.setState({ adminCollection: data })
        }
    }
    removeContentRoleItem(index: number) {
        this.state?.selectedContentRole.splice(index, 1)
        this.setState({ selectedContentRole: this.state?.selectedContentRole })
        this.props?.location?.data?.removeSelectedContentRole && this.props?.location?.data?.removeSelectedContentRole(index)
    }
    // Customizable Area End

    render() {
        if (this.props?.location?.state?.selectedContentRole) {
            window.history.replaceState({ state: this.props?.location?.state }, "")
        }
        const categories = this.state.category;
        const getImageSource = () => {
            if (this.props.navigation.getParam('id') === "new" && this.props.navigation.getParam('type') === "addProgram") {
                if (this.props?.location?.state?.imgUrl) {
                    return this.props.location.state.imgUrl;
                } else if (this.props?.location?.state?.image) {
                    return this.props.location.state.image;
                } else {
                    return "https://images.unsplash.com/photo-1520085601670-ee14aa5fa3e8?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1170&q=80";
                }
            } else if (this.props.navigation.getParam('type') === "editProgram") {
                if (this.props?.location?.state?.imgUrl) {
                    return this.props.location.state.imgUrl;
                } else if (this.props?.location?.state?.image) {
                    return this.props.location.state.image;
                } else if (this.state?.course?.attributes?.thumbnail_image?.url) {
                    return this.state.course.attributes.thumbnail_image.url;
                } else {
                    return "https://images.unsplash.com/photo-1520085601670-ee14aa5fa3e8?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1170&q=80";
                }
            }
        }
        const { programs } = this.state;
        const { formObject } = this.props?.location?.state || {};

        return (
            // Customizable Area Start
            <>
                <DynamicTitle title={this.state.programs === "" ? "Program Creation" : "Edit Program"} />
                {(this.props.navigation.getParam('type') === "addProgram" || this.state?.programs?.attributes || this.props?.location?.state?.formObject) && <Formik
                    initialValues={{
                        title: programs?.attributes?.title ?? "",
                        description: programs?.attributes?.description ?? "",
                        is_sellable: programs?.attributes?.is_sellable ?? false,
                        price: programs?.attributes?.price ?? 0,
                        is_forced_learning: programs?.attributes?.is_forced_learning ?? false,
                        is_notify_when_completed: programs?.attributes?.is_notify_when_completed ?? false,
                        time_constraint: programs?.attributes?.time_constraint ?? false,
                        add_preview: programs?.attributes?.add_preview ?? false,
                        course_timeline: programs?.attributes?.course_timeline ?? "",
                        part_of_program: programs?.attributes?.part_of_program ?? "",
                        include_launch_date: programs?.attributes?.include_launch_date ?? false,
                        launch_date: programs?.attributes?.launch_date ? new Date(programs?.attributes?.launch_date) : new Date(),
                        due_date: programs?.attributes?.due_date ? new Date(programs?.attributes?.due_date) : new Date(),
                        content_release_schedule: programs?.attributes?.content_release_schedule ?? false,
                        content_release_schedule_value: programs?.attributes?.content_release_schedule_value ?? "",
                        add_thumbnail: programs?.attributes?.add_thumbnail ?? false,
                        add_due_date: programs?.attributes?.add_due_date ?? false,
                        add_to_xpand_collection: false,
                        add_reward:  programs?.attributes?.add_reward ?? false,
                        add_achievement:programs?.attributes?.add_achievement ?? false,
                        is_trial: false,
                        subscription_period: programs?.attributes?.subscription_period ?? "one_time_purchase",
                        launchDateToggle: false,
                        dueDateToggle: false,
                        days_to_complete_the_course: programs?.attributes?.days_to_complete_the_course ?? false,
                        phases: 100,
                        is_free_trial: programs?.attributes?.is_free_trial ?? false,
                        isEdit: false,
                        program_id: 0,
                        start_date: programs?.attributes?.launch_date ? new Date(programs?.attributes?.launch_date) : new Date(),
                        end_date: programs?.attributes?.due_date ? new Date(programs?.attributes?.due_date) : new Date(),
                        ...formObject

                    }}
                    onSubmit={async (values) => {
                        console.log(values)
                        this.handleSubmit(values);
                    }}
                    validationSchema={yup.object().shape({
                        title: yup
                            .string()
                            .required("Title is require").min(5, "min 5 characters required").max(150, "max 150 characters required"),
                        description: yup
                            .string()
                            .required("Description is require").min(5, "min 5 characters required").max(1500, "max 1500 characters required"),

                    })}
                >
                    {(props) => {
                        const {
                            values,
                            errors,
                            touched,
                            handleChange,
                            handleSubmit,
                            handleBlur,
                            setFieldValue
                        } = props;
                        return (
                            <form className="course-section" onSubmit={handleSubmit}>
                                <Grid container className="course">
                                    <Grid item container spacing={4} md={12}>
                                        <Grid item md={3} xs={12}>
                                            <TextField
                                                fullWidth
                                                id="course_title"
                                                name="title"
                                                value={values.title}
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                error={touched.title && Boolean(errors.title)}
                                                helperText={touched.title && errors.title}
                                                disabled={this.state.programs !== "" && !values.isEdit ? true : false}
                                                className="course_title"
                                                variant="standard"
                                                placeholder="Enter program title"
                                                InputLabelProps={{
                                                    shrink: true
                                                }}
                                            />
                                        </Grid>
                                        {(this.props.navigation?.getParam('type') !== "addProgram" && !this.state.isLoading) ? <Grid item md={2}>
                                            <p className="edit-title">Edit Name <span style={{ margin: "10px" }}><img src={EditCourse} onClick={() => setFieldValue('isEdit', !values.isEdit)} /></span></p>
                                        </Grid> : ""}
                                    </Grid>


                                </Grid>
                                <Grid container spacing={4} md={12} style={{ marginTop: "10px" }} className="thumb-content">
                                    <Grid item md={2} xs={12}>
                                        <Link to={{ pathname: '/videos', state: { values: values, redirectPath: window.location.pathname, categories: categories } }} style={{ textDecoration: "none !important" }}><Button className="changethumb-btn"

                                        > {this.props.navigation.getParam('id') === "new" && this.props.navigation.getParam('type') === "addProgram" ? "Add Thumbnail" : "Update Thumbnail"}</Button>
                                        </Link>
                                    </Grid>
                                    <Grid item md={3} className="thumbpreview">
                                        <img src={getImageSource()}
                                            style={{ filter: "drop-shadow(0px 2px 14px rgba(140, 140, 140, 0.715335))", borderRadius: "8px", height: "56px", width: "56px" }}
                                        />
                                    </Grid>
                                </Grid>
                                <Grid container spacing={4} md={12} xs={12} style={{ marginTop: "10px" }} className="contentRole">
                                    <Grid md={2} item>
                                        <Link to={{
                                            pathname: this.props?.navigation?.getParam('id') === "addProgram" ? "/ManageContentRoleList/new" : `/ManageContentRoleList/${this.props?.navigation?.getParam('id')}`, state: {
                                                pathname: window.location.pathname,
                                                values: values, redirectPath: window.location.pathname, categories: categories,
                                                imgUrl: this.props?.location?.state?.imgUrl, image: this.props?.location?.state?.image,
                                                id:this.props?.location?.state?.id
                                            }
                                        }}><Button className="contentRole-btn">+ Add Content Role(s)</Button></Link>
                                    </Grid>

                                    {this.state?.selectedContentRole.length > 0 && this.state?.selectedContentRole.map((item: any, index: number) => <Grid md={2} key={item.id} item className="custom-md-2" >
                                        <Badge badgeContent={"✕"} onClick={() => this.removeContentRoleItem(index)} className="contentRole-badge">
                                            <Button className="contentRoleContent-btn" variant="outlined">{item.content_role}</Button>
                                        </Badge>
                                    </Grid>)}
                                </Grid>
                                <p className="category-heading">Categories</p>
                                <Grid container spacing={4} md={12} xs={12} style={{ marginTop: "10px" }} className="contentRole">
                                    {categories && categories?.length === 0 ? "" : categories?.map((item: any, index: any) => {
                                        return <Grid md={2} xs={12} item className="custom-md-2" key={item.id}>
                                            <Button className={item?.selected ? "contentRoleContent-btn btn-background" : "contentRoleContent-btn"} variant="outlined" onClick={() => this.selectCategory(index, categories)}>{item?.attributes?.name}</Button>
                                        </Grid>
                                    })}
                                </Grid>

                                <Grid item container spacing={4} md={12} style={{ marginTop: "10px" }}>
                                    <Grid item md={12} xs={12}>
                                        <InputLabel htmlFor="filled-adornment-password" style={{ marginBottom: "10px" }} className="course-desc">Program Description</InputLabel>
                                        <TextField
                                            fullWidth
                                            id="course_desc"
                                            name="description"
                                            value={values.description}
                                            error={touched.description && Boolean(errors.description)}
                                            helperText={touched.description && errors.description}
                                            onChange={handleChange}
                                            onBlur={handleBlur}                                           
                                            variant="outlined"
                                            placeholder="Please write your description here..."
                                            multiline
                                            rows={10}
                                            maxRows={12}
                                            InputLabelProps={{
                                                shrink: true
                                            }}
                                        />
                                    </Grid>
                                </Grid>
                                <Grid item container spacing={4} md={12}>
                                    <Grid item md={5} xs={12}>
                                        <Accordion expanded={this.state.expanded} onClick={() => this.handleChange(true)} style={{ background: "#f4f4f4" }}>
                                            <AccordionSummary
                                                expandIcon={<ExpandMore />}
                                                aria-controls="panel1a-content"
                                                id="panel1a-header"
                                                style={{
                                                    borderBottom: "1px solid #979797",
                                                    borderTop: "0.5px solid rgb(215, 212, 212)",
                                                    borderLeft: "0.5px solid rgb(215, 212, 212)",
                                                    borderRight: "0.5px solid rgb(215, 212, 212)"
                                                }}
                                            >
                                                <Typography className="course-logic-options">Program Logic</Typography>
                                            </AccordionSummary>
                                            <AccordionDetails>
                                                <Typography style={{ width: "100%" }}>

                                                    <Box
                                                        className={this.props.classes.listItem}
                                                        key="include_launch_date"
                                                    // onClick={() => {setCurContent(item)}}
                                                    >
                                                        {console.log(values.launch_date, "props")}
                                                        <Typography
                                                            component="p"
                                                            className={values.include_launch_date ? `${this.props.classes.listItemText} toggle-show-label` : `${this.props.classes.listItemText} toggle-hide-label`}
                                                        >
                                                            Include Launch Date
                                                        </Typography>
                                                        <Switch
                                                            // checked={state.checkedA}
                                                            onChange={handleChange}
                                                            name="include_launch_date"
                                                            checked={values.include_launch_date}
                                                            inputProps={{ 'aria-label': "Include Launch Date" }}
                                                            className={this.props.classes.switch}
                                                            classes={{
                                                                switchBase: this.props.classes.switchBase,
                                                                track: this.props.classes.track,
                                                            }}
                                                        />
                                                    </Box>
                                                    <Box
                                                        className={this.props.classes.listItem}
                                                        key="add_due_date"
                                                    // onClick={() => {setCurContent(item)}}
                                                    >
                                                        <Typography
                                                            component="p"
                                                            className={values.add_due_date ? `${this.props.classes.listItemText} toggle-show-label` : `${this.props.classes.listItemText} toggle-hide-label`}
                                                        >
                                                            Add Due Date
                                                        </Typography>
                                                        <Switch
                                                            // checked={state.checkedA}
                                                            onChange={handleChange}
                                                            name="add_due_date"
                                                            checked={values.add_due_date}
                                                            inputProps={{ 'aria-label': "Add Due Date" }}
                                                            className={this.props.classes.switch}
                                                            classes={{
                                                                switchBase: this.props.classes.switchBase,
                                                                track: this.props.classes.track,
                                                            }}
                                                        />
                                                    </Box>
                                                    <Box
                                                        className={this.props.classes.listItem}
                                                        key="days_to_complete_the_course"
                                                    >
                                                        <Typography
                                                            component="p"
                                                            className={values.days_to_complete_the_course ? `${this.props.classes.listItemText} toggle-show-label` : `${this.props.classes.listItemText} toggle-hide-label`}
                                                        >
                                                            Days to Complete The Course
                                                        </Typography>
                                                        <Switch
                                                            onChange={(e) => { handleChange(e); values.days_to_complete_the_course === false && values.add_due_date && setFieldValue('add_due_date', false) }}
                                                            name="days_to_complete_the_course"
                                                            checked={values.days_to_complete_the_course}
                                                            inputProps={{ 'aria-label': "Days to Comeplete The Course" }}
                                                            className={this.props.classes.switch}
                                                            classes={{
                                                                switchBase: this.props.classes.switchBase,
                                                                track: this.props.classes.track,
                                                            }}
                                                        />
                                                    </Box>
                                                    <Box
                                                        className={this.props.classes.listItem}
                                                        key="notify_when_completed"
                                                    // onClick={() => {setCurContent(item)}}
                                                    >
                                                        <Typography
                                                            component="p"
                                                            className={values.is_notify_when_completed ? `${this.props.classes.listItemText} toggle-show-label` : `${this.props.classes.listItemText} toggle-hide-label`}
                                                        >
                                                            Notify When completed
                                                        </Typography>
                                                        <Switch
                                                            // checked={state.checkedA}
                                                            onChange={handleChange}
                                                            name="is_notify_when_completed"
                                                            checked={values.is_notify_when_completed}
                                                            inputProps={{ 'aria-label': "Notify When completed" }}
                                                            className={this.props.classes.switch}
                                                            classes={{
                                                                switchBase: this.props.classes.switchBase,
                                                                track: this.props.classes.track,
                                                            }}
                                                        />
                                                    </Box>
                                                    <Box
                                                        className={this.props.classes.listItem}
                                                        key="content_release_schedule"
                                                    // onClick={() => {setCurContent(item)}}
                                                    >
                                                        <Typography
                                                            component="p"
                                                            className={values.content_release_schedule ? `${this.props.classes.listItemText} toggle-show-label` : `${this.props.classes.listItemText} toggle-hide-label`}
                                                        >
                                                            Content release Schedule
                                                        </Typography>
                                                        <Switch
                                                            // checked={state.checkedA}
                                                            onChange={handleChange}
                                                            name="content_release_schedule"
                                                            checked={values.content_release_schedule}
                                                            inputProps={{ 'aria-label': "Content release Schedule" }}
                                                            className={this.props.classes.switch}
                                                            classes={{
                                                                switchBase: this.props.classes.switchBase,
                                                                track: this.props.classes.track,
                                                            }}
                                                        />
                                                    </Box>
                                                    <Box
                                                        className={this.props.classes.listItem}
                                                        key="forced_learning"
                                                    // onClick={() => {setCurContent(item)}}
                                                    >
                                                        <Typography
                                                            component="p"
                                                            className={values.is_forced_learning ? `${this.props.classes.listItemText} toggle-show-label` : `${this.props.classes.listItemText} toggle-hide-label`}
                                                        >
                                                            Forced Learning
                                                        </Typography>
                                                        <Switch
                                                            // checked={state.checkedA}
                                                            onChange={handleChange}
                                                            name="is_forced_learning"
                                                            checked={values.is_forced_learning}
                                                            inputProps={{ 'aria-label': "Forced Learning" }}
                                                            className={this.props.classes.switch}
                                                            classes={{
                                                                switchBase: this.props.classes.switchBase,
                                                                track: this.props.classes.track,
                                                            }}
                                                        />
                                                    </Box>
                                                    <Box
                                                        className={this.props.classes.listItem}
                                                        key="add_to_xpand_collection"
                                                    // onClick={() => {setCurContent(item)}}
                                                    >
                                                        <Typography
                                                            component="p"
                                                            className={values.add_to_xpand_collection ? `${this.props.classes.listItemText} toggle-show-label` : `${this.props.classes.listItemText} toggle-hide-label`}
                                                        >
                                                            Add to Xpand Collection
                                                        </Typography>
                                                        <Switch
                                                            // checked={state.checkedA}
                                                            onChange={handleChange}
                                                            name="add_to_xpand_collection"
                                                            checked={values.add_to_xpand_collection}
                                                            inputProps={{ 'aria-label': "Add to Xpand Collection" }}
                                                            className={this.props.classes.switch}
                                                            classes={{
                                                                switchBase: this.props.classes.switchBase,
                                                                track: this.props.classes.track,
                                                            }}
                                                        />
                                                    </Box>
                                                    {/* <Box
                                                        className={this.props.classes.listItem}
                                                        key="part_of_program"
                                                    // onClick={() => {setCurContent(item)}}
                                                    >
                                                        <Typography
                                                            component="p"
                                                            className={values.part_of_program ? `${this.props.classes.listItemText} toggle-show-label` : `${this.props.classes.listItemText} toggle-hide-label`}
                                                        >
                                                            Part of a Program
                                                        </Typography>
                                                        <Switch
                                                            // checked={state.checkedA}
                                                            onChange={handleChange}
                                                            name="part_of_program"
                                                            checked={values.part_of_program}
                                                            inputProps={{ 'aria-label': "Part of a Program" }}
                                                            className={this.props.classes.switch}
                                                            classes={{
                                                                switchBase: this.props.classes.switchBase,
                                                                track: this.props.classes.track,
                                                            }}
                                                        />
                                                    </Box> */}
                                                    {this.props?.navigation?.getParam('type') === "editProgram" && <><Box
                                                        className={this.props.classes.listItem}
                                                        key="add_reward"
                                                    // onClick={() => {setCurContent(item)}}
                                                    >
                                                        <Typography
                                                            component="p"
                                                            className={values.add_reward ? `${this.props.classes.listItemText} toggle-show-label` : `${this.props.classes.listItemText} toggle-hide-label`}
                                                        >
                                                            Add Reward
                                                        </Typography>
                                                        <Switch
                                                            // checked={state.checkedA}
                                                            onChange={handleChange}
                                                            name="add_reward"
                                                            checked={values.add_reward}
                                                            inputProps={{ 'aria-label': "Add Reward" }}
                                                            className={this.props.classes.switch}
                                                            classes={{
                                                                switchBase: this.props.classes.switchBase,
                                                                track: this.props.classes.track,
                                                            }}
                                                        />
                                                    </Box>
                                                        <Box
                                                            className={this.props.classes.listItem}
                                                            key="add_achievement"
                                                        // onClick={() => {setCurContent(item)}}
                                                        >
                                                            <Typography
                                                                component="p"
                                                                className={values.add_achievement ? `${this.props.classes.listItemText} toggle-show-label` : `${this.props.classes.listItemText} toggle-hide-label`}
                                                            >
                                                                Add Achievement
                                                            </Typography>
                                                            <Switch
                                                                // checked={state.checkedA}
                                                                onChange={handleChange}
                                                                name="add_achievement"
                                                                checked={values.add_achievement}
                                                                inputProps={{ 'aria-label': "Add Achievement" }}
                                                                className={this.props.classes.switch}
                                                                classes={{
                                                                    switchBase: this.props.classes.switchBase,
                                                                    track: this.props.classes.track,
                                                                }}
                                                            />
                                                        </Box></>}
                                                    {this.props?.navigation?.getParam('type') === "editProgram" && <Box
                                                        className={this.props.classes.listItem}
                                                        key="add_preview"
                                                    // onClick={() => {setCurContent(item)}}
                                                    >
                                                        <Typography
                                                            component="p"
                                                            className={values.add_preview ? `${this.props.classes.listItemText} toggle-show-label` : `${this.props.classes.listItemText} toggle-hide-label`}
                                                        >
                                                            Add Preview/Ad
                                                        </Typography>
                                                        <Switch
                                                            // checked={state.checkedA}
                                                            onChange={handleChange}
                                                            name="add_preview"
                                                            checked={values.add_preview}
                                                            inputProps={{ 'aria-label': "Add Preview/Ad" }}
                                                            className={this.props.classes.switch}
                                                            classes={{
                                                                switchBase: this.props.classes.switchBase,
                                                                track: this.props.classes.track,
                                                            }}
                                                        />
                                                    </Box>}
                                                    <Box
                                                        className={this.props.classes.listItem}
                                                        key="is_sellable"
                                                    // onClick={() => {setCurContent(item)}}
                                                    >
                                                        <Typography
                                                            component="p"
                                                            className={values.is_sellable ? `${this.props.classes.listItemText} toggle-show-label` : `${this.props.classes.listItemText} toggle-hide-label`}
                                                        >
                                                            Make this program sellable
                                                        </Typography>
                                                        <Switch
                                                            // checked={state.checkedA}
                                                            onChange={handleChange}
                                                            name="is_sellable"
                                                            checked={values.is_sellable}
                                                            inputProps={{ 'aria-label': "Make this program sellable" }}
                                                            className={this.props.classes.switch}
                                                            classes={{
                                                                switchBase: this.props.classes.switchBase,
                                                                track: this.props.classes.track,
                                                            }}
                                                        />
                                                    </Box>
                                                    <Box
                                                        className={this.props.classes.listItem}
                                                        key="is_free_trial"
                                                    // onClick={() => {setCurContent(item)}}
                                                    >
                                                        <Typography
                                                            component="p"
                                                            className={values.is_free_trial ? `${this.props.classes.listItemText} toggle-show-label` : `${this.props.classes.listItemText} toggle-hide-label`}
                                                        >
                                                            Add Free Trial
                                                        </Typography>
                                                        <Switch
                                                            // checked={state.checkedA}
                                                            onChange={handleChange}
                                                            name="is_free_trial"
                                                            checked={values.is_free_trial}
                                                            inputProps={{ 'aria-label': "Make this program trial" }}
                                                            className={this.props.classes.switch}
                                                            classes={{
                                                                switchBase: this.props.classes.switchBase,
                                                                track: this.props.classes.track,
                                                            }}
                                                        />
                                                    </Box>
                                                </Typography>
                                            </AccordionDetails>
                                        </Accordion>
                                    </Grid>
                                    <Grid item md={7} xs={12}>
                                        <Accordion expanded={true} style={{ background: "#f4f4f4" }}>
                                            <AccordionSummary
                                                expandIcon={""}
                                                aria-controls="panel1a-content"
                                                id="panel1a-header"
                                                style={{
                                                    borderBottom: "1px solid #979797",
                                                    borderTop: "0.5px solid rgb(215, 212, 212)",
                                                    borderLeft: "0.5px solid rgb(215, 212, 212)",
                                                    borderRight: "0.5px solid rgb(215, 212, 212)"
                                                }}
                                            >
                                                <Typography className="course-logic-options">Program Options</Typography>
                                            </AccordionSummary>
                                            <AccordionDetails>
                                                <Box
                                                    className={this.props.classes.listItemTimeline}
                                                    style={{ display: "flex", flexDirection: "column", width: "100% !important" }}
                                                // onClick={() => {setCurContent(item)}}
                                                >
                                                    <Grid container md={12} xs={12} style={{ marginBottom: "10px" }}>
                                                        <div style={{ width: "100%", display: "flex", justifyContent: "space-between", padding: "10px" }}>

                                                            {values.include_launch_date ?

                                                                <Grid item xs={12} md={6} style={{
                                                                    background: "#FAFAFA",
                                                                    border: "1px solid #979797",
                                                                    borderRadius: 8,
                                                                    maxWidth: "48.5%"
                                                                }} >
                                                                    <span style={{ display: "flex", position: "relative" }} className="head-text-launch-date">
                                                                        <IconButton color="primary" aria-label="calender" onClick={(e) => {
                                                                            e.preventDefault();
                                                                            e.stopPropagation();
                                                                            this.showLaunchDate();
                                                                        }
                                                                        }>
                                                                            <img src={Calender} style={{ width: "27.5px", height: "27.15px", background: "#9F9F9F" }} />
                                                                        </IconButton>
                                                                        <span className="sub-text-launch-date" style={{ display: "flex", alignItems: "center", fontWeight: 500, fontSize: "16px", color: "#1A1A1A" }} >
                                                                            {values.start_date === "" ? "Launch Date" : values.start_date.toLocaleDateString("en-US")}
                                                                        </span>
                                                                        {this.state.start_date_show &&
                                                                            <div style={{ position: "absolute", zIndex: 100, top: 50, left: 0 }} ref={this.wrapperRefLaunchDate}  >
                                                                                <Calendar tileDisabled={this.tileDisabled} onChange={(date: any) => { setFieldValue('launch_date', date); setFieldValue('start_date', date) }} value={values.launch_date} />

                                                                            </div>
                                                                        }
                                                                    </span>
                                                                </Grid>
                                                                : ""}
                                                            {values.add_due_date ?
                                                                <Grid item xs={12} md={6}
                                                                    style={{
                                                                        background: "#FAFAFA",
                                                                        border: "1px solid #979797",
                                                                        borderRadius: 8,
                                                                        maxWidth: "48.5%"
                                                                    }}

                                                                >
                                                                    <span className="head-text-launch-date" style={{ display: "flex", position: 'relative' }}  >
                                                                        <IconButton color="primary" aria-label="calender" onClick={(e) => {
                                                                            e.preventDefault();
                                                                            e.stopPropagation();
                                                                            this.showDueDate();
                                                                        }}>
                                                                            <img src={Calender} style={{ width: "27.5px", height: "27.15px", background: "#9F9F9F" }} />
                                                                        </IconButton>
                                                                        <span className="sub-text-launch-date" style={{ display: "flex", alignItems: "center", fontWeight: 500, fontSize: "16px", color: "#1A1A1A" }} >
                                                                            {values.end_date === "" ? "Due Date" : values.end_date.toLocaleDateString("en-US")}
                                                                        </span>

                                                                        {this.state.end_date_show &&
                                                                            <div style={{ position: "absolute", zIndex: 100, top: 50, left: 0 }} ref={this.wrapperRefDueDate}>
                                                                                <Calendar tileDisabled={this.tileDisabled} onChange={(date: any) => { setFieldValue('due_date', date); setFieldValue('end_date', date) }} value={values.due_date} />
                                                                            </div>
                                                                        }
                                                                    </span>
                                                                </Grid>
                                                                : ""}
                                                        </div>
                                                    </Grid>

                                                    <Grid container md={12} xs={12} style={{ marginBottom: "10px" }} spacing={4}>
                                                        {values.days_to_complete_the_course ? <Grid item md={6} xs={12}>
                                                            <p className="notify-heading">Days to Complete The Course</p>

                                                            <TextField
                                                                style={{ marginTop: "10px" }}
                                                                name="course_timeline"
                                                                className="form-field"
                                                                type="number"
                                                                value={values.course_timeline}
                                                                InputLabelProps={{
                                                                    shrink: true,
                                                                }}
                                                                onChange={handleChange}
                                                                placeholder="Days to Compelete The Course"
                                                                variant="standard"
                                                                fullWidth
                                                            />


                                                        </Grid> : ""}
                                                        {values.is_notify_when_completed ?
                                                            <Grid item md={6}>
                                                                <p className="notify-heading">Who gets notified?</p>
                                                                <NotifyAutocomplete getSelectedData={(data: any) => this.getData(data)} options={this.state.notificationList} />
                                                            </Grid>
                                                            : ""}
                                                    </Grid>
                                                    {values.content_release_schedule ?

                                                        <Grid container md={12} xs={12} style={{ marginBottom: "10px" }} spacing={4}>
                                                            <div className="calender">
                                                                <Button variant="outlined" onClick={(e) => { e.preventDefault(); e.stopPropagation(); setFieldValue("content_release_schedule_value", "daily") }} className={`${values.content_release_schedule_value !== "daily" && "calender-btnoutlined" || "calender-btn"}`}>Daily</Button>
                                                                <Button variant="outlined" onClick={(e) => { e.preventDefault(); e.stopPropagation(); setFieldValue("content_release_schedule_value", "weekly") }} className={`${values.content_release_schedule_value !== "weekly" && "calender-btnoutlined" || "calender-btn"}`}>Weekly</Button>
                                                                <Button variant="outlined" onClick={(e) => { e.preventDefault(); e.stopPropagation(); setFieldValue("content_release_schedule_value", "monthly") }} className={`${values.content_release_schedule_value !== "monthly" && "calender-btnoutlined" || "calender-btn"}`}>Monthly</Button>

                                                            </div>

                                                        </Grid>
                                                        : ""}
                                                    {values.part_of_program ?

                                                        <Grid container md={12} xs={12} style={{ marginBottom: "10px" }} spacing={4}>
                                                            <Grid item md={6} xs={12}>
                                                                <FormControl className="select-phases">
                                                                    <Select
                                                                        labelId="demo-controlled-open-select-label"
                                                                        id="demo-controlled-open-select"
                                                                        name="phases"
                                                                        value={values.phases}
                                                                        onChange={handleChange}
                                                                        variant="outlined"
                                                                        IconComponent={() => null}
                                                                        endAdornment={
                                                                            <InputAdornment position="end">
                                                                                <img src={Caret} />
                                                                            </InputAdornment>

                                                                        }
                                                                        fullWidth>
                                                                        <MenuItem selected value={100}>
                                                                            <em>Phases</em>
                                                                        </MenuItem>
                                                                        <MenuItem value={1}>Phases 1</MenuItem>
                                                                        <MenuItem value={2}>Phases 2</MenuItem>
                                                                        <MenuItem value={3}>Phases 3</MenuItem>
                                                                        <MenuItem value={4}>Phases 4</MenuItem>
                                                                        <MenuItem value={5}>Phases 5</MenuItem>
                                                                        <MenuItem value={6}>Phases 6</MenuItem>
                                                                        <MenuItem value={7}>Phases 7</MenuItem>
                                                                        <MenuItem value={8}>Phases 8</MenuItem>
                                                                        <MenuItem value={9}>Phases 9</MenuItem>
                                                                        <MenuItem value={10}>Phases 10</MenuItem>

                                                                    </Select>
                                                                </FormControl>
                                                            </Grid>
                                                            <Grid item md={6} xs={12}>
                                                                <Autocomplete
                                                                    id="combo-box-demo"
                                                                    //@ts-ignore
                                                                    name="program_id"
                                                                    options={this.state.programList}
                                                                    defaultValue={values.program_id}
                                                                    popupIcon={<img src={Caret} />}
                                                                    getOptionLabel={(option: any) => option.title}
                                                                    onChange={(e, value) => {
                                                                        setFieldValue("program_id", value.id)
                                                                    }}
                                                                    renderInput={(params) => <><TextField
                                                                        {...params}
                                                                        name="program_id"
                                                                        InputLabelProps={{
                                                                            ...params.InputLabelProps,
                                                                            shrink: true,
                                                                        }}
                                                                        placeholder="Select or Search Program"
                                                                        variant="outlined"
                                                                        fullWidth
                                                                    /></>}
                                                                />

                                                            </Grid>
                                                        </Grid>
                                                        : ""}
                                                    <Grid container md={12} xs={12} spacing={4} style={{ marginBottom: "10px" }}>
                                                        {values.add_reward ?
                                                            <Grid item md={6} xs={12} >
                                                                <Link to={{ pathname: "/AddReward", state: { programId: this.props?.navigation?.getParam('id') } }}>
                                                                    <div className="dashed-border">
                                                                        <img src={CirclePlusRA} />
                                                                        <p className="bottom-title">Add Reward</p>
                                                                    </div>
                                                                </Link>
                                                            </Grid>

                                                            : ""}
                                                        {values.add_achievement ?
                                                            <Grid item md={6} xs={12} >
                                                                <Link to={{ pathname: "/AddAchievement", state: { programId: this.props?.navigation?.getParam('id') } }}>
                                                                    <div className="dashed-border">
                                                                        <img src={CirclePlusRA} />
                                                                        <p className="bottom-title">Add Achievement</p>
                                                                    </div>
                                                                </Link>
                                                            </Grid>

                                                            : ""}
                                                    </Grid>
                                                    <Grid container md={12} xs={12} spacing={4} style={{ marginBottom: "10px" }}>
                                                        {values.add_preview ?
                                                            <Grid item md={6} xs={12}>
                                                                <Link to={{ pathname: "/AddPreview", state: { programId: this.props?.navigation?.getParam('id') } }} style={{ textDecoration: "none !important" }}>
                                                                    <div className="dashed-border">
                                                                        <img src={CirclePlusRA} />
                                                                        <p className="bottom-title">Add Preview/Ad</p>
                                                                    </div>
                                                                </Link>
                                                            </Grid>

                                                            : ""}
                                                    </Grid>
                                                    {values.is_sellable ?
                                                        <Grid container md={12} xs={12} spacing={4} style={{ marginBottom: "10px", marginTop: "10px" }}>
                                                            <Grid item md={6} xs={12}>
                                                                <TextField
                                                                    name="price"
                                                                    className="form-field"
                                                                    type="number"
                                                                    value={values.price}
                                                                    InputLabelProps={{
                                                                        shrink: true,
                                                                    }}
                                                                    onChange={handleChange}
                                                                    placeholder="Price"
                                                                    variant="standard"
                                                                    fullWidth
                                                                    InputProps={{
                                                                        startAdornment: (
                                                                            <InputAdornment position="start">
                                                                                <img src={Dollar} />
                                                                            </InputAdornment>
                                                                        ),
                                                                        inputProps: { min: 1 }
                                                                    }}
                                                                />
                                                            </Grid>

                                                            <Grid item md={6} xs={12}>
                                                                <FormControl className="select-phases">
                                                                    <Select
                                                                        labelId="demo-controlled-open-select-label"
                                                                        id="demo-controlled-open-select"
                                                                        name="subscription_period"
                                                                        onChange={handleChange}
                                                                        value={values.subscription_period}
                                                                        variant="outlined"
                                                                        IconComponent={() => null}
                                                                        endAdornment={
                                                                            <InputAdornment position="end">
                                                                                <img src={Caret} />
                                                                            </InputAdornment>

                                                                        }
                                                                        fullWidth>
                                                                        <MenuItem selected value="sub">
                                                                            Subscription Period
                                                                        </MenuItem>
                                                                        <MenuItem value={"one_time_purchase"}>One Time Purchase</MenuItem>
                                                                        <MenuItem value={"monthly_subscription"}>Monthly</MenuItem>
                                                                        <MenuItem value={"semi_anually"}>Semi-Annually</MenuItem>
                                                                        <MenuItem value={"anually"}>Annually</MenuItem>
                                                                    </Select>
                                                                </FormControl>
                                                            </Grid>
                                                        </Grid>
                                                        : ""}
                                                </Box>
                                            </AccordionDetails>
                                        </Accordion>
                                    </Grid>
                                </Grid>
                                <Grid container md={12} xs={12} style={{ textAlign: "center", margin: "20px" }}>
                                    <Grid item md={12} xs={12}>
                                        <Button className="draft-btn" fullWidth>Save to Draft</Button>
                                    </Grid>
                                </Grid>
                                <Grid container md={12} xs={12} style={{ textAlign: "center", margin: "20px" }}>
                                    <Grid item md={12} xs={12}>
                                        <Button className="proceed-content-btn" type="submit" fullWidth>{window.location.pathname === "/ProgramCreation/new/addProgram" ? "Proceed To Add Content" : "Proceed To Update Content"}</Button>
                                    </Grid>
                                </Grid>

                            </form>
                        );
                    }}
                </Formik>
                }
                {
                    (this.props?.navigation.getParam('type') !== "addProgram" && this.state.isLoading) && <Box style={{ display: "flex", height: "35%", width: "100%", justifyContent: "center", alignItems: "center" }} > <CircularProgress /> </Box>
                }
            </>
            // Customizable Area End
        );
    }
}
// Customizable Area 
const styles: any = (theme: Theme) =>
    createStyles({
        listItem: {
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            padding: "12px 10px",
            [theme.breakpoints.up("sm")]: {
                width: "100%",
                padding: "12px 20px",
                paddingRight: 0
            },
            [theme.breakpoints.up("md")]: {
                width: "100%"
            }
        },
        listItemTimeline: {
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            padding: "12px 10px",
            [theme.breakpoints.up("sm")]: {
                width: "100%",
                padding: "12px 20px",
                paddingRight: 0
            },
            [theme.breakpoints.up("md")]: {
                width: "100%"
            }
        },
        listItemText: {
            fontWeight: 500,
            fontSize: "14px",
            color: "#11142D",
            lineHeight: "20px"
        },
        switch: {
            "& .MuiSwitch-colorSecondary.Mui-checked": {
                color: "#6C328B",
            },
            "& .MuiSwitch-colorSecondary.Mui-checked + .MuiSwitch-track": {
                backgroundColor: "#C4AED0",
            },
        },
        switchBase: {
            color: "#545650",
            "&$checked": {
                color: "#6C328B"
            },
            "&$checked + $track": {
                background: "#c4aed0",
                mixBlendMode: "normal",
                opacity: "0.37"
            }
        },
        track: {
            background: "#c8cbd2",
            mixBlendMode: "normal",
            opacity: "0.37"
        }
    });
const webStyle = {
    mainWrapper: {
        display: "flex",
        fontFamily: "Roboto-Medium",
        flexDirection: "column",
        alignItems: "center",
        paddingBottom: "30px",
        background: "#fff",
    },
    inputStyle: {
        borderBottom: "1px solid rgba(0, 0, 0, 0.6)",
        width: "100%",
        height: "100px",
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
    },
    buttonStyle: {
        width: "100%",
        height: "45px",
        marginTop: "40px",
        border: "none",
        backgroundColor: "rgb(98, 0, 238)",
    },
};
// @ts-ignore
//@ts-nocheck
export default withRouter(withStyles(styles)(ProgramCreation))
// Customizable Area End
// Customizable Area End